import {
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/react";
import { arrowUp } from "ionicons/icons";
import React, { FC, ComponentProps, useRef, useState, useContext } from "react";
import "react-toastify/dist/ReactToastify.css";
import { isMobile } from "react-device-detect";
import { ScrollContext } from '../../../data/ScrollContext'
type CustomContentProps = ComponentProps<typeof IonContent>;

interface ContentProps extends CustomContentProps {
  isRefreshPage: boolean;
  isLogin?: boolean;
}

const CustomContent: FC<ContentProps> = ({
  isLogin,
  isRefreshPage,
  ...props
}) => {
  const [isShowLoader, setIsShowLoader] = useState(false);
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const [isShowScrollBtn, setIsShowScrollBtn] = useState(false);
  const { setScrollPosition } = useContext(ScrollContext);
  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop(500);
  };

  const onScroll = (e: any) => {
    setScrollPosition(e.detail.currentY);
    if (e.detail.currentY < 100) {
      setIsShowScrollBtn(false);
    } else {
      setIsShowScrollBtn(true);
    }
  };

  const refreshPage = () => {
    window.location.reload();
  };
  return (
    <>
      <IonContent
        className={isLogin ? "login" : ""}
        {...props}
        ref={contentRef}
        // onIonScrollEnd={(e) => {
        //   setIsShowLoader(false);
        // }}
        // onIonScrollStart={(e) => {
        //   setIsShowLoader(true);
        // }}
        scrollEvents={true}
        onIonScroll={(e) => {
          onScroll(e);
        }}
      >
        {isRefreshPage && (
          <IonRefresher
            slot="fixed"
            onIonRefresh={(e) => {
              refreshPage();
            }}
          >
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
        )}
        {props.children}
        {isMobile && (
          <IonFab
            slot="fixed"
            vertical="bottom"
            horizontal="end"
            className="button-pop scroll-up"
          >
            <IonFabButton
              hidden={!isShowScrollBtn}
              onClick={() => scrollToTop()}
            >
              <IonIcon icon={arrowUp} />
            </IonFabButton>
          </IonFab>
        )}
      </IonContent>
      {/* {!isPlatform("ios") && isShowLoader && (
        <IonBackdrop tappable={true} visible={false} />
      )} */}
    </>
  );
};

export default CustomContent;
