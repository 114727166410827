import React, { useState } from "react";
import { IonButton, IonIcon } from "@ionic/react";
import CustomAlert from "./CustomAlert";
import { trash, trashDark } from "../../../images/index";

const CustomDeleteButton: React.FC<{
  header?: string;
  message?: string;
  subHeader?: string;
  icon?: string;
  isShow?: boolean;
  fillClear?: boolean;
  sizeSmall?: boolean;
  deleteButtonTitle?: string;
  onDelete: () => void;
  onCancel?: () => void;
}> = (props) => {
  const [showDeleteAlert, setDeleteAlert] = useState(false);
  const DeleteButtonsArray = [
    {
      text: "לא",
      role: "no",
      handler: () => {
        setDeleteAlert(false);
        if (props.onCancel) {
          props.onCancel();
        }
      },
    },
    {
      text: "כן",
      role: "yes",
      handler: () => {
        setDeleteAlert(false);
        props.onDelete();
      },
    },
  ];

  return (
    <>
      <IonButton
        className="ion-no-padding delete-btn"
        size={props.sizeSmall ? "small" : "default"}
        fill={props.fillClear ? "clear" : "default"}
        hidden={
          props.isShow === undefined || props.isShow === true ? false : true
        }
        onClick={() => {
          setDeleteAlert(true);
        }}
      >
        {props.deleteButtonTitle}
        <IonIcon icon={props.icon ? props.icon : trash} />
      </IonButton>
      <CustomAlert
        onDismiss={() => {
          setDeleteAlert(false);
        }}
        isOpen={showDeleteAlert}
        header={props.header ? props.header : "האם ברצונך לבצע מחיקה?"}
        message={props.message ? props.message : "האם אתה בטוח?"}
        buttons={DeleteButtonsArray}
      ></CustomAlert>
    </>
  );
};
export default CustomDeleteButton;
