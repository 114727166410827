// React
import React, { useContext, useEffect, useState } from "react";
import { IonButton, IonCol, IonIcon, IonImg, IonRow } from "@ionic/react";
import CustomTitleButton from "../../utils/CustomElements/CustomTitleButton";
// Styles
import "../businessCardTabs.scss";
import { blankProductIcon, pencil } from "../../../images";
// Context
import UserContext from "../../../data/Auth-context";
import { usePriceListContext } from "../../../data/PriceListContextProvider";
// Component
import AddEditPriceList from "./AddEditPriceList";
import { CategoryPriceList } from "../../../data/PriceList-context";
import CategoriesPopUp from "./CategoriesPopUp";
import MiniMemberPriceList from "./MiniMemberPriceList";

const PriceList: React.FC<{
  isAddPriceListClick: boolean;
  onIsAddPriceListClick: () => void;
  isPriceListSelected: (isPriceListSelected: boolean) => void;
}> = (props) => {
  const { authValues } = useContext(UserContext);
  const {
    GetPriceListByMemberId,
    CategoryPriceLists,
    MemberPriceList,
  } = usePriceListContext();

  const [selectedPriceListId, setSelectedPriceListId] = useState(Number);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [isAddEditing, setIsEditing] = useState(false);
  const [isChooseCategory, setIsChooseCategory] = useState(false);
  const numOfCatPriceLists = CategoryPriceLists.length;

  React.useEffect(() => {
    GetPriceListByMemberId(authValues?.ClientId!);
  }, []);

  React.useEffect(() => {
    if (MemberPriceList) {
      MemberPriceList.PriceListItems.map((priceListItem, i) => {
        return (
          priceListItem.PriceListId !== -1 &&
          setSelectedPriceListId(priceListItem.PriceListId)
        );
      });
    }
  }, [MemberPriceList]);

  const setPriceListDetails = (priceList: CategoryPriceList) => {
    priceList.PriceListId !== -1 &&
      setSelectedPriceListId(priceList.PriceListId);
    setSelectedCategoryName(priceList.CatName);
  };

  const catNameButtons =
    CategoryPriceLists.length > 1 &&
    CategoryPriceLists.map((priceList, i) => {
      return (
        <IonCol key={`priceListCategories_${i}`}>
          <CustomTitleButton
            position="home-section-btn"
            onClick={() => {
              setPriceListDetails(priceList);
              setIsEditing(true);
              setIsChooseCategory(false);
            }}
            BtnTxt={priceList.CatName}
          />
        </IonCol>
      );
    });

  useEffect(() => {
    if (props.isAddPriceListClick === true && numOfCatPriceLists <= 1) {
      setIsEditing(true);
      props.onIsAddPriceListClick();
      numOfCatPriceLists === 1 &&
        CategoryPriceLists.map((priceList) => {
          return setPriceListDetails(priceList);
        });
    } else if (
      props.isAddPriceListClick === true &&
      numOfCatPriceLists > 1 &&
      !MemberPriceList
    ) {
      setIsChooseCategory(true);
      props.onIsAddPriceListClick();
    }
  }, [props.isAddPriceListClick]);

  return (
    <React.Fragment>
      <IonRow>
        <IonCol className="ion-padding-bottom">
          <p className="small-p">
            הוספת מחירון אישי יוכל לתת ללקוחות שלך את היכולת לדעת מה טווח
            המחירים שלך ולקדם הנעה לפעולה מול בית העסק שלך
          </p>
        </IonCol>
      </IonRow>
      {!MemberPriceList && (
        <IonRow>
          <IonCol className="ion-text-center no-items-wraper">
            <IonImg src={blankProductIcon}></IonImg>
            <p className="large-p">אין מחירון</p>
          </IonCol>
        </IonRow>
      )}
      {MemberPriceList && (
        <React.Fragment>
          <IonRow>
            <IonCol>
              <IonButton
                className="btn-clear-icon-text"
                fill="clear"
                onClick={() => {
                  setIsEditing(true);
                }}
              >
                <span className="small-p">מחירון פעיל</span>
                <IonIcon icon={pencil} size="small" slot="end" />
              </IonButton>
            </IonCol>
          </IonRow>
          <MiniMemberPriceList />
        </React.Fragment>
      )}

      {/* more than 1 category priceList related & don't have exist priceList*/}
      {isChooseCategory && (
        <CategoriesPopUp
          show={isChooseCategory}
          closeModal={() => {
            setIsChooseCategory(false);
          }}
        >
          {catNameButtons}
        </CategoriesPopUp>
      )}

      {isAddEditing && authValues && (
        <AddEditPriceList
          selectedPriceListId={selectedPriceListId}
          selectedCategoryName={selectedCategoryName}
          show={isAddEditing}
          closeModal={() => {
            setIsEditing(false);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default PriceList;
