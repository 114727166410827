import { IonButton, IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import {
  arrowLeftRed,
} from '../../images/index';

export interface progressBarStep {
  id: number, step_desc: string, is_completed: boolean, hidden: boolean
}
export interface progressBarButton {
  text: string, showIcon: boolean, class: string, hidden: boolean, disabled: boolean
}
const SelfSaleProgressBar: React.FC<{ salePhases: progressBarStep[], currentIndex: number, setCurrentPhase: (currentPhaseIndex: number, nextPhaseIndex: number, completed: boolean) => void, class?: string, hidePhasesIndicator?: boolean, backBtn?: progressBarButton, nextBtn?: progressBarButton }> = props => {

  const backButtonHandler = () => {
    console.log("back. curr index ", props.currentIndex)
    if (props.currentIndex === 0) {
      props.setCurrentPhase(-1, 0, false)
    }
    else {
      props.setCurrentPhase(props.currentIndex, props.currentIndex - 1, false)
    }
  }
  const continueButtonHandler = () => {
    console.log("continueButtonHandler. curr index ", props.currentIndex)
    console.log("continueButtonHandler. props.salePhases ", props.salePhases)
    if (props.currentIndex !== props.salePhases.length - 1) {
      props.setCurrentPhase(props.currentIndex, props.currentIndex + 1, true)
    }
    else {
      props.setCurrentPhase(props.currentIndex, -1, true)
    }
  }
  return (
    <div className={props.class ? props.class + " progress-bar" : "progress-bar"}>
      {/* {props.currentIndex > 0 && (
        )} */}
      <IonButton fill="clear" className="pb-control pb-control-back" onClick={backButtonHandler}>
        {props.backBtn && props.backBtn.text ? props.backBtn.text : "הקודם"}
      </IonButton>
      {props.salePhases.length && !props.hidePhasesIndicator && (
        props.salePhases.map((item, index) => <div key={index} className={index === props.currentIndex || item.is_completed ? "pb-phase pb-phase-current" : "pb-phase"}></div>)
      )}
      <IonButton disabled={props.nextBtn && props.nextBtn.disabled} fill="clear" className={props.nextBtn && props.nextBtn.class ? props.nextBtn.class : "pb-control pb-control-continue"} onClick={continueButtonHandler}>
        {props.nextBtn && props.nextBtn.text ? props.nextBtn.text : "הבא"}
        {props.nextBtn && props.nextBtn.showIcon && <img src={arrowLeftRed} />}
      </IonButton>
    </div>
  )
}
export default SelfSaleProgressBar;