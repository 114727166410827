import { Plugins } from "@capacitor/core";
import { config } from "../Constants";
const { Storage } = Plugins;

export const  crashSendLog = async (error : string, info : string, componentStack :string ) => {
    const userData = await Storage.get({ key: "userData" });
    const Token = userData.value ? JSON.parse(userData.value).Token : "";
    var data = new URLSearchParams({
      Error: error,
      Info: info,
      ComponentStack: componentStack,
    }).toString();

    return new Promise((resolve) => {
      fetch(`${config.url.API_URL}/Client.asmx/CrashSend`, {
        method: "POST",
        body: data,
        headers: {
            "Accept": "application/json",
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
            "Auth-Token": Token
          }
      }).then(response => {
        if (response.ok) {
          console.log("Crash Send Log OK");
        } 
       
      })
      .catch(error => {
        console.log(error);
      
      });
    });
  }