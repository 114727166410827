import React, { useContext, useEffect, useRef, useState } from 'react';
import { IonButton, IonGrid, IonRow, IonCol, IonItem, IonIcon, IonLabel, IonSelect, IonSelectOption, IonCheckbox } from '@ionic/react';
import MemberDetailsContext, { OpenDays } from '../../../data/MemberDetailes-context';
import { getTimeFotmat } from '../../../utils-ts/GlobalDateFunctions';

import { useForm } from 'react-hook-form';
import CustomForm from '../../utils/CustomElements/CustomForm';
import { addOutline } from 'ionicons/icons';
import CustomModal from '../../utils/CustomElements/CustomModal/CustomModal';
import { getOpenDays } from '../../../utils-ts/BusinessOpenDaysFunc';
import { HeaderParameters } from '../../../data/Header-Context';
import { pencil } from '../../../images';
import HandleMemberOpenHours from './HandleMemberOpenHours';
import { toast } from 'react-toastify';
import CustomPublishButton from '../../utils/CustomElements/CustomPublishButton';

const MemberOpenHours: React.FC<{
  show: boolean;
  closeModal: () => void;
  onSave: () => void;
}> = (props) => {
  const { handleSubmit, formState, errors, control } = useForm({
    mode: 'onChange',
  });

  let MemberDetailsCxt = useContext(MemberDetailsContext);
  useEffect(() => {
    if (MemberDetailsCxt.OpenDays == undefined) {
      MemberDetailsCxt.initMemberDetailsContext();
    }
  }, []);
  const allowGmbSync = MemberDetailsCxt.allowSyncGmb == true;
  const [syncGmb, setSyncGmb] = React.useState(allowGmbSync);
  const syncGmbRef = useRef<HTMLIonCheckboxElement>(null);

  const [editIndex, setEditIndex] = useState<number>(-1);
  const [pageHaveChanges, setPageHaveChanges] = useState(false);
  const [invalidItems, setInvalidItems] = useState<string[]>([]);

  // const getMemberTemplate = () => {
  //   const currentOpenDays = MemberDetailsCxt.OpenDays ? MemberDetailsCxt.OpenDays.filter((o) => o.IsDeleted === false) : [];
  //   if (currentOpenDays.length > 0) {
  //     if (currentOpenDays.length == 1) {
  //       //if only one item possible 24/7 or 24/5 / or NeedToPhone
  //       const curr = currentOpenDays[0];
  //       if (curr.FromHour == 7 && curr.FromHourMinutes == 0 && curr.ToHour == 7 && curr.ToHourMinutes == 0) {
  //         if (curr.Day1 && curr.Day2 && curr.Day3 && curr.Day4 && curr.Day5) {
  //           if (curr.Day6 && curr.Day7 && curr.Is24x7) {
  //             if (curr.IsNeedToPhone) {
  //               return 7;
  //             } else {
  //               return 5;
  //             }
  //           } else {
  //             return 8;
  //           }
  //         }
  //       }
  //     }
  //   } else {
  //     //list empty
  //     //template not selected
  //     return undefined;
  //   }
  //   return 9; //custom
  // };

  const [templateID, setTemplatId] = useState<number | undefined>(() => {
    return MemberDetailsCxt.getMemberOpenHoursTemplate();
  });

  const [tempOpenDays, setTempOpenDays] = useState<OpenDays[]>(MemberDetailsCxt.OpenDays ? MemberDetailsCxt.OpenDays.filter((o) => o.IsDeleted === false)
  .map((o)=> JSON.parse(JSON.stringify(o))) : []);
  const interfaceOptions = {
    showBackdrop: false,
  };

  const templateList = [
    { id: 9, description: 'הגדרה לפי ימים ושעות' },
    { id: 8, description: 'פתוח 24/5' },
    { id: 5, description: 'פתוח 24/7' },
    { id: 7, description: 'בתאום טלפוני' },
  ];

  const week_days: Array<{ name: string; day: 'Day1' | 'Day2' | 'Day3' | 'Day4' | 'Day5' | 'Day6' | 'Day7' }> = [
    { name: 'א', day: 'Day1' },
    { name: 'ב', day: 'Day2' },
    { name: 'ג', day: 'Day3' },
    { name: 'ד', day: 'Day4' },
    { name: 'ה', day: 'Day5' },
    { name: 'ו', day: 'Day6' },
    { name: 'ש', day: 'Day7' },
  ];
  const saveHandler = () => {
    if (editIndex >= 0) {
      if (tempOpenDays[editIndex].IsDirty) {
        toast('השינויים טרם נשמרו');
        return;
      }
    }

    const is_valid = checkIfPageValid();
    if (!is_valid) {
      return;
    }

    const original_template = MemberDetailsCxt.getMemberOpenHoursTemplate();
    if (templateID == 9) {
    } else if (original_template == templateID) {
      return;
    }
    let new_openDays: OpenDays[] = [];
    switch (templateID) {
      case 9:
        new_openDays = tempOpenDays;
        break;
      case 8: //א-ה 24 שעות
        new_openDays = [
          {
            Id: '-1',
            Day1: true,
            Day2: true,
            Day3: true,
            Day4: true,
            Day5: true,
            Day6: false,
            Day7: false,
            FromHour: 7,
            FromHourMinutes: 0,
            Is24x7: false,
            IsDeleted: false,
            IsDirty: false,
            IsNeedToPhone: false,
            IsNextDay: false,
            IsSaturdayInOut: false,
            ToHour: 7,
            ToHourMinutes: 0,
          },
        ];
        break;
      case 5: //7 ימים 24 שעות
        new_openDays = [
          {
            Id: '-1',
            Day1: true,
            Day2: true,
            Day3: true,
            Day4: true,
            Day5: true,
            Day6: true,
            Day7: true,
            FromHour: 7,
            FromHourMinutes: 0,
            Is24x7: true,
            IsDeleted: false,
            IsDirty: false,
            IsNeedToPhone: false,
            IsNextDay: false,
            IsSaturdayInOut: false,
            ToHour: 7,
            ToHourMinutes: 0,
          },
        ];
        break;
      case 7: //7 ימים 24 שעות בתאום טלפוני
        new_openDays = [
          {
            Id: '-1',
            Day1: true,
            Day2: true,
            Day3: true,
            Day4: true,
            Day5: true,
            Day6: true,
            Day7: true,
            FromHour: 7,
            FromHourMinutes: 0,
            Is24x7: true,
            IsDeleted: false,
            IsDirty: false,
            IsNeedToPhone: true,
            IsNextDay: false,
            IsSaturdayInOut: false,
            ToHour: 7,
            ToHourMinutes: 0,
          },
        ];
        break;
    }
    if (templateID == 5 || templateID == 7 || templateID == 8) {
      //if template then delete all existing
      for (let i = 0; i < tempOpenDays.length; i++) {
        if (tempOpenDays[i].Id != '-1') {
          tempOpenDays[i].IsDeleted = true;
          new_openDays.push(tempOpenDays[i]);
        }
      }
    }

    MemberDetailsCxt.saveOpenDays(new_openDays, syncGmb);
    props.onSave();
  };
  const checkIfPageValid = () => {
    let isValid = true;
    let error = '';
    const invalid_items = [];
    if (templateID === undefined) {
      //template not selected
      isValid = false;
      error = 'יש לבחור מרשימה';
    } else if (templateID === 9) {
      //only if custom hours
      if (tempOpenDays.filter((f) => f.IsDeleted === false).length == 0) {
        //no active items
        isValid = false;
        error = 'יש להזין לפחות יום אחד';
      } else {
        //validate every active item

        for (let i = 0; i < tempOpenDays.length; i++) {
          const check_open_day = tempOpenDays[i];
          if (check_open_day.IsDeleted) {
            continue;
          }

          //check if any week day selected
          if (
            check_open_day.Day1 === false &&
            check_open_day.Day2 === false &&
            check_open_day.Day3 === false &&
            check_open_day.Day4 === false &&
            check_open_day.Day5 === false &&
            check_open_day.Day6 === false &&
            check_open_day.Day7 === false
          ) {
            isValid = false;
            error = 'יש לבחור לפחות יום אחד';
            invalid_items.push(check_open_day.Id);
          } else {
            //check if fromtime before totime

            // if (Date.parse('01/01/2011 ' + fromTime) >= Date.parse('01/01/2011 ' + toTime)) {
            //   isValid = false;
            //   // אם שעת סיום קטנה משעת התחלה
            //   error = 'שעות פתיחה לא תקינות';
            //   invalid_items.push(i);
            // } else {
            //check if time overlaps other items
            const fromTime = getTimeFotmat(check_open_day.FromHour!, check_open_day.FromHourMinutes!);
            const toTime = getTimeFotmat(check_open_day.ToHour!, check_open_day.ToHourMinutes!);
            tempOpenDays
              .filter((f) => f.IsDeleted === false)
              .map((d, index) => {
                const _fromTime = getTimeFotmat(d.FromHour!, d.FromHourMinutes!);
                const _toTime = getTimeFotmat(d.ToHour!, d.ToHourMinutes!);

                if (
                  (d.Day1 === true && check_open_day.Day1 === true) ||
                  (d.Day2 === true && check_open_day.Day2 === true) ||
                  (d.Day3 === true && check_open_day.Day3 === true) ||
                  (d.Day4 === true && check_open_day.Day4 === true) ||
                  (d.Day5 === true && check_open_day.Day5 === true) ||
                  (d.Day6 === true && check_open_day.Day6 === true) ||
                  (d.Day7 === true && check_open_day.Day7 === true)
                ) {
                  const checkOverlap = check_open_day.Id.toString() != d.Id.toString(); //parseInt(check_open_day.Id) <= 0 || check_open_day.Id.toString() != d.Id.toString();
                  const isHoursOverlap = checkOverlap && dateRangeOverlaps_sameDay(_fromTime, _toTime, d.IsNextDay, fromTime, toTime!);
                  if (isHoursOverlap) {
                    isValid = false;
                    // אם קיימות שעות חופפות
                    error = 'לא ניתן לשמור קיימות שעות חופפות במערכת';
                    invalid_items.push(check_open_day.Id);
                    //console.log('overlap');
                  }
                }

                //check overlap next day
                if (check_open_day.IsNextDay) {
                  if (toTime > _fromTime) {
                    if (
                      (d.Day2 === true && check_open_day.Day1 === true) ||
                      (d.Day3 === true && check_open_day.Day2 === true) ||
                      (d.Day4 === true && check_open_day.Day3 === true) ||
                      (d.Day5 === true && check_open_day.Day4 === true) ||
                      (d.Day6 === true && check_open_day.Day5 === true) ||
                      (d.Day7 === true && check_open_day.Day6 === true) ||
                      (d.Day1 === true && check_open_day.Day7 === true)
                    ) {
                      isValid = false;
                      // אם קיימות שעות חופפות
                      error = 'לא ניתן לשמור קיימות שעות חופפות ביום למחרת במערכת';
                      invalid_items.push(check_open_day.Id);
                      //console.log('overlap next day');
                    }
                  }
                }
                if (d.IsNextDay) {
                  if (_toTime > fromTime) {
                    if (
                      (d.Day1 === true && check_open_day.Day2 === true) ||
                      (d.Day2 === true && check_open_day.Day3 === true) ||
                      (d.Day3 === true && check_open_day.Day4 === true) ||
                      (d.Day4 === true && check_open_day.Day5 === true) ||
                      (d.Day5 === true && check_open_day.Day6 === true) ||
                      (d.Day6 === true && check_open_day.Day7 === true) ||
                      (d.Day7 === true && check_open_day.Day1 === true)
                    ) {
                      isValid = false;
                      // אם קיימות שעות חופפות
                      error = 'לא ניתן לשמור קיימות שעות חופפות ביום קודם במערכת';
                      invalid_items.push(check_open_day.Id);
                      //console.log('overlap prev day');
                    }
                  }
                }
              });
            //}
          }
        }
      }
    }
    setInvalidItems(invalid_items);
    if (!isValid) {
      toast.error(error);
    }
    return isValid;
  };
  const dateRangeOverlaps_sameDay = (a_start: string, a_end: string, a_is_next: boolean, b_start: string, b_end: string) => {
    let is_overlap = false;
    if (a_start <= b_start && b_start <= a_end) {
      is_overlap = true; // b starts in a
    }
    if (a_start <= b_end && b_end <= a_end) {
      is_overlap = true; // b ends in a
    }
    if (b_start < a_start && a_end < b_end && !a_is_next) {
      is_overlap = true; // a in b
    }

    return is_overlap;
  };

  const headerParameters: HeaderParameters = {
    isPageHaveChanges: pageHaveChanges,
    modalType: 'modal',
  };

  const cbxSyncGmbChanged = () => {
    const sync = syncGmbRef.current!.checked;
    setSyncGmb(sync);
  };
  const onTempSaveClicked = (index: number, openDay: OpenDays) => {
    setEditIndex(-1);
    setPageHaveChanges(true);
    setTempOpenDays((curr) => {
      const curr_items = [...curr];
      curr_items[index] = openDay;
      curr_items[index].IsDirty = false;
      return curr_items;
    });
  };
  const onTempUndoClicked = (index: number) => {
    setEditIndex(-1);
  };

  const onTempDeleteClicked = (index: number) => {
    setEditIndex(-1);
    setPageHaveChanges(true);
    setTempOpenDays((curr) => {
      const curr_items = [...curr];
      curr_items[index].IsDeleted = true;
      curr_items[index].IsDirty = false;
      return curr_items;
    });
  };

  const onTempChanged = (index: number) => {
    setTempOpenDays((curr) => {
      const curr_items = [...curr];
      curr_items[index].IsDirty = true;
      return curr_items;
    });
  };

  return (
    <CustomModal
      pageName={'BusinessOpenHours'}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
      headerParameters={headerParameters}
      title="שעות פתיחה"
      subTitle="">
      <CustomForm onSubmit={handleSubmit(saveHandler)} errors={errors}>
        <IonGrid>
          <IonRow>
            <IonCol className="ion-padding-bottom">
              <IonItem color="light" lines="full" mode="md" className="ion-margin-bottom">
                <IonLabel position="stacked">שעות הפתיחה</IonLabel>
                <IonSelect
                  color="light"
                  mode="md"
                  value={templateID}
                  interface="popover"
                  interfaceOptions={interfaceOptions}
                  placeholder="בחר מרשימה"
                  onIonChange={(e) => {
                    e.stopPropagation();
                    setTemplatId(e.detail.value);
                  }}>
                  {templateList &&
                    templateList.map((t) => {
                      return (
                        <IonSelectOption key={t.id} value={t.id}>
                          {t.description}
                        </IonSelectOption>
                      );
                    })}
                </IonSelect>
              </IonItem>
              {templateID == 9 && <div>יש להגדיר ימים וטווח שעות בהם העסק פתוח</div>}
              {templateID == 8 && <div>העסק פתוח בימים א-ה, 24 שעות ביממה</div>}
              {templateID == 5 && <div>העסק פתוח בימים א-ש, 24 שעות ביממה</div>}
            </IonCol>
          </IonRow>
          {templateID == 9 && (
            <>
              <IonRow>
                <IonCol className="ion-no-padding">
                  <IonButton
                    title="הוספת שעות פעילות"
                    color="primary"
                    shape="round"
                    className="ion-no-padding"
                    size="small"
                    style={{ width: 24, height: 24 }}
                    onClick={() => {
                      //setStartEditing(true);
                      //selectedOpenHourIndexRef.current = null;
                      if (editIndex >= 0) {
                        if (tempOpenDays[editIndex].IsDirty) {
                          toast('השינויים טרם נשמרו');
                          return;
                        }
                      }
                      setInvalidItems([]);
                      let min_id = Math.min.apply(
                        null,
                        tempOpenDays.map((t) => parseInt(t.Id))
                      );
                      if (min_id < 0) {
                        min_id = min_id - 1;
                      } else {
                        min_id = -1;
                      }
                      setTempOpenDays((curr) => {
                        const curr_items = [...curr];
                        curr_items.unshift({
                          Id: min_id.toString(),
                          Day1: false,
                          Day2: false,
                          Day3: false,
                          Day4: false,
                          Day5: false,
                          Day6: false,
                          Day7: false,
                          FromHour: 0,
                          FromHourMinutes: 0,
                          Is24x7: false,
                          IsDeleted: false,
                          IsDirty: false,
                          IsNeedToPhone: false,
                          IsNextDay: false,
                          IsSaturdayInOut: false,
                          ToHour: 0,
                          ToHourMinutes: 0,
                        });
                        setEditIndex(0);
                        return curr_items;
                      });
                    }}>
                    <IonIcon slot="icon-only" icon={addOutline}></IonIcon>
                  </IonButton>
                </IonCol>
              </IonRow>
              {tempOpenDays.length > 0 && (
                <IonRow>
                  <IonCol sizeMd="8" size="12" className="ion-padding-top">
                    {tempOpenDays.map((openDay, index) => {
                      const openHour = getTimeFotmat(openDay.FromHour!, openDay.FromHourMinutes!);
                      const closeHour = getTimeFotmat(openDay.ToHour!, openDay.ToHourMinutes!);
                      const openDaysList = getOpenDays(openDay);

                      if (openDay.IsDeleted) {
                        return;
                      }

                      return (
                        <IonItem
                          lines="none"
                          key={index}
                          className={
                            'ion-margin-bottom ' +
                            (invalidItems.find((i) => {
                              return i == openDay.Id;
                            })
                              ? 'item-invalid'
                              : '')
                          }>
                          {editIndex == index ? (
                            <HandleMemberOpenHours
                              onChange={() => {
                                onTempChanged(index);
                              }}
                              onCloseModal={() => {
                                onTempUndoClicked(index);
                              }}
                              onDelete={() => {
                                onTempDeleteClicked(index);
                              }}
                              onSave={(tempOpenDay: OpenDays) => {
                                onTempSaveClicked(index, tempOpenDay);
                              }}
                              openDay={openDay}></HandleMemberOpenHours>
                          ) : (
                            <>
                              <IonCol size="5">
                                {openHour} - {closeHour}
                              </IonCol>
                              <IonCol>
                                {week_days.map((day, i) => {
                                  return (
                                    <span key={i} className={openDay[day.day] ? 'emph-text' : ''}>
                                      {day.name}{' '}
                                    </span>
                                  );
                                })}
                              </IonCol>

                              <IonCol size="1.5">
                                <IonButton
                                  fill="clear"
                                  type="button"
                                  size="small"
                                  onClick={() => {
                                    if (editIndex >= 0) {
                                      if (tempOpenDays[editIndex].IsDirty) {
                                        toast('השינויים טרם נשמרו');
                                        return;
                                      }
                                    }
                                    setEditIndex(index);
                                    setInvalidItems([]);
                                  }}>
                                  <IonIcon icon={pencil} size="small" slot="icon-only"></IonIcon>
                                </IonButton>
                              </IonCol>
                            </>
                          )}
                        </IonItem>
                      );
                    })}
                  </IonCol>
                </IonRow>
              )}
            </>
          )}
          <IonRow>
            {allowGmbSync && (
              <IonCol sizeMd="8" size="12">
                {allowGmbSync && (
                  <IonItem lines="none" className="checkbox-list-item">
                    <IonLabel>סינכרון עם דף גוגל</IonLabel>
                    <IonCheckbox slot="start" checked={syncGmb} value="SyncGmb" ref={syncGmbRef} onIonChange={cbxSyncGmbChanged} />
                  </IonItem>
                )}
              </IonCol>
            )}
          </IonRow>
        </IonGrid>
        <CustomPublishButton style={{ marginTop: 22, zIndex: 1 }} fixed={false} />
      </CustomForm>
    </CustomModal>
  );
};

export default MemberOpenHours;
