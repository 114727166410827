import React, { useState } from 'react';
import { IonItem, IonLabel, IonTextarea, IonText, IonButton, IonIcon, IonInput } from '@ionic/react';
import { close } from "ionicons/icons";
interface CustomTextInputProps {
    name: string;
    value: string;
    placeholder: string;
    maxlength?: number,
    rows?: number,
    autoGrow?: boolean,
    hideCounter?: boolean,
    hideClearBtn?: boolean,
    customClass?: string,
    onChange: (value: any) => void;
}

const CustomTextInput: React.FC<CustomTextInputProps> = ({ placeholder, value, name, onChange, maxlength, rows, autoGrow, hideCounter, customClass, hideClearBtn }) => {
    const handleTextareaChange = (e: CustomEvent) => {
        onChange(e);
    };
    const clearText = () => {
        let obj = {
            detail: { value: "" },
            target: { name: name }
        }
        onChange(obj);
    };

    return (
        <div className={customClass ? 'custom-text-input ' + customClass : 'custom-text-input'}>
            <IonItem>
                <IonTextarea
                    placeholder={placeholder}
                    value={value}
                    onIonChange={handleTextareaChange}
                    name={name}
                    autoGrow={autoGrow}
                    rows={rows}
                />
                {!hideClearBtn && value && <IonButton fill="clear" style={{ height: '30px', width: '30px' }} onClick={clearText}>
                    <IonIcon icon={close}></IonIcon>
                </IonButton>}

            </IonItem>

            {!hideCounter && maxlength && (
                <p className="small-p input-counter">
                    {value.length}/{maxlength}
                </p>
            )}
        </div>
    );
};

export default CustomTextInput;