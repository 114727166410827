import React, { useContext, useEffect } from "react";
import {
    IonRow,
    IonCol,
} from "@ionic/react";

import { Control, FieldError, NestDataObject } from "react-hook-form";

import VasContext, { DigitalProductField } from "../../data/Vas-context";
import VasPackSelect from "./VasPackSelect";
import VasDigitalField from "./VasDigitalField";

const FrmDigitalProd: React.FC<{
    prod_code: number;
    register: React.Ref<any>;
    control: Control;
    errors?: NestDataObject<Record<string, any>, FieldError>;
    digitalProductFields: DigitalProductField[];
    clearError: (name: string) => void;

}> = props => {

    const vasCxt = useContext(VasContext);

    useEffect(() => {
        initDefaultValues();
    }, []);

    const initDefaultValues = () => {
        if (props.digitalProductFields) {
            for (let i = 0; i < props.digitalProductFields!.length; i++) {
                //register cbx fields
                if (props.digitalProductFields[i].fldType == 6) {
                    if (props.control.fieldsRef &&
                        props.control.fieldsRef!.current &&
                        !props.control.fieldsRef!.current![props.digitalProductFields[i].fldKey]) {
                        props.control.register(props.digitalProductFields[i].fldKey);
                    }

                }

                if (props.digitalProductFields[i].defaultValue) {
                    props.control.setValue(props.digitalProductFields[i].fldKey, props.digitalProductFields[i].defaultValue);
                }
                else if (props.digitalProductFields[i].fldType == 6) {
                    props.control.setValue(props.digitalProductFields[i].fldKey, false);
                }
            }
        }
    };


    const getCustomAutocomplete = (searchString: string, digitalFieldNumber?: number) => {
        if (!!digitalFieldNumber) {
            const digitalField = props.digitalProductFields!.find(f => { return f.fldNumber == digitalFieldNumber });
            if (digitalField) {
                if (digitalField.fldType == 12) {
                    return vasCxt.getAddressAutocomplete(searchString);
                } else if (digitalField.fldType == 11) {
                    if (digitalField.fldKey.toLowerCase() == 'facebookcategory') {
                        return getFacebookCategoriesAutocomplete(searchString);
                    } else if (digitalField.fldKey.toLowerCase() == 'googlecategory') {
                        return vasCxt.getGoogleCategoriesAutocomplete(searchString);
                    }
                    else {
                        //return vasCxt.getCustomAutocomplete(searchString, digitalFieldNumber!);
                    }
                }
            }
        }
        return null;
    };

    const getFacebookCategoriesAutocomplete = (searchText: string) => {
        return new Promise(resolve => {
            var search_cat = vasCxt.FacebookCategories?.filter(f => f.display.indexOf(searchText) >= 0).sort((a, b) => { return a.display.indexOf(searchText) - b.display.indexOf(searchText) });

            resolve(JSON.stringify(search_cat));
        });
    }

    // const handleCbxChange = (e: CustomEvent) => {
    //     props.control.setValue(e.detail.value, e.detail.checked); //for outer form ref
    // };
    return (
        <React.Fragment>
            <IonRow>
                <IonCol size='12' sizeMd='6' className='col-1'>
                    <IonRow>
                        <IonCol >
                            <VasPackSelect prod_code={props.prod_code} register={props.register} control={props.control} errors={props.errors} clearError={props.clearError} />
                        </IonCol>
                    </IonRow>
                    {!!props.digitalProductFields && props.digitalProductFields.sort((a, b) => { return a.sortOrder - b.sortOrder }).slice(0, 4).map(field =>
                        <IonRow key={field.fldNumber}>
                            <IonCol >
                                <VasDigitalField errors={props.errors}
                                    control={props.control}
                                    register={props.register}
                                    field={field}
                                    getCustomAutocomplete={getCustomAutocomplete} />
                            </IonCol>
                        </IonRow>)
                    } </IonCol>

                {!!props.digitalProductFields && props.digitalProductFields.length > 4 &&
                    <IonCol size='12' sizeMd='6' className='col-2'>
                        {!!props.digitalProductFields && props.digitalProductFields.sort((a, b) => { return a.sortOrder - b.sortOrder }).slice(4, 9).map(field =>


                            <IonRow key={field.fldNumber}>
                                <IonCol >
                                    <VasDigitalField errors={props.errors}
                                        control={props.control}
                                        register={props.register}
                                        field={field}
                                        getCustomAutocomplete={getCustomAutocomplete} />
                                </IonCol>
                            </IonRow>


                        )
                        }
                    </IonCol>}

            </IonRow>

        </React.Fragment>
    );
};

export default FrmDigitalProd;