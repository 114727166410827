import { IonButton, IonIcon, IonItem, IonPopover } from "@ionic/react";
import { close } from "ionicons/icons";
import React, { useContext, useState } from "react";
import UserContext from "../../../data/AuthContextProvider";
import {
  businessCardWhite,
  cupon,
  product,
  reviewWhite,
  imageWhite,
  budget_white
} from "../../../images";
import { isMobile } from "react-device-detect";
import ShareOptions from "../../ShareOptions/ShareOptions";
import { config } from "../../../Constants";

const CustomPopContentOptions: React.FC<{
  isShow: boolean;
  onClosePopOver: () => void;
}> = (props) => {
  const [isShowShareOptions, setIsShowShareOptions] = useState(false);
  const { authValues } = useContext(UserContext);
  const popoverButtonsList = [
    {
      buttonTitle: "לבקש חוות דעת",
      buttonIcon: reviewWhite,
      buttonLink: "",
      onClick: "shareFunc",
    },
    {
      buttonTitle: "הוספת מוצר",
      buttonIcon: product,
      buttonLink: "/BusinessCard/products",
    },
    {
      buttonTitle: "עדכון פרטים",
      buttonIcon: businessCardWhite,
      buttonLink: "/BusinessCard",
    },
    {
      buttonTitle: "הוספת קופון",
      buttonIcon: cupon,
      buttonLink: "/BusinessCard/coupons",
    },
    {
      buttonTitle: "הוספת תמונה",
      buttonIcon: imageWhite,
      buttonLink: "/BusinessCard/gallery",
    },
  ];

  if (authValues && authValues.IsSelfRegister == false && !config.otef_israel) {
    popoverButtonsList.push({
      buttonTitle: "שדרוג חבילה",
      buttonIcon: budget_white,
      buttonLink: "/Budget",
    });
  }
  return (
    <>
      <IonPopover
        isOpen={props.isShow}
        cssClass="popover-container"
        onDidDismiss={(e) => props.onClosePopOver()}
      >
        {!isMobile && (
          <IonButton color="light" onClick={() => props.onClosePopOver()}>
            <IonIcon icon={close}></IonIcon>
          </IonButton>
        )}
        {popoverButtonsList.map((popoverItem, index) => {
          return (
            <IonItem
              detail={false}
              button={false}
              routerLink={
                popoverItem.buttonLink != ""
                  ? popoverItem.buttonLink
                  : undefined
              }
              onClick={() => {
                if (popoverItem.onClick && popoverItem.onClick != "") {
                  switch (popoverItem.onClick) {
                    case "shareFunc":
                      setIsShowShareOptions(true);
                      break;
                  }
                }
                
                dtlPushEvt({'event':'content_menu','eventInfo': {'ScreenName':'{{page path}}','user_action':popoverItem.buttonTitle}});

              }}
              routerDirection="none"
              key={index}
              className="popover-item"
              lines="none"
            >
              <span className="popover-item-title">
                {popoverItem.buttonTitle}
              </span>
              <div className="icon-wraper">
                <IonIcon size="large" icon={popoverItem.buttonIcon}></IonIcon>
              </div>
            </IonItem>
          );
        })}
        {isMobile && (
          <IonButton color="light" onClick={() => props.onClosePopOver()}>
            <IonIcon icon={close}></IonIcon>
          </IonButton>
        )}
      </IonPopover>
      <ShareOptions
        closeModal={() => {
          setIsShowShareOptions(false);
        }}
        initShowQR={false}
        show={isShowShareOptions}
      ></ShareOptions>
    </>
  );
};
export default CustomPopContentOptions;
