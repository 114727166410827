import { FieldError, NestDataObject } from "react-hook-form";

export const validateForm = (
  errors: NestDataObject<Record<string, any>, FieldError>
) => {
  if (Object.keys(errors).length > 0) {
    let key = Object.keys(errors).reduceRight((k, l) => {
      return document.getElementsByName(k)[0].offsetTop <
        document.getElementsByName(l)[0].offsetTop
        ? k
        : l;
    });
    document.getElementsByName(key)[1].focus();
    return false;
  }
  else{ return true;}
};

export const validatePatterns = (value: string, type: string) => {
  let pattern = undefined;
  switch (type) {
    case "facebook":
    case "FB":
      //https://fb.watch/ve4uWfWyXt/ 
      pattern = /(?:https?:\/\/)?(?:www\.)?(?:he-il\.)?facebook\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-._:A-Za-z0-9&%?=א-ת]*\/)*([\w\-\._:A-Za-z0-9&%?=א-ת]*)/;
      if (!pattern.test(value)) {
        return "כתובת פייסבוק אינה תקינה";
      }
      break;
      case 'FB_video':
      // https://www.facebook.com/username2/videos/100000000000000
      // https://www.facebook.com/video.php?v=1024669469401082
      // https://www.facebook.com/watch/?v=1024669469401082
      // https://www.facebook.com/username/videos/title/1024669469401082/
      pattern = /^https?:\/\/www\.facebook\.com.*\/(video(s)?|watch|story)(\.php?|\/).+$/;
      if (!pattern.test(value)) {
if(!value.startsWith('https://fb.watch/')){

        return "כתובת סרטון פייסבוק אינה תקינה";
      }
      }
      break;
    case "youtube":
    case "YT":
      pattern = /(?:https:\/\/)(?:youtu\.be\/|(?:www\.|m\.)?youtube\.com\/(?:watch|v|embed|shorts)(?:\.php)?(?:\?.*v=|\/))([a-zA-Z0-9\_-]+)((\?|\&)([^=]+)\=([^&]+))?/gi;
      if (!pattern.test(value)) {
        //test with old patern
        pattern = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/gi;
        if (!pattern.test(value)) {
          return "כתובת youtube אינה תקינה";
        }
      }
      break;
      case "instagram":
      case "IG":
        const ig_regex = /(?:https?:\/\/)?(?:www.)?instagram.com\/?([a-zA-Z0-9\.\_\-]+)?\/([p]+)?([reel]+)?([tv]+)?([stories]+)?\/([a-zA-Z0-9\-\_\.]+)\/?([0-9]+)?/gm;
        pattern = ig_regex;
        if (!pattern.test(value)) {
          return "כתובת אינסטגרם אינה תקינה";
        }
      break;
      case "tiktok":
        case "TK":
          const tt_regex =  /(?:https?:\/\/)?(?:m|www|vm)?\.?tiktok\.com\/((?:.*\b(?:(?:usr|v|embed|user|video)\/|\?shareId=|\&item_id=)(\d+))|\w+)/;
          pattern = tt_regex;
          if (!pattern.test(value)) {
            return "כתובת טיקטוק אינה תקינה";
          }
          break;
    case "email":
      pattern = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      if (!pattern.test(value)) {
        return "כתובת מייל אינה תקינה";
      }
      break;
    case "tel":
      pattern = /[0-9]{9}/;
      if (!pattern.test(value)) {
        return "טלפון אינו תקין";
      }
      break;
    case "url":
      pattern = /(http:\/\/|https:){1}?.?\S*$/;
      if (!pattern.test(value)) {
        return "יש להזין כתובת תקינה לדוגמא: https://www.b144.co.il";
      }
      break;
      case "password":
        pattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
        if (!pattern.test(value)) {
          return "יש להציג סיסמה עם מינימום שש ספרות ולפחות אות אחת באנגלית וספרה";
        }
        break;
        case "color":
        pattern = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
        if (!pattern.test(value)) {
          return "קוד צבע לא תקין";
        }
        break;
  }
};

export const ErrorMessage = (key: string, parameter?: string) => {
  switch (key) {
    case "required":
      return "שדה חובה";
      break;
    case "maxLength":
      return " מס התווים המקסימלי הוא " + parameter;
      break;
    case "minLength":
      return " מס התווים המינימלי הוא " + parameter;
      break;
    default:
      return "שדה לא תקין";
      break;
  }
};
