import React, { useContext, useEffect, useState } from "react";

import {
  IonRow,
  IonCol,
  IonGrid,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import { isMobile } from "react-device-detect";

import { HeaderParameters } from "../../data/Header-Context";
import CustomPage from "../../components/utils/CustomElements/CustomPage";
import CustomHeaddingItem from "../../components/utils/CustomElements/CustomPageTitle/CustomHeaddingItem";
import "../../theme/items-list-page.scss";
import {
  DateFilter,
  GetMemberCalls,
  GetMemberLeads,
  GetAllLeadsTypes,
  memberCallsObj,
  memberLeadsObj,
  memberLeadOrCallObj,
} from "../../data/statisticServices";
import CustomDateRangePicker, {
  defaultStaticRanges,
  defaultStaticRangesOpt,
} from "../../components/utils/Filters/CustomDateRangePicker/CustomDateRangePicker";
import LeadsList from "../../components/Statistic/LeadsList";
import CallsList from "../../components/Statistic/CallsList";
import UserContext from "../../data/AuthContextProvider";
import LeadsAndCallsList from "../../components/Statistic/LeadsAndCallsList";

const headerParameters: HeaderParameters = {
  isShowLogo: true,
  backButton: {
    isShowMobile: true,
    isShowDesktop: true,
    link: "/StatisticsOpt",
  },
  isShowNotification: true,
};

const MemberLeads: React.FC = () => {
  const [leadsList, setLeadsList] = useState<memberLeadsObj[]>();
  const [callsList, setCallsList] = useState<memberCallsObj[]>();
  const [leadsAndCallsList, setLeadsAndCallsList] = useState<
    memberLeadOrCallObj[]
  >();

  const _defaultStaticRanges: defaultStaticRangesOpt = "החודש";
  const defaultStaticSelectedRanges = defaultStaticRanges.find(
    (range) => range.label === _defaultStaticRanges
  );
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(() => {
    return defaultStaticSelectedRanges!.startDate;
  });
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(() => {
    return defaultStaticSelectedRanges!.endDate;
  });
  const { setLoader } = useContext(UserContext);

  const [segmentSelected, setsegmentSelected] = useState("all");

  useEffect(() => {
    setLoader(true);
    const dateFilterObj: DateFilter = {
      FromYear: selectedStartDate.getFullYear(),
      FromMonth: selectedStartDate.getMonth() + 1,
      FromDay: selectedStartDate.getDate(),
      ToYear: selectedEndDate.getFullYear(),
      ToMonth: selectedEndDate.getMonth() + 1,
      ToDay: selectedEndDate.getDate(),

      // FromYear: 2020,
      // FromMonth: 10,
      // FromDay: 1,
      // ToYear: 2020,
      // ToMonth: 10,
      // ToDay: 31,
    };

    GetAllLeadsTypes(dateFilterObj).then((res) => {
      setLeadsAndCallsList(res);
      setLoader(false);
    });
  }, [selectedEndDate]); //segmentSelected

  return (
    <CustomPage
      headerParameters={headerParameters}
      pageName={"MemberLeads"}
      title="הלידים שקיבלת"
      // innerPage={false}
    >
      <>
        <IonGrid fixed={true} className="main-content">
          <IonRow>
            <IonCol size="12">
              <CustomHeaddingItem
                mainTitle="לידים שהתקבלו בזכות הפרסום שלך ב-B144"
                isButton={false}
                innerPage={true}
                pageName={"MemberLeads"}
                // iconBackground="statistics-asset"
              ></CustomHeaddingItem>
            </IonCol>
          </IonRow>
          <IonCol>
            <IonCol size="4">
              <CustomDateRangePicker
                maxDate={new Date()}
                onDateChange={(startDate: Date, endDate: Date) => {
                  if (startDate !== selectedStartDate) {
                    setSelectedStartDate(startDate);
                  }
                  if (endDate !== selectedEndDate) {
                    setSelectedEndDate(endDate);
                  }
                }}
                startDate={selectedStartDate}
                endDate={selectedEndDate}
                defaultStaticRanges={_defaultStaticRanges}
              ></CustomDateRangePicker>
            </IonCol>
            {/* <IonCol>
              <IonCol style={{ padding: 0 }}>
                <IonSegment
                  style={{ justifyContent: "flex-start" }}
                  mode="md"
                  value={segmentSelected}
                  onIonChange={(e) => {
                    setsegmentSelected(
                      e.detail.value ? e.detail.value.toString() : "all"
                    );
                  }}
                >
                  <IonSegmentButton value="all">
                    <span className="segment">הכל</span>
                  </IonSegmentButton>
                  <IonSegmentButton value="leads">
                    <span className="segment">לידים</span>
                  </IonSegmentButton>
                  <IonSegmentButton value="calls">
                    <span className="segment">טלפונים</span>
                  </IonSegmentButton>
                </IonSegment>
              </IonCol>
            </IonCol> */}
          </IonCol>

          {leadsAndCallsList && (
            <>
              <LeadsAndCallsList
                items={leadsAndCallsList}
                filterSelected={segmentSelected}
              />
            </>
          )}
        </IonGrid>
      </>
    </CustomPage>
  );
};
export default MemberLeads;
