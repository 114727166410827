import React, { useContext, useState, useCallback, useEffect } from "react";
import { config } from "../Constants";
import MemberDetailes, {
  BusinessAddress,
  GeneralInfo,
  BusinessLanguages,
  AdvCategories,
  OpenDays,
  PublicationDays,
  BusinessNewsItem,
  BusinessLink,
  BusinessEvent,
  ContentStatus,
  ExtraDetailes,
  originModal,
  QuestionsAndAnswers,
  BusinessMovie,
  BusinessServiceAreaObj,
  productGalleryTypes,
  BusinessSeoDetailsItem,
  SeoDetailsItem,
  MultiCardsMember,
  BusinessCertificateItem,
  CertificateIssuerType,
} from "./MemberDetailes-context";

import { base64FromPath } from "@ionic/react-hooks/filesystem";
import { Photo } from "../components/utils/ImagePicker/ImagePicker";
import { getTimeFotmat } from "../utils-ts/GlobalDateFunctions";
import { fetchRequest, fetchRequestNode } from "../utils-ts/fetchRequest";
import { toast } from "react-toastify";
//import CustomLoader from "../components/utils/CustomElements/CustomLoader";
//import MemberDetaielsModules from "../components/BusinessCardTabs/MemberDetails/MemberDetaielsModules";
import GlobalContext from "./Global-Context";
import { getOpenDays } from "../utils-ts/BusinessOpenDaysFunc";

const parseString = require("xml2js").parseString;

const MemberDetailesProvider: React.FC = (props) => {
 // const [saveLoading, setSaveLoading] = useState<boolean>(false);
 const globalCtx = useContext(GlobalContext);

 const setSaveLoading = (loading:boolean)=>{
  globalCtx.setLoader(loading);

 };
  const [allowSyncGmb, setAllowSyncGmb] = useState<boolean>(false);
  //const [BusinessMovie, setBusinessMovie] = useState<string | undefined>();
  const [GeneralInfo, setGeneralInfo] = useState<GeneralInfo>();
  const [BusinessAddress, setBusinessAddress] = useState<BusinessAddress>();
  const [AdvCategories, setAdvCategories] = useState<
    AdvCategories[] | undefined
  >();
 
  
  const [ContentStatus, setContentStatus] = useState<ContentStatus>();
  const [
    ContentStatusPercentage,
    setContentStatusPercentage,
  ] = useState<number>();
  const [OpenDays, setOpenDays] = useState<OpenDays[] | undefined>();
  const [isPublicationDays, setIsPublicationDays] = useState<boolean>(false);
  const [PublicationDays, setPublicationDays] = useState<
    PublicationDays[] | undefined
  >();
  const [publicationDaysType, setPublicationDaysType] = useState<number>(0);
  const [fullFriday, setFullFriday] = useState<boolean>(true);
  const [halfFriday, setHalfFriday] = useState<boolean>(false);
  const [fullSaturday, setFullSaturday] = useState<boolean>(true);


  const [BusinessNews, setBusinessNews] = useState<BusinessNewsItem[] | undefined>();
  const [BusinessCertificates, setBusinessCertificates] = useState<BusinessCertificateItem[] | undefined>();
  const [CertificateIssuerTypes, setCertificateIssuerTypes] = useState<CertificateIssuerType[] | undefined>();
  

  const [Description, setDescription] = useState("");
  const [DaysRemarks, setDaysRemarks] = useState("");
  const [BusinessLinks, setBusinessLinks] = useState<
    BusinessLink[] | undefined
  >();
  const [BusinessSeoDetails, setBusinessSeoDetails] = useState<
  BusinessSeoDetailsItem[] | undefined
  >();
  const [SeoDetails, setSeoDetails] = useState<
  SeoDetailsItem[] | undefined
  >();
  const [
    BusinessQuestionsAndAnswers,
    setBusinessQuestionsAndAnswers,
  ] = useState<QuestionsAndAnswers[] | undefined>();
  const [BusinessMovies, setBusinessMovies] = useState<
    BusinessMovie[] | undefined
  >();

  const [BusinessEvents, setBusinessEvents] = useState<
    BusinessEvent[] | undefined
  >();
  const [
    BusinessLanguages,
    setBusinessLanguages,
  ] = useState<BusinessLanguages>();
  const [BusinessLogo, setBusinessLogo] = useState<string>();
  const [FirstLogin, setFirstLogin] = useState<boolean>();

  const [ExtraDetailes, setExtraDetailes] = useState<ExtraDetailes>();

  const [
    BusinessServiceArea,
    setBusinessServiceArea,
  ] = useState<BusinessServiceAreaObj>();
  const [
    showProductGalleryTypes,
    setShowProductGalleryTypes,
  ] = useState<boolean>(false);
  const [newProducts, setNewProducts] = useState<boolean>(false);
  const [isAutoLogo, setIsAutoLogo] = useState<boolean>(false);

  const [priceLists, setPriceLists] = useState<boolean>(false);
  const [showNews, setShowNews] = useState<boolean>(false);
  const [showSeoBrands,setShowSeoBrands] = useState<boolean>(false);
  const [isMemberMultiCard, setIsMemberMultiCard] = useState<boolean>(false);
  const [multiCardsMembers, setMultiCardsMembers] = useState<MultiCardsMember[] | undefined>();

  
  
  
  const [manageProductGallery, setManageProductGallery] = useState<boolean>(
    false
  );
  const [showSelfSale, setShowSelfSale] = useState<boolean>(false);
  const [showSelfSaleAdds, setShowSelfSaleAdds] = useState<boolean>(false);
  const [showSelfSaleGoogle, setShowSelfSaleGoogle] = useState<boolean>(false);

  
  const [
    productGalleryType,
    setProductGalleryType,
  ] = useState<productGalleryTypes>("default");

  function SetDtlPushEvtInner(user_action: string) {
    SetDtlPushEvt(
      "details_update",
      user_action,
      "" + (GeneralInfo ? GeneralInfo?.CompanyName : ""),
      GetMemberAdvCategories(AdvCategories),
      BusinessAddress?.City
    );
  }

  // initFunctions
  const initExtraDetailes = useCallback(async () => {
    var data = new URLSearchParams({}).toString();
    fetchRequest(
      `${config.url.API_URL}/Client.asmx/GetExtraDetails`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const ExtraDetailsData = JSON.parse(res.Response);
              setExtraDetailes(ExtraDetailsData);
            }
          }
        });
      })
      .catch((error) => {});
  }, []);

  const initBusinessLinksContext = useCallback(async () => {
    var data = new URLSearchParams({}).toString();
    fetchRequest(
      `${config.url.API_URL}/Client.asmx/GetBusinessLinks`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const LinksData = JSON.parse(res.Response);
              LinksData.map((c: BusinessLink) => {
                c.isDelete = false;
              });
              setBusinessLinks(LinksData);
            }
          }
        });
      })
      .catch(function (error) {});
  }, []);


  
  const initBusinessSeoDetailsContext = useCallback(async () => {
    var data = new URLSearchParams({}).toString();
    fetchRequest(
      `${config.url.API_URL}/ClientSeo.asmx/GetMemberSeoAddDetails`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const seoData = JSON.parse(res.Response);
              setBusinessSeoDetails(seoData);
            }
          }
        });
      })
      .catch(function (error) {});
  }, []);

  const initSeoDetailsContext = useCallback(async () => {
    var data = new URLSearchParams({}).toString();
    fetchRequest(
      `${config.url.API_URL}/ClientSeo.asmx/GetSeoAddDetails`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            //console.log(res);
            if (res.Success) {
              const seoData = JSON.parse(res.Response);
              setSeoDetails(seoData);
            }
          }
        });
      })
      .catch(function (error) {});
  }, []);

  const initBusinessQuestionsAndAnswerContext = useCallback(async () => {
    var data = new URLSearchParams({}).toString();
    fetchRequest(
      `${config.url.API_URL}/ClientFAQ.asmx/GetMemberFAQ`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const questionData = JSON.parse(res.Response);
              setBusinessQuestionsAndAnswers(questionData);
            }
          }
        });
      })
      .catch(function (error) {});
  }, []);

  const initContentStatusContext = useCallback(async () => {
    var data = new URLSearchParams({}).toString();
    fetchRequest(
      `${config.url.API_URL}/Client.asmx/GetContentStatus`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const ContentStatusData = JSON.parse(res.Response);
              let Percentage = 0;
              ContentStatusData.ContentStatus.map((c: ContentStatus) => {
                if (c.isExist) {
                  Percentage = Percentage + c.weight;
                }
              });
              setContentStatus(ContentStatusData);
              setContentStatusPercentage(Percentage);
            }
          }
        });
      })
      .catch(function (error) {});
  }, []);

  const initBusinessEventsContext = useCallback(async () => {
    var data = new URLSearchParams({}).toString();
    fetchRequest(
      `${config.url.API_URL}/ClientEvents.asmx/GetBusinessEvents`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const EventsData = JSON.parse(res.Response);
              EventsData.map((e: BusinessEvent, index: number) => {
                e.isDelete = false;
                e.StartHour = getTimeFotmat(
                  EventsData[index].StartHour.Hours,
                  EventsData[index].StartHour.Minutes
                );
                e.EndHour = getTimeFotmat(
                  EventsData[index].EndHour.Hours,
                  EventsData[index].EndHour.Minutes
                );
              });
              setBusinessEvents(EventsData);
            }
          }
        });
      })
      .catch(function (error) {});
  }, []);

  const initMovieContext = useCallback(async () => {
    var data = new URLSearchParams({}).toString();
    const ws_path =
      config.url.API_URL +
      `/ClientMovies.asmx/GetMemberMovies`;
    fetchRequest(ws_path, "post", data)
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const movieData = JSON.parse(res.Response);
             
                setBusinessMovies(movieData);
             
            }
          }
        });
      })
      .catch(function (error) {});
  }, []);

  const initMemberDetailsContext = useCallback(async (isSelfReg?: boolean) => {
    var data = new URLSearchParams({}).toString();

    fetchRequest(
      `${config.url.API_URL}/Client.asmx/GetMainDetails`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const fullMemberDetailes = JSON.parse(res.Response);
              let memberUrl = "";
              if (fullMemberDetailes.BuisenessPageUrl) {
                if (fullMemberDetailes.BuisenessPageUrl.startsWith("//")) {
                  memberUrl = "https:" + fullMemberDetailes.BuisenessPageUrl;
                } else {
                  memberUrl = fullMemberDetailes.BuisenessPageUrl;
                }
              }
              const generalInfo = {
                CompanyName: fullMemberDetailes.CompanyName
                  ? fullMemberDetailes.CompanyName
                  : "",
                EcommerceSite: fullMemberDetailes.EcommerceSite
                  ? fullMemberDetailes.EcommerceSite
                  : "",
                Email: fullMemberDetailes.Email ? fullMemberDetailes.Email : "",
                Facebook: fullMemberDetailes.Facebook
                  ? fullMemberDetailes.Facebook
                  : "",
                FacebookId: fullMemberDetailes.FacebookId
                  ? fullMemberDetailes.FacebookId
                  : "",
                Fax: fullMemberDetailes.Fax ? fullMemberDetailes.Fax : "",
                Instagram: fullMemberDetailes.Instagram
                  ? fullMemberDetailes.Instagram
                  : "",
                LandingPage: fullMemberDetailes.LandingPage
                  ? fullMemberDetailes.LandingPage
                  : "",
                Mobile: fullMemberDetailes.Mobile
                  ? fullMemberDetailes.Mobile
                  : "",
                Phone1: fullMemberDetailes.Phone1
                  ? fullMemberDetailes.Phone1
                  : "",
                VirtualNumber:fullMemberDetailes.VirtualNumber
                  ? fullMemberDetailes.VirtualNumber
                  : "",
                WebSite: fullMemberDetailes.WebSite
                  ? fullMemberDetailes.WebSite
                  : "",
                BusinessExpert: fullMemberDetailes.BusinessExpert
                  ? fullMemberDetailes.BusinessExpert
                  : "",
                BuisenessPageUrl: memberUrl,
                YoutubeChannel: fullMemberDetailes.YoutubeChannel
                  ? fullMemberDetailes.YoutubeChannel
                  : "",
                  TikTok: fullMemberDetailes.TikTok
                  ? fullMemberDetailes.TikTok
                  : "",
                Wolt: fullMemberDetailes.Wolt
                  ? fullMemberDetailes.Wolt
                  : "",
                  SeoBrands: fullMemberDetailes.SeoBrands
                  ? fullMemberDetailes.SeoBrands
                  : "",
              };
              const desc = fullMemberDetailes.Description
                ? fullMemberDetailes.Description
                : "";
              const _daysRemarks = fullMemberDetailes.DaysRemarks
                ? fullMemberDetailes.DaysRemarks
                : "";
              const address = fullMemberDetailes.BusinessAddress
                ? fullMemberDetailes.BusinessAddress
                : "";
              const advCat = fullMemberDetailes.advCategories
                ? fullMemberDetailes.advCategories
                : "";
              const _opendays = fullMemberDetailes.openDays
                ? fullMemberDetailes.openDays.sort(sortOpenDays)
                : [];
              const _isPublicationDays = fullMemberDetailes.isPublicationDays
                ? fullMemberDetailes.isPublicationDays
                : false;

              const _publicationDaysType = fullMemberDetailes.publicationDaysType
                ? fullMemberDetailes.publicationDaysType
                : 0;
              const _fullFriday = fullMemberDetailes.fullFriday
                ? fullMemberDetailes.fullFriday
                : true;
              const _halfFriday = fullMemberDetailes.halfFriday
                ? fullMemberDetailes.halfFriday
                : false;
              const _fullSaturday = fullMemberDetailes.fullSaturday
                ? fullMemberDetailes.fullSaturday
                : true;
              const _publicationDays = fullMemberDetailes.publicationDays
                ? fullMemberDetailes.publicationDays
                : [];
              setBusinessAddress(address);
              setDescription(desc);
              setDaysRemarks(_daysRemarks);
              setAdvCategories(advCat);
              setGeneralInfo(generalInfo);
              setOpenDays(_opendays);
              setIsPublicationDays(_isPublicationDays);
              setPublicationDays(_publicationDays);
              setPublicationDaysType(_publicationDaysType);
              setFullFriday(_fullFriday);
              setHalfFriday(_halfFriday);
              setFullSaturday(_fullSaturday);
              setAllowSyncGmb(fullMemberDetailes.AllowSyncGmb == true);
              setShowProductGalleryTypes(
                fullMemberDetailes.ShowProductGalleryTypes
              );
              setNewProducts(fullMemberDetailes.NewProducts);
              setIsAutoLogo(fullMemberDetailes.IsAutoLogo);
              setPriceLists(fullMemberDetailes.PriceLists);
              setShowNews(fullMemberDetailes.ShowNews);
              setShowSeoBrands(fullMemberDetailes.ShowSeoBrands);
              setManageProductGallery(fullMemberDetailes.ManageProductGallery);
              setProductGalleryType(fullMemberDetailes.ProductGalleryType);
              setIsMemberMultiCard(fullMemberDetailes.IsMemberMultiCard);
              setMultiCardsMembers(fullMemberDetailes.MultiCardsMembers);
              
              if (fullMemberDetailes.BusinessLanguages) {
                setBusinessLanguages(fullMemberDetailes.BusinessLanguages);
              } else {
                const newLang: BusinessLanguages = {
                  Amharic: false,
                  Arabic: false,
                  Chinese: false,
                  English: false,
                  France: false,
                  German: false,
                  Hebrew: false,
                  Italian: false,
                  Portuguese: false,
                  Romanian: false,
                  Russian: false,
                  Spanish: false,
                  Bulgarian: false,
                  Hungarian: false,
                  Turkish: false,
                  Polish: false,
                  Ukrainian: false,
                  Japanese: false,
                  Yiddish: false,
                };
                setBusinessLanguages(newLang);
              }
            }
          }
        });
      })
      .catch(function (error) {});
    if (isSelfReg == false || !!config.otef_israel) {
      initServiceAreasContext();
    }
  }, []);

  const initFirstLogin = () => {
    setFirstLogin(true);
  };
  const initBusinessLogoContext = useCallback(async () => {
    var data = new URLSearchParams({}).toString();
    fetchRequest(`${config.url.API_URL}/ClientLogo.asmx/GetLogo`, "post", data)
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const logo = res.Response != undefined ? res.Response : "";
              setBusinessLogo(logo);
            } else {
              setBusinessLogo("");
            }
          }
        });
      })
      .catch(function (error) {});
  }, []);
  const initSelfSale = useCallback(async () => {
   
    var data = new URLSearchParams({}).toString();
    fetchRequestNode(`${config.url.Node_API_URL}/selfSale/getSelfSaleSettings`, "post", data)
      .then((response:any) => {
        var res = JSON.parse(response);
        //console.log("SELF --> " + response);
        if (res.message === "") {
          setShowSelfSale(res.selfSaleEnabled);
          setShowSelfSaleAdds(res.mailingAccepted);
          setShowSelfSaleGoogle(res.showGoogle);

        }
      })
      .catch(function (error) {});
   
  }, []);
  //SERVICE AREA
  const initServiceAreasContext = () => {
    return new Promise((resolve) => {
      var data = new URLSearchParams({}).toString();

      fetchRequestNode(
        `${config.url.Node_API_URL}/selfService/getMemberServiceAreaSettings`,
        "post",
        data
      )
        .then((response: any) => {
          var res = JSON.parse(response);
          
          if (res && res.success) {
            const area_type =
              res.areaType == 1
                ? "city"
                : res.areaType == 2
                ? "region"
                : "country";
            const serviceArea: BusinessServiceAreaObj = {
              ServiceAreaType: area_type,
              MemberCity: res.memberCity,
              ServiceAreasList: res.serviceAreas,
              DoDelivery: res.doDelivery,
              HasDelivery: res.hasDelivery,
            };
            setBusinessServiceArea(serviceArea);
            resolve(serviceArea);
          } else {
            toast.error("ארעה שגיאה");
            resolve(null);
          }
        })
        .catch(function (error) {
          console.log(error);
          toast.error("ארעה שגיאה");
          resolve(null);
        });
    });
    
  };

  // save Functions
  const saveMovieHandler = async (
    currMovie: BusinessMovie,
    action: "save" | "delete"
  ) => {
    setSaveLoading(true);
    
    var data = new URLSearchParams({
      jsDetails: JSON.stringify(
        currMovie 
      ),
    }).toString();

    const ws_path =
      config.url.API_URL +
      `/ClientMovies.asmx/${action}MemberMovie`;
    fetchRequest(ws_path, "post", data)
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
             
                if (action == "save") {
                  if (res.Response != "") {
                    setBusinessMovies(JSON.parse(res.Response));
                  }
                } else {
                  //remove from movies
                  setBusinessMovies((currMovies) => {
                    let updatedList = currMovies ? [...currMovies] : [];
                    updatedList = updatedList.filter(
                      (i) => i.Id != currMovie.Id
                    );
                    return updatedList;
                  });
                }
              

              SetDtlPushEvtInner("updates_MovieProduction");

              toast.success("נשמר בהצלחה");
            } else {
              dtlPushEvt({
                event: "errors",
                eventInfo: {
                  ScreenName: "{{page path}}",
                  user_action: "got_error__MovieProduction",
                },
              });
              toast.error(res.ErrorMessage);
            }
          }
          setSaveLoading(false);
        });
      })
      .catch(function (error) {
        setSaveLoading(false);
        console.log("Request failed", error);
      });
  };
  const saveMovieData = async (
    currMovie: BusinessMovie
  ) => {
    return new Promise((resolve) => {
    setSaveLoading(true);
    
    var data = new URLSearchParams({
      jsDetails: JSON.stringify(
        currMovie 
      ),
    }).toString();

    const ws_path =
      config.url.API_URL +
      `/ClientMovies.asmx/saveMemberMovie`;
    fetchRequest(ws_path, "post", data)
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
             
                
                  if (res.Response != "") {
                    setBusinessMovies(JSON.parse(res.Response));
                  }
                
              

              SetDtlPushEvtInner("updates_MovieProduction");

              toast.success("נשמר בהצלחה");
              resolve(true);
            } else {
              dtlPushEvt({
                event: "errors",
                eventInfo: {
                  ScreenName: "{{page path}}",
                  user_action: "got_error__MovieProduction",
                },
              });
              toast.error(res.ErrorMessage);
              resolve(false);
            }
          }else{
            resolve(false);
          }
          setSaveLoading(false);
        });
      })
      .catch(function (error) {
        setSaveLoading(false);
        console.log("Request failed", error);
        resolve(false);
      });
    });
  };
const getMovieData = async (movieType: string,url:string) => {
  return new Promise((resolve) => {
  var data = new URLSearchParams({movieType, url}).toString();
  const ws_path =
    config.url.API_URL +
    `/ClientMovies.asmx/getMovieData`;
  fetchRequest(ws_path, "post", data)
    .then((response) => {
      parseString(response, (err: any, result: any) => {
        
        if (result) {
          var res = JSON.parse(result.string["_"]);
          if (res.Success) {
            const movieData = res.Response;
           
            resolve(movieData);
           
          }
        }
      });
    })
    .catch(function (error) {});
  });
};

  const savePublicationDaysHandler = async (
    publicationDays: PublicationDays[] | undefined,
    templateID: number | undefined,
    fullFriday: boolean,
    halfFriday: boolean,
    fullSaturday: boolean
  ) => {
    setSaveLoading(true);
    var data = new URLSearchParams({
      jsDetails: JSON.stringify({
        publicationDays,
        templateID,
        fullFriday,
        halfFriday,
        fullSaturday,
      }),
    }).toString();
    fetchRequest(
      `${config.url.API_URL}/Client.asmx/updatePublicationDays`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const PublicationDaysData = JSON.parse(res.Response);
              PublicationDaysData.map((c: PublicationDays) => {
                c.isDelete = false;
              });
              setPublicationDays(PublicationDaysData);
              SetDtlPushEvtInner("updates_PublicationDays");

              toast.success("נשמר בהצלחה");
            } else {
              dtlPushEvt({
                event: "errors",
                eventInfo: {
                  ScreenName: "{{page path}}",
                  user_action: "got_error_PublicationDays",
                },
              });
              toast.error(res.ErrorMessage);
            }
          }
          setSaveLoading(false);
        });
      })
      .catch(function (error) {
        console.log("Request failed", error);
        setSaveLoading(false);
      });
  };
  const saveLinksHandler = async (link: BusinessLink) => {
    setSaveLoading(true);
    var data = new URLSearchParams({
      jsDetails: JSON.stringify(link),
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/Client.asmx/updateBusinessLink`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const LinksData = JSON.parse(res.Response);
              LinksData.map((c: BusinessLink) => {
                c.isDelete = false;
              });
              setBusinessLinks(LinksData);

              SetDtlPushEvtInner("updates_BusinessLink");

              toast.success("נשמר בהצלחה");
            } else {
              dtlPushEvt({
                event: "errors",
                eventInfo: {
                  ScreenName: "{{page path}}",
                  user_action: "got_error_BusinessLink",
                },
              });
              toast.error(res.ErrorMessage);
            }
          }
          setSaveLoading(false);
        });
      })
      .catch(function (error) {
        console.log("Request failed", error);
        setSaveLoading(false);
      });
  };

  const sortQuestionAnswerHandler = async (
    QuestionsAndAnswersArray: QuestionsAndAnswers[],
    status: "start" | "finish"
  ) => {
    if (status == "start") {
      var data = new URLSearchParams({
        jsDetails: JSON.stringify(QuestionsAndAnswersArray),
      }).toString();
      fetchRequest(
        `${config.url.API_URL}/ClientFAQ.asmx/updateMemberFAQsSortOrder`,
        "post",
        data
      )
        .then((response) => {
          parseString(response, (err: any, result: any) => {
            if (result) {
              var res = JSON.parse(result.string["_"]);
              if (res.Success) {
              } else {
                toast.error(res.ErrorMessage);
              }
            }
          });
        })
        .catch(function (error) {
          console.log("Request failed", error);
        });
    } else {
      setBusinessQuestionsAndAnswers(QuestionsAndAnswersArray);
    }
  };

  const saveQuestionAnswerHandler = async (
    questionAnswer: QuestionsAndAnswers,
    action: "save" | "delete"
  ) => {
    setSaveLoading(true);
    if (action == "save") {
      var data = new URLSearchParams({
        jsDetails: JSON.stringify(questionAnswer),
      }).toString();
      fetchRequest(
        `${config.url.API_URL}/ClientFAQ.asmx/saveMemberFAQ`,
        "post",
        data
      )
        .then((response) => {
          parseString(response, (err: any, result: any) => {
            if (result) {
              var res = JSON.parse(result.string["_"]);
              if (res.Success) {
                const nerData = JSON.parse(res.Response);
                if (nerData != "") {
                  setBusinessQuestionsAndAnswers(nerData);

                  SetDtlPushEvtInner("updates_MemberFAQ");

                  toast.success("נשמר בהצלחה");
                }
              } else {
                dtlPushEvt({
                  event: "errors",
                  eventInfo: {
                    ScreenName: "{{page path}}",
                    user_action: "got_error_MemberFAQ",
                  },
                });
                toast.error(res.ErrorMessage);
              }
            }
            setSaveLoading(false);
          });
        })
        .catch(function (error) {
          console.log("Request failed", error);
          setSaveLoading(false);
        });
    } else if (action == "delete") {
      var data = new URLSearchParams({
        jsDetails: JSON.stringify(questionAnswer),
      }).toString();
      fetchRequest(
        `${config.url.API_URL}/ClientFAQ.asmx/deleteMemberFAQ`,
        "post",
        data
      )
        .then((response) => {
          parseString(response, (err: any, result: any) => {
            if (result) {
              var res = JSON.parse(result.string["_"]);
              if (result) {
                var res = JSON.parse(result.string["_"]);
                if (res.Success) {
                  const newData = JSON.parse(res.Response);
                  setBusinessQuestionsAndAnswers(newData);
                } else {
                  toast.error(res.ErrorMessage);
                }
              }
            }
            setSaveLoading(false);
          });
        })
        .catch(function (error) {
          console.log("Request failed", error);
          setSaveLoading(false);
        });
    }
  };

  const saveEventsHandler = async (event: BusinessEvent) => {
    setSaveLoading(true);
    var data = new URLSearchParams({
      jsDetails: JSON.stringify(event),
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientEvents.asmx/SaveBusinessEvents`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const EventsData = JSON.parse(res.Response);
              EventsData.map((e: BusinessEvent, index: number) => {
                e.isDelete = false;
                e.StartHour = getTimeFotmat(
                  EventsData[index].StartHour.Hours,
                  EventsData[index].StartHour.Minutes
                );
                e.EndHour = getTimeFotmat(
                  EventsData[index].EndHour.Hours,
                  EventsData[index].EndHour.Minutes
                );
              });
              setBusinessEvents(EventsData);

              SetDtlPushEvtInner("updates_BusinessEvents");

              toast.success("נשמר בהצלחה");
            } else {
              dtlPushEvt({
                event: "errors",
                eventInfo: {
                  ScreenName: "{{page path}}",
                  user_action: "got_error_BusinessEvents",
                },
              });
              toast.error(res.ErrorMessage);
            }
          }
          setSaveLoading(false);
        });
      })
      .catch(function (error) {
        console.log("Request failed", error);
        setSaveLoading(false);
      });
  };

  const saveLogoHandler = async (photo: Photo, sync_gmb: boolean) => {
    if (photo!.preview) {
      setSaveLoading(true);
      const base64 = await base64FromPath(photo!.preview);
      var data = new URLSearchParams({
        sImage: base64,
        syncGmb: sync_gmb ? "true" : "false",
      }).toString();
      fetchRequest(
        `${config.url.API_URL}/ClientLogo.asmx/UploadLogoAndSync`,
        "post",
        data
      )
        .then((response) => {
          parseString(response, (err: any, result: any) => {
            if (result) {
              var res = JSON.parse(result.string["_"]);
              if (res.Success) {
                toast.success("הלוגו עלה בהצלחה");
                setBusinessLogo(photo!.preview);
                initContentStatusContext();
              } else {
                toast.error("ארעה שגיאה בהעלאת לוגו");
              }
            }
            setSaveLoading(false);
          });
        })
        .catch(function (error) {
          console.log("Request failed", error);
          setSaveLoading(false);
        });
    }
  };

  const saveAutoLogoHandler = async (base64: string, sync_gmb: boolean) => {
    setSaveLoading(true);
    //const base64 = await base64FromPath(photo!.preview);
    var data = new URLSearchParams({
      imageBase64: base64, //encodeURIComponent
      syncGmb: sync_gmb ? "true" : "false",
    }).toString();
    fetchRequest(
      `${config.url.API_URL}/ClientLogo.asmx/SaveAutoLogo`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              toast.success("הלוגו עלה בהצלחה");
              setBusinessLogo(base64);
              initContentStatusContext();
            } else {
              toast.error("ארעה שגיאה בהעלאת לוגו");
            }
          } else {
            toast.error("ארעה שגיאה בהעלאת לוגו אוטומטי");
          }
          setSaveLoading(false);
        });
      })
      .catch(function (error) {
        toast.error("שגיאה בהעלאת לוגו אוטומטי");
        console.log("Request failed", error);
        setSaveLoading(false);
      });
  };
  const saveServiceAreasHandler = (serviceArea: BusinessServiceAreaObj) => {
    setSaveLoading(true);

    var data = new URLSearchParams().toString();

    fetchRequestNode(
      `${config.url.Node_API_URL}/selfService/updateMemberAreaType`,
      "post",
      data
    )
      .then((response: any) => {
        setSaveLoading(false);
        setBusinessServiceArea(serviceArea);
        toast.success("not implemented");
        return;
        var res = JSON.parse(response);
        if (res.Success) {
          setBusinessServiceArea(serviceArea);

          SetDtlPushEvtInner("updates_MemberArea");

          toast.success("נשמר בהצלחה");
        } else {
          dtlPushEvt({
            event: "errors",
            eventInfo: {
              ScreenName: "{{page path}}",
              user_action: "got_error_MemberArea",
            },
          });
          toast.error(res.ErrorMessage);
        }
      })
      .catch(function (error) {
        console.log(error);
        setSaveLoading(false);
        toast.success("not implemented");
      });
  };

  const deleteLogoHandler = async (sync_gmb: boolean) => {
    setSaveLoading(true);
    var data = new URLSearchParams({
      syncGmb: sync_gmb ? "true" : "false",
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientLogo.asmx/DeleteLogoAndSync`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              toast.success("הלוגו נמחק בהצלחה");
              setBusinessLogo("");
              initContentStatusContext();
            } else {
              toast.error("ארעה שגיאה בהעלאת לוגו");
            }
          }
          setSaveLoading(false);
        });
      })
      .catch(function (error) {
        console.log("Request failed", error);
        setSaveLoading(false);
      });
  };

  const saveMainDetails = async (
    _originModal: originModal,
    _advCategories: AdvCategories[] | undefined,
    _businessLanguages: BusinessLanguages | undefined,
    //_openDays: OpenDays[] | undefined,
    //_DaysRemarks: string | undefined,
    _GeneralInfo: GeneralInfo | undefined,
    _description: string | undefined,
    _syncGmb?: boolean | undefined,
    _syncType?: string | undefined
  ) => {
    setSaveLoading(true);
    const jsDetails = {
      Description: _description,
      BusinessExpert: _GeneralInfo?.BusinessExpert,
      Fax: _GeneralInfo?.Fax,
      Facebook: _GeneralInfo?.Facebook,
      Instagram: _GeneralInfo?.Instagram,
      Wolt: _GeneralInfo?.Wolt,
      TikTok: _GeneralInfo?.TikTok,
      Phone1: _GeneralInfo?.Phone1,
      Mobile: _GeneralInfo?.Mobile,
      WebSite: _GeneralInfo?.WebSite,
      LandingPage: _GeneralInfo?.LandingPage,
      EcommerceSite: _GeneralInfo?.EcommerceSite,
      YoutubeChannel: _GeneralInfo?.YoutubeChannel,
      FacebookId: _GeneralInfo?.FacebookId,
      Email: _GeneralInfo?.Email,
      //DaysRemarks: _DaysRemarks,
      BusinessAddress: {
        //cant edit
        city: BusinessAddress?.City,
        Street: BusinessAddress?.Street,
        StreetNo: BusinessAddress?.StreetNo,
        ZipCode: BusinessAddress?.ZipCode,
      },
      BusinessLanguages: _businessLanguages,
      advCategories: _advCategories,
      //openDays: _openDays,
    };

    var data = new URLSearchParams({
      jsDetails: JSON.stringify(jsDetails),
      syncGmb: _syncGmb ? "true" : "false",
      syncType: _syncType || "",
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/Client.asmx/UpdateMainDetailsAndSync`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              if (res.Response != "") {
                const fullMemberDetailes = JSON.parse(res.Response);
                // if (_originModal === "OpenDays") {
                //   setOpenDays(fullMemberDetailes.openDays.sort(sortOpenDays));
                //   setDaysRemarks(_DaysRemarks!);
                // } else 
                if (_originModal === "AdvCategories") {
                  initMemberDetailsContext();
                  setAdvCategories(_advCategories!);
                } else if (_originModal === "GeneralInfo") {
                  setGeneralInfo(_GeneralInfo!);
                } else if (_originModal === "Description") {
                  setDescription(_description!);
                } else if (_originModal === "Languages") {
                  setBusinessLanguages(_businessLanguages!);
                }
              }

              SetDtlPushEvtInner("updates_" + _originModal);

              toast.success("נשמר בהצלחה");
              initContentStatusContext();
            } else {
              dtlPushEvt({
                event: "errors",
                eventInfo: {
                  ScreenName: "{{page path}}",
                  user_action: "got_error_" + _originModal,
                },
              });

              toast.info(res.ErrorMessage);
            }
          }
          setSaveLoading(false);
        });
      })
      .catch(function (error) {
        console.log("Request failed", error);
        setSaveLoading(false);
      });
  };
const saveMemberDeliverySettings = (doDelivery: boolean) => {
  if(!config.update_delivery){
    return;
  }
    return new Promise((resolve) => {
      
      
      var data = new URLSearchParams({
        doDelivery: doDelivery ? '1' : '0'
      }).toString();

      fetchRequestNode(
        `${config.url.Node_API_URL}/selfService/saveMemberDeliverySettings`,
        "post",
        data
      )
        .then((response: any) => {
          var res = JSON.parse(response);
          resolve(true);
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  };
  const saveOpenDays = async( _openDays: OpenDays[],_syncGmb:boolean) => {
    setSaveLoading(true);
    

    var data = new URLSearchParams({
      jsDetails: JSON.stringify(_openDays),
      syncGmb: _syncGmb ? "true" : "false",
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/Client.asmx/UpdateMemberOpenDaysAndSync`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              if (res.Response != "") {
                const updated_openDays = JSON.parse(res.Response);
                
                  setOpenDays(updated_openDays.sort(sortOpenDays));
                  //setDaysRemarks(_DaysRemarks!);
                
              }

              SetDtlPushEvtInner("updates_OpenDays");

              toast.success("נשמר בהצלחה");
              initContentStatusContext();
            } else {
              dtlPushEvt({
                event: "errors",
                eventInfo: {
                  ScreenName: "{{page path}}",
                  user_action: "got_error_OpenDays"
                },
              });

              toast.info(res.ErrorMessage);
            }
          }
          setSaveLoading(false);
        });
      })
      .catch(function (error) {
        console.log("Request failed", error);
        setSaveLoading(false);
      });
  };

const sortOpenDays = (day1: OpenDays, day2: OpenDays) => {
  const open_days_1 = getOpenDays(day1);
  const open_days_2 = getOpenDays(day2);
  if (open_days_1 < open_days_2) return -1;
  if (open_days_1 > open_days_2) return 1;

  if (day1.FromHour! < day2.FromHour!) return -1;
  if (day1.FromHour! > day2.FromHour!) return 1;

  else return 0;
};

  const syncMemberGmb = async (isOpenHoursOnly: boolean) => {
    setSaveLoading(true);
    var data = new URLSearchParams({
      isOpenHoursOnly: isOpenHoursOnly == true ? "true" : "false",
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/Client.asmx/SyncMemberGmb`,
      "post",
      data
    )
      .then((response) => {
        setSaveLoading(false);
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              toast.success("סונכרן בהצלחה");
            } else {
              toast.info(res.ErrorMessage);
            }
          }
        });
      })
      .catch(function (error) {
        console.log("Request failed", error);
        setSaveLoading(false);
      });
  };

  const updateProductsGalleryType = async (
    galleryType: productGalleryTypes
  ) => {
    setSaveLoading(true);
    var data = new URLSearchParams({
      newGalleryType: galleryType,
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/Client.asmx/UpdateProductsGalleryType`,
      "post",
      data
    )
      .then((response) => {
        setSaveLoading(false);
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              toast.success("סוג גלריית מוצרים עודכן בהצלחה");
            } else {
              toast.error("ארעה שגיאה בעדכון סוג גלריית מוצרים");
            }
          }
          setProductGalleryType(galleryType);
        });
      })
      .catch(function (error) {
        setSaveLoading(false);
        console.log("Request failed", error);
      });
  };


  const getBusinessNews= useCallback(async (useLoading: boolean) => {
    useLoading && setSaveLoading(useLoading);
    var data = new URLSearchParams({}).toString();
   
    fetchRequest(
      `${config.url.API_URL}/ClientNews.asmx/getMemberNews`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const response = JSON.parse(res.Response);
              setBusinessNews(response);
             
              useLoading && setSaveLoading(false);
            }
          }
        });
      })
      .catch(function (error) {
        console.log("Request failed", error);
        useLoading && setSaveLoading(false);
      });
  }, []);
  const saveBusinessNews= (item:BusinessNewsItem)  =>  {

    setSaveLoading(true);
    var data = new URLSearchParams({
      jsDetails: JSON.stringify(item),
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientNews.asmx/saveMemberNews`,
      "post",
      data
    )
      .then((response) => {
        setSaveLoading(false);
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              getBusinessNews(false);
            
              toast.success("המוצר עודכן בהצלחה");
            } else {
              toast.error("ארעה שגיאה בעדכון מוצר");
            }
          }else {
          getBusinessNews(true);
        }
        });
      })
      .catch(function (error) {
        setSaveLoading(false);
        getBusinessNews(true);
        console.log("Request failed", error);
      });
  };
  const deleteBusinessNews= (id:number)  =>  {
    setSaveLoading(true);
    var data = new URLSearchParams({
      id: id.toString(),
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientNews.asmx/deleteMemberNews`,
      "post",
      data
    )
      .then((response) => {
        setSaveLoading(false);
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              toast.success("חדשות נמחקו בהצלחה");
              getBusinessNews(true);
            } else {
              toast.error("ארעה שגיאה במחיקת חדשות");
            }
          }
        });
      })
      .catch(function (error) {
        setSaveLoading(false);
        console.log("Request failed", error);
      });
  };

  
  const getBusinessCertificates= useCallback(async (useLoading: boolean) => {
    useLoading && setSaveLoading(useLoading);
    var data = new URLSearchParams({}).toString();
   
    fetchRequest(
      `${config.url.API_URL}/ClientCertificates.asmx/getMemberCertificates`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const response = JSON.parse(res.Response);
              setBusinessCertificates(response.MemberCertificates);

              setCertificateIssuerTypes( response.IssuerTypes);
             
              useLoading && setSaveLoading(false);
            }
          }
        });
      })
      .catch(function (error) {
        console.log("Request failed", error);
        useLoading && setSaveLoading(false);
      });
  }, []);
  const saveBusinessCertificate= (item:BusinessCertificateItem)  =>  {

    setSaveLoading(true);
    var data = new URLSearchParams({
      jsDetails: JSON.stringify(item),
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientCertificates.asmx/saveMemberCertificate`,
      "post",
      data
    )
      .then((response) => {
        setSaveLoading(false);
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              getBusinessCertificates(false);
            
              toast.success("תעודה עודכנה בהצלחה");
            } else {
              toast.error("ארעה שגיאה בעדכון תעודה");
            }
          } else{
          getBusinessCertificates(true);}
        });
      })
      .catch(function (error) {
        setSaveLoading(false);
        getBusinessCertificates(true);
        console.log("Request failed", error);
      });
  };
  const deleteBusinessCertificate= (id:number)  =>  {
    setSaveLoading(true);
    var data = new URLSearchParams({
      id: id.toString(),
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientCertificates.asmx/deleteMemberCertificate`,
      "post",
      data
    )
      .then((response) => {
        setSaveLoading(false);
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              toast.success("הסמכות נמחקו בהצלחה");
              getBusinessCertificates(true);
            } else {
              toast.error("ארעה שגיאה במחיקת הסמכות");
            }
          }
        });
      })
      .catch(function (error) {
        setSaveLoading(false);
        console.log("Request failed", error);
      });
  };
  
        

  const saveSeoDetailsHandler = async (item: BusinessSeoDetailsItem) => {
    setSaveLoading(true);
    var data = new URLSearchParams({
      jsDetails: JSON.stringify(item),
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientSeo.asmx/saveMemberSeoAddDetails`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const seoData = JSON.parse(res.Response);
             
              setBusinessSeoDetails(seoData);

              SetDtlPushEvtInner("updated_BusinessSeo");

              toast.success("נשמר בהצלחה");
            } else {
              dtlPushEvt({
                event: "errors",
                eventInfo: {
                  ScreenName: "{{page path}}",
                  user_action: "got_error_BusinessSeo",
                },
              });
              toast.error(res.ErrorMessage);
            }
          }
          setSaveLoading(false);
        });
      })
      .catch(function (error) {
        console.log("Request failed", error);
        setSaveLoading(false);
      });
  };

  const updateMemberSeoBrandsHandler= async (items: BusinessSeoDetailsItem[]) => {
    setSaveLoading(true);
    var data = new URLSearchParams({
      jsDetails: JSON.stringify(items),
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientSeo.asmx/updateMemberSeoBrands`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const seoData = JSON.parse(res.Response);
             
              setBusinessSeoDetails(seoData);
              setGeneralInfo((current) => {
                let updated = current;
                if(updated){
                updated.SeoBrands = seoData.filter((b:BusinessSeoDetailsItem) => b.Type == 4).map((b:BusinessSeoDetailsItem) => b.Text).join(', ');
                }
                return updated;

              });

              SetDtlPushEvtInner("updated_SeoBrands");

              toast.success("נשמר בהצלחה");
            } else {
              dtlPushEvt({
                event: "errors",
                eventInfo: {
                  ScreenName: "{{page path}}",
                  user_action: "got_error_SeoBrands",
                },
              });
              toast.error(res.ErrorMessage);
            }
          }
          setSaveLoading(false);
        });
      })
      .catch(function (error) {
        console.log("Request failed", error);
        setSaveLoading(false);
      });
  };
const getMemberOpenHoursTemplate = ()=>{

  const currentOpenDays = OpenDays ? OpenDays.filter((o) => o.IsDeleted === false) : [];
  if (currentOpenDays.length > 0) {
    if (currentOpenDays.length == 1) {
      //if only one item possible 24/7 or 24/5 / or NeedToPhone
      const curr = currentOpenDays[0];
      if (curr.FromHour == 7 && curr.FromHourMinutes == 0 && curr.ToHour == 7 && curr.ToHourMinutes == 0) {
        if (curr.Day1 && curr.Day2 && curr.Day3 && curr.Day4 && curr.Day5) {
          if (curr.Day6 && curr.Day7 && curr.Is24x7) {
            if (curr.IsNeedToPhone) {
              return 7;
            } else {
              return 5;
            }
          } else {
            return 8;
          }
        }
      }
    }
  } else {
    //list empty
    //template not selected
    return undefined;
  }
  return 9; //custom

};
  return (
    <MemberDetailes.Provider
      value={{
        ContentStatus,
        ExtraDetailes,
        ContentStatusPercentage,
        //BusinessMovie,
        BusinessEvents,
        BusinessLinks,
        BusinessSeoDetails,
        SeoDetails,
        BusinessQuestionsAndAnswers,
        BusinessMovies,
        Description,
        DaysRemarks,
        AdvCategories,
        OpenDays,
        isPublicationDays,
        PublicationDays,
        publicationDaysType,
        fullFriday,
        halfFriday,
        fullSaturday,
        BusinessNews,
        BusinessCertificates,
        CertificateIssuerTypes,
        allowSyncGmb,
        GeneralInfo,
        BusinessLanguages,
        BusinessAddress,
        BusinessLogo,
        BusinessServiceArea,
        ShowProductGalleryTypes: showProductGalleryTypes,
        NewProducts: newProducts,
        IsAutoLogo: isAutoLogo,
        PriceLists: priceLists,
        ShowNews: showNews,
        ShowSeoBrands: showSeoBrands,
        ManageProductGallery: manageProductGallery,
        ProductGalleryType: productGalleryType,
        FirstLogin,
        ShowSelfSale: showSelfSale,
        ShowSelfSaleAdds: showSelfSaleAdds,
        ShowSelfSaleGoogle: showSelfSaleGoogle,
        IsMemberMultiCard:isMemberMultiCard,
        MultiCardsMembers: multiCardsMembers,
        saveMainDetails,
        saveMovieHandler,
        saveMovieData,
        //getMovieData,
        saveLinksHandler,
        saveSeoDetailsHandler,
        updateMemberSeoBrandsHandler,
        savePublicationDaysHandler,
        saveQuestionAnswerHandler,
        sortQuestionAnswerHandler,
        saveEventsHandler,
        saveLogoHandler,
        saveAutoLogoHandler,
        saveServiceAreasHandler,
        saveOpenDays,
        deleteLogoHandler,
        initBusinessEventsContext,
        initMovieContext,
        initBusinessLinksContext,
        initBusinessSeoDetailsContext,
        initSeoDetailsContext,
        initBusinessQuestionsAndAnswerContext,
        initBusinessLogoContext,
        initMemberDetailsContext,
        initContentStatusContext,
        initExtraDetailes,
        initFirstLogin,
        initServiceAreasContext,
        syncMemberGmb,
        updateProductsGalleryType,
        getBusinessNews,
        saveBusinessNews,
        deleteBusinessNews,
        getBusinessCertificates,
        saveBusinessCertificate,
        deleteBusinessCertificate,
        initSelfSale,
        getMemberOpenHoursTemplate,
        saveMemberDeliverySettings
      }}
    >
      {props.children}

      {/* <CustomLoader
        isOpen={saveLoading}
        duration={5000}
        onDidDismiss={() => {
          setSaveLoading(false);
        }}
      ></CustomLoader> */}
    </MemberDetailes.Provider>
  );
};

export default MemberDetailesProvider;
