import React from "react";

export default function XIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          d="M8 15.997c-4.411 0-8-3.588-8-7.999C0 3.588 3.589 0 8 0s8 3.588 8 7.998c0 4.411-3.589 8-8 8"
        ></path>
        <path
          fill="currentColor"
          d="M4.057 12.8a.856.856 0 01-.606-1.462l7.887-7.887a.856.856 0 111.211 1.211L4.662 12.55a.854.854 0 01-.605.251"
        ></path>
        <path
          fill="currentColor"
          d="M11.943 12.8a.854.854 0 01-.605-.25L3.45 4.661A.856.856 0 114.661 3.45l7.887 7.887a.856.856 0 01-.606 1.462"
        ></path>
      </g>
    </svg>
  );
}
