import {
  IonCol,
  IonGrid,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonRow
} from "@ionic/react";
import "./memberInbox.scss";
import React, { useContext, useEffect, useRef, useState } from "react";
import MemberInboxMessage from "../components/MemberInboxMessage";
import DropDownFilter, {
  filterOptions,
} from "../components/utils/Filters/DropDownFilter";
import CustomMultiChoice, {
  ChooseAll,
  SetMultiSelectListner,
} from "../components/utils/CustomElements/CustomMultiChoice";
import MemberInboxContext, { messageObj } from "../data/MemberInbox-Context";
import { convertTimeStampToDate,monthNames } from "../utils-ts/GlobalDateFunctions";
import { ClearHtmlFromString } from "../utils-ts/GlobalStringClearFunction";

import { HeaderParameters } from "../data/Header-Context";
import { optionButtons } from "../components/utils/CustomElements/CustomeMoreOptions";
import CustomPage from "../components/utils/CustomElements/CustomPage";
import { useParams } from "react-router";
import CustomHeaddingItem from "../components/utils/CustomElements/CustomPageTitle/CustomHeaddingItem";
import {
  inboxWhite,
  blankProductIconRed,
  mailOpenBlue,
  logoOrangeHollow,
} from "../images/index";

import MemberDetailsContext from "../data/MemberDetailes-context"
import moment from "moment";


const MemberInbox: React.FC = () => {
  const MemberInboxCxt = useContext(MemberInboxContext);
  const [selectedMessage, setSelectedMessage] = useState<messageObj | undefined>();
  const [isShowMessage, setIsShowMessage] = useState(false);
  const [isMultiChoise, setIsMultiChoise] = useState(false);
  const [isMarkAllAsRead, setIsMarkAllAsRead] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedItemsArray, setSelectedItemsArray] = useState<string[]>([]);
  const [groupedMessage, setGroupedMessage] = useState<{messages: messageObj[],total:number,title:string}[]>();

  const IonInfiniteScrollRef = useRef<HTMLIonInfiniteScrollElement>(null);
  
  const MemberDetailsCxt = useContext(MemberDetailsContext);
  // since we compare to url set value lowerCase
  const filterOptions: filterOptions[] = [
    {
      title: "כל הסטטוסים",
      value: "all",
    },
    {
      title: "נקראו",
      value: "read",
    },
    {
      title: "לא נקראו",
      value: "notread",
    },
  ];
  const selectedFilterName = useParams<{ filterName: string }>().filterName;
  const platformType = useParams<{ platformType: string }>().platformType; //mail or sms
  const messageId = useParams<{ messageId: string }>().messageId;
  //"Read" | NotRead | All
  const [messageFilter, setMessageFilter] = useState<string>(() => {
    let res = "all";
    if (selectedFilterName != undefined && selectedFilterName != "") {
      res = selectedFilterName.toLowerCase();
    }
    return res;
  });
  const [filterIndex] = useState(() => {
    let filterIndex = filterOptions.findIndex((f) => f.value === "All");
    if (messageFilter != undefined && messageFilter != "") {
      const checkFilterIndex = filterOptions.findIndex(
        (f) => f.value!.toLowerCase() === messageFilter.toLowerCase()
      );
      if (checkFilterIndex > -1) {
        filterIndex = checkFilterIndex;
      }
    }
    return filterIndex;
  });
  const [monthSelectedFilter, setMonthSelectedFilter] = useState('');
  const updateMessage = (action: "delete" | "read") => {
    if (isMarkAllAsRead) {
      const mailPlatformArray = MemberInboxCxt.messages?.filter(
        (i) => i.platformType === "mail"
      );
      const smsPlatformArray = MemberInboxCxt.messages?.filter(
        (i) => i.platformType === "sms"
      );
      if (mailPlatformArray!.length > 0) {
        const mailIdsArray = getAllElementsId(mailPlatformArray);
        MemberInboxCxt.updateMemberMessages(
          mailIdsArray.toString(),
          "mail",
          action,
          pageNumber,
          messageFilter
        );
      }
      if (smsPlatformArray!.length > 0) {
        const smsIdsArray = getAllElementsId(smsPlatformArray);
        MemberInboxCxt.updateMemberMessages(
          smsIdsArray.toString(),
          "sms",
          action,
          pageNumber,
          messageFilter
        );
      }
      setIsMarkAllAsRead(false);
      setSelectedItemsArray([]);
    } else if (isMultiChoise) {
      const mailPlatformArray = MemberInboxCxt.messages?.filter(
        (i) =>
          i.platformType === "mail" &&
          selectedItemsArray.includes(i.id.toString())
      );
      const smsPlatformArray = MemberInboxCxt.messages?.filter(
        (i) =>
          i.platformType === "sms" &&
          selectedItemsArray.includes(i.id.toString())
      );
      if (mailPlatformArray!.length > 0) {
        const mailIdsArray = getAllElementsId(mailPlatformArray);
        MemberInboxCxt.updateMemberMessages(
          mailIdsArray.toString(),
          "mail",
          action,
          pageNumber,
          messageFilter
        );
      }
      if (smsPlatformArray!.length > 0) {
        const smsIdsArray = getAllElementsId(smsPlatformArray);
        MemberInboxCxt.updateMemberMessages(
          smsIdsArray.toString(),
          "sms",
          action,
          pageNumber,
          messageFilter
        );
      }
      setSelectedItemsArray([]);
      setIsMultiChoise(false);
    } else {
      MemberInboxCxt.updateMemberMessages(
        selectedMessage!.id.toString(),
        selectedMessage!.platformType,
        action,
        pageNumber,
        messageFilter
      );
      setIsShowMessage(false);
      setSelectedMessage(undefined);
    }
  };

  const moreOptionsButtons: optionButtons[] = [
    {
      title: "סמן הכל כנקרא",
      icon: mailOpenBlue,
      customClass: isMarkAllAsRead ? "inbox-read" : "",
      onClick: () => {
        const allItemArray = getAllElementsId(undefined);
        setSelectedItemsArray(allItemArray);
        setIsMarkAllAsRead(true);
      },
    },
    // {
    //   title: "הגדרות תיבת דואר",
    //   icon: settingsBlack,
    //   onClick: () => {
    //   },
    // },
  ];
  const headerParameters: HeaderParameters = {
    isShowLogo: true,
    backButton: { isShowMobile: true },
    moreOptionsButtons: moreOptionsButtons,
    isShowDeleteButton:
      isMultiChoise === true &&
      selectedItemsArray &&
      selectedItemsArray.length > 0,
    deleteFunc: () => {
      updateMessage("delete");
    },
    numOfMultiSelected:
      isMultiChoise === true ? selectedItemsArray.length : undefined,
    onCancelMultiSelected: () => {
      setIsMultiChoise(false);
      setSelectedItemsArray([]);
      SetMultiSelectListner(false, () => {
        setIsMultiChoise(false);
        setSelectedItemsArray([]);
      });
    },
  };
  

  useEffect(() => {
    if (pageNumber > 0) {
      MemberInboxCxt.initMessageContext(true, pageNumber, messageFilter);
    }
  }, [pageNumber]);

  useEffect(() => {
    if(MemberInboxCxt.messages){
    
      let groups = MemberInboxCxt.messages.reduce((group: any, item:messageObj) => {

        let t = moment(item.sending_time);
        let t2 = t.format('YYYY-MM-DD');
        const date = new Date(t2);
        const title = monthNames[date.getMonth()] + ' ' + date.getFullYear();
        
        
        
        if (!group[title]) {
            group[title] = [];
        }
        
        group[title].push(item);
        
        return group;
    }, {}); // <-- Start with an empty object
    //{messages: message[],total:number,title:string}
    var result = Object.keys(groups).map((key) => { return {title: key,total: groups[key].length | 0, messages: groups[key] }});
    setGroupedMessage(result);
    }
    if (
      MemberInboxCxt.messages &&
      pageNumber === 0 &&
      platformType != "" &&
      messageId != ""
    ) {
      const _selectedMessage = MemberInboxCxt.messages.filter(
        (f) => f.id.toString() === messageId && f.platformType === platformType
      );
      if (_selectedMessage.length > 0) {
        setSelectedMessage(_selectedMessage[0]);
        setIsShowMessage(true);
      }
    }
  }, [MemberInboxCxt.messages]);

  useEffect(() => {
    MemberInboxCxt.initMessageContext(true, 0, messageFilter);
    setPageNumber(0);
  }, [messageFilter]);

  useEffect(() => {
    if (isMarkAllAsRead === true) {
      updateMessage("read");
    }
  }, [isMarkAllAsRead]);

  const getAllElementsId = (getArray: messageObj[] | undefined) => {
    let idsStr: string[] = [];
    if (getArray) {
      getArray.map((g) => {
        idsStr.push(g.id.toString());
      });
    } else if (MemberInboxCxt.messages) {
      MemberInboxCxt.messages.map((g) => {
        idsStr.push(g.id.toString());
      });
    }
    return idsStr;
  };

  const handleUpdateElement = (message: messageObj, action: "read" | "delete") => {
    setSelectedMessage(message);
    if (action === "read") {
      setIsShowMessage(true);
      if (message.isRead === false) {
        MemberInboxCxt.updateMemberMessages(
          message.id.toString(),
          message.platformType,
          action,
          pageNumber,
          messageFilter
        );
      }
      SetDtlPushEvtInner("open_message");

    } 
    else if (action === "delete") 
    {
      updateMessage(action);
      SetDtlPushEvtInner("delete_message");
    }
  };

  const itemSelected = (itemId: string) => {
    return selectedItemsArray.some((id) => id == itemId);
  };

  
  function SetDtlPushEvtInner(user_action:string){

    SetDtlPushEvt('messages' , 
    user_action,
    '' + (MemberDetailsCxt.GeneralInfo  ? MemberDetailsCxt.GeneralInfo?.CompanyName : ""),
    GetMemberAdvCategories(MemberDetailsCxt.AdvCategories),
    MemberDetailsCxt.BusinessAddress?.City);
  }

  return (
    <CustomPage
      headerParameters={headerParameters}
      title="תיבת הדואר שלך"
      pageName={"MemberInbox"}
    >
      <IonGrid fixed={true} className="main-content">
        {MemberInboxCxt.unReadMessagesAmount !== undefined &&
          MemberInboxCxt.totalMessagesAmount !== undefined && (
            <IonRow>
              <IonCol size="12">
                <CustomHeaddingItem
                  mainTitle={
                    MemberInboxCxt.unReadMessagesAmount > 0
                      ? `
                יש לך ${MemberInboxCxt.unReadMessagesAmount} הודעות שלא נקראו
                מתוך ${MemberInboxCxt.totalMessagesAmount}   
              `
                      : // : `${MemberInboxCxt.totalMessagesAmount} הודעות`
                        `אין הודעות חדשות`
                  }
                  subTitle={
                    "שרות טוב ללקוח נמדד גם כאן, חשוב לענות מהר ולספק שירות טוב לכל מי שפנה אליכם."
                  }
                  icon={inboxWhite}
                  innerPage={true}
                  iconBackground="inbox"
                />
              </IonCol>
            </IonRow>
          )}
        {MemberInboxCxt.messages && (
          <IonRow>
            <IonCol sizeMd="2" className="ion-no-padding news-chip-wrapper">
              <DropDownFilter showSelectedTextInBtn={true}
                disable={
                  MemberInboxCxt.totalMessagesAmount == 0 ||
                  MemberInboxCxt.totalMessagesAmount == undefined
                }
                defaulvalueIndex={filterIndex}
                options={filterOptions}
                onValueCahnge={(value) => {
                  setMessageFilter(value);
                }}
              ></DropDownFilter>
            </IonCol>
            <IonCol className="ion-no-padding news-chip-wrapper">
             {groupedMessage && <DropDownFilter showSelectedTextInBtn={true}
                disable={
                  MemberInboxCxt.totalMessagesAmount == 0 ||
                  MemberInboxCxt.totalMessagesAmount == undefined
                }
                defaulvalueIndex={0}
                options={[{title: 'כל התאריכים', value: ''},   ...groupedMessage.map((g) => { return { title:g.title,value: g.title} })]}
                onValueCahnge={(value) => {
                  setMonthSelectedFilter(value);
                }}
              ></DropDownFilter>}
            </IonCol>
          </IonRow>
        )}

        {MemberInboxCxt.messages && MemberInboxCxt.messages.length > 0 && (
          <IonRow>
            <IonCol className="ion-no-padding">
              <ChooseAll
                show={isMultiChoise}
                onChooseAll={(checked: boolean) => {
                  if (checked) {
                    const allItemArray = getAllElementsId(undefined);
                    setSelectedItemsArray(allItemArray);
                  } else {
                    setSelectedItemsArray([]);
                  }
                }}
              ></ChooseAll>
            </IonCol>
          </IonRow>
        )}
        <IonRow>
          <IonCol>
            {groupedMessage && groupedMessage.filter(group => monthSelectedFilter == '' || monthSelectedFilter == group.title).map((group,g_index) => {
              return <React.Fragment key={g_index}><label>{group.title} ({group.total})</label>
            <IonList className="inbox-list-wrapper">
              {
              group.messages &&
              group.messages.length > 0 &&
              group.messages.map((m, index) => {
                  const date = convertTimeStampToDate(m.sending_time);
                  
                  const msgBody = ClearHtmlFromString(m.body);
                  const isRead = m.isRead;
                  return (
                    <CustomMultiChoice
                      key={index}
                      show={isMultiChoise}
                      checkedElements={selectedItemsArray}
                      elementId={m.id}
                      onChange={(checkedElements: string[]) => {
                        setSelectedItemsArray(checkedElements);
                      }}
                      onTap={() => {
                        setIsMultiChoise(false);
                        setSelectedItemsArray([]);
                        handleUpdateElement(m, "read");
                      }}
                      onPress={() => {
                        setIsMultiChoise(true);

                        SetMultiSelectListner(true, () => {
                          setIsMultiChoise(false);
                          setSelectedItemsArray([]);
                        });
                      }}
                    >
                      <IonItem
                        lines="none"
                        mode="md"
                        detail={false}
                        key={index}
                        className={`inbox-item ion-margin-bottom ${
                          itemSelected(m.id) ? "selected" : ""
                        } ${
                          isRead ? "inbox-item-read" : "inbox-item-unread"
                        }`}
                      >
                        <IonIcon
                          className={isMultiChoise ? "icon-transparent" : ""}
                          icon={logoOrangeHollow}
                        ></IonIcon>
                        <IonLabel>
                          <IonRow className="ion-justify-content-between ion-align-items-center">
                            <IonCol size="7">
                              <h3
                                className={`ion-no-margin text-wrap ${
                                  !isRead ? "bold-text" : ""
                                }`}
                              >
                                {m.title}
                              </h3>
                            </IonCol>
                            <IonCol className="ion-text-left">
                              <p className="small-p">{date}</p>
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol>
                              <p className="text-wrap">{msgBody}</p>
                            </IonCol>
                          </IonRow>
                        </IonLabel>
                      </IonItem>
                    </CustomMultiChoice>
                  );
                })}
              {MemberInboxCxt.messages == null ||
                (MemberInboxCxt.messages.length === 0 && (
                  <IonRow>
                    <IonCol className="ion-text-center">
                      <IonIcon
                        icon={blankProductIconRed}
                        className="blank-list-icon"
                      />
                      <p>אין הודעות</p>
                    </IonCol>
                  </IonRow>
                ))}
            </IonList></React.Fragment>})}
          </IonCol>
        </IonRow>
      </IonGrid>
      {isShowMessage && (
        <MemberInboxMessage
          closeModal={() => {
            setIsShowMessage(false);
            setSelectedMessage(undefined);
          }}
          onDelete={(message: messageObj) => {
            handleUpdateElement(message, "delete");
          }}
          show={isShowMessage}
          selectedMessage={selectedMessage}
        ></MemberInboxMessage>
      )}
      {MemberInboxCxt.messages && MemberInboxCxt.messages.length > 0 && (
        <IonInfiniteScroll
          position="bottom"
          threshold="2%"
          disabled={
            (messageFilter === "notread" &&
              MemberInboxCxt.unReadMessagesAmount &&
              MemberInboxCxt.messages.length >=
                MemberInboxCxt.unReadMessagesAmount) ||
            (messageFilter === "all" &&
              MemberInboxCxt.totalMessagesAmount &&
              MemberInboxCxt.messages.length >=
                MemberInboxCxt.totalMessagesAmount) ||
            (messageFilter === "read" &&
              MemberInboxCxt.totalReadMessagesAmount &&
              MemberInboxCxt.messages.length >=
                MemberInboxCxt.totalReadMessagesAmount)
              ? true
              : false
          }
          ref={IonInfiniteScrollRef}
          onIonInfinite={() => {
            if (IonInfiniteScrollRef.current) {
              setPageNumber((current) => {
                return current + 1;
              });
              IonInfiniteScrollRef.current.complete();
            }
          }}
        >
          <IonInfiniteScrollContent loadingSpinner="bubbles"></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      )}
    </CustomPage>
  );
};

export default MemberInbox;
