
import Lottie from "lottie-react";
import React, { FC } from "react";

import "react-toastify/dist/ReactToastify.css";
import b144Loader from "../../../images/icons/LOADER_SEPTEMBER_2023.json";


const CustomGlobalLoader: FC = () => {

  return <div className="b144-loader-cont">
  <Lottie animationData={b144Loader} loop={true}   />  
   </div>
};

export default CustomGlobalLoader;
