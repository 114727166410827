import { IonButton, IonCol, IonIcon, IonRow } from "@ionic/react";
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
} from "date-fns";
import { isMobile } from "react-device-detect";
import moment from "moment";
import React, { ComponentProps, useState } from "react";
import { DateRangePicker } from "react-date-range";
// import { addDays } from "date-fns";
import he from "date-fns/locale/he";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./CustomDateRangePicker.scss";
import { toast } from "react-toastify";
import { getDateFormat } from "../../../../utils-ts/GlobalDateFunctions";
import { calendarClearOutline, calendarOutline, star } from "ionicons/icons";
import CustomModal from "../../CustomElements/CustomModal/CustomModal";
import { HeaderParameters } from "../../../../data/Header-Context";

//Documentation for DateRangePicker:
//https://www.npmjs.com/package/react-date-range

type DateRangePickerComponentProps = ComponentProps<typeof DateRangePicker>;

export type defaultStaticRangesOpt =
  | "היום"
  | "אתמול"
  | "השבוע"
  | "שבוע שעבר"
  | "החודש"
  | "חודש קודם";

interface DateRangePickerProps extends DateRangePickerComponentProps {
  onDateChange?: (startDate: Date, endDate: Date) => void;
  onApproveDates?: () => void;
  limitedDates?: Number;
  defaultStaticRanges: defaultStaticRangesOpt;
}

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};

export const defaultStaticRanges = [
  {
    label: "היום",
    startDate: defineds.startOfToday,
    endDate: defineds.endOfToday,
  },
  {
    label: "אתמול",
    startDate: defineds.startOfYesterday,
    endDate: defineds.endOfYesterday,
  },
  {
    label: "השבוע",
    startDate: defineds.startOfWeek,
    endDate: defineds.endOfWeek,
  },
  {
    label: "שבוע שעבר",
    startDate: defineds.startOfLastWeek,
    endDate: defineds.endOfLastWeek,
  },
  {
    label: "החודש",
    startDate: defineds.startOfMonth,
    endDate: defineds.endOfMonth,
  },
  {
    label: "חודש קודם",
    startDate: defineds.startOfLastMonth,
    endDate: defineds.endOfLastMonth,
  },
];

const CustomDateRangePicker: React.FC<DateRangePickerProps> = ({
  ...props
}) => {
  const [state, setState] = useState([
    {
      startDate: props.startDate,
      endDate: props.endDate,
      key: "selection",
    },
  ]);
  const [title, setTitle] = useState<any>(() => {
    const selectedRange = defaultStaticRanges.find(
      (range) => range.label === props.defaultStaticRanges
    );
    return (
      <p>
        <span className="bold-text">{selectedRange!.label}: </span>
        {getDateFormat(selectedRange!.startDate) +
          " - " +
          getDateFormat(selectedRange!.endDate)}
      </p>
    );
  });

  const customRanges: any = [];
  defaultStaticRanges.map((cutsomRange) =>
    customRanges.push({
      label: cutsomRange.label,
      range: () => ({
        startDate: cutsomRange.startDate,
        endDate: cutsomRange.endDate,
      }),
      isSelected() {
        return cutsomRange.startDate === state[0].startDate;
      },
    })
  );
  const [showModal, setShowModal] = useState({
    showModal: false,
    event: undefined,
  });

  const headerParameters: HeaderParameters = {
    modalType: "modal",
  };

  return (
    <>
      <CustomModal
        isOpen={showModal.showModal}
        cssClass="date-reange-modal"
        onDidDismiss={(e) =>
          setShowModal({ showModal: false, event: undefined })
        }
        pageName={"range"}
        headerParameters={headerParameters}
        closeModal={() => {
          props.closeModal();
        }}
        title={"בחר טווח תאריכים"}
      >
        <DateRangePicker
          onChange={(item: any) => {
            if (props.onDateChange) {
              if (
                props.limitedDates &&
                !moment(item.selection.endDate).isSameOrBefore(
                  moment(item.selection.startDate).add(
                    +props.limitedDates,
                    "days"
                  )
                )
              ) {
                toast.error(
                  "טווח מקסימלי של " + (+props.limitedDates + 1) + " ימים"
                );
              } else {
                props.onDateChange(
                  item.selection.startDate,
                  item.selection.endDate
                );
                setState([
                  {
                    startDate: item.selection.startDate,
                    endDate: item.selection.endDate,
                    key: "selection",
                  },
                ]);
                setTitle(() => {
                  // let t = "ידני";
                  let t = (
                    <p>
                      <span className="bold-text">ידני: </span>
                      {getDateFormat(item.selection.startDate) +
                        " - " +
                        getDateFormat(item.selection.endDate)}
                    </p>
                  );
                  return t;
                });
              }
            }
          }}
          showSelectionPreview={false}
          moveRangeOnFirstSelection={false}
          months={!isMobile ? 2 : 1}
          ranges={state}
          direction="horizontal"
          calendarFocus="backwards"
          locale={he}
          renderStaticRangeLabel={(opt: any) => {
            return (
              <span>
                <i>{opt.label}</i>
              </span>
            );
          }}
          staticRanges={customRanges}
          inputRanges={[]}
          {...props}
        />
        <IonRow className="ion-justify-content-center">
          <IonCol size="4" className="ion-no-margin ion-no-padding">
            <IonButton
              onClick={(e) => {
                setShowModal({ showModal: false, event: undefined });
                props.onApproveDates?.();
              }}
              className="main-button"
              expand="block"
            >
              אישור
            </IonButton>
          </IonCol>
        </IonRow>
      </CustomModal>
      <IonButton
        onClick={(e: any) => {
          e.persist();
          setShowModal({ showModal: true, event: e });
        }}
        fill="clear"
        size="small"
        className="date-range-button"
      >
        <IonIcon slot="start" icon={calendarOutline} />
        {title}
      </IonButton>
    </>
  );
};
export default CustomDateRangePicker;
