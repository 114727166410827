import React, { useState, useCallback, useEffect,useContext } from "react";
import ProductContext, { iProduct,MemberOrder,MemberProduct,MemberOrderDetailed, GoogleMerchantEnum, MemberShipping } from "./Products-context";
import { config } from "../Constants";
import { fetchRequest } from "../utils-ts/fetchRequest";
import { toast } from "react-toastify";
//import CustomLoader from "../components/utils/CustomElements/CustomLoader";
import GlobalContext from "./Global-Context";
const parseString = require("xml2js").parseString;

const ProductsContextProvider: React.FC = (props) => {
  const [Products, setProducts] = useState<iProduct[] | undefined>();
  //const [loading, setLoading] = useState<boolean>(false);
  const globalCtx = useContext(GlobalContext);

 const setLoading = (loading:boolean)=>{
  globalCtx.setLoader(loading);
 };
  const [MemberProducts, setMemberProducts] = useState<MemberProduct[] | undefined>();
  const [MemberShippings,setMemberShippings] = useState<MemberShipping[] | undefined>();
  const [TotalOrders, setTotalOrders] = useState<number>(0);
  const [NewOrders, setNewOrders] = useState<number>(0);
  const [MemberOrders, setMemberOrders] = useState<MemberOrder[] | undefined>();
  const [MemberOrderDetailed,setMemberOrderDetailed] = useState< MemberOrderDetailed | undefined>();
  
  const [googleMerchantStageChanges, setGoogleMerchantStageChanges] = useState<boolean>(false);
  const [GoogleMerchantStage, setGoogleMerchantStage] = useState<GoogleMerchantEnum>(GoogleMerchantEnum.None);
  const [GoogleShopVerificationCode, setGoogleShopVerificationCode] = useState('');
  
  useEffect(() => {
    if (Products) {
      //setLoading(false);
    }
  }, [Products]);

  const uploadProduct = async (product: iProduct) => {
    setLoading(true);
    var data = new URLSearchParams({
      jsDetails: JSON.stringify(product),
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientFiles.asmx/InsertUpdateBusinessProduct`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              toast.success("המוצר הועלה בהצלחה");
            } else {
              toast.error("ארעה שגיאה בהעלאת מוצר");
            }
          }
          initProductsContext(true);
        });
      })
      .catch(function (error) {
        initProductsContext(true);
        console.log("Request failed", error);
      });
  };

  const initProductsContext = useCallback(async (useLoading: boolean) => {
    useLoading && setLoading(useLoading);
    var data = new URLSearchParams({}).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientFiles.asmx/GetBusinessProducts`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const productsArray = JSON.parse(res.Response);
              setProducts(productsArray);
              useLoading && setLoading(false);
            }
          }
        });
      })
      .catch(function (error) {
        console.log("Request failed", error);
        useLoading && setLoading(false);
      });
  }, []);

  const saveProduct = async (product: iProduct) => {
    setLoading(true);
    var data = new URLSearchParams({
      jsDetails: JSON.stringify(product),
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientFiles.asmx/InsertUpdateBusinessProduct`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              toast.success("המוצר עודכן בהצלחה");
            } else {
              toast.error("ארעה שגיאה בעדכון מוצר");
            }
          }
          initProductsContext(true);
        });
      })
      .catch(function (error) {
        initProductsContext(true);
        console.log("Request failed", error);
      });
  };

  const deletedProductsHandler = async (deletedProducts: string[]) => {
    setLoading(true);
    var data = new URLSearchParams({
      jsDetails: JSON.stringify(deletedProducts),
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientFiles.asmx/DeleteBusinessProducts`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              toast.success("מוצרים נמחקו בהצלחה");
              initProductsContext(true);
            } else {
              toast.error("ארעה שגיאה במחיקת מוצרים");
            }
          }
        });
      })
      .catch(function (error) {
        console.log("Request failed", error);
      });
  };

// NEW PRODUCTS
  const initMemberProductsContext = useCallback(async (useLoading: boolean) => {
    useLoading && setLoading(useLoading);
    var data = new URLSearchParams({}).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientProducts.asmx/GetBusinessProducts`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const response = JSON.parse(res.Response);
              setMemberProducts(response.MemberProductsList);
              setTotalOrders(response.TotalOrders);
              setNewOrders(response.NewOrders);
              setGoogleMerchantStage(response.GoogleMerchantStage);
              setMemberShippings(response.MemberShippings);
              useLoading && setLoading(false);
            }
          }
        });
      })
      .catch(function (error) {
        console.log("Request failed", error);
        useLoading && setLoading(false);
      });
  }, []);
  const saveMemberProduct = (product: MemberProduct) => {
    setLoading(true);
    var data = new URLSearchParams({
      jsDetails: JSON.stringify(product),
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientProducts.asmx/InsertUpdateBusinessProduct`,
      "post",
      data
    )
      .then((response) => {
        setLoading(false);
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              initMemberProductsContext(false);
            //   if(product.Id > 0) {
            //   setMemberProducts((currProducts) => {
            //     const updatedProducts = currProducts != null ? [...currProducts] : [];
            //     const updatedProductIndex = updatedProducts.findIndex((p) => p.Id == product.Id);
          
            //     const updatedProduct = { ...updatedProducts[updatedProductIndex] };
            //     updatedProduct.Active = product.Active;
            //     updatedProduct.Name = product.Name;
            //     updatedProducts[updatedProductIndex] = updatedProduct;
          
            //     return updatedProducts;

            //   });
            // }
              toast.success("המוצר עודכן בהצלחה");
            } else {
              toast.error("ארעה שגיאה בעדכון מוצר");
            }
          }
          initProductsContext(true);
        });
      })
      .catch(function (error) {
        setLoading(false);
        initProductsContext(true);
        console.log("Request failed", error);
      });
  };

  const deletedMemberProductsHandler = async (deletedProducts: string[]) => {
    setLoading(true);
    var data = new URLSearchParams({
      jsDetails: JSON.stringify(deletedProducts),
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientProducts.asmx/DeleteBusinessProducts`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              toast.success("מוצרים נמחקו בהצלחה");
              initProductsContext(true);
            } else {
              toast.error("ארעה שגיאה במחיקת מוצרים");
            }
          }
        });
      })
      .catch(function (error) {
        console.log("Request failed", error);
      });
  };
// ECOMM ORDERS
  const initMemberOrdersContext = useCallback(async (useLoading: boolean) => {
    useLoading && setLoading(useLoading);
    var data = new URLSearchParams({}).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientProducts.asmx/GetMemberOrders`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const ordersArray = JSON.parse(res.Response);
              setMemberOrders(ordersArray);
              useLoading && setLoading(false);
            }
          }
        });
      })
      .catch(function (error) {
        console.log("Request failed", error);
        useLoading && setLoading(false);
      });
  }, []);

 const getMemberOrder = (orderId: number)  => {
  setLoading(true);
  var data = new URLSearchParams({
    orderId: orderId.toString()
  }).toString();

  fetchRequest(
    `${config.url.API_URL}/ClientProducts.asmx/GetMemberOrder`,
    "post",
    data
  )
    .then((response) => {
      parseString(response, (err: any, result: any) => {
        if (result) {
          var res = JSON.parse(result.string["_"]);
          if (res.Success) {
            const order = JSON.parse(res.Response);
            setMemberOrderDetailed(order);    
          } else {
            toast.error("ארעה שגיאה בשליפת הזמנה");
          }
        }
        
      });
    })
    .catch(function (error) {
      
      console.log("Request failed", error);
    });
};
const updateOrderStatus= (orderId: number, status: number) => {
  setLoading(true);
  var data = new URLSearchParams({
    orderId: orderId.toString(),
    status:status.toString()
  }).toString();

  fetchRequest(
    `${config.url.API_URL}/ClientProducts.asmx/UpdateOrderStatus`,
    "post",
    data
  )
    .then((response) => {
      parseString(response, (err: any, result: any) => {
        if (result) {
          var res = JSON.parse(result.string["_"]);
          if (res.Success) {
            initMemberOrdersContext(false);
        
            toast.success("הזמנה עודכנה בהצלחה");
          } else {
            toast.error("ארעה שגיאה בעדכון הזמנה");
          }
        }
        
      });
    })
    .catch(function (error) {
      
      console.log("Request failed", error);
    });
};
//ECOMM SHIPPING
const saveMemberShipping =(shipping: MemberShipping)=> {
  return new Promise((resolve) => {
    var data = new URLSearchParams({
      jsDetails: JSON.stringify(shipping),
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientProducts.asmx/SaveMemberShipping`,
      "post",
      data
    )
      .then((response: any) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res && res.Success) {
              setMemberShippings(JSON.parse(res.Response));
              resolve(true);
            } else {
              toast.error("ארעה שגיאה");
              resolve(false);
            }
          }
        
      });
    }).catch(function (error) {
        console.log(error);
        toast.error("ארעה שגיאה");
        resolve(false);
      });
  });
  
};

// const getMemberShipping = () => {
//   return new Promise((resolve) => {
//     var data = new URLSearchParams({}).toString();

//     fetchRequest(
//       `${config.url.API_URL}/ClientProducts.asmx/GetMemberShipping`,
//       "post",
//       data
//     )
//       .then((response: any) => {
//         var res = JSON.parse(response);
//         //debugger;
//         if (res && res.success) {
          
//           resolve(res.Data);
//         } else {
//           toast.error("ארעה שגיאה");
//           resolve(null);
//         }
//       })
//       .catch(function (error) {
//         console.log(error);
//         toast.error("ארעה שגיאה");
//         resolve(null);
//       });
//   });
  
// };

//GOOGLE SHOPPING
const openGoogleShop= () => {
  setLoading(true);
  
  var data = new URLSearchParams({}).toString();
  fetchRequest(
    `${config.url.API_URL}/ClientProducts.asmx/OpenGoogleShop`,
    "post",
    data
  )
    .then((response) => {
      parseString(response, (err: any, result: any) => {
        if (result) {
          var res = JSON.parse(result.string["_"]);
          if (res.Success) {
            toast.success(res.Response);
            setGoogleMerchantStage(GoogleMerchantEnum.Created);
            setGoogleMerchantStageChanges(true);
          } else {
            toast.error(res.ErrorMessage);
          }
        }
        
      });
    })
    .catch(function (error) {
      
      console.log("Request failed", error);
    });
};
const sendGoogleShopVerificationCode=( mobilePhone:string)=> {
  setLoading(true);
  
  var data = new URLSearchParams({mobilePhone}).toString();
  fetchRequest(
    `${config.url.API_URL}/ClientProducts.asmx/SendGoogleShopVerificationCode`,
    "post",
    data
  )
    .then((response) => {
      parseString(response, (err: any, result: any) => {
        if (result) {
          var res = JSON.parse(result.string["_"]);
          if (res.Success) {
            setGoogleShopVerificationCode(res.Response);
             
          } else {
            toast.error(res.ErrorMessage);
          }
        }
        
      });
    })
    .catch(function (error) {
      
      console.log("Request failed", error);
    });
};
const verifyGoogleShop=(smsCode:string)=> {
  setLoading(true);
  
  var data = new URLSearchParams({verificationId: GoogleShopVerificationCode, smsCode}).toString();
  fetchRequest(
    `${config.url.API_URL}/ClientProducts.asmx/VerifyGoogleShop`,
    "post",
    data
  )
    .then((response) => {
      parseString(response, (err: any, result: any) => {
        if (result) {
          var res = JSON.parse(result.string["_"]);
          if (res.Success) {
            toast.success(res.Response);
            setGoogleMerchantStage(GoogleMerchantEnum.Verified);
            setGoogleMerchantStageChanges(true);
          } else {
            toast.error(res.ErrorMessage);
          }
        }
      });
    })
    .catch(function (error) {
      
      console.log("Request failed", error);
    });
};
const resetGoogleShopVerificationCode = () => {
  setLoading(true);
  setGoogleShopVerificationCode('');
};

  return (
    <ProductContext.Provider
      value={{
        Products,
        initProductsContext,
        saveProduct,
        deletedProductsHandler,
        uploadProduct,
        MemberProducts,
        TotalOrders,
        NewOrders,
        initMemberProductsContext,
        saveMemberProduct,
        deletedMemberProductsHandler,
        MemberOrders,
        MemberOrderDetailed,
        initMemberOrdersContext,
        getMemberOrder,
        updateOrderStatus,
        GoogleMerchantStage,
        GoogleShopVerificationCode,
        openGoogleShop,
        sendGoogleShopVerificationCode,
        verifyGoogleShop,
        resetGoogleShopVerificationCode,
        googleMerchantStageChanges,
        setGoogleMerchantStageChanges,
        MemberShippings,
        saveMemberShipping,
        //getMemberShipping,
      }}
    >
      {props.children}
      {/* <CustomLoader
        isOpen={loading}
        duration={2000}
        onDidDismiss={() => {
          setLoading(false);
        }}
      ></CustomLoader> */}
    </ProductContext.Provider>
  );
};

export default ProductsContextProvider;
