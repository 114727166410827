import React, {
  ComponentProps,
  FC,
  useContext,
  useEffect,
  useRef,
} from "react";
import { IonCol, IonGrid, IonModal, IonRow } from "@ionic/react";
import CustomHeader from "../CustomHeader/Header";
import { HeaderParameters } from "../../../../data/Header-Context";
import CustomPageTitle from "../CustomPageTitle/CustomPageTitle";
import TipFromBezeq from "../../../TipFromBezeq/TipFromBezeq";
import CustomContent from "../CustomContent";
import "./CustomModal.scss";
import GlobalContext from "../../../../data/Global-Context";
import { isMobile } from "react-device-detect";
//import { config } from "process";
import { config } from "../../../../Constants";

type IonModalProps = ComponentProps<typeof IonModal>;
interface ModalProps extends IonModalProps {
  headerParameters: HeaderParameters;
  title?: string;
  subTitle?: string;
  closeModal: () => void;
  pageName: string; //send the  parent function name
  icon?: string;
  skipPageTips?: boolean;
}

const CustomModal: FC<ModalProps> = ({
  headerParameters,
  title,
  icon,
  subTitle,
  pageName,
  closeModal,
  skipPageTips,
  ...props
}) => {
  const ionModal = useRef<HTMLIonModalElement>(null);
  const modalCss = () => {
    let wraperClassName = "modal-wraper ";
    switch (pageName) {
      case "PrivacyPolicy":
      case "BusinessOpenHours":
      case "HandleOpenHours":
      case "AddEditBusinessCertificate":
        wraperClassName += "modal-wraper-wide";
        break;
      case "BusinessMovie":
          wraperClassName += "modal-wraper-wide";
        break;
      case "GalleryHandler":
        wraperClassName += "modal-wraper-wide";
        break;
      case "CustomImageEditor":
        wraperClassName += "modal-wraper-wide";
        break;
      case "AddEditProduct":
        wraperClassName += "modal-wraper-wide";
        break;
      case "AddEditPriceList":
        wraperClassName += "modal-wraper-wide";
        break;
      case "HandleMemberCoupon":
        wraperClassName += "modal-wraper-wide";
        break;
      case "HandlePriceListItem":
        wraperClassName += "modal-wraper-wide";
        break;
      case "LeadItemPopup":
        wraperClassName += "modal-wraper-wide";
        break;
      case "ProfileSettings":
        wraperClassName += "modal-wraper-wide";
        break;
      case "MemberCampaigns":
        wraperClassName += "modal-wraper-wide";
        break;
      case "AddVas":
      case "VasInfo":
      case "ServiceAreas":
        wraperClassName += "modal-wraper-wide";
        break;
      case "ServiceAreasConfirm":
        wraperClassName += "modal-h-75";
        break;
        case "SeoDetails":
      case "FrmVasMainConfirm":
        wraperClassName += "modal-h-420";
        break;
      case "BudgetUpgradeConfirm":
        wraperClassName += "modal-h-450";
        break;
        case "ColorPicker":
          wraperClassName += "modal-narrow modal-color-picker";
          break;
      case "GalleryItemPreview":
        wraperClassName += "modal-wraper-fullscreen";
        break;
        case "ECommerceOrder":
          wraperClassName += "modal-wraper-wide";
        break;
        case "AddEditBusinessNews":
          wraperClassName += "modal-wraper-wide";
        break;
      default:
        wraperClassName += "modal-narrow";
    }
    if (headerParameters.modalType === "confirm") {
      wraperClassName += " modal-confirm ";
    }
    return wraperClassName;
  };

  const modalMblCss = () => {
    let wraperClassName = "";
    switch (pageName) {
      case "ServiceAreasConfirm":
        wraperClassName += "modal-h-75";
        break;
      case "FrmVasMainConfirm":
        wraperClassName += "modal-h-420";
        break;
      case "BudgetUpgradeConfirm":
        wraperClassName += "modal-h-450";
        break;
      case "ProductInfo":
        wraperClassName += "modal-wraper-product-info";
        break;
      case "ProductList":
        wraperClassName += "modal-wraper-product-info";
        break;
        case "ColorPicker":
          wraperClassName += "modal-color-picker";
          break;
    }

    if (headerParameters.modalType === "confirm") {
      wraperClassName += " modal-wraper modal-confirm ";
    }
    return wraperClassName;
  };

  const PageTipCtx = useContext(GlobalContext);
  const _headerParameters = { ...headerParameters };
  if (headerParameters.isCloseModalBtn === undefined) {
    _headerParameters.isCloseModalBtn = true;
    _headerParameters.closeModalFunc = () => {
      ionModal.current?.dismiss();
    };
  }

  useEffect(() => {
    if (pageName && pageName !== "" && !skipPageTips) {
      PageTipCtx.getPageTip(pageName, "modal");
    }
  }, []);

  return (
    <IonModal
      ref={ionModal}
      cssClass={!isMobile ? modalCss() : modalMblCss()}
      backdropDismiss={false}
      onDidPresent = {() => {
        loopAllAnimations();
      }}
      onDidDismiss={() => {
        closeModal();
      }}
      {...props}
    >
      <CustomHeader headerParameters={_headerParameters}></CustomHeader>
      <CustomContent
        isRefreshPage={false}
        className={`modal-page  ${
          pageName === "GalleryItemPreview" ? "modal-item-preview" : ""
        }`}
      >
        <IonGrid>
          <IonRow className="ion-justify-content-between">
            {title && (
              <IonCol
                size={PageTipCtx.modalTip ? "10" : "11"}
                className="ion-no-padding"
              >
                <CustomPageTitle
                  title={title}
                  icon={icon}
                  subTitle={subTitle}
                  subTitleClass="modal-subtitle"
                />
              </IonCol>
            )}
            {PageTipCtx.modalTip && pageName && pageName !== "" && !skipPageTips && (
              <IonCol className="ion-text-end" size="2">
                <TipFromBezeq
                  tip={PageTipCtx.modalTip}
                  pageName={pageName}
                  modal={true}
                />
              </IonCol>
            )}
          </IonRow>
        </IonGrid>

        {props.children}
      </CustomContent>
    </IonModal>
  );
};

export default CustomModal;
