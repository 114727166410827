const NativeApp = () => {
  const IsInWindow = typeof window !== "undefined";
  const IsNativeApp = IsInWindow
    ? window.localStorage.getItem("AppToken") !== null
    : false;

  //@ts-ignore
  const nativeApp = IsInWindow && window.ReactNativeWebView;

  const LoadExternalSite = (externalUrl: string) => {
    if (IsNativeApp) {
      //@ts-ignore
      window.postMessage(
        JSON.stringify({ action: "LoadExternalSite", data: externalUrl })
      );
      if (nativeApp) {
        nativeApp.postMessage(
          JSON.stringify({ action: "LoadExternalSite", data: externalUrl })
        );
      }
    } else {
      if (IsInWindow) {
        window.open(externalUrl, "_blank");
      }
    }
  };

  const SendSms = (phone_num: string, body: string) => {
    if (IsNativeApp) {
      const externalUrl = "sms:" + phone_num + "?&body=" + body;
      //@ts-ignore
      window.postMessage(
        JSON.stringify({ action: "SendSms", data: unescape(externalUrl) })
      );
      if (nativeApp) {
        nativeApp.postMessage(
          JSON.stringify({ action: "SendSms", data: unescape(externalUrl) })
        );
      }
    } else {
      if (IsInWindow) {
        window.location.href = "sms:" + phone_num + "?&body=" + body;
      }
    }
  };

  const OpenWaze = (externalUrl: string) => {
    if (IsNativeApp) {
      //@ts-ignore
      window.postMessage(
        JSON.stringify({ action: "OpenWaze", data: externalUrl })
      );
      if (nativeApp) {
        nativeApp.postMessage(
          JSON.stringify({ action: "OpenWaze", data: externalUrl })
        );
      }
    } else {
      if (IsInWindow) {
        window.open(externalUrl, "_blank");
      }
    }
  };

  const OpenDialer = (externalUrl: string) => {
    if (IsNativeApp) {
      //@ts-ignore
      window.postMessage(
        JSON.stringify({ action: "OpenDialer", data: externalUrl })
      );
      if (nativeApp) {
        nativeApp.postMessage(
          JSON.stringify({ action: "OpenDialer", data: externalUrl })
        );
      }
    } else {
      if (IsInWindow) {
        window.location.href = externalUrl;
      }
    }
  };

  const OpenWhatsApp = (externalUrl: string) => {
    if (IsNativeApp) {
      //@ts-ignore
      window.postMessage(
        JSON.stringify({ action: "OpenWhatsApp", data: externalUrl })
      );
      if (nativeApp) {
        nativeApp.postMessage(
          JSON.stringify({ action: "OpenWhatsApp", data: externalUrl })
        );
      }
    } else {
      if (IsInWindow) {
        window.location.href = externalUrl;
      }
    }
  };

  const MailTo = (externalUrl: string) => {
    if (IsNativeApp) {
      //@ts-ignore
      window.postMessage(
        JSON.stringify({ action: "MailTo", data: unescape(externalUrl) })
      );
      if (nativeApp) {
        nativeApp.postMessage(
          JSON.stringify({ action: "MailTo", data: unescape(externalUrl) })
        );
      }
    } else {
      if (IsInWindow) {
        window.open(unescape(externalUrl), "_blank");
      }
    }
  };

  const RequestPic = (externalUrl: string) => {
    if (IsNativeApp) {
      //@ts-ignore
      window.postMessage(
        JSON.stringify({ action: "RequestPic", data: externalUrl })
      );
      if (nativeApp) {
        nativeApp.postMessage(
          JSON.stringify({ action: "RequestPic", data: externalUrl })
        );
      }
    } else {
      if (IsInWindow) {
        window.location.href =
          "Request_144.aspx?" + "Get_pic&mid=" + externalUrl;
      }
    }
  };

  const RequestXY = () => {
    if (IsNativeApp) {
      //@ts-ignore
      window.postMessage(JSON.stringify({ action: "RequestXY", data: "" }));
      if (nativeApp) {
        nativeApp.postMessage(
          JSON.stringify({ action: "RequestXY", data: "" })
        );
      }
    }
  };

  const NativeFacebookLogin = (externalUrl?: string) => {
    if (IsNativeApp) {
      //@ts-ignore
      window.postMessage(
        JSON.stringify({ action: "NativeFacebookLogin", data: externalUrl })
      );
      if (nativeApp) {
        nativeApp.postMessage(
          JSON.stringify({ action: "NativeFacebookLogin", data: externalUrl })
        );
      }
    }
  };

  const NativeGoogleLogin = (externalUrl?: string) => {
    if (IsNativeApp) {
      //@ts-ignore
      window.postMessage(
        JSON.stringify({ action: "NativeGoogleLogin", data: externalUrl })
      );
      if (nativeApp) {
        nativeApp.postMessage(
          JSON.stringify({ action: "NativeGoogleLogin", data: externalUrl })
        );
      }
    }
  };

  const DownloadFile = (file?: any) => {
    if (IsNativeApp) {
      //@ts-ignore
      window.postMessage(
        JSON.stringify({ action: "DownloadFile", data: file })
      );
      if (nativeApp) {
        nativeApp.postMessage(
          JSON.stringify({ action: "DownloadFile", data: file })
        );
      }
    }
  };

  return {
    IsNativeApp,
    SendSms,
    OpenWaze,
    OpenDialer,
    OpenWhatsApp,
    MailTo,
    RequestPic,
    RequestXY,
    LoadExternalSite,
    NativeFacebookLogin,
    NativeGoogleLogin,
    DownloadFile,
  };
};

export default NativeApp;
