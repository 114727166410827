import { IonButton, IonCard, IonCheckbox, IonIcon, IonItem, IonLabel } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';

import Autocomplete from "react-autocomplete";
import CustomCheckbox from './CustomCheckbox';

export type AutocompleteDropDownOptions = {
    labelAccessor: string;
    valueAccessor: string;
}
const CustomAutocomplete: React.FC<{
    options: AutocompleteDropDownOptions,
    selectedItems?: any[],
    fetchItems: (prefix: string) => any,
    onSelect: (item: any) => void,
    type?: string,
    placeholder: string,
    query?: string,
    header: string
}> = props => {

    const [originalItems, setOriginalItems] = React.useState<any[]>([])
    const [searchStr, setSearchStr] = React.useState(props.query || "")
    const [menuOpen, setMenuOpen] = React.useState<boolean>(false)

    const convertArray = (itemsArr: any[]) => {
        if (itemsArr && itemsArr.length) {
            let converted = itemsArr.map((item: any) => {
                if (props.selectedItems && props.selectedItems.length) {
                    let checkSelected = props.selectedItems.find((selected: any) => {
                        return selected[props.options.valueAccessor] === item[props.options.valueAccessor]
                    })

                    if (checkSelected || props.type==="standard") {
                        item.selected = true
                    }
                    else {
                        item.selected = false
                    }
                }
                if (props.query && item[props.options.labelAccessor] === props.query) {
                    item.selected = true
                    handleSelectItem2(item)
                }
                return item
            })
            return converted
        }
    }

    useEffect(() => {
        if (props.query) {
            setSearchStr(props.query)
        }
    }, [props.query])

    useEffect(() => {
        if (searchStr) {
            props.fetchItems && props.fetchItems(searchStr).then((res: any) => {
                if (res && res.length) {
                    let converted = convertArray(res)
                    if (converted) {
                        setOriginalItems(converted)
                    }
                }
            })
        }
        else {
            setOriginalItems([])
        }
    }, [searchStr])


    const handleSelectMenu = () => {
        if (!menuOpen) {
            let converted = convertArray(originalItems)
            if (converted) {
                setOriginalItems(converted)
            }
        }
        setMenuOpen(!menuOpen)
    }

    const handleSelectItem2 = (item: any) => {
        if (item.hasOwnProperty(props.options.valueAccessor)) {
            if (!item.hasOwnProperty("selected")) {
                item.selected = true
            }
            props.onSelect(item)
            
        }
        else {
            let originalSelected = originalItems.find(o => o[props.options.valueAccessor] === item.value)
            originalSelected.selected = item.selected
            props.onSelect(originalSelected)
        }

    }

    return (
        <>
            <div className={searchStr ? "custom-autocomplete-wrapper show-label" : "custom-autocomplete-wrapper"}>
                {searchStr && <IonLabel>{props.placeholder}</IonLabel>}
                <Autocomplete inputProps={{ "placeholder": props.placeholder }}
                    selectOnBlur={false}
                    getItemValue={(item) => item[props.options.valueAccessor]}
                    items={originalItems}
                    renderItem={(item, isHighlighted) => {
                        var renderElement = <CustomCheckbox key={uuidv4()} selected={item.selected} value={item[props.options.valueAccessor]} label={item[props.options.labelAccessor]} onSelect={handleSelectItem2} />
                        if (props.type === "standard") {
                            renderElement = <div key={uuidv4()} style={{ background: isHighlighted ? 'rgb(238,238,238)' : 'white', padding: '5px' }}>
                                {item[props.options.labelAccessor]}
                            </div>
                        }
                        return (
                            renderElement
                        )
                    }

                    }
                    open={menuOpen}
                    renderMenu={(originalItems) => {
                        return (<div className="custom-autocomplete-dd-list" ><div className="dd-list-inner" children={originalItems}></div></div>)
                    }}
                    onMenuVisibilityChange={handleSelectMenu}
                    value={searchStr}
                    onChange={(e) => setSearchStr(e.target.value)}
                    onSelect={(val, item) => handleSelectItem2(item)}
                ></Autocomplete>

            </div>
        </>
    )
}
export default CustomAutocomplete;