import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
} from "@ionic/react";
import "chartjs-plugin-labels";
import jsPDF from "jspdf";
import React, { useContext, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import MemberCampaignData from "../../components/Statistic/MemberCampaignData";
import MemberCampaign from "../../components/Statistic/MemberCampaigns";
import StatisticActionSummery from "../../components/Statistic/StatisticActionSummery";
import BarChart from "../../components/utils/Charts/BarChart";
import DoughnutChart from "../../components/utils/Charts/DoughnutChart";
import PieChart from "../../components/utils/Charts/PieChart";
import CustomPage from "../../components/utils/CustomElements/CustomPage";
import CustomHeaddingItem from "../../components/utils/CustomElements/CustomPageTitle/CustomHeaddingItem";
import CustomTrend from "../../components/utils/CustomElements/CustomTrend/CustomTrend";
import CustomDateRangePicker, {
  defaultStaticRanges,
  defaultStaticRangesOpt,
} from "../../components/utils/Filters/CustomDateRangePicker/CustomDateRangePicker";
import { HeaderParameters } from "../../data/Header-Context";
import MemberDetailsContext from "../../data/MemberDetailes-context";
import SelfServiceContext, {
  BudgetDetails,
} from "../../data/SelfService-context";
import StatisticContext, {
  B144Stats,
  StatProd,
} from "../../data/Statistic-Context";
import {
  ListIcon,
  arrowLeftEmpty,
  arrowLeftFilled,
  blankProductIconOrange,
  distrib,
  exclamationMark,
  fact_check,
  graph,
  important_devices,
  megaphone,
  ring_volume,
  search,
  statisticsWhite,
  touch_app,
  whatsapp_click,
} from "../../images/index";
import { convertTimeStampToDate, monthNames } from "../../utils-ts/GlobalDateFunctions";
import { getStatisticReport } from "../../utils-ts/PdfGenerator";
import "./statistics.scss";
import { initMemberActiveSystems } from "../../data/GlobalService";

const Statistics: React.FC = () => {
  const StatisticsCxt = useContext(StatisticContext);
  const [products, setProducts] = useState<number[]>();
  const MemberDetailsCxt = useContext(MemberDetailsContext);
  const selfCtx = useContext(SelfServiceContext);
  const limitDate = MemberDetailsCxt.ExtraDetailes?.RegistrationDate;
  const statProducts: StatProd[] | undefined = !!StatisticsCxt.statProducts
    ?.length
    ? StatisticsCxt.statProducts
    : (products as any[]);
  const statB144: B144Stats = StatisticsCxt.statB144;
  const statProductsPrev: StatProd[] | undefined =
    StatisticsCxt.statProductsPrev;
  let sumImpressions = 0;
  let budget = 0;
  let cost = 0;
  let totalCall = 0;
  let totalWhatsApp = 0;
  let totalClickes = 0;
  let totalCallPrev = 0;
  let totalWhatsAppPrev = 0;
  let totalClickesPrev = 0;
  let persentage = 0;
  let limitMonth: number | undefined = undefined;
  let limitYear: number | undefined = undefined;
  let b144ProdDetail: StatProd | undefined;
  let b144ProdDetailPrev: StatProd | undefined;

  const [isShowCampaign, setIsShowCampaign] = useState(false);
  const [approvedDateSelection, setApprovedDateSelection] = useState(true);
  const _defaultStaticRanges: defaultStaticRangesOpt = "החודש";
  const defaultStaticSelectedRanges = defaultStaticRanges.find(
    (range) => range.label === _defaultStaticRanges
  );
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(() => {
    return defaultStaticSelectedRanges!.startDate;
  });
  const prevSelectedStartDate = useRef<Date>();
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(() => {
    return defaultStaticSelectedRanges!.endDate;
  });
  const prevSelectedEndDate = useRef<Date>();
  const month = selectedStartDate.getMonth() + 1;
  const year = selectedStartDate.getFullYear();
  // get the number of days in the month
  const days = Number(
    new Date(year, month, 0).toISOString().split("T")[0].split("-")[2]
  );
  const hasDatesChanged =
    prevSelectedStartDate.current?.toISOString() !==
      selectedStartDate.toISOString() ||
    prevSelectedEndDate.current?.toISOString() !==
      selectedEndDate.toISOString();

  const isFullMonth =
    selectedStartDate.getMonth() === selectedEndDate.getMonth() &&
    selectedStartDate.getFullYear() === selectedEndDate.getFullYear();

  const [daysToShow, setDaysToShow] = useState(() => {
    if (!isFullMonth)
      return isMobile && statB144.StatsDaysByMonths.length > 15
        ? Math.round(statB144.StatsDaysByMonths.length) / 2
        : statB144.StatsDaysByMonths.length;

    return isMobile ? 15 : days;
  });
  const [transactionsToShow, setTransactionsToShow] = useState(6);
  const [isSwitchArrow, setIsSwitchArrow] = useState({
    StatsDaysByMonths: false,
    StatsBytransactionsSources: false,
  });

  const [statisticReport, setStatisticReport] = useState<jsPDF>();
  const [exposeType, setExposeType] = useState<1 | 2>(1);
  const [currentBudget, setCurrentBudget] = useState<BudgetDetails>();
  const setData = () => {
    if (!StatisticsCxt.statProducts?.length) return;
    if (limitDate != null) {
      const dateArray = convertTimeStampToDate(limitDate).split("/");
      limitMonth = Number(dateArray[1]);
      limitYear = Number(dateArray[2]);
    }
    statProducts?.map((prod) => {
      sumImpressions = sumImpressions + prod.Impressions;
      budget = budget + prod.Budget;
      cost = cost + prod.Cost + prod.GF_Cost;
      totalCall = totalCall + prod.CallsCount;
      totalWhatsApp = totalWhatsApp + (prod.WhatsAppCount || 0);
      totalClickes = totalClickes + prod.GF_Clicks;
    });
    statProductsPrev?.map((prod) => {
      totalCallPrev = totalCallPrev + prod.CallsCount;
      totalWhatsAppPrev = totalWhatsAppPrev + (prod.WhatsAppCount || 0);
      totalClickesPrev = totalClickesPrev + prod.GF_Clicks;
    });

    persentage = budget > 0 ? (cost / budget) * 100 : 0;

    b144ProdDetail = StatisticsCxt.statProducts?.filter(
      (p) => p.ProdMainName === "מדריך 144"
    )[0];

    b144ProdDetailPrev = StatisticsCxt.statProductsPrev?.filter(
      (p) => p.ProdMainName === "מדריך 144"
    )[0];
  };

  const headerParameters: HeaderParameters = {
    isShowLogo: true,
    backButton: {
      isShowMobile: true,
      isShowDesktop: true,
      link: "/StatisticsOpt",
    },
    pdfDownloadPdf: statisticReport,
    isShowNotification: true,
  };

  useEffect(() => {
    (async () => {
      if (MemberDetailsCxt.ExtraDetailes === undefined) {
        MemberDetailsCxt.initExtraDetailes();
      }

      if (!approvedDateSelection || (approvedDateSelection && !hasDatesChanged))
        return;

      if (isShowCampaign === false) {
        if (isFullMonth) {
          StatisticsCxt.initStatProductsContext(true, month, year);
        } else {
          const prods = (await initMemberActiveSystems()).filter((prodId) =>
            [0, 1, 4, 10, 18, 20, 21, 24, 25, 55].includes(prodId)
          );
          setProducts(prods);
        }
        StatisticsCxt.initActionSummeryContext(
          true,
          selectedStartDate,
          selectedEndDate
        );
      }
    })();
  }, [
    selectedStartDate,
    selectedEndDate,
    approvedDateSelection,
    isShowCampaign,
  ]);

  useEffect(() => {
    if (
      MemberDetailsCxt.ExtraDetailes !== undefined &&
      StatisticsCxt.actionSummery !== undefined &&
      !!StatisticsCxt.statProducts?.length &&
      isFullMonth
    ) {
      const report = getStatisticReport(
        month,
        year,
        MemberDetailsCxt.ExtraDetailes!.Package_Desc,
        StatisticsCxt.actionSummery,
        statProducts
      );
      setStatisticReport(report);
    }
  }, [
    MemberDetailsCxt.ExtraDetailes,
    StatisticsCxt.actionSummery,
    statProducts,
  ]);

  React.useEffect(() => {
    if (!approvedDateSelection || (approvedDateSelection && !hasDatesChanged))
      return;

    StatisticsCxt.initStatB144Context(
      selectedStartDate,
      selectedEndDate,
      "StatsByTotalExposed"
    );
    StatisticsCxt.initStatB144Context(
      selectedStartDate,
      selectedEndDate,
      "StatsByCatOrName",
      exposeType
    );
    StatisticsCxt.initStatB144Context(
      selectedStartDate,
      selectedEndDate,
      "StatsByCategories",
      exposeType
    );
    StatisticsCxt.initStatB144Context(
      selectedStartDate,
      selectedEndDate,
      "StatsBytransactionsSources",
      exposeType
    );
    StatisticsCxt.initStatB144Context(
      selectedStartDate,
      selectedEndDate,
      "StatsDaysByMonths",
      exposeType,
      exposeType
    );
    if (currentBudget == null) {
      selfCtx.getMemberBudgetDetails(true).then((res: any) => {
        if (res != null) {
          setCurrentBudget(res);
        }
      });
    }
    // to keep track on the date changes
    prevSelectedStartDate.current = selectedStartDate;
    prevSelectedEndDate.current = selectedEndDate;
    setApprovedDateSelection(false);
  }, [selectedStartDate, selectedEndDate, approvedDateSelection, exposeType]);

  if (MemberDetailsCxt.ExtraDetailes != null) {
    setData();
  }

  // const months = React.useMemo(
  //   () => [
  //     "ינואר",
  //     "פבואר",
  //     "מרץ",
  //     "אפריל",
  //     "מאי",
  //     "יוני",
  //     "יולי",
  //     "אוגוסט",
  //     "ספטמבר",
  //     "אוקטובר",
  //     "נובמבר",
  //     "דצמבר",
  //   ],
  //   []
  // );

  const daysArr = React.useMemo(
    () =>
      Array.from(
        { length: days },
        (value, index) => (value = String(index + 1))
      ),
    [days]
  );

  const daysDataArr = React.useMemo(() => {
    const arr = Array.from({ length: days }, (value) => (value = 0));
    statB144.StatsDaysByMonths.forEach(
      (stat) => (arr[stat.Day - 1] = stat.AllClicks)
    );
    return arr;
  }, [days, statB144.StatsDaysByMonths]);

  const backgroundColor = ["rgba(255, 149, 72, 1)", "rgba(255, 212, 182, 1)"];

  return (
    <CustomPage
      headerParameters={headerParameters}
      pageName={"Statistics"}
      title="מצב התקציב"
    >
      <IonGrid fixed={true} className="main-content">
        <IonRow>
          <IonCol size="12">
            <CustomHeaddingItem
              mainTitle={"חבילת הפרסום שלך"}
              subTitle={`חבילת  ${
                MemberDetailsCxt.ExtraDetailes
                  ? MemberDetailsCxt.ExtraDetailes.Package_Desc
                  : ""
              }`}
              icon={statisticsWhite}
              iconBackground="statistics-main"
              innerPage={true}
              linkItem={{
                textValue: "לשדרוג חבילת הפרסום",
                linkValue: "/Budget",
              }}
            />
          </IonCol>
        </IonRow>

        <IonRow className="ion-align-items-center">
          <div style={{ display: "flex", alignItems: "baseline", gap: "14px" }}>
            {!isMobile && (
              <h2 className="large-title bold-text padding-start-12">
                {!statB144.isStatEmpty &&
                statProducts &&
                statProducts?.length === 1
                  ? "סטטיסטיקות B144"
                  : "תמונת מצב כללית"}
              </h2>
            )}
            {month && MemberDetailsCxt.ExtraDetailes && (
              <div style={{ marginTop: "2px" }}>
                <CustomDateRangePicker
                  maxDate={new Date()}
                  onDateChange={(startDate: Date, endDate: Date) => {
                    if (startDate !== selectedStartDate) {
                      setSelectedStartDate(startDate);
                    }
                    if (endDate !== selectedEndDate) {
                      setSelectedEndDate(endDate);
                    }
                  }}
                  startDate={selectedStartDate}
                  endDate={selectedEndDate}
                  defaultStaticRanges={_defaultStaticRanges}
                  onApproveDates={() => setApprovedDateSelection(true)}
                />
              </div>
            )}
          </div>
        </IonRow>

        {((!!StatisticsCxt.statProducts?.length && isFullMonth) ||
          (StatisticsCxt.statProducts?.length === 1 &&
            !statB144.isStatEmpty &&
            !currentBudget?.isFixed &&
            statB144.StatsByTotalExposed)) && (
          <IonRow>
            <IonCol className="ion-no-padding">
              <IonGrid>
                <IonRow>
                  {/* card-1-start */}
                  <IonCol size="12" sizeMd="6" className="card-wrapper">
                    <IonCard mode="md" className="statistics-card">
                      <IonGrid>
                        <IonRow>
                          <IonCol className="card-title">
                            <IonIcon
                              icon={fact_check}
                              className="page-icon statistics"
                            />
                            <h3 className="bold-text ion-no-margin">
                              אז איך נראים סה"כ הנתונים?
                            </h3>
                          </IonCol>
                        </IonRow>
                        <IonGrid>
                          <IonRow
                            style={{
                              marginBlock: "24px",
                            }}
                          >
                            <CustomTrend
                              value={totalCall}
                              PrevValue={totalCallPrev}
                              header="שיחות"
                              subHeader="כמה שיחות נכנסו בחודש האחרון"
                              icon={ring_volume}
                            />
                          </IonRow>
                          <IonRow
                            style={{
                              marginBlock: "24px",
                            }}
                          >
                            <CustomTrend
                              value={totalWhatsApp}
                              PrevValue={totalWhatsAppPrev}
                              header="הקלקות על וואטסאפ"
                              subHeader="כמה פניות וואטסאפ נכנסו בחודש האחרון"
                              icon={whatsapp_click}
                            />
                          </IonRow>
                          <IonRow
                            style={{
                              marginBlock: "24px",
                            }}
                          >
                            <CustomTrend
                              value={totalClickes}
                              PrevValue={totalClickesPrev}
                              header="הקלקות"
                              subHeader="כמה לחצו על המודעה של הקמפיין"
                              icon={touch_app}
                            />
                          </IonRow>
                        </IonGrid>
                      </IonGrid>
                    </IonCard>
                  </IonCol>

                  {/* card-2-start */}
                  {StatisticsCxt.statProducts.length === 1 &&
                    !statB144.isStatEmpty &&
                    !currentBudget?.isFixed &&
                    statB144.StatsByTotalExposed && (
                      <IonCol size="12" sizeMd="6" className="card-wrapper">
                        <IonCard mode="md" className="statistics-card">
                          <IonGrid>
                            <IonRow>
                              <IonCol className="card-title">
                                <IonIcon
                                  icon={megaphone}
                                  className="page-icon statistics"
                                />
                                <h3 className="bold-text ion-no-margin">
                                  ניצול לפי יח' פרסום
                                </h3>
                              </IonCol>
                            </IonRow>
                            <IonRow className="ion-justify-content-center">
                              <IonCol className="ion-text-center" size="6">
                                <DoughnutChart
                                  value={
                                    statB144.StatsByTotalExposed?.Clicks_Total
                                      ? ((statB144?.StatsByTotalExposed
                                          .Clicks_Charged ?? 0) /
                                          statB144.StatsByTotalExposed
                                            .Period_Budget) *
                                        100
                                      : 0
                                  }
                                  chartWidth={!isMobile ? 135 : 118}
                                  chartWeight={65}
                                  titleColor="#16254f"
                                  chartBackgroundColor="#FFCBAD"
                                  isGradient
                                />
                              </IonCol>
                              <IonCol
                                size="5"
                                className="ion-align-self-center"
                              >
                                <span
                                  className="bold-text"
                                  style={{ color: "#16254f" }}
                                >
                                  {`נוצלו ${
                                    statB144?.StatsByTotalExposed
                                      .Clicks_Charged ?? 0
                                  } יח' פרסום`}
                                </span>
                                <br />
                                <span>{`מתוך ${
                                  statB144.StatsByTotalExposed?.Period_Budget ??
                                  0
                                } יח' פרסום`}</span>
                                {!isMobile && (
                                  <div
                                    className="bold-text"
                                    style={{
                                      color: "#16254f",
                                      marginTop: "6px",
                                    }}
                                  >
                                    {`סה"כ תקציב שנוצל: ${
                                      statB144.StatsByTotalExposed
                                        ?.Amount_Charged ?? 0
                                    } ₪`}
                                  </div>
                                )}
                              </IonCol>
                              {isMobile && (
                                <div
                                  className="bold-text"
                                  style={{
                                    color: "#16254f",
                                    marginTop: "6px",
                                  }}
                                >
                                  {`סה"כ תקציב שנוצל: ${
                                    statB144.StatsByTotalExposed
                                      ?.Amount_Charged ?? 0
                                  } ₪`}
                                </div>
                              )}
                            </IonRow>
                          </IonGrid>
                        </IonCard>
                      </IonCol>
                    )}
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        )}

        {statProducts && statProducts.length > 1 && isMobile && (
          <IonRow className="ion-justify-content-end">
            <IonCol sizeMd="4">
              <IonButton
                className="main-button"
                style={{ margin: "0" }}
                expand="block"
                onClick={() => {
                  setIsShowCampaign(true);
                }}
              >
                לפירוט כל הקמפיינים
              </IonButton>
            </IonCol>
          </IonRow>
        )}
        {statProducts && statProducts.length > 1 && !isMobile && (
          <IonRow className="ion-justify-content-end">
            <IonCol className="ion-no-padding">
              {!!StatisticsCxt.statProducts?.length ? (
                StatisticsCxt.statProducts
                  .filter((m) => m.ProdMainName !== "מדריך 144")
                  .map((m, index) => (
                    <IonGrid key={index} className="ion-no-padding">
                      <IonRow className="ion-no-padding">
                        <IonCol className="ion-no-padding">
                          <MemberCampaignData
                            Product={m}
                            month={month}
                            year={year}
                            LimitMonth={limitMonth}
                            LimitYear={limitYear}
                            isShowEmptyData={!isFullMonth}
                          ></MemberCampaignData>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  ))
              ) : (
                <IonGrid className="ion-no-padding">
                  <IonRow className="ion-no-padding">
                    <IonCol className="ion-no-padding">
                      <MemberCampaignData
                        isShowEmptyData={!isFullMonth}
                      ></MemberCampaignData>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              )}
            </IonCol>
          </IonRow>
        )}
        {isShowCampaign && (
          <MemberCampaign
            month={month}
            year={year}
            LimitMonth={limitMonth}
            LimitYear={limitYear}
            show={isShowCampaign}
            Package_Desc={MemberDetailsCxt.ExtraDetailes!.Package_Desc}
            closeModal={() => {
              setIsShowCampaign(false);
            }}
          ></MemberCampaign>
        )}

        {!statB144.isStatEmpty && statProducts && statProducts?.length > 1 && (
          <IonRow className="ion-padding">
            <h2 className="large-title bold-text">B144 סטטיסטיקות</h2>
          </IonRow>
        )}

        {/* {!StatisticsCxt.loading && ( */}
        <>
          {!statB144.isStatEmpty && (
            <>
              {(StatisticsCxt.statProducts?.length ?? 0) > 1 && (
                <IonRow>
                  {/* card-1-start */}
                  <IonCol size="12" sizeMd="6" className="card-wrapper">
                    <IonCard mode="md" className="statistics-card">
                      <IonGrid>
                        <IonRow>
                          <IonCol className="card-title">
                            <IonIcon
                              icon={fact_check}
                              className="statistics page-icon"
                            />
                            <h3 className="bold-text ion-no-margin">
                              פירוט ביצועי הקמפיין
                            </h3>
                          </IonCol>
                        </IonRow>
                        {b144ProdDetail && (
                          <IonGrid>
                            <IonRow
                              style={{
                                marginBlock: "24px",
                              }}
                            >
                              <CustomTrend
                                value={b144ProdDetail.CallsCount}
                                PrevValue={
                                  b144ProdDetailPrev
                                    ? b144ProdDetailPrev.CallsCount
                                    : 0
                                }
                                header="שיחות"
                                subHeader="כמה שיחות נכנסו בחודש האחרון"
                                icon={ring_volume}
                              ></CustomTrend>
                            </IonRow>
                            <IonRow
                              style={{
                                marginBlock: "24px",
                              }}
                            >
                              <CustomTrend
                                value={b144ProdDetail.WhatsAppCount}
                                PrevValue={
                                  b144ProdDetailPrev
                                    ? b144ProdDetailPrev.WhatsAppCount
                                    : 0
                                }
                                header="הקלקות על וואטסאפ"
                                subHeader="כמה פניות וואטסאפ נכנסו בחודש האחרון"
                                icon={whatsapp_click}
                              />
                            </IonRow>

                            <IonRow
                              style={{
                                marginBlock: "24px",
                              }}
                            >
                              <CustomTrend
                                value={b144ProdDetail.Impressions}
                                PrevValue={
                                  b144ProdDetailPrev
                                    ? b144ProdDetailPrev.Impressions
                                    : 0
                                }
                                header="חשיפות"
                                subHeader="כמה חשיפות היו"
                                icon={touch_app}
                              ></CustomTrend>
                            </IonRow>
                          </IonGrid>
                        )}
                      </IonGrid>
                    </IonCard>
                  </IonCol>

                  {/* card-2-start */}
                  {!statB144.isStatEmpty &&
                    !currentBudget?.isFixed &&
                    statB144.StatsByTotalExposed && (
                      <IonCol size="12" sizeMd="6" className="card-wrapper">
                        <IonCard mode="md" className="statistics-card">
                          <IonGrid style={{ height: "100%" }}>
                            <IonRow>
                              <IonCol className="card-title">
                                <IonIcon
                                  icon={megaphone}
                                  className="page-icon statistics"
                                />
                                <h3 className="bold-text ion-no-margin">
                                  ניצול לפי יח' פרסום
                                </h3>
                              </IonCol>
                            </IonRow>
                            <IonRow
                              className="ion-justify-content-center"
                              style={{
                                height: "calc(100% - 42px)",
                                alignItems: "center",
                              }}
                            >
                              <IonCol className="ion-text-center" size="6">
                                <DoughnutChart
                                  value={
                                    statB144.StatsByTotalExposed?.Clicks_Total
                                      ? ((statB144.StatsByTotalExposed
                                          ?.Clicks_Charged ?? 0) /
                                          statB144.StatsByTotalExposed
                                            .Clicks_Total) *
                                        100
                                      : 0
                                  }
                                  chartWidth={!isMobile ? 135 : 118}
                                  chartWeight={65}
                                  titleColor="#16254f"
                                  chartBackgroundColor="#FFCBAD"
                                  isGradient
                                />
                              </IonCol>
                              <IonCol
                                size="5"
                                className="ion-align-self-center"
                              >
                                <span
                                  className="bold-text"
                                  style={{ color: "#16254f" }}
                                >
                                  {`נוצלו ${statB144.StatsByTotalExposed?.Clicks_Charged} יח' פרסום`}
                                </span>
                                <br />
                                <span>{`מתוך ${statB144.StatsByTotalExposed?.Clicks_Total} יח' פרסום`}</span>
                                {statB144.StatsByTotalExposed?.Amount_Charged &&
                                  !isMobile && (
                                    <div
                                      className="bold-text"
                                      style={{
                                        color: "#16254f",
                                        marginTop: "6px",
                                      }}
                                    >
                                      {`סה"כ תקציב שנוצל: ${statB144.StatsByTotalExposed?.Amount_Charged} ₪`}
                                    </div>
                                  )}
                              </IonCol>
                              {statB144.StatsByTotalExposed?.Amount_Charged &&
                                isMobile && (
                                  <div
                                    className="bold-text"
                                    style={{
                                      color: "#16254f",
                                      marginTop: "6px",
                                    }}
                                  >
                                    {`סה"כ תקציב שנוצל: ${statB144.StatsByTotalExposed?.Amount_Charged} ₪`}
                                  </div>
                                )}
                            </IonRow>
                          </IonGrid>
                        </IonCard>
                      </IonCol>
                    )}
                </IonRow>
              )}

              {statB144.StatsByCategories.length > 0 &&
                statB144.StatsBytransactionsSources.length > 0 && (
                  <IonRow>
                    {statB144.StatsByCategories.length > 0 && (
                      <IonCol size="12" sizeMd="6" className="card-wrapper">
                        <IonCard mode="md" className="statistics-card">
                          <IonGrid>
                            <IonRow>
                              <IonCol className="card-title">
                                <IonIcon
                                  icon={ListIcon}
                                  className="page-icon statistics"
                                />
                                <h3 className="bold-text ion-no-margin">
                                  לפי קטגוריות ( יח' פרסום לחיוב )
                                </h3>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                          <div
                            style={{
                              position: "absolute",
                              marginTop: "20px",
                            }}
                          >
                            <img
                              src={exclamationMark}
                              alt="exclamation mark icon"
                            />
                          </div>
                          <div className="info-tag">
                            <span style={{ color: "#383b41" }}>
                              תוצאות החיפוש של הגולשים לפי קטגוריות
                            </span>
                          </div>
                          <BarChart
                            chartData={{
                              labels: statB144.StatsByCategories.sort(
                                (a, b) =>
                                  b.SumCountOfClicksTotal -
                                  a.SumCountOfClicksTotal
                              ).map((stat) => stat.CatDesc),
                              data: statB144.StatsByCategories.sort(
                                (a, b) =>
                                  b.SumCountOfClicksTotal -
                                  a.SumCountOfClicksTotal
                              ).map((stat) => stat.SumCountOfClicksTotal),
                            }}
                            chartHeight={
                              statB144.StatsByCategories.length > 1 &&
                              statB144.StatsByCategories.length < 6
                                ? statB144.StatsByCategories.length * 10
                                : !isMobile
                                ? 50
                                : 100
                            }
                            titleColor="#16254f"
                          />
                        </IonCard>
                      </IonCol>
                    )}
                    {statB144.StatsBytransactionsSources.length > 0 && (
                      <IonCol size="12" sizeMd="6" className="card-wrapper">
                        <IonCard mode="md" className="statistics-card">
                          <IonGrid>
                            <IonRow>
                              <IonCol className="card-title">
                                <IonIcon
                                  icon={graph}
                                  className="page-icon statistics"
                                />
                                <h3 className="bold-text ion-no-margin">
                                  לפי מקורות ( יח' לפרסום לחיוב )
                                </h3>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                          <div
                            style={{
                              position: "absolute",
                              marginTop: "20px",
                            }}
                          >
                            <img
                              src={exclamationMark}
                              alt="exclamation mark icon"
                            />
                          </div>
                          <div className="info-tag">
                            <span style={{ color: "#383b41" }}>
                              דירוג המקורות דרכם הגיעו אל העסק שלכם
                            </span>
                          </div>
                          <div style={{ display: "flex" }}>
                            {!isMobile &&
                              statB144.StatsBytransactionsSources.length >
                                6 && (
                                <img
                                  src={
                                    isSwitchArrow.StatsBytransactionsSources
                                      ? arrowLeftEmpty
                                      : arrowLeftFilled
                                  }
                                  alt="arrow right"
                                  style={{
                                    transform: "rotate(180deg)",
                                    cursor: isSwitchArrow.StatsBytransactionsSources
                                      ? "default"
                                      : "pointer",
                                  }}
                                  onClick={() => {
                                    setTransactionsToShow(
                                      StatisticsCxt.statB144
                                        .StatsBytransactionsSources.length
                                    );
                                    setIsSwitchArrow((current) => ({
                                      ...current,
                                      StatsBytransactionsSources: true,
                                    }));
                                  }}
                                />
                              )}
                            <div style={{ width: "100%" }}>
                              <BarChart
                                chartData={{
                                  labels: StatisticsCxt.statB144.StatsBytransactionsSources.sort(
                                    (a, b) => b.CountClicks - a.CountClicks
                                  )
                                    .slice(
                                      transactionsToShow > 6
                                        ? transactionsToShow - 6
                                        : 0,
                                      transactionsToShow
                                    )
                                    .map((stat) => stat.SourceName),
                                  data: StatisticsCxt.statB144.StatsBytransactionsSources.sort(
                                    (a, b) => b.CountClicks - a.CountClicks
                                  )
                                    .slice(
                                      transactionsToShow > 6
                                        ? transactionsToShow - 6
                                        : 0,
                                      transactionsToShow
                                    )
                                    .map((stat) => stat.CountClicks),
                                }}
                                chartHeight={
                                  statB144.StatsBytransactionsSources.length >
                                    1 &&
                                  statB144.StatsBytransactionsSources.length < 6
                                    ? statB144.StatsBytransactionsSources
                                        .length * 10
                                    : !isMobile
                                    ? 50
                                    : 100
                                }
                                titleColor="#16254f"
                              />
                            </div>
                            {!isMobile &&
                              StatisticsCxt.statB144.StatsBytransactionsSources
                                .length > 6 && (
                                <img
                                  src={
                                    isSwitchArrow.StatsBytransactionsSources
                                      ? arrowLeftFilled
                                      : arrowLeftEmpty
                                  }
                                  alt="arrow left"
                                  style={{
                                    cursor: isSwitchArrow.StatsBytransactionsSources
                                      ? "pointer"
                                      : "default",
                                  }}
                                  onClick={() => {
                                    setTransactionsToShow(6);
                                    setIsSwitchArrow((current) => ({
                                      ...current,
                                      StatsBytransactionsSources: false,
                                    }));
                                  }}
                                />
                              )}
                          </div>
                        </IonCard>
                      </IonCol>
                    )}
                  </IonRow>
                )}

              {StatisticsCxt.statB144.StatsDaysByMonths.length > 0 && (
                <IonRow className="ion-justify-content-center">
                  <IonCol className="card-wrapper">
                    <IonCard
                      mode="md"
                      className="statistics-card"
                      style={{ height: "unset" }}
                    >
                      <IonRow className="ion-justify-content-center">
                        <IonCol size="12">
                          <IonGrid>
                            <IonRow>
                              <IonCol className="card-title">
                                <IonIcon
                                  icon={distrib}
                                  className="page-icon statistics"
                                />
                                <h3 className="bold-text ion-no-margin">
                                  התפלגות יח' פרסום לפי{" "}
                                  {isFullMonth ? "ימים" : "תאריכים"}
                                </h3>
                              </IonCol>
                            </IonRow>
                            {isFullMonth && (
                              <IonRow>
                                <span
                                  className="bold-text"
                                  style={{
                                    height: "12px",
                                    fontSize: "14px",
                                    lineHeight: 0.86,
                                    marginTop: "16px",
                                  }}
                                >{`${monthNames[month - 1]} ${year}`}</span>
                              </IonRow>
                            )}
                          </IonGrid>
                          <div
                            style={{
                              display: "flex",
                              marginInline: isMobile ? "-1rem" : "",
                            }}
                          >
                            {isMobile && (
                              <img
                                src={
                                  isSwitchArrow.StatsDaysByMonths
                                    ? arrowLeftEmpty
                                    : arrowLeftFilled
                                }
                                alt="arrow right"
                                style={{
                                  transform: "rotate(180deg)",
                                  cursor: isSwitchArrow.StatsDaysByMonths
                                    ? "default"
                                    : "pointer",
                                  display:
                                    !isFullMonth &&
                                    statB144.StatsDaysByMonths.length <= 15
                                      ? "none"
                                      : "block",
                                }}
                                onClick={() => {
                                  daysToShow !== days && setDaysToShow(days);
                                  setIsSwitchArrow((current) => ({
                                    ...current,
                                    StatsDaysByMonths: true,
                                  }));
                                }}
                              />
                            )}
                            <div
                              style={{
                                width: isMobile ? "95%" : "100%",
                                paddingRight: "3px",
                              }}
                            >
                              <BarChart
                                chartData={{
                                  labels: isFullMonth
                                    ? daysArr.slice(
                                        isMobile && daysToShow > 15
                                          ? daysToShow - 15
                                          : 0,
                                        daysToShow
                                      )
                                    : statB144.StatsDaysByMonths.slice(
                                        isMobile && daysToShow > 15
                                          ? Math.round(
                                              statB144.StatsDaysByMonths
                                                .length / 2
                                            )
                                          : 0,
                                        daysToShow
                                      ).map(
                                        (stat) =>
                                          `${stat.Day}/${stat.Month}/${stat.Year}`
                                      ),
                                  data: isFullMonth
                                    ? daysDataArr.slice(
                                        isMobile && daysToShow > 15
                                          ? daysToShow - 15
                                          : 0,
                                        daysToShow
                                      )
                                    : statB144.StatsDaysByMonths.slice(
                                        isMobile && daysToShow > 15
                                          ? Math.round(
                                              statB144.StatsDaysByMonths
                                                .length / 2
                                            )
                                          : 0,
                                        daysToShow
                                      ).map((stat) => stat.AllClicks),
                                }}
                                chartHeight={!isMobile ? 50 : 110}
                                chartWidth={"100%"}
                                chartType="vertical"
                                titleColor="#16254f"
                              />
                            </div>
                            {isMobile && (
                              <img
                                src={
                                  isSwitchArrow.StatsDaysByMonths
                                    ? arrowLeftFilled
                                    : arrowLeftEmpty
                                }
                                alt="arrow left"
                                style={{
                                  cursor: isSwitchArrow.StatsDaysByMonths
                                    ? "pointer"
                                    : "default",
                                  display:
                                    !isFullMonth &&
                                    statB144.StatsDaysByMonths.length <= 15
                                      ? "none"
                                      : "block",
                                }}
                                onClick={() => {
                                  daysToShow !== 15 && setDaysToShow(15);
                                  setIsSwitchArrow((current) => ({
                                    ...current,
                                    StatsDaysByMonths: false,
                                  }));
                                }}
                              />
                            )}
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonCard>
                  </IonCol>
                </IonRow>
              )}
            </>
          )}

          {!!statProducts?.length &&
            !statB144.isStatEmpty &&
            statB144.StatsByCatOrName.length > 0 && (
              <IonRow>
                {!!statProducts.length && (
                  <IonCol size="12" sizeMd="6" className="card-wrapper">
                    <IonCard mode="md" className="statistics-card">
                      <IonGrid>
                        {StatisticsCxt.actionSummery &&
                        StatisticsCxt.actionSummery.length > 0 ? (
                          <IonRow className="statistics-action-summery-wrapper">
                            <StatisticActionSummery
                              fromDate={selectedStartDate}
                              toDate={selectedEndDate}
                            />
                          </IonRow>
                        ) : (
                          <IonRow className="ion-justify-content-center">
                            <IonCol size="12" className="card-title">
                              <IonIcon
                                icon={important_devices}
                                className="page-icon statistics"
                              />
                              <h3 className="bold-text ion-no-margin">
                                על מה לחצו הגולשים
                              </h3>
                            </IonCol>
                            <IonCol size="6" className="ion-text-center">
                              <IonIcon
                                icon={blankProductIconOrange}
                                className="blank-list-icon"
                              />
                              <p>עדיין אין פעולות, אבל ממש בקרוב יהיו</p>
                            </IonCol>
                          </IonRow>
                        )}
                      </IonGrid>
                    </IonCard>
                  </IonCol>
                )}
                {!statB144.isStatEmpty && !!statB144.StatsByCatOrName.length && (
                  <IonCol size="12" sizeMd="6" className="card-wrapper">
                    <IonCard mode="md" className="statistics-card">
                      <IonGrid>
                        <IonRow>
                          <IonCol className="card-title">
                            <IonIcon
                              icon={search}
                              className="page-icon statistics"
                            />
                            <h3
                              className="bold-text ion-no-margin"
                              style={{ lineHeight: 1 }}
                            >
                              חיפוש גולשים לפי קטגוריאלי ושמי של העסק
                            </h3>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                      <div style={{ position: "absolute", marginTop: "20px" }}>
                        <img
                          src={exclamationMark}
                          alt="exclamation mark icon"
                        />
                      </div>
                      <div className="info-tag">
                        <span style={{ color: "#383b41" }}>
                          חלוקת האחוזים לפי חיפושי הגולשים
                        </span>
                      </div>
                      <IonRow>
                        <IonCol
                          style={{
                            display: "flex",
                            flexDirection: isMobile ? "row" : "column",
                            gap: isMobile ? "28px" : "16px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          className="ion-text-center"
                        >
                          <PieChart
                            chartData={{
                              data: StatisticsCxt.statB144.StatsByCatOrName.sort(
                                (a, b) => a.CountOfClicks - b.CountOfClicks
                              ).map((stat) => stat.CountOfClicks),
                              labels: [""],
                            }}
                            chartWidth={!isMobile ? 135 : 118}
                            backgroundColor={backgroundColor}
                            isGradient
                          />
                          <IonRow>
                            {StatisticsCxt.statB144.StatsByCatOrName.map(
                              (stat, i) => (
                                <IonCol
                                  size={
                                    StatisticsCxt.statB144.StatsByCatOrName
                                      .length === 1
                                      ? "12"
                                      : "6"
                                  }
                                  key={stat.SearchType}
                                >
                                  <span
                                    className="pieSpan"
                                    style={{
                                      backgroundColor: backgroundColor[i],
                                    }}
                                  />
                                  {stat.SearchType}
                                </IonCol>
                              )
                            )}
                          </IonRow>
                        </IonCol>
                      </IonRow>
                    </IonCard>
                  </IonCol>
                )}
              </IonRow>
            )}
        </>
        {/* )} */}

        {!StatisticsCxt.statProducts?.length && statB144.isStatEmpty && (
          <IonRow>
            <IonCol className="ion-text-center">
              <IonIcon
                icon={blankProductIconOrange}
                className="blank-list-icon"
              />
              <p>לא קיימים נתונים</p>
            </IonCol>
          </IonRow>
        )}
      </IonGrid>
    </CustomPage>
  );
};

export default Statistics;
