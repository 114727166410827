import { IonCard, IonCardContent, IonCol, IonGrid, IonRow } from "@ionic/react";
import React  from "react";
import CustomPage from "../components/utils/CustomElements/CustomPage";
import { HeaderParameters } from "../data/Header-Context";


export function PrivacyPolicyText () {
  return (
    <>
            <b>הגנה על פרטיות ו-Cookies</b>
            <ol>
            <li>מדיניות הפרטיות החלה על הגולש באתר או על בעל העסק המנוי על השירות היא כמפורט בלינק https://www.bezeq.co.il/media/PDF/serviceandsupport/private.pdf</li>
            <li>כמו כן יודגש כי האתר משתמש בעוגיות ("Cookies"), תגיות פיקסל וטכנולוגיות דומות. ה-Cookies הנם קבצי טקסט קטנים אשר מועברים לכונן הקשיח שבמחשבך האישי או למכשיר הטלפון הנייד שלך על ידי שרת אינטרנט, על מנת ששרתים אלה יוכלו לזהות אותך במהירות וביעילות כאשר אתה מבקר באתר, כאשר אתה חוזר ומבקר בו, או כשאתה מבקר באתרים אחרים. תגיות פיקסל הן קטעי קוד בדף אינטרנט, שמאפשרים לשרת האינטרנט לקרוא או להציב קובצי Cookies וכן להעביר אל שרתי האתר מידע הנוגע למבקר באתר, כמו כתובת ה-IP של המכשיר, השעה שבה נצפה הדף, סוג הדפדפן שבו נעשה שימוש וכד' (לכל הטכנולוגיות הללו ולדומותיהן נקרא להלן: "קוקיס")</li>
            <li>ה Cookies - מכילים מידע כדוגמת הדפים שבהם ביקרת, מהיכן הגעת לאתר, משך הזמן ששהית באתר וכן, מסייעים באיסוף מידע אודות הצפייה והשימוש באתרי אינטרנט בהם קיים פרסום ואודות הפרסומות אליהן נחשפת ועליהן הקלקת.</li>
            <li>ה Cookies  מאפשרים איסוף מידע על סמך השימוש שלך באתר ובאתרי האינטרנט בכלל, על סוג האתרים המועדף עליך, על סוג המידע שהנך מבקש לאתר בדרך כלל ברשת האינטרנט, על השירותים שבהם השתמשת באתרנו או באתרים אחרים, ועל האופן שבו אתה משתמש בהם כמו למשל כאשר אתה מבקר באתר שלנו, משתמש בשירותים שלנו ומקיים אינטראקציה כלשהי באתר.</li>
            <li>ה  Cookies  אינם מזהים אותך ספציפית, אך השימוש במידע הנאסף באמצעותם מאפשר לשפר את חווית המשתמש ואת האיכות הכוללת של השירותים שלנו. כך לדוגמא, שימוש Cookies יכול לחסוך עדכון פרטים כדוגמת שם משתמש וסיסמא בכל מקרה בו נדרש רישום וזיהוי במסגרת רכיבים שונים באתר ומאפשר הצגת תוכן פרסומי המבוסס על פעילותך.</li>
            <li>כאמור, השימוש ב Cookies נועד למטרות הפעלה, קידום ושיפור שירותים (קיימים ועתידיים) ועל מנת להציע לך תוכן מותאם אישית ורלוונטי עבורך לפי הפרופיל שאספנו.</li>
            <li>באמצעות ה Cookies וספקי צד ג' אנחנו עשויים לפרסם את האתר ואודות האתר באתרים שונים בהם תבקר, כך שבמהלך ביקורך באתרים אלה ייתכן שייאסף מידע לגביך ונוכל לעשות בו שימוש לצורך האמור לעיל.</li>
            <li>לידיעתך, ייתכן כי חלק מהמודעות, שבהם אתה צופה בעת הביקור באתר מגיעות משרתים של צדדים שלישיים, המנהלים את מערך הגשת הפרסומות באתר. לצורך ניהול הפרסומות עושים גם צדדים שלישיים אלה שימוש ב Cookies, בין באתר זה ובין באתרים אחרים.</li>
            <li>באפשרותך להגדיר את דפדפן האינטרנט שלך כך שהוא ידחה/ ימחוק קבצי ה Cookies. האופן שבו תעשה זאת תלוי בדפדפן האינטרנט שבו הנך משתמש ולכן לא נוכל באופן מעשי להסביר בזאת את האופן שבו ניתן לדחות את אותם הקבצים. אנא פנה לאתר האינטרנט של היצרן הרלוונטי של דפדפן האינטרנט שבו אתה משתמש (אינטרנט אקספלורר, כרום, פיירפוקס וכו'), ושם תוכל לקבל את כל המידע הדרוש לך. אנחנו לא ממליצים להשתמש באפשרות זו, כיוון שהיא הופכת את הגלישה באתר שלנו לחוויה פחות מהנה, מאחר שהאתר אינו יכול לזהות אותך ואת ההעדפות שלך לקבלת מידע. ייתכן גם שנטרול ה Cookies יגרום לכך שלא תוכל להשתמש בחלק מהשירותים והתכונות באתרנו ובאתרי אינטרנט אחרים.</li>
            <li>אנו עשויים לאסוף ולהעביר לצדדים שלישיים נתונים אנונימיים על המשתמשים שלנו במונחים של דפוסי גלישה ומידע אחר רלוונטי ועוד, כפי שפורט לעיל.</li>
            <li>מומלץ לבחון בכל גלישה לאתר כלשהו ברשת האינטרנט, את מדיניות הפרטיות של אותו אתר.</li>
            <li>שימושך באתרנו מעיד על הסכמתך המוחלטת והבלתי חוזרת לשימושנו ב Cookies ובמידע שנאסף כאמור לעיל.</li>
            <li>עיון במידע ותיקונו: בהתאם לחוק, אתה רשאי לעיין במידע אודותיך השמור במאגרי החברה.  כמו כן, הנך רשאי לבקש את תיקונו או מחיקתו של המידע, אולם זכות זו אינה מוחלטת והיא כפופה להוראות החוק ולזכויות בזק וצדדים שלישיים, ועל כן בזק רשאית לדחות את בקשתך או לקבלה באופן חלקי. בקשות כאמור ניתן להעביר אלינו בכתב בדואר אלקטרוני בכתובת: ca 2@bezeq.co.il תוך ציון נושא הפנייה בכותרת המייל, או בפקס שמספרו 1-800-222-110 בציון כל הפרטים הנדרשים, נימוקים מדוע אתה סבור שיש מידע אודותיך אצלנו ופירוט סוג המידע שבו אתה מבקש לעיין ו/או לתקן ו/או למחוק.</li>
            </ol>
    </>
  );
};

const PrivacyPolicy: React.FC = () => {
  const headerParameters: HeaderParameters = {
    isShowLogo: true,
    backButton: { isShowMobile: true },
    isShowNotification: true,
  };
  

  return (
    <CustomPage
      headerParameters={headerParameters}
      pageName={"Help"}
      title="מדיניות פרטיות"
    >
      <IonGrid fixed={true} className="main-content">
        <IonRow>
<IonCol className="card-desc">
  <IonRow>
  <IonCol size="12" >
          <IonCard mode="md" className="missing-info-card">
            
                  <IonCardContent>
           <PrivacyPolicyText />
            </IonCardContent></IonCard>
          </IonCol>
  </IonRow>
</IonCol>
          
        </IonRow>
      </IonGrid>
    </CustomPage>
  );
};

export default PrivacyPolicy;
