import React, { FC, ComponentProps, useContext } from "react";
import { download } from "../../../images/index";
import { IonButton, IonIcon, isPlatform } from "@ionic/react";
import jsPDF from "jspdf";
import { FilesystemDirectory, Plugins } from "@capacitor/core";
import { toast } from "react-toastify";
//import CustomLoader from "./CustomLoader";
import { isMobile } from "react-device-detect";
import NativeApp from "../../../utils-ts/NativeApp";
import GlobalContext from '../../../data/Global-Context';
type IonButtonProps = ComponentProps<typeof IonButton>;

interface ButtonProps extends IonButtonProps {
  pdf: jsPDF;
  icon?: string;
  btnTitle: string;
  btnClass?: string; //icon/iconAndTitle/iconAndTitleRow/title
  callbackfunc?: () => void;
}

const CustomPdfButton: FC<ButtonProps> = ({ pdf, ...props }) => {
  //const [isShowLoader, setIsShowLoader] = useState(false);
  const globalCtx = useContext(GlobalContext);

  const setIsShowLoader = (loading:boolean)=>{
   
   globalCtx.setLoader(loading);
 
  };
  const { DownloadFile, IsNativeApp } = NativeApp();
  const generatePDF = () => {
    setIsShowLoader(true);
    if (pdf) {
      if (isPlatform("mobileweb") || !isMobile) {
        pdf.save(`${new Date().toISOString()}.pdf`);
        setIsShowLoader(false);
      } else {
        let blob = pdf.output("blob");
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          var base64data: any = reader!.result;
          if (IsNativeApp) {
            DownloadFile(base64data);
          } else {
            Plugins.Filesystem.appendFile({
              data: base64data,
              path: `${new Date().toISOString()}.pdf`,
              directory: FilesystemDirectory.Documents,
            }).then((c) => {
              setIsShowLoader(false);
              toast("קובץ נשמר בהצלחה בתיקיית מסמכים");
            });
          }
          setIsShowLoader(false);
        };
      }
      if (props.callbackfunc) {
        props.callbackfunc();
      }
    }
  };

  const btnHandler = () => {
    switch (props.btnClass) {
      case "iconAndTitleRow":
        return (
          <>
            <IonIcon icon={props.icon} />
            {props.btnTitle}
          </>
        );
      default:
        return (
          <>
            <IonIcon icon={props.icon ? props.icon : download} />
            {!isMobile && props.btnTitle}
          </>
        );
    }
  };

  return (
    <div className={`pdf-btn-wrapper ${props.btnClass && props.btnClass}`}>
      <IonButton
        className="button-flex-rows"
        fill={props.btnClass ? "solid" : "clear"}
        onClick={() => {
          generatePDF();
        }}
        {...props}
      >
        {btnHandler()}
      </IonButton>
      {/* <CustomLoader isOpen={isShowLoader}></CustomLoader> */}
    </div>
  );
};

export default CustomPdfButton;
