import React, { useContext, useEffect, useRef, useState } from 'react';
import { IonGrid, IonRow, IonCol, IonRadioGroup, IonItem, IonLabel, IonRadio, IonListHeader, IonCheckbox, IonText } from '@ionic/react';

import { useForm } from 'react-hook-form';

import CustomForm from '../utils/CustomElements/CustomForm';
import CustomPublishButton from '../utils/CustomElements/CustomPublishButton';
import CustomModal from '../utils/CustomElements/CustomModal/CustomModal';
import MemberDetailsContext from '../../data/MemberDetailes-context';
import FrmCities from './FrmCities';
import FrmRegions from './FrmRegions';
import { isMobile } from 'react-device-detect';

import SelfServiceContext from '../../data/SelfService-context';

import './ServiceAreas.scss';

import { toast } from 'react-toastify';
import UserContext from '../../data/AuthContextProvider';
import { serviceAreaConfirm } from '../../images';
import { config } from '../../Constants';

const ServiceAreas: React.FC<{
  show: boolean;
  closeModal: () => void;
  onSave: () => void;
}> = (props) => {
  const { handleSubmit, formState, errors, control, register } = useForm({
    mode: 'onChange',
  });

  const { dirty } = formState;
  const MemberDetailsCxt = useContext(MemberDetailsContext);
  const SelfCtx = useContext(SelfServiceContext);
  const { authValues } = useContext(UserContext);

  //const [showMainModal, setShowMainModal] = useState<boolean>(props.show);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [allowSave, setAllowSave] = useState<boolean>(false);
  const [error, setError] = useState('');

  const [tempServiceAreaType, setTempServiceAreaType] = useState<'country' | 'region' | 'city'>();

  const [hasDelivery, setHasDelivery] = useState<boolean>();
  const [doDelivery, setDoDelivery] = useState<boolean | undefined>();
  //const refCbxDoDelivery = useRef<HTMLIonCheckboxElement>(null);
  const refCbxConfirm = useRef<HTMLIonCheckboxElement>(null);

  useEffect(() => {
    if ((authValues && authValues.IsSelfRegister == false) || !!config.otef_israel) {
      if (MemberDetailsCxt.BusinessServiceArea == null) {
        MemberDetailsCxt.initServiceAreasContext();
      } else {
        setTempServiceAreaType(MemberDetailsCxt.BusinessServiceArea.ServiceAreaType);
        setHasDelivery(MemberDetailsCxt.BusinessServiceArea.HasDelivery);
        if (MemberDetailsCxt.BusinessServiceArea.HasDelivery) {
          setDoDelivery(MemberDetailsCxt.BusinessServiceArea.DoDelivery);
        }
      }
    }
  }, []);

  const onAreaTypeChange_callback = (event: CustomEvent) => {
    setError('');
    if (tempServiceAreaType == 'city' || tempServiceAreaType == 'region') {
      toast.info('שינוי אזורי השירות מסיר את הבחירות');
    }
    setTempServiceAreaType(event.detail.value);
  };

  const saveHandler = (data: any) => {
    setError('');
    const area_type = data.ServiceAreas;
    if (!area_type) {
      setError('נא לבחור סוג אזור שירות');
      return;
    } else {
      if (area_type == 'city') {
        if (!data.cities_arr || data.cities_arr.length == 0 || data.cities_arr.length > 15) {
          setError('נא לבחור עד 15 ערים / יישובים');
          return;
        }
        if (MemberDetailsCxt.BusinessServiceArea?.MemberCity.City_Code &&
          MemberDetailsCxt.BusinessServiceArea?.MemberCity.City_Code > 0) {
          const bus_city = data.cities_arr.find((c: any) => {
            return c.value == MemberDetailsCxt.BusinessServiceArea?.MemberCity.City_Code;
          });
          if (bus_city == null) {
            setError('חובה לבחור את יישוב העסק');
            return;
          }
        }
      } else if (area_type == 'region') {
        if (!data.regions_arr || data.regions_arr.length == 0 || data.regions_arr.length > 3) {
          setError('נא לבחור עד 3 אזורים');
          return;
        }
      }
    }

    setShowConfirmation(true);
  };

  const cbxConfirmChanged = () => {
    setAllowSave(refCbxConfirm.current!.checked);
  };
  const saveServiceAreas = (data: any) => {
    let areasList: number[] = [];
    if (data.ServiceAreas == 'city') {
      areasList = data.cities_arr.map((c: any) => {
        return c.value;
      });
    } else if (data.ServiceAreas == 'region') {
      areasList = data.regions_arr;
    } else if (data.ServiceAreas == 'country') {
      areasList = [999];
    }

    SelfCtx.saveServiceAreas(true, data.ServiceAreas, areasList, doDelivery).then((res: any) => {
      MemberDetailsCxt.initServiceAreasContext().then((res: any) => {
        setShowConfirmation(false);

        props.closeModal();
        props.onSave();
      });
    });
  };

  return (
    <React.Fragment>
      <CustomModal
        pageName="ServiceAreas"
        headerParameters={{
          isPageHaveChanges: dirty,
          isShowLogo: isMobile,
          modalType: 'modal',
        }}
        closeModal={() => {
          props.closeModal();
        }}
        title="אזורי שירות"
        subTitle="בחירת אזורי השרות תשפיע על ההופעה שלך בתוצאות החיפוש.
        יש לבחור רק את אזורי השרות בהם העסק שלך עובד."
        isOpen={props.show}>
        <CustomForm onSubmit={handleSubmit(saveHandler)} errors={errors}>
          <IonGrid className="ion-margin-top">
            <IonRow>
              <IonCol className="ion-no-padding">
                <IonRadioGroup name="ServiceAreas" onIonChange={onAreaTypeChange_callback} ref={register} value={tempServiceAreaType}>
                  <IonListHeader>
                    <IonLabel>בחר את סוג אזורי השירות</IonLabel>
                  </IonListHeader>
                  <IonRow>
                    <IonCol size="4" sizeMd="2" className="ion-no-padding">
                      <IonItem mode="md" lines="none" className="item-radio">
                        <IonLabel className="item-radio-label">ארצי</IonLabel>
                        <IonRadio mode="md" slot="start" className="item-radio-btn" value="country" />
                      </IonItem>
                    </IonCol>
                    <IonCol size="4" sizeMd="2" className="ion-no-padding">
                      <IonItem mode="md" lines="none" className="item-radio">
                        <IonLabel>אזורי</IonLabel>
                        <IonRadio mode="md" slot="start" className="item-radio-btn" value="region" />
                      </IonItem>
                    </IonCol>
                    <IonCol size="4" sizeMd="2" className="ion-no-padding">
                      <IonItem mode="md" lines="none" className="item-radio">
                        <IonLabel>ערים</IonLabel>
                        <IonRadio mode="md" slot="start" className="item-radio-btn" value="city" />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                </IonRadioGroup>
              </IonCol>
            </IonRow>
            <IonRow style={{ minHeight: (window.innerWidth > 768 ? 272 : 170) - (hasDelivery ? 78 : 0) }}>
              <IonCol>
                {tempServiceAreaType == 'city' && <FrmCities control={control} errors={errors} register={register} />}
                {tempServiceAreaType == 'region' && <FrmRegions control={control} errors={errors} register={register} />}
              </IonCol>

              {error && (
                <IonCol size="12" className="ion-text-center">
                  <IonText color="danger">
                    <p>{error}</p>
                  </IonText>
                </IonCol>
              )}
            </IonRow>
            {hasDelivery && (
              <IonRow>
                <IonCol>
                  <IonItem lines="none" className="item-cbx-cont">
                    <IonLabel>עסק עושה משלוחים</IonLabel>
                    <IonCheckbox
                      slot="start"
                      name="cbx_confirm"
                      checked={doDelivery}
                      onIonChange={(event: CustomEvent) => {
                        setDoDelivery(event.detail.checked);
                      }}></IonCheckbox>
                  </IonItem>
                </IonCol>
              </IonRow>
            )}
          </IonGrid>
          <CustomPublishButton fixed={tempServiceAreaType == 'country' || (!hasDelivery && tempServiceAreaType == 'region')} />
        </CustomForm>
      </CustomModal>

      <CustomModal
        pageName={'ServiceAreasConfirm'}
        headerParameters={{
          isPageHaveChanges: false,
          modalType: 'confirm',
        }}
        closeModal={() => {
          if (showConfirmation) {
            setShowConfirmation(false);
          }
        }}
        isOpen={showConfirmation}>
        <CustomForm onSubmit={handleSubmit(saveServiceAreas)} errors={errors}>
          <div className="ion-text-center ion-margin-bottom ion-padding-bottom">
            <img src={serviceAreaConfirm} />
          </div>
          <h2 className="ion-text-center"> שים לב</h2>
          <IonItem lines="none" className="item-cbx-cont">
            <IonLabel>אני מאשר שאני מבין ששינוי אזור השירות משנה את קמפיין הפרסום ואת תוצאוציו.</IonLabel>
            <IonCheckbox slot="start" name="cbx_confirm" ref={refCbxConfirm} onIonChange={cbxConfirmChanged}></IonCheckbox>
          </IonItem>
          <div className="ion-margin-top">
            <small color="light">שים לב, שינויים שבוצעו באופן עצמאי באזורי שירות בדיוק כאילו בוצעו על ידי אחד מנציגי השירות</small>
          </div>
          <CustomPublishButton disabled={!allowSave} />
        </CustomForm>
      </CustomModal>
    </React.Fragment>
  );
};

export default ServiceAreas;
