export const ClearHtmlFromString = (text: string) => {
  var span = document.createElement('span');
  span.innerHTML = ClearImageFromString(text);
  var cleanText = span.textContent || span.innerText;
  return cleanText ;
};

export const ClearImageFromString = (text: string) => {
  
  return text.replace(/<img[^>]*>/g,"");

};
