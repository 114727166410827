import React, { useEffect, useRef, useState } from 'react';

import { IonGrid, IonRow, IonCol, IonButton, IonInput, IonItem, IonLabel, IonText, IonIcon } from '@ionic/react';
import UserContext from '../../data/AuthContextProvider';
import CustomAlert from '../../components/utils/CustomElements/CustomAlert';
import { getNotificationToken } from '../../firebaseInit';
import { HeaderParameters } from '../../data/Header-Context';
import CustomPage from '../../components/utils/CustomElements/CustomPage';
import './login.scss';
import CustomForm from '../../components/utils/CustomElements/CustomForm';
import CustomPublishButton from '../../components/utils/CustomElements/CustomPublishButton';
import { useForm } from 'react-hook-form';
import CustomInput from '../../components/utils/CustomElements/CustomInput';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';
import CustomModal from '../../components/utils/CustomElements/CustomModal/CustomModal';
import { PrivacyPolicyText } from '../PrivacyPolicy';
import { isMobile } from 'react-device-detect';
const Login: React.FC = () => {
  const { handleSubmit, formState, errors, control } = useForm({
    mode: 'onChange',
  });
  const userCtx = React.useContext(UserContext);
  const [phoneNum, setPhoneNum] = useState<string>();
  const [showAlert, setShowAlert] = useState(false);
  const [showPrivacyPolicy,setShowPrivacyPolicy]= useState(false);
  const [error, setError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [sentSMSStatus, setSentSMSStatus] = useState(false);
  const [loginType, setLoginType] = useState<'SMS' | 'PASSWORD' | 'TEMP_PASS'>('SMS');
  const [passInputType,setPassInputType] = useState<'password' | 'text'>('password');
  const [tempUserToken, setTempUserToken] = useState('');
  const phoneNumRef = useRef<HTMLIonInputElement>(null);
  const verificationCodeRef = useRef<HTMLIonInputElement>(null);

  const userNameRef = useRef<HTMLIonInputElement>(null);
  const passwordRef = useRef<HTMLIonInputElement>(null);

  const pass1Ref = useRef<HTMLIonInputElement>(null);
  const pass2Ref = useRef<HTMLIonInputElement>(null);

  useEffect(() => {
    let params = new URLSearchParams(decodeURIComponent(window.location.search));
    let memberPhone = params.get('memberPhone');
    let destination = params.get('destination');

    if (memberPhone && destination) {
      window.history.pushState({}, document.title, '/' + destination);
      verificationCodeHandler(memberPhone!);
    }
  }, []);

  const verificationCodeHandler = async (memberPhone?: string) => {
    let enteredPhoneNum;
    if (memberPhone) {
      enteredPhoneNum = memberPhone;
    } else {
      enteredPhoneNum = phoneNumRef.current?.value;
      if (!enteredPhoneNum || enteredPhoneNum.toString().trim().length === 0) {
        return;
      }
      enteredPhoneNum = enteredPhoneNum.toString();
    }

    const captcha = 'bsoft123';
    let res = await userCtx.verificationCode(enteredPhoneNum, captcha);
    if (res != '') {
      setSentSMSStatus(false);
      setError(res);
      setShowAlert(true);
    } else {
      setPhoneNum(enteredPhoneNum.toString());
      setSentSMSStatus(true);
    }
  };

  const verificationUserPassword = async () => {
    
    const user_code = userNameRef.current?.value;
    const pass = passwordRef.current?.value;
    if (!user_code || user_code.toString().trim().length === 0) {
      return;
    }

    if (!pass || pass.toString().trim().length === 0) {
      return;
    }

    getNotificationToken()
      .then((firebaseToken) => {
        loginWithUserAndPass(user_code.toString(), pass.toString(), firebaseToken);
      })
      .catch((err) => {
        loginWithUserAndPass(user_code.toString(), pass.toString(), '');
      });
  };

  const loginHandler = async () => {
    const enteredVerificationCode = verificationCodeRef.current?.value;
    if (!enteredVerificationCode || enteredVerificationCode.toString().trim().length === 0) {
      return;
    }

    // if (phoneNum === "0506938950") {
    //   //Iphone - test phone number
    //   loginWithNotificationToken(enteredVerificationCode.toString(), "");
    // } else {
    getNotificationToken()
      .then((firebaseToken) => {
        loginWithNotificationToken(enteredVerificationCode.toString(), firebaseToken);
      })
      .catch((err) => {
        loginWithNotificationToken(enteredVerificationCode.toString(), '');
      });
    // }
  };

  const loginWithNotificationToken = async (enteredVerificationCode: string, firebaseToken: any) => {
    let res = await userCtx.login(phoneNum!, enteredVerificationCode.toString(), firebaseToken);

    //~~~ Login - Test ~~~
    //let res = await userCtx.loginTest();

    if (res != '') {
      setError(res || 'ארעה שגיאה בכניסה למערכת');
      setShowAlert(true);
    }
  };

  const loginWithUserAndPass = async (userCode: string, pass: string, firebaseToken: any) => {
    //let res = await
    userCtx.loginUser(userCode, pass, firebaseToken).then((res: any) => {
      if (res.IsTempUser && res.TempUserToken) {
        setTempUserToken(res.TempUserToken);
        setLoginType('TEMP_PASS');
      } else if (res != '') {
        setError(res || 'ארעה שגיאה בכניסה למערכת');
        setShowAlert(true);
      }
    });
  };

  const changeTempPassword = async () => {
    setErrorMessage('');
    const pass1 = pass1Ref.current?.value;
    const pass2 = pass2Ref.current?.value;

    if (pass1 && pass2 && pass1.toString() === pass2.toString()) {
      const pass_rgx = new RegExp('^(?=.*[a-zA-Z])(?=.*[0-9]).{6,20}$');
      if (pass_rgx.test(pass1.toString())) {
        userCtx.changeTempPassword(pass1.toString(), tempUserToken).then((res: any) => {
          if (res == 'OK') {
            setTempUserToken('');
            setLoginType('PASSWORD');
          } else {
            setError(res || 'ארעה שגיאה בקביעת סיסמה חדשה');
            setShowAlert(true);
          }
        });
      } else {
        setErrorMessage('יש להזין סיסמא בהתאם להוראות');
      }
    } else {
      setErrorMessage('שדות אינם תואמים');
    }
  };

  const clearForm = () => {
    if (loginType == 'PASSWORD') {
      if (userNameRef.current) {
        userNameRef.current.value = '';
      }
      if (passwordRef.current) {
        passwordRef.current.value = '';
      }
    } else if (loginType == 'TEMP_PASS') {
      if (pass1Ref.current) {
        pass1Ref.current.value = '';
      }
      if (pass2Ref.current) {
        pass2Ref.current.value = '';
      }
    } else if (loginType == 'SMS') {
      if (phoneNumRef.current) {
        phoneNumRef.current.value = '';
      }
      if (verificationCodeRef.current) {
        verificationCodeRef.current.value = '';
      }
    }
  };
  const ErrorMessageButton = [
    {
      text: 'אישור',
      role: 'ok',
      handler: () => {
        setShowAlert(false);
        setError('');
        if (loginType == 'PASSWORD') {
          clearForm();
        } else {
          window.location.reload(false);
        }
      },
    },
  ];

  const submitPage = () => {
    
    if (loginType == 'SMS' && sentSMSStatus === false) {
      verificationCodeHandler();
    } else if (loginType == 'SMS' && sentSMSStatus === true) {
      loginHandler();
    }
  };

  const headerProps: HeaderParameters = {
    isShowLogo: true,
    isPageHaveChanges: false,
    isShowHamburger: false,
    backButton: { isShowMobile: false, isShowDesktop: false },
    isShowDeleteButton: false,
    isImagePreview: false,
    isCloseModalBtn: false,
    isShowEditButton: false,
    isLogin: true, 
  };
  return (
    <CustomPage
      headerParameters={headerProps}
      title="ברוכים הבאים לאפליקציית B144"
      subTitle={loginType == 'SMS' ? 'התחברות ע"י סמס' : loginType == 'PASSWORD' ? 'כניסה באמצעות שם משתמש וסיסמה' : 'קביעת סיסמה חדשה'}
      pageName="" >
      <CustomForm onSubmit={handleSubmit(submitPage)} errors={errors}>
        <CustomAlert
          isOpen={showAlert}
          header={'הודעת מערכת'}
          message={error}
          onDismiss={() => {
            setShowAlert(false);
            setError('');
            if (loginType == 'PASSWORD') {
              clearForm();
            } else {
              window.location.reload(false);
            }
          }}
          buttons={ErrorMessageButton}></CustomAlert>
          <IonGrid fixed={true} className="login-wrapper">
        {loginType == 'SMS' && sentSMSStatus === false && (
          <>
            <IonRow>
              <IonCol className="ion-no-padding">
                <CustomInput name="phonNum" label="מספר טלפון" errors={errors} control={control} type="tel" value="" required={loginType == 'SMS' && sentSMSStatus === false} ref={phoneNumRef} />
              </IonCol>
            </IonRow>
            <IonRow className="actions-wrapper">
              <IonCol>
                <CustomPublishButton expand="block" className="main-button" title="לקבלת קוד" />
                <IonButton onClick={() => setLoginType('PASSWORD')} className="login-switch-btn" fill="clear">
                  כניסה עם שם משתמש וסיסמה
                </IonButton>
              </IonCol>
            </IonRow>
            </>
        )}
        {loginType == 'SMS' && sentSMSStatus === true && (
          <>
            <IonRow>
              <IonCol className="ion-no-padding">
                <CustomInput name="code" label="קוד אימות" errors={errors} control={control} type="number" value="" clearInput={true} required={loginType == 'SMS' && sentSMSStatus === true} ref={verificationCodeRef} />
              </IonCol>
            </IonRow>
            {/* <IonRow>
                    <IonCol className="ion-no-padding">
                      <IonButton fill="clear" className="underline">לא קיבלתי קוד</IonButton>
                    </IonCol>
                  </IonRow> */}
            <IonRow className="actions-wrapper">
              <IonCol className="ion-text-center">
                <CustomPublishButton expand="block" className="main-button" title="שלח" />
                <IonButton onClick={() => setLoginType('PASSWORD')} className="login-switch-btn" fill="clear">
                  כניסה עם שם משתמש וסיסמה
                </IonButton>
              </IonCol>
            </IonRow>
          </>
        )}
        {loginType == 'PASSWORD' && (
          <>
            <IonRow>
              <IonCol className="ion-margin-bottom ion-no-padding">
              {/* <CustomInput name="userName" label="שם משתמש" errors={errors} control={control} value="" clearInput={true} required={loginType == 'PASSWORD' } ref={userNameRef} /> */}

                <IonItem color="light" className="input-item" lines="none" mode="md">
                  <IonLabel position="floating">שם משתמש</IonLabel>
                  <IonInput clearInput={true} mode="md" ref={userNameRef} type="text" onKeyUp={(e: any) => {
                 if(e.key == 'Enter'){
                  verificationUserPassword()
                 }
                
              }}></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-no-padding">
              {/* <CustomInput name="userPass" label="סיסמה" errors={errors} control={control} value="" type="password" clearInput={true} required={loginType == 'PASSWORD' } ref={passwordRef} /> */}

                <IonItem color="light" className="input-item" lines="none" mode="md">
                  <IonLabel position="floating">סיסמה</IonLabel>
                  <IonInput clearInput={false} mode="md" ref={passwordRef} type={passInputType} onKeyUp={(e: any) => {
                 if(e.key == 'Enter'){
                  verificationUserPassword()
                 }
                
              }}></IonInput>
<IonIcon class="ion-margin-top" style={{cursor:'pointer'}} icon={passInputType == 'password' ? eyeOutline : eyeOffOutline} slot='end' onClick={()=> {
  const curr_type = passInputType;
  setPassInputType(curr_type == 'password' ? 'text' : 'password');
}}></IonIcon>

                </IonItem>
              </IonCol>
            </IonRow>
            {/* <IonRow>
                  <IonCol className="ion-no-padding">
                    <IonButton fill="clear" className="underline">שכחתי סיסמה</IonButton>
                    <IonButton fill="clear" className="underline">שכחתי שם משתמש</IonButton>
                  </IonCol>
                </IonRow> */}
            <IonRow className="actions-wrapper">
              <IonCol className="ion-no-padding">
                <IonButton expand="block" className="main-button" onClick={verificationUserPassword}>
                  כניסה
                </IonButton>
                <div>אין לך שם משתמש או סיסמה?</div>
                <IonButton onClick={() => setLoginType('SMS')} className="login-switch-btn" fill="clear">
                  להתחברות עם סמס
                </IonButton>
              </IonCol>
            </IonRow>
          </>
        )}
        {loginType == 'TEMP_PASS' && (
          <>
            <IonRow>
              <IonCol>הרכב הסיסמה יכלול מינימום 6 תווים המכילים לפחות אות אחת באנגלית (גדולה או קטנה) וספרה אחת</IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-margin-bottom ion-no-padding">
                <IonItem color="light" className="input-item" lines="none" mode="md">
                  <IonLabel position="floating">סיסמה חדשה</IonLabel>
                  <IonInput clearInput={true} mode="md" ref={pass1Ref} type="password"></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-no-padding">
                <IonItem color="light" className="input-item" lines="none" mode="md">
                  <IonLabel position="floating">אישור סיסמה</IonLabel>
                  <IonInput clearInput={true} mode="md" ref={pass2Ref} type="password"></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>{errorMessage && <IonText color="danger">{errorMessage}</IonText>}</IonCol>
            </IonRow>
            <IonRow className="actions-wrapper">
              <IonCol className="ion-no-padding">
                <IonButton expand="block" className="main-button" onClick={changeTempPassword}>
                  שמירה
                </IonButton>
                <IonButton onClick={() => setLoginType('SMS')} className="login-switch-btn" fill="clear">
                  להתחברות עם סמס
                </IonButton>
                <p>צוות קשרי לקוחות לשירותכם בימים א’-ה’ בשעות הפעילות 08:00-17:00</p>
                <p>בטלפון: 1-700-707-144</p>
              </IonCol>
            </IonRow>
          </>
        )}
<IonRow className="actions-wrapper" style={{bottom:0}}>
            <IonCol>
            <IonButton fill='clear' style={{textDecoration:'underline'}} onClick={()=>{
              window.open('/assets/privacypolicy.pdf','_blank');
        //setShowPrivacyPolicy(true);
      }}>מדיניות פרטיות</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        <CustomModal
      closeModal={() => {
        setShowPrivacyPolicy(false);
      }}
      pageName={"PrivacyPolicy"}
      isOpen={showPrivacyPolicy}
      headerParameters={{
        isShowDeleteButton: false,
        modalType: "modal",
        isShowLogo:true
      }}
      title="מדיניות פרטיות"
    >
      <div className={ isMobile ? '' : 'ion-padding-horizontal'}>
      <PrivacyPolicyText></PrivacyPolicyText></div>
    </CustomModal>
        
      </CustomForm>
      
      
    </CustomPage>
  );
};
export default Login;
