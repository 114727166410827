import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IonIcon,
  IonCard,
  IonButton,
  IonImg,
} from "@ionic/react";
import { isMobile } from 'react-device-detect';
import SelfSaleContext, { AdvertisingBudgetField } from "../../../data/SelfSaleContext";
import SelfSaleFAQ from "./SelfSaleFAQ";
import { chevronBackOutline } from "ionicons/icons";
import { v4 as uuidv4 } from 'uuid';
import { ScrollContext } from '../../../data/ScrollContext';
import { b144VideoBcg, logoBipbip, logoChannel9, logoYad2, logoWalla, logoOne, logoHomeless, logoRotter, logoTopper, qotes, googleVideoBcg, youtube, logoGooglePartner, logoGoogleAds, budget, b144LogoBlue, googleLogo, google } from "../../../images";
import SelfSaleWhyChoose from "./SelfSaleWhyChoose";
const SelfSaleWellcome: React.FC<{ saleType: string, handleStartClick: () => void, startBtnDisabled: boolean }> = ({ saleType, handleStartClick, startBtnDisabled }) => {
  const SelfSaleCtx = useContext(SelfSaleContext);
  const [showGoogleVideo, setShowGoogleVideo] = useState<boolean>(false);
  const [packsPriceList, setPacksPriceList] = useState<AdvertisingBudgetField[]>([]);
  const [promoTextReg, setPromoTextReg] = useState<string>("");
  const [promoTextBold, setPromoTextBold] = useState<string>("");

  const { scrollPosition } = useContext(ScrollContext);
  const buttonRef = useRef<HTMLIonButtonElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const [buttonStyle, setButtonStyle] = useState({display: 'none', width:'0', right: '0'});

  const [isButtonVisible, setIsButtonVisible] = useState(false);
 

  const checkButtonVisibility = () => {
    if (buttonRef.current) {
      const buttonPosition = buttonRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      setIsButtonVisible(buttonPosition < windowHeight && buttonPosition > 0);
    }
  };
  useEffect(() => {
    SelfSaleCtx.getAdvertisingBudget(saleType, false).then((response: any) => setPacksPriceList(response))
    SelfSaleCtx.getAdvertisingBudget(saleType, true).then((response: any) => {
      setPromoTextReg(response[0]?.PromoTextReg || "")
      setPromoTextBold(response[0]?.PromoTextBold || "")
    })
    checkButtonVisibility();

  }, []);
  useEffect(() => {
    
    if (parentRef.current) {
      const parentRect = parentRef.current.getBoundingClientRect();
      const right = isMobile? 0 :  window.innerWidth - parentRect.right - 285
      const width = isMobile? window.innerWidth :  parentRect.width 
      const newStyle = {
        width: `${width}px`,
        right: `${right}px`,
        display: 'block'
      };
      if (isButtonVisible) {
        setButtonStyle(newStyle);
      }
    }
    checkButtonVisibility();


  }, [scrollPosition]);

  return (
    <IonCard mode="md" className="card-overflow self-sale-card self-sale-start" >
      <div ref={parentRef} style={{ position: 'relative' }}>
        <div className='ion-text-center'>
          <h2 className='self-sale-blue-header'>{saleType === "1" ? "פרסום בB144" : "פרסום ממומן בגוגל"}</h2>
          {saleType === "1" && (
            <div>
              <div style={{ padding: '0 8%' }}>
                <p className='large-p'>הצטרפות למדריך העסקים של B144 תפתח את הדלת לעסק שלך בעולם הדיגיטלי. בזכות פרסום ממוקד של מומחי הפרסום שלנו, העסק שלך ייחשף ללקוחות פוטנציאליים רבים ברשת.</p>
              </div>
              <p className='xl-p'>{promoTextReg}<b> {promoTextBold}</b></p>
              <div className='terms-div'>* המחירים אינם כוללים מע״מ,
                <IonButton className="terms-link-btn" size="small" href="https://www.b144.co.il/TermsOfService/" target="_blank" fill="clear">בכפוף לתקנון</IonButton>
              </div>
              <IonButton ref={buttonRef} className={`main-oval-button main-button`} disabled={startBtnDisabled} onClick={() => handleStartClick()}>
                לפרסום וקידום העסק שלך
                <IonIcon size="small" icon={chevronBackOutline}></IonIcon>
              </IonButton>
              <IonImg src={b144VideoBcg}></IonImg>
              <h2 className="bold-text">מה תקבלו:</h2>
              <ul className="section-list check-icon">
                <li>קידום העסק במקומות הראשונים באתר ובאפליקציית B144</li>
                <li>דף עסק דיגיטלי מעוצב, עם כל המידע החשוב על העסק שלך</li>
                <li>נוכחות בולטת ברשת וחשיפה למאות אלפי גולשים המחפשים מידע</li>
                <li>ממשק לעדכון עצמאי של דף העסק</li>
                <li>אפשרות לפרסם בדף העסק מוצרים, קופונים ומחירונים</li>
                <li>הצגת דירוגים וחוות דעת של לקוחות עם אפשרות מענה בדף העסק שלכם</li>
                <li>אפליקציה למעקב אחרי ביצועי הקמפיין</li>
                <li>קידום העסק במנועי חיפוש, בגוגל, באתר יד 2 ובאתרים רבים אחרים</li>
              </ul>
              <div className='logos-div'>
                <div><IonImg src={logoBipbip}></IonImg></div>
                <div><IonImg src={logoChannel9}></IonImg></div>
                <div><IonImg src={logoYad2}></IonImg></div>
                <div><IonImg src={logoWalla}></IonImg></div>
                <div><IonImg src={logoOne}></IonImg></div>
                <div><IonImg src={logoHomeless}></IonImg></div>
                <div><IonImg src={logoRotter}></IonImg></div>
                <div><IonImg src={logoTopper}></IonImg></div>
              </div>
            </div>
          )}
          {saleType == '2' && (
            <div>
              <div style={{ padding: '0 8%' }}>
                <p className='large-p'>כשותפי גוגל (Google Partners) בישראל, המומחים שלנו יקימו קמפיין ממומן ומותאם עם מודעות אפקטיביות, שיחשפו את העסק שלך להרבה לקוחות פוטנציאליים!</p>
              </div>
              <p className='xl-p'>{promoTextReg}<b> {promoTextBold}</b></p>
              <div className='terms-div'>* המחירים אינם כוללים מע״מ,
                <IonButton className="terms-link-btn" size="small" href="https://www.b144.co.il/TermsOfService/" target="_blank" fill="clear">בכפוף לתקנון</IonButton>
              </div>
              <IonButton ref={buttonRef} className={`main-oval-button main-button`} disabled={startBtnDisabled} onClick={() => handleStartClick()}>
                לפרסום וקידום העסק שלך
                <IonIcon size="small" icon={chevronBackOutline}></IonIcon>
              </IonButton>
              <div className='youtube-div'>
                {showGoogleVideo && (<iframe
                  id="iframe" width="100%" height="224"
                  src="https://www.youtube.com/embed/u2YAL92rkE8?enablejsapi=1&amp;origin=https:%2F%2Fwww.b144biz.co.il&amp;autoplay=1&amp;mute=1"
                  title="חבילות הפרסום בגוגל של B144"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share">
                </iframe>)}

                {!showGoogleVideo && (
                  <>
                    <IonImg src={googleVideoBcg}></IonImg>
                    <div className="youtube-link-btn">
                      <IonButton onClick={() => setShowGoogleVideo(true)} fill="clear"><IonImg src={youtube}></IonImg></IonButton>
                      <p>לצפייה בסרטון</p>
                    </div>
                  </>
                )}
              </div>
              <h2 className="bold-text">מה תקבלו:</h2>
              <ul className="section-list check-icon">
                <li>מחקר מילות מפתח ייעודי לעסק שלך על ידי מומחה</li>
                <li>הקמה וניהול קמפיין מודעות מקצועי ואפקטיבי בגוגל</li>
                <li>ניהול הקמפיין על ידי מומחים לפרסום עסקים קטנים בתקציבים נמוכים</li>
                <li>מודעות שיווקית מעוצבות במטרה לקבל חשיפה בולטת לעסק שלך</li>
                <li>דף נחיתה מעוצב ומותאם אישית</li>
                <li>אפליקציה למעקב אחרי ביצועי הקמפיין</li>
                <li>ממשק לעדכון עצמאי של דף העסק</li>
                <li>קידום העסק באתר B144 ודף עסק דיגיטלי משלך</li>
              </ul>
              <div className='google-logos-div'>
                <IonImg alt='g' src={logoGooglePartner}></IonImg>
                <IonImg src={logoGoogleAds}></IonImg>
              </div>
            </div>
          )}
          <div className='hamlatza-div'>
            <div className="hamlatza-text">
              <p>{saleType === "1" ? "שירות מצוין, עובד ועוזר לקדם את העסק שלי, התקשורת עם B144 נוחה לי. חשוב לי מאוד להישאר בשירות, בזכותם הטלפון שלי לא מפסיק לצלצל." : "השירות טוב, יש תמיד מי שרצה לעזור ולקדם באמצעות הפרסום בגוגל אני נחשף בפני הרבה לקוחות ומקבל הרבה מאוד עבודה."}</p>
              <IonImg className='quotes' style={{ height: '60px', width: '75px' }} src={qotes}></IonImg>
            </div>
            <div className="authorDetail">
              {saleType === "1" ? "ולדי מושאילוב " : "אורן ארנבייב "}
               |
              <a rel="nofollow" style={{ marginRight: '6px' }} href={saleType === "1" ? "https://www.b144.co.il/b144_sip/4A1404134470655D4C16021246716D544E/" : "https://private.b144.co.il/bezeq_phone/401C041340726D5D411D0C1A/"}>{saleType === "1" ? "ולדי הפקות - שירותי במה" : "א.א מיזוג אוויר"}</a>
            </div>
          </div>
          <div className='packs-pricelist'>
            <h2 className="ion-no-margin self-sale-text-blue bold-text">{saleType === "1" ? "מחירון חבילות B144" : "מחירון חבילות פרסום בGoogle"}</h2>
            <div className="packs-list">
              {packsPriceList.length > 0 && (
                packsPriceList.map(pack => (
                  <div className="packs-list-item" key={uuidv4()}>
                    <img className="b144-logo" src={saleType === "1" ? b144LogoBlue : google} />
                    <p className="self-sale-text-azure bold-text">{saleType === "1" ? "פרסום באתר B144" : "פרסום בGoogle"}</p>
                    <p className="large-p">{pack.PromoTextPack}</p>
                    <p className="small-p">{pack.BudgetDesc}</p>
                  </div>
                ))
              )}

            </div>
          </div>
          <SelfSaleFAQ saleType={saleType} />
          <SelfSaleWhyChoose />
          {saleType === "1" && (
            <div className="last-div">
              <h2 className="ion-no-margin self-sale-text-blue bold-text">מה נחשב יחידת פרסום?</h2>
              <div className="text-right">
                <div className="d-flex" style={{ marginBottom: '8px' }}>
                  <span style={{ marginLeft: '5px' }}>•</span>
                  <p>פרסום העסק שלך בדף תוצאות החיפוש באתר B144 או באתרי השותפים, באחד משלושת המקומות הראשונים ברוטציה, עם מספר טלפון גלוי של העסק. </p>
                </div>
                <div className="d-flex" style={{ marginBottom: '8px' }}>
                  <span style={{ marginLeft: '5px' }}>•</span>
                  <p>כאשר הגולש מקליק על אחד מכפתורי המידע או יצירת הקשר בכרטיס העסק (במידה והעסק מופיע במקום רביעי ואילך) באתר B144 או באתרי השותפים. או כאשר נעשית פניה אל העסק משירות "מי פנוי".</p>
                </div>
                <div className="d-flex">
                  <span style={{ marginLeft: '5px' }}>•</span>
                  <p>פרסום במוקד 144 הקולי: מסירת שם העסק וטלפון לחיוג לעסק - במידה והפונה מחפש עסק לפי קטגוריה מסוימת ואזור גאוגרפי והמספר נמסר לפונה. אם מבוקש מספר הטלפון תוך ציון שם העסק במפורש אין חיוב על יחידת פרסום.</p>
                </div>
              </div>
            </div>
          )}
        </div>
        {!isButtonVisible && (
          <IonButton style={buttonStyle}

            expand="block"
            className="main-button fixed"
            disabled={startBtnDisabled}
            onClick={() => handleStartClick()}>
            לקידום ופרסום העסק שלך
            <IonIcon size="small" icon={chevronBackOutline}></IonIcon>
          </IonButton>
        )}
      </div>
    </IonCard>
  );
};

export default SelfSaleWellcome;