import React from 'react';

export interface userData {
  Token: string | null;
  ClientId: string | null;
  Email: string | null;
  MobileNumber: string | null;
  FacebookUserCode: string | null;
  FullName: string | null;
  GooglePlusUserCode: string | null;
  Id: string | null;
  LangCode: string | null;
  ProfileImage: string | null;
  UserCode: string | null;
  EZToken: string | null;
  IsSelfRegister: boolean | null;
  IsCm: boolean | null;
}

const UserContext = React.createContext<{
  authValues: userData | undefined;
  login: (MobileNumber: string, SmsCode: string, firebaseToken: any) => any;
  loginUser:(userCode:string,pass:string, firebaseToken: any)=>any;
  loginCmByToken: (strToken: string, cm_username: string, tp: any) => any;
  loginUserByToken: (strToken: string) => any;
  loginMultiCardMember: (memberId: string) => any;
  loginTest: () => any;
  logout: (calbackFunc: () => void) => void;
  verificationCode: (MobileNumber: string, captcha: string) => any;
  changeTempPassword:(password:string, tempUserToken:string) => any;
  initUserContext: () => void;
  setLoader: (loading: boolean) => void;
}>({
  authValues: {
    IsSelfRegister: null,
    Token: null,
    ClientId: null,
    Email: null,
    MobileNumber: null,
    FacebookUserCode: null,
    FullName: null,
    GooglePlusUserCode: null,
    Id: null,
    LangCode: null,
    ProfileImage: null,
    UserCode: null,
    EZToken: null,
    IsCm: null,
  },
  verificationCode: () => {},
  login: () => {},
  loginUser:()=>{},
  loginCmByToken: () => {},
  loginUserByToken: () => {},
  loginMultiCardMember: () => {},
  loginTest: () => {},
  logout: () => {},
  changeTempPassword: () => {},
  initUserContext: () => {},
  setLoader: () => {},
});

export default UserContext;
