import jsPDF from "jspdf";
import { actionSummery, StatProd } from "../data/Statistic-Context";
import { vfs } from "./vfs_fonts";
import LogoQR from "../images/logos/logob-144-mobile-negative-blue.jpg";
import LogoNegative from "../images/logos/logob-144-mobile-negative-svg.png";
import PdfBg from "../images/backgrounds/qrPdfBg.jpg";
import domtoimage from "dom-to-image";
import "./qrDownloadStyles.scss";
interface pdfStyle {
  name: string;
  value: string | number;
}

let pdf: jsPDF;
let heightFromTop = 0;

export const getStatisticReport = (
  month: number,
  year: number,
  Package_Desc: string,
  actionSummery: actionSummery[],
  statProducts: StatProd[]
) => {
  pdf = new jsPDF();
  heightFromTop = 5;
  pdf.addImage(LogoNegative, "png", 20, heightFromTop, 10, 10);
  heightFromTop = 30;
  pdf.addFileToVFS("Arial.ttf", vfs["Arial.ttf"]);
  pdf.addFont("Arial.ttf", "Arial", "normal");
  pdf.setFont("Arial");
  pdf.setLanguage("he");
  pdf.setR2L(true);

  let budget = 0;
  let cost = 0;
  let totalCall = 0;
  let totalClickes = 0;
  let sumImpressions = 0;

  statProducts?.map((prod) => {
    sumImpressions = sumImpressions + prod.Impressions;
    budget = budget + prod.Budget;
    cost = cost + prod.Cost + prod.GF_Cost;
    totalCall = totalCall + prod.CallsCount;
    totalClickes = totalClickes + prod.GF_Clicks;
  });
  let persentage = budget > 0 ? (cost / budget) * 100 : 0;

  addLineToPdf(
    `חבילת פרסום : ${Package_Desc} )דוח חודש ${month} - ${year}(\n`,
    190,
    "right",
    [{ name: "setFontSize", value: 18 }]
  );

  if (statProducts?.length == 0) {
    addLineToPdf(
      `לא קיימים נתונים עבור תאריך : ${month} - ${year}`,
      190,
      "right",
      [{ name: "setFontSize", value: 16 }]
    );
  } else {
    addLineToPdf("תמונת מצב כללית:", 190, "right", [
      { name: "setTextColor", value: "#808080" },
    ]);

    addLineToPdf(
      ` נוצלו  ${cost.toLocaleString()} ש"ח מתוך ${budget.toLocaleString()} ש"ח )${Math.round(
        persentage
      )}%( \n כמות שיחת שנכנסו בחודש האחרון: ${totalCall.toLocaleString()} \n כמות הקלקות - כמה לחצו על המודעה של הקמפיין: ${totalClickes.toLocaleString()}`,
      190,
      "right"
    );
    if (actionSummery.length > 0) {
      addLineToPdf(`סיכום פעולות:`, 190, "right", [
        { name: "setTextColor", value: "#808080" },
      ]);
      let actionSummeryList = "";
      actionSummery.map((s) => {
        if (s.SumClicks > 0) {
          actionSummeryList =
            actionSummeryList +
            ` ${s.SumClicks.toLocaleString()}  ${s.ClickTypeDesc}\n`;
        }
      });
      addLineToPdf(actionSummeryList, 190, "right");
    }
    statProducts.map((p) => {
      addLineToPdf(`${p.ProdMainName}`, 190, "right", [
        { name: "setFontSize", value: 16 },
        { name: "setTextColor", value: "#808080" },
      ]);
      let cost = 0;
      let precentage = 0;
      let budget = p.Budget;
      if (p.Prodmain === 4 || p.Prodmain === 20) {
        cost = p?.GF_Cost;
        precentage = p.GF_precentage * 100;
      } else {
        cost = p?.Cost;
        precentage = p.precentage * 100;
      }
      addLineToPdf(
        ` נוצלו  ${cost.toLocaleString()} ש"ח מתוך ${budget.toLocaleString()} ש"ח )${Math.round(
          precentage
        )}%( \n כמות שיחת שנכנסו בחודש האחרון: ${p.CallsCount.toLocaleString()} \n כמות הקלקות - כמה לחצו על המודעה של הקמפיין: ${p.GF_Clicks.toLocaleString()}  \n עלות ממוצעת להקלקה: ${p.avgPricePerEvent.toLocaleString()}`,
        190,
        "right"
      );
    });
  }
  return pdf;
};

export const getQrImag = (businessName: string, businessAvatar: any) => {
  const bussinesDataContainer = document.createElement("div");
  const qrEl = document.getElementById("downloadToPdf");
  bussinesDataContainer.id = "bussinesDataContainer";
  bussinesDataContainer.className = "pdf";
  bussinesDataContainer.innerHTML = `<img src="${businessAvatar}"/> <span>${businessName}</span>`;

  document.body.appendChild(bussinesDataContainer);
  document.body.appendChild(qrEl!);
  pdf = new jsPDF("p", "mm", "a3");
  pdf.addImage(PdfBg, "JPEG", 0, 0, 297, 420);
  divToImage("downloadToPdf", pdf, 105, 85, 125, 125);
  setTimeout(() => {
    divToImage("bussinesDataContainer", pdf, 330, 0, 297, 30);
    pdf.addImage(LogoQR, "png", 122, 141, 50, 52);
  }, 500);

  return pdf;
};

const divToImage = (
  el: string,
  pdf: jsPDF,
  heightFromTop: number,
  widthFromRight: number,
  width: number,
  height: number
) => {
  var node = document.getElementById(el);
  if (node) {
    domtoimage
      .toPng(node)
      .then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        pdf.addImage(img, "png", widthFromRight, heightFromTop, width, height);
        heightFromTop = heightFromTop + 300;
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
    return true;
  }
};

const addTableTpPdf = (data: any, column: string[]) => {
  // not support hebrew
  pdf.addFont("Arial.ttf", "Arial", "normal");
  pdf.table(10, 20, data, column, { autoSize: true, printHeaders: true });
};

const addLineToPdf = (
  text: string,
  maxWidth: number,
  textAlign: any,
  pdfStyle?: pdfStyle[]
) => {
  let lines = pdf.splitTextToSize(text, 160);
  pdf.setFontSize(12);
  pdf.setTextColor("#16254f");
  //setStyle
  if (pdfStyle != undefined) {
    pdfStyle.map((s) => {
      switch (s.name) {
        case "setFontSize":
          pdf.setFontSize(Number(s.value));
          break;
        case "setTextColor":
          pdf.setTextColor(s.value.toString());
          break;
      }
    });
  }
  pdf.text(lines, maxWidth, heightFromTop, {
    align: textAlign,
    isInputRtl: true,
    isOutputRtl: true,
  });
  let brakeLine = (text.match(/\n/g) || []).length;
  if (brakeLine > 0) {
    const totalH = (10 / 2) * brakeLine + 10;
    heightFromTop += totalH;
  } else {
    heightFromTop += 10;
  }
};
