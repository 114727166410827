import { IonCard, IonLoading, IonIcon, IonLabel } from "@ionic/react";
import React, { useEffect, useState, useContext } from "react";
import { HeaderParameters } from "../../../data/Header-Context";
import CustomModal from '../../utils/CustomElements/CustomModal/CustomModal'
import SelfSaleContext, { RequestResult, CreditGuardSetup, CreditGuardResponse } from "../../../data/SelfSaleContext";
const CreditGuardIFrame: React.FC<{ src: string, onComplete: (obj: CreditGuardResponse, success: RequestResult) => void }> = (props) => {
  const SelfSaleCtx = useContext(SelfSaleContext);
  const headerParameters: HeaderParameters = {
    modalType: "modal",
  };
  useEffect(() => {
    const handler = (event: any) => {
      if (event.data && event.data.hasOwnProperty('uniqueID')) {
        SelfSaleCtx.completeCGTransaction(event.data).then((res: any) => {
          let result: RequestResult = {
            ResultData: "",
            ResultDesc: res.error_desc,
            Success: res.result === "OK"
          }
          let cgResData = {...event.data, cardMask: res.card_mask}
          props.onComplete(cgResData, result)
        })
      }
    }
    window.addEventListener('message', handler)
    return () => window.removeEventListener('message', handler)
  }, [])
  return (<>
    <iframe id="creditGuard" style={{ height: '480px', border: 'none', width: '328px' }} src={props.src} />
  </>
  )
}
export default CreditGuardIFrame;