import {
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
} from "@ionic/react";
import { attach } from "ionicons/icons";
import React, { useEffect,useContext, useState } from "react";
import CustomPage from "../components/utils/CustomElements/CustomPage";
import CustomHeaddingItem from "../components/utils/CustomElements/CustomPageTitle/CustomHeaddingItem";
import { HeaderParameters } from "../data/Header-Context";
import { DownloadLetter, getUserLetters, Letter } from "../data/LettersService";
import { getDateObjFromIsoString } from "../utils-ts/GlobalDateFunctions";
import MemberDetailsContext from "../data/MemberDetailes-context";

const Letters: React.FC = () => {
  const MemberDetailsCxt = useContext(MemberDetailsContext);
  const headerParameters: HeaderParameters = {
    isShowLogo: true,
    backButton: { isShowMobile: true },
    isShowNotification: true,
  };

  const [letters, setLetters] = useState<Letter[]>();
  useEffect(() => {
    getUserLetters().then((res) => {
      setLetters(res);
    });
  }, []);

  return (
    <CustomPage
      headerParameters={headerParameters}
      pageName={"Letters"}
      title="המכתבים שלי"
    >
      <IonGrid fixed={true} className="main-content">
        <IonRow>
          <IonCol size="12" size-lg="4"></IonCol>
        </IonRow>
        {letters && (
          <IonRow>
            <IonCol size="12">
              <CustomHeaddingItem
                mainTitle={`ישנם ${letters.length} מכתבים בתיבת הדואר`}
                subTitle={"מכתבים אלו באים לעדכן אותך בכל שינוי שנעשה בחשבונך"}
                icon={attach}
                innerPage={true}
                iconBackground="inbox"
              />
            </IonCol>
          </IonRow>
        )}
        <IonRow>
          <IonCol>
            <IonList className="inbox-list-wrapper">
              {letters &&
                letters.length > 0 &&
                letters.map((l, index) => {
                  const date = getDateObjFromIsoString(l.updateDate);
                  return (
                    <IonItem
                      key={l.id}
                      lines="none"
                      mode="md"
                      detail={false}
                      className="inbox-item ion-margin-bottom"
                    >
                      <IonIcon icon={attach}></IonIcon>

                      <IonLabel
                        onClick={() => {
                          SetDtlPushEvt('messages' , 'open_message',
                          '' + (MemberDetailsCxt.GeneralInfo  ? MemberDetailsCxt.GeneralInfo?.CompanyName : ""),
                          GetMemberAdvCategories(MemberDetailsCxt.AdvCategories),
                          MemberDetailsCxt.BusinessAddress?.City);

                          DownloadLetter(l.fileName, l.is_call_summary);
                        }}
                      >
                        <IonRow className="ion-justify-content-between ion-align-items-center">
                          <IonCol size="7">
                            <h3>{l.prodPackdesc}</h3>
                          </IonCol>
                          <IonCol className="ion-text-left">
                            <p className="small-p">{date}</p>
                          </IonCol>
                        </IonRow>
                      </IonLabel>
                    </IonItem>
                  );
                })}
            </IonList>
          </IonCol>
        </IonRow>
      </IonGrid>
    </CustomPage>
  );
};

export default Letters;
