import React from "react";
import FacebookIcon3 from "../../../../../components/icons/FacebookIcon3";
import styles from "./FacebookIntroPage.module.scss";

export default function FacebookIntroPage() {
  return (
    <div className={styles["facebook-intro-page"]}>
      <FacebookIcon3 className={styles.icon} />
      <div className={styles.title}>פרסום ממומן בפייסבוק</div>
      <p className={styles.paragraph}>
        לפרסם את העסק שלך במקום שבו מתרחשים הדברים המעניינים באמת! קמפיין
        אפקטיבי בפייסבוק ובאינסטגרם שיצור עניין וימשוך קהל חדש לעסק שלך.
      </p>
    </div>
  );
}
