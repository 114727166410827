import React, { useState, useEffect, useContext } from "react";
import PriceListContext, {
  CategoryPriceList,
  PrivatePriceList,
  PriceListItem,
} from "./PriceList-context";
import { config } from "../Constants";
import { fetchRequest } from "../utils-ts/fetchRequest";
import { toast } from "react-toastify";
//import CustomLoader from "../components/utils/CustomElements/CustomLoader";
import UserContext from "./Auth-context";
import GlobalContext from "./Global-Context";
const parseString = require("xml2js").parseString;

const PriceListContextProvider: React.FC = (props) => {
  const { authValues } = useContext(UserContext);
  //const [loading, setLoading] = useState<boolean>(false);
  const globalCtx = useContext(GlobalContext);

 const setLoading = (loading:boolean)=>{
  globalCtx.setLoader(loading);
 };
  const [CategoryPriceLists, setCategoryPriceList] = useState<
    CategoryPriceList[]
  >([]);
  const [MemberPriceList, setMemberPriceList] = useState<PrivatePriceList>();

  useEffect(() => {
    if (CategoryPriceLists.length > 0) {
      setLoading(false);
    }
  }, [CategoryPriceLists]);

  const GetPriceListByMemberId = async (memberId: string) => {
    setLoading(true);
    var data = new URLSearchParams(memberId).toString();
    fetchRequest(
      `${config.url.API_URL}/PrivatePriceListItems.asmx/GetPriceListByMemberId`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const data = JSON.parse(res.Response);
              setCategoryPriceList(data.CategoryPriceListObj);
              setMemberPriceList(data.PrivatePriceListObj);
              setLoading(false);
            }
          }
        });
      })
      .catch(function (error) {
        console.log("Request failed", error);
        setLoading(false);
      });
  };

  // Update & Insert
  const UpdatePriceListItem = async (
    itemToUpdate: PriceListItem,
    priceListId: number
  ) => {
    setLoading(true);
    var data = new URLSearchParams({
      jsDetails: JSON.stringify(itemToUpdate),
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/PrivatePriceListItems.asmx/UpdatePriceListItem`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              setMemberPriceList((current) => {
                let newMemberPriceList;
                // already exist private pricelist
                if (current) {
                  const indexOfItemToUpdate = current.PriceListItems.findIndex(
                    (priceList) => priceList.Id === itemToUpdate.Id
                  );
                  // exist item (update)
                  if (indexOfItemToUpdate > -1) {
                    const filteredPriceListItems = current.PriceListItems.filter(
                      (priceList) => priceList.Id !== itemToUpdate.Id
                    );
                    newMemberPriceList = {
                      ...current,
                      PriceListItems: [itemToUpdate, ...filteredPriceListItems],
                    };
                    // new item (insert)
                  } else {
                    itemToUpdate.Id = res.Response;
                    itemToUpdate.PriceListId = priceListId;
                    newMemberPriceList = {
                      ...current,
                      PriceListItems: [itemToUpdate, ...current.PriceListItems],
                    };
                  }
                } // new private pricelist
                else {
                  itemToUpdate.Id = res.Response;
                  newMemberPriceList = {
                    MemberId: authValues!.ClientId!,
                    PriceListId: itemToUpdate.PriceListId,
                    PriceListItems: [itemToUpdate],
                  };
                }
                return newMemberPriceList;
              });
              toast.success("המחירון התעדכן בהצלחה");
            } else {
              toast.error("ארעה שגיאה בעדכון המחירון");
            }
          }
        });
      })
      .catch(function (error) {
        console.log("Request failed", error);
      });
  };

  const DeletePriceListItem = async (itemToDelete: PriceListItem) => {
    setLoading(true);
    var data = new URLSearchParams({
      jsDetails: JSON.stringify(itemToDelete),
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/PrivatePriceListItems.asmx/DeletePriceListItem`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              setMemberPriceList((current) => {
                let newMemberPriceList;
                if (current) {
                  const indexOfItemToDelete = current.PriceListItems.findIndex(
                    (priceList) => priceList.Id === itemToDelete.Id
                  );
                  if (indexOfItemToDelete > -1) {
                    const filteredPriceListItems = current.PriceListItems.filter(
                      (priceList) => priceList.Id !== itemToDelete.Id
                    );
                    newMemberPriceList = {
                      ...current,
                      PriceListItems: filteredPriceListItems,
                    };
                  }
                }
                return newMemberPriceList;
              });
              toast.success("הפריט נמחק בהצלחה");
            } else {
              toast.error("ארעה שגיאה במחיקת הפריט");
            }
          }
        });
      })
      .catch(function (error) {
        console.log("Request failed", error);
      });
  };

  return (
    <PriceListContext.Provider
      value={{
        CategoryPriceLists,
        MemberPriceList,
        GetPriceListByMemberId,
        UpdatePriceListItem,
        DeletePriceListItem,
      }}
    >
      {props.children}
      {/* <CustomLoader
        isOpen={loading}
        duration={2000}
        onDidDismiss={() => {
          setLoading(false);
        }}
      ></CustomLoader> */}
    </PriceListContext.Provider>
  );
};

export default PriceListContextProvider;

export const usePriceListContext = () => {
  const context = React.useContext(PriceListContext);
  return context;
};
