import { isMobile } from "react-device-detect";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router";
import ProductListModal from "../../components/Statistic/ProductListModal";
import CustomPage from "../../components/utils/CustomElements/CustomPage";
import CustomHeaddingItem from "../../components/utils/CustomElements/CustomPageTitle/CustomHeaddingItem";
import UserContext from "../../data/Auth-context";
import { HeaderParameters } from "../../data/Header-Context";
import { myAssets, leadsAndTelephones, myCampaigns } from "../../images/index";
import { getTitle } from "../../utils-ts/GlocalFunc";

const StatisticsOpt: React.FC = () => {
  const { authValues } = useContext(UserContext);
  const headerParameters: HeaderParameters = {
    isShowLogo: true,
    backButton: { isShowMobile: true },
    isShowNotification: true,
  };
  const [showProductList, setshowProductList] = useState(false);
  return (
    <CustomPage
      headerParameters={headerParameters}
      pageName={"StatisticsOpt"}
      title="איזה נתונים מעניינים אותך?"
    >
      <IonGrid fixed={true} className="main-content">
        {/* main buttons section*/}
        <IonRow>
          {authValues && authValues.IsSelfRegister === false && (
            <IonCol size="12" size-lg="4">
              <CustomHeaddingItem
                mainTitle={"הקמפיינים שלי"}
                subTitle={"לראות תמונת מצב של הקמפיינים שלי"}
                icon={myCampaigns}
                iconBackground="statistics"
                routerLink={"/Statistics"}
              ></CustomHeaddingItem>
            </IonCol>
          )}
          <IonCol size="12" size-lg="4">
            <CustomHeaddingItem
              mainTitle={"הנכסים שלי"}
              subTitle={"לראות תמונת מצב של הנכסים שלי"}
              icon={myAssets}
              iconBackground="statistics"
              onClick={() => {
                if (isMobile) {
                  setshowProductList(true);
                } else {
                  window.location.href = "/ProductListPage";
                }
              }}
            ></CustomHeaddingItem>
          </IonCol>
          <IonCol size="12" size-lg="4">
            <CustomHeaddingItem
              mainTitle={"לידים וטלפונים"}
              subTitle={"לראות תמונת מצב של הלידים שלי"}
              icon={leadsAndTelephones}
              iconBackground="statistics"
              routerLink={"/MemberLeads"}
            ></CustomHeaddingItem>
          </IonCol>
        </IonRow>
      </IonGrid>
      {showProductList && (
        <ProductListModal
          show={showProductList}
          closeModal={() => {
            setshowProductList(false);
          }}
        ></ProductListModal>
      )}
    </CustomPage>
  );
};
export default StatisticsOpt;
