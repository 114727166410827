import React from "react";
import ReactTextFormat from "react-text-format";
import NativeApp from "../../../utils-ts/NativeApp";
const CustomReactTextFormat: React.FC<{}> = (props) => {
  const { OpenDialer, MailTo, LoadExternalSite } = NativeApp();
  React.useEffect(() => {
    setTimeout(() => {
      const modal = document.querySelector(".xl-p");
      modal &&
        modal.querySelectorAll("a").forEach((node: any) => {
          node.addEventListener("click", (e: any) => {
            e.preventDefault();
            if (node.href.includes("tel:")) {
              OpenDialer(node.href);
            } else if (node.href.includes("mailto:")) {
              MailTo(node.href);
            } else {
              LoadExternalSite(node.href);
            }
          });
        });
    }, 500);
  }, []);

  return (
    <ReactTextFormat
      linkTarget="_blank"
      allowedFormats={["URL", "Email", "Image", "Phone"]}
      className="modal-body text-wrap link"
    >
      {props.children}
    </ReactTextFormat>
  );
};

export default CustomReactTextFormat;
