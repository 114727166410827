import React, { useState,useContext } from "react";
import SelfServiceContext, {
  request, BudgetDetails, UpgradeBudgetOption

} from "./SelfService-context";
import { config } from "../Constants";
import { fetchRequestNode } from "../utils-ts/fetchRequest";
//import CustomLoader from "../components/utils/CustomElements/CustomLoader";
import GlobalContext from "./Global-Context";
import { toast } from "react-toastify";
import { autocompleteOption } from "../components/utils/CustomElements/CustomAutocomplete";

//const parseString = require("xml2js").parseString;

const SelfServiceContextProvider: React.FC = (props) => {
  //const [loading, setLoading] = useState<boolean>(false);
  const globalCtx = useContext(GlobalContext);
 const setLoading = (loading:boolean)=>{
  globalCtx.setLoader(loading);
 };

  const getMemberBudgetDetails = async (useLoading: boolean) => {
    useLoading && setLoading(useLoading);
    return new Promise((resolve) => {
      var data = new URLSearchParams({}).toString();

      fetchRequestNode(
        `${config.url.Node_API_URL}/selfService/getMemberBudgetDetails`,
        "post",
        data
      )
        .then((response: any) => {
          useLoading && setLoading(false);
          var res = JSON.parse(response);
          if (res != null) {

            // Budget: 1
            // Description: "הופעה במדריך לעסקים b144- פיילוט מנהל"
            // package_type: 1742
            // recommendedPacks
            const member_budget: BudgetDetails = {
              pack_code: res.package_type,
              pack_name: res.Description,
              cost: res.Budget,
              isFixed: res.isFixed,
              max_budget_fee: res.max_budget_fee | 0,
              //has_upgrades: res.recommendedPacks && res.recommendedPacks.length > 0,
              recommendedPacks: res.recommendedPacks && res.recommendedPacks.length > 0 ? res.recommendedPacks.map((p:any) => {
                const upgrage:UpgradeBudgetOption = {
                  pack_code: p.pack_recommend,
                  pack_name: p.Description,
                  cost: p.Budget,
                  isFixed: p.isFixed,
                  max_budget_fee: p.max_budget_fee | 0
                };
                return upgrage;
              }) : [],
              prods_in_pack: //TODO
                []
            };

            // 'הקמה וניהול קמפיין מודעות מקצועי ומניב תוצאות בגוגל',
            //   'קידום באתר B144 עם דף עסק דיגיטלי',
            //   'דף נחיתה מעוצב ומותאם אישית'

            resolve(member_budget);
          } else {

            resolve(null);
          }
        })
        .catch(function (error) {
          useLoading && setLoading(false);
          resolve(null);
          console.log(error);
        });
    });
  };

  // const getRecommendedPacks = async (useLoading: boolean, pack_id: number) => {
  //   setLoading(useLoading);
  //   return new Promise((resolve) => {
  //     var data = new URLSearchParams({
  //       pack_id: pack_id.toString(),
  //     }).toString();

  //     fetchRequestNode(
  //       `${config.url.Node_API_URL}/selfService/getRecommendedPacks`,
  //       "post",
  //       data
  //     )
  //       .then((response: any) => {
  //         setLoading(false);
  //         var res = JSON.parse(response);
  //         if (res.length > 0) {
  //           const budget_options: UpgradeBudgetOption[] = res.map((pack: any) => {
  //             return {
  //               pack_code: pack.pack_recommend,
  //               cost: pack.Budget
  //             }
  //           });



  //           resolve(budget_options);
  //         } else {

  //           resolve(null);
  //         }
  //       })
  //       .catch(function (error) {
  //         setLoading(false);
  //         console.log(error);
  //       });
  //   });
  // };



  const createBudgetRequests = async (useLoading: boolean, req: request) => {
    useLoading && setLoading(useLoading);
    return new Promise((resolve) => {
      var data = new URLSearchParams({
        reqD: JSON.stringify(req),
      }).toString();

      fetchRequestNode(
        `${config.url.Node_API_URL}/selfService/createBudgetRequests`,
        "post",
        data
      )
        .then((response: any) => {
          var res = JSON.parse(response);
          resolve(res);
          useLoading && setLoading(false);
        })
        .catch(function (error) {
          useLoading && setLoading(false);
          console.log(error);
        });
    });
  };

 
  const getRegions = (useLoading: boolean) => {
    return new Promise((resolve) => {
      useLoading && setLoading(useLoading);
      var data = new URLSearchParams({
        areaType: '2',
        serviceArea: ''
      }).toString();

      fetchRequestNode(
        `${config.url.Node_API_URL}/selfService/getServiceAreas`,
        "post",
        data
      )
        .then((response: any) => {
          useLoading && setLoading(false);
          var res = JSON.parse(response);
          resolve(res);
        })
        .catch(function (error) {
          useLoading && setLoading(false);
          console.log(error);
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const getCities = (search_string: string) => {
    return new Promise((resolve) => {

      var data = new URLSearchParams({
        areaType: '1',
        serviceArea: search_string
      }).toString();

      fetchRequestNode(
        `${config.url.Node_API_URL}/selfService/getServiceAreas`,
        "post",
        data
      )
        .then((response: any) => {

          var res = JSON.parse(response);
          if (res) {

            const autocomplete_results: autocompleteOption[] = res.map((c: any) => {
              return {
                value: c.City_Code,
                display: c.City
              }
            });
            resolve(autocomplete_results);
          }
        }).catch(function (error) {

          console.log(error);
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const saveServiceAreas = (useLoading: boolean, area_type: string, serviceAreas: number[],doDelivery?: boolean) => {
    return new Promise((resolve) => {
      useLoading && setLoading(useLoading);
      
      var data = new URLSearchParams({
        area_type: area_type == 'city' ? '1' : (area_type == 'region' ? '2' : '3'),
        serviceAreas: serviceAreas.join(),
        doDelivery: doDelivery == undefined ? '' : (doDelivery ? '1' : '0')
      }).toString();

      fetchRequestNode(
        `${config.url.Node_API_URL}/selfService/saveServiceAreas`,
        "post",
        data
      )
        .then((response: any) => {
          var res = JSON.parse(response);
          resolve(true);
          useLoading && setLoading(false);
          toast.success('נשמר בהצלחה');
        })
        .catch(function (error) {
          useLoading && setLoading(false);
          console.log(error);
        });
    });
  };
  return (
    <SelfServiceContext.Provider
      value={{
        getMemberBudgetDetails,
        //getRecommendedPacks,
        createBudgetRequests,

        
        getRegions,
        getCities,
        saveServiceAreas,
      }}
    >
      {props.children}
      {/* <CustomLoader
        isOpen={loading}
        duration={5000}
        onDidDismiss={() => {
          setLoading(false);
        }}
      ></CustomLoader> */}
    </SelfServiceContext.Provider>
  );
};

export default SelfServiceContextProvider;
