import React, { useState, useCallback, useEffect,useContext } from "react";
import CommentsContext, { Comment, GmbComment } from "./Comments-context";
import { config } from "../Constants";
import { fetchRequest } from "../utils-ts/fetchRequest";
import { toast } from "react-toastify";
//import CustomLoader from "../components/utils/CustomElements/CustomLoader";
import GlobalContext from "./Global-Context";
const parseString = require("xml2js").parseString;

const CommentsContextProvider: React.FC = (props) => {
  const [comments, setComments] = useState<Comment[] | undefined>();
  const [gmbComments, setGmbComments] = useState<GmbComment[] | undefined>();
  //const [loading, setLoading] = useState<boolean>(false);
  const globalCtx = useContext(GlobalContext);

  const setLoading = (loading:boolean)=>{
   
   globalCtx.setLoader(loading);
 
  };

  useEffect(() => {
    if (gmbComments && comments) {
      setLoading(false);
    }
  }, [comments, gmbComments]);

  const saveComment = async (
    commentId: string,
    commentbusinessReply: string,
    rating: string
  ) => {
    const jsDetails = {
      sBody: commentbusinessReply,
      sParentId: commentId,
      dRating: rating,
    };
    setLoading(true);
    var data = new URLSearchParams({
      jsDetails: JSON.stringify(jsDetails),
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientComments.asmx/saveMemberComment`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              setComments((currentComment) => {
                const updatedComments = [...currentComment!];
                const updatedCommentIndex = updatedComments.findIndex(
                  (c) => c.Id === commentId
                );
                const updatedComment = {
                  ...updatedComments[updatedCommentIndex],
                };
                updatedComment.BusinessReplyText = commentbusinessReply;
                updatedComments[updatedCommentIndex] = updatedComment;
                return updatedComments;
              });
              toast.success("תגובתך תפורסם בדף העסק בתוך 48 שעות");
            } else {
              toast.error(res.ErrorMessage);
            }
          }
        });
      })
      .catch(function (error) {
        console.log("Request failed", error);
      });
  };

  const saveGmbComment = async (ReviewId: number, Comment: string) => {
    const jsDetails = {
      ReviewId: ReviewId,
      Comment: Comment,
    };
    setLoading(true);
    var data = new URLSearchParams({
      jsDetails: JSON.stringify(jsDetails),
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/Client.asmx/ReplayGmbReview`,
      "post",
      data
    )
      .then((response) => {
        setLoading(false);
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              setGmbComments((currentComment) => {
                const updatedComments = [...currentComment!];
                const updatedCommentIndex = updatedComments.findIndex(
                  (c) => c.ReviewId === ReviewId.toString()
                );
                const updatedComment = {
                  ...updatedComments[updatedCommentIndex],
                };
                updatedComment.ReplyComment = Comment;
                updatedComments[updatedCommentIndex] = updatedComment;
                return updatedComments;
              });
              toast.success("תגובתך תפורסם בדף העסק בתוך 48 שעות");
            } else {
              toast.error(res.ErrorMessage);
            }
          }
        });
      })
      .catch(function (error) {
        setLoading(false);
        console.log("Request failed", error);
      });
  };

  const initGmbCommentsContext = useCallback(async (useLoading: boolean) => {
    useLoading && setLoading(useLoading);
    var data = new URLSearchParams({}).toString();

    fetchRequest(
      `${config.url.API_URL}/Client.asmx/GetGmbReviews`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const commentsArray = JSON.parse(res.Response);
              setGmbComments(commentsArray);
            }
          }
        });
      })
      .catch(function (error) {});
  }, []);
  const initCommentsContext = useCallback(async (useLoading: boolean) => {
    useLoading && setLoading(useLoading);
    var data = new URLSearchParams({}).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientComments.asmx/getMemberComments`,
      "post",
      data
    )
      .then((response) => {
        useLoading && setLoading(false);
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const commentsArray = JSON.parse(res.Response);
              setComments(commentsArray);
            }
          }
        });
      })
      .catch(function (error) {
        useLoading && setLoading(false);
      });
  }, []);

  return (
    <CommentsContext.Provider
      value={{
        comments,
        gmbComments,
        saveComment,
        saveGmbComment,
        initGmbCommentsContext,
        initCommentsContext,
      }}
    >
      {props.children}
      {/* <CustomLoader
        isOpen={loading}
        onDidDismiss={() => {
          setLoading(false);
        }}
      ></CustomLoader> */}
    </CommentsContext.Provider>
  );
};

export default CommentsContextProvider;
