import React, { useContext, useEffect, useRef, useState } from 'react';
import { IonContent, IonGrid, IonRow, IonCol, IonList, IonItem, IonLabel, IonCheckbox, isPlatform } from '@ionic/react';
import MemberDetailsContext, { AdvCategories } from '../../../data/MemberDetailes-context';
import CustomPublishButton from '../../utils/CustomElements/CustomPublishButton';
import CustomModal from '../../utils/CustomElements/CustomModal/CustomModal';
import '../businessCardTabs.scss';
import { HeaderParameters } from '../../../data/Header-Context';

import CustomForm from '../../utils/CustomElements/CustomForm';
import { useForm } from 'react-hook-form';
const BusinessAdvCategory: React.FC<{
  show: boolean;
  mainCategory: string;
  closeModal: () => void;
  onSave: () => void;
}> = (props) => {
  const { handleSubmit, formState, errors, control } = useForm({
    mode: 'onChange',
  });
  const { dirty } = formState;
  const [isPageChange, setIsPageChange] = useState(false);
  const MemberDetailsCxt = useContext(MemberDetailsContext);
  const [advCatDoDelivery, setAdvCatDoDelivery] = useState<boolean | undefined>();
  const [currentAdvCategoriesArray, setCurrentAdvCategoriesArray] = useState<AdvCategories[] | undefined>();
  const [originalArray, setOriginalArray] = useState<AdvCategories[] | undefined>();
  useEffect(() => {
    if (MemberDetailsCxt.AdvCategories === undefined) {
      MemberDetailsCxt.initMemberDetailsContext();
    }
  }, []);

  useEffect(() => {
    if (MemberDetailsCxt.AdvCategories) {
      const filterArray = MemberDetailsCxt.AdvCategories?.filter((mc) => mc.Category === props.mainCategory);
      setOriginalArray([...filterArray!]);
      setCurrentAdvCategoriesArray([...filterArray!]);

      const do_delivery = filterArray.find((d: any) => d.IsChecked && d.CategoryFldDesc.indexOf('משלוח') >= 0) != undefined;
      const dont_do_delivery = filterArray.find((d: any) => d.IsChecked == false && d.CategoryFldDesc.indexOf('משלוח') >= 0) != undefined;
      if (do_delivery) {
        setAdvCatDoDelivery(true);
      } else if (dont_do_delivery) {
        setAdvCatDoDelivery(false);
      }
    }
  }, [MemberDetailsCxt.AdvCategories]);

  const saveHandler = () => {
    const originAdvCategoriesArray = MemberDetailsCxt.AdvCategories?.filter((mc) => mc.Category !== props.mainCategory);
    const NewArray = currentAdvCategoriesArray!.concat(originAdvCategoriesArray!);
    MemberDetailsCxt.saveMainDetails(
      'AdvCategories',
      NewArray,
      MemberDetailsCxt.BusinessLanguages,
      //MemberDetailsCxt.OpenDays,
      //MemberDetailsCxt.DaysRemarks,
      MemberDetailsCxt.GeneralInfo,
      MemberDetailsCxt.Description
    );

    const do_delivery = currentAdvCategoriesArray?.find((d) => d.IsChecked && d.CategoryFldDesc.indexOf('משלוח') >= 0) != undefined;
    const dont_do_delivery = currentAdvCategoriesArray?.find((d) => d.IsChecked == false && d.CategoryFldDesc.indexOf('משלוח') >= 0) != undefined;
    if (do_delivery) {
      if (advCatDoDelivery === false) {
        MemberDetailsCxt.saveMemberDeliverySettings(true);
        setAdvCatDoDelivery(true);
      }
    } else if (dont_do_delivery) {
      if (advCatDoDelivery === true) {
        MemberDetailsCxt.saveMemberDeliverySettings(false);
        setAdvCatDoDelivery(false);
      }
    }

    props.onSave();
    props.closeModal();
  };

  const headerParameters: HeaderParameters = {
    modalType: 'modal',
    isPageHaveChanges: dirty || isPageChange,
  };

  const checkIfPageDirty = (newObj: AdvCategories[]) => {
    if (JSON.stringify(newObj) !== JSON.stringify(originalArray)) {
      setIsPageChange(true);
    } else {
      setIsPageChange(false);
    }
  };

  return (
    <CustomModal
      pageName={'BusinessAdvCategories'}
      headerParameters={headerParameters}
      closeModal={() => {
        props.closeModal();
      }}
      title="מאפיינים"
      subTitle="הוספת מאפיינים לדף העיסקי תאפשר ללקוחות לדעת יותר על העסק שלך"
      isOpen={props.show}>
      <CustomForm onSubmit={handleSubmit(saveHandler)} errors={errors}>
        {currentAdvCategoriesArray && (
          <IonGrid>
            <IonRow>
              <IonCol>
                {currentAdvCategoriesArray.map((cat, index) => {
                  return (
                    <IonItem key={index} className="toggle-token" mode="ios">
                      <IonCheckbox
                        className="ion-no-margin"
                        checked={cat.IsChecked}
                        onIonChange={(e) => {
                          const newObj = [...currentAdvCategoriesArray!];
                          const updateCat = { ...newObj[index] };
                          updateCat.IsChecked = e.detail.checked;
                          newObj[index] = updateCat;
                          setCurrentAdvCategoriesArray(newObj);
                          checkIfPageDirty(newObj);
                        }}
                      />
                      <IonLabel className="ion-no-margin">
                        <p>{cat.Title != '' ? cat.CategoryFldDesc + ' - ' + cat.Title : cat.CategoryFldDesc}</p>
                      </IonLabel>
                    </IonItem>
                  );
                })}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <CustomPublishButton />
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </CustomForm>
    </CustomModal>
  );
};

export default BusinessAdvCategory;
