import React, { useContext, useEffect, useState } from 'react';
import { IonGrid, IonRow, IonCol, IonImg, IonButton, IonIcon, IonBadge } from '@ionic/react';

import ProductsContext, { GoogleMerchantEnum, MemberProduct } from '../../../data/Products-context';
import AddEditMemberProduct from './AddEditMemberProduct';
import '../businessCardTabs.scss';
import CustomMultiChoice, { ChooseAll, SetMultiSelectListner } from '../../utils/CustomElements/CustomMultiChoice';
import { blankProductIcon, google, imagePlaceholder, vas_ecommerce_exist } from '../../../images/index';
import MemberDetailsContext from '../../../data/MemberDetailes-context';
import ECommerceOrders from './ECommerceOrders';
import { generateExternalLinkWithToken } from '../../../data/GlobalService';
import GoogleShopVerification from './GoogleShopVerification';
import ECommerceShipping from './ECommerceShipping';

const MemberProducts: React.FC<{
  isRunDelete: boolean;
  isCancelMultiChoice: boolean;
  isAddProductClick: boolean;
  onIsAddProductClick: () => void;
  onDelete: () => void;
  changeHeader: (isShowDeleteButton: boolean, numOfMultiSelected: number | undefined, isShowAddButton: boolean) => void;
}> = (props) => {
  const ProductsCtx = useContext(ProductsContext);

  const [isAddEditing, setIsEditing] = useState(false);
  const [showOrders, setShowOrders] = useState(false);

  const [showShipping, setShowShipping] = useState(false);
  
  const [showGoogleVerification, setShowGoogleVerification] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState<MemberProduct | undefined>();
  const [isMultiChoise, setIsMultiChoise] = useState(false);
  const [chechedElements, setChechedElements] = useState<string[]>([]);

  const MemberDetailsCxt = useContext(MemberDetailsContext);

  useEffect(() => {
    if (props.isRunDelete) {
      props.onDelete();
      ProductsCtx.deletedMemberProductsHandler(chechedElements);
    }
    if (props.isCancelMultiChoice) {
      cancelMuliChoice();
    }
  });
  useEffect(() => {
    //console.log(MemberDetailsCxt.ManageProductGallery);
    if (ProductsCtx.MemberProducts == null && MemberDetailsCxt.ManageProductGallery) {
      ProductsCtx.initMemberProductsContext(true);
    }
  }, [MemberDetailsCxt.ManageProductGallery]);

  useEffect(() => {
    if (props.isAddProductClick === true) {
      setIsEditing(true);
      props.onIsAddProductClick();
    }
  }, [props.isAddProductClick]);

  useEffect(() => {
    if(ProductsCtx.googleMerchantStageChanges){
    if (ProductsCtx.GoogleMerchantStage === GoogleMerchantEnum.Created) {
      //OPEN VERIFICATION MODAL
      setShowGoogleVerification(true);
    } 
    // else  if (ProductsCtx.GoogleMerchantStage === GoogleMerchantEnum.Verified) {
    //   //CLOSE VERIFICATION MODAL
    //   setShowGoogleVerification(false);
    // }
    ProductsCtx.setGoogleMerchantStageChanges(false);
  }
  }, [ProductsCtx.GoogleMerchantStage, ProductsCtx.googleMerchantStageChanges]);

  const cancelMuliChoice = () => {
    setIsMultiChoise(false);
    setChechedElements([]);
    props.changeHeader(false, undefined, true);
  };
  const saveProductHandler = (product: MemberProduct) => {
    ProductsCtx.saveMemberProduct(product);

    dtlPushEvt({
      event: 'products',
      eventInfo: {
        ScreenName: '{{page path}}',
        user_action: 'adds_product',
        business_name: '' + (MemberDetailsCxt.GeneralInfo ? MemberDetailsCxt.GeneralInfo?.CompanyName : ''),
        business_category: GetMemberAdvCategories(MemberDetailsCxt.AdvCategories),
        business_location: MemberDetailsCxt.BusinessAddress?.City,
      },
    });

    setIsEditing(false);
    setSelectedProduct(undefined);
  };

  const getAllElementsId = () => {
    let idsStr: string[] = [];
    if (ProductsCtx.MemberProducts) {
      ProductsCtx.MemberProducts.map((p) => {
        idsStr.push(p.Id.toString());
      });
    }
    return idsStr;
  };

  const openOrdersList = () => {
    setShowOrders(true);
  };

  const openGoogleShop = (show_shipping:boolean) => {
    
    if(ProductsCtx.MemberShippings && ProductsCtx.MemberShippings.length > 0){
      ProductsCtx.openGoogleShop();
      
    }else if(show_shipping){
      setShowShipping(true);
    }
  };


  const openEcommerceProductsEdit = () => {
    generateExternalLinkWithToken().then((data) => {
      const ex_url = data + '1115';
      window.open(ex_url, '_blank');
    });
  };

  return (
    <React.Fragment>
      {isAddEditing && (
        <AddEditMemberProduct
          product={selectedProduct}
          show={isAddEditing}
          onDelete={(productid: number) => {
            ProductsCtx.deletedMemberProductsHandler([productid.toString()]);
            setIsEditing(false);
            setSelectedProduct(undefined);
          }}
          closeModal={() => {
            setIsEditing(false);
            setSelectedProduct(undefined);
          }}
          onSave={saveProductHandler}></AddEditMemberProduct>
      )}

      <IonGrid>
        <IonRow>
          <IonCol className="ion-padding-bottom">
            <p className="small-p">המחקרים שלנו מראים כי דף עסק המכיל גלריה עשירה של מוצרים מגדיל את הסיכוי שלך לקבל פניות מלקוחות פוטנציאלים</p>
          </IonCol>
        </IonRow>
        {MemberDetailsCxt.ManageProductGallery == false && (
          <IonRow>
            <IonCol>
              <div className="ion-text-center">
                <IonButton className="main-button" onClick={openEcommerceProductsEdit}>
                  עבור לניהול המוצרים בחנות
                  <IonIcon slot="end" size="big" icon={vas_ecommerce_exist} />
                </IonButton>
              </div>
            </IonCol>
          </IonRow>
        )}
        {MemberDetailsCxt.ManageProductGallery && (
          <>
            {ProductsCtx.MemberProducts && ProductsCtx.MemberProducts.length === 0 && (
              <IonRow>
                <IonCol className="ion-text-center no-items-wraper">
                  <IonImg src={blankProductIcon}></IonImg>
                  <p className="large-p">אין מוצרים</p>
                </IonCol>
              </IonRow>
            )}

            {ProductsCtx.MemberProducts && ProductsCtx.MemberProducts.length > 0 && (
              <IonRow>
                <IonCol>
                  <ChooseAll
                    show={isMultiChoise}
                    onChooseAll={(checked: boolean) => {
                      if (checked) {
                        const allItemArray = getAllElementsId();
                        setChechedElements(allItemArray);
                        props.changeHeader(true, allItemArray.length, true);
                      } else {
                        cancelMuliChoice();
                      }
                    }}></ChooseAll>
                </IonCol>
                <IonCol size='8'>
                  <div className="ion-text-left">
                {(ProductsCtx.GoogleMerchantStage === GoogleMerchantEnum.None) && (
                      <IonButton slot="end" className="button" color='light' onClick={()=> {openGoogleShop(true)}}>
                        הקמת חנות בגוגל
                        <IonIcon slot="end" size="big" icon={google} />
                      </IonButton>
                )} 
                {(ProductsCtx.GoogleMerchantStage === GoogleMerchantEnum.Created && !showGoogleVerification) && (
                      <IonButton slot="end" className="button" color='light' onClick={() => {setShowGoogleVerification(true)}}>
                        אימות חנות בגוגל
                        <IonIcon slot="end" size="big" icon={google} />
                      </IonButton>
                )}

                { ProductsCtx.TotalOrders != undefined && ProductsCtx.TotalOrders > 0 && (
                    <>
                      <IonButton slot="end" className="button" onClick={openOrdersList}>
                        הזמנות
                        <IonIcon slot="end" size="big" icon={vas_ecommerce_exist} />
                      </IonButton>
                      {ProductsCtx.NewOrders != undefined && ProductsCtx.NewOrders > 0 && (
                        <IonBadge color="danger" slot="end" style={{ position: 'absolute', top: 5, left: 7, borderRadius: 10 }}>
                          {ProductsCtx.NewOrders}
                        </IonBadge>
                      )}
                    </>
                )}
                 {ProductsCtx.MemberShippings != undefined && ProductsCtx.MemberShippings.length > 0 && (
                    
                      <IonButton slot="end" className="button" onClick={()=>{ setShowShipping(true);}}>
                        שיטת משלוח
                      </IonButton>)
                      } 
                </div></IonCol>
              </IonRow>
            )}
            <IonRow>
              {ProductsCtx.MemberProducts &&
                ProductsCtx.MemberProducts.map((product, index) => {
                  return (
                    <IonCol key={index} size="6" sizeMd="3" className="ion-margin-bottom ion-no-padding">
                      <CustomMultiChoice
                        show={isMultiChoise}
                        checkedElements={chechedElements}
                        elementId={product.Id.toString()}
                        onChange={(checkedElements: string[]) => {
                          setChechedElements(checkedElements);
                          if (checkedElements.length > 0) {
                            props.changeHeader(true, checkedElements.length, true);
                          } else {
                            cancelMuliChoice();
                          }
                        }}
                        onTap={() => {
                          setSelectedProduct(product);
                          setIsEditing(true);
                          cancelMuliChoice();
                        }}
                        onPress={() => {
                          setIsMultiChoise(true);
                          props.changeHeader(false, 0, true);
                          SetMultiSelectListner(true, () => {
                            cancelMuliChoice();
                          });
                        }}>
                        <div className="image-container">
                          {chechedElements.find((id) => id == product.Id.toString()) && <div className="selected-image-overlay"></div>}
                          <IonImg src={product.ImagePath || imagePlaceholder} />
                          <div className="image-desc">
                            <p className="bold-text text-wrap white-text text-shadow">{product.Name}</p>
                          </div>
                        </div>
                      </CustomMultiChoice>
                    </IonCol>
                  );
                })}
            </IonRow>
          </>
        )}
      </IonGrid>

      {showOrders && (
        <ECommerceOrders
          show={showOrders}
          closeModal={() => {
            setShowOrders(false);
          }}
        />
      )}
      {showShipping && 
      <ECommerceShipping
       show={showShipping}
       closeModal={(is_open_google_shop:boolean) => {
         setShowShipping(false);
         if(is_open_google_shop){
         openGoogleShop(false);
         }
       }} />}
      {showGoogleVerification && (
        <GoogleShopVerification
          show={showGoogleVerification}
          closeModal={() => {
            setShowGoogleVerification(false);
          }}
          
        />
      )}


    </React.Fragment>
  );
};

export default MemberProducts;
