import React, { createRef, useContext, useEffect, useRef, useState } from 'react';
import { IonButton, IonGrid, IonRow, IonCol, IonItem, IonIcon, IonList, IonText } from '@ionic/react';
import MemberDetailsContext from '../../../data/MemberDetailes-context';

import { useForm } from 'react-hook-form';
import CustomForm from '../../utils/CustomElements/CustomForm';
import CustomItemSliding from '../../utils/CustomElements/CustomItemSliding';
import { addOutline } from 'ionicons/icons';
import CustomModal from '../../utils/CustomElements/CustomModal/CustomModal';
import { HeaderParameters } from '../../../data/Header-Context';
import BusinessMovieCtrl from './BusinessMovieNew';

const BusinessMovies: React.FC<{
  show: boolean;
  closeModal: () => void;
  onSave: () => void;
}> = (props) => {
  const { handleSubmit, formState, errors, control } = useForm({
    mode: 'onChange',
  });
  const { dirty } = formState;
  let MemberDetailsCxt = useContext(MemberDetailsContext);
  useEffect(() => {
    if (MemberDetailsCxt.BusinessMovies == undefined) {
      MemberDetailsCxt.initMovieContext();
    }
  }, []);

  let currentMovies = MemberDetailsCxt.BusinessMovies;

  let selectedMovie = useRef<HTMLIonItemSlidingElement | null>(null);

  const [startedEditing, setStartEditing] = useState(false);
  const selectedMoviesIdRef = useRef<number | null>(null);

  const saveHandler = () => {
    props.onSave();
  };

  const deleteMovie = () => {
    const movieId = selectedMoviesIdRef.current!;
    var movie = currentMovies?.find((i) => i.Id == movieId);
    if (movie) {
      MemberDetailsCxt.saveMovieHandler(movie, 'delete');
      selectedMovie?.current?.closeOpened();
      saveHandler();
    }
  };

  const headerParameters: HeaderParameters = {
    isPageHaveChanges: dirty,
    modalType: 'modal',
  };

  return (
    <CustomModal
      pageName={'BusinessMovies'}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
      headerParameters={headerParameters}
      title="סרטונים"
      subTitle="יש לך ערוץ יוטיוב? מצוין! זה המקום לקשר אליו - כדי שהלקוחות הפוטנציאלים שלך יוכלו לצפות ולהצטרף">
      <CustomForm onSubmit={handleSubmit(saveHandler)} errors={errors}>
        <IonGrid>
          {currentMovies && currentMovies.length > 0 && (
            <IonRow>
              <IonCol>
                <IonList>
                  <IonItem>
                    <IonCol>תיאור</IonCol>
                    <IonCol>לינק</IonCol>
                  </IonItem>
                </IonList>
                {currentMovies.map((movie, index) => {
                  const myRef = createRef<HTMLIonItemSlidingElement>();
                  return (
                    <CustomItemSliding
                      onDelete={() => {
                        selectedMoviesIdRef.current = movie.Id;
                        selectedMovie.current = myRef.current;
                        deleteMovie();
                      }}
                      onEdit={() => {
                        setStartEditing(true);
                        selectedMoviesIdRef.current = movie.Id;
                        selectedMovie.current = myRef.current;
                      }}
                      key={index}
                      ref={myRef}>
                      <IonItem
                        key={index}
                        onClick={() => {
                          setStartEditing(true);
                          selectedMoviesIdRef.current = movie.Id;
                          selectedMovie.current = myRef.current;
                        }}>
                        <IonCol size="6" className="text-wrap ion-no-padding">
                          {movie.MovieDesc}
                        </IonCol>
                        <IonCol size="6" className="text-wrap ltr link ion-no-padding">
                          <a href={movie.MovieUrl} onClick={(event:any)=> {
                            event.stopPropagation();
                            }} target='_blank' rel="noopener noreferrer" className="rtfLink">{movie.MovieUrl}</a>
                        </IonCol>
                      </IonItem>
                    </CustomItemSliding>
                  );
                })}
              </IonCol>
            </IonRow>
          )}

          <IonRow>
            <IonCol>
              {currentMovies && currentMovies.length < 5 ? (
                <IonButton
                  onClick={() => {
                    setStartEditing(true);
                    selectedMoviesIdRef.current = null;
                  }}>
                  {'הוספת סרטון'}
                  <IonIcon icon={addOutline}></IonIcon>
                </IonButton>
              ) : (
                <IonText className="ion-text-center" >
                  <p>ניתן להוסיף עד 5 סרטונים</p>
                </IonText>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </CustomForm>
      {startedEditing && (
        <BusinessMovieCtrl
          id={selectedMoviesIdRef.current!}
          show={startedEditing}
          closeModal={() => {
            setStartEditing(false);
            selectedMovie.current?.closeOpened();
          }}
          onSave={() => {
            setStartEditing(false);
            selectedMovie.current?.closeOpened();
            // currentOpenDays = newOpenDaysArray;
          }}></BusinessMovieCtrl>
      )}
    </CustomModal>
  );
};

export default BusinessMovies;
