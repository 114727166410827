import React, { useContext, useEffect, useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonImg,
  IonCheckbox,
  IonItem,
  IonButton,
  IonRadioGroup,
  IonRadio,
} from "@ionic/react";
import { isMobile } from "react-device-detect";
import GalleryContext, { Image } from "../../../data/Gallery-context";
import GalleryItemPreview from "./GalleryItemPreview";
import { blankImageIcon } from "../../../images/index";
import GalleryHandler from "./GalleryHandler";
import { Photo } from "../../utils/ImagePicker/ImagePicker";
import { monthDiff } from "../../../utils-ts/GlobalDateFunctions";
import CustomMultiChoice, {
  ChooseAll,
  SetMultiSelectListner,
} from "../../utils/CustomElements/CustomMultiChoice";
import DropDownFilter, {
  filterOptions,
} from "../../utils/Filters/DropDownFilter";
import { initMemberActiveSystems } from "../../../data/GlobalService";
import CustomModal from "../../utils/CustomElements/CustomModal/CustomModal";
import { HeaderParameters } from "../../../data/Header-Context";
import MemberDetailsContext from "../../../data/MemberDetailes-context";

const Gallery: React.FC<{
  isRunDelete: boolean;
  isRunUploade: boolean;
  isCancelMultiChoice: boolean;
  isAddImageClick: boolean;
  onIsAddImageClick: () => void;
  onDelete: () => void;
  onUpload: () => void;
  changeHeader: (
    isShowDeleteButton: boolean,
    isShowUploadButton: boolean,
    numOfMultiSelected: number | undefined
  ) => void;
}> = (props) => {
  const GalleryCtx = useContext(GalleryContext);
  useEffect(() => {
    if (GalleryCtx.gallery == null) {
      GalleryCtx.initGalleryContext(true);
    }
  }, []);
  const [galleryFilter, setGalleryFilter] = useState<"All" | "Google">("All");
  const [isAddImag, setIsAddImag] = useState(false);
  const [isShowMultiUploadAlert,setIsShowMultiUploadAlert] = useState(false);
  const [isPreviewImag, setIsPreviewImag] = useState(false);
  const[tempGalleryData,setTempGalleryData] = useState<Image[]>();

  const [isMultiChoise, setIsMultiChoise] = useState(false);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [selectedImageId, setSelectedImageId] = useState<string>();
  const [isShowUploadAlert, setIsShowUploadAlert] = useState(false);
  const [isMultiple,setIsMultiple] = useState(false);
  const [targetOptions, setTargetOptions] = useState<
    { name: string; value: string; label: string; isChecked: boolean }[]
  >([
    { name: "google", label: "גוגל", value: "Google", isChecked: false },
    { name: "gallery", label: "גלריה", value: "Gallery", isChecked: false },
    // { name: "facebook", label: "פייסבוק", value: "Facebook", isChecked: false },
  ]);
  const [isShowDeleteAlert, setIsShowDeleteAlert] = useState(false);
  const [memberActiveSystems, setMemberActiveSystems] = useState<
    number[] | undefined
  >();

  const getAllElementsId = () => {
    let idsStr: string[] = [];
    if (galleryData) {
      galleryData.map((g) => {
        idsStr.push(g.Id.toString());
      });
    }
    return idsStr;
  };

  const MemberDetailsCxt = useContext(MemberDetailsContext);

  let ImageArrayByDate: { title: string; imagArray: Image[] }[] = [];
  let galleryData = GalleryCtx.gallery ? [...GalleryCtx.gallery] : [];

  const setData = () => {
    const todayDate = new Date();
    let todayImage: Image[] = [];
    let lastMonthImage: Image[] = [];
    let beforeLastMonthImage: Image[] = [];
    galleryData?.map((i) => {
      const dateArray = i.InsertDate.split("/");
      const imagDate = new Date(
        dateArray[2] + "/" + dateArray[1] + "/" + dateArray[0]
      );
      const dateDiff = monthDiff(imagDate, todayDate);
      if (dateDiff === 0) {
        todayImage.push(i);
      } else if (dateDiff === 1) {
        lastMonthImage.push(i);
      } else {
        beforeLastMonthImage.push(i);
      }
    });

    if (todayImage.length > 0) {
      ImageArrayByDate.push({ title: "היום", imagArray: todayImage });
    }
    if (lastMonthImage.length > 0) {
      ImageArrayByDate.push({
        title: "בחודש האחרון",
        imagArray: lastMonthImage,
      });
    }
    if (beforeLastMonthImage.length > 0) {
      ImageArrayByDate.push({ title: "לפני", imagArray: beforeLastMonthImage });
    }
  };

  const setCheckedItems = (checkedElements: string[] | undefined) => {
    if (checkedElements) {
      setSelectedImages(checkedElements);
      if (checkedElements.length > 0) {
        // show only for google
        let isShowUploadBtn = memberActiveSystems!.length > 0;
        props.changeHeader(true, isShowUploadBtn, checkedElements.length);
      } else {
        cancelMultiChoice();
      }
    }
  };

  const OptionsModalHeaderParameters: HeaderParameters = {
    isShowLogo: false,
    isHeaderDark: false,
    modalType: "modal",
  };

  useEffect(() => {
    initMemberActiveSystems().then((data) => {
      // get onky google if exist
      data = data.filter((f) => {
        return f == 8;
      });
      setMemberActiveSystems(data);
    });
  }, []);

  useEffect(() => {
    if (props.isAddImageClick === true) {
      setIsMultiple(false);
      setIsShowMultiUploadAlert(true);
      //setIsAddImag(props.isAddImageClick);
      props.onIsAddImageClick();
    }
  }, [props.isAddImageClick]);

  useEffect(() => {
    if (props.isRunUploade) {
      setIsShowUploadAlert(true);
    }
    if (props.isRunDelete) {
      if (memberActiveSystems!.length > 0) {
        setIsShowDeleteAlert(true);
      } else {
        handleDeleteimg(undefined, "Gallery");
      }
    }
    if (props.isCancelMultiChoice) {
      cancelMultiChoice();
    }
  });

  const cancelMultiChoice = () => {
    setIsMultiChoise(false);
    setSelectedImages([]);
    props.changeHeader(false, false, undefined);
  };

  const resetUploadDeleteOptions = () => {
    let tempOptions = [...targetOptions];
    tempOptions.forEach((option) => (option.isChecked = false));
    setTargetOptions(tempOptions);
  };

  const handleDeleteimg = (
    imageId: string | undefined,
    property: "Google" | "Gallery" | ''
  ) => {
    props.onDelete();
    
    let googleTarget = targetOptions.some(
      (target) => target.value === "Google" && target.isChecked === true
    );
    let gelleryTarget = targetOptions.some(
      (target) => target.value === "Gallery" && target.isChecked === true
    );
    // let facebookTarget = uploadOptions.some(
    //   (target) => target.value === "Facebook" && target.isChecked === true
    // );

    if (property === "Gallery" || gelleryTarget) {
      const mark_delete = imageId ? [imageId] : [...selectedImages];
      for(let i = 0; i < ImageArrayByDate.length; i++){
        for(let j = 0; j < ImageArrayByDate[i].imagArray.length; j++){
          if(mark_delete.find(k => ImageArrayByDate[i].imagArray[j].Id == k)) {
            ImageArrayByDate[i].imagArray[j].IsDeleted = true;
          }
        }
      }

      if (imageId !== undefined) {
        setSelectedImages([imageId.toString()]);
        GalleryCtx.deletedImagesHandler([imageId.toString()], "Gallery");
      } else {
        GalleryCtx.deletedImagesHandler(selectedImages, "Google");
      }
    } else if (property === "Google" || googleTarget) {
      GalleryCtx.uploadDeleteFromGoogleHandler(selectedImages, true);
    }
  };

  const handleuploadimg = (imageId: string | undefined) => {
    props.onUpload();
    cancelMultiChoice();
    let googleTarget = targetOptions.some(
      (target) => target.value === "Google" && target.isChecked === true
    );
    // let facebookTarget = uploadOptions.some(
    //   (target) => target.value === "Facebook" && target.isChecked === true
    // );

    if (googleTarget) {
      if (imageId !== undefined) {
        setSelectedImages([imageId.toString()]);
        GalleryCtx.uploadDeleteFromGoogleHandler([imageId.toString()], false);
      } else {
        GalleryCtx.uploadDeleteFromGoogleHandler(selectedImages, false);
      }
    }
    // if (facebookTarget) {
    //   .....
    // }
  };

  if (GalleryCtx.gallery) {
    galleryData = GalleryCtx.gallery?.filter(
      (g) =>
        galleryFilter === "All" ||
        (galleryFilter === "Google" && g.GmbId != null)
    );
    setData();
  }
  const filterOptions: filterOptions[] = [
    {
      title: "הכל",
      value: "All",
    },
    {
      title: "גוגל",
      value: "Google",
    },
  ];

  return (
    <React.Fragment>
      <IonGrid>
        <IonRow>
          <IonCol className="ion-padding-bottom">
            <p className="small-p">
              העלו תמונות נוספות של בית העסק ותנו לגולשים להתרשם לטובה מכל מה
              שיש לכם להציע!
            </p>
          </IonCol>
        </IonRow>

        {galleryData && galleryData.length === 0 && (
          <IonRow>
            <IonCol className="ion-text-center no-items-wraper">
              <IonImg src={blankImageIcon}></IonImg>
              <p className="large-p">אין תמונות בגלריה</p>
            </IonCol>
          </IonRow>
        )}

        {galleryData && galleryData.length > 0 && isMultiChoise && (
          <IonRow>
            <IonCol>
              <ChooseAll
                show={isMultiChoise}
                onChooseAll={(checked: boolean) => {
                  if (checked) {
                    const allItemArray = getAllElementsId();
                    setSelectedImages(allItemArray);
                    let isShowUploadBtn = memberActiveSystems!.length > 0;
                    props.changeHeader(
                      true,
                      isShowUploadBtn,
                      allItemArray.length
                    );
                  } else {
                    cancelMultiChoice();
                  }
                }}
              />
            </IonCol>
          </IonRow>
        )}

        {memberActiveSystems && memberActiveSystems.length > 0 && galleryData && (
          <IonGrid className="ion-no-padding">
            <IonRow>
              <IonCol>
                <DropDownFilter
                  defaulvalueIndex={0}
                  options={filterOptions}
                  onValueCahnge={(value) => {
                    setGalleryFilter(value);
                  }}
                ></DropDownFilter>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
        {galleryData &&
          ImageArrayByDate &&
          ImageArrayByDate.length > 0 &&
          ImageArrayByDate.map((i, index) => {
            return (
              <IonGrid className="ion-no-padding" key={index}>
                <IonRow>
                  <IonCol className="ion-padding-vertical">
                    <IonLabel>
                      <h3 className="small-title bold-text">{i.title}</h3>
                    </IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  {i.imagArray.filter(galleryItem => galleryItem.IsDeleted != true).map((galleryItem, index) => {
                    let imgSize = "&w=154&h=150&typ=1";
                    if (!isMobile) {
                      imgSize = "&w=165&h=150&typ=1";
                    }
                    return (
                      <IonCol
                        className="ion-margin-bottom ion-no-padding"
                        size="6"
                        sizeMd="3"
                        key={galleryItem.Id}
                      >
                        <CustomMultiChoice
                          show={isMultiChoise}
                          checkedElements={selectedImages}
                          elementId={galleryItem.Id}
                          onChange={(checkedElements: string[]) => {
                            setCheckedItems(checkedElements);
                            if (checkedElements.length > 0) {
                              let isShowUploadBtn =
                                memberActiveSystems!.length > 0;
                              props.changeHeader(
                                true,
                                isShowUploadBtn,
                                checkedElements.length
                              );
                            } else {
                              props.changeHeader(false, false, undefined);
                            }
                          }}
                          onTap={() => {
                            // openPreviePage
                            setSelectedImageId(galleryItem.Id);
                            setTempGalleryData(galleryData);
                            setIsPreviewImag(true);
                            cancelMultiChoice();
                          }}
                          onPress={() => {
                            setIsMultiChoise(true);
                            props.changeHeader(false, false, 0);
                            SetMultiSelectListner(true, () => {
                              cancelMultiChoice();
                            });
                          }}
                        >
                          <div className="image-container">
                            {selectedImages.find(
                              (id) => id == galleryItem.Id
                            ) && <div className="selected-image-overlay"></div>}
                            <IonImg src={galleryItem.ImagePath + imgSize} />
                          </div>
                        </CustomMultiChoice>
                      </IonCol>
                    );
                  })}
                </IonRow>
              </IonGrid>
            );
          })}
      </IonGrid>
      {isAddImag && (
        <GalleryHandler
          onSave={(photo: Photo | undefined, desc: string) => {
            if (photo) {
              GalleryCtx.addImage(photo, desc);
              setIsAddImag(false);
             
              dtlPushEvt({'event':'images','eventInfo': {'ScreenName':'{{page path}}','user_action':'adds_image',
              'business_name': '' + (MemberDetailsCxt.GeneralInfo  ? MemberDetailsCxt.GeneralInfo?.CompanyName : ""),
              'business_category':GetMemberAdvCategories(MemberDetailsCxt.AdvCategories),
              'business_location': MemberDetailsCxt.BusinessAddress?.City}});

            }
          }}
          onMultipleSave={(photos: Photo[] , descs: string[]) => {
            
            if (photos) {
              for(let i = 0; i < photos.length; i++){
                const photo = photos[i];
                const desc = descs[i]; //"imgDesc_"
              GalleryCtx.addImage(photo, desc);
              setIsAddImag(false);
             
              dtlPushEvt({'event':'images','eventInfo': {'ScreenName':'{{page path}}','user_action':'adds_image',
              'business_name': '' + (MemberDetailsCxt.GeneralInfo  ? MemberDetailsCxt.GeneralInfo?.CompanyName : ""),
              'business_category':GetMemberAdvCategories(MemberDetailsCxt.AdvCategories),
              'business_location': MemberDetailsCxt.BusinessAddress?.City}});
              }
            }
          }}
          image={undefined}
          closeModal={() => {
            setIsAddImag(false);
            setSelectedImageId(undefined);
          }}
          show={isAddImag}
          isMultiple={isMultiple}
        ></GalleryHandler>
      )}
      {isPreviewImag && (
        <GalleryItemPreview
          imagId={selectedImageId!}
          imageArray={tempGalleryData}
          onDelete={(photoId: string) => {
            handleDeleteimg(photoId, "Gallery");
          }}
          closeModal={() => {
            setIsPreviewImag(false);
          }}
          show={isPreviewImag}
        ></GalleryItemPreview>
      )}
      <CustomModal
        isOpen={isShowUploadAlert}
        headerParameters={OptionsModalHeaderParameters}
        closeModal={function (): void {
          props.onUpload();
          cancelMultiChoice();
          setIsShowUploadAlert(false);
          resetUploadDeleteOptions();
        }}
        pageName={""}
        title="לאיזה נכס תרצה להוסיף תמונות?"
        cssClass="mini-modal"
      >
        <IonRow className="checkbox-list">
          {targetOptions
            .filter((option) => option.value !== "Gallery")
            .map((option, i) => (
              <IonItem key={i} lines="none" className="checkbox-list-item">
                <IonLabel>{option.label}</IonLabel>
                <IonCheckbox
                  slot="start"
                  checked={option.isChecked}
                  value={option.value}
                  onIonChange={(e) => {
                    let tempOptions = [...targetOptions];
                    tempOptions[i].isChecked = e.detail.checked;
                    setTargetOptions(tempOptions);
                  }}
                />
              </IonItem>
            ))}
        </IonRow>
        <IonButton
          className="main-button"
          size="small"
          expand="block"
          onClick={() => handleuploadimg(undefined)}
        >
          אישור
        </IonButton>
      </CustomModal>

      <CustomModal
        isOpen={isShowDeleteAlert}
        headerParameters={OptionsModalHeaderParameters}
        closeModal={function (): void {
          props.onDelete();
          cancelMultiChoice();
          setIsShowDeleteAlert(false);
          resetUploadDeleteOptions();
        }}
        pageName={""}
        title="מהיכן תרצה להסיר את התמונות?"
        cssClass="mini-modal"
      >
        <IonRow className="checkbox-list">
          {targetOptions.map((option, i) => (
            <IonItem key={i} lines="none" className="checkbox-list-item">
              <IonLabel>{option.label}</IonLabel>
              <IonCheckbox
                slot="start"
                checked={option.isChecked}
                value={option.value}
                onIonChange={(e) => {
                  let tempOptions = [...targetOptions];
                  tempOptions[i].isChecked = e.detail.checked;
                  setTargetOptions(tempOptions);
                }}
              />
            </IonItem>
          ))}
        </IonRow>
        <IonButton
          className="main-button"
          size="small"
          expand="block"
          onClick={() => {
            handleDeleteimg(undefined,'');
            setIsShowDeleteAlert(false);
        }
        }
        >
          אישור
        </IonButton>
      </CustomModal>

      <CustomModal
        isOpen={isShowMultiUploadAlert}
        headerParameters={OptionsModalHeaderParameters}
        closeModal={function (): void {
          setIsShowMultiUploadAlert(false);
          
        }}
        pageName={""}
        title="תרצה להעלות תמונה אחת או יותר?"
        cssClass="mini-modal"
      >
        <IonRow>
              <IonCol className="ion-no-padding">
                <IonRadioGroup name="ServiceAreas" value={isMultiple ? "multi" : "single"} onIonChange={(event: CustomEvent) => {
                      setIsMultiple(event.detail.value == 'multi');
                    }}>
                  
                  <IonRow>
                    <IonCol size="12" className="ion-no-padding">
                      <IonItem mode="md" lines="none" className="item-radio">
                        <IonLabel className="item-radio-label">תמונה בודדת <small>(עם אפשרות עריכה)</small></IonLabel>
                        <IonRadio mode="md" slot="start" className="item-radio-btn" value="single" />
                      </IonItem>
                    </IonCol>
                    <IonCol size="12" className="ion-no-padding">
                      <IonItem mode="md" lines="none" className="item-radio">
                        <IonLabel>העלאה מרובה <small>(ללא עריכה)</small></IonLabel>
                        <IonRadio mode="md" slot="start" className="item-radio-btn" value="multi" />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                </IonRadioGroup>
              </IonCol>
            </IonRow>
            
        
        <IonButton
        style={{marginTop:10}}
          className="main-button"
          size="small"
          expand="block"
          onClick={() => {
            setIsAddImag(true);
            setIsShowMultiUploadAlert(false);
          }}
        >
          אישור
        </IonButton>
      </CustomModal>
    </React.Fragment>
  );
};

export default Gallery;
