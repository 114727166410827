import React from "react";

export interface messageObj {
  attachment: string | undefined;
  body:string;
  id:string;
  isDeleted:boolean;
  isRead:boolean;
  memberId:number;
  origin:string;
  platformType:string;
  sending_time:string;
  title:string;
}

const MemberInboxContext = React.createContext<{
  messages:messageObj[]|undefined;
  unReadMessagesAmount: number|undefined;
  totalMessagesAmount: number|undefined;
  totalReadMessagesAmount: number|undefined;
  initMessageContext: (useLoading: boolean, pageNumber: number,messageFilter?:string,returnCount?: number) => void;
  updateMemberMessages: (rowId:string,platformType: string,action:"read"|"delete",pageNum: number,messageFilter?: string) => void;
}>({
  messages:[],
  totalMessagesAmount: undefined,
  unReadMessagesAmount: undefined,
  totalReadMessagesAmount:undefined,
  initMessageContext: () => {},
  updateMemberMessages: () => {}
});

export default MemberInboxContext;
