import React, { useContext, useState, useEffect } from "react";
import {
    IonCheckbox,
    IonCol,
    IonItem,
    IonLabel,
    IonRow,
    IonSelect,
    IonSelectOption,
} from "@ionic/react";

import {
    Control,
    FieldError,
    NestDataObject
} from "react-hook-form";


import VasContext, { selectOption } from "../../data/Vas-context";
import CustomTextarea from "../utils/CustomElements/CustomTextarea";
import VasContactHoursSelect from "./VasContactHoursSelect";
import VasPackSelect from "./VasPackSelect";



const FrmLandingPage: React.FC<{

    register: React.Ref<any>;
    control: Control;
    errors?: NestDataObject<Record<string, any>, FieldError>;
    clearError: (name: string) => void;
}> = props => {
    const vasCxt = useContext(VasContext);

    const [lpGoalsList, setLpGoalsList] = useState<selectOption[] | null>(null);
    useEffect(() => {

        if (lpGoalsList == null) {
            vasCxt.getLandingPageGoalsList().then((result: any) => {
                setLpGoalsList(result);
                props.control.setValue('goal', result[0].value);
            });
        }

        if (props.control &&
            props.control.fieldsRef &&
            props.control.fieldsRef!.current) {
            //register cbx fields
            if (!props.control.fieldsRef!.current!.hasImages) {
                props.control.register('hasImages');
            }
            if (!props.control.fieldsRef!.current!.hasDocs) {
                props.control.register('hasDocs');
            }
            //set cbx default value-false
            if (props.control.fieldsRef!.current!.hasImages &&
                props.control.fieldsRef!.current!.hasImages.ref.value == undefined) {
                props.control.setValue('hasImages', false);
            }
            if (props.control.fieldsRef!.current!.hasDocs &&
                props.control.fieldsRef!.current!.hasDocs.ref.value == undefined) {
                props.control.setValue('hasDocs', false);
            }

        }

    }, []);

    const handleCbxChange = (e: CustomEvent) => {
        props.control.setValue(e.detail.value, e.detail.checked); //for outer form ref
    };
    return (

        <React.Fragment>
            <IonRow>
                <IonCol size='12' sizeMd='6' className='col-1'>
                    <IonRow>
                        <IonCol >
                            <VasPackSelect prod_code={20} register={props.register} control={props.control} errors={props.errors} clearError={props.clearError} />
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol >
                            {lpGoalsList &&
                                <IonItem color='light' >
                                    <IonLabel position="stacked">מטרת דף הנחיתה</IonLabel>
                                    <IonSelect
                                        color="light"
                                        cancelText="ביטול"
                                        okText="אישור"
                                        name='goal'
                                        ref={props.register}
                                        //value={lpGoalsList[0].value}
                                        placeholder="בחר מטרה" >
                                        {lpGoalsList &&
                                            lpGoalsList.map((t) => {
                                                return (
                                                    <IonSelectOption key={t.value} value={t.value}>
                                                        {t.text}
                                                    </IonSelectOption>
                                                );
                                            })}
                                    </IonSelect>
                                </IonItem>
                            }
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol >
                            <IonItem lines='none' color='light' className='item-cbx'>
                                <IonLabel>האם ברשותך תמונות רלוונטיות?</IonLabel>
                                <IonCheckbox slot='end' value='hasImages' onIonChange={handleCbxChange} />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol >
                            <IonItem lines='none' color='light' className='item-cbx'>
                                <IonLabel>האם ברשותך מסמכים/מידע טקסטואלי?</IonLabel>
                                <IonCheckbox slot='end' value='hasDocs' onIonChange={handleCbxChange} />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                   
                </IonCol>
                <IonCol size='12' sizeMd='6' className='col-2'>
                <IonRow>
                        <IonCol >
                            <VasContactHoursSelect register={props.register} control={props.control} />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol >
                            <CustomTextarea
                                name='remarks'
                                errors={props.errors}
                                control={props.control}
                                label='הערות לצוות בוני הדף'
                                ref={props.register} />
                        </IonCol>
                    </IonRow>
                </IonCol>
            </IonRow>
        </React.Fragment>

    );
};

export default FrmLandingPage;