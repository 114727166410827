import {
  IonCard,
  IonCol,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRouterLink,
  IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import {
  ExternalLinkPages,
  InternalLinkPages,
  generateExternalLinkWithToken,
} from "../../data/GlobalService";
import StatisticContext, { StatProd } from "../../data/Statistic-Context";
import {
  arrowLeftEmpty,
  donut_small,
  fact_check,
  ring_volume,
  touch_app,
  whatsapp_click,
} from "../../images";
import DoughnutChart from "../utils/Charts/DoughnutChart";
import CustomTrend from "../utils/CustomElements/CustomTrend/CustomTrend";
import "./statistics.scss";

const MemberCampaignData: React.FC<{
  month?: number;
  year?: number;
  Product?: StatProd;
  LimitMonth?: number;
  LimitYear?: number;
  isShowEmptyData?: boolean;
}> = (props) => {
  const StatisticsCxt = useContext(StatisticContext);

  const [externalLinkWithToken, setExternalLinkWithToken] = useState<
    string | undefined
  >();

  useEffect(() => {
    (async () => {
      if (props.Product && props.Product.Prodmain === 18) {
        const internalLinkPage = InternalLinkPages[props.Product.Prodmain];
        const externalLinkPages = ExternalLinkPages[props.Product.Prodmain];
        const productUrl = internalLinkPage
          ? internalLinkPage
          : await generateExternalLinkWithToken().then((data) =>
              externalLinkPages ? data + externalLinkPages : data
            );
        setExternalLinkWithToken(productUrl);
      }
    })();
  }, []);

  useEffect(() => {
    if (props.Product || !props.month || !props.year) return;
    StatisticsCxt.initStatProductsContext(true, props.month!, props.year!);
  }, [props.month, props.year]);

  const prodDetaile = StatisticsCxt.statProducts?.filter(
    (p) => p.Prodmain === props.Product?.Prodmain
  )[0];
  const prodDetailePrev = StatisticsCxt.statProductsPrev?.filter(
    (p) => p.Prodmain === props.Product?.Prodmain
  )[0];

  let cost = 0;
  let precentage = 0;
  let IsGF = false;
  const emptyStatProduct = {
    Prodmain: 0,
    Budget: 0,
    CallsCount: 0,
    WhatsAppCount: 0,
    Cost: 0,
    Events: 0,
    precentage: 0,
    avgPricePerEvent: 0,
    Impressions: 0,
    GF_Impressions: 0,
    GF_Cost: 0,
    GF_Clicks: 0,
    GF_precentage: 0,
    GF_avgPricePerEvent: 0,
    Transactions: 0,
    CallsDuration: 0,
    IsByBudget: 0,
  };

  const currentProduct = props.isShowEmptyData ? emptyStatProduct : prodDetaile;
  const prevProduct = props.isShowEmptyData
    ? emptyStatProduct
    : prodDetailePrev;
  if (props.Product && prodDetaile && !props.isShowEmptyData) {
    if (
      props.Product.Prodmain === 4 ||
      props.Product.Prodmain === 20 ||
      props.Product.Prodmain === 18
    ) {
      cost = prodDetaile?.GF_Cost;
      precentage = prodDetaile.GF_precentage;
      IsGF = true;
    } else {
      cost = prodDetaile?.Cost;
      precentage = prodDetaile.precentage;
      IsGF = false;
    }
  }

  return (
    <IonGrid>
      {!isMobile && currentProduct && !props.isShowEmptyData && (
        <IonRow className={!isMobile ? "ion-align-items-center" : ""}>
          <IonCol>
            <h2 className="large-title bold-text">
              {prodDetaile && prodDetaile.ProdMainName}
              {currentProduct.Prodmain === 18 && externalLinkWithToken ? (
                <IonRouterLink
                  target="_blank"
                  href={externalLinkWithToken}
                  className="small-p bold-text underline "
                  style={{ marginRight: 20 }}
                >
                  <IonLabel>לצפייה בכל הנתונים</IonLabel>
                  <IonIcon
                    icon={arrowLeftEmpty}
                    style={{
                      verticalAlign: "middle",
                      fontSize: 26,
                      marginRight: -5,
                    }}
                  ></IonIcon>
                </IonRouterLink>
              ) : (
                <></>
              )}
            </h2>
          </IonCol>
        </IonRow>
      )}
      {props.isShowEmptyData && (
        <IonRow>
          <IonCol>
            <small style={{ fontWeight: "bold", color: "red" }}>
              *ניצול תקציב מוצג רק כאשר נבחר טווח תאריכים של חודש שלם
            </small>
          </IonCol>
        </IonRow>
      )}
      <IonRow>
        {/* card-1-start */}
        <IonCol size="12" sizeMd="4" className="card-wrapper">
          <IonCard mode="md" className="statistics-card">
            <IonGrid>
              <IonRow>
                <IonCol className="card-title">
                  <IonIcon
                    icon={donut_small}
                    className="statistics page-icon"
                  />
                  <h3 className="bold-text ion-no-margin">כמה תקציב נוצל?</h3>
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center">
                {(currentProduct?.IsByBudget || props.isShowEmptyData) && (
                  <IonCol className="ion-text-center" size="6" sizeMd="12">
                    <DoughnutChart
                      value={(precentage ?? 0) * 100}
                      chartWidth={!isMobile ? 135 : 118}
                      chartWeight={65}
                      titleColor="#16254f"
                      chartBackgroundColor="#FFCBAD"
                      isGradient
                    />
                  </IonCol>
                )}
                {(currentProduct?.IsByBudget || props.isShowEmptyData) && (
                  <IonCol
                    size="5"
                    sizeMd="12"
                    className={
                      !isMobile ? "ion-text-center" : "ion-align-self-center"
                    }
                  >
                    <span className="bold-text" style={{ color: "#16254f" }}>
                      נוצלו ₪{cost}{" "}
                    </span>
                    {isMobile && <br />}
                    <span>
                      מתוך ₪{Math.round(currentProduct?.Budget ?? 0)}{" "}
                    </span>
                  </IonCol>
                )}
              </IonRow>
            </IonGrid>
          </IonCard>
        </IonCol>

        {/* card-2-start */}
        <IonCol size="12" sizeMd="8" className="card-wrapper">
          <IonCard mode="md" className="statistics-card">
            <IonGrid>
              <IonRow>
                <IonCol className="card-title">
                  <IonIcon icon={fact_check} className="statistics page-icon" />
                  <h3 className="bold-text ion-no-margin">
                    פירוט ביצועי הקמפיין
                  </h3>
                </IonCol>
              </IonRow>
              {currentProduct && (
                <IonGrid>
                  <IonRow
                    style={{
                      marginBlock: "24px",
                    }}
                  >
                    <IonCol offset="1">
                      <CustomTrend
                        value={currentProduct.CallsCount}
                        PrevValue={prevProduct ? prevProduct.CallsCount : 0}
                        header="שיחות"
                        subHeader="כמה שיחות נכנסו בחודש האחרון"
                        icon={ring_volume}
                      ></CustomTrend>
                    </IonCol>
                    <IonCol>
                      <CustomTrend
                        value={currentProduct.WhatsAppCount}
                        PrevValue={prevProduct ? prevProduct.WhatsAppCount : 0}
                        header="הקלקות על וואטסאפ"
                        subHeader="כמה פניות וואטסאפ נכנסו בחודש האחרון"
                        icon={whatsapp_click}
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    {IsGF ? (
                      <>
                        <IonCol offset="1">
                          <CustomTrend
                            value={currentProduct.GF_Clicks}
                            PrevValue={prevProduct ? prevProduct.GF_Clicks : 0}
                            header="הקלקות"
                            subHeader="כמה לחצו על מודעה של הקמפיין"
                            icon={touch_app}
                          ></CustomTrend>
                        </IonCol>
                        <IonCol>
                          <CustomTrend
                            value={currentProduct.avgPricePerEvent}
                            PrevValue={
                              prevProduct ? prevProduct.avgPricePerEvent : 0
                            }
                            header="עלות ממוצעת להקלקה"
                            subHeader="לכמה התקציב התחלק יחסית לחשיפה שקיבלת"
                            icon={touch_app}
                          ></CustomTrend>
                        </IonCol>
                      </>
                    ) : (
                      <>
                        <IonCol>
                          <CustomTrend
                            value={currentProduct.Impressions}
                            PrevValue={
                              prevProduct ? prevProduct.Impressions : 0
                            }
                            header="חשיפות"
                            subHeader="כמה חשיפות היו"
                            icon={touch_app}
                          ></CustomTrend>
                        </IonCol>
                        <IonCol></IonCol>
                      </>
                    )}
                  </IonRow>
                </IonGrid>

                //! OLD DESIGN
                // <IonList className="trends-list-wrapper">
                //   <IonItem lines="none">
                //     <CustomTrend
                //       value={prodDetaile.CallsCount}
                //       PrevValue={
                //         prodDetailePrev ? prodDetailePrev.CallsCount : 0
                //       }
                //       header="שיחות"
                //       subHeader="כמה שיחות נכנסו בחודש האחרון"
                //       icon={ring_volume}
                //     ></CustomTrend>
                //   </IonItem>
                //   <IonItem className="trends-list-wrapper">
                //     <CustomTrend
                //       value={prodDetaile.WhatsAppCount}
                //       PrevValue={
                //         prodDetailePrev ? prodDetailePrev.WhatsAppCount : 0
                //       }
                //       header="הקלקות על וואטסאפ"
                //       subHeader="כמה פניות וואטסאפ נכנסו בחודש האחרון"
                //       icon={whatsapp_click}
                //     />
                //   </IonItem>
                //   {IsGF == true && (
                //     <React.Fragment>
                //       <IonItem lines="none">
                //         <CustomTrend
                //           value={prodDetaile.GF_Clicks}
                //           PrevValue={
                //             prodDetailePrev ? prodDetailePrev.GF_Clicks : 0
                //           }
                //           header="הקלקות"
                //           subHeader="כמה לחצו על מודעה של הקמפיין"
                //           icon={touch_app}
                //         ></CustomTrend>
                //       </IonItem>
                //       <IonItem lines="none">
                //         <CustomTrend
                //           value={prodDetaile.avgPricePerEvent}
                //           PrevValue={
                //             prodDetailePrev
                //               ? prodDetailePrev.avgPricePerEvent
                //               : 0
                //           }
                //           header="עלות ממוצעת להקלקה"
                //           subHeader="לכמה התקציב התחלק יחסית לחשיפה שקיבלת"
                //           icon={touch_app}
                //         ></CustomTrend>
                //       </IonItem>
                //     </React.Fragment>
                //   )}
                //   {IsGF == false && (
                //     <>
                //       <IonItem lines="none">
                //         <CustomTrend
                //           value={prodDetaile.Impressions}
                //           PrevValue={
                //             prodDetailePrev ? prodDetailePrev.Impressions : 0
                //           }
                //           header="חשיפות"
                //           subHeader="כמה חשיפות היו"
                //           icon={touch_app}
                //         ></CustomTrend>
                //       </IonItem>
                //       <IonItem lines="none">
                //         <CustomTrend
                //           value={prodDetaile.GF_avgPricePerEvent}
                //           PrevValue={
                //             prodDetailePrev
                //               ? prodDetailePrev.GF_avgPricePerEvent
                //               : 0
                //           }
                //           header="עלות ממוצעת להקלקה"
                //           subHeader="לכמה התקציב התחלק יחסית לחשיפה שקיבלת"
                //           icon={touch_app}
                //         ></CustomTrend>
                //       </IonItem>
                //     </>
                //   )}
                // </IonList>
              )}
            </IonGrid>
          </IonCard>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default MemberCampaignData;
