import React, { useContext, useEffect, useState } from "react";
import {
  IonCard,
  IonCol,
  IonItem,
  IonRow,
  IonButton,
  IonCheckbox,
  IonLabel,
  IonText,
  IonLoading,
  IonAlert
} from "@ionic/react";
import { useForm } from "react-hook-form";

import SelfSaleContext, { RequestResult, BillingAddress, BezeqPayer, CreditGuardSetup, CreditGuardResponse } from "../../../data/SelfSaleContext";
import CustomInput from "../../utils/CustomElements/CustomInput";
import CustomForm from "../../utils/CustomElements/CustomForm";
import CustomInputGroup from "../CustomElements/CustomRadioGroup";
import CustomAutocomplete from '../CustomElements/CustomAutocomplete';
import CustomSelect from '../CustomElements/CustomSelect';
import { isMobile } from 'react-device-detect';
import CreditGuardIFrame from "./CreditGuardIFrame";
import QuestionButton from "../CustomElements/QuestionButton";

const SelfSalePayment: React.FC<{ saleType: string, completed: boolean, onComplete?: (phaseId: number, result: RequestResult | null) => void }> = (props) => {
  const { handleSubmit, setValue, errors, control, watch } = useForm({
    mode: "onChange", defaultValues: { credit4Digits: "", bezeqCustomer: "", bezeqTzHp: "", invoiceBusinessName: "", streetNum: "" }
  });
  const SelfSaleCtx = useContext(SelfSaleContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasBezeqAccount, setHasBezeqAccount] = useState<string>("-1")
  const [billingAddress, setBillingAddress] = useState<BillingAddress>({ city: "", city_code: "", street: "" })
  const [bezeqPayerMulti, setBezeqPayerMulti] = useState<BezeqPayer[]>([])
  const [bezeqPayerMsg, setBezeqPayerMsg] = useState<string>("")
  const [bezeqPayerCustomerId, setBezeqPayerCustomerId] = useState<string>("")
  const [bezeqPayerValid, setBezeqPayerValid] = useState<boolean>(true)
  const [insertSaleForChizuk, setInsertSaleForChizuk] = useState<boolean>(false)

  const [showCreditGuard, setShowCreditGuard] = useState<boolean>(false)
  const [cgLink, setCgLink] = useState<string>("")
  const [cgResponseData, setCgResponseData] = useState<CreditGuardResponse | null>(null);
  const [showCreditGuardError, setShowCreditGuardError] = useState<boolean>(false);
  const [creditGuardErrorMsg, setCreditGuardErrorMsg] = useState<string>("")

  const credit4Digits = watch("credit4Digits");
  const bezeqCustomer = watch("bezeqCustomer");
  const bezeqTzHp = watch("bezeqTzHp");
  const invoiceBusinessName = watch("invoiceBusinessName");
  const streetNum = watch("streetNum");

  const areaAccessFields = {
    valueAccessor: "city_code",
    labelAccessor: "city"
  }
  const streetAccessFields = {
    valueAccessor: "street_code",
    labelAccessor: "street"
  }
  const hasBezeqAccountOptions = [
    {
      value: "1",
      label: "חשבון הבזק שלי"
    },
    {
      value: "0",
      label: "כרטיס אשראי"
    },
  ]
  useEffect(() => {
    SelfSaleCtx.setFinalStep(false)
  }, [])

  useEffect(() => {
    SelfSaleCtx.setFinalStep(false)
    if (hasBezeqAccount === "1" && bezeqTzHp.length === 9 && ((bezeqCustomer !== "" || bezeqPayerCustomerId !== "") || insertSaleForChizuk)) {
      SelfSaleCtx.setFinalStep(true)
      SelfSaleCtx.setChizukSale(insertSaleForChizuk)
    }
    if (hasBezeqAccount === "0" && cgResponseData?.cardMask && (invoiceBusinessName !== "" && streetNum !== "" && bezeqTzHp !== "" && billingAddress.city !== "" && billingAddress.street !== "")) {
      SelfSaleCtx.setFinalStep(true)
    }
  }, [bezeqPayerCustomerId, bezeqCustomer, insertSaleForChizuk, bezeqTzHp, invoiceBusinessName, streetNum, billingAddress])

  useEffect(() => {
    if (hasBezeqAccount === "0") {
      if (cgResponseData === null) {
        setLoading(true);
        SelfSaleCtx.initCreditGuard().then((res: CreditGuardSetup) => {
          setLoading(false);
          if (res.result === "OK") {
            setCgLink(res.cg_link)
            setShowCreditGuard(true)
          }
          else {
            setCreditGuardErrorMsg("אין גישה לחברת אשראי");
            setShowCreditGuardError(true);
          }
        })
      }
    }
  }, [hasBezeqAccount])
  useEffect(() => {
    if (props.completed) {
      let errors = handlePhaseValidation()
      if (errors.length > 0) {
        props.onComplete && props.onComplete(7, { Success: false, ResultData: "", ResultDesc: errors.join('<br/>') })
      }
      else {
        if (hasBezeqAccount === "0") {
          SelfSaleCtx.saveMethodsOfPaymentNoBezeqAccount(invoiceBusinessName, bezeqTzHp, billingAddress.city, billingAddress.street, streetNum).then((res: any) => {
            props.onComplete && props.onComplete(7, res)
          })
        }
        else {
          let cust_id = bezeqPayerMulti && bezeqPayerMulti.length ? bezeqPayerCustomerId : bezeqCustomer;
          SelfSaleCtx.saveMethodsOfPaymentWithBezeqAccount(cust_id, bezeqTzHp).then((res: RequestResult) => {
            props.onComplete && props.onComplete(7, res)
          })
        }
      }
    }
  }, [props.completed])

  const handleSetHasBezeqAcc = (value: string) => {
    setHasBezeqAccount(value)
    if (value != hasBezeqAccount) {
      setValue([{ bezeqTzHp: "" }, { invoiceBusinessName: "" }, { streetNum: "" }])
      setBillingAddress({ city: "", city_code: "", street: "" })
      setBezeqPayerMsg("")
      setInsertSaleForChizuk(false)
      setBezeqPayerValid(true)
      setShowCreditGuardError(false)
    }
  };
  const getBezeqDetails = () => {
    setBezeqPayerMulti([])
    setBezeqPayerMsg("")

    if (bezeqTzHp && credit4Digits) {
      SelfSaleCtx.getBezeqDetailsByTz(bezeqTzHp, credit4Digits).then((res: any) => {
        if (res && res.length) {
          if (res[0].type === "1") {
            setBezeqPayerCustomerId(res[0].customer_id)
            setValue([{ bezeqCustomer: res[0].customer_id }])
          }

          else {
            setBezeqPayerMsg("יש לבחור מספר לקוח")
            setBezeqPayerMulti(res)
          }
        }
        else {
          setBezeqPayerValid(false)
          setBezeqPayerMsg("מספר לקוח לא נמצא. ניתן לבחור תשלום בכרטיס אשראי או להשלים את הרכישה מול נציג מכירות")
        }
      })
    }
  };
  const fetchCitiesAutocomplete = (prefix: string) => {
    return new Promise((resolve) => {
      SelfSaleCtx.getCitiesAutocomplete(prefix)
        .then((res: any) => {
          if (res && res.length) {
            resolve(res)
          }
          else {
            resolve([])
          }
        })
    });
  }
  const fetchCityStreetsAutocomplete = (prefix: string) => {
    return new Promise((resolve) => {
      SelfSaleCtx.getCityStreetsAutocomplete(prefix, billingAddress.city_code)
        .then((res: any) => {
          if (res && res.length) {
            resolve(res)
          }
          else {
            resolve([])
          }
        })
    });
  }
  const handleSetBillingAddress = (e: any, type: string) => {
    if (type === "city") {
      setBillingAddress(billingAddress => ({ ...billingAddress, city: e.city, city_code: e.city_code }))
    }
    if (type === "street") {
      setBillingAddress(billingAddress => ({ ...billingAddress, street: e.street }))
    }
  };
  const handlePhaseValidation = () => {
    let errors: string[] = []
    if (hasBezeqAccount === "-1" && cgResponseData === null) {
      errors.push("יש לבחור אמצעי תשלום")
    }
    if (hasBezeqAccount === "1" && bezeqTzHp.length !== 9 && ((bezeqCustomer === "" || bezeqPayerCustomerId === "") || insertSaleForChizuk)) {
      errors.push("יש למלא את כל השדות על מנת לסיים את תהליך הרישום")
    }
    if (hasBezeqAccount === "0" && (invoiceBusinessName === "" && streetNum === "" && bezeqTzHp === "" && billingAddress.city === "" && billingAddress.street === "")) {
      errors.push("יש למלא את כל השדות על מנת לסיים את תהליך הרישום")
    }
    return errors
  };
  const handleCreditGuardComplete = (data: CreditGuardResponse, result: RequestResult) => {
    setShowCreditGuard(false)
    if (!result.Success && result.ResultDesc !== null && result.ResultDesc !== "") {
      setCreditGuardErrorMsg(result.ResultDesc)
      setShowCreditGuardError(true)
    }
    else {
      setCgResponseData(data)
    }
  };
  const handleCreditGuardDismiss = () => {
    setShowCreditGuard(false)
    if (cgLink !== "" && cgResponseData == null) {
      setHasBezeqAccount("-1")
    }
  };
  return (
    <CustomForm onSubmit={handleSubmit(() => (console.log("submit")))} errors={errors}>
      <IonCard mode="md" className={isMobile ? "self-sale-card self-sale-card-sm self-sale-card-mobile " : "self-sale-card self-sale-card-sm"}>
        <h2 className="ion-no-margin self-sale-section-title">מה אמצעי התשלום שלך לפרסום?</h2>
        <CustomInputGroup value={hasBezeqAccount} direction="column" name="has_bezeq_account" options={hasBezeqAccountOptions} setValue={(val) => handleSetHasBezeqAcc(val)} />
      </IonCard>
      {hasBezeqAccount === "1" && (
        <>
          <IonCard mode="md" className={isMobile ? "self-sale-card self-sale-payment-card self-sale-card-mobile" : "self-sale-card self-sale-payment-card"}>
            <h2 className="ion-no-margin self-sale-section-title d-flex">פרטי הקו בבזק
            <QuestionButton text="מספר הטלפון העסקי הרשום בבזק "/>
            </h2>
            <IonRow className="ion-align-items-center">
              <IonCol size="12" size-lg="6">
                <CustomInput
                  name="bezeqTzHp"
                  label="ת.ז / ח.פ"
                  required={true}
                  control={control}
                  errors={errors}
                  type="number"
                  maxlength={9}
                  max={"9"}
                  isHideCounter={true}
                ></CustomInput>

              </IonCol>
              <IonButton disabled={bezeqTzHp.length < 9} className="button-small" onClick={getBezeqDetails}>אימות</IonButton>
            </IonRow>

            <IonText color="danger" className="bold-text">{bezeqPayerMsg}</IonText>
            <IonRow className="ion-align-items-center" style={{margin: '10px 0'}}>
              <IonCol size="12" size-lg="6">
                <CustomInput
                  name="credit4Digits"
                  label="4 ספרות אחרונות של כ. אשראי או מס הוראת קבע"
                  required={true}
                  control={control}
                  errors={errors}
                  type="number"
                  maxlength={4}
                  max={"4"}
                  isHideCounter={true}
                ></CustomInput>
              </IonCol>
            </IonRow>

            {!bezeqPayerValid &&
              (<IonItem mode="md" lines="none" className="item-radio">
                <IonCheckbox
                  mode="md"
                  slot="start"
                  className="item-radio-btn"
                  checked={insertSaleForChizuk}
                  onIonChange={(e) => setInsertSaleForChizuk(e.detail.checked)}></IonCheckbox>
                <IonLabel className="item-radio-label">להמשיך את הרכישה מול נציג מכירות</IonLabel>
              </IonItem>)
            }
            <>
              <IonRow>
                <IonCol size="12" size-lg="6">
                  {bezeqPayerMulti && bezeqPayerMulti.length > 0 && (
                    <CustomSelect onSelect={(e) => setBezeqPayerCustomerId(e)}
                      label="מספר לקוח"
                      showValues={true}
                      placeholder="בחר מהרשימה"
                      name="bezeqCustomerMulti"
                      value={bezeqPayerCustomerId}
                      optionLabelFld="customer_name"
                      optionValueFld="customer_id"
                      options={bezeqPayerMulti} />
                  ) || bezeqPayerValid && (<CustomInput
                    name="bezeqCustomer"
                    label="מספר לקוח"
                    control={control}
                    errors={errors}
                    type="number"
                    defaultValue={bezeqPayerCustomerId}
                    disabled={true}
                    isHideCounter={true}
                  ></CustomInput>)}
                </IonCol>
              </IonRow>
            </>
          </IonCard>

        </>

      )}
      {hasBezeqAccount === "0" && (
        <>
          {cgLink !== "" && (
            <IonCard mode="md" className={isMobile ? "self-sale-card self-sale-card-mobile" : "self-sale-card"}>
              <h2 className="ion-no-margin self-sale-section-title">אמצעי תשלום</h2>
              {cgResponseData === null && (<CreditGuardIFrame onComplete={handleCreditGuardComplete} src={cgLink} />)}
              {cgResponseData !== null && (<p className="large-p">פרטי אשראי נקלטו לכרטיס שמסתיים בספרות: {cgResponseData.cardMask.slice(-4)}</p>)}
            </IonCard>
          )}
          <IonCard mode="md" className={isMobile ? "self-sale-card self-sale-card-mobile" : "self-sale-card"}>
            <h2 className="ion-no-margin self-sale-section-title">פרטים לחיוב</h2>
            <IonRow>
              <IonCol size="12" size-lg="6">
                <CustomInput
                  name="bezeqTzHp"
                  label="עוסק מורשה / ח.פ"
                  required={true}
                  control={control}
                  errors={errors}
                  type="number"
                  maxlength={9}
                  max={"9"}
                  isHideCounter={true}
                ></CustomInput>
                <CustomInput
                  name="invoiceBusinessName"
                  label="שם על החשבונית"
                  required={true}
                  control={control}
                  errors={errors}
                  type="text"
                ></CustomInput>
                <p className="ion-no-margin self-sale-section-subtitle">כתובת לחיוב</p>
                <CustomAutocomplete
                  options={areaAccessFields}
                  placeholder={"עיר"}
                  selectedItems={[]}
                  header={"עיר"}
                  fetchItems={fetchCitiesAutocomplete}
                  onSelect={(e) => handleSetBillingAddress(e, "city")}
                  type="standard"
                  query={billingAddress.city} />

                <CustomAutocomplete
                  options={streetAccessFields}
                  placeholder={"רחוב"}
                  selectedItems={[]}
                  header={"רחוב"}
                  fetchItems={fetchCityStreetsAutocomplete}
                  onSelect={(e) => handleSetBillingAddress(e, "street")}
                  type="standard"
                  query={billingAddress.street} />
                <CustomInput
                  name="streetNum"
                  label="מספר בית"
                  required={true}
                  control={control}
                  errors={errors}
                  type="number"
                ></CustomInput>
              </IonCol>
            </IonRow>
          </IonCard>


        </>
      )}
      <IonLoading isOpen={loading}></IonLoading>
      <IonAlert isOpen={showCreditGuardError} onDidDismiss={handleCreditGuardDismiss} header="שגיאה" message={creditGuardErrorMsg} buttons={['OK']} />
    </CustomForm>
  );
};

export default SelfSalePayment;