import React from "react";


export interface request {
  old_b144_pack: string;
  new_b144_pack: string;
  // customer_id: string;
  // billing_phone: string;
}

export interface BudgetDetails {
  pack_code: number;
  pack_name: string;
  cost: number;
  prods_in_pack: string[];
  //has_upgrades: boolean;
  recommendedPacks: UpgradeBudgetOption[];
  isFixed: boolean;
  max_budget_fee: number;
}
export interface UpgradeBudgetOption {
  pack_code: number;
  pack_name: string;
  cost: number;
  isFixed: boolean;
  max_budget_fee: number;
}



const SelfServiceContext = React.createContext<{
  getMemberBudgetDetails: (useLoading: boolean) => any;
  //getRecommendedPacks: (useLoading: boolean, pack_id: number) => any;
  createBudgetRequests: (useLoading: boolean, req: request) => any;
  getRegions: (useLoading: boolean) => any,
  getCities: (search_string: string) => any,
  saveServiceAreas: (useLoading: boolean, area_type: string, serviceAreas: number[], doDelivery?: boolean) => any;
}>({
  getMemberBudgetDetails: () => { },
  //getRecommendedPacks: () => { },
  createBudgetRequests: () => { },

  
  getRegions: () => { },
  getCities: () => { },
  saveServiceAreas: () => { },
});

export default SelfServiceContext;
