import React, { createContext, useState } from 'react';

interface ScrollContextType {
  scrollPosition: number;
  setScrollPosition: (position: number) => void;
}

export const ScrollContext = createContext<ScrollContextType>({
  scrollPosition: 0,
  setScrollPosition: () => {},
});

export const ScrollProvider: React.FC = ({ children }) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  return (
    <ScrollContext.Provider value={{ scrollPosition, setScrollPosition }}>
      {children}
    </ScrollContext.Provider>
  );
};