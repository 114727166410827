import React from "react";
//https://www.npmjs.com/package/qrcode.react
var QRCode = require("qrcode.react");
const CustomQrCode: React.FC<{
  value: string;
  qrSize: number;
}> = (props) => {
  return (
    <div
      id="downloadToPdf"
      style={{
        position: "absolute",
        width: `${props.qrSize}px`,
        height: `${props.qrSize}px`,
        zIndex: -9999,
      }}
    >
      <QRCode
        bgColor="#FFFFFF"
        fgColor="#16254f"
        renderAs="svg"
        level="Q"
        size={props.qrSize}
        value={props.value}
      />
    </div>
  );
};

export default CustomQrCode;
