// React
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { IonGrid, IonRow, IonCol, IonItem, IonLabel, IonSelect, IonSelectOption, IonList, IonIcon, IonButton } from '@ionic/react';
import CustomForm from '../utils/CustomElements/CustomForm';
import CustomModal from '../utils/CustomElements/CustomModal/CustomModal';

// Context
import { HeaderParameters } from '../../data/Header-Context';
import CustomInput from '../utils/CustomElements/CustomInput';
import { GetLogsForItem, memberLeadOrCallObj, logObj, GetAllDataPhone } from '../../data/statisticServices';
import CustomPublishButton from '../utils/CustomElements/CustomPublishButton';

import moment from 'moment';
import CustomTextarea from '../utils/CustomElements/CustomTextarea';
import { convertTimeStampToDate, convertTimeStampToTime } from '../../utils-ts/GlobalDateFunctions';
import { blankProductIconRed } from '../../images';
import LeadsAndCallsList from './LeadsAndCallsList';
import { isMobile } from 'react-device-detect';

const LeadItemPopup: React.FC<{
  show: boolean;
  closeModal: () => void;
  currentItem: memberLeadOrCallObj;
  onSave: (itemToUpdate: memberLeadOrCallObj) => void;
}> = (props) => {
  const { handleSubmit, formState, errors, control } = useForm({
    mode: 'onChange',
  });

  const [currentItem, setCurrentItem] = useState(props.currentItem);
  const [logsList, setLogsList] = useState<logObj[]>();
  const [showMoreFromNumber, setShowMoreFromNumber] = useState(false);
  const [leadsAndCallsList, setLeadsAndCallsList] = useState<memberLeadOrCallObj[]>();

  const saveHandler = async (data: any) => {
    const { Name, Phone, Address, Mail, Referer, Comments } = data!;
    currentItem.Name = Name;
    currentItem.Address = Address;
    currentItem.Mail = Mail;
    currentItem.Referer = Referer;
    //currentItem.Date = moment(currentItem.Date).toISOString(true);
    currentItem.Phone = Phone;
    currentItem.Comments = Comments;
    props.onSave(currentItem);
  };
  const headerParameters: HeaderParameters = {
    isShowEditButton: false,
    modalType: 'modal',
  };

  const dropdownStatusFunc = (e: CustomEvent) => {
    currentItem.StatusType = e.detail.value;
  };
  const dropdownHighQualityFunc = (e: CustomEvent) => {
    currentItem.HighQuality = e.detail.value;
  };

  useEffect(() => {
    if (currentItem) {
      GetLogsForItem(currentItem.Id).then((res) => {
        setLogsList(res);
      });
    }
  }, [currentItem]);

  useEffect(() => {
    if (showMoreFromNumber) {
      GetAllDataPhone(currentItem.Phone).then((res) => {
        setLeadsAndCallsList(res);
        console.log(res);

        //setLoader(false);
      });
    }
  }, [showMoreFromNumber]);

  return (
    <CustomModal
      pageName={'LeadItemPopup'}
      headerParameters={headerParameters}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
      title={(props.currentItem.Type == 1 ? 'שיחה' : 'ליד') + ' ' + props.currentItem.LeadId}>
      <CustomForm onSubmit={handleSubmit(saveHandler)} errors={errors}>
        <IonGrid className="ion-no-padding ion-padding-horizontal">
          <IonRow>
            <IonCol className="ion-padding-vertical">
              <React.Fragment>
                <IonRow>
                  <IonCol size="12" offsetMd="2" sizeMd="5">
                    <CustomInput
                      name="Name"
                      errors={errors}
                      control={control}
                      label="שם משאיר הליד"
                      // required={true}
                      type="text"
                      value={currentItem.Name}
                      maxlength={45}
                      disabled={currentItem.OriginName != ''}
                    />
                  </IonCol>
                  <IonCol size="12" sizeMd="5" style={{marginBottom: 25}}>
                    <IonItem color="light" lines="full" mode="md">
                      <IonLabel position="stacked">סטטוס הליד</IonLabel>
                      <IonSelect
                        color="light"
                        cancelText="ביטול"
                        okText="אישור"
                        aria-required={false}
                        value={currentItem.StatusType}
                        placeholder={'בחר סטטוס'}
                        onIonChange={(e) => {
                          dropdownStatusFunc(e);
                        }}
                        defaultValue={0}
                        interface="popover">
                        <IonSelectOption value={0}>{'פניה פתוחה'}</IonSelectOption>
                        <IonSelectOption value={1}>{'אין מענה'}</IonSelectOption>
                        <IonSelectOption value={2}>{'לא רלוונטי'}</IonSelectOption>
                        <IonSelectOption value={3}>{'לחזור ללקוח'}</IonSelectOption>
                        <IonSelectOption value={4}>{'בטיפול'}</IonSelectOption>
                        <IonSelectOption value={5}>{'טופל'}</IonSelectOption>
                        <IonSelectOption value={6}>{'בוטל'}</IonSelectOption>
                        <IonSelectOption value={7}>{'מחכה לתשובה מהלקוח'}</IonSelectOption>
                        <IonSelectOption value={8}>{'אחר'}</IonSelectOption>
                      </IonSelect>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" offsetMd="2" sizeMd="5">
                    <CustomInput
                      name="Phone"
                      errors={errors}
                      control={control}
                      label="טלפון"
                      required={false}
                      type="text"
                      disabled={currentItem.OriginPhone != ''}
                      value={currentItem.Phone}
                      maxlength={12}
                    />
                  </IonCol>
                  <IonCol size="12" sizeMd="5">
                    <CustomInput
                      name="Mail"
                      errors={errors}
                      control={control}
                      label="מייל"
                      required={false}
                      type="text"
                      disabled={currentItem.OriginMail != ''}
                      value={props.currentItem.Mail}
                      maxlength={40}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" offsetMd="2" sizeMd="5">
                    <CustomInput
                      name="Address"
                      errors={errors}
                      control={control}
                      label="כתובת"
                      required={false}
                      type="text"
                      disabled={currentItem.OriginAddress != ''}
                      value={currentItem.Address}
                      maxlength={12}
                    />
                  </IonCol>
                  <IonCol size="12" sizeMd="5" style={{marginBottom: 25}}>
                    <CustomInput name="Referer" control={control} label="מקור הליד" required={false} type="text" disabled value={currentItem.Referer} />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" offsetMd="2" sizeMd="5">
                    <CustomTextarea
                      name="NotesOrigin"
                      errors={errors}
                      control={control}
                      required={false}
                      label="פרטי הליד"
                      value={currentItem.NotesOrigin}
                      maxlength={1000}
                      disabled={true}></CustomTextarea>
                  </IonCol>

                  <IonCol size="12" sizeMd="5">
                    <CustomTextarea
                      name="Comments"
                      maxHeight={isMobile ? '133px' : '158px'}
                      // onClearFunc={() => {
                      //   fileDescriptionTextRef.current!.value = "";
                      // }}
                      errors={errors}
                      control={control}
                      required={false}
                      label="הערות לעצמי"
                      value={currentItem.Comments}
                      maxlength={1000}></CustomTextarea>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" offsetMd="2" sizeMd="5">
                    <IonItem color="light" lines="full" mode="md">
                      <IonLabel position="stacked">האם הליד היה איכותי</IonLabel>
                      <IonSelect
                        color="light"
                        cancelText="ביטול"
                        okText="אישור"
                        aria-required={false}
                        value={currentItem.HighQuality}
                        placeholder={'בחר'}
                        onIonChange={(e) => {
                          dropdownHighQualityFunc(e);
                        }}
                        interface="popover">
                        <IonSelectOption value={0}>{'פנייה לא קשורה לתחום שלי'}</IonSelectOption>
                        <IonSelectOption value={1}>{'מעולה תביאו לי עוד לידים כאלו'}</IonSelectOption>
                        <IonSelectOption value={2}>{'נראה שהגיע בטעות'}</IonSelectOption>
                        <IonSelectOption value={3}>{'ליד איכותי אבל בסוף לא סגרנו'}</IonSelectOption>
                      </IonSelect>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </React.Fragment>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonGrid
              className="ion-no-padding ion-padding-horizontal"
              style={{
                textAlign: 'center',
              }}>
              <IonList
                style={{
                  padding: 0,
                }}>
                <IonRow
                  style={{
                    fontWeight: 'bold',
                    borderRadius: 0,
                    fontSize: '12px',
                    backgroundColor: '#16254f',
                    color: 'white',
                  }}>
                  <IonCol size="4">סוג פניה</IonCol>
                  <IonCol size="4">תאריך</IonCol>
                  <IonCol size="4">שעה</IonCol>
                </IonRow>
              </IonList>

              {logsList &&
                logsList.length > 0 &&
                logsList.map((m, index) => {
                  const date = convertTimeStampToDate(m.Date);
                  const time = convertTimeStampToTime(m.Date);
                  return (
                    <IonRow key={index} style={{ border: '1px solid #cbcbcb' }}>
                      <IonCol size="4">{m.Type}</IonCol>
                      <IonCol size="4">{date}</IonCol>
                      <IonCol size="4">{time}</IonCol>
                    </IonRow>
                  );
                })}

              {logsList == null ||
                (logsList.length === 0 && (
                  <IonRow>
                    <IonCol className="ion-text-center ion-padding-vertical" style={{ minHeight: '150px' }}>
                      <IonIcon icon={blankProductIconRed} className="blank-list-icon" />
                      <p>אין יומן פעילות</p>
                    </IonCol>
                  </IonRow>
                ))}
            </IonGrid>
          </IonRow>
          <IonRow>
            {showMoreFromNumber ? (
              <div style={{ width: '100%' }}>
                <div style={{ textAlign: 'center' }}>
                  <IonButton
                    onClick={() => {
                      setShowMoreFromNumber(false);
                    }}>
                    הסתר פניות
                  </IonButton>
                </div>
                {leadsAndCallsList && (
                  <>
                    <LeadsAndCallsList items={leadsAndCallsList} inPopup={true} filterSelected="all" />
                  </>
                )}
              </div>
            ) : (
              <div style={{ textAlign: 'center', width: '100%' }}>
                <IonButton
                  onClick={() => {
                    setShowMoreFromNumber(true);
                  }}>
                  הצג פניות נוספות ממספר הטלפון הזה
                </IonButton>
              </div>
            )}
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonCol size="2">
              <CustomPublishButton title="שמירה"></CustomPublishButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </CustomForm>
    </CustomModal>
  );
};

export default LeadItemPopup;
