const otef = false;
const prod = {
    SelfSaleActive: false,
    loader_max_duration: 5000,
    loader_hide_delay: 1500,
    total_filesize_mb: 8,
    logo_war: true,
    otef_israel: otef,
    update_delivery:false,
    seo_discounts: true,
    newMovieFormats: false,
    url: {
        API_URL: otef ? "https://appwsotef.b144.co.il" : "https://appws.b144.co.il",
        Node_API_URL: otef ? "https://ezbiznodeapiotef.b144.co.il" : "https://ezbizNodeApi.b144.co.il",
        Member_Url: "https://members.b144.co.il",
        MEMBERS_LOGIN_CHECK_URL: "https://members.b144.co.il/Infrastructure/B144_Login/checkuserlogin.aspx?tp=1&_t=",//tp - UserType regular : 1 ; debug :2
        SELF_SALE_WS: "https://selfsalews.b144.co.il/SalesWS.asmx",
    }

    //192.168.10.22:
    // url: {
    //     API_URL: "http://sbms6.b-soft.co.il",
    //     Member_Url: "http://socialbiz.b-soft.co.il",
    //     MEMBERS_LOGIN_CHECK_URL: "http://socialbiz.b-soft.co.il/Infrastructure/B144_Login/checkuserlogin.aspx?tp=1&_t=",//tp - UserType regular : 1 ; debug :2
    // }
};
const dev = {
    SelfSaleActive: true,
    loader_max_duration: 5000,
    loader_hide_delay: 1500,
    total_filesize_mb: 8,
    logo_war: true,
    otef_israel: otef,
    update_delivery:true,
    seo_discounts: true,
    newMovieFormats: false,
    url: {
        API_URL: "http://localhost:59836",
        Node_API_URL: "http://localhost:3001",
        Member_Url: "http://localhost:55309",
        MEMBERS_LOGIN_CHECK_URL: "http://localhost:55309/Infrastructure/B144_Login/checkuserlogin.aspx?tp=1&_t=",//tp - UserType regular : 1 ; debug :2
        SELF_SALE_WS: "http://localhost:20002/SalesWS.asmx",
    }
};

export const config = process.env.NODE_ENV === "production" ? prod : dev;