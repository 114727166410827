import { IonRow, IonRadioGroup, IonIcon, IonLabel, IonCol, IonRadio, IonItem, IonCheckbox } from "@ionic/react";
import React from "react";
import { isMobile } from 'react-device-detect';
import CustomCheckbox from './CustomCheckbox'

const CustomInputGroup: React.FC<{ value?: string, name: string, setValue: (value: string) => void, options: any[], multichoise?: boolean, direction?: string }> = (props) => {

    return (<>
            {props.options && props.multichoise && (<>
                {props.options.map((option, index) => (<CustomCheckbox key={index} checked={option.selected} onSelect={props.setValue} value={option.value}  label={option.label} selected={option.selected} />)
                )}
            </>)}
        {props.options && !props.multichoise && (
            <IonRadioGroup
                allowEmptySelection={true}
                name={props.name}
                onIonChange={(e) => { props.setValue(e.detail.value) }}
                value={props.value || ""}
                className={isMobile ? "custom-radio-group mobile-view" : props.direction==="column" ? "custom-radio-group column" : "custom-radio-group"}
            >
                {props.options.map((option, index) => (<IonItem key={index} mode="md" lines="none" className="item-radio">
                    <IonLabel className="item-radio-label">{option.label}</IonLabel>
                    <IonRadio
                        mode="md"
                        slot="start"
                        className="item-radio-btn"
                        value={option.value}
                    />
                </IonItem>))}
            </IonRadioGroup>
        )}
    </>
    )
}
export default CustomInputGroup;