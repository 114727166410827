import React from "react";
import Highlighter from "../../components/Highlighter/Highlighter";
import background from "./google-results-page.webp";

export default function GoogleResultsPage() {
  return (
    <div style={{ position: "relative", backgroundColor: "white" }}>
      <img src={background} alt="Google results" />

      <Highlighter
        title="חיפוש לפי קטגוריה בגוגל"
        text="הגדילו את החשיפה לעסק שלכם! יש לנו את מיטב המערכות, ידע וניסיון שנצברו במשך שנים, ובכך אנו יודעים לזהות את הקהל הרלוונטי לעסק שלך. באמצעות חשיפה בגוגל העסק שלכם יכול להגיע למודעות של מאות אלפי גולשים ויותר"
        style={{
          width: "100%",
          position: "absolute",
          height: "19.5%",
          top: "18.5%",
        }}
      />
    </div>
  );
}
