import {
  IonCard,
  IonCol,
  IonIcon,
  IonLabel,
  IonList,
  IonRow,
} from "@ionic/react";
import React, { useRef } from "react";
import { isMobile } from "react-device-detect";
import useDownloadExcel from "../../hooks/useDownloadExcel";
import { blankProductIconRed, excel } from "../../images";

interface Props {
  items?: any[];
  additionalData?: string;
  exportToExl?: boolean | string;
  noDataElement?: React.ReactNode | React.ReactNode[];
}

const ProductStatisticTable = ({
  items,
  additionalData,
  exportToExl = true,
  noDataElement,
}: Props) => {
  const tableRef = useRef<HTMLTableElement>(null);
  const { onDownload } = useDownloadExcel({
    tableRef,
    filename: typeof exportToExl === "string" ? exportToExl : "סטטיסטוקות",
  });

  return (
    <>
      {!!items?.length ? (
        <>
          <IonRow
            className="ion-justify-content-end ion-align-items-center"
            style={{ columnGap: "40px", marginBottom: "10px" }}
          >
            {additionalData && (
              <IonCol className="ion-no-padding">
                <IonCard
                  className="ion-no-margin"
                  style={{ paddingTop: 5, paddingBottom: 5 }}
                >
                  <IonLabel>{additionalData}</IonLabel>
                </IonCard>
              </IonCol>
            )}
            {exportToExl && (
              <button
                style={{
                  borderRadius: "17px",
                  boxShadow: "2px 2px 6px 0",
                  border: "solid 1px #4a4a4a",
                  padding: "4px 12px",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={onDownload}
              >
                <IonIcon size="small" icon={excel}></IonIcon>ייצוא לאקסל
              </button>
            )}
          </IonRow>

          <div
            style={{ overflowX: "scroll", width: "100%" }}
            className="leadsTable"
          >
            <table
              className="ion-no-padding ion-padding-horizontal "
              style={{
                width: isMobile ? "650px" : "100%",
              }}
              ref={tableRef}
            >
              <thead>
                <tr
                  style={{
                    padding: 0,
                    fontWeight: "bold",
                    fontSize: "12px",
                    height: "45px",
                    backgroundColor: "#16254f",
                    textAlign: "right",
                    color: "white",
                  }}
                >
                  {Object.keys(items[0]).map((value, i) => (
                    <th style={{ minWidth: "75px" }} key={i}>
                      {value}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {items.map((item, index) => (
                  <tr
                    key={index}
                    style={{
                      fontSize: "12px",
                      borderBottom: "1px solid #e7e9ed",
                      backgroundColor: "white",
                      height: "30px",
                    }}
                  >
                    {Object.values(item).map((value: any, i) => (
                      <td key={value + i}>
                        {typeof value === "string" ? (
                          <div dangerouslySetInnerHTML={{ __html: value }} />
                        ) : (
                          value
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <IonList>
          <IonRow>
            <IonCol className="ion-text-center">
              <IonIcon icon={blankProductIconRed} className="blank-list-icon" />
              {noDataElement ? noDataElement : <p>אין סטטיסטיקות</p>}
            </IonCol>
          </IonRow>
        </IonList>
      )}
    </>
  );
};

export default ProductStatisticTable;
