import React from "react";
import { Photo } from "../components/utils/ImagePicker/ImagePicker";


export interface userProfileSettingsInfo {
Email:string,
EmployeeFullName:string,
FaxFax:string,
FirstName:string,
FullName:string,
GenderId: number,//1=man
Id: number,
LastName: string,
MobilePhone: string,
Phone1: string,
Phone2: string| null,
ProfileImage: {FileId: number, Url:string, DefaultWidth: number|null, DefaultHeight: number|null, ImageAvrgColor: string},
ProfileImagePath: string,
UserCode: string,
UserId: number,
UserRoleId: number
}

export interface userSettingsInfo {
  isShowNotification: boolean;
}
export interface memberNotifications {
  isShowNotification: boolean;
}

export interface memberPreventNotification {
  NotificationGroupId : number;
}
export interface notificationGroup {
  name: string;
  Description: string;
  Id: number;
  isActive: boolean;
}
const UserSettingsContext = React.createContext<{
  userSettingsInfo: userSettingsInfo| undefined;
  userNotificationAmount:number| undefined;
  memberPreventNotification:memberPreventNotification[]| undefined
  notificationGroup:notificationGroup[]| undefined
  userProfileSettingsInfo:userProfileSettingsInfo|undefined
  deleteProfilePhoto: (useLoading: boolean)=>void
  initUserNotificationAmount: ()=>void
  updateUserNotificationAmount:()=>void
  ChangePassword: (useLoading: boolean, NewPassWord: string, confirmPassword: string) => void;
  initUserSettingContext: (useLoading: boolean) => void;
  updateProfilePhoto: (photo: Photo) => void;
  initUserProfileSettingContext: (useLoading: boolean) => void;
  initUserNotificationsContext: (useLoading: boolean) => void;
  updateUserSettingContext: (useLoading: boolean,userSettingsInfo: userSettingsInfo) => void;
  updateUserNotificationsContext: (useLoading: boolean,NotificationGroupId: memberPreventNotification[]) => void;
}>({
  userSettingsInfo: undefined,
  userNotificationAmount: undefined,
  userProfileSettingsInfo: undefined,
  memberPreventNotification: [],
  notificationGroup: [],
  updateProfilePhoto: () => {},
  updateUserNotificationAmount: () => {},
  initUserNotificationAmount: () => {},
  deleteProfilePhoto: () => {},
  ChangePassword: () => {},
  initUserSettingContext: () => {},
  initUserProfileSettingContext: () => {},
  initUserNotificationsContext: () => {},
  updateUserSettingContext: () => {},
  updateUserNotificationsContext: () => {},
});

export default UserSettingsContext;
