import {
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
} from "@ionic/react";
import { isMobile } from "react-device-detect";
import React, { useEffect, useState } from "react";
import { facebook, google, landingPage, eCommerce } from "../../images/index";
import CustomHeaddingItem from "../utils/CustomElements/CustomPageTitle/CustomHeaddingItem";
import {
  GetFacebookPagesSummery,
  DateFilter,
  GetGooglePagesSummery,
  GetLandingPageSummery,
  GetECommerceSummery,
} from "../../data/statisticServices";

import CustomTrend from "../utils/CustomElements/CustomTrend/CustomTrend";
import LineChart from "../utils/Charts/LineChart";
import CustomDateRangePicker, {
  defaultStaticRanges,
  defaultStaticRangesOpt,
} from "../utils/Filters/CustomDateRangePicker/CustomDateRangePicker";
import { useParams } from "react-router";
const ProductInfoData: React.FC<{ productName: string }> = (props) => {
  const productNameHe: { [id: string]: { title: string; icon: any } } = {
    Google: { title: "גוגל", icon: google },
    FaceBook: { title: "פייסבוק", icon: facebook },
    eCommerce: { title: "חנות מסחר", icon: eCommerce },
    LandingPagesSystem: { title: "דף נחיתה", icon: landingPage },
  };
  const _defaultStaticRanges: defaultStaticRangesOpt = "החודש";
  const defaultStaticSelectedRanges = defaultStaticRanges.find(
    (range) => range.label === _defaultStaticRanges
  );
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(() => {
    return defaultStaticSelectedRanges!.startDate;
  });
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(() => {
    return defaultStaticSelectedRanges!.endDate;
  });
  const [dataSet, setDataSet] = useState<any>();
  const [chartData, setChartData] = useState<[] | undefined>();
  const subPoductId: any = useParams<{
    subPoductId: string;
  }>().subPoductId?.toString();

  const LandingPages_eComm_additionalData = {
    valuesViewPages: {
      label: "כמות דפים שנצפו",
      backgroundColor: "rgba(75,192,192,0.2)",
      borderColor: "rgba(75,192,192,1)",
    },
    valuesVisits: {
      label: "מבקרים",
      backgroundColor: "rgba(135,75,191,0.2)",
      borderColor: "rgba(135,75,191,1)",
    },
    valuesUniqueVisits: {
      label: "מבקרים יחודיים",
      backgroundColor: "rgba(190,75,180,0.2)",
      borderColor: "rgba(190,75,180,1)",
    },
  };

  useEffect(() => {
    const dateFilter: DateFilter = {
      FromYear: 2018, //2018,
      FromMonth: selectedStartDate.getMonth() + 1,
      FromDay: selectedStartDate.getDate(),
      ToYear: selectedEndDate.getFullYear(),
      ToMonth: selectedEndDate.getMonth() + 1,
      ToDay: selectedEndDate.getDate(),
    };

    if (props.productName === "FaceBook") {
      GetFacebookPagesSummery(dateFilter).then((res) => {
        setDataSet(res.data);
        setChartData(res.chart);
      });
    } else if (props.productName === "Google") {
      GetGooglePagesSummery(dateFilter).then((res) => {
        setDataSet(res.data);
        setChartData(res.chart);
      });
    } else if (props.productName === "eCommerce") {
      GetECommerceSummery(dateFilter).then((res) => {
        setDataSet(res.data);
        setChartData(res.chart);
      });
    } else if (props.productName === "LandingPagesSystem") {
      if (subPoductId != "") {
        GetLandingPageSummery(dateFilter, subPoductId).then((res) => {
          setDataSet(res.data);
          setChartData(res.chart);
        });
      }
    }
  }, [selectedStartDate, selectedEndDate]);

  return (
    <>
      {dataSet && (
        <IonGrid fixed={true} className="main-content">
          <IonRow>
            <IonCol size="12">
              {!isMobile ? (
                <CustomHeaddingItem
                  mainTitle={productNameHe[props.productName].title}
                  icon={productNameHe[props.productName].icon}
                  iconBackground="statistics-asset"
                  isButton={false}
                  class="product-info-title"
                ></CustomHeaddingItem>
              ) : (
                <IonItem
                  lines="none"
                  detail={false}
                  className="product-info-title"
                >
                  <IonLabel>{productNameHe[props.productName].title}</IonLabel>
                  <IonIcon
                    icon={productNameHe[props.productName].icon}
                    slot="start"
                  />
                </IonItem>
              )}
            </IonCol>
          </IonRow>
          {isMobile && <div className={"product-modal"} />}
          <IonRow>
            <IonCol>
              <CustomDateRangePicker
                maxDate={new Date()}
                onDateChange={(startDate: Date, endDate: Date) => {
                  setSelectedStartDate(startDate);
                  setSelectedEndDate(endDate);
                }}
                startDate={selectedStartDate}
                endDate={selectedEndDate}
                defaultStaticRanges={_defaultStaticRanges}
              ></CustomDateRangePicker>
              {chartData && (
                <div className="chart-container">
                  <LineChart
                    chartData={chartData!}
                    isShowLegend={
                      props.productName === "LandingPagesSystem" ||
                      props.productName === "eCommerce"
                    }
                    chartAdditionalData={
                      props.productName === "LandingPagesSystem" ||
                      props.productName === "eCommerce"
                        ? LandingPages_eComm_additionalData
                        : undefined
                    }
                    chartHeight={!isMobile ? 50 : 110}
                  ></LineChart>
                </div>
              )}
            </IonCol>
          </IonRow>

          {props.productName == "FaceBook" && (
            <>
              <IonRow>
                <IonCol size="12" sizeMd="4" className="ion-margin-top">
                  <IonItem lines="none">
                    <CustomTrend
                      value={dataSet.TotalPageActionsPostReactionsLikeTotal}
                      PrevValue={0}
                      header="לייקים"
                      subHeader="כמות הלייקים"
                      isShowTrend={false}
                      isHideIcon={true}
                    ></CustomTrend>
                  </IonItem>
                </IonCol>
                <IonCol size="12" sizeMd="4" className="ion-margin-top">
                  <IonItem lines="none">
                    <CustomTrend
                      value={dataSet.TotalPageEngagedUsers}
                      PrevValue={0}
                      isShowTrend={false}
                      isHideIcon={true}
                      header="משתמשים"
                      subHeader="כמות המשתמשים"
                    ></CustomTrend>
                  </IonItem>
                </IonCol>
                <IonCol size="12" sizeMd="4" className="ion-margin-top">
                  <IonItem lines="none">
                    <CustomTrend
                      value={dataSet.TotalPageImpressions}
                      PrevValue={0}
                      isShowTrend={false}
                      isHideIcon={true}
                      header="צפיות"
                      subHeader="כמות הצפיות"
                    ></CustomTrend>
                  </IonItem>
                </IonCol>
                <IonCol size="12" sizeMd="4" className="ion-margin-top">
                  <IonItem lines="none">
                    <CustomTrend
                      value={dataSet.TotalPagePostEngagements}
                      isShowTrend={false}
                      isHideIcon={true}
                      PrevValue={0}
                      header="תגובות"
                      subHeader="כמות התגובות"
                    ></CustomTrend>
                  </IonItem>
                </IonCol>
                <IonCol size="12" sizeMd="4" className="ion-margin-top">
                  <IonItem lines="none">
                    <CustomTrend
                      value={dataSet.TotalPagePostsImpressions}
                      isShowTrend={false}
                      isHideIcon={true}
                      PrevValue={0}
                      header="חשיפות של הפוסטים"
                      subHeader="כמות חשיפות של הפוסטים"
                    ></CustomTrend>
                  </IonItem>
                </IonCol>
                <IonCol size="12" sizeMd="4" className="ion-margin-top">
                  <IonItem lines="none">
                    <CustomTrend
                      isShowTrend={false}
                      isHideIcon={true}
                      value={dataSet.TotalPageTotalActions}
                      PrevValue={0}
                      header="סהכ פעולות"
                      subHeader="סהכ פעולות"
                    ></CustomTrend>
                  </IonItem>
                </IonCol>
                <IonCol size="12" sizeMd="4" className="ion-margin-top">
                  <IonItem lines="none">
                    <CustomTrend
                      isShowTrend={false}
                      isHideIcon={true}
                      value={dataSet.TotalPageViewsTotal}
                      PrevValue={0}
                      header="סהכ צפיות בפרופיל"
                      subHeader="סהכ צפיות בפרופיל"
                    ></CustomTrend>
                  </IonItem>
                </IonCol>
              </IonRow>
            </>
          )}
          {props.productName == "Google" && (
            <>
              <IonRow>
                <IonCol size="12" sizeMd="4" className="ion-margin-top">
                  <IonItem lines="none">
                    <CustomTrend
                      value={dataSet.AllActivities}
                      PrevValue={0}
                      header="כל הפעילויות"
                      subHeader="כמות כל הפעילויות"
                      isShowTrend={false}
                      isHideIcon={true}
                    ></CustomTrend>
                  </IonItem>
                </IonCol>
                <IonCol size="12" sizeMd="4" className="ion-margin-top">
                  <IonItem lines="none">
                    <CustomTrend
                      value={dataSet.QueriesDirect}
                      PrevValue={0}
                      isShowTrend={false}
                      isHideIcon={true}
                      header="חיפוש שמי"
                      subHeader="כמות חיפוש שמי"
                    ></CustomTrend>
                  </IonItem>
                </IonCol>
                <IonCol size="12" sizeMd="4" className="ion-margin-top">
                  <IonItem lines="none">
                    <CustomTrend
                      value={dataSet.QueriesIndirect}
                      PrevValue={0}
                      isShowTrend={false}
                      isHideIcon={true}
                      header="חיפוש קטגוריה"
                      subHeader="כמות חיפוש קטגוריה"
                    ></CustomTrend>
                  </IonItem>
                </IonCol>
                <IonCol size="12" sizeMd="4" className="ion-margin-top">
                  <IonItem lines="none">
                    <CustomTrend
                      value={dataSet.QueriesChain}
                      isShowTrend={false}
                      isHideIcon={true}
                      PrevValue={0}
                      header="צפיות קטגוריה"
                      subHeader="כמות צפיות קטגוריה"
                    ></CustomTrend>
                  </IonItem>
                </IonCol>
                <IonCol size="12" sizeMd="4" className="ion-margin-top">
                  <IonItem lines="none">
                    <CustomTrend
                      value={dataSet.ViewsMaps}
                      isShowTrend={false}
                      isHideIcon={true}
                      PrevValue={0}
                      header="צפיות מפה"
                      subHeader="כמות צפיות מפה"
                    ></CustomTrend>
                  </IonItem>
                </IonCol>
                <IonCol size="12" sizeMd="4" className="ion-margin-top">
                  <IonItem lines="none">
                    <CustomTrend
                      isShowTrend={false}
                      isHideIcon={true}
                      value={dataSet.ViewsSearch}
                      PrevValue={0}
                      header="צפיות בחיפוש"
                      subHeader="סהכ צפיות בחיפוש"
                    ></CustomTrend>
                  </IonItem>
                </IonCol>
                <IonCol size="12" sizeMd="4" className="ion-margin-top">
                  <IonItem lines="none">
                    <CustomTrend
                      isShowTrend={false}
                      isHideIcon={true}
                      value={dataSet.ActionsWebsite}
                      PrevValue={0}
                      header="הקלקות כתובות"
                      subHeader="סהכ הקלקות כתובות"
                    ></CustomTrend>
                  </IonItem>
                </IonCol>
                <IonCol size="12" sizeMd="4" className="ion-margin-top">
                  <IonItem lines="none">
                    <CustomTrend
                      isShowTrend={false}
                      isHideIcon={true}
                      value={dataSet.ActionsPhone}
                      PrevValue={0}
                      header="הקלקות טלפון"
                      subHeader="סהכ הקלקות טלפון"
                    ></CustomTrend>
                  </IonItem>
                </IonCol>
                <IonCol size="12" sizeMd="4" className="ion-margin-top">
                  <IonItem lines="none">
                    <CustomTrend
                      isShowTrend={false}
                      isHideIcon={true}
                      value={dataSet.ActionsDrivingDirections}
                      PrevValue={0}
                      header="סהכ הוראות נסיעה "
                      subHeader="סהכ הוראות נסיעה"
                    ></CustomTrend>
                  </IonItem>
                </IonCol>
              </IonRow>
            </>
          )}
          {props.productName == "LandingPagesSystem" && (
            <>
              <IonRow>
                <IonCol size="12" sizeMd="4" className="ion-margin-top">
                  <IonItem lines="none">
                    <CustomTrend
                      value={dataSet.AveragePerVisit}
                      PrevValue={0}
                      header="ממוצע דפים לביקור"
                      subHeader="כמות ממוצעת של דפים לביקור של גולש אצלך בנכס"
                      isShowTrend={false}
                      isHideIcon={true}
                      valColSize="4"
                    ></CustomTrend>
                  </IonItem>
                </IonCol>
                <IonCol size="12" sizeMd="4" className="ion-margin-top">
                  <IonItem lines="none">
                    <CustomTrend
                      value={dataSet.TotalViewedPages}
                      PrevValue={0}
                      isShowTrend={false}
                      isHideIcon={true}
                      header="דפים נצפים"
                      subHeader="מספר הדפים הנצפים בנכסים שלך (ביקור אחד יכול לייצר מספר דפים נצפים)"
                      valColSize="4"
                    ></CustomTrend>
                  </IonItem>
                </IonCol>
                <IonCol size="12" sizeMd="4" className="ion-margin-top">
                  <IonItem lines="none">
                    <CustomTrend
                      value={dataSet.UniqueVisitCount}
                      PrevValue={0}
                      isShowTrend={false}
                      isHideIcon={true}
                      header="מבקרים ייחודיים"
                      subHeader="כמות הגולשים שהגיעו לנכסים שלך (ביקור פר גולש אחד)"
                      valColSize="4"
                    ></CustomTrend>
                  </IonItem>
                </IonCol>
                <IonCol size="12" sizeMd="4" className="ion-margin-top">
                  <IonItem lines="none">
                    <CustomTrend
                      value={dataSet.Visits}
                      isShowTrend={false}
                      isHideIcon={true}
                      PrevValue={0}
                      header="ביקורים"
                      subHeader="כמות הפעמים שגולשים הגיעו לנכסים שלך (ייתכן כי גולש אחד ייצר יותר מביקור אחד)"
                      valColSize="4"
                    ></CustomTrend>
                  </IonItem>
                </IonCol>
              </IonRow>
            </>
          )}
          {props.productName == "eCommerce" && (
            <>
              <IonRow>
                <IonCol
                  size="12"
                  sizeMd="4"
                  className="ion-margin-top col-container"
                >
                  <IonItem lines="none">
                    <CustomTrend
                      value={dataSet.AveragePerVisit}
                      PrevValue={0}
                      header="ממוצע דפים לביקור"
                      subHeader="כמות ממוצעת של דפים לביקור של גולש אצלך בנכס"
                      isShowTrend={false}
                      isHideIcon={true}
                      valColSize="4"
                    ></CustomTrend>
                  </IonItem>
                </IonCol>
                <IonCol
                  size="12"
                  sizeMd="4"
                  className="ion-margin-top col-container"
                >
                  <IonItem lines="none">
                    <CustomTrend
                      value={dataSet.TotalViewedPages}
                      PrevValue={0}
                      isShowTrend={false}
                      isHideIcon={true}
                      header="דפים נצפים"
                      subHeader="מספר הדפים הנצפים בנכסים שלך (ביקור אחד יכול לייצר מספר דפים נצפים)"
                      valColSize="4"
                    ></CustomTrend>
                  </IonItem>
                </IonCol>
                <IonCol
                  size="12"
                  sizeMd="4"
                  className="ion-margin-top col-container"
                >
                  <IonItem lines="none">
                    <CustomTrend
                      value={dataSet.UniqueVisitCount}
                      PrevValue={0}
                      isShowTrend={false}
                      isHideIcon={true}
                      header="מבקרים ייחודיים"
                      subHeader="כמות הגולשים שהגיעו לנכסים שלך (ביקור פר גולש אחד)"
                      valColSize="4"
                    ></CustomTrend>
                  </IonItem>
                </IonCol>
                <IonCol
                  size="12"
                  sizeMd="4"
                  className="ion-margin-top col-container"
                >
                  <IonItem lines="none">
                    <CustomTrend
                      value={dataSet.Visits}
                      isShowTrend={false}
                      isHideIcon={true}
                      PrevValue={0}
                      header="ביקורים"
                      subHeader="כמות הפעמים שגולשים הגיעו לנכסים שלך (ייתכן כי גולש אחד ייצר יותר מביקור אחד)"
                      valColSize="4"
                    ></CustomTrend>
                  </IonItem>
                </IonCol>
              </IonRow>
            </>
          )}
        </IonGrid>
      )}
    </>
  );
};
export default ProductInfoData;
