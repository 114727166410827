import React, { useState } from "react";

import CustomModal from "../CustomElements/CustomModal/CustomModal";
import { HeaderParameters } from "../../../data/Header-Context";
import { Photo } from "./ImagePicker";
import ImageEditor from "tui-image-editor";

import "tui-image-editor/dist/tui-image-editor.css";

import CustomPublishButton from "../CustomElements/CustomPublishButton";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { whiteTheme } from "./CustomImageJS/white-theme";
import { locale_he_IL } from "./CustomImageJS/locale_he_IL";

const CustomImageEditor: React.FC<{
  show: boolean;
  closeModal: () => void;
  onSave: (imgSrc: string) => void;
  photo: Photo;
}> = (props) => {
  const [imagEditoreObj, setimageEditor] = useState<ImageEditor>();
  const saveHandler = () => {
    const imgSrc = imagEditoreObj?.toDataURL();
    if (imagEditoreObj === undefined || imgSrc === undefined || imgSrc === "") {
      props.closeModal();
    } else {
      props.onSave(imgSrc!);
      props.closeModal();
    }
  };

  const ShowEditor = () => {
    var imageEditor = new ImageEditor("#tui-image-editor", {
      includeUI: {
        loadImage: {
          path: props.photo.preview,
          name: "SampleImage",
        },
        theme: whiteTheme, // or whiteTheme
        initMenu: "",
        menuBarPosition: "bottom",
        locale: locale_he_IL, //if not recognized add to node_modules\tui-image-editor\index.d.ts
        // to interface IIncludeUIOptions
        //locale?:any,
        uiSize: {
          width: window.innerWidth.toString(),
          height: "450px",
        },
        menu: ["crop", "flip", "rotate"], //['crop', 'flip', 'rotate', 'draw', 'shape', 'icon', 'text', 'mask', 'filter'].
      },
      cssMaxWidth: 500,
      cssMaxHeight: 300,
      selectionStyle: {
        cornerSize: 20,
        rotatingPointOffset: 50,
      },
      usageStatistics: false,
    });

    setimageEditor(imageEditor);
  };

  const headerParameters: HeaderParameters = {
    modalType: "modal",
  };
  return (
    <CustomModal
      onDidPresent={() => {
        const element = document.getElementById("tui-image-editor");
        if (props.photo && props.photo.preview != "" && element != null) {
          ShowEditor();
        }
      }}
      pageName={"CustomImageEditor"}
      headerParameters={headerParameters}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
    >
      <IonGrid>
        <IonRow>
          <IonCol className="ion-text-center">
            <div>
              <div id="tui-image-editor">
                <canvas></canvas>
              </div>
            </div>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="ion-text-center">
            <CustomPublishButton
            style={{marginTop:15}}
              onClick={() => {
                saveHandler();
              }}
              title="שמור"
            ></CustomPublishButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </CustomModal>
  );
};

export default CustomImageEditor;
