import React from "react";
import ReactDOM from "react-dom";
import { defineCustomElements } from "@ionic/pwa-elements/loader";

import App from "./App";
import { AuthContextProvider } from "./data/AuthContextProvider";
import ErrorBoundary from "./utils-ts/ErrorBoundary";
import * as serviceWorker from "./serviceWorker";
import GlobalContextProvider from "./data/GlobalContextProvider";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IsNavigatorOnLine } from "./utils-ts/OnLineStatus";
import CustomToast from "./components/utils/CustomElements/CustomToast";
import MemberDetailesProvider from "./data/MemberDetailesProvider";
import UserSettingsContextProvider from "./data/UserSettingsContextProvider";
import MemberInboxContextProvider from "./data/MemberInboxContextProvider";
import CommentsContextProvider from "./data/CommentsContextProvider";
import SelfServiceContextProvider from "./data/SelfServiceContextProvider";
import SelfSaleContextProvider from "./data/SelfSaleContextProvider";
import VasContextProvider from "./data/VasContextProvider";
import CoSaleContextProvider from "./data/CoSaleContextProvider";

var toastId: any = null;

const StatusIndicator = () => {
  const isOnline = IsNavigatorOnLine();
  if (!isOnline) {
    toastId = toast.error("לא נמצא חיבור לרשת", {
      autoClose: false,
      closeButton: false, // Remove the closeButton
    });
  } else if (toastId !== null) {
    toast.update(toastId, {
      render: "חיבור לרשת שוחזר",
      type: toast.TYPE.INFO,
      autoClose: 1000,
      closeButton: null,
    });
    toastId = null;

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  return <></>;
};

ReactDOM.render(
  <ErrorBoundary>
    <StatusIndicator />
    <CustomToast />
    <AuthContextProvider>
      <SelfServiceContextProvider>
        <SelfSaleContextProvider>
          <MemberInboxContextProvider>
            <UserSettingsContextProvider>
              <GlobalContextProvider>
                <MemberDetailesProvider>
                  <CommentsContextProvider>
                    <VasContextProvider>
                      <CoSaleContextProvider>
                      <App />
                      </CoSaleContextProvider>
                    </VasContextProvider>
                  </CommentsContextProvider>
                </MemberDetailesProvider>
              </GlobalContextProvider>
            </UserSettingsContextProvider>
          </MemberInboxContextProvider>
        </SelfSaleContextProvider>
      </SelfServiceContextProvider>
    </AuthContextProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);

defineCustomElements(window);
serviceWorker.register();
serviceWorker.registerServiceWorker();
