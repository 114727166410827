import React, { createRef, useContext, useEffect, useRef, useState } from 'react';
import { IonGrid, IonRow, IonCol, IonItem, IonList, IonBadge } from '@ionic/react';

import { convertTimeStampToDate } from '../../../utils-ts/GlobalDateFunctions';
import ECommerceOrder from './ECommerceOrder';

import CustomItemSliding from '../../utils/CustomElements/CustomItemSliding';

import CustomModal from '../../utils/CustomElements/CustomModal/CustomModal';

import { HeaderParameters } from '../../../data/Header-Context';
import ProductsContext, { OrderStatus, OrderStatusDesc } from '../../../data/Products-context';

const ECommerceOrders: React.FC<{
  show: boolean;
  closeModal: () => void;
}> = (props) => {
  const ProductsCtx = useContext(ProductsContext);

  useEffect(() => {
    if (ProductsCtx.MemberOrders == undefined) {
      ProductsCtx.initMemberOrdersContext(true);
    }
  }, []);

  let selectedOrderRef = useRef<HTMLIonItemSlidingElement | null>(null);

  const [startedEditing, setStartEditing] = useState(false);
  const selectedOrderIdRef = useRef<number | null>(null);

  const saveHandler = () => {
    const orderId = selectedOrderIdRef.current!;
    ProductsCtx.MemberOrders!.find((o) => o.Id == orderId)!.Status = OrderStatus.Ready; //sent
    ProductsCtx.updateOrderStatus(orderId, OrderStatus.Ready);
    //props.onSave();
  };

  const cancelOrder = () => {
    const orderId = selectedOrderIdRef.current!;
    ProductsCtx.MemberOrders!.find((o) => o.Id == orderId)!.Status = OrderStatus.Canceled; //canceled
    ProductsCtx.updateOrderStatus(orderId, OrderStatus.Canceled);
  };

  const headerParameters: HeaderParameters = {
    isPageHaveChanges: false,
    modalType: 'modal',
  };

  const getCurrnetOrderId = () => {
    if (selectedOrderIdRef.current) {
      return selectedOrderIdRef.current!;
    }
    return -1;
  };

  return (
    <CustomModal
      pageName={'ECommerceOrder'}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
      headerParameters={headerParameters}
      title="הזמנות"
      subTitle="בלחיצה על הזמנה ניתן לצפות בפרטי הזמנה מלאים ולעדכן סטטוס הזמנה">
      <IonGrid>
        {ProductsCtx.MemberOrders && ProductsCtx.MemberOrders.length > 0 && (
          <IonRow>
            <IonCol>
              <IonList>
                <IonItem style={{ fontWeight: 'bold' }}>
                  <IonCol size="1">#</IonCol>
                  <IonCol>תאריך</IonCol>
                  <IonCol>סכום</IonCol>
                  <IonCol size="3">סטטוס</IonCol>
                </IonItem>

                {ProductsCtx.MemberOrders.map((order, index) => {
                  const myRef = createRef<HTMLIonItemSlidingElement>();
                  return (
                    // <CustomItemSliding
                    //   onDelete={() => {
                    //     selectedOrderIdRef.current = order.Id;
                    //     selectedOrderRef.current = myRef.current;
                    //     cancelOrder();
                    //   }}
                    //   onEdit={() => {
                    //     setStartEditing(true);
                    //     selectedOrderIdRef.current = order.Id;
                    //     selectedOrderRef.current = myRef.current;
                    //   }}
                    //   key={index}
                    //   ref={myRef}
                    // >
                    <IonItem
                      style={{ cursor: 'pointer' }}
                      key={index}
                      onClick={() => {
                        selectedOrderIdRef.current = order.Id;
                        selectedOrderRef.current = myRef.current;
                        setStartEditing(true);
                      }}>
                      <IonCol size="1">{order.OrderNumber}</IonCol>
                      <IonCol>{convertTimeStampToDate(order.Date)}</IonCol>
                      <IonCol>{order.TotalPrice} ₪</IonCol>
                      <IonCol size="3">
                        {order.Status == OrderStatus.New ? <IonBadge color="danger"> {OrderStatusDesc[order.Status]}</IonBadge> : OrderStatusDesc[order.Status]}
                      </IonCol>
                    </IonItem>
                    // </CustomItemSliding>
                  );
                })}
              </IonList>
            </IonCol>
          </IonRow>
        )}
      </IonGrid>

      {startedEditing && (
        <ECommerceOrder
          show={startedEditing}
          closeModal={() => {
            setStartEditing(false);
            selectedOrderRef.current?.closeOpened();
          }}
          onSave={(newStatus) => {
            setStartEditing(false);
            selectedOrderRef.current?.closeOpened();
          }}
          orderId={getCurrnetOrderId()}
        />
      )}
    </CustomModal>
  );
};

export default ECommerceOrders;
