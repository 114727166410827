import React, { useContext, useEffect, useState } from 'react';
import { IonGrid, IonRow, IonCol, IonList, IonItem, IonLabel } from '@ionic/react';

import UserSettingsContext, {
  memberPreventNotification,
} from '../../data/UserSettings-Context';
import { useForm } from 'react-hook-form';

import CustomModal from '../utils/CustomElements/CustomModal/CustomModal';
import { HeaderParameters } from '../../data/Header-Context';

import { MultiCardsMember } from '../../data/MemberDetailes-context';
import UserContext from '../../data/Auth-context';

const MultiCardsSelector: React.FC<{
  show: boolean;
  members: MultiCardsMember[];
  closeModal: () => void;
  onSave: () => void;
}> = (props) => {
  
  const userCtx = React.useContext(UserContext);
  
  
const selectMember = (member: MultiCardsMember) => {
userCtx.loginMultiCardMember(member.Id).then((result:any)=> {
  if(result){
    window.location.reload(false);
  }
});
};
  const headerParameters: HeaderParameters = {
    
    modalType: 'modal',
  };
  return (
    <CustomModal
      pageName={'NotificationSettings'}
      title='כרטיסים נוספים'
      subTitle='רשימת כרטיסים'
      headerParameters={headerParameters}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
    >
       <IonList> 
        {props.members.filter((member: MultiCardsMember) => !member.Current).map((member: MultiCardsMember, index:number)=>
       {
       return <IonItem key={index}
       
       lines={(index + 1 < props.members.length ? 'full' : 'none')}
                                    button={true} type='button' onClick={()=> {selectMember(member)}}
                                    color={(member.Current ? 'light' : undefined)}  >
                                   
       
        <IonLabel><b>{member.Name}</b><br/><small>{member.City + ' ' + member.Street + ' ' + member.StreetNo}</small></IonLabel>
      </IonItem>
       })}
      </IonList>
    </CustomModal>
  );
};

export default MultiCardsSelector;
