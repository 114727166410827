import React, { useState, useCallback, useRef, useEffect } from "react";

import GlobalContext from "./Global-Context";
import { config } from "../Constants";
import { fetchRequest } from "../utils-ts/fetchRequest";
//import CustomLoader from "../components/utils/CustomElements/CustomLoader";
//import { Plugins } from "@capacitor/core";
import CustomGlobalLoader from "../components/utils/CustomElements/CustomGlobalLoader";
//const { Storage } = Plugins;

const parseString = require("xml2js").parseString;

const GlobalContextProvider: React.FC = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [requestCount, setRequestCount] = useState<number>(0);

  const [pageTip, setPageTip] = useState<string | undefined>();
  const [modalTip, setModalTip] = useState<string | undefined>();
  const getPageTip = useCallback(
    async (pageName: string, pageType: "modal" | "page") => {
      var data = new URLSearchParams({
        PageName: pageName,
      }).toString();

      fetchRequest(
        `${config.url.API_URL}/SystemUI.asmx/GetPagesTips`,
        "post",
        data
      )
        .then((response) => {
          parseString(response, (err: any, result: any) => {
            if (result) {
              var res = JSON.parse(result.string["_"]);
              if (res.Success) {
                const data = res.Response;
                if (pageType === "page") {
                  setPageTip(data);
                } else if (pageType === "modal") {
                  setModalTip(data);
                }
              }
            }
          });
        })
        .catch((error) => {});
    },
    []
  );

  let loaderHideDelay = useRef<NodeJS.Timeout>(); 
  let autoHideDelay = useRef<NodeJS.Timeout>(); 

  useEffect(
    () => {
      
      // this will clear Timeout
      // when component unmount like in willComponentUnmount
      // and show will not change to true
      return () => {
        //console.log('clear timeouts');
        loaderHideDelay.current && clearTimeout(loaderHideDelay.current);
        autoHideDelay.current && clearTimeout(autoHideDelay.current);
      };
    },
    // useEffect will run only one time with empty []
    // if you pass a value to array,
    // like this - [data]
    // than clearTimeout will run every time
    // this value changes (useEffect re-run)
    []
  );

  useEffect(
    () => {
      //console.log('requestCount ',requestCount);
//if we have request we will show loader
      //if no more requests,
      //we want to hide loader after a delay so it won't flick
      if(requestCount > 0){
        setLoading(true);
        loaderHideDelay.current && clearTimeout(loaderHideDelay.current);
      } else{
        //console.log('no request - set hide timeout');
        loaderHideDelay.current = setTimeout(()=>{
          setLoading(false);
        },config.loader_hide_delay);
      }

    },[requestCount]);
  const setLoader = (loading: boolean) => {
    
    if(loading){
      //we dont let the loader show more than max duration
      //we reset timer every show
      autoHideDelay.current && clearTimeout(autoHideDelay.current);
      autoHideDelay.current = setTimeout(()=>{
        console.log('loader autohide max_duration');
        setRequestCount(0);
      },config.loader_max_duration);
    }
    setRequestCount((currRequests) => {
      
      let updatedReq = currRequests != null && currRequests >= 0 ? currRequests : 0;
      if (loading) {
        updatedReq++;
      } else {
        updatedReq--;
      }
      if(updatedReq < 0){
        console.log('NEGATIVE requestCount ',updatedReq);
        updatedReq = 0;
      }
      return updatedReq;
    });

  };

  return (
    <GlobalContext.Provider
      value={{
        pageTip,
        modalTip,
        getPageTip,
        setLoader
      }}
    >
      {props.children}
      {loading && <CustomGlobalLoader></CustomGlobalLoader>}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
