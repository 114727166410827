import React, { useContext, useEffect, useRef, useState } from "react";
import { IonCard } from "@ionic/react";
import { isMobile } from 'react-device-detect';
import SelfSaleContext, { Keyword, RequestResult } from "../../../data/SelfSaleContext";
import CustomTextInput from "../CustomElements/CustomTextInput";

const Keywords: React.FC<{ adGroup: string, completed: boolean, onComplete?: (phaseId: number, valid: RequestResult) => void }> = (props) => {
  const SelfSaleCtx = useContext(SelfSaleContext);
  const [adgroupsKeywords, setAdgroupsKeywords] = useState<Keyword[]>([]);
  const [keywordsNotes, setKeywordsNotes] = useState<string>("");
  const [generalNotes, setGeneralNotes] = useState<string>("");
  useEffect(() => {
    if (SelfSaleCtx.memberAdgroups && SelfSaleCtx.memberAdgroups.length > 0) {
      fetchGoogleKeywords(SelfSaleCtx.memberAdgroups.filter(i => i.selected).map((i: any) => i.id).join(','))
    }
    else {
      SelfSaleCtx.getResllerSubCategories("")
        .then((res: any) => {
          fetchGoogleKeywords(res.filter((g: any) => g.selected).map((i: any) => i.id).join(','))
        })
    }
    SelfSaleCtx.getGoogleAdsNotes()
      .then((res: any) => {
        if (res && typeof (res) === 'string') {
          console.log(res)
          let notes = res.split("^^^")
          let generalNotesTxt = notes.length > 1 && notes[1] !== "" ? notes[1] : ""
          setGeneralNotes(generalNotesTxt)
          let txt = notes[0].replace("הערות מילות מפתח: ", "")
          setKeywordsNotes(txt)
        }
        else {
          setKeywordsNotes("")
        }
      })
  }, [])
  useEffect(() => {
    if (props.completed) {
      SelfSaleCtx.saveGoogleAdsNotes("הערות מילות מפתח: " + keywordsNotes + "^^^" + generalNotes).then((res: RequestResult) => {
        props.onComplete && props.onComplete(5, res)
      })
    }
  }, [props.completed])

  const fetchGoogleKeywords = (adgroupId: string) => {
    SelfSaleCtx.getAdgroupKeywords(adgroupId)
      .then((res: any) => {
        if (res && res.length > 0) {
          setAdgroupsKeywords(res)
        }
        else {
          setAdgroupsKeywords([])
        }
      })
  }
  const handleInputChange = (e: any) => {
    setKeywordsNotes(e.detail.value)
  }
  return (
    <IonCard mode="md" className={isMobile ? "self-sale-card self-sale-card-mobile" : "self-sale-card"}>
      <h2 className="ion-no-margin self-sale-section-title">איך ימצאו את העסק שלך בגוגל?</h2>
      <p className="ion-no-margin self-sale-section-text">הפרסום בגוגל עובד לפי מילות מפתח שהלקוחות מקלידים בשורת החיפוש באתר.
        <br /><b>אלו מילות המפתח שמתאימות לפרסום שלך:</b></p>
      {adgroupsKeywords && adgroupsKeywords.length > 0 && (
        <div className="google-keywords">
          {
            adgroupsKeywords.map((item, index) =>
              <div className="google-keyword" key={item.id}>
                <span>{item.text}</span>
                {index != adgroupsKeywords.length - 1 && (<span style={{ margin: '0 5px' }}>|</span>)}
              </div>)
          }
        </div>
      )}
      <div className="reseller-notes">
        <p className="ion-no-margin self-sale-section-subtitle">רוצה למסור משהו לצוות מומחי הקידום שלנו?</p>
        <CustomTextInput rows={3} maxlength={100} placeholder="נשמח לעזור בכל נושא שקשור לפרסום העסק בגוגל" name="notes" value={keywordsNotes} onChange={handleInputChange} />
      </div>
    </IonCard>
  );
};

export default Keywords;