import React, { useRef } from "react";
import { IonActionSheet, IonButton, IonIcon, IonPopover } from "@ionic/react";
import { alert } from "../../../images/index";

const CustomAreYouSureAlert: React.FC<{
  isOpen: boolean;
  message: string;
  cancelFunc: () => void;
  continueFunc: () => void;
  cancelButton?: string;
  continueButton?: string;
}> = (props) => {
  const popUpRef = useRef<HTMLIonPopoverElement>(null);
  const backBtnRef = useRef<HTMLIonButtonElement>(null);
  
  return (
    <IonPopover 
      cssClass="bottom-page-popover"
      backdropDismiss={false} 
      ref={popUpRef} 
      onDidPresent = {() => {
        loopAllAnimations();
      }}
      isOpen={props.isOpen}
    >
      <div>
        <IonIcon size="large" icon={alert} />
        <p
          className="md-title"
          dangerouslySetInnerHTML={{ __html: props.message }}
        ></p>
        <IonButton
          color="light"
          ref={backBtnRef}
          fill="clear"
          onClick={() => {
            props.cancelFunc();
          }}
        >
          {props.cancelButton ?? "חזור"}
        </IonButton>
        <IonButton color="dark" onClick={() => props.continueFunc()}>
          {props.continueButton ?? "המשך ללא שמירה"}
        </IonButton>
      </div>
    </IonPopover>

    // <IonActionSheet
    //   header={props.message}
    //   isOpen={props.isOpen}
    //   buttons={[
    //     {
    //       text: "חזור",
    //       handler: () => {
    //         props.cancelFunc();
    //       },
    //     },
    //     {
    //       text: "המשך ללא שמירה",
    //       handler: () => {
    //         props.continueFunc();
    //       },
    //     },
    //   ]}
    // ></IonActionSheet>
  );
};
export default CustomAreYouSureAlert;
