import React from "react";
import { IonButton, IonIcon, IonItem, IonLabel } from "@ionic/react";
import { googleLogo } from "../../../images";
import { chevronBackOutline, triangle } from "ionicons/icons";


const GoogleAdsOffer: React.FC<{
  isSelfReg: boolean;
}> = (props) => {


  return (
    <>
      <IonItem lines="none" className="section-item">
        <IonLabel>
          <div className="section-top">
            <div className="section-header">
              <img className="google-logo" src={googleLogo} style={{maxWidth:65,marginLeft:5}} />
              <div className='text-center'>
                <h2 className="bold-text">קידום העסק שלך בגוגל</h2>
                <p className="large-p">עם המומחים של B144</p>
              </div>
            </div>
            <ul className="section-list check-icon" style={{marginBottom: '16px',    whiteSpace: 'normal'}}>
              <li>הקמה וניהול קמפיין על ידי מומחים לפרסום עסקים קטנים בתקציבים נמוכים</li>
              <li>מחקר מילות מפתח ייעודי לעסק שלך על ידי מומחה</li>
              <li>דף נחיתה מעוצב ומותאם אישית</li>
              <li>פרסום וקידום העסק שלך במקומות הראשונים באתר ובאפליקציית B144</li>
              <li>דף עסק דיגיטלי מעוצב, עם כל המידע החשוב על העסק שלך, גלריית תמונות, קופונים, מוצרים, מחירונים ועוד…</li>
              <li>אפליקציה לעדכון עצמאי של דף העסק ולמעקב אחרי ביצועי הפרסום</li>
            </ul>
          </div>
          {props.isSelfReg && <IonButton fill="clear"
            routerLink={"/SelfSale/2"} style={{ color: '#4878fd', textDecoration: "underline", display: 'block' }}
          >לפרטים נוספים{">"}</IonButton>}
          <IonButton
            routerLink={props.isSelfReg ? "/SelfSale/2" : undefined}
            href={!props.isSelfReg ? "https://www.b144biz.co.il/digital-marketing/google-advertising/google-ads/" : undefined}
            target={!props.isSelfReg ? "_blank" : undefined}
            className="main-button">
            {props.isSelfReg ? 'לפרסום וקידום העסק שלך' : 'לפרטים נוספים'}
          </IonButton>
        </IonLabel>
      </IonItem>
    </>
  );
};

export default GoogleAdsOffer;