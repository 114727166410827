import { Plugins } from "@capacitor/core";
import { crashSendLog } from "./CrashSendLog";
import { GetIsNavigatorOnLine } from "./OnLineStatus";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { Storage } = Plugins;

export const fetchRequest = async (
  url: string,
  method: string,
  data: string
) => {
  const userData = await Storage.get({ key: "userData" });
  const Token = userData.value ? JSON.parse(userData.value).Token : "";
  if (!GetIsNavigatorOnLine()) {
    return null;
  }

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: method || "GET",
      body: data,
      headers: {
        Accept: "application/json",
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        "Auth-Token": Token,
      },
    })
      .then((response) => {
        if (response.status === 503) {
          if(window.location.pathname.toLowerCase().indexOf('maintenance') < 0) {
              window.location.href = "/Maintenance";
        }
          return;
        }

        if (response.ok) {
          resolve(response.text());
        } else {
          crashSendLog(
            "fetchRequest Error ",
            response.statusText,
            "url : " + url + " || data : " + data
          );
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        crashSendLog(
          "fetchRequest catch Error ",
          error,
          "url : " + url + " || data : " + data
        );
        reject(error);
      });
  });
};

export const fetchRequestNode = async (
  url: string,
  method: string,
  data: string
) => {
  const userData = await Storage.get({ key: "userData" });
  const TokenNode = userData.value ? JSON.parse(userData.value).TokenNode : "";
  if (!GetIsNavigatorOnLine()) {
    return null;
  }

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: method || "GET",
      body: data,
      headers: {
        Accept: "application/json",
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        authorization: TokenNode,
      },
    })
      .then((response) => {
        if (response.ok) {
          resolve(response.text());
        } else {
          crashSendLog(
            "fetchRequest Error ",
            response.statusText,
            "url : " + url + " || data : " + data
          );
          if (response.status === 401) {
            Storage.clear();
            window.location.href = "/Home";
          }
          //handle errors in the way you want to
          /* switch (response.status) {
              case 404:
                console.log('Object not found');
                break;
              case 500:
                console.log('Internal server error');
                break;
              default:
                console.log('Some error occured');
                break;
            } */

          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        crashSendLog(
          "fetchRequest catch Error ",
          error,
          "url : " + url + " || data : " + data
        );
        reject(error);
      });
  });
};
