import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import CustomHeaddingItem from "../utils/CustomElements/CustomPageTitle/CustomHeaddingItem";
import { eCommerce, facebook, google, landingPage } from "../../images/index";
import UserContext from "../../data/Auth-context";
import ProductInfoModal from "./ProductInfoModal";
import { initMemberActiveSystems } from "../../data/GlobalService";
import { GetLandingPages, landingPageObj } from "../../data/statisticServices";
import LandingPageListModal from "./LandingPageListModal";
import { isMobile } from "react-device-detect";
const ProductListData: React.FC<{}> = (props) => {
  const { authValues } = useContext(UserContext);
  const [iShowProduct, setShowProduct] = useState(false);
  const [selectedProd, setSelectedProd] = useState("");
  const [memberActiveSystems, setMemberActiveSystems] = useState<
    number[] | undefined
  >();
  const [isShowLandingPageList, setShowLandingPageList] = useState(false);
  const [landingPageListData, setLandingPageListData] = useState<
    landingPageObj[]
  >([]);
  useEffect(() => {
    initMemberActiveSystems().then((data) => {
      setMemberActiveSystems(data);
    });
  }, []);

  const productList = [
    {
      id: 8,
      title: "גוגל",
      subTitle: "קבל מידע על דפי הגוגל שלך",
      icon: google,
      name: "Google",
    },
    {
      id: 999,
      title: "דף פייסבוק",
      subTitle: "קבל מידע על דפי הפייסבוק שלך",
      icon: facebook,
      name: "FaceBook",
    },
    {
      id: 10,
      title: "חנות מסחר",
      subTitle: "קבל מידע על אתר העסק שלך",
      icon: eCommerce,
      name: "eCommerce",
    },
    {
      id: 55,
      title: "דף נחיתה",
      subTitle: "קבל מידע על אתר העסק שלך",
      icon: landingPage,
      name: "LandingPagesSystem",
    },
  ];
  return (
    <>
      <IonGrid fixed={true} className="main-content">
        {/* main buttons section*/}
        <IonRow className="ion-margin-top">
          {authValues &&
            authValues.IsSelfRegister === false &&
            memberActiveSystems &&
            productList.map((p) => {
              const isIdExist = memberActiveSystems!.some((f) => {
                return f === p.id;
              });
              if (isIdExist) {
                return (
                  <IonCol key={p.id} size="12" size-lg="4">
                    <CustomHeaddingItem
                      mainTitle={p.title}
                      subTitle={p.subTitle}
                      icon={p.icon}
                      isButton={true}
                      iconBackground="statistics-asset"
                      onClick={() => {
                        if (p.name === "LandingPagesSystem") {
                          GetLandingPages().then((res) => {
                            if (res) {
                              if (res.length > 1) {
                                setShowLandingPageList(true);
                                setLandingPageListData(res);
                              } else {
                                if (isMobile) {
                                  setShowProduct(true);
                                  setSelectedProd(p.name);
                                } else {
                                  window.location.href = `/ProductInfo/${p.name}/${res[0].Id}`;
                                }
                              }
                            }
                          });
                        } else {
                          if (isMobile) {
                            setShowProduct(true);
                            setSelectedProd(p.name);
                          } else {
                            window.location.href = `/ProductInfo/${p.name}`;
                          }
                        }
                      }}
                    ></CustomHeaddingItem>
                  </IonCol>
                );
              }
            })}
        </IonRow>
      </IonGrid>
      {iShowProduct && (
        <ProductInfoModal
          show={iShowProduct}
          closeModal={() => {
            setShowProduct(false);
          }}
          selectedProduct={selectedProd}
        ></ProductInfoModal>
      )}
      {isShowLandingPageList && landingPageListData.length > 0 && (
        <LandingPageListModal
          closeModal={() => {
            setShowLandingPageList(false);
          }}
          chooseLandingPage={(Id: string) => {
            setShowLandingPageList(false);
            if (isMobile) {
              setShowProduct(true);
              setSelectedProd("LandingPagesSystem");
            } else {
              window.location.href = `/ProductInfo/LandingPagesSystem/${Id}`;
            }
          }}
          show={isShowLandingPageList}
          landingPageListData={landingPageListData}
        ></LandingPageListModal>
      )}
    </>
  );
};
export default ProductListData;
