import React, { useContext, useEffect, useRef } from 'react';
import { IonGrid, IonRow, IonCol, IonIcon } from '@ionic/react';

import CustomInput from '../../utils/CustomElements/CustomInput';
import { useForm } from 'react-hook-form';
import MemberDetailsContext from '../../../data/MemberDetailes-context';
import CustomForm from '../../utils/CustomElements/CustomForm';
import CustomPublishButton from '../../utils/CustomElements/CustomPublishButton';
import CustomModal from '../../utils/CustomElements/CustomModal/CustomModal';
import { HeaderParameters } from '../../../data/Header-Context';
import { facebook, instegram, wolt, tiktok } from '../../../images/index';

const BusinessSocialNetworks: React.FC<{
  show: boolean;
  closeModal: () => void;
  onSave: () => void;
}> = (props) => {
  const { handleSubmit, formState, errors, control } = useForm({
    mode: 'onChange',
  });
  const { dirty } = formState;

  const MemberDetailsCxt = useContext(MemberDetailsContext);
  useEffect(() => {
    if (MemberDetailsCxt.GeneralInfo == null) {
      MemberDetailsCxt.initMemberDetailsContext();
    }
  }, []);
  const currentGeneralInfo = MemberDetailsCxt.GeneralInfo;
  
  const InstagramTextRef = useRef<HTMLIonInputElement>(null);
  const FacebookTextRef = useRef<HTMLIonInputElement>(null);
  const TiktokTextRef = useRef<HTMLIonInputElement>(null);
  const WoltTextRef = useRef<HTMLIonInputElement>(null);


  const saveHandler = () => {
    let newGeneralInfo = { ...currentGeneralInfo! };
    newGeneralInfo.Instagram =  InstagramTextRef.current!.value!.toString();
    newGeneralInfo.Facebook =  FacebookTextRef.current!.value!.toString();
    newGeneralInfo.TikTok = TiktokTextRef.current!.value!.toString();
    newGeneralInfo.Wolt = WoltTextRef.current!.value!.toString();

    MemberDetailsCxt.saveMainDetails(
      'GeneralInfo',
      MemberDetailsCxt.AdvCategories,
      MemberDetailsCxt.BusinessLanguages,
      //MemberDetailsCxt.OpenDays,
      //MemberDetailsCxt.DaysRemarks,
      newGeneralInfo,
      MemberDetailsCxt.Description
    );
    props.onSave();
    props.closeModal();
  };

  const headerParameters: HeaderParameters = {
    isPageHaveChanges: dirty,
    modalType: 'modal',
  };

  return (
    <CustomModal
      pageName={'BusinessSocialNetworks'}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
      headerParameters={headerParameters}
      title='רשתות חברתיות'
    >
      <CustomForm onSubmit={handleSubmit(saveHandler)} errors={errors}>
        <IonGrid className='ion-margin-top'>
          <IonRow className='ion-align-items-start'>
            <IonCol size='2' className='ion-text-center ion-padding-top'>
              <IonIcon size='large' icon={facebook}></IonIcon>
            </IonCol>
            <IonCol>
              {currentGeneralInfo && (
                <CustomInput
                  name='facebook'
                  label='פייסבוק'
                  errors={errors}
                  control={control}
                  type='url'
                  maxlength={4000}
                  value={currentGeneralInfo.Facebook}
                  patternType='facebook'
                  ref={FacebookTextRef}
                />
              )}
            </IonCol>
          </IonRow>
          <IonRow className='ion-align-items-start'>
            <IonCol size='2' className='ion-text-center ion-padding-top'>
              <IonIcon size='large' icon={instegram}></IonIcon>
            </IonCol>
            <IonCol>
              {currentGeneralInfo && (
                <CustomInput
                  name='instagram'
                  label='אינסטגרם'
                  errors={errors}
                  control={control}
                  type='url'
                  maxlength={4000}
                  value={currentGeneralInfo.Instagram}
                  ref={InstagramTextRef}
                />
              )}
            </IonCol>
          </IonRow>
          <IonRow className='ion-align-items-start'>
            <IonCol size='2' className='ion-text-center ion-padding-top'>
              <IonIcon size='large' icon={tiktok}></IonIcon>
            </IonCol>
            <IonCol>
              {currentGeneralInfo && (
                <CustomInput
                  name='tiktok'
                  label='טיקטוק'
                  errors={errors}
                  control={control}
                  type='url'
                  maxlength={4000}
                  value={currentGeneralInfo.TikTok}
                  ref={TiktokTextRef}
                />
              )}
            </IonCol>
          </IonRow>
          <IonRow className='ion-align-items-start'>
            <IonCol size='2' className='ion-text-center ion-padding-top'>
              <IonIcon size='large' icon={wolt}></IonIcon>
            </IonCol>
            <IonCol>
              {currentGeneralInfo && (
                <CustomInput
                  name='wolt'
                  label='וולט'
                  errors={errors}
                  control={control}
                  type='url'
                  maxlength={4000}
                  value={currentGeneralInfo.Wolt}
                  ref={WoltTextRef}
                />
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
        <CustomPublishButton />
      </CustomForm>
    </CustomModal>
  );
};

export default BusinessSocialNetworks;
