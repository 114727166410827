import React, { FC, ComponentProps } from "react";
import { addOutline } from "ionicons/icons";
import { IonButton, IonIcon } from "@ionic/react";

type IonButtonProps = ComponentProps<typeof IonButton>;

interface ButtonProps extends IonButtonProps {
  titleTxt?: string;
  BtnTxt: string;
  position?: string;
}

const CustomTitleButton: FC<ButtonProps> = ({position, titleTxt, BtnTxt, ...props }) => {
  return (
    <div className={"custom-title-button " + (position? position: "")}>
    {/* {titleTxt && <h3 className="small-title bold-text">{titleTxt}</h3>} */}
      <IonButton {...props} >
        <span className="text-wrap">
          {BtnTxt}
        </span>

        <IonIcon slot="start" size="big" icon={addOutline} />
      </IonButton>
    </div>
  );
};

export default CustomTitleButton;
