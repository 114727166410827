import React, { useEffect } from "react";

import { HeaderParameters } from "../data/Header-Context";
import CustomPage from "../components/utils/CustomElements/CustomPage";
import { b144Logo_503 } from "../images";
import { checkIsSiteMaintenance } from "../data/GlobalService";

const headerParameters: HeaderParameters = {
  isShowLogo: false,
  backButton: { isShowMobile: true },
  isShowNotification: true,
  isShowHamburger: false,
   isLogin: true, 
};

const MaintenancePage: React.FC = () => {

useEffect(()=>{

  checkIsSiteMaintenance().then((
    response:any
  )=>{
    if(response){
      //check again in X minutes??
    } else{
      window.location.href = '/Home';
    }
  });

},[]);

  return (
    <CustomPage  title="" pageName=""
    headerParameters={headerParameters}>
      <div className="error_page">
        <div>
            <img src={b144Logo_503} />
        </div>
        <div className="error_msg_long">
            <b>האתר בתחזוקה זמנית</b>
            <br/>
            נחזור לפעילות בהקדם!
        </div>
        
    </div>
    </CustomPage>
  );
};
export default MaintenancePage;
