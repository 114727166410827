import { IonImg } from "@ionic/react";
import React, { useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Pagination, Navigation } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import { reasonsAppIcon, reasonsBudgetIcon, reasonsExposureIcon, reasonsKnowledgeIcon } from "../../../images";
const SelfSaleWhyChoose: React.FC = (props) => {
    const [showMore, setShowMore] = useState(false);
    const [shownQuestion, setShownQuestion] = useState<number>(1);
    const reasons = [
        {
            id: 1,
            name: 'חשיפה',
            desc: `קבלו חשיפה אדירה לעסק שלכם!\n
             יש לנו את מיטב המערכות ונסיון של שנים בצבירת מידע, ובכך אנו יודעים לזהות את הקהל הרלוונטי לעסק שלך. באמצעות חשיפה בדיגיטל העסק שלכם יכול להגיע למודעות של מאות אלפי גולשים ויותר.`,
            image: reasonsExposureIcon
        },
        {
            id: 2,
            name: 'תקציב',
            desc: 'בעזרת המומחים שלנו ובשילוב ידע קיים, נמקסם יחד את התקציב על מנת שיושקע בצורה האפקטיבית ביותר כדי להגיע לקהל הרלוונטי לעסק שלך. התקציב יעבוד עבורך בעזרת חבילת פרסום נכונה',
            image: reasonsBudgetIcon
        },
        {
            id: 3,
            name: 'ידע',
            desc: 'בזכות שילוב מנצח של מומחים בכל תחומי הדיגיטל, יחד עם מידע עסקי מצטבר ומערכות חכמות, יש לנו את הידע להתאים לעסק שלך את חבילת הפרסום הנכונה. אנחנו יודעים לנתח מה קורה בעסקים אחרים בתחום שלך, ולהכווין בדיוק מה יתאים לך בהתאם למידע בזמן אמת.',
            image: reasonsKnowledgeIcon
        },
        {
            id: 4,
            name: 'אפליקציה',
            desc: `כדי שהפרסום בדיגיטל יהיה לך נגיש וזורם.
פיתחנו עבורך אפליקציה, שתאפשר לך מעקב קל ותקשורת נוחה עם כל מה שקשור לנכסים הדיגיטליים שלך והעדכונים 24/7
• תוצאות קמפיינים באפליקציה
• ממשק לעדכון מידע שוטף של העסק
• פנייה אל מנהלי הקמפיין`,
            image: reasonsAppIcon
        },
    ]

    return (
        <div className="reasons-div">
            <h2 className="ion-no-margin self-sale-text-blue bold-text">למה לפרסם ב B144</h2>
            <Swiper
                modules={[Navigation, Pagination]}
                // spaceBetween={50}
                dir="rtl"
                slidesPerView={1}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }}
                pagination={{ clickable: true }}
            >
                {reasons.map(item => {
                    return (<SwiperSlide key={item.id}>
                        <div className="reason-slide">
                            <div style={{ height: '60px', margin: '20px' }}>
                                <IonImg style={{height: '100%'}} src={item.image}></IonImg>
                            </div>
                            <p className="bold-text reason-slide-name">{item.name}</p>
                            <p className="reason-slide-desc">{item.desc}</p>
                        </div>
                    </SwiperSlide>)
                })}
            </Swiper>

        </div>
    )
}
export default SelfSaleWhyChoose;