import React, { useContext, useEffect, useState } from 'react';
import { IonMenu, IonContent, IonList, IonMenuToggle, IonItem, IonIcon, IonLabel, IonHeader, IonButtons, IonToolbar, IonAvatar, IonButton, IonPopover, IonGrid, IonRow, IonCol } from '@ionic/react';
import { isMobile } from 'react-device-detect';
import { logOutOutline, closeOutline, cart, desktop, peopleCircleOutline, helpBuoy } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { useLocation } from 'react-router';

import { avatar, budget, vas, review, businessCard, statistics, home, inbox, settings, help, arrowBack } from '../../images/index';
import UserSettingsContext from '../../data/UserSettings-Context';
import UserContext from '../../data/AuthContextProvider';
import MemberDetailsContext from '../../data/MemberDetailes-context';
import ProfileSettings from '../UserSettings/ProfileSettings';
import '../../theme/avatar.scss';
import './hamburger.scss';
import { config } from '../../Constants';
import MultiCardsSelector from '../UserSettings/MultiCardsSelector';
import CustomModal from '../utils/CustomElements/CustomModal/CustomModal';
//import { generateExternalLinkWithToken, initMemberActiveSystems } from '../../data/GlobalService';

addIcons({
  cart: cart,
  desktop: desktop,
});
export interface externalLinks {
  icon: string;
  title: string;
  pageLink: string;
  id: number;
}

const Hamburger: React.FC<{ sideMenuIndexPos: (isOpen: boolean) => void }> = (props) => {
  const { logout, authValues } = useContext(UserContext);
  const MemberDetailsCxt = useContext(MemberDetailsContext);
  const UserSettingsCtx = useContext(UserSettingsContext);
  const location = useLocation();
  //const [memberActiveSystems, setMemberActiveSystems] = useState<number[] | undefined>();
  //const [externalLinkWithToken, setExternalLinkWithToken] = useState<string | undefined>();
  useEffect(() => {
    if (UserSettingsCtx.userProfileSettingsInfo === undefined) {
      UserSettingsCtx.initUserProfileSettingContext(false);
    }

    // initMemberActiveSystems().then((data) => {
    //   setMemberActiveSystems(data);
    // });
    // generateExternalLinkWithToken().then((data) => {
    //   setExternalLinkWithToken(data);
    // });
  }, []);

  // const externalLinks: externalLinks[] = [
  //   {
  //     icon: laptop,
  //     id: 13,
  //     title: 'בניית אתרים ',
  //     pageLink: externalLinkWithToken + '1001',
  //   },
  //   {
  //     icon: shopping,
  //     title: 'מערכת חנות און ליין',
  //     id: 10,
  //     pageLink: externalLinkWithToken + '8',
  //   },
  // ];

  const [isShowProfileSettings, setIsShowProfileSettings] = useState(false);
  const [showStatisticsSubMenu, setIsShowStatisticsSubMenu] = useState(false);
const [isShowMultiCardsSelector, setIsShowMultiCardsSelector] = useState(false);
const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  
  return (
    <>
      <IonMenu side={!isMobile ? 'start' : 'end'} contentId="hamburgerMain" 
      
      onIonDidOpen={()=> {
        loopAllAnimations();

      }}
      onIonWillOpen={() => props.sideMenuIndexPos(true)} 
      onIonDidClose={() => 
      {
        props.sideMenuIndexPos(false);
      }
      }>
        {isMobile && MemberDetailsCxt.GeneralInfo && UserSettingsCtx.userProfileSettingsInfo != undefined && (
          <IonHeader className="ion-no-border">
            <IonToolbar mode="ios" className={isMobile ? 'hamburger-toolbar' : ''}>
              <IonMenuToggle autoHide={false}>
                <IonItem
                  onClick={() => {
                    setIsShowProfileSettings(true);
                  }}
                  lines="none"
                  className="item-trns"
                  detail={false}
                  button
                  routerDirection="none">
                  <IonAvatar className={UserSettingsCtx.userProfileSettingsInfo?.ProfileImage.Url == '' ? 'no-image' : ''}>
                    <img src={UserSettingsCtx.userProfileSettingsInfo?.ProfileImage.Url ? UserSettingsCtx.userProfileSettingsInfo?.ProfileImage.Url : avatar} alt="" />
                  </IonAvatar>
                  <div className="ion-margin-horizontal">
                    <p className="small-title bold-text underline">{UserSettingsCtx.userProfileSettingsInfo?.FullName + (authValues && authValues.IsCm ? ' (CM)' : '')}</p>
                    <p>{MemberDetailsCxt.GeneralInfo.CompanyName}</p>
                  </div>
                </IonItem>
              </IonMenuToggle>
              <IonButtons slot="end">
                <IonMenuToggle autoHide={true}>
                  <IonIcon icon={closeOutline}></IonIcon>
                </IonMenuToggle>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
        )}

        {MemberDetailsCxt.GeneralInfo && UserSettingsCtx.userProfileSettingsInfo != undefined && (
          <IonContent>
            <IonList>
              {!isMobile && (
                <IonMenuToggle autoHide={false}>
                  <IonItem
                    onClick={() => {
                      setIsShowProfileSettings(true);
                      SetDtlPushEvt('menu',"פרופיל");
                    }}
                    lines="none"
                    className="side-menu-desktop-header"
                    detail={false}
                    button
                    routerDirection="none">
                    <IonAvatar>
                      <img src={UserSettingsCtx.userProfileSettingsInfo?.ProfileImage.Url ? UserSettingsCtx.userProfileSettingsInfo?.ProfileImage.Url : avatar} alt="" />
                    </IonAvatar>
                    <div className="ion-margin-horizontal">
                      <p className="small-title bold-text underline">{UserSettingsCtx.userProfileSettingsInfo?.FullName + (authValues && authValues.IsCm ? ' (CM)' : '')}</p>
                      <p>{MemberDetailsCxt.GeneralInfo.CompanyName}</p>
                    </div>
                  </IonItem>
                </IonMenuToggle>
              )}
              <IonMenuToggle autoHide={false}>
                <IonItem 
                onClick={() => {SetDtlPushEvt('menu',"עמוד הבית");}} 
                detail={false} button routerLink="/Home" routerDirection="none" lines="none" className={location.pathname == '/Home' ? 'active' : ''}>
                  <IonIcon slot="start" icon={home}></IonIcon>
                  <IonLabel>עמוד ראשי</IonLabel>
                </IonItem>
              </IonMenuToggle>
              <IonMenuToggle autoHide={false}>
                <IonItem 
                onClick={() => {SetDtlPushEvt('menu',"העמוד העיסקי שלי");}} 
                detail={false} button routerLink="/BusinessCard" routerDirection="none" lines="none" className={location.pathname == '/BusinessCard' ? 'active' : ''}>
                  <IonIcon slot="start" icon={businessCard}></IonIcon>
                  <IonLabel>העמוד העיסקי שלי</IonLabel>
                </IonItem>
              </IonMenuToggle>
              {authValues && authValues.IsSelfRegister === false && !config.otef_israel && (
                // <>
                <IonMenuToggle autoHide={false}>
                  <IonItem
                    detail={false}
                    button
                    routerLink="/StatisticsOpt"
                    routerDirection="none"
                    lines="none"
                    onClick={() => {
                      setIsShowStatisticsSubMenu(!showStatisticsSubMenu);
                      SetDtlPushEvt('menu',"סטטיסטיקות");
                    }}
                    className={location.pathname == '/StatisticsOpt' ? 'active' : ''}>
                    <IonIcon slot="start" icon={statistics}></IonIcon>
                    <IonLabel>סטטיסטיקות</IonLabel>
                  </IonItem>
                </IonMenuToggle>
                /* {showStatisticsSubMenu && (
                      <>
                        <IonMenuToggle autoHide={false}>
                          <IonItem
                            detail={false}
                            button
                            routerLink="/ProductListPage"
                            routerDirection="none"
                            lines="none"
                            className={
                              location.pathname == "/ProductListPage"
                                ? "active"
                                : ""
                            }
                          >
                            <IonLabel>הנכסים שלי</IonLabel>
                          </IonItem>
                        </IonMenuToggle>

                        <IonMenuToggle autoHide={false}>
                          <IonItem
                            detail={false}
                            button
                            routerLink="/Statistics"
                            routerDirection="none"
                            lines="none"
                            className={
                              location.pathname == "/Statistics" ? "active" : ""
                            }
                          >
                            <IonLabel>הקמפיינים שלי</IonLabel>
                          </IonItem>
                        </IonMenuToggle>
                        <IonMenuToggle autoHide={false}>
                          <IonItem
                            detail={false}
                            button
                            routerLink="/MemberLeads"
                            routerDirection="none"
                            lines="none"
                            className={
                              location.pathname == "/MemberLeads"
                                ? "active"
                                : ""
                            }
                          >
                            <IonLabel>לידים וטלפונים</IonLabel>
                          </IonItem>
                        </IonMenuToggle>
                      </>
                    )} */
                // </>
              )}
              <IonMenuToggle autoHide={false}>
                <IonItem 
                onClick={() => {SetDtlPushEvt('menu',"חוות דעת");}} 
                detail={false} button routerLink="/MemberComments" routerDirection="none" lines="none" className={location.pathname == '/MemberComments' ? 'active' : ''}>
                  <IonIcon slot="start" icon={review}></IonIcon>
                  <IonLabel>חוות דעת</IonLabel>
                </IonItem>
              </IonMenuToggle>
             {!config.otef_israel && <>
              <IonMenuToggle autoHide={false}>
                <IonItem 
                onClick={() => {SetDtlPushEvt('menu',"דואר נכנס");}} 
                 detail={false} button routerLink="/Inbox" routerDirection="none" lines="none" className={location.pathname == '/Inbox' ? 'active' : ''}>
                  <IonIcon slot="start" icon={inbox}></IonIcon>
                  <IonLabel>דואר נכנס</IonLabel>
                </IonItem>
              </IonMenuToggle>
              <IonMenuToggle autoHide={false}>
                <IonItem 
                onClick={() => {SetDtlPushEvt('menu',"מכתבים שלי");}} 
                 detail={false} button routerLink="/Letters" routerDirection="none" lines="none" className={location.pathname == '/Letters' ? 'active' : ''}>
                  <IonIcon slot="start" icon={inbox}></IonIcon>
                  <IonLabel>מכתבים שלי</IonLabel>
                </IonItem>
              </IonMenuToggle>
             </>} 
              {authValues && authValues.IsSelfRegister === false && !config.otef_israel && (
                <React.Fragment>
                  <IonMenuToggle autoHide={false}>
                    <IonItem 
                onClick={() => {SetDtlPushEvt('menu',"מוצרי פרסום נלווים");}} 
                  detail={false} button routerLink="/Vas" routerDirection="none" lines="none" className={location.pathname == '/Vas' ? 'active' : ''}>
                      <IonIcon slot="start" icon={vas}></IonIcon>
                      <IonLabel>מוצרי פרסום נלווים</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle autoHide={false}>
                    <IonItem 
                onClick={() => {SetDtlPushEvt('menu',"חבילת הפרסום");}} 
                  detail={false} button routerLink="/Budget" routerDirection="none" lines="none" className={location.pathname == '/Budget' ? 'active' : ''}>
                      <IonIcon slot="start" icon={budget}></IonIcon>
                      <IonLabel>חבילת הפרסום</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                </React.Fragment>
              )}
              <span className="menu-divider" />
              
              {MemberDetailsCxt.IsMemberMultiCard && 
              <IonMenuToggle autoHide={false}>
                <IonItem 
                onClick={() => 
                  {
                    SetDtlPushEvt('menu',"כרטיסים נוספים");
                    setIsShowMultiCardsSelector(true);
                  }
                } 
                  detail={false} button lines="none" >
                  <IonIcon slot="start" icon={peopleCircleOutline}></IonIcon>
                  <IonLabel>כרטיסים נוספים</IonLabel>
                </IonItem>
              </IonMenuToggle>}

              {!config.otef_israel &&<IonMenuToggle autoHide={false}>
                <IonItem 
                onClick={() => 
                  {
                    SetDtlPushEvt('menu',"תמיכה מרחוק");
                    showSessionCode(null,null,config.url.API_URL);
                  }
                } 
                  detail={false} button lines="none" >
                  <IonIcon slot="start" icon={helpBuoy}></IonIcon>
                  <IonLabel>תמיכה מרחוק</IonLabel>
                </IonItem>
              </IonMenuToggle>}
              <IonMenuToggle autoHide={false}>
                <IonItem 
                onClick={() => {SetDtlPushEvt('menu',"הגדרות");}} 
                  detail={false} button routerLink="/userSettings" routerDirection="none" lines="none" className={location.pathname == '/userSettings' ? 'active' : ''}>
                  <IonIcon slot="start" icon={settings}></IonIcon>
                  <IonLabel>הגדרות</IonLabel>
                </IonItem>
              </IonMenuToggle>
              <IonMenuToggle autoHide={false}>
                <IonItem 
                onClick={() => {SetDtlPushEvt('menu',"עזרה");}} 
                  detail={false} button routerLink="/Help" routerDirection="none" lines="none" className={location.pathname == '/Help' ? 'active' : ''}>
                  <IonIcon slot="start" icon={help}></IonIcon>
                  <IonLabel>עזרה</IonLabel>
                </IonItem>
              </IonMenuToggle>
             
              {/* {memberActiveSystems &&
                externalLinks.map((m, index) => {
                  const isIdExist = memberActiveSystems!.some((f) => {
                    return f === m.id;
                  });
                  if (isIdExist) {
                    return (
                      <IonMenuToggle autoHide={false} key={index}>
                        <IonRouterLink target="_blank" href={m.pageLink}>
                          <IonItem detail={false} button lines="none">
                            <IonIcon slot="start" icon={m.icon}></IonIcon>
                            <IonLabel>{m.title}</IonLabel>
                          </IonItem>
                        </IonRouterLink>
                      </IonMenuToggle>
                    );
                  }
                })} */}

              <IonMenuToggle autoHide={false}>
                <IonItem
                  detail={false}
                  button
                  onClick={() => {
                    setShowLogoutConfirmation(true);
                    // SetDtlPushEvt('menu',"התנתק");
                    // logout(() => {
                    //   props.sideMenuIndexPos(false);
                    // });
                  }}
                  lines="none"
                  className={location.pathname == '/' ? 'active' : ''}>
                  <IonIcon slot="start" icon={logOutOutline}></IonIcon>
                  <IonLabel>התנתק</IonLabel>
                </IonItem>
              </IonMenuToggle>
            </IonList>
          </IonContent>
        )}
      </IonMenu>
      {showLogoutConfirmation && 
       <CustomModal
       pageName={"LogoutConfirmation"}
       headerParameters={{
        modalType: "modal",
        isShowLogo: false,
      }}
       closeModal={() => {
        setShowLogoutConfirmation(false);
       }}
       cssClass="welcomeNewUser logout-confirm-popover"
       isOpen={showLogoutConfirmation}
       
     >
       <IonGrid>
         
         <IonRow className="actions-wrapper">
         <IonCol size="12">
         <p>בטוחים שרוצים להתנתק מהמערכת?</p>
         </IonCol>
           <IonCol size="12" className='ion-no-padding'>
           
             <IonButton
               className="button-pop"
               onClick={() => {
                setShowLogoutConfirmation(false);
               }}
             >
               <span>חזרה לאפליקציה</span>
               <IonIcon mode="ios" icon={arrowBack} />
             </IonButton>
           </IonCol>
           <IonCol size="12" className='ion-no-padding'>
             
           
         <IonButton fill='clear' class='button-link'  onClick={() => 
         {
           SetDtlPushEvt('menu',"התנתק");
                     logout(() => {
                       props.sideMenuIndexPos(false);
                     });
         }
         }>
           להתנתק
         </IonButton>
               
             
           </IonCol>
         </IonRow>
       </IonGrid>
     </CustomModal>
      
  }
      {isShowProfileSettings && (
        <ProfileSettings
          show={isShowProfileSettings}
          closeModal={() => {
            setIsShowProfileSettings(false);
          }}
          onSave={() => {}}></ProfileSettings>
      )}

      {MemberDetailsCxt.MultiCardsMembers && isShowMultiCardsSelector && 
      <MultiCardsSelector 
      show={isShowMultiCardsSelector} 
      members = {MemberDetailsCxt.MultiCardsMembers }
      closeModal={()=> {
        setIsShowMultiCardsSelector(false);
      }}
      onSave={() => {
        setIsShowMultiCardsSelector(false);
      }}
      />}
    </>
  );
};

export default Hamburger;
