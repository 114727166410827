import React from "react";

export interface Comment {
  Body: string;
  BusinessReplyText: string;
  CommentDate: string;
  FBId: string;
  Id: string;
  Nickname: string;
  ParentId: string;
  Rating: number;
}
export interface GmbComment {
  ClientNotification: boolean,
  ClientNotificationDate: string,
  ClientNotificationMessageId: number,
  Id: number,
  IsRead: boolean,
  Name: string,
  ReadDate: string,
  ReplyComment:string,
  ReplyUpdateTime: string,
  ReviewComment: string,
  ReviewCreateTime: string,
  ReviewId: string,
  ReviewUpdateTime:string,
  ReviewerDisplayName: string,
  ReviewerProfilePhotoUrl: string,
  StarRating: number
}

const CommentsContext = React.createContext<{
  comments: Comment[] | undefined;
  gmbComments:GmbComment[]|undefined
  saveGmbComment: (
    ReviewId: number, Comment: string
  ) => void;
  saveComment: (
    commentId: string,
    commentbusinessReply: string,
    rating: string
  ) => void;
  initCommentsContext: (useLoading: boolean) => void;
  initGmbCommentsContext: (useLoading: boolean) => void;
}>({
  comments: [],
  gmbComments:[],
  saveGmbComment: () => {},
  saveComment: () => {},
  initCommentsContext: () => {},
  initGmbCommentsContext: () => {},
});

export default CommentsContext;
