import { IonButton } from "@ionic/react";
import React, { FC, ComponentProps } from "react";
import { isMobile } from "react-device-detect";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
type ToastContainerProps = ComponentProps<typeof ToastContainer>;

interface ToastProps extends ToastContainerProps {}

const CloseButton = (closeFunc: { closeToast: () => {} }) => (
  <IonButton
    fill="clear"
    onClick={() => {
      if (closeFunc.closeToast != undefined) {
        closeFunc.closeToast();
      }
    }}
  >
    אישור
  </IonButton>
);

const CustomToast: FC<ToastProps> = ({ ...props }) => {
  return (
    <ToastContainer
      {...props}
      position={!isMobile ? "top-center" : "bottom-center"}
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick={true}
      rtl={true}
      draggable={false}
      pauseOnHover={true}
      closeButton={CloseButton}
    />
  );
};

export default CustomToast;
