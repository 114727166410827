import React, { useContext, useEffect, useRef, useState } from "react";
import { isMobile } from 'react-device-detect';
import { AdGroupAds } from './AdsPreviewSlider'
import { IonButton, IonIcon } from "@ionic/react";
import { pauseOutline, chevronForwardOutline, chevronBackOutline, playOutline } from 'ionicons/icons';
const AdsPreviewSlide: React.FC<{ adGroupAds: AdGroupAds }> = (props) => {
  const [adPath1, setAdPath1] = useState<string>("");
  const [adPath2, setAdPath2] = useState<string>("");
  const [adHeader1, setAdHeader1] = useState<string>("");
  const [adHeader2, setAdHeader2] = useState<string>("");
  const [adHeader3, setAdHeader3] = useState<string>("");
  const [adDescription, setAdDescription] = useState<string>("");
  const [isIntervalRunning, setIsIntervalRunning] = useState<boolean>(false);
  const headersIntervalRef = useRef<number | null>(null);
  const descriptionsIntervalRef = useRef<number | null>(null);
  const currentHeaderIndexRef = useRef<number>(0);
  const currentDescriptionIndexRef = useRef<number>(0);

  useEffect(() => {
    if (props.adGroupAds.adHeaders.length) {
      setAdHeader1(props.adGroupAds.adHeaders[0] || "")
      setAdHeader2(props.adGroupAds.adHeaders[1] || "")
      setAdHeader3(props.adGroupAds.adHeaders[2] || "")
      if (isIntervalRunning)
      headersInterval()
    }
    if (props.adGroupAds.adDescriptions.length) {
      setAdDescription(props.adGroupAds.adDescriptions[0] || "")
      if (isIntervalRunning)
      descriptionsInterval()
    }
    if (props.adGroupAds.adPaths.length) {
      setAdPath1(props.adGroupAds.adPaths[0] || "")
      setAdPath2(props.adGroupAds.adPaths[1] || "")
    }
    return () => {
      clearHeadersInterval()
      clearDescriptionsInterval()
    };
  }, [])

  const headersInterval = () => {
    if (!isIntervalRunning && headersIntervalRef.current !== null) return;
    headersIntervalRef.current = window.setInterval(() => {
      updateHeader();
    }, 10000);
  }

  const descriptionsInterval = () => {
    if (!isIntervalRunning && descriptionsIntervalRef.current !== null) return;
    descriptionsIntervalRef.current = window.setInterval(() => {
      updateDescription();
    }, 10000);
  }

  const clearHeadersInterval = () => {
    if (headersIntervalRef.current) {
      window.clearInterval(headersIntervalRef.current);
      headersIntervalRef.current = null;
    }
  };

  const clearDescriptionsInterval = () => {
    if (descriptionsIntervalRef.current) {
      window.clearInterval(descriptionsIntervalRef.current);
      descriptionsIntervalRef.current = null;
    }
  };

  const updateHeader = (increment: number = 1) => {
    currentHeaderIndexRef.current = (currentHeaderIndexRef.current + increment + props.adGroupAds.adHeaders.length) % props.adGroupAds.adHeaders.length;
    setAdHeader1(props.adGroupAds.adHeaders[currentHeaderIndexRef.current]);
    setAdHeader2(props.adGroupAds.adHeaders[(currentHeaderIndexRef.current + 1) % props.adGroupAds.adHeaders.length]);
    setAdHeader3(props.adGroupAds.adHeaders[(currentHeaderIndexRef.current + 2) % props.adGroupAds.adHeaders.length]);
  };

  const updateDescription = (increment: number = 1) => {
    currentDescriptionIndexRef.current = (currentDescriptionIndexRef.current + increment + props.adGroupAds.adDescriptions.length) % props.adGroupAds.adDescriptions.length;
    setAdDescription(props.adGroupAds.adDescriptions[currentDescriptionIndexRef.current]);
  };

  const toggleInterval = () => {
    if (isIntervalRunning) {
      clearHeadersInterval();
      clearDescriptionsInterval();
    } else {
      headersInterval();
      descriptionsInterval();
    }
    setIsIntervalRunning(!isIntervalRunning);
  }

  const nextPreview = () => {
    updateHeader(1);
    updateDescription(1);
  }

  const prevPreview = () => {
    updateHeader(-1);
    updateDescription(-1);
  }

  return (
    <div className="ads-preview">
      {/* <p className="large-p">מודעות לדוגמה:</p> */}
      <div className="ad-preview" style={{ direction: 'ltr' }}>
        <div className={isMobile ? 'preview-mobile' : ''} style={{ display: "flex", alignItems: 'end' }}>
          <div className="preview-content-wrapper">
            <div className="preview-content">
              <div className="url-section">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span className="ad-badge">Ad</span>
                  <div style={{ display: 'flex', alignItems: 'center', marginLeft: '4px' }}>
                    <span className="pathurl">
                      <span>www.b144.co.il</span>
                      {adPath1 && (<><span>/</span><span>{adPath1}</span></>)}
                      {adPath2 && (<><span>/</span><span>{adPath2}</span></>)}
                    </span>
                    <div className="triangle"></div>
                  </div>
                </div>
              </div>
              <a href="" target="_blank" style={{ textDecoration: 'none' }}>
                <div className="headline-section text-left">
                  <span>{adHeader1 + "|" + adHeader2 + "|" + adHeader3}</span>
                </div>
              </a>
              <div className="description-section text-left">
                <span className="description-line">{adDescription}</span>
              </div>
            </div>
          </div>
          <div className="contol-buttons" style={{ display: "flex" }}>
            <IonButton fill="clear" onClick={toggleInterval}><IonIcon icon={isIntervalRunning?pauseOutline:playOutline}></IonIcon></IonButton>
            <IonButton fill="clear" onClick={prevPreview}><IonIcon icon={chevronBackOutline}></IonIcon></IonButton>
            <IonButton fill="clear" onClick={nextPreview}><IonIcon icon={chevronForwardOutline}></IonIcon></IonButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdsPreviewSlide;