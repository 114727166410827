import { IonCheckbox, IonLabel,IonItem } from "@ionic/react";
import React, { ComponentProps, useContext, useEffect, useState, forwardRef } from "react";
import { update } from "../../../images";
type IonCheckboxProps = ComponentProps<typeof IonCheckbox>;
type inputRef = HTMLIonCheckboxElement;
interface CheckboxProps extends IonCheckboxProps {
    value: string;
    label: string;
    selected: boolean;
    original?:any;
    onSelect: (item: any) => void;
}
export type CustomCheckboxFields = {
    label: string;
    value?: string;
    selected?: boolean;
    original?:any;
}
const CustomCheckbox = forwardRef<inputRef, CheckboxProps>(
    (props, ref) => {
        const [checked, setChecked] = useState<boolean>(props.selected || false);
        const defaultRef = ref || React.useRef<HTMLIonCheckboxElement>(null)
        const handleSelectItem = (e: any) => {
            const { checked } = e.detail
            setChecked(checked)
            if (props.original) {
                let updatedItem = props.original
                updatedItem.selected = checked
                props.onSelect(updatedItem)
            }
            else {
                props.onSelect({ value: props.value, selected: checked })
            }
        }
        useEffect(() => {
            setChecked(props.selected || false)
        }, [props.selected])
        return (<>
            <IonItem  mode="md" lines="none" className="item-radio">
                <IonCheckbox
                    mode="md"
                    slot="start"
                    className="item-radio-btn" ref={defaultRef} checked={checked} onIonChange={(e) => handleSelectItem(e)} value={props.value}></IonCheckbox>
                <IonLabel className="item-radio-label">{props.label}</IonLabel>
            </IonItem>
        </>
        )
    }
)
export default CustomCheckbox;