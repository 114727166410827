import React, {
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonButton,
  IonIcon,
  IonReorderGroup,
  IonReorder,
} from "@ionic/react";

import MemberDetailsContext from "../../../data/MemberDetailes-context";
import { addOutline } from "ionicons/icons";
import CustomModal from "../../utils/CustomElements/CustomModal/CustomModal";
import CustomForm from "../../utils/CustomElements/CustomForm";
import { useForm } from "react-hook-form";
import { HeaderParameters } from "../../../data/Header-Context";
import CustomItemSliding from "../../utils/CustomElements/CustomItemSliding";
import HandleQuestionAnswer from "./HandleQuestionAnswer";

const BusinessQuestionsAndAnswers: React.FC<{
  show: boolean;
  closeModal: () => void;
  onSave: () => void;
}> = (props) => {
  const { handleSubmit, formState, errors, control } = useForm({
    mode: "onChange",
  });
  const { dirty } = formState;
  const MemberDetailsCxt = useContext(MemberDetailsContext);

  useEffect(() => {
    if (MemberDetailsCxt.BusinessQuestionsAndAnswers == null) {
      MemberDetailsCxt.initBusinessQuestionsAndAnswerContext();
    }
  }, []);

  useEffect(() => {
    setNewArray(MemberDetailsCxt.BusinessQuestionsAndAnswers);
  }, [MemberDetailsCxt.BusinessQuestionsAndAnswers]);

  const selectedQuestionAnswerIndexRef = useRef<number | null>(null);
  let selectedQuestionAnswerRef = useRef<HTMLIonItemSlidingElement | null>(
    null
  );
  const [newArray, setNewArray] = useState(
    MemberDetailsCxt.BusinessQuestionsAndAnswers
  );
  const [startedEditing, setStartEditing] = useState(false);
  const [isStartSort, setIsStartSort] = useState(true);
  const [sortButtonTitile, setSortButtonTitile] = useState("מיין");

  const deleteQuestionAndAnswer = () => {
    if (MemberDetailsCxt.BusinessQuestionsAndAnswers) {
      const questionAnswerIndex = selectedQuestionAnswerIndexRef.current!;
      MemberDetailsCxt.saveQuestionAnswerHandler(
        MemberDetailsCxt.BusinessQuestionsAndAnswers[questionAnswerIndex],
        "delete"
      );
      selectedQuestionAnswerIndexRef.current = null;
    }
  };

  const doReorder = (e: CustomEvent) => {
    if (newArray) {
      const fromIndex = e.detail.from;
      const toIndex = e.detail.to;
      const changeObj = newArray[fromIndex];
      let _newArray = [...newArray];
      _newArray.splice(fromIndex, 1);
      _newArray.splice(toIndex, 0, changeObj);
      setNewArray(_newArray);
      MemberDetailsCxt.sortQuestionAnswerHandler(_newArray, "start");
    }
    // Finish the reorder
    e.detail.complete();
  };

  const saveHandler = () => {
    props.closeModal();
  };

  const headerParameters: HeaderParameters = {
    isPageHaveChanges: dirty,
    modalType: "modal",
  };
  return (
    <CustomModal
      pageName={"BusinessQuestionsAndAnswers"}
      closeModal={() => {
        MemberDetailsCxt.sortQuestionAnswerHandler(newArray!, "finish");
        props.closeModal();
      }}
      isOpen={props.show}
      headerParameters={headerParameters}
      title="שאלות ותשובות"
      subTitle=""
    >
      <CustomForm onSubmit={handleSubmit(saveHandler)} errors={errors}>
        <IonButton
          onClick={() => {
            if (isStartSort == true) {
              setIsStartSort(false);
              setSortButtonTitile("סגור מיון");
            } else {
              setIsStartSort(true);
              setSortButtonTitile("מיין");
            }
          }}
        >
          {sortButtonTitile}
        </IonButton>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonList>
                <IonItem>
                  <IonCol>שאלות</IonCol>
                  <IonCol>תשובות</IonCol>
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
          {MemberDetailsCxt.BusinessQuestionsAndAnswers &&
            MemberDetailsCxt.BusinessQuestionsAndAnswers.length > 0 && (
              <IonRow>
                <IonCol>
                  <IonReorderGroup
                    disabled={isStartSort}
                    onIonItemReorder={(e) => {
                      doReorder(e);
                    }}
                  >
                    {MemberDetailsCxt.BusinessQuestionsAndAnswers.map(
                      (obj, index) => {
                        const myRef = createRef<HTMLIonItemSlidingElement>();
                        return (
                          <CustomItemSliding
                            onDelete={() => {
                              selectedQuestionAnswerIndexRef.current = index;
                              selectedQuestionAnswerRef.current = myRef.current;
                              deleteQuestionAndAnswer();
                            }}
                            onEdit={() => {
                              setStartEditing(true);
                              selectedQuestionAnswerIndexRef.current = index;
                              selectedQuestionAnswerRef.current = myRef.current;
                            }}
                            key={index}
                            ref={myRef}
                          >
                            <IonItem
                              key={index}
                              onClick={() => {
                                setStartEditing(true);
                                selectedQuestionAnswerIndexRef.current = index;
                                selectedQuestionAnswerRef.current =
                                  myRef.current;
                              }}
                            >
                              <IonCol size="6">{obj.Question}</IonCol>
                              <IonCol size="6">{obj.Answer}</IonCol>
                              <IonReorder slot="start" />
                            </IonItem>
                          </CustomItemSliding>
                        );
                      }
                    )}
                  </IonReorderGroup>
                </IonCol>
              </IonRow>
            )}
          <IonRow>
            <IonCol>
              <IonButton
                onClick={() => {
                  setStartEditing(true);
                  selectedQuestionAnswerIndexRef.current = null;
                }}
              >
                {"הוסף שאלה ותשובה"}
                <IonIcon icon={addOutline}></IonIcon>
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </CustomForm>
      {startedEditing && (
        <HandleQuestionAnswer
          show={startedEditing}
          closeModal={() => {
            setStartEditing(false);
            selectedQuestionAnswerRef.current?.closeOpened();
          }}
          onSave={() => {
            setStartEditing(false);
            selectedQuestionAnswerRef.current?.closeOpened();
          }}
          currentQuestionsAnswers={
            MemberDetailsCxt.BusinessQuestionsAndAnswers![
              selectedQuestionAnswerIndexRef.current!
            ]
          }
        ></HandleQuestionAnswer>
      )}
    </CustomModal>
  );
};

export default BusinessQuestionsAndAnswers;
