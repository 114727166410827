import { IonBackButton, IonIcon } from "@ionic/react";
import { chevronForwardOutline } from "ionicons/icons";
import jsPDF from "jspdf";
import React from "react";
import CustomAddFloatingButton from "../CustomAddFloatingButton";
import CustomeMoreOptions, { optionButtons } from "../CustomeMoreOptions";
import { floatingButton } from "../CustomPage";
import { BackButton } from "../../../../data/Header-Context";
import "./customPageTitle.scss";
import { isMobile } from "react-device-detect";

const CustomPageTitle: React.FC<{
  title: string;
  subTitle?: string;
  smallSubTitle?: string;
  titleClass?: string;
  subTitleClass?: string;
  pageName?: string;
  floatingButton?: floatingButton;
  pdfDownloadPdf?: jsPDF | undefined;
  moreOptionsButtons?: optionButtons[] | undefined;
  isLogin?: boolean;
  icon?: string;
  backButtonDesktop?: BackButton;
}> = (props) => {
  const pageTitle = () => {
    return (
      <>
        {props.backButtonDesktop?.isShowDesktop && !isMobile && (
          <IonBackButton
            text=""
            icon={chevronForwardOutline}
            defaultHref={
              props.backButtonDesktop.link
                ? props.backButtonDesktop.link
                : `/Home`
            }
          ></IonBackButton>
        )}
        <div className={"page-title-wraper" + (props.isLogin ? " login" : "")}>
          {props.pageName === "Home" ? (
            <h2 className={`white-text ion-no-margin xl-p ${props.titleClass}`}>
              {props.title}
            </h2>
          ) : (
            <h1 className="ion-no-margin">
              {props.icon && (
                <IonIcon
                  slot="start"
                  style={{ marginLeft: 8 }}
                  icon={props.icon}
                />
              )}
              {props.title}
            </h1>
          )}
          {props.subTitle && props.subTitle != "" ? (
            <h1 className={props.subTitleClass}>{props.subTitle}</h1>
          ) : null}
          {props.smallSubTitle && props.smallSubTitle != "" ? (
            <p className="white-text" style={{marginBottom: isMobile ? 10 : 0}}>{props.smallSubTitle}</p>
          ) : null}
        </div>
        {props.floatingButton != undefined ? (
          <CustomAddFloatingButton
            onclick={() => {
              props.floatingButton?.onClick();
            }}
            title={props.floatingButton?.title}
            icon={props.floatingButton?.icon}
          ></CustomAddFloatingButton>
        ) : (
          ""
        )}

        {!isMobile &&
          props.moreOptionsButtons &&
          props.moreOptionsButtons.length > 0 && (
            <CustomeMoreOptions
              options={props.moreOptionsButtons}
            ></CustomeMoreOptions>
          )}
      </>
    );
  };
  return pageTitle();
};

export default CustomPageTitle;
