import React, {
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
  
} from "react";
import {
  
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonRadioGroup,
  IonListHeader,
  IonItem,
  IonRadio,
  IonSelect,
  IonSelectOption,
  IonText,
  
} from "@ionic/react";

import MemberDetailsContext from "../../../data/MemberDetailes-context";
import { close } from "ionicons/icons";
import CustomModal from "../../utils/CustomElements/CustomModal/CustomModal";
import CustomReactTextFormat from "../../utils/CustomElements/CustomReactTextFormat";
import CustomInput from "../../utils/CustomElements/CustomInput";
import CustomPublishButton from "../../utils/CustomElements/CustomPublishButton";
import CustomForm from "../../utils/CustomElements/CustomForm";
import { useForm } from "react-hook-form";
import CustomDeleteButton from "../../utils/CustomElements/CustomDeleteButton";
import { HeaderParameters } from "../../../data/Header-Context";
import CustomTextarea from "../../utils/CustomElements/CustomTextarea";
import { config } from "../../../Constants";

const BusinessSeoDetails: React.FC<{
  show: boolean;
  closeModal: () => void;
  onSave: () => void;
}> = (props) => {
  const { handleSubmit, formState, errors, control,register,setError } = useForm({
    mode: "onChange",
  });
  const { dirty } = formState;
  const MemberDetailsCxt = useContext(MemberDetailsContext);
  useEffect(() => {
    if (MemberDetailsCxt.BusinessSeoDetails == null) {
      MemberDetailsCxt.initBusinessSeoDetailsContext();
    }
    console.log(MemberDetailsCxt.SeoDetails);
    if (MemberDetailsCxt.SeoDetails == null) {
      //console.log('init seo');
      MemberDetailsCxt.initSeoDetailsContext();
    }
  }, []);
  
  
  const selectedIndexRef = useRef<number | null>(null);
  let selectedRef = useRef<HTMLIonItemSlidingElement | null>(null);
  const discountTextRef = useRef<HTMLIonInputElement>(null);
  
  const [contentType,setContentType] = useState(1);
  const [showCustomText,setShowCustomText] = useState(false);
  const [contentTemplateLength,setContentTemplateLength] = useState(0);
  const [placeholder,setPlaceholder] = useState('');

  const [giftType,setGiftType] = useState(1);
  const [selectedPopulation,setSelectedPopulation] = useState<string[]>([]);


  const onContentTypeChange_callback = (event: CustomEvent) => {
    
    setContentType(event.detail.value);
    control.setValue("seo_content",undefined);
    setContentTemplateLength(0);
    setShowCustomText(false);
  };
  const onGiftTypeChange_callback = (event: CustomEvent) => {
    
    setGiftType(event.detail.value);
    //control.setValue("seo_content",undefined);
    setContentTemplateLength(0);
    
    
  };

  const deleteItem = () => {
    if (MemberDetailsCxt.BusinessSeoDetails) {
      const linkIndex = selectedIndexRef.current!;
      MemberDetailsCxt.BusinessSeoDetails[linkIndex].isDelete = true;
      MemberDetailsCxt.saveSeoDetailsHandler(MemberDetailsCxt.BusinessSeoDetails[linkIndex]);
    }
  };
  const saveHandler = (data: any) => {
    
    let new_text = '';
    const custom_text = data['text'];// descriptionTextRef.current!.value!.toString();
    const new_type = contentType;//linkTextRef.current!.value!.toString();
    const seo_content = data['seo_content'];
    if(seo_content == undefined || seo_content == ''){
      setError("seo_content", "manual", "נא לבחור " + (new_type == 3 ? "קהל יעד" : "הצעת תוכן"));
      return;
    }
if(new_type == 3){
if(giftType == 1){
  const discount = discountTextRef.current!.value!.toString();
  if(!discount){
    setError("discount", "manual", "נא להזין אחוז הנחה");
    return;
  }
  new_text = 'ניתנת הנחה של ' + discount + '% ל' + populationToString();
} else if(giftType == 2){
  const bonus = data["bonus"];
  new_text = populationToString() + ' מקבלים ' + bonus + ' חינם';
}
} else if(seo_content > 0){
      const selected_item = MemberDetailsCxt.SeoDetails?.find((c)=>c.Id == seo_content);
      new_text = selected_item?.Text + (selected_item?.IsOpenField == true ? (' ' + custom_text) : '');
    }else{
      new_text = custom_text;
    }
    let seo_run_id = '';
    if(seo_content){
    if(seo_content instanceof Array){
      seo_run_id = seo_content.join(',');
    }else{
      seo_run_id = seo_content.toString();
    }
  }
    let seoObj = {
      Text: new_text,
      Type: new_type,
      Id: -99,
      SeoRunId: seo_run_id,
      isDelete: false,
    };

    //console.log(seoObj);
    MemberDetailsCxt.saveSeoDetailsHandler(seoObj);
    props.closeModal();
  };

  function populationToString() {
    if (selectedPopulation.length === 1) return selectedPopulation[0];
    const firsts = selectedPopulation.slice(0, selectedPopulation.length - 1);
    const last = selectedPopulation[selectedPopulation.length - 1];
    return firsts.join(', ') + ' ו' + last;
  }
  const headerParameters: HeaderParameters = {
    isPageHaveChanges: dirty,
    modalType: "modal",
  };
  return (
    <CustomModal
      pageName={"SeoDetails"}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
      headerParameters={headerParameters}
      title="מידע נוסף על העסק"
      subTitle="כאן תוכל להוסיף מידע שיווקי נוסף על העסק, 
      המידע יופיע בסמוך לתאור העסק בכרטיס"
    >
      <CustomForm onSubmit={handleSubmit(saveHandler)} errors={errors}>
        <IonGrid>
        <IonRow>
              <IonCol className="ion-no-padding">
                <IonRadioGroup name="content_type" onIonChange={onContentTypeChange_callback} ref={register} value={contentType}>
                  <IonListHeader>
                    <IonLabel>סוג תוכן (עד 3 אפשרויות מכל סוג)</IonLabel>
                  </IonListHeader>
                  <IonRow>
                    <IonCol size="5" sizeMd="3"  className="ion-no-padding">
                      <IonItem mode="md" lines="none" className="item-radio">
                        <IonLabel className="item-radio-label">שיווקי</IonLabel>
                        <IonRadio mode="md" slot="start" className="item-radio-btn" value={1}  />
                      </IonItem>
                    </IonCol>
                    <IonCol size="7" sizeMd="4"  className="ion-no-padding">
                      <IonItem mode="md" lines="none" className="item-radio">
                        <IonLabel className="item-radio-label">אודות העסק</IonLabel>
                        <IonRadio mode="md" slot="start" className="item-radio-btn" value={2} />
                      </IonItem>
                    </IonCol>
                   {config.seo_discounts && <IonCol size="12" sizeMd="5"  className="ion-no-padding">
                      <IonItem mode="md" lines="none" className="item-radio">
                        <IonLabel className="item-radio-label">הנחות והטבות</IonLabel>
                        <IonRadio mode="md" slot="start" className="item-radio-btn" value={3} />
                      </IonItem>
                    </IonCol>}
                   
                  </IonRow>
                </IonRadioGroup>
              </IonCol>
            </IonRow>
            
            <IonRow>
                  <IonCol className="ion-padding-vertical">
                    <IonItem color="light" lines="full" mode="md">
                      <IonLabel position="stacked">{contentType == 3 ? "קהל יעד" : "הצעות תוכן"}</IonLabel>
                      <IonSelect
                        color="light" interfaceOptions={{cssClass: 'select-extra-long'}}
                        
                        className="select-extra-long"
                        name="seo_content"
                        cancelText="ביטול"
                        okText="אישור"
                        multiple={contentType == 3}
                        ref={register}
                        placeholder={contentType == 3 ? "בחירת קהל יעד" : "בחירת תוכן"}
                        disabled={MemberDetailsCxt.BusinessSeoDetails && MemberDetailsCxt.BusinessSeoDetails.filter((t)=> t.Type == contentType).length >= 3}
                        onIonChange={(e) => {
                          if(e.detail.value == 0){
                            setShowCustomText(true);
                            setContentTemplateLength(0);
                            setPlaceholder('');
                          }else{
                            if(e.detail.value instanceof Array){
                              const items = MemberDetailsCxt.SeoDetails?.filter((t)=> e.detail.value.find((j:number) => j === t.Id));
                              setSelectedPopulation(items ? items?.map((p)=> p.Text) : []);
                            }else{
                            const item = MemberDetailsCxt.SeoDetails?.find((t)=> e.detail.value == t.Id);
                            
                           const isOpenField = item?.IsOpenField == true;
                           setShowCustomText(isOpenField);
                           setContentTemplateLength(item ? item.Text.length : 0);
                           if(isOpenField && item && item.Placeholder){
                           setPlaceholder(item.Placeholder);
                          }else{
                            setPlaceholder('');
                          }}
                          }
                         
                        }}
                      >
                        {MemberDetailsCxt.SeoDetails &&
                          MemberDetailsCxt.SeoDetails.filter((t)=> t.Type == contentType).map((t) => 
                           
                              <IonSelectOption key={t.Id} value={t.Id}>
                                {t.Text}
                              </IonSelectOption>
                           
                          )}
                         {contentType != 3 &&  <IonSelectOption key={0} value={0}>
                               אחר
                              </IonSelectOption>}
                      </IonSelect>
                    </IonItem>
                    <div className="input-alerts">
                      {errors && errors["seo_content"] != null && errors["seo_content"] != undefined && (
                        <p className="input-error">{ !!errors["seo_content"].message ? errors["seo_content"].message : (errors as any)["seo_content"].message}</p>
                      )}
                    </div>
                  </IonCol>
                </IonRow>
                {contentType == 3 && <IonRow>
                  <IonCol className="ion-no-padding">
                <IonRadioGroup name="content_type" onIonChange={onGiftTypeChange_callback} ref={register} value={giftType}>
                  <IonRow>
                    <IonCol size="6"  className="ion-no-padding">
                      <IonItem mode="md" lines="none" className="item-radio">
                        <IonLabel className="item-radio-label">אחוז הנחה</IonLabel>
                        <IonRadio mode="md" slot="start" className="item-radio-btn" value={1}  />
                      </IonItem>
                    </IonCol>
                    <IonCol size="6"  className="ion-no-padding">
                      <IonItem mode="md" lines="none" className="item-radio">
                        <IonLabel className="item-radio-label">מתנה</IonLabel>
                        <IonRadio mode="md" slot="start" className="item-radio-btn" value={2} />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                </IonRadioGroup>
              </IonCol>
          </IonRow>}
          {showCustomText && <IonRow>
            <IonCol className="ion-no-padding">
              <CustomInput
                name="text"
                errors={errors}
                control={control}
                maxlength={55 - (contentTemplateLength > 0 ? contentTemplateLength + 1 : 0)  }
                required={true}
                ref={register}
                value={""}
                label={placeholder || "טקסט חופשי"}
              />
            </IonCol>
          </IonRow>}
          {contentType == 3 && (
            <IonRow>
            <IonCol className="ion-no-padding">
           { giftType == 1 ?
              <CustomInput type="number"
                name="discount"
                errors={errors}
                control={control}
                maxlength={2}
                min="1" max="99"
                required={contentType == 3 && giftType == 1}
                ref={discountTextRef}
                value={""}
                label='אחוז הנחה'
              />  : <CustomTextarea
                    name="bonus"
                    errors={errors}
                    control={control}
                    maxlength={100}
                    rows={3}
                    required={contentType == 3 && giftType == 2}
                    ref={register}
                    
                    placeholder='פירוט הטבה' 
                                />  }
            </IonCol>
          </IonRow>
           
          )}

          <IonRow>{MemberDetailsCxt.BusinessSeoDetails && MemberDetailsCxt.BusinessSeoDetails.filter((t)=> t.Type == contentType).length >= 3 && (
                <IonCol size="12" className="ion-text-center">
                  <IonText color="danger">
                    <p>לא ניתן להוסיף עוד תכנים מסוג זה</p>
                  </IonText>
                </IonCol>
              )}
            </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel className="small-title bold-text">
                תכנים שהוספת
              </IonLabel>
            </IonCol>
          </IonRow>
          {MemberDetailsCxt.BusinessSeoDetails &&
            MemberDetailsCxt.BusinessSeoDetails.length > 0 ?
            MemberDetailsCxt.BusinessSeoDetails.map((item, index) => {
              const myRef = createRef<HTMLIonItemSlidingElement>();
              return (
                <IonRow key={index} className="ion-align-items-center">
                  <IonCol size="1" className="ion-no-padding">
                    <CustomDeleteButton
                      icon={close}
                      fillClear={true}
                      sizeSmall={true}
                      onDelete={() => {
                        selectedIndexRef.current = index;
                        selectedRef.current = myRef.current;
                        deleteItem();
                      }}
                    />
                  </IonCol>
                   <IonCol size={item.Type == 3 ? "11" : "4"} className="text-wrap link ion-no-padding">

                 {item.Type == 3 ?   <CustomReactTextFormat>
                      {item.Text}
                    </CustomReactTextFormat> : <>{item.Type == 1 ? 'שיווקי' : (item.Type == 2 ? 'אודות העסק' : '')}</>}
                    
                  </IonCol>
                  {item.Type != 3 && <IonCol
                    size= "7"
                    className="text-wrap ltr link ion-no-padding"
                  >
                    <CustomReactTextFormat>
                      {item.Text}
                    </CustomReactTextFormat>
                  </IonCol>}
                </IonRow>
              );
            }) : 'טרם נוספו תכנים'}

          <IonRow className="ion-text-center">
            <IonCol>
              <CustomPublishButton title="שמור"></CustomPublishButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </CustomForm>
    </CustomModal>
  );
};

export default BusinessSeoDetails;
