import React from "react";

export interface actionSummery {
  ClickType: number;
  ClickTypeDesc: string;
  SumClicks: number; //תקציב
}

export interface StatProd {
  Prodmain: number;
  ProdMainName: string;
  Budget: number; //תקציב
  CallsCount: number; //כמות שיחות
  CallsDuration: number; // משך שיחה
  WhatsAppCount: number;
  Cost: number; // תקציב שנוצל
  Events: number; // אירועים
  Impressions: number; //חשיפות/יחידות פרסום
  GF_Clicks: number; //אירועים פייסבוק וגוגל
  GF_Cost: number; //תקציב שנוצל גוגל ופייסבוק
  GF_Impressions: number; //הקלקות גוגל ופייסבוק
  GF_avgPricePerEvent: number; //עלות ממוצית לאירוע
  GF_precentage: number; //אחוז ניצול
  IsByBudget: number; //האם יש אחוז ניצול
  Transactions: number; //סהכ פעולות
  avgPricePerEvent: number; //עלות ממוצת לאירוע
  precentage: number; // אחוז ניצול
}

export interface B144Stats {
  StatsDaysByMonths: {
    AllClicks: number;
    Day: number;
    Month: number;
    Year: number;
  }[];
  StatsByCatOrName: { CountOfClicks: number; SearchType: string }[];
  StatsExposeTypes: { TotalClicks: number; Type: string }[];
  StatsByCategories: {
    CatDesc: string;
    SumCountOfClicksCharge: number;
    SumCountOfClicksTotal: number;
  }[];
  StatsBytransactionsSources: { CountClicks: number; SourceName: string }[];
  StatsByTotalExposed?: {
    Amount_Charged: number;
    Clicks_Charged: number;
    Clicks_Total: number;
    Period_Budget: number;
  };
  isStatEmpty: boolean;
}

export type ExposeStatType =
  | "StatsDaysByMonths"
  | "StatsByCatOrName"
  | "StatsExposeTypes"
  | "StatsByCategories"
  | "StatsByTotalExposed"
  | "StatsBytransactionsSources";

const StatisticContext = React.createContext<{
  statProducts: StatProd[] | undefined;
  statProductsPrev: StatProd[] | undefined;
  actionSummery: actionSummery[] | undefined;
  statB144: B144Stats;
  //loading: boolean;
  initStatProductsContext: (
    useLoading: boolean,
    month: number,
    year: number
  ) => void;
  initActionSummeryContext: (
    useLoading: boolean,
    fromDate: Date,
    toDate: Date,
    ProdMain?: number
  ) => void;
  initStatB144Context: (
    fromDate: Date,
    toDate: Date,
    exposeStatType: ExposeStatType,
    userChoice?: number,
    chargedOrTotalExposures?: number
  ) => void;
}>({
  //loading: true,
  statProducts: [],
  statProductsPrev: [],
  actionSummery: [],
  statB144: {
    StatsDaysByMonths: [],
    StatsByCatOrName: [],
    StatsExposeTypes: [],
    StatsByCategories: [],
    StatsBytransactionsSources: [],
    isStatEmpty: true,
  },
  initStatProductsContext: () => {},
  initActionSummeryContext: () => {},
  initStatB144Context: () => {},
});

export default StatisticContext;
