import React, { useContext, useEffect, useState } from 'react';

import UserContext from '../../data/AuthContextProvider';
import * as pageData from '../../data/homePageData';
import './Home.scss';
import { isMobile } from 'react-device-detect';
import CommentsContext from '../../data/Comments-context';
import MemberInboxContext from '../../data/MemberInbox-Context';

import { IonGrid, IonRow, IonButton, IonCol, IonItem, IonLabel, IonImg, IonIcon, IonModal, IonList } from '@ionic/react';

import Tips from '../../components/Tips/Tips';
import CustomTitleButton from '../../components/utils/CustomElements/CustomTitleButton';
import DoughnutChart from '../../components/utils/Charts/DoughnutChart';
import MemberDetailsContext from '../../data/MemberDetailes-context';
import { HeaderParameters } from '../../data/Header-Context';
import CustomPopContentOptions from '../../components/utils/CustomElements/CustompopContentOptions';
import MemberDetaielsModules from '../../components/BusinessCardTabs/MemberDetails/MemberDetaielsModules';
import { businessCardModulesName, getAdvCategoryCategories, MemberDetailesArrayToShow, showEmptyElementsBtn } from '../../utils-ts/BusinessCardFunc';
import CustomHeaddingItem from '../../components/utils/CustomElements/CustomPageTitle/CustomHeaddingItem';
import ShareOptions from '../../components/ShareOptions/ShareOptions';
import {
  b144LogoBlue,
  reviewWhite,
  businessCardWhite,
  statisticsWhite,
  inboxWhite,
  vas_white,
  budget_white,
  closeBlack
} from '../../images/index';

import CustomPage, { floatingButton } from '../../components/utils/CustomElements/CustomPage';
import { addOutline, chevronBackOutline } from 'ionicons/icons';
import WelcomeNewUser from '../../components/WelcomeNewUser/welcomeNewUser';
import Cookies from 'universal-cookie';
import { Capacitor, Plugins } from '@capacitor/core';
import { getTitle } from '../../utils-ts/GlocalFunc';
import { config } from '../../Constants';
import GoogleAdsOffer from '../../components/SelfSale/SelfSaleGoogleAds/GoogleAdsOffer';
import BusinessCardQuickAccess from './BusinessCardQuickAccess';
import CustomModal from '../../components/utils/CustomElements/CustomModal/CustomModal';

const { Storage } = Plugins;
const Home: React.FC = () => {
  const { authValues } = useContext(UserContext);
  const commentsCtx = useContext(CommentsContext);
  const MemberInboxCxt = useContext(MemberInboxContext);

  const [isPrecentageChange, setisPrecentageChange] = useState(false);
  const [mainCategory, setMainCategory] = useState('');
  const [isShowShareOptions, setIsShowShareOptions] = useState(false);
  const [showSelfSalePopup, setShowSelfSalePopup] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showElementName, setShowElementName] = useState<businessCardModulesName>('');
  const [showPopover, setShowPopover] = useState(false);
  const floatingButton: floatingButton = {
    icon: addOutline,
    onClick: () => {
      setShowPopover(true);
    },
    title: 'תוכן',
  };

  const closeModal = () => { };
  const cookies = new Cookies();

  let showEmptyElements = 0;

  const MemberDetailsCxt = useContext(MemberDetailsContext);

  const headerParameters: HeaderParameters = {
    isShowLogo: true,
    isShowHamburger: true,
    isShowNotification: true,
    isShowBusinessCardPreview: !isMobile,
  };

  useEffect(() => {
    if (!config.otef_israel) {
      MemberDetailsCxt.initSelfSale();
    }
  }, []);

  useEffect(() => {
    if (MemberDetailsCxt.BusinessLogo == null) {
      MemberDetailsCxt.initBusinessLogoContext();
    }
    if (MemberDetailsCxt.ContentStatusPercentage == null || isPrecentageChange == true) {
      MemberDetailsCxt.initContentStatusContext();
      setisPrecentageChange(false);
    }
    if (MemberDetailsCxt.BusinessLinks == null) {
      MemberDetailsCxt.initBusinessLinksContext();
    }
    if (MemberDetailsCxt.BusinessSeoDetails == null && !config.otef_israel) {
      MemberDetailsCxt.initBusinessSeoDetailsContext();
    }
    if (MemberDetailsCxt.BusinessQuestionsAndAnswers == null) {
      MemberDetailsCxt.initBusinessQuestionsAndAnswerContext();
    }
    if (MemberDetailsCxt.BusinessMovies == null) {
      MemberDetailsCxt.initMovieContext();
    }
    if (!config.otef_israel) {
      if (Capacitor.isNative && Capacitor.getPlatform() === 'ios') {
        if (!MemberDetailsCxt.FirstLogin) {
          setShowModal(true);
          MemberDetailsCxt.initFirstLogin();
        }
      } else {
        if (!cookies.get('welcomePopup')) {
          setShowModal(true);
          cookies.set('welcomePopup', 1, { path: '/' });
        }
      }
    }

    if (!commentsCtx.comments) {
      commentsCtx.initCommentsContext(true);
    }

    if (commentsCtx.gmbComments == null) {
      commentsCtx.initGmbCommentsContext(true);
    }
  }, [isPrecentageChange]);

  useEffect(() => {
    if (commentsCtx.comments) {
      SetDtlPushLoginEvt();
    }
  }, [commentsCtx.comments]);

  async function SetDtlPushLoginEvt() {
    if (DataLayerLoginExists()) {
      return;
    }

    var missing_details: string = '';
    var user_details_filled: string = '';

    MemberDetailesArrayToShow.map((o, index) => {
      if (showEmptyElementsBtn(MemberDetailsCxt, o.id)) {
        missing_details = missing_details + (o.id == 'AdvCategories' ? o.btnTitle : o.title) + ';';
      } else {
        user_details_filled = user_details_filled + (o.id == 'AdvCategories' ? o.btnTitle : o.title) + ';';
      }
    });

    let commentsRank = 0;

    let GmbRank = 0;
    let _totalComments = 0;
    let _totalCommentsReplay = 0;
    if (commentsCtx.gmbComments) {
      commentsCtx.gmbComments!.map((c) => {
        GmbRank = GmbRank + c.StarRating;
        if (c.ReplyComment != '') {
          _totalCommentsReplay = _totalCommentsReplay + 1;
        }
      });

      commentsCtx.comments!.map((c) => {
        commentsRank = commentsRank + c.Rating;
        if (c.BusinessReplyText != '') {
          _totalCommentsReplay = _totalCommentsReplay + 1;
        }
      });

      _totalComments = commentsCtx.gmbComments!.length + commentsCtx.comments!.length;
    }
    // _totalComments = (commentsCtx.gmbComments ? commentsCtx.gmbComments!.length : 0)
    //                   + (commentsCtx.comments  ? commentsCtx.comments!.length : 0);

    let totalMessagesAmount = MemberInboxCxt ? MemberInboxCxt.totalMessagesAmount : 0;

    let user_id = '0';
    const userData = await Storage.get({ key: 'userData' });
    const storedUserData = userData.value ? JSON.parse(userData.value) : undefined;
    if (storedUserData) {
      user_id = storedUserData.MemberId;
    }

    dtlPushEvt({
      event: 'login',
      eventInfo: {
        ScreenName: '{{page path}}',
        user_action: 'login',
        business_name_ep: '' + (MemberDetailsCxt.GeneralInfo ? MemberDetailsCxt.GeneralInfo?.CompanyName : ''),
        business_category_ep: GetMemberAdvCategories(MemberDetailsCxt.AdvCategories),
        business_location_ep: MemberDetailsCxt.BusinessAddress?.City,
        user_id: user_id,
        business_name: '' + (MemberDetailsCxt.GeneralInfo ? MemberDetailsCxt.GeneralInfo?.CompanyName : ''),
        business_category: GetMemberAdvCategories(MemberDetailsCxt.AdvCategories),
        business_location: MemberDetailsCxt.BusinessAddress?.City,
        user_details_filled: user_details_filled,
        number_of_reviews_up: _totalComments,
        number_of_reviews_responses_up: _totalCommentsReplay,
        emails_got_up: totalMessagesAmount,
        percentage_of_details_up: MemberDetailsCxt.ContentStatusPercentage,
        missing_details: missing_details,
        number_of_reviews: _totalComments,
        number_of_reviews_responses: _totalCommentsReplay,
        emails_got: totalMessagesAmount,
        percentage_of_details: MemberDetailsCxt.ContentStatusPercentage,
      },
    });
  }
  return (
    <CustomPage headerParameters={headerParameters}
      pageName={'Home'}
      title={getTitle(authValues?.FullName!)}
      subTitle="מה תרצה לעשות היום?"
      floatingButton={floatingButton}
      smallSubTitle={authValues && authValues.IsSelfRegister == true ? 'פעולות בשירות עצמי שיעזרו לך להגיע ליותר לקוחות' : ''}>
      <IonGrid fixed={true} className="main-content">
        {/* main buttons section*/}
        <IonRow>
          {authValues && authValues.IsSelfRegister === false && !config.otef_israel && (
            <IonCol size="12" size-lg="4">
              <CustomHeaddingItem
                mainTitle={'סטטיסטיקות'}
                subTitle={'לקבל מידע על הקמפיינים שלי'}
                icon={statisticsWhite}
                iconBackground="statistics-main"
                routerLink={'/StatisticsOpt'}></CustomHeaddingItem>
            </IonCol>
          )}
          <IonCol size="12" size-lg="4">
            <CustomHeaddingItem
              mainTitle={'דף עסק'}
              subTitle={'לעדכן ולרענן את המידע על העסק'}
              icon={businessCardWhite}
              iconBackground="bussiness"
              routerLink={'/BusinessCard'}></CustomHeaddingItem>
          </IonCol>
          <IonCol size="12" size-lg="4">
            <CustomHeaddingItem
              mainTitle={'חוות דעת'}
              subTitle={'לבדוק את חוות הדעת, להודות ולהגיב עליהם'}
              icon={reviewWhite}
              iconBackground="review"
              routerLink={'/MemberComments'}></CustomHeaddingItem>
          </IonCol>
          <IonCol size="12" size-lg="4">
            <CustomHeaddingItem
              mainTitle={'לבקש חוות דעת'}
              subTitle={'לקבל עוד חוות דעת על העסק'}
              icon={reviewWhite}
              iconBackground="review"
              onClick={() => {
                setIsShowShareOptions(true);
              }}></CustomHeaddingItem>
          </IonCol>

          {!config.otef_israel && (
            <IonCol size="12" size-lg="4">
              <CustomHeaddingItem
                mainTitle={'הודעות'}
                subTitle={'לעבור על הודעות שהתקבלו ולענות להן'}
                icon={inboxWhite}
                iconBackground="inbox"
                routerLink={'/Inbox'}></CustomHeaddingItem>
            </IonCol>
          )}

          {authValues && authValues.IsSelfRegister == false && !config.otef_israel && (
            <React.Fragment>
              <IonCol size="12" size-lg="4">
                <CustomHeaddingItem
                  mainTitle={'מוצרי פרסום נלווים'}
                  subTitle={'כאן ניתן לצפות ברשימת מוצרי הפרסום שלך'}
                  icon={vas_white}
                  iconBackground="icon-background-blue"
                  routerLink={'/Vas'}></CustomHeaddingItem>
              </IonCol>
              <IonCol size="12" size-lg="4">
                <CustomHeaddingItem
                  mainTitle={'חבילת הפרסום שלי'}
                  subTitle={'תקציב חבילת הפרסום שלי'}
                  icon={budget_white}
                  iconBackground="icon-background-orange"
                  routerLink={'/Budget'}></CustomHeaddingItem>
              </IonCol>
            </React.Fragment>
          )}
        </IonRow>

        {authValues && authValues.IsSelfRegister == true && MemberDetailsCxt.ShowSelfSale && !config.otef_israel && (
          <>
            <IonRow className="self-reg-cont">
              <IonCol size="12" size-lg="4" >
                <BusinessCardQuickAccess
                  onSetMainCategory={(c) => {
                    setMainCategory(c);
                  }}
                  onSetShowElementName={(o_id) => {
                    setShowElementName(o_id);
                  }}
                />
              </IonCol>
              {MemberDetailsCxt.ShowSelfSaleAdds && <>
                <IonCol size="12" size-lg="4" >
                  <IonItem className="section-item" lines="none">
                    <IonLabel>
                      <div className="section-top">
                        <div className="section-header">
                          <img className="b144-logo" src={b144LogoBlue} />
                          <div className='text-center'>
                            <h2 className="bold-text">פרסום העסק שלך ב B144</h2>
                            <p className={isMobile ? 'small-p' : 'large-p'}>להיחשף למאות אלפי גולשים</p>
                          </div>
                        </div>
                        <ul className="section-list check-icon" style={{ marginBottom: 0 }}>
                          <li>קידום העסק שלך במקומות הראשונים באתר ובאפליקציית B144</li>
                          <li>דף עסק דיגיטלי מעוצב, עם כל המידע החשוב על העסק שלך, גלריית תמונות, קופונים, מוצרים, מחירונים ועוד…</li>
                          <li>נוכחות בולטת ברשת וחשיפה למאות אלפי גולשים המחפשים מידע</li>
                          <li>אפליקציה לעדכון עצמאי של דף העסק ולמעקב אחרי ביצועי הפרסום</li>
                          <li>הצגת דירוגים וחוות דעת של לקוחות עם אפשרות מענה בדף העסק שלך</li>
                          <li>קידום במנועי חיפוש, בגוגל, באתר יד 2 ובאתרים נוספים</li>
                        </ul>
                        <IonButton fill="clear"
                          routerLink="/SelfSale/1" style={{ color: '#4878fd', textDecoration: "underline", display: 'block' }}
                        >לפרטים נוספים{">"}</IonButton>
                      </div>
                      <IonButton routerLink="/SelfSale/1" className="main-button">
                        לפרסום וקידום העסק שלך
                      </IonButton>
                    </IonLabel>
                  </IonItem>
                </IonCol>
                {MemberDetailsCxt.ShowSelfSaleGoogle && <IonCol size="12" size-lg="4" >
                  <GoogleAdsOffer isSelfReg={true} />
                </IonCol>}

              </>}

            </IonRow>
            <IonRow>
              <IonButton fill="clear" onClick={() => setShowSelfSalePopup(!showSelfSalePopup)} style={{ margin:'0 auto 20px', color: '#4878fd', textDecoration: "underline", display: 'block' }}
              >מהי יחידת פרסום{">"}</IonButton>
            </IonRow>
          </>
        )}
        {/* card section only on desktop*/}
        {MemberDetailsCxt.ContentStatusPercentage != undefined && !isMobile && authValues && authValues.IsSelfRegister === false && (
          <IonRow className="extra-margin ">
            <IonCol size="4">
              <BusinessCardQuickAccess
                onSetMainCategory={(c) => {
                  setMainCategory(c);
                }}
                onSetShowElementName={(o_id) => {
                  setShowElementName(o_id);
                }}
              />
              {/* <IonItem lines="none" className="section-item section-item-full">
                <IonLabel>
                  <IonRow>
                    <IonCol className="ion-no-padding">
                      <h2 className="ion-no-margin large-p bold-text">{MemberDetailsCxt.ContentStatusPercentage === 100 ? 'כל הכבוד!' : 'דף העסק שלך יכול לעבוד טוב יותר'}</h2>
                      <p className="ion-no-margin">
                        {MemberDetailsCxt.ContentStatusPercentage === 100
                          ? 'עשית את זה בגדול! כל המידע אודות העסק שלך נרשם בהצלחה'
                          : 'הוספה של מידע, תמונות, מחירים וקופונים ימשכו לקוחות רבים יותר לעסק שלכם.'}
                      </p>
                    </IonCol>
                    {authValues && authValues.IsSelfRegister === false && (
                      <IonCol size="3" className="ion-no-padding">
                        <DoughnutChart
                          titleColor="black"
                          chartColor={config.otef_israel ? 'rgb(29,139,200)' : undefined}
                          value={MemberDetailsCxt.ContentStatusPercentage ? MemberDetailsCxt.ContentStatusPercentage : 0}
                          chartWidth={63}
                          chartWeight={80}
                        />
                      </IonCol>
                    )}
                  </IonRow>
                  {MemberDetailesArrayToShow.map((o, index) => {
                    
                    if (showEmptyElements < 5 && showEmptyElementsBtn(MemberDetailsCxt, o.id)) {
                      console.log('1_' + o.id)
                      if (o.id === 'AdvCategories') {
                        let mainCategoriesTemp = getAdvCategoryCategories(MemberDetailsCxt);
                        return mainCategoriesTemp.map((c, index) => {
                          if (showEmptyElements < 5) {
                            showEmptyElements = showEmptyElements + 1;
                            return (
                              <>[{showEmptyElements}]
                              <CustomTitleButton
                                position="home-section-btn"
                                key={'c_' + index}
                                onClick={() => {
                                  setMainCategory(c);
                                  setShowElementName(o.id);
                                  SetDtlPushEvt('user_engagement', o.btnTitle);
                                }}
                                BtnTxt={o.btnTitle + ' - ' + c}></CustomTitleButton></>
                            );
                          }
                        });
                      } else {
                        showEmptyElements = showEmptyElements + 1;
                        return (
                          <>[{showEmptyElements}]<CustomTitleButton
                            position="home-section-btn"
                            key={index}
                            onClick={() => {
                              setShowElementName(o.id);
                              SetDtlPushEvt('user_engagement', o.btnTitle);
                            }}
                            BtnTxt={o.btnTitle}></CustomTitleButton></>
                        );
                      }
                    }
                  })}

                  <IonButton
                    routerLink="/BusinessCard"
                    expand="block"
                    className="main-button"
                    onClick={() => {
                      SetDtlPushEvt('user_engagement', 'לפרופיל העסק');
                    }}>
                    לפרופיל העסק
                  </IonButton>
                </IonLabel>
              </IonItem> */}
            </IonCol>
            <IonCol size="8" className="ion-no-padding">
              <IonGrid>
                <IonRow>
                  <IonCol size="6">
                    <IonItem lines="none" className="section-item-small section-item">
                      <IonLabel>
                        <h2 className="bold-text">לשמור על קשר עם הלקוחות</h2>
                        <p className="large-p">מומלץ לעדכן לקוחות שהעסק פתוח, כמו גם את השירותים שהוא מציע</p>
                        <CustomTitleButton
                          position="home-section-btn"
                          onClick={() => {
                            SetDtlPushEvt('user_engagement', 'שעות פעילות');
                            setShowElementName('OpenDays');
                          }}
                          BtnTxt="שעות פעילות"
                        />
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                  <IonCol size="6">
                    <GoogleAdsOffer isSelfReg={false} />
                    {/* <IonItem
                      lines="none"
                      target="_blank"
                      href="https://support.b144.co.il/Content.aspx?pid=176&id=183"
                      className="section-item-small section-item section-item-center"
                      onClick={() => {
                        SetDtlPushEvt('user_engagement', 'לפרטים נוספים');
                        SetDtlPushEvtOutBound('outbound_links', 'https://support.b144.co.il/Content.aspx?pid=176&id=183');
                      }}>
                      <IonLabel>
                        <img src={googleAndB144} />
                        <h2 className="bold-text">פרסום העסק בגוגל</h2>
                        <p className="large-p">הלקוחת שלך מחפשים אותך ברגע זה</p>
                        <IonImg src={coupon}></IonImg>
                        <IonButton expand="block" className="main-button">
                          לפרטים נוספים
                        </IonButton>
                      </IonLabel>
                    </IonItem> */}
                  </IonCol>
                  <IonRow>
                    <IonCol size="12">
                      <Tips tipsTitle="טיפים מבזק" cardData={pageData.tips} />
                    </IonCol>
                  </IonRow>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        )}
      </IonGrid>
      <MemberDetaielsModules
        closeModal={() => {
          setShowElementName('');
          setMainCategory('');
        }}
        onSave={() => {
          setisPrecentageChange(true);
        }}
        mainCategory={mainCategory}
        showElement={showElementName}></MemberDetaielsModules>
      {isShowShareOptions && (
        <ShareOptions
          initShowQR={false}
          show={isShowShareOptions}
          closeModal={() => {
            setIsShowShareOptions(false);
          }}></ShareOptions>
      )}
      {showPopover && (
        <CustomPopContentOptions
          isShow={showPopover}
          onClosePopOver={() => {
            setShowPopover(false);
          }}></CustomPopContentOptions>
      )}
      <IonModal isOpen={showSelfSalePopup} cssClass={"self-sale-popup"}>
        <IonButton fill="clear" onClick={() => setShowSelfSalePopup(!showSelfSalePopup)}>
          <IonIcon icon={closeBlack}></IonIcon>
        </IonButton>
        <div className='self-sale-popup-content'>
          <h3>מה נחשב יחידת פרסום?</h3>
          <ul>
            <li>פרסום העסק שלך בדף תוצאות החיפוש באתר B144 או באתרי השותפים, באחד משלושת המקומות הראשונים ברוטציה, עם מספר טלפון גלוי של העסק. </li>
            <li>כאשר הגולש מקליק על אחד מכפתורי המידע או יצירת הקשר בכרטיס העסק (במידה והעסק מופיע במקום רביעי ואילך) באתר B144 או באתרי השותפים. או כאשר נעשית פניה אל העסק משירות "מי פנוי".</li>
            <li>פרסום במוקד 144 הקולי: מסירת שם העסק וטלפון לחיוג לעסק - במידה והפונה מחפש עסק לפי קטגוריה מסוימת ואזור גאוגרפי והמספר נמסר לפונה. אם מבוקש מספר הטלפון תוך ציון שם העסק במפורש אין חיוב על יחידת פרסום.
            </li>
          </ul>
          <IonButton fill="clear" onClick={() => setShowSelfSalePopup(!showSelfSalePopup)} style={{ textDecoration: "underline", display: 'block' }}>
            סגירה
          </IonButton>
        </div>
      </IonModal>
      {/* <CustomModal
        pageName={"home"}
        headerParameters={headerParameters}
        closeModal={() => setShowSelfSalePopup(false)}
        cssClass=""
        isOpen={showSelfSalePopup}
        title={""}
        subTitle={""}
      >
        <div>

        </div>
      </CustomModal> */}
      <WelcomeNewUser
        show={showModal}
        closeModal={() => {
          setShowModal(false);
        }}
      />
    </CustomPage>
  );
};
export default Home;
