import { toast } from "react-toastify";
import { fetchRequestNode } from "../utils-ts/fetchRequest";
import { config } from "../Constants";
import download from "js-file-download";

export interface Letter {
  fileName: string;
  updateDate: string;
  prodPackdesc: string;
  id: number;
  is_call_summary: string;
}

export const getUserLetters = () => {
  return new Promise<Letter[]>((resolve, reject) => {
    var data = new URLSearchParams({}).toString();

    fetchRequestNode(
      `${config.url.Node_API_URL}/lettersApi/getUserLetters`,
      "post",
      data
    )
      .then((response: any) => {
        var res = JSON.parse(response);
        if (res != null && res.data.length > 0) {
          resolve(res.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  });
};

export const DownloadLetter = (fileName: string,is_call_summary: string) => {
  var data = new URLSearchParams({
    fileName: encodeURI(fileName),
    callSummary: is_call_summary
  }).toString();
  fetchRequestNode(
    `${config.url.Node_API_URL}/lettersApi/DownloadLetter`,
    "post",
    data
  )
    .then((response: any) => {
      var res = JSON.parse(response);
      if (res.Success == "true") {
        const fileContents = new Buffer(res.data, "base64");
        download(fileContents, fileName);
      } else if (res.Message != "") {
        toast(res.Message);
      } else {
        toast("ארעה שגיאה");
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};
