import { IonButton, IonCard, IonCol, IonIcon, IonRow } from "@ionic/react";
import { shareSocialOutline } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import ProductStatisticTable from "../../components/Statistic/ProductStatisticTable";
import LineChart from "../../components/utils/Charts/LineChart";
import CustomModal from "../../components/utils/CustomElements/CustomModal/CustomModal";
import ImagePicker, {
  Photo,
} from "../../components/utils/ImagePicker/ImagePicker";
import {
  SaveFacebookAd,
  SendMail,
  SendSms,
} from "../../data/ProductStatsService";
import { validatePatterns } from "../../utils-ts/GlobalValidationFunctions";

interface Props {
  pageData?: any;
  tabs?: { he: string; en: string }[];
  tabNumber: number;
  children?: React.ReactNode | React.ReactNode[];
}

const FacebookStats = ({ pageData, tabs, tabNumber, children }: Props) => {
  const [performenceData, setPerformenceData] = useState<Map<number, any[]>>();
  const [performenceTabsNumber, setPerformenceTabsNumber] = useState(0);
  const [chartRadioButtonsNumber, setChartRadioButtonsNumber] = useState(0);
  const [imageRadioButtonsNumber, setImageRadioButtonsNumber] = useState(0);
  const [adImage, setAdImage] = useState<Photo>();
  const [bannerText, setBannerText] = useState({
    top: "",
    bottom: "",
  });

  const performenceTabs = ["ביצועים", "טקסטים", "מעורבות", "החלפת תמונות"];
  const radioButtonsText = [
    { he: "חשיפות", en: "Reached" },
    { he: "הקלקות", en: "Clicks" },
    { he: "הוצאות", en: "Spent" },
  ];

  const formatter = new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });

  const normalizeDataFunc = (items: any[], tabIndex: number) => {
    const itemsWithoutSum = items.slice(0, items.length - 2);
    let normalizedData: any[] = [];
    switch (tabIndex) {
      case 0:
        normalizedData = items.map((item: any, i) => ({
          מודעה: [items.length - 1, items.length - 2].includes(i) ? (
            ""
          ) : (
            <AdModal src={item.Url} iframe={item.Iframe} />
          ),
          "שם המודעה":
            items.length - 1 === i
              ? 'סה"כ פעילות בקמפיין'
              : items.length - 2 === i
              ? 'סה"כ מודעות פעילות בלבד'
              : item.Name,
          ניצול: item.Spent.toFixed(2),
          חשיפות: item.Impressions.toLocaleString("en-US"),
          אנשים: item.Reached.toLocaleString("en-US"),
          תדירות: item.Frequency.toFixed(2),
          קליקים: item.Clicks,
          CTR: item.CTR.toFixed(2),
          CPC: item.CPC.toFixed(2),
          איוונטים: item.Events.toLocaleString("en-US"),
          "יחס איוונטים": item.Events_Clicks.toLocaleString("en-US"),
          "עלות איוונט": item.Events_Spent.toLocaleString("en-US"),
        }));
        break;
      case 1:
        normalizedData = itemsWithoutSum.map((item: any) => ({
          שיתוף: <ShareModal link={item.link} />,
          מודעה: <AdModal src={item.Url} iframe={item.Iframe} />,
          "שם מודעה": item.Name,
          "טקסט עליון": item.LongText,
          "באנר על התמונה": item.BannerLine,
          כותרת: item.HeaderLine,
          "טקסט תחתון": item.FooterLine,
        }));
        break;
      case 2:
        normalizedData = itemsWithoutSum.map((item: any, i) => ({
          מודעה: <AdModal src={item.Url} iframe={item.Iframe} />,
          "שם מודעה": item.Name,
          'סה"כ מעורבות בפוסט': item.Post_engagement.toLocaleString("en-US"),
          תגובות: item.Post_shares.toLocaleString("en-US"),
          "רגשות בפוסט": item.Post_reaction.toLocaleString("en-US"),
        }));
        normalizedData.push({
          מודעה: "",
          "שם מודעה": 'סה"כ',
          'סה"כ מעורבות בפוסט': itemsWithoutSum
            .reduce((ac, b) => ac + b.Post_engagement, 0)
            .toLocaleString("en-US"),
          תגובות: itemsWithoutSum.reduce((ac, b) => ac + b.Post_shares, 0),
          "רגשות בפוסט": itemsWithoutSum
            .reduce((ac, b) => ac + b.Post_reaction, 0)
            .toLocaleString("en-US"),
        });
        break;
    }

    return normalizedData;
  };

  const isFbAdsPerformenceTab =
    tabs && tabs[tabNumber].en === "fbAdsPerformance";

    const _img_min_width = 1200;
    const _img_min_height = 1200;

  useEffect(() => {
    if (!pageData) setPerformenceData(undefined);
    if (
      !pageData ||
      !tabs ||
      !isFbAdsPerformenceTab ||
      (performenceData && !!performenceData.has(performenceTabsNumber))
    )
      return;
    const normalizedData = normalizeDataFunc(
      pageData[tabs[tabNumber].en],
      performenceTabsNumber
    );
    setPerformenceData((current) => {
      let update = current ? new Map(current) : new Map();
      update.set(performenceTabsNumber, normalizedData);
      return update;
    });
  }, [performenceTabsNumber, pageData, tabs]);

  const saveFbAd = async () => {
    
    if (!adImage){
      toast.error("חסרה תמונה");
      return;
    }

    
    const ad = pageData && tabs && pageData[tabs[tabNumber].en][imageRadioButtonsNumber];

      if (!ad || !ad.Ad_Id){
        toast.error("לא נמצאה מודעה");
        return;
      }
    
    const isSuccess = await SaveFacebookAd(
      bannerText.top || '',
      bannerText.bottom || '',
      ad.Ad_Id,
      adImage
    );

    isSuccess
      ? toast.info("המודעה נשמרה בהצלחה!")
      : toast.error("באמת שניסינו, אבל משהו השתבש. נסה שנית");
  };

  const performenceArr = performenceData?.get(0) ?? [];


  const handleFileUpload = (
    blob: Blob,
    callBack: (
      answer: string,
      { width, height }: { width: number; height: number }
    ) => void
  ) => {
    if (!blob) return callBack("יש לבחור תמונה", { width: 0, height: 0 }); // No file selected

    const reader = new FileReader();
    reader.onload = function (e) {
      const img = new Image();
      img.src = String(e.target!.result ?? ""); // Set the image source to the base64 data

      img.onload = function () {
        // Image dimensions
        const width = img.naturalWidth;
        const height = img.naturalHeight;

        // Check if it's a photo (you can adjust this condition as needed)
        const isPhoto = width > 0 && height > 0;
        if (isPhoto) {
          if (width < _img_min_width || height < _img_min_height) {
            console.log(`Image dimensions: ${width} x ${height}`);
            return callBack("יש לבחור תמונה בגודל מינימלי של " + _img_min_width + "x" + _img_min_height, {
              width,
              height,
            });
          }

          return callBack("", { width, height });
        } else {
          return callBack("תמונה לא תקינה", { width, height });
        }
      };
    };

    reader.onerror = function () {
      return callBack("תמונה לא תקינה", { width: 0, height: 0 });
    };

    reader.readAsDataURL(blob); // Read the file as base64 data
  };

  return (
    <>
      <IonCol size="12" className="card-wrapper">
        <IonCard mode="md" className="statistics-card">
          {children && children}
          {isFbAdsPerformenceTab && (
            <IonRow>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div className="fb-stat-cube-wrapper">
                  <div className="fb-stat-cube">
                    <div>
                      {performenceArr[performenceArr.length - 2]?.חשיפות ?? 0}
                      <br />
                      סה"כ חשיפות
                    </div>
                  </div>
                  <div className="fb-stat-cube">
                    <div>
                      {performenceArr[performenceArr.length - 2]?.קליקים ?? 0}
                      <br />
                      סה"כ הקלקות
                    </div>
                  </div>
                  <div className="fb-stat-cube">
                    <div>
                      {performenceArr[performenceArr.length - 1]?.ניצול ?? 0}
                      <br />
                      סה"כ הוצאות
                    </div>
                  </div>
                </div>
                <div className="radio-wrapper">
                  {radioButtonsText.map((value, index) => (
                    <label
                      htmlFor={`RadioButtonListDesg_${index}`}
                      key={`RadioButtonListDesg_${index}`}
                    >
                      <input
                        type="radio"
                        name={`RadioButtonListDesg_${index}`}
                        value={index}
                        checked={index === chartRadioButtonsNumber}
                        onChange={() => setChartRadioButtonsNumber(index)}
                      />
                      {value.he}
                    </label>
                  ))}
                </div>
                <div className="fb-stat-cube-wrapper">
                  <div className="fb-stat-cube graph">
                    {pageData && !!pageData["fbAdsChartPerformance"]?.length ? (
                      <div className="chart-container">
                        <LineChart
                          chartData={[
                            {
                              Key: "labels",
                              Value: pageData["fbAdsChartPerformance"].map(
                                (item: any) =>
                                  "     " +
                                  formatter.format(
                                    Number(
                                      item["Date"]
                                        .replaceAll("/", "")
                                        .replaceAll(")", "")
                                        .replace("Date(", "")
                                    )
                                  )
                              ),
                            },
                            {
                              Key: "values",
                              Value: pageData["fbAdsChartPerformance"].map(
                                (item: any) =>
                                  item[
                                    radioButtonsText[chartRadioButtonsNumber].en
                                  ]
                              ),
                            },
                          ]}
                        />
                      </div>
                    ) : (
                      "NO DATA"
                    )}
                  </div>
                </div>
              </div>
            </IonRow>
          )}
          <IonRow className="fb-stat-cube-wrapper">
            {isFbAdsPerformenceTab && (
              <div className="permence-tables">
                <div className="tab-headers">
                  <ul>
                    {performenceTabs.map((value, i) => (
                      <li
                        key={i}
                        className={
                          "tab_header" +
                          (i === performenceTabsNumber ? " active" : "")
                        }
                        onClick={() => setPerformenceTabsNumber(i)}
                      >
                        {value}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            {performenceTabsNumber !== 3 || !isFbAdsPerformenceTab ? (
              <ProductStatisticTable
                items={
                  pageData && tabs && !isFbAdsPerformenceTab
                    ? pageData[tabs[tabNumber].en]
                    : performenceData
                    ? performenceData.get(performenceTabsNumber)
                    : undefined
                }
                exportToExl={false}
                noDataElement={tabNumber === 1 && <p>אין פניות</p>}
              />
            ) : (
              isFbAdsPerformenceTab && (
                <IonRow className="image-replacement-tab">
                  <IonCol size="12" className="description">
                    איך מחליפים מודעות במערכת החדשה?
                    <br /> בוחרים את התמונה שרוצים להחליף, מעלים תמונה חדשה
                    לפרסום, מוסיפים טקסט לבאנר, כותרת וטקסט תחתון. משם המודעה
                    נשלחת לצוות הפיתוח שלנו שמוודא שהטקסטים והתמונות תקינים
                    ומעלה אותם לאוויר.
                    <br />
                    נ.ב – על מנת להחליף מודעה נוספת יש להתחיל את התהליך מחדש
                  </IonCol>
                  <IonCol size="12" sizeMd="4">
                    <ul className="images-list">
                      {pageData &&
                        tabs &&
                        pageData[tabs[tabNumber].en]
                          .slice(0, pageData[tabs[tabNumber].en].length - 2)
                          .map((item: any, index: number) => (
                            <li key={index}>
                              <input
                                type="radio"
                                checked={index === imageRadioButtonsNumber}
                                onChange={() =>
                                  setImageRadioButtonsNumber(index)
                                }
                              />
                              <img
                                src={item.Url}
                                alt="ad"
                                width={50}
                                height={50}
                              />
                            </li>
                          ))}
                    </ul>
                  </IonCol>
                  <IonCol size="12" sizeMd="6">
                    <ImagePicker
                      onImagePick={(photo) => setAdImage(photo)}
                      onValidate={handleFileUpload}
                      currentPhoto={adImage}
                      isMultiple={false}
                    />
                    <div>
                      <div className="image-input-group">
                        <label htmlFor="image-banner-top">באנר עליון</label>
                        <span className="input-counter">
                          <input
                            type="text"
                            maxLength={25}
                            value={bannerText.top}
                            onChange={(e) => {
                              const value = e.currentTarget.value;

                              setBannerText((current) => ({
                                ...current,
                                top: value,
                              }));
                            }}
                          />
                          <span>{`25 / ${bannerText.top.length}`}</span>
                        </span>
                      </div>
                      <div className="image-input-group">
                        <label htmlFor="image-banner-bottom">באנר תחתון</label>
                        <span className="input-counter">
                          <input
                            type="text"
                            maxLength={25}
                            value={bannerText.bottom}
                            onChange={(e) => {
                              const value = e.currentTarget.value;

                              setBannerText((current) => ({
                                ...current,
                                bottom: value,
                              }));
                            }}
                          />
                          <span>{`25 / ${bannerText.bottom.length}`}</span>
                        </span>
                      </div>
                      <div className="image-button-group">
                        <IonButton
                          color="danger"
                          onClick={() => {
                            setBannerText({
                              top: "",
                              bottom: "",
                            });
                            setAdImage({
                              runId: 1,
                              path: undefined,
                              preview: " ",
                              name: "",
                              size: 0,
                            });
                            setImageRadioButtonsNumber(0);
                          }}
                        >
                          בטל
                        </IonButton>
                        <IonButton onClick={saveFbAd}>שמור</IonButton>
                      </div>
                    </div>
                  </IonCol>
                </IonRow>
              )
            )}
          </IonRow>
        </IonCard>
      </IonCol>
    </>
  );
};

export default FacebookStats;

const AdModal = ({ src, iframe }: { src: string; iframe: string }) => {
  const [show, setShow] = useState(false);

  return (
    <div>
      <img
        src={src}
        alt="ad"
        width={50}
        height={50}
        style={{ marginTop: "2px", cursor: "pointer" }}
        title="לחץ עלי"
        onClick={() => setShow(true)}
      />
      <CustomModal
        closeModal={() => setShow(false)}
        isOpen={show}
        headerParameters={{
          modalType: "modal",
        }}
        title="תוכן המודעה"
        pageName="FacebookStat"
        cssClass="modal-stat"
      >
        <div
          dangerouslySetInnerHTML={{ __html: iframe }}
          style={{ display: "flex", justifyContent: "center" }}
        />
      </CustomModal>
    </div>
  );
};

const ShareModal = ({ link }: { link: string }) => {
  const [show, setShow] = useState(false);
  const [isPhoneInputValid, setIsPhoneInputValid] = useState(true);
  const [isEmailInputValid, setIsEmailInputValid] = useState(true);
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const phoneInputRef = useRef<HTMLInputElement>(null);
  const mailInputRef = useRef<HTMLInputElement>(null);

  const toastMessage = (isSuccess: boolean) => {
    isSuccess
      ? toast.info("המודעה נשלחה בהצלחה!")
      : toast.error("באמת שניסינו, אבל משהו השתבש. נסה שנית");
  };

  return (
    <>
      <IonButton
        onClick={() => setShow(true)}
        style={{
          width: "50px",
          borderRadius: "15px",
        }}
      >
        <IonIcon src={shareSocialOutline} style={{ width: 25, height: 25 }} />
      </IonButton>
      <CustomModal
        closeModal={() => setShow(false)}
        isOpen={show}
        headerParameters={{
          modalType: "modal",
        }}
        title="שיתוף מודעה"
        pageName="FacebookStat"
        cssClass="modal-share"
      >
        <div className="share-wrapper">
          <div className="share-item">
            <span>ליינק למודעה:</span>
            <input type="text" defaultValue={link} readOnly />
          </div>
          <IonButton
            onClick={() => {
              navigator.clipboard.writeText(link);
              setTimeout(() => setIsLinkCopied(true), 200);
            }}
          >
            {isLinkCopied ? "קישור הועתק" : "העתק קישור"}
          </IonButton>
          <div className="share-item">
            <IonButton
              onClick={() =>
                !!phoneInputRef.current?.value.length &&
                isPhoneInputValid &&
                SendSms(phoneInputRef.current.value, link).then(toastMessage)
              }
            >
              שתף בהודעה
            </IonButton>
            <input
              ref={phoneInputRef}
              type="text"
              minLength={10}
              maxLength={10}
              placeholder="הכנס מספר טלפון"
              onChange={(e) =>
                setIsPhoneInputValid(
                  !validatePatterns(e.target.value, "tel")?.length
                )
              }
            />
          </div>
          {!isPhoneInputValid && (
            <small className="error">*יש למלא מספר תקין</small>
          )}
          <div className="share-item">
            <IonButton
              onClick={() =>
                !!mailInputRef.current?.value.length &&
                isEmailInputValid &&
                SendMail(mailInputRef.current.value, link).then(toastMessage)
              }
            >
              שתף במייל
            </IonButton>
            <input
              ref={mailInputRef}
              type="email"
              placeholder="הכנס מייל"
              onChange={(e) =>
                setIsEmailInputValid(
                  !validatePatterns(e.target.value, "email")?.length
                )
              }
            />
          </div>
          {!isEmailInputValid && (
            <small className="error">*יש למלא מייל תקין</small>
          )}
        </div>
      </CustomModal>
    </>
  );
};
