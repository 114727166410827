import React from "react";
import { FieldError, NestDataObject } from "react-hook-form";
import { validateForm } from "../../../utils-ts/GlobalValidationFunctions";

const CustomForm: React.FC<{
  onSubmit: () => void;
  errors: NestDataObject<Record<string, any>, FieldError>;
  customValidateForm?: () => boolean;
}> = (props) => {
  return (
    <form
      noValidate
      onSubmitCapture={(e) => {
        validateForm(props.errors);
        if(props.customValidateForm){
          props.customValidateForm();
        }
      }}
      onSubmit={props.onSubmit}
    >
      {props.children}
    </form>
  );
};

export default CustomForm;
