import React, { useContext, useEffect, useRef, useState } from "react";
import { IonGrid, IonRow, IonCol, IonList, isPlatform } from "@ionic/react";
import MemberDetailsContext, {
  BusinessLanguages,
} from "../../../data/MemberDetailes-context";
import CustomPublishButton from "../../utils/CustomElements/CustomPublishButton";
import CustomForm from "../../utils/CustomElements/CustomForm";
import { useForm } from "react-hook-form";
import CustomModal from "../../utils/CustomElements/CustomModal/CustomModal";
import { BusinessLanguagesHebrew } from "../../../utils-ts/BusinessCardFunc";
import { HeaderParameters } from "../../../data/Header-Context";
import CustomToggle from "../../utils/CustomElements/CustomToggle";
const BusinessLanguage: React.FC<{
  show: boolean;
  closeModal: () => void;
  onSave: () => void;
}> = (props) => {
  const MemberDetailsCxt = useContext(MemberDetailsContext);
  const { handleSubmit, formState, errors } = useForm({
    mode: "onChange",
  });
  const { dirty } = formState;
  const [isPageChange, setIsPageChange] = useState(false);

  const [currentLanguages, setCurrentLanguages] = useState<
    BusinessLanguages | undefined
  >();
  const [originalLanguages, setOriginalLanguages] = useState<
    BusinessLanguages | undefined
  >();

  useEffect(() => {
    if (MemberDetailsCxt.BusinessLanguages == null) {
      MemberDetailsCxt.initMemberDetailsContext();
    }
  }, []);

  useEffect(() => {
    if (MemberDetailsCxt.BusinessLanguages) {
      setCurrentLanguages({ ...MemberDetailsCxt.BusinessLanguages });
      setOriginalLanguages({ ...MemberDetailsCxt.BusinessLanguages });
    }
  }, [MemberDetailsCxt.BusinessLanguages]);

  const checkIfPageDirty = (newLanguage: BusinessLanguages) => {
    if (JSON.stringify(originalLanguages) !== JSON.stringify(newLanguage)) {
      setIsPageChange(true);
    } else {
      setIsPageChange(false);
    }
  };

  const setCheckedForLang = (isCheck: boolean, key: string) => {
    let newLanguage = { ...currentLanguages! };
    if (key == "Arabic") {
      newLanguage!.Arabic = isCheck;
    } else if (key == "Chinese") {
      newLanguage!.Chinese = isCheck;
    } else if (key == "English") {
      newLanguage!.English = isCheck;
    } else if (key == "France") {
      newLanguage!.France = isCheck;
    } else if (key == "German") {
      newLanguage!.German = isCheck;
    } else if (key == "Hebrew") {
      newLanguage!.Hebrew = isCheck;
    } else if (key == "Italian") {
      newLanguage!.Italian = isCheck;
    } else if (key == "Portuguese") {
      newLanguage!.Portuguese = isCheck;
    } else if (key == "Romanian") {
      newLanguage!.Romanian = isCheck;
    } else if (key == "Russian") {
      newLanguage!.Russian = isCheck;
    } else if (key == "Spanish") {
      newLanguage!.Spanish = isCheck;
    } else if (key == "Bulgarian") { 
      newLanguage!.Bulgarian = isCheck;
    }else if (key == "Hungarian") {
      newLanguage!.Hungarian = isCheck;
    }else if (key == "Turkish") {
      newLanguage!.Turkish = isCheck;
    }else if (key == "Polish") {
      newLanguage!.Polish = isCheck;
    }else if (key == "Ukrainian") {
      newLanguage!.Ukrainian = isCheck;
    }else if (key == "Japanese") {
      newLanguage!.Japanese = isCheck;
    }else if (key == "Yiddish") {
      newLanguage!.Yiddish = isCheck;
    }else if (key == "Amharic") {
      newLanguage!.Amharic = isCheck;
    }
    setCurrentLanguages(newLanguage!);
    checkIfPageDirty(newLanguage);
  };

  const saveHandler = () => {
    MemberDetailsCxt.saveMainDetails(
      "Languages",
      MemberDetailsCxt.AdvCategories,
      currentLanguages!,
      //MemberDetailsCxt.OpenDays,
      //MemberDetailsCxt.DaysRemarks,
      MemberDetailsCxt.GeneralInfo,
      MemberDetailsCxt.Description
    );
    props.onSave();
    props.closeModal();
  };

  const headerParameters: HeaderParameters = {
    isPageHaveChanges: dirty || isPageChange,
    modalType: "modal",
  };
  return (
    <CustomModal
      pageName={"BusinessLanguage"}
      closeModal={() => {
        props.closeModal();
      }}
      headerParameters={headerParameters}
      isOpen={props.show}
      title="שפות"
      subTitle="ללקוחות פוטנציאלים חשוב לדעת האם בית העסק נגיש ובאיזו שפות מקובל לדבר בו. ייתכן והעסק שלך פונה לקהלים נוספים, תיירים ולכן כאן קיימת האופציה להזין את השפות הרלוונטיות לעסק. וכן, גם רק עברית זה לגמרי בסדר"
    >
      <CustomForm onSubmit={handleSubmit(saveHandler)} errors={errors}>
        {currentLanguages && (
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonList>
                  {Object.entries(currentLanguages).map(([key, value]) => {
                    return (
                      <CustomToggle
                        lines="full"
                        key={key}
                        titleTxt={BusinessLanguagesHebrew[key]}
                        checked={value}
                        onIonChange={(e) => {
                          setCheckedForLang(e.detail.checked, key);
                        }}
                      ></CustomToggle>
                    );
                  })}
                </IonList>
              </IonCol>
            </IonRow>

            <IonRow className="ion-text-center">
              <IonCol>
                <CustomPublishButton></CustomPublishButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </CustomForm>
    </CustomModal>
  );
};

export default BusinessLanguage;
