import React, { useContext, useEffect, useState } from "react";
import {
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
} from "@ionic/react";


import { Control } from "react-hook-form";



const VasContactHoursSelect: React.FC<{
    control: Control;
    register: React.Ref<any>;
}> = props => {



    const [contactHoursList, setContactHoursList] = useState<string[]>([
        'שעות הבוקר'
        , 'אחרי הצהרים'
        , 'שעות הערב'
    ]);

    useEffect(() => {

        if (props.control &&
            props.control.fieldsRef &&
            props.control.fieldsRef!.current) {
            
            //set default value
            if (props.control.fieldsRef!.current!.contactHours &&
                props.control.fieldsRef!.current!.contactHours.ref.value == undefined) {
                props.control.setValue('contactHours', contactHoursList[0]);
            }
        }

    });

    return (
        <React.Fragment>
            <IonItem color='light'>
                <IonLabel position="stacked">שעות נוחות להתקשרות</IonLabel>
                <IonSelect
                    color="light"
                    cancelText="ביטול"
                    okText="אישור"
                    name='contactHours'
                    ref={props.register}
                    //value={contactHoursList[0]}
                    placeholder="בחר שעות נוחות להתקשרות" >
                    {
                        contactHoursList.map((t, i) => {
                            return (
                                <IonSelectOption key={i} value={t}>
                                    {t}
                                </IonSelectOption>
                            );
                        })}
                </IonSelect>
            </IonItem>
        </React.Fragment>
    );
};

export default VasContactHoursSelect;