import React, { useContext, useEffect, useState } from "react";
import { IonGrid, IonRow, IonCol, IonImg,IonRadioGroup, IonItem, IonLabel, IonRadio,IonListHeader, IonButton, IonIcon } from "@ionic/react";

import ProductsContext, { iProduct } from "../../../data/Products-context";
import AddEditProduct from "./AddEditProduct";
import "../businessCardTabs.scss";
import CustomMultiChoice, {
  ChooseAll,
  SetMultiSelectListner,
} from "../../utils/CustomElements/CustomMultiChoice";
import { blankProductIcon, imagePlaceholder, vas_ecommerce_exist } from "../../../images/index";
import MemberDetailsContext, { productGalleryTypes } from "../../../data/MemberDetailes-context";
import { generateExternalLinkWithToken } from "../../../data/GlobalService";

const B144Products: React.FC<{
  isRunDelete: boolean;
  isCancelMultiChoice: boolean;
  isAddProductClick: boolean;
  onIsAddProductClick: () => void;
  onDelete: () => void;
  changeHeader: (
    isShowDeleteButton: boolean,
    numOfMultiSelected: number | undefined,
    isShowAddButton: boolean
  ) => void;
}> = (props) => {
  const ProductsCtx = useContext(ProductsContext);
  
  const [isAddEditing, setIsEditing] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState<
    iProduct | undefined
  >();
  const [isMultiChoise, setIsMultiChoise] = useState(false);
  const [chechedElements, setChechedElements] = useState<string[]>([]);
  

  const MemberDetailsCxt = useContext(MemberDetailsContext);
  const [tempGalleryType, setTempGalleryType] = useState<productGalleryTypes>(MemberDetailsCxt.ProductGalleryType);

  useEffect(() => {
    if (props.isRunDelete) {
      props.onDelete();
      ProductsCtx.deletedProductsHandler(chechedElements);
    }
    if (props.isCancelMultiChoice) {
      cancelMuliChoice();
    }
  });
  useEffect(() => {
    if (ProductsCtx.Products == null) {
      ProductsCtx.initProductsContext(true);
    }
  }, []);

  useEffect(() => {
    if (props.isAddProductClick === true) {
      setIsEditing(true);
      props.onIsAddProductClick();
    }
  }, [props.isAddProductClick]);

  useEffect(() => {
    props.changeHeader(false, 0, MemberDetailsCxt.ProductGalleryType !== 'eCommerce');
    setTempGalleryType(MemberDetailsCxt.ProductGalleryType);
    //reset
    setIsMultiChoise(false);
    setSelectedProduct(undefined);
    setChechedElements([]);
    setIsEditing(false);
  }, [MemberDetailsCxt.ProductGalleryType]);

  const onGalleryTypeChange_callback = (event: CustomEvent) => {
    
    setTempGalleryType(event.detail.value);
    MemberDetailsCxt.updateProductsGalleryType(event.detail.value);
   
  };

const openEcommerceProductsEdit = () => {

  generateExternalLinkWithToken().then((data) => {
    const ex_url = data  + '1115';
    window.open(ex_url,'_blank')
  });

};

  const cancelMuliChoice = () => {
    setIsMultiChoise(false);
    setChechedElements([]);
    props.changeHeader(false, undefined, true);
  };
  const saveProductHandler = (product: iProduct) => {
    ProductsCtx.uploadProduct(product);
    
    dtlPushEvt({'event':'products','eventInfo': {'ScreenName':'{{page path}}','user_action':'adds_product',
    'business_name': '' + (MemberDetailsCxt.GeneralInfo  ? MemberDetailsCxt.GeneralInfo?.CompanyName : ""),
    'business_category': GetMemberAdvCategories(MemberDetailsCxt.AdvCategories),
    'business_location': MemberDetailsCxt.BusinessAddress?.City}});

    setIsEditing(false);
    setSelectedProduct(undefined);
  };

  const getAllElementsId = () => {
    let idsStr: string[] = [];
    if (ProductsCtx.Products) {
      ProductsCtx.Products.map((p) => {
        idsStr.push(p.Id.toString());
      });
    }
    return idsStr;
  };

  return (
    <React.Fragment>
      {isAddEditing && (
        <AddEditProduct
          product={selectedProduct}
          show={isAddEditing}
          onDelete={(productid: string) => {
            ProductsCtx.deletedProductsHandler([productid]);
            setIsEditing(false);
            setSelectedProduct(undefined);
          }}
          closeModal={() => {
            setIsEditing(false);
            setSelectedProduct(undefined);
          }}
          onSave={saveProductHandler}
        ></AddEditProduct>
      )}

      <IonGrid>
        <IonRow>
          <IonCol className="ion-padding-bottom">
            <p className="small-p">
              המחקרים שלנו מראים כי דף עסק המכיל גלריה עשירה של מוצרים מגדיל
              את הסיכוי שלך לקבל פניות מלקוחות פוטנציאלים
            </p>
          </IonCol>
        </IonRow>
        {MemberDetailsCxt.ShowProductGalleryTypes && 
        <IonRow>
              <IonCol className="ion-no-padding">
                <IonRadioGroup name="ServiceAreas" onIonChange={onGalleryTypeChange_callback} value={tempGalleryType}>  {/* tempGalleryType */}
                  <IonListHeader>
                    <IonLabel>בחר את סוג גלריית מוצרים</IonLabel>
                  </IonListHeader>
                  <IonRow>
                    <IonCol size="6" sizeMd="6" className="ion-no-padding">
                      <IonItem mode="md" lines="none" className="item-radio">
                        <IonLabel className="item-radio-label">בחירת מוצרים מהחנות</IonLabel>
                        <IonRadio mode="md" slot="start" className="item-radio-btn" value="eCommerce" />
                      </IonItem>
                    </IonCol>
                    <IonCol size="6" sizeMd="6" className="ion-no-padding">
                      <IonItem mode="md" lines="none" className="item-radio">
                        <IonLabel>הוספת מוצרים שלא מהחנות</IonLabel>
                        <IonRadio mode="md" slot="start" className="item-radio-btn" value="default" />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                </IonRadioGroup>
              </IonCol>
            </IonRow>
            
            }
             
        {MemberDetailsCxt.ShowProductGalleryTypes && MemberDetailsCxt.ProductGalleryType == 'eCommerce' ? 
       <div className="ion-text-center">
       <IonButton  className="main-button" onClick={openEcommerceProductsEdit}>
          עבור לבחירת מוצרים מהחנות
          <IonIcon slot="end" size="big" icon={vas_ecommerce_exist} />
        </IonButton></div> : 
        <>
         {ProductsCtx.Products && ProductsCtx.Products.length === 0 && (
          <IonRow>
            <IonCol className="ion-text-center no-items-wraper">
              <IonImg src={blankProductIcon}></IonImg>
              <p className="large-p">אין מוצרים</p>
            </IonCol>
          </IonRow>
        )}
        <IonRow>
          <IonCol>
            <ChooseAll
              show={isMultiChoise}
              onChooseAll={(checked: boolean) => {
                if (checked) {
                  const allItemArray = getAllElementsId();
                  setChechedElements(allItemArray);
                  props.changeHeader(true, allItemArray.length,true);
                } else {
                  cancelMuliChoice();
                }
              }}
            ></ChooseAll>
          </IonCol>
        </IonRow>
        <IonRow>
          {ProductsCtx.Products &&
            ProductsCtx.Products.map((product, index) => {
              return (
                <IonCol
                  key={index}
                  size="6"
                  sizeMd="3"
                  className="ion-margin-bottom ion-no-padding"
                >
                  <CustomMultiChoice
                    show={isMultiChoise}
                    checkedElements={chechedElements}
                    elementId={product.Id}
                    onChange={(checkedElements: string[]) => {
                      setChechedElements(checkedElements);
                      if (checkedElements.length > 0) {
                        props.changeHeader(true, checkedElements.length, true);
                      } else {
                        cancelMuliChoice();
                      }
                    }}
                    onTap={() => {
                      setSelectedProduct(product);
                      setIsEditing(true);
                      cancelMuliChoice();
                    }}
                    onPress={() => {
                      setIsMultiChoise(true);
                      props.changeHeader(false, 0, true);
                      SetMultiSelectListner(true, () => {
                        cancelMuliChoice();
                      });
                    }}
                  >
                    <div className="image-container">
                      {chechedElements.find((id) => id == product.Id) && (
                        <div className="selected-image-overlay"></div>
                      )}
                      <IonImg src={product.ImagePath || imagePlaceholder} />
                      <div className="image-desc">
                        <p className="bold-text text-wrap white-text text-shadow">
                          {product.Name}
                        </p>
                      </div>
                    </div>
                  </CustomMultiChoice>
                </IonCol>
              );
            })}
        </IonRow>
        </>}
       
       
      </IonGrid>
    </React.Fragment>
  );
};

export default B144Products;
