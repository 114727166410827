import React, { createRef, useRef, useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonCheckbox,
  IonLabel,
} from "@ionic/react";
import { isMobile } from "react-device-detect";
import { iProduct } from "../../../data/Products-context";
import ImagePicker, { Photo } from "../../utils/ImagePicker/ImagePicker";
import CustomInput from "../../utils/CustomElements/CustomInput";
import { useForm } from "react-hook-form";
import { base64FromPath } from "@ionic/react-hooks/filesystem";
import CustomForm from "../../utils/CustomElements/CustomForm";
import CustomPublishButton from "../../utils/CustomElements/CustomPublishButton";
import CustomModal from "../../utils/CustomElements/CustomModal/CustomModal";
import { HeaderParameters } from "../../../data/Header-Context";
import { toast } from "react-toastify";

const AddEditProduct: React.FC<{
  show: boolean;
  product: iProduct | undefined;
  closeModal: () => void;
  onSave: (product: iProduct) => void;
  onDelete: (productid: string) => void;
}> = (props) => {
  const { handleSubmit, formState, errors, control } = useForm({
    mode: "onChange",
  });
  const { dirty } = formState;
  const [isPhotoChange, setIsPhotoChange] = useState(false);
  const [isPageChange, setisPageChange] = useState(false);
  const productNameTextRef = useRef<HTMLIonInputElement>(null);
  const productCatNumberTextRef = useRef<HTMLIonInputElement>(null);
  const productDescriptionTextRef = useRef<HTMLIonInputElement>(null);
  const productLinkTextRef = useRef<HTMLIonInputElement>(null);
  const productPriceTextRef = useRef<HTMLIonInputElement>(null);

  const customBtnDescoptions = [
    "הזמינו אונליין",
    "למדו עוד",
    "קנו",
    "קבלו הצעה",
  ];

  const [CurrentProduct, setCurrentProduct] = useState(() => {
    if (props.product != undefined) {
      return { ...props.product };
    } else {
      const iproduct: iProduct = {
        Base64Image: "",
        Chosen: "false",
        FileName: "",
        CatNumber: "",
        CurrencySign: "ש''ח",
        FileId: "",
        Id: "",
        ImagePath: "",
        Name: "",
        Price: "",
        Description: "",
        LongDescription: "",
        LinkButtonText: "",
      };
      return iproduct;
    }
  });

  const [takenPhoto, setTakenPhoto] = useState(() => {
    if (props.product != undefined) {
      const photo: Photo = {
        path: props.product.ImagePath,
        preview: props.product.ImagePath,
      };
      return photo;
    }
  });

  const saveHandler = () => {
    if (CurrentProduct.Base64Image === "") {
      toast.warning("לא נבחרה תמונה");
      return;
    }

    const productNameText = productNameTextRef.current!.value;
    const productCatNumberText = productCatNumberTextRef.current!.value;
    const productDescriptionText = productDescriptionTextRef.current!.value;
    const productPriceText = productPriceTextRef.current!.value;

    const productLink = productLinkTextRef.current!.value;

    CurrentProduct!.LongDescription = productLink!.toString();
    CurrentProduct!.Name = productNameText!.toString();
    CurrentProduct!.CatNumber = productCatNumberText!.toString();
    CurrentProduct!.Description = productDescriptionText!.toString();
    CurrentProduct!.Price = productPriceText!.toString();

    props.onSave(CurrentProduct!);
  };

  const imagePickHandler = async (photo: Photo) => {
    if (photo) {
      setTakenPhoto(photo);
      setIsPhotoChange(true);
      const base64 = await base64FromPath(photo!.preview);
      CurrentProduct!.Base64Image = base64;
    } else {
      setIsPhotoChange(false);
    }
  };
  let isBtnFocuse = false;
  const headerParameters: HeaderParameters = {
    isPageHaveChanges: dirty || isPhotoChange || isPageChange,
    modalType: "modal",
    isShowLogo: false,
    isShowDeleteButton: props.product != undefined,
    deleteFunc: () => {
      props.onDelete(props.product!.Id);
    },
  };

  return (
    <CustomModal
      pageName={"AddEditProduct"}
      headerParameters={headerParameters}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
      title={"איזה מוצר תעלה?"}
      subTitle={"תיאור טוב לתמונה יגדיל את החשיפה שלה ונותן לך יתרון שיווקי."}
    >
      <CustomForm onSubmit={handleSubmit(saveHandler)} errors={errors}>
        <IonGrid className="ion-no-padding ion-padding-horizontal">
          <IonRow className="ion-justify-content-between">
            {/* start - column right (picture) desktop mode  */}
            <IonCol
              size="12"
              sizeMd="5.9"
              style={{ order: !isMobile ? "2" : "" }}
            >
              <IonRow className="ion-text-center">
                <IonCol className="ion-margin-bottom">
                  <ImagePicker
                    onImagePick={imagePickHandler}
                    currentPhoto={takenPhoto}
                    showPreview={true} isMultiple={false}
                  />
                </IonCol>
              </IonRow>
            </IonCol>

            {/* start - column left (input fields) desktop mode  */}

            <IonCol className="ion-no-margin" size="12" sizeMd="5.6">
              <IonGrid>
                <IonRow>
                  <IonCol size="12">
                    <CustomInput
                      name="productName"
                      errors={errors}
                      control={control}
                      label="שם מוצר"
                      ref={productNameTextRef}
                      value={CurrentProduct?.Name}
                      required={true}
                      maxlength={50}
                    ></CustomInput>
                  </IonCol>
                  <IonCol size="12">
                    <CustomInput
                      name="productCatNumber"
                      errors={errors}
                      control={control}
                      label="מק''ט"
                      ref={productCatNumberTextRef}
                      value={CurrentProduct?.CatNumber}
                      maxlength={1000}
                    ></CustomInput>
                  </IonCol>
                  <IonCol size="12">
                    <CustomInput
                      name="productDescription"
                      errors={errors}
                      control={control}
                      label="תיאור המוצר"
                      ref={productDescriptionTextRef}
                      value={CurrentProduct?.Description}
                      maxlength={1000}
                    ></CustomInput>
                  </IonCol>
                  <IonCol size="12">
                    <CustomInput
                      name="productPrice"
                      errors={errors}
                      control={control}
                      label="מחיר"
                      ref={productPriceTextRef}
                      value={CurrentProduct?.Price}
                      maxlength={8}
                      max={"8"}
                      type="number"
                    ></CustomInput>
                  </IonCol>

                  <IonCol className="ion-padding-vertical">
                    {customBtnDescoptions.map((b, index) => {
                      const isChecked =
                        b === CurrentProduct!.LinkButtonText ? true : false;
                      return (
                        <IonItem
                          key={index}
                          className="toggle-token"
                          mode="ios"
                        >
                          <IonCheckbox
                            checked={isChecked}
                            onIonFocus={(e) => {
                              isBtnFocuse = true;
                            }}
                            className="ion-no-margin"
                            onIonChange={(e) => {
                              if (isBtnFocuse) {
                                isBtnFocuse = false;
                                const newText = e.detail.checked ? b : "";
                                let newObj = { ...CurrentProduct };
                                newObj.LinkButtonText = newText;
                                setCurrentProduct(newObj);
                                if (
                                  JSON.stringify(newObj) !==
                                  JSON.stringify(props.product)
                                ) {
                                  setisPageChange(true);
                                } else {
                                  setisPageChange(false);
                                }
                              }
                            }}
                          />
                          <IonLabel className="ion-no-margin">
                            <p>{b}</p>
                          </IonLabel>
                        </IonItem>
                      );
                    })}
                  </IonCol>

                  <IonCol size="12">
                    <CustomInput
                      name="producLink"
                      errors={errors}
                      control={control}
                      label="הוספת לינק לאתר"
                      ref={productLinkTextRef}
                      value={CurrentProduct?.LongDescription}
                      type="url"
                    ></CustomInput>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>

          {/* start - action button (align to left) desktop mode  */}

          <IonRow className="ion-justify-content-end">
            <IonCol size="12" sizeMd="5.9">
              <CustomPublishButton></CustomPublishButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </CustomForm>
    </CustomModal>
  );
};

export default AddEditProduct;
