import React, { useState, useContext } from "react";

import VasContext, {
  VasProduct,
  selectOption,
  productInfoItem,
  DigitalProductField,
  VasProductDetailed,
  webSiteReq,
  ecommerceReq,
  digitalProductReq,
  landingPageReq,
} from "./Vas-context";

import { autocompleteOption } from "../components/utils/CustomElements/CustomAutocomplete";

import { config } from "../Constants";
//import CustomLoader from "../components/utils/CustomElements/CustomLoader";
import GlobalContext from "./Global-Context";
import { fetchRequest, fetchRequestNode } from "../utils-ts/fetchRequest";

import { toast } from "react-toastify";
import {
  vas,
  vas_ecommerce_exist,
  vas_ecommerce_select,
  vas_facebook_exist,
  vas_facebook_select,
  vas_google_exist,
  vas_google_select,
  vas_landing_page_exist,
  vas_landing_page_select,
  vas_website_exist,
  vas_website_select,
} from "../images";

const parseString = require("xml2js").parseString;

const VasContextProvider: React.FC = (props) => {
 // const [loading, setLoading] = useState<boolean>(false);
  const globalCtx = useContext(GlobalContext);
 const setLoading = (loading:boolean)=>{
  globalCtx.setLoader(loading);
 };

  const [FacebookCategories, setFacebookCategories] = useState<
    autocompleteOption[] | null
  >(null);

  //VAS MAIN
  const getVasProducts = (useLoading: boolean) => {
    useLoading && setLoading(useLoading);
    return new Promise((resulve) => {
      var data = new URLSearchParams({}).toString();

      fetchRequestNode(
        `${config.url.Node_API_URL}/selfService/getSelfServiceVasOptions`,
        "post",
        data
      )
        .then((response: any) => {
          var res: Array<any> = JSON.parse(response);
          if (res != null) {
            const prod_arr: VasProduct[] = res.map((obj) => {
              let icon_select_vas = vas;
              let icon_my_vas = vas;
              switch (obj["prod_code"]) {
                case 1:
                  icon_select_vas = vas_website_select;
                  icon_my_vas = vas_website_exist;
                  break;
                case 12:
                  icon_select_vas = vas_ecommerce_select;
                  icon_my_vas = vas_ecommerce_exist;
                  break;
                case 20:
                  icon_select_vas = vas_landing_page_select;
                  icon_my_vas = vas_landing_page_exist;
                  break;
                case 21:
                case 18:
                  icon_select_vas = vas_facebook_select;
                  icon_my_vas = vas_facebook_exist;
                  
                  break;
                case 4:
                case 24:
                  icon_select_vas = vas_google_select;
                  icon_my_vas = vas_google_exist;
                  break;
              }
              
              const rObj: VasProduct = {
                prod_code: obj["prod_code"],
                title: obj["prod_name"],
                allow_purchase: obj["show"],
                icon_select_vas: icon_select_vas,
                icon_my_vas: icon_my_vas,
              };
              return rObj;
            });
            resulve(prod_arr);
          }
          useLoading && setLoading(false);
        })
        .catch(function (error) {
          useLoading && setLoading(false);
          console.log(error);
        });
    });
  };

  //VAS INFO
  const initVasProductInfoContext = (prod_code: number) => {
    setLoading(true);
    return new Promise((resulve) => {
      getProductInfo(prod_code, true).then((prod_info: any) => {
        if (!!prod_info) {
          const prod_detailed: VasProductDetailed = {
            produuctInfo: prod_info,
          };
          resulve(prod_detailed);
          setLoading(false);
          return;
        } else {
          resulve(null);
          setLoading(false);
        }
      });
    });
  };
  // const getVasProductByName: any = (prod_name: string) => {
  //     return new Promise((resolve) => {

  //         if (VasProducts != null) {
  //             const prod = VasProducts.find(p => { return p.title == prod_name });
  //             if (prod != null) {
  //                 resolve(prod.prod_code);
  //                 return;
  //             }
  //         }

  //         var data = new URLSearchParams({}).toString();

  //         fetchRequestNode(
  //             `${config.url.Node_API_URL}/selfService/getSelfServiceVasOptions`,
  //             "post",
  //             data
  //         )
  //             .then((response: any) => {
  //                 var res: Array<any> = JSON.parse(response);
  //                 if (res != null) {

  //                     const prod = res.find(p => { return p.prod_name == prod_name });
  //                     if (prod) {

  //                         resolve(prod.prod_code);
  //                     } else {
  //                         resolve(null);
  //                     }

  //                 }

  //             })
  //             .catch(function (error) {
  //                 resolve(null);
  //                 console.log(error);
  //             });
  //     });
  // };
  const getProductInfo: any = async (
    prod_code: number,
    useLoading: boolean
  ) => {
    useLoading && setLoading(useLoading);
    return new Promise((resolve) => {
      var data = new URLSearchParams({
        prodmain: prod_code.toString(),
      }).toString();

      fetchRequestNode(
        `${config.url.Node_API_URL}/selfService/getVasInfoByProdCode`,
        "post",
        data
      )
        .then((response: any) => {
          var res: Array<any> = JSON.parse(response);
          if (res != null) {
            const pack_arr: productInfoItem[] = res.map((obj) => {
              return {
                pageNumber: parseInt(obj.pageNo) | 0,
                text: obj.page_desc,
              };
            });
            resolve(pack_arr);
          }
          useLoading && setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          useLoading && setLoading(false);
        });
    });
  };

  //FORMS DATA
  const initDigitalProductFields = (prod_code: number, useLoading: boolean) => {
    useLoading && setLoading(useLoading);
    return new Promise((resolve) => {
      var data = new URLSearchParams({
        prodmain: prod_code.toString(),
      }).toString();

      fetchRequestNode(
        `${config.url.Node_API_URL}/selfService/getDigitalProductsFieldDefs`,
        "post",
        data
      )
        .then((response: any) => {
          var res: Array<any> = JSON.parse(response);
          if (res != null) {
            const field_arr: DigitalProductField[] = res.map((obj) => {
              return {
                fldCaption: obj.FldCaption,
                fldKey: obj.FldKey,
                fldNumber: obj.FldNumber,
                fldType: obj.FldType,
                fldLength: obj.FldLength,
                isMandtory: obj.IsMandtory,
                minValue: obj.MinValue,
                maxValue: obj.MaxValue,
                sortOrder: obj.SortOrder,
                defaultValue: obj.DefaultValue,
                //fldParams: obj.fldParams,
                fieldDesc: obj.FieldDesc,
              };
            });

            if (
              field_arr.filter(
                (f) =>
                  f.fldType == 11 &&
                  f.fldKey.toLowerCase() == "facebookcategory"
              ).length > 0
            ) {
              if (FacebookCategories == null) {
                initFacebookCategories(false);
              }
            }

            resolve(field_arr);
          }
          useLoading && setLoading(false);
        })
        .catch(function (error) {
          useLoading && setLoading(false);
          console.log(error);
        });
    });
  };

  const initFacebookCategories = (useLoading: boolean) => {
    useLoading && setLoading(useLoading);

    var data = new URLSearchParams({}).toString();

    fetchRequest(
      `${config.url.API_URL}/AutocompleteFB.asmx/GetFacebookCategories`,
      "post",
      data
    )
      .then((response) => {
        useLoading && setLoading(false);
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              setFacebookCategories(JSON.parse(res.Response));
            } else {
              toast.error(res.ErrorMessage);
            }
          }
        });
      })
      .catch(function (error) {
        useLoading && setLoading(false);
        console.log(error);
      });
  };

  const getPackagesByProdCode = async (
    prod_code: number,
    useLoading: boolean
  ) => {
    useLoading && setLoading(useLoading);
    return new Promise((resolve) => {
      var data = new URLSearchParams({
        prodmain: prod_code.toString(),
      }).toString();

      fetchRequestNode(
        `${config.url.Node_API_URL}/selfService/getPackagesByProdCode`,
        "post",
        data
      )
        .then((response: any) => {
          var res: Array<any> = JSON.parse(response);
          if (res != null) {
            const pack_arr: selectOption[] = res.map((obj) => {
              return {
                value: obj.prodsub_code,
                text: obj.prodsub_desc,
              };
            });
            resolve(pack_arr);
          }

          useLoading && setLoading(false);
        })
        .catch(function (error) {
          useLoading && setLoading(false);
          console.log(error);
        });
    });
  };

  const getLandingPageGoalsList = () => {
    return new Promise((resolve) => {
      var data = new URLSearchParams().toString();

      fetchRequestNode(
        `${config.url.Node_API_URL}/selfService/getLandingPageGoal`,
        "post",
        data
      )
        .then((response: any) => {
          var res: Array<any> = JSON.parse(response);
          if (res != null) {
            const goal_arr: selectOption[] = res.map((obj) => {
              return {
                value: obj.goal_id,
                text: obj.goal_Desc,
              };
            });
            resolve(goal_arr);
          }

          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
    });
  };

  const mainVasError = () => {
    toast.error("אופס, קרתה תקלה.יש לפנות למוקד השרות שלנו לסיום התהליך.", {
      delay: 3000,
    });
  };
  //SAVE
  const insertLandingPage = async (
    useLoading: boolean,
    lPageReq: landingPageReq
  ) => {
    return new Promise((resolve) => {
      useLoading && setLoading(useLoading);
      var data = new URLSearchParams({
        reqD: JSON.stringify(lPageReq),
      }).toString();

      fetchRequestNode(
        `${config.url.Node_API_URL}/selfService/insertLandingPage`,
        "post",
        data
      )
        .then((response: any) => {
          useLoading && setLoading(false);
          var res = JSON.parse(response);
          if (res && res.success) {
            // toast.error('הואס התווסף');
          } else {
            mainVasError();
          }
          resolve(res);
        })
        .catch(function (error) {
          useLoading && setLoading(false);
          console.log(error);
          //toast.error('ארעה שגיאה');
          mainVasError();
          resolve(null);
        });
    });
  };
  const insertDigitalProduct = async (
    useLoading: boolean,
    dProReq: digitalProductReq
  ) => {
    return new Promise((resolve) => {
      useLoading && setLoading(useLoading);
      var data = new URLSearchParams({
        reqD: JSON.stringify(dProReq),
      }).toString();

      fetchRequestNode(
        `${config.url.Node_API_URL}/selfService/insertDigitalProduct`,
        "post",
        data
      )
        .then((response: any) => {
          useLoading && setLoading(false);
          var res = JSON.parse(response);
          if (res && res.success) {
            toast.success("הואס התווסף");
          } else {
            //toast.error('ארעה שגיאה');
            mainVasError();
          }
          resolve(res);
        })
        .catch(function (error) {
          useLoading && setLoading(false);
          console.log(error);
          //toast.error('ארעה שגיאה');
          mainVasError();
          resolve(null);
        });
    });
  };

  const insertWebSite = async (useLoading: boolean, wsReq: webSiteReq) => {
    return new Promise((resolve) => {
      useLoading && setLoading(useLoading);
      var data = new URLSearchParams({
        reqD: JSON.stringify(wsReq),
      }).toString();

      fetchRequestNode(
        `${config.url.Node_API_URL}/selfService/insertWebSite`,
        "post",
        data
      )
        .then((response: any) => {
          useLoading && setLoading(false);
          var res = JSON.parse(response);
          if (res && res.success) {
            // toast.error('הואס התווסף');
          } else {
            //toast.error('ארעה שגיאה');
            mainVasError();
          }
          resolve(res);
        })
        .catch(function (error) {
          useLoading && setLoading(false);
          console.log(error);
          //toast.error('ארעה שגיאה');
          mainVasError();
          resolve(null);
        });
    });
  };

  const insertEcommerce = async (useLoading: boolean, ecReq: ecommerceReq) => {
    return new Promise((resolve) => {
      useLoading && setLoading(useLoading);
      var data = new URLSearchParams({
        reqD: JSON.stringify(ecReq),
      }).toString();

      fetchRequestNode(
        `${config.url.Node_API_URL}/selfService/insertEcommerce`,
        "post",
        data
      )
        .then((response: any) => {
          useLoading && setLoading(false);
          var res = JSON.parse(response);
          if (res && res.success) {
            // toast.error('הואס התווסף');
          } else {
            //toast.error('ארעה שגיאה');
            mainVasError();
          }
          resolve(res);
        })
        .catch(function (error) {
          useLoading && setLoading(false);
          console.log(error);
          //toast.error('ארעה שגיאה');
          mainVasError();
          resolve(null);
        });
    });
  };

  const getAddressAutocomplete = (search_string: string) => {
    return new Promise((resolve) => {
      var data = new URLSearchParams({
        searchString: JSON.stringify({ searchString: search_string }),
      }).toString();

      fetchRequest(
        `${config.url.API_URL}/AutocompleteAddress.asmx/Search`,
        "post",
        data
      )
        .then((response) => {
          parseString(response, (err: any, result: any) => {
            if (result) {
              var res = JSON.parse(result.string["_"]);
              if (res.Success) {
                resolve(res.Response);
              } else {
                toast.error(res.ErrorMessage);
              }
            }
          });
        })
        .catch(function (error) {
          console.log("Request failed", error);

          resolve([]);
        });
    });
  };

  const getGoogleCategoriesAutocomplete = async (
    search_string: string,
    useLoading?: boolean
  ) => {
    !!useLoading && setLoading(!!useLoading);
    return new Promise((resolve) => {
      var data = new URLSearchParams({
        prefixText: search_string,
      }).toString();

      fetchRequestNode(
        `${config.url.Node_API_URL}/selfService/getGoogleCategoriesAutocomplete`,
        "post",
        data
      )
        .then((response: any) => {
          var res: Array<any> = JSON.parse(response);
          if (res != null) {
            //label,value,Match
            const google_cat_arr: autocompleteOption[] = res
              .sort((a, b) => {
                return a.Match - b.Match;
              })
              .map((obj) => {
                return {
                  value: JSON.stringify({
                    text: obj.label,
                    value: obj.value,
                  }),
                  display: obj.label,
                };
              });
            resolve(google_cat_arr);
          }

          !!useLoading && setLoading(false);
        })
        .catch(function (error) {
          !!useLoading && setLoading(false);
          console.log(error);
        });
    });
  };
  // const getCustomAutocomplete = (search_string: string, field_number: number) => {

  //     return new Promise((resolve) => {

  //         var data = new URLSearchParams({
  //             searchString: JSON.stringify({ searchString: search_string }),
  //         }).toString();

  //         fetchRequestNode(
  //             `${config.url.Node_API_URL}/selfService/getCustomAutocompleteOptions`,
  //             "post",
  //             data
  //         )
  //             .then((response) => {
  //                 parseString(response, (err: any, result: any) => {
  //                     if (result) {
  //                         var res = JSON.parse(result.string["_"]);
  //                         if (res.Success) {
  //                             resolve(res.Response);

  //                         }

  //                     }

  //                 });
  //             })
  //             .catch(function (error) {
  //                 console.log("Request failed", error);

  //                 resolve([]);
  //             });
  //     });
  // };
  return (
    <VasContext.Provider
      value={{
        FacebookCategories,
        getVasProducts,
        getPackagesByProdCode,
        getLandingPageGoalsList,
        initDigitalProductFields,
        getAddressAutocomplete,
        getGoogleCategoriesAutocomplete,
        // getCustomAutocomplete,
        insertLandingPage,
        insertDigitalProduct,
        insertWebSite,
        insertEcommerce,

        initVasProductInfoContext,
      }}
    >
      {props.children}
      {/* <CustomLoader
        isOpen={loading}
        duration={5000}
        onDidDismiss={() => {
          setLoading(false);
        }}
      ></CustomLoader> */}
    </VasContext.Provider>
  );
};

export default VasContextProvider;
