import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IonRow,
  IonCol,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonRadio,
  IonIcon,
  IonChip,
  IonCard,
} from "@ionic/react";
import { isMobile } from 'react-device-detect';

import './SelfSaleGoogleAds.scss'
import SelfSaleContext, { AdGroup, RequestResult } from "../../../data/SelfSaleContext";
import AdsPreviewSlider from './AdsPreviewSlider'
import AdsAdditional from './AdsAdditional'

const SelfSaleGoogleAds: React.FC<{ saleType: string, completed: boolean, onComplete?: (phaseId: number, valid: RequestResult) => void }> = (props) => {
  const SelfSaleCtx = useContext(SelfSaleContext);
  const [memberAdgroups, setMemberAdgroups] = useState<AdGroup[]>([]);
  useEffect(() => {
    if (SelfSaleCtx.memberAdgroups && SelfSaleCtx.memberAdgroups.length > 0) {
      setMemberAdgroups(SelfSaleCtx.memberAdgroups.filter(ag => ag.selected))
    }
    else {
      SelfSaleCtx.getResllerSubCategories("")
        .then((res: any) => {
          setMemberAdgroups(res.filter((g: any) => g.selected))
        })
    }
  }, [SelfSaleCtx.memberAdgroups])

  return (
    <>
      <IonCard className={isMobile ? "self-sale-card self-sale-card-mobile self-sale-card-sm" : "self-sale-card self-sale-card-sm"}>
        <h2 className="ion-no-margin self-sale-section-title">שילוב מידע נוסף במודעה</h2>
        <p className="large-p">
          גוגל יכול לשלב במודעה מידע נוסף, בהתאם לסוג החיפוש שהלקוחות יבצעו.
        </p>
        <p className="large-p">ההחלטה אם לשלב את המידע במודעה תלוי אך ורק באלגוריתם של גוגל.</p>
        {memberAdgroups && memberAdgroups.length > 0 && (
          <AdsPreviewSlider adgroups={memberAdgroups} />
        )}
      </IonCard>
      <AdsAdditional completed={props.completed} onComplete={props.onComplete} />
    </>
  );
};

export default SelfSaleGoogleAds;