import React, { } from "react";
import {
    IonCol,
    IonRow,
} from "@ionic/react";

import { Control, FieldError, NestDataObject } from "react-hook-form";

import CustomTextarea from "../utils/CustomElements/CustomTextarea";
import VasContactHoursSelect from "./VasContactHoursSelect";
import VasPackSelect from "./VasPackSelect";


const FrmEcommerce: React.FC<{
    register: React.Ref<any>;
    control: Control;
    errors?: NestDataObject<Record<string, any>, FieldError>;
    clearError: (name: string) => void;
}> = props => {

    //const vasCxt = useContext(VasContext);
    return (
        <React.Fragment>
             <IonRow>
                <IonCol sizeMd='6'>
                    <VasPackSelect prod_code={12} register={props.register} control={props.control} errors={props.errors} clearError={props.clearError} />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeMd='6'>
                    <VasContactHoursSelect register={props.register} control={props.control} />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeMd='6'>
                    <CustomTextarea
                        name='remarks'
                        errors={props.errors}
                        control={props.control}
                        label='הערות לצוות בוני האתר'
                        ref={props.register} />
                </IonCol>
            </IonRow>
        </React.Fragment>
    );
};

export default FrmEcommerce;