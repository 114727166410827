import {
  IonCard,
  IonCol,
  IonGrid,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonCardContent,
  IonItem,
  IonLabel,
  IonImg,
  IonButton,
  IonIcon,
} from "@ionic/react";
import { isMobile } from "react-device-detect";
import React, { useContext, useEffect, useState } from "react";
import BusinessLogo from "../../components/BusinessCardTabs/BusinessLogo";
import DoughnutChart from "../../components/utils/Charts/DoughnutChart";
import { Photo } from "../../components/utils/ImagePicker/ImagePicker";
import MemberDetailsContext from "../../data/MemberDetailes-context";
import {
  businessCardModulesName,
  MemberDetailesArrayToShow,
  showEmptyElementsBtn,
  getAdvCategoryCategories,
} from "../../utils-ts/BusinessCardFunc";
import MemberDetaielsModules from "../../components/BusinessCardTabs/MemberDetails/MemberDetaielsModules";
import CustomTitleButton from "../../components/utils/CustomElements/CustomTitleButton";

import MemberCoupon from "../../components/BusinessCardTabs/MemberCoupon/MemberCoupon";
import MemberDetailes from "../../components/BusinessCardTabs/MemberDetails/MemberDetails";
import B144Products from "../../components/BusinessCardTabs/B144Product/B144Products";
import MemberProducts from "../../components/BusinessCardTabs/MemberProduct/MemberProducts";
import Gallery from "../../components/BusinessCardTabs/B144Gallery/Gallery";
/* import B144Files from "../../components/BusinessCardTabs/B144Files/B144Files"; */
import "./BusinessCard.scss";
import "../../components/utils/ImagePicker/ImagePicker.scss";

import { useParams } from "react-router";
import { HeaderParameters } from "../../data/Header-Context";
import CustomPage, {
  floatingButton,
} from "../../components/utils/CustomElements/CustomPage";
import {
  coupon,
  googleAndB144,
  replaceImageWhite,
  imageBlack,
  googleWhite,
} from "../../images/index";
import { addOutline } from "ionicons/icons";
//import CustomLoader from "../../components/utils/CustomElements/CustomLoader";
import UserContext from "../../data/AuthContextProvider";
import { config } from "../../Constants";
import PriceList from "../../components/BusinessCardTabs/B144PriceList/PriceList";
import { usePriceListContext } from "../../data/PriceListContextProvider";
import MemberNews from "../../components/BusinessCardTabs/MemberNews/MemberNews";
import GlobalContext from '../../data/Global-Context';
import MemberCertificates from "../../components/BusinessCardTabs/MemberCertificates/MemberCertificates";

const BusinessCard: React.FC = () => {
  const selectedSegmentName = useParams<{ segmentName: string }>().segmentName;
  const MemberDetailsCxt = useContext(MemberDetailsContext);

  const [segmentSelected, setsegmentSelected] = useState<string | undefined>(
    () => {
      if (selectedSegmentName && selectedSegmentName !== "") {
        return selectedSegmentName;
      } else {
        return "memberDetails";
      }
    }
  );
  const { authValues } = useContext(UserContext);
  const [isShowLogo, setIsShowLogo] = useState(false);
  const [isShowDeleteBtn, setIsShowDeleteBtn] = useState(false);
  const [isRunDeleteFunc, setIsRunDeleteFunc] = useState(false);
  const [isRunUploadFunc, setIsRunUploadFunc] = useState(false);
  const [isShowUploadBtn, setIsShowUploadBtn] = useState(false);
  const [isPrecentageChange, setisPrecentageChange] = useState(false);
  const [isCouponSelected, setCouponSelected] = useState(false);
  const [isPriceListSelected, setPriceListSelected] = useState(false);
  const [isMemberNewsSelected, setMemberNewsSelected] = useState(false);
  const [isMemberCertificatesSelected, setMemberCertificatesSelected] = useState(false);
  const { GetPriceListByMemberId, MemberPriceList } = usePriceListContext();
 

  const [
    showElementName,
    setShowElementName,
  ] = useState<businessCardModulesName>("");
  const [mainCategory, setMainCategory] = useState("");
  const [numOfMultiSelected, setNumOfMultiSelected] = useState<
    number | undefined
  >();
  const [isCancelMultiChoice, setIsCancelMultiChoice] = useState(false);
  //const [loading, setLoading] = useState<boolean>(true);

  const globalCtx = useContext(GlobalContext);

 const setLoading = (loading:boolean)=>{
  //console.log('loading',loading);
  globalCtx.setLoader(loading);

 };

  const [isAddImag, setIsAddImag] = useState(false);
  const [isAddProduct, setIsAddProduct] = useState(false);
  const [isAddCoupon, setIsAddCoupon] = useState(false);
  const [isAddPriceList, setIsAddPriceList] = useState(false);
  const [isAddMemberNews, setIsAddMemberNews] = useState(false);
  const [isAddCertificate, setIsAddCertificate] = useState(false);
  

  const productFloatingButton: floatingButton = {
    icon: addOutline,
    onClick: () => {
      setIsAddProduct(true);
    },
    title: "מוצרים",
  };
  const galleryFloatingButton: floatingButton = {
    icon: addOutline,
    onClick: () => {
      setIsAddImag(true);
    },
    title: "תמונה",
  };

  const couponFloatingButton: floatingButton = {
    icon: addOutline,
    onClick: () => {
      setIsAddCoupon(true);
    },
    title: "קופון",
  };

  const priceListFloatingButton: floatingButton = {
    icon: addOutline,
    onClick: () => {
      setIsAddPriceList(true);
    },
    title: "מחירון",
  };

  const certificatesFloatingButton: floatingButton = {
    icon: addOutline,
    onClick: () => {
      setIsAddCertificate(true);
    },
    title: "הוספה",
  };

  const memberNewsFloatingButton: floatingButton = {
    icon: addOutline,
    onClick: () => {
      setIsAddMemberNews(true);
    },
    title: "הוספה",

  };
  React.useEffect(() => {
    GetPriceListByMemberId(authValues?.ClientId!);
  }, []);

  const [floatingButton, setFloatingButton] = useState<
    floatingButton | undefined
  >(() => {
    if (selectedSegmentName && selectedSegmentName === "gallery") {
      return galleryFloatingButton;
    } else if (
      selectedSegmentName &&
      (selectedSegmentName === "products" || selectedSegmentName === "memberProducts") &&
      MemberDetailsCxt.ProductGalleryType !== "eCommerce" && MemberDetailsCxt.ManageProductGallery
    ) {
      
      return productFloatingButton;
    } else if (
      selectedSegmentName &&
      selectedSegmentName === "pricelist" &&
      !isPriceListSelected
    ) {
      return priceListFloatingButton;
    } else if (
      selectedSegmentName &&
      selectedSegmentName === "coupons" &&
      !isCouponSelected
    ) {
      return couponFloatingButton;
    } else if (
      selectedSegmentName &&
      selectedSegmentName === "membernews" &&
      !isMemberNewsSelected
    ) {
      return memberNewsFloatingButton;
    } else if (
      selectedSegmentName &&
      selectedSegmentName === "certificates" &&
      !isMemberCertificatesSelected
    ) {
      return certificatesFloatingButton;
    }
    
    else return undefined;
  });

  const headerParameters: HeaderParameters = {
    isShowLogo: true,
    isShowBusinessCardPreview: segmentSelected === "memberDetails",
    backButton: { isShowMobile: true },
    isShowNotification: true,
    isShowDeleteButton: isShowDeleteBtn,
    isShowUploadButton: isShowUploadBtn,
    deleteFunc: () => {
      setIsRunDeleteFunc(true);
    },
    uploadFunc: () => {
      setIsRunUploadFunc(true);
    },
    numOfMultiSelected: numOfMultiSelected,
    onCancelMultiSelected: () => {
      setIsCancelMultiChoice(true);
    },
  };

  useEffect(() => {
    if (MemberDetailsCxt.BusinessLogo == null) {
      MemberDetailsCxt.initBusinessLogoContext();
    }
    if (
      MemberDetailsCxt.ContentStatusPercentage == null ||
      isPrecentageChange == true
    ) {
      MemberDetailsCxt.initContentStatusContext();
      setisPrecentageChange(false);
    }
    if (MemberDetailsCxt.GeneralInfo == null) {
      MemberDetailsCxt.initMemberDetailsContext(!!authValues?.IsSelfRegister);
    }
    // if (MemberDetailsCxt.BusinessEvents == null) {
    //   MemberDetailsCxt.initBusinessEventsContext();
    // }
    if (MemberDetailsCxt.BusinessLinks == null) {
      MemberDetailsCxt.initBusinessLinksContext();
    }
    if (MemberDetailsCxt.BusinessSeoDetails == null && !config.otef_israel) {
      MemberDetailsCxt.initBusinessSeoDetailsContext();
    }
    if (MemberDetailsCxt.BusinessQuestionsAndAnswers == null) {
      MemberDetailsCxt.initBusinessQuestionsAndAnswerContext();
    }
    if (MemberDetailsCxt.BusinessMovies == null) {
      MemberDetailsCxt.initMovieContext();
    }
  }, [isPrecentageChange]);

  useEffect(() => {
    if (
      MemberDetailsCxt.BusinessLogo &&
      MemberDetailsCxt.ContentStatusPercentage &&
      MemberDetailsCxt.GeneralInfo &&
      MemberDetailsCxt.BusinessLinks &&
      MemberDetailsCxt.BusinessQuestionsAndAnswers &&
      MemberDetailsCxt.BusinessMovies
    ) {
      setLoading(false);
    }
  }, [
    MemberDetailsCxt.BusinessLogo,
    MemberDetailsCxt.ContentStatusPercentage,
    MemberDetailsCxt.GeneralInfo,
    MemberDetailsCxt.BusinessLinks,
    //MemberDetailsCxt.BusinessMovie,
    MemberDetailsCxt.BusinessMovies,
    MemberDetailsCxt.BusinessQuestionsAndAnswers,
  ]);

  useEffect(() => {
    if(selectedSegmentName === 'products' && MemberDetailsCxt.NewProducts){
      setsegmentSelected('memberProducts');
    }
  }, [MemberDetailsCxt.NewProducts]);
  

  const isCouponSelectedHandler = (selected: boolean) => {
    setCouponSelected(selected);
    if (selected) {
      setFloatingButton(undefined);
    } else {
      setFloatingButton(couponFloatingButton);
    }
  };

  const isPriceListSelectedHandler = (selected: boolean) => {
    setPriceListSelected(selected);
    if (selected) {
      setFloatingButton(undefined);
    } else {
      setFloatingButton(priceListFloatingButton);
    }
  };

  const isMemberNewsSelectedHandler = (selected: boolean) => {
    setMemberNewsSelected(selected);
    if (selected) {
      setFloatingButton(undefined);
    } else {
      setFloatingButton(memberNewsFloatingButton);
    }
  };

  const isCertificateSelectedHandler = (selected: boolean) => {
    setMemberCertificatesSelected(selected);
    if (selected) {
      setFloatingButton(undefined);
    } else {
      setFloatingButton(certificatesFloatingButton);
    }
  };

  const syncGmb = () => {
    //console.log('sync');
    MemberDetailsCxt.syncMemberGmb(false);
  };

  const allowGmbSync = MemberDetailsCxt.allowSyncGmb == true;
  return (
    <CustomPage
      headerParameters={headerParameters}
      pageName={"BusinessCard"}
      title={"דף העסק שלך"}
      logoSrc={MemberDetailsCxt.BusinessLogo}
      logoClickFunc={() => {
        setIsShowLogo(true);
      }}
      floatingButton={floatingButton}
    >
      {MemberDetailsCxt.BusinessLogo != undefined &&
        MemberDetailsCxt.BusinessLinks != undefined &&
        MemberDetailsCxt.BusinessQuestionsAndAnswers != undefined &&
        MemberDetailsCxt.ContentStatusPercentage != undefined &&
        MemberDetailsCxt.GeneralInfo != undefined &&
        (MemberDetailsCxt.BusinessMovies != undefined) && (
          <IonGrid fixed={true}>
            <IonRow>
              <IonCol size="12" sizeMd="8">
                <IonCard mode="md" className="missing-info-card">
                  <IonCardContent>
                    <IonRow className="ion-justify-content-between">
                      <IonCol size="8" sizeMd="9.8" className="card-desc">
                        <h2 className="ion-no-margin large-p bold-text">
                          {MemberDetailsCxt.ContentStatusPercentage !=
                            undefined &&
                          MemberDetailsCxt.ContentStatusPercentage === 100
                            ? "כל הכבוד!"
                            : "דף העסק שלך יכול לעבוד טוב יותר"}
                        </h2>
                        <p className="ion-no-margin">
                          {MemberDetailsCxt.ContentStatusPercentage !=
                            undefined &&
                          MemberDetailsCxt.ContentStatusPercentage === 100
                            ? "עשית את זה בגדול! כל המידע אודות העסק שלך נרשם בהצלחה"
                            : "הוספה של מידע, תמונות, מחירים וקופונים ימשכו לקוחות רבים יותר לעסק שלכם."}
                        </p>
                      </IonCol>
                      {/* {authValues && authValues.IsSelfRegister === false && ( */}
                        <IonCol size="2.5" sizeMd="2.2">
                          <DoughnutChart
                          titleColor="black"
                          chartColor={config.otef_israel ?  'rgb(29,139,200)' : undefined}
                            chartWidth={!isMobile ? 105 : 60}
                            chartWeight={80}
                            value={
                              MemberDetailsCxt.ContentStatusPercentage
                                ? MemberDetailsCxt.ContentStatusPercentage
                                : 0
                            }
                          ></DoughnutChart>
                        </IonCol>
                      {/* )} */}
                    </IonRow>
                    {segmentSelected === "memberDetails" && (
                      <IonRow>
                        <IonCol size="12" sizeMd="8">
                          {MemberDetailesArrayToShow.map((o, index) => {
                            if (showEmptyElementsBtn(MemberDetailsCxt, o.id)) {
                              if (o.id === "AdvCategories") {
                                let mainCategoriesTemp = getAdvCategoryCategories(
                                  MemberDetailsCxt
                                );
                                return mainCategoriesTemp.map((c, index) => {
                                  return (
                                    <CustomTitleButton
                                      key={"c_" + index}
                                      onClick={() => {
                                        setMainCategory(c);
                                        setShowElementName(o.id);
                                        SetDtlPushEvt(
                                          "user_engagement",
                                          o.btnTitle
                                        );
                                      }}
                                      BtnTxt={o.btnTitle + " - " + c}
                                    ></CustomTitleButton>
                                  );
                                });
                              } else {
                                return (
                                  <CustomTitleButton
                                    BtnTxt={o.btnTitle}
                                    key={index}
                                    onClick={() => {
                                      // if (o.id == "OpenDays") {
                                      //   setShowElementName("HandleOpenDays");
                                      // } else {
                                        
                                      // }
                                      setShowElementName(o.id);
                                      SetDtlPushEvt(
                                        "user_engagement",
                                        o.btnTitle
                                      );
                                    }}
                                  ></CustomTitleButton>
                                );
                              }
                            }
                          })}
                          {allowGmbSync && (
                            <IonCol>
                              {" "}
                              <span className="custom-title-button">
                                <IonButton onClick={syncGmb}>
                                  סינכרון דף גוגל
                                  <IonIcon
                                    slot="end"
                                    size="big"
                                    icon={googleWhite}
                                  />
                                </IonButton>
                              </span>
                            </IonCol>
                          )}
                        </IonCol>
                      </IonRow>
                    )}
                  </IonCardContent>
                </IonCard>
                <IonSegment
                  mode="md"
                  className="seg-bus-card"
                  value={segmentSelected}
                  scrollable={true}
                  onIonChange={(e) => {
                    setsegmentSelected(e.detail.value);
                    setIsRunDeleteFunc(false);
                    setNumOfMultiSelected(undefined);
                    setIsShowDeleteBtn(false);
                    if (e.detail.value === "gallery") {
                      setFloatingButton(galleryFloatingButton);
                    } else if (
                      (e.detail.value === "products" || e.detail.value === "memberProducts") &&
                      MemberDetailsCxt.ProductGalleryType !== "eCommerce" && MemberDetailsCxt.ManageProductGallery
                    ) {
                      setFloatingButton(productFloatingButton);
                    } else if (
                      e.detail.value === "pricelist" &&
                      (MemberPriceList === undefined ||
                        MemberPriceList === null)
                    ) {
                      setFloatingButton(priceListFloatingButton);
                    } else if (
                      e.detail.value === "coupons" &&
                      !isCouponSelected
                    ) {
                      setFloatingButton(couponFloatingButton);
                    }else if (
                      e.detail.value === "membernews" &&
                      !isMemberNewsSelected
                    ) {
                      setFloatingButton(memberNewsFloatingButton);
                    } else if (
                      e.detail.value === "certificates" &&
                      !isMemberCertificatesSelected
                    ) {
                      setFloatingButton(certificatesFloatingButton);
                    } else {
                      setFloatingButton(undefined);
                    }
                  }}
                >
                  <IonSegmentButton value="memberDetails" className="segment-button-md">
                    <span className="segment">פרטי העסק</span>
                  </IonSegmentButton>
                  <IonSegmentButton value="gallery">
                    <span className="segment">תמונות</span>
                  </IonSegmentButton>
                  {MemberDetailsCxt.NewProducts ? 
                  <IonSegmentButton value="memberProducts">
                    <span className="segment">מוצרים</span>
                  </IonSegmentButton> : 
                  <IonSegmentButton value="products">
                    <span className="segment">מוצרים</span>
                  </IonSegmentButton>}
                  
                  {/* <IonSegmentButton value="files">קבצים</IonSegmentButton> */}
                  <IonSegmentButton value="coupons">
                    <span className="segment">קופונים</span>
                  </IonSegmentButton>
                  {MemberDetailsCxt.ShowNews && <IonSegmentButton className="segment-button-lg" value="membernews">
                    <span className="segment">חדשות מהעסק</span>
                  </IonSegmentButton> }
                  {MemberDetailsCxt.PriceLists && <IonSegmentButton value="pricelist">
                    <span className="segment">מחירון</span>
                  </IonSegmentButton> }
                  {<IonSegmentButton value="certificates" className="segment-button-lg">
                    <span className="segment">תעודות והסמכות</span>
                  </IonSegmentButton> }

                  
                </IonSegment>
                <IonRow>
                  <IonCol size="12" className="ion-no-padding">
                    {segmentSelected === "memberDetails" &&
                      MemberDetailsCxt.GeneralInfo != null && (
                        <MemberDetailes
                          changePrecentage={(changePrecentage: boolean) => {
                            if (changePrecentage) {
                              setisPrecentageChange(true);
                            }
                          }}
                        ></MemberDetailes>
                      )}
                    {segmentSelected === "gallery" && (
                      <Gallery
                        isRunDelete={isRunDeleteFunc}
                        isRunUploade={isRunUploadFunc}
                        isAddImageClick={isAddImag}
                        onIsAddImageClick={() => {
                          setIsAddImag(false);
                        }}
                        isCancelMultiChoice={isCancelMultiChoice}
                        changeHeader={(
                          isShowDeleteButton: boolean,
                          isShowUploadButton: boolean,
                          numOfMultiSelected: number | undefined
                        ) => {
                          setIsShowDeleteBtn(isShowDeleteButton);
                          setIsShowUploadBtn(isShowUploadButton);
                          setNumOfMultiSelected(numOfMultiSelected);
                          if (numOfMultiSelected === undefined) {
                            setIsCancelMultiChoice(false);
                          }
                        }}
                        onDelete={() => {
                          setIsRunDeleteFunc(false);
                          setIsShowDeleteBtn(false);
                          setIsRunUploadFunc(false);
                          setIsShowUploadBtn(false);
                          setNumOfMultiSelected(undefined);
                        }}
                        onUpload={() => {
                          setIsRunDeleteFunc(false);
                          setIsShowDeleteBtn(false);
                          setIsRunUploadFunc(false);
                          setIsShowUploadBtn(false);
                          setNumOfMultiSelected(undefined);
                        }}
                      ></Gallery>
                    )}
                    {segmentSelected === "coupons" && (
                      <MemberCoupon
                        isCouponSelected={isCouponSelectedHandler}
                        isAddCouponClick={isAddCoupon}
                        onIsAddCouponClick={() => {
                          setIsAddCoupon(false);
                        }}
                      ></MemberCoupon>
                    )}
                    {/* {segmentSelected == "files" && <B144Files></B144Files>} */}
                    {segmentSelected === "memberProducts" && (<MemberProducts
                        isCancelMultiChoice={isCancelMultiChoice}
                        isAddProductClick={isAddProduct}
                        onIsAddProductClick={() => {
                          setIsAddProduct(false);
                        }}
                        isRunDelete={isRunDeleteFunc}
                        changeHeader={(
                          isShowDeleteButton: boolean,
                          numOfMultiSelected: number | undefined,
                          isShowAddButton: boolean
                        ) => {
                          setIsShowDeleteBtn(isShowDeleteButton);
                          setNumOfMultiSelected(numOfMultiSelected);
                          if (numOfMultiSelected === undefined) {
                            setIsCancelMultiChoice(false);
                          }
                          if (isShowAddButton) {
                            setFloatingButton(productFloatingButton);
                          } else {
                            setFloatingButton(undefined);
                          }
                        }}
                        onDelete={() => {
                          setIsRunDeleteFunc(false);
                          setIsShowDeleteBtn(false);
                          setNumOfMultiSelected(undefined);
                        }}
                      ></MemberProducts>)}
                    {segmentSelected === "products" && (
                      <B144Products
                        isCancelMultiChoice={isCancelMultiChoice}
                        isAddProductClick={isAddProduct}
                        onIsAddProductClick={() => {
                          setIsAddProduct(false);
                        }}
                        isRunDelete={isRunDeleteFunc}
                        changeHeader={(
                          isShowDeleteButton: boolean,
                          numOfMultiSelected: number | undefined,
                          isShowAddButton: boolean
                        ) => {
                          setIsShowDeleteBtn(isShowDeleteButton);
                          setNumOfMultiSelected(numOfMultiSelected);
                          if (numOfMultiSelected === undefined) {
                            setIsCancelMultiChoice(false);
                          }
                          if (isShowAddButton) {
                            setFloatingButton(productFloatingButton);
                          } else {
                            setFloatingButton(undefined);
                          }
                        }}
                        onDelete={() => {
                          setIsRunDeleteFunc(false);
                          setIsShowDeleteBtn(false);
                          setNumOfMultiSelected(undefined);
                        }}
                      ></B144Products>
                    )}
                    {segmentSelected === "membernews" && (
                      <MemberNews
                        isMemberNewsSelected={isMemberNewsSelectedHandler}
                        isAddMemberNewsClick={isAddMemberNews}
                        onIsAddMemberNewsClick={() => {
                          setIsAddMemberNews(false);
                        }}
                      ></MemberNews>
                    )}
                    {segmentSelected === "pricelist" && (
                      <PriceList
                        isPriceListSelected={isPriceListSelectedHandler}
                        isAddPriceListClick={isAddPriceList}
                        onIsAddPriceListClick={() => {
                          setIsAddPriceList(false);
                        }}
                      ></PriceList>
                    )}
                    {segmentSelected === "certificates" && (
                      <MemberCertificates
                      isMemberCertificateSelected={isCertificateSelectedHandler}
                        isAddMemberCertificateClick={isAddCertificate}
                        onIsAddMemberCertificatesClick={() => {
                          setIsAddCertificate(false);
                        }}
                      ></MemberCertificates>
                    )}
                    <MemberDetaielsModules
                      mainCategory={mainCategory}
                      closeModal={() => {
                        setShowElementName("");
                        setMainCategory("");
                      }}
                      onSave={() => {
                        setisPrecentageChange(true);
                      }}
                      showElement={showElementName}
                    ></MemberDetaielsModules>
                  </IonCol>
                </IonRow>
              </IonCol>
              {!isMobile && (
                <IonCol size="0" sizeMd="4">
                  {MemberDetailsCxt.BusinessLogo !== undefined &&
                  MemberDetailsCxt.BusinessLogo !== "" ? (
                    <IonItem
                      lines="none"
                      className="section-item-logo image-preview section-item"
                    >
                      <IonImg
                        src={MemberDetailsCxt.BusinessLogo}
                        alt=""
                        onClick={() => {
                          setIsShowLogo(true);
                        }}
                      />
                      <div className="image-actions-wrapper">
                        <IonButton
                          fill="clear"
                          onClick={() => {
                            setIsShowLogo(true);
                          }}
                        >
                          <IonIcon slot="icon-only" icon={replaceImageWhite} />
                        </IonButton>
                      </div>
                    </IonItem>
                  ) : (
                    <IonItem
                      lines="none"
                      className="section-item-logo no-logo section-item"
                    >
                      <div className="no-pic-wraper">
                        <IonButton
                          className="upload-img-btn"
                          fill="clear"
                          shape="round"
                          onClick={() => setIsShowLogo(true)}
                        >
                          <IonIcon icon={imageBlack} slot="icon-only"></IonIcon>
                        </IonButton>
                        <p>הוספת לוגו</p>
                      </div>
                    </IonItem>
                  )}
                  <IonItem
                    target="_blank"
                    href="https://www.b144biz.co.il/digital-marketing/google-advertising/google-ads/"
                    lines="none"
                    className="section-item-small section-item section-item-center"
                    onClick={() => {
                      SetDtlPushEvtOutBound(
                        "outbound_links",
                        "https://www.b144biz.co.il/digital-marketing/google-advertising/google-ads/"
                      );
                    }}
                  >
                    <IonLabel>
                      <img src={googleAndB144} />
                      <h2 className="bold-text">פרסום העסק בגוגל</h2>
                      <p className="large-p">הלקוחות שלך מחפשים אותך ברגע זה</p>
                      <IonImg src={coupon}></IonImg>
                      <IonButton expand="block" className="main-button">
                        לפרטים נוספים
                      </IonButton>
                    </IonLabel>
                  </IonItem>
                </IonCol>
              )}
            </IonRow>
          </IonGrid>
        )}
      {isShowLogo && (
        <BusinessLogo
          businessLogo={MemberDetailsCxt.BusinessLogo}
          show={isShowLogo}
          allowGmbSync={MemberDetailsCxt.allowSyncGmb == true}
          onSave={(takenPhoto: Photo, sync_gmb: boolean) => {
            MemberDetailsCxt.saveLogoHandler(takenPhoto, sync_gmb);
          }}
          onSaveAuto={(base64: string, sync_gmb: boolean) => {
            MemberDetailsCxt.saveAutoLogoHandler(base64, sync_gmb);
          }}
          onDelete={(sync_gmb: boolean) => {
            MemberDetailsCxt.deleteLogoHandler(sync_gmb);
          }}
          closeModal={() => {
            setIsShowLogo(false);
          }}
        ></BusinessLogo>
      )}
      {/* <CustomLoader
        isOpen={loading}
        duration={2000}
        onDidDismiss={() => {
          setLoading(false);
        }}
      ></CustomLoader> */}
    </CustomPage>
  );
};

export default BusinessCard;
