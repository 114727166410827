import { IonList, IonRadioGroup, IonSelect, IonLabel, IonSelectOption, IonRadio, IonItem, IonCheckbox } from "@ionic/react";
import React, { ComponentProps, forwardRef, useEffect, useState } from "react";
import { FieldError, NestDataObject } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';
type IonSelectProps = ComponentProps<typeof IonSelect>;
type selectRef = HTMLSelectElement;

interface SelectProps extends IonSelectProps {
    name: string;
    label: string;
    optionValueFld: string;
    optionLabelFld: string;
    options: any[];
    showValues?: boolean;
    showBackdrop?: boolean,
    multiple?: boolean,
    errors?: NestDataObject<Record<string, any>, FieldError>,
    onSelect: (e: any) => void
}
const CustomSelect = forwardRef<selectRef, SelectProps>(
    ({ name, ...props }, ref) => {
        const interfaceOptions = {
            showBackdrop: false,
            side: "top",
            alignment: "center",
            cssClass: 'custom-select-popover'
        }
        return (<>
            <IonList>
                <IonItem color="light" lines="none" mode="md" className={(props.class ? props.class : "") + (props.errors && props.errors[name] != null ? " item-invalid" : "")}>
                    {props.label && <IonLabel position="stacked">{props.label}</IonLabel>}
                    <IonSelect
                        multiple={props.multiple}
                        mode="md" 
                        interfaceOptions={interfaceOptions}
                        className="custom-select"
                        interface="popover" 
                        value={props.value}
                        onIonChange={(e) => props.onSelect(e.detail.value)}
                        placeholder={props.placeholder}>
                        {
                            props.options.map((el, index) => <IonSelectOption value={el[props.optionValueFld]} key={uuidv4()}>{props.showValues ? el[props.optionLabelFld] + " - " + el[props.optionValueFld] : el[props.optionLabelFld]}</IonSelectOption>)
                        }
                    </IonSelect>
                </IonItem>
                
        <div className="input-alerts">
          <p className="input-error">{props.errors && props.errors[name] != null && (props.errors as any)[name].message}</p>
        </div>
      
            </IonList>

        </>
        )
    }
)
export default CustomSelect;