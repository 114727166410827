import { IonCheckbox, IonChip, IonIcon, IonLabel } from "@ionic/react";
import React from "react";
import { close, add, addCircleOutline } from 'ionicons/icons';
const CustomChip: React.FC<{ text: string, icon: string, onClick: () => void, className?:string }> = (props) => {
      const renderIcon = () => {
          switch (props.icon) {
              case "close":
              return (<IonIcon icon={close}></IonIcon>);
              case "add":
              return (<IonIcon icon={addCircleOutline}></IonIcon>);
            default:
              break;
          }
      };
    return (<>
        <IonChip className={"custom-chip " + props.className} onClick={props.onClick}>
            {renderIcon()}
            <IonLabel>{props.text}</IonLabel>
        </IonChip>
    </>
    )
}
export default CustomChip;