import React, { useEffect } from 'react';
import { IonRouterOutlet, isPlatform } from '@ionic/react';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import Home from '../../pages/HomePage/Home';
import Statistics from '../../pages/Statistics/Statistics';
import MemberInbox from '../../pages/MemberInbox';
import VasMenu from '../../pages/VAS/VasMenu';
import CheckUserLogin from '../../pages/Login/CheckUserLogin';

import GalleryContextProvider from '../../data/GalleryContextProvider';
// import FilesContextProvider from "../../data/FilesContextProvider";
import CouponContextProvider from '../../data/CouponContextProvider';
import StatisticProvider from '../../data/StatisticContextProvider';
import ProductsContextProvider from '../../data/ProductsContextProvider';
import BusinessCard from '../../pages/BusinessCard/BusinessCard';
import SelfSale from '../../pages/SelfSale/SelfSale';
import UserSettings from '../../pages/UserSettings';
import Help from '../../pages/Help';
//import PrivacyPolicy from '../../pages/PrivacyPolicy';
import Transition from '../utils/Transition';
import { Plugins } from '@capacitor/core';
import Budget from '../../pages/Budget/Budget';
import StatisticsOpt from '../../pages/Statistics/StatisticsOpt';
import ProductListPage from '../../pages/Statistics/ProductListPage';
import ProductInfoPage from '../../pages/Statistics/ProductInfoPage';
import Letters from '../../pages/Letters';
import MemberLeads from '../../pages/Statistics/MemberLeads';
import { isMobile } from 'react-device-detect';
import PriceListContextProvider from '../../data/PriceListContextProvider';
import MaintenancePage from '../../pages/Maintenance';
import CoSalePage from '../../pages/CoSale/coSale';
import ProductStatistics from '../../pages/ProductStatistics/ProductStatistics';

const Routing: React.FC = () => {
  const MemberComments = React.lazy(() => import('../../pages/Comments/MemberComments'));
  const location = useLocation();

  useEffect(() => {
    if (isMobile && !isPlatform('mobileweb')) {
      if (location.pathname.toLowerCase() === '/home') {
        Plugins.App.addListener('backButton', Plugins.App.exitApp);
      } else {
        Plugins.App.removeAllListeners();
      }
    }
  });

  React.useEffect(() => {
    // runs on location, i.e. route, change
    //console.log('handle route change here', location)
    if (dtlPushEvt) {
      dtlPushEvt({
        event: 'screen_view',
        eventInfo: { ScreenName: '{{page path}}', user_action: 'screen_view' },
      });
    }
  }, [location]);

  return (
    <IonRouterOutlet id="hamburgerMain">
      <Redirect path="/" to="/Home" exact></Redirect>
      <AnimatePresence exitBeforeEnter>
        <Switch key={location.pathname}>
          <Route path="/login" render={() => <Redirect to="/Home" />} exact={true} />
          <Route path="/Home" exact>
            <Transition>
              <Home />
            </Transition>
          </Route>

          <Route
            path="/Maintenance"
            render={({ staticContext }) => {
              if (staticContext) {
                console.log(503);
                staticContext.statusCode = 503;
              }
              return <MaintenancePage />;
            }}
          />

          <Route path="/Statistics" exact>
            <StatisticProvider>
              <Transition>
                <Statistics />
              </Transition>
            </StatisticProvider>
          </Route>
          <Route path="/Statistics/:productName" exact>
            <Transition>
              <ProductStatistics />
            </Transition>
          </Route>
          <Route path="/ProductInfo/:productName/:subPoductId?" exact>
            <StatisticProvider>
              <Transition>
                <ProductInfoPage />
              </Transition>
            </StatisticProvider>
          </Route>

          <Route path="/StatisticsOpt" exact>
            <StatisticProvider>
              <Transition>
                <StatisticsOpt />
              </Transition>
            </StatisticProvider>
          </Route>

          <Route path="/ProductListPage" exact>
            <StatisticProvider>
              <Transition>
                <ProductListPage />
              </Transition>
            </StatisticProvider>
          </Route>

          <Route path="/MemberLeads" exact>
            <StatisticProvider>
              <Transition>
                <MemberLeads />
              </Transition>
            </StatisticProvider>
          </Route>

          <Route path="/userSettings" exact>
            <Transition>
              <UserSettings />
            </Transition>
          </Route>

          <Route path="/Help" exact>
            <Transition>
              <Help>
                <UserSettings />
              </Help>
            </Transition>
          </Route>
          {/* <Route path="/Privacy" exact>
            <Transition>
              <PrivacyPolicy />
            </Transition>
          </Route> */}

          <Route path="/Inbox" exact>
            <Transition>
              <MemberInbox />
            </Transition>
          </Route>

          <Route path="/Letters" exact>
            <Transition>
              <Letters />
            </Transition>
          </Route>
          <Route path="/Inbox/:filterName" exact>
            <Transition>
              <MemberInbox />
            </Transition>
          </Route>
          <Route path="/Inbox/:platformType/:messageId" exact>
            <Transition>
              <MemberInbox />
            </Transition>
          </Route>

          <Route path="/BusinessCard/:segmentName" exact>
            <ProductsContextProvider>
              <PriceListContextProvider>
                {/* <FilesContextProvider> */}
                <CouponContextProvider>
                  <GalleryContextProvider>
                    <Transition>
                      <BusinessCard />
                    </Transition>
                  </GalleryContextProvider>
                </CouponContextProvider>
                {/* </FilesContextProvider> */}
              </PriceListContextProvider>
            </ProductsContextProvider>
          </Route>
          <Route path="/BusinessCard" exact>
            <ProductsContextProvider>
              <PriceListContextProvider>
                {/* <FilesContextProvider> */}
                <CouponContextProvider>
                  <GalleryContextProvider>
                    <Transition>
                      <BusinessCard />
                    </Transition>
                  </GalleryContextProvider>
                </CouponContextProvider>
                {/* </FilesContextProvider> */}
              </PriceListContextProvider>
            </ProductsContextProvider>
          </Route>
          <Route path="/MemberComments/:showPopup" exact>
            <Transition>
              <MemberComments />
            </Transition>
          </Route>
          <Route path="/MemberComments" exact>
            <Transition>
              <MemberComments />
            </Transition>
          </Route>
          <Route path="/Budget" exact>
            <Transition>
              <Budget />
            </Transition>
          </Route>
          <Route path="/Vas" exact>
            <Transition>
              <VasMenu />
            </Transition>
          </Route>
          <Route path="/UserLogin/:token?" exact>
            <Transition>
              <CheckUserLogin loginType="user" />
            </Transition>
          </Route>
          <Route path="/CmLogin/:token/:cm_username/:tp/:member_username?/:activity_origins?/:st?" exact>
            <Transition>
              <CheckUserLogin loginType="cm" />
            </Transition>
          </Route>
          <Route path="/CmCheckUser/:cm_username/:tp/:member_username?/:activity_origins?/:st?" exact>
            <Transition>
              <CheckUserLogin loginType="cm_query" />
            </Transition>
          </Route>
          <Route path="/SelfSale/:saleType" exact>
            <Transition>
              <SelfSale />
            </Transition>
          </Route>
          <Route path="/CoSale/:token?" exact>
            <Transition>
              <CoSalePage />
            </Transition>
          </Route>
        </Switch>
      </AnimatePresence>
    </IonRouterOutlet>
  );
};

export default Routing;
