import React, { FC, ComponentProps } from "react";
import { IonIcon, IonItem, IonLabel, IonRouterLink } from "@ionic/react";
import { isMobile } from "react-device-detect";
type IonItemProps = ComponentProps<typeof IonItem>;

interface ItemProps extends IonItemProps {
  mainTitle: string;
  subTitle?: string;
  routerLink?: string;
  detailIcon?: string;
  icon?: string;
  iconBackground?: string;
  pageName?: string;
  innerPage?: boolean;
  noHover?: boolean;
  class?: string;
  isButton?: boolean;
  externalLink?: string;
  linkItem?: { textValue: string; linkValue: string };
}

const CustomHeaddingItem: FC<ItemProps> = ({ ...props }) => {
  return (
    <div
      className={
        "custom-list-button-wraper " +
        (props.innerPage ? "inner " : "") +
        (props.noHover ? "no-hover " : "") +
        (props.iconBackground ? props.iconBackground : "")
      }
    >
      <div
        className={
          "page-icon " + (props.iconBackground ? props.iconBackground : "")
        }
        hidden={isMobile && props.innerPage}
      >
        {props.icon && <IonIcon icon={props.icon} />}
      </div>
      {props.externalLink ? (
        <IonRouterLink
          target={props.target ? props.target : "_blank"}
          href={props.externalLink}
        >
          <IonItem
            lines="none"
            disabled={props.innerPage}
            button={props.isButton !== undefined ? props.isButton : true}
            mode="md"
            detail={false}
            className={
              (props.icon ? "activityItem " : "") +
              // (isMobile ? "ion-margin-horizontal " : "") +
              (props.innerPage && props.iconBackground
                ? props.iconBackground
                : "") +
              (props.class ? props.class : "")
            }
            {...props}
          >
            <IonLabel>
              <h3
                className={
                  "bold-text " + (!props.innerPage ? "small-title" : "")
                }
              >
                {props.mainTitle}
              </h3>
              {props.children}
              <p className={props.innerPage ? "subtitle" : ""}>
                {props.subTitle}
              </p>
            </IonLabel>
          </IonItem>
        </IonRouterLink>
      ) : (
        <IonItem
          lines="none"
          disabled={props.linkItem ? false : props.innerPage}
          button={props.isButton !== undefined ? props.isButton : true}
          mode="md"
          detail={false}
          className={
            (props.icon ? "activityItem " : "") +
            // (isMobile ? "ion-margin-horizontal " : "") +
            (props.innerPage && props.iconBackground
              ? props.iconBackground
              : "") +
            (props.class ? props.class : "")
          }
          {...props}
        >
          <IonLabel
            style={{
              display: `${props.pageName === "MemberLeads" ? "flex" : ""}`,
            }}
          >
            <h3
              className={"bold-text " + (!props.innerPage ? "small-title" : "")}
            >
              {props.mainTitle}
            </h3>
            {props.children}
            <p className={`inline-block ${props.innerPage ? "subtitle" : ""}`}>
              {props.subTitle}
              {props.linkItem && (
                <IonRouterLink
                  className="small-p bold-text underline"
                  routerLink={props.linkItem.linkValue}
                  style={{ marginRight: "12px" }}
                >
                  {props.linkItem.textValue}
                </IonRouterLink>
              )}
            </p>
          </IonLabel>
        </IonItem>
      )}
    </div>
  );
};

export default CustomHeaddingItem;
