import {
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { base64FromPath } from "@ionic/react-hooks/filesystem";
import { chevronDownOutline, chevronUpOutline } from "ionicons/icons";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import CouponContext, {
  CouponAdditionalInfo,
  CouponMember,
} from "../../../data/Coupon-context";
import { HeaderParameters } from "../../../data/Header-Context";
import MemberDetailsContext from "../../../data/MemberDetailes-context";
import couponPresentIMG from "../../../images/coupons/couponPresent.jpg";
import defaultFriendNew from "../../../images/coupons/defaultFriendNew.png";
import defaultPersonallyCustomizedIMG from "../../../images/coupons/defaultPersonallyCustomized.jpg";
import orderProdutGetSurpriseIMG from "../../../images/coupons/orderProdutGetSurprise.jpg";
import pricePlusDiscountPercentIMG from "../../../images/coupons/pricePlusDiscountPercent.jpg";
import {
  addDaysToDate,
  addMonthsToDate,
  dateToISOString,
  getDateObjFromIsoString,
  getDateReverseFormat,
} from "../../../utils-ts/GlobalDateFunctions";
import CustomAreYouSureAlert from "../../utils/CustomElements/CustomAreYouSureAlert";
import CustomDateTime from "../../utils/CustomElements/CustomDateTime";
import CustomForm from "../../utils/CustomElements/CustomForm";
import CustomInput from "../../utils/CustomElements/CustomInput";
import CustomModal from "../../utils/CustomElements/CustomModal/CustomModal";
import CustomPublishButton from "../../utils/CustomElements/CustomPublishButton";
import CustomToggle from "../../utils/CustomElements/CustomToggle";
import ImagePicker, { Photo } from "../../utils/ImagePicker/ImagePicker";
import "./MemberCoupon.scss";

const HandleMemberCoupon: React.FC<{
  show: boolean;
  isNewCoupon: boolean;
  closeModal: (isDeleted: boolean) => void;
  onSave: () => void;
}> = (props) => {
  // #region States
  const [
    couponsQuantitySelected,
    setCouponsQuantitySelected,
  ] = useState<boolean>(false);
  const [couponActive, setCouponActive] = useState<boolean>(false);
  const [for144ClientsOnly, setFor144ClientsOnly] = useState<boolean>(false);
  const [showDiscountPct, setShowDiscountPct] = useState<boolean>(false);
  const [selectedToDate, setSelectedToDate] = useState<string>("");
  const [couponImage, setCouponImage] = useState<Photo>();
  const [selectedFromDate, setSelectedFromDate] = useState<string>("");
  const [dropownDateVal, setDropownDateVal] = useState<number>(-1);
  const [showAreYouSure, setShowAreYouSure] = useState(false);
  const [isExpanded, setAccordionMode] = useState(false);
  const [templateID, setTemplateID] = useState<number>(0);
  // #endregion States
  // #region Refs
  const AdditionalInfoTextRef = useRef<HTMLIonInputElement>(null);
  const QuantityTextRef = useRef<HTMLIonInputElement>(null);
  const CouponDescTextRef = useRef<HTMLIonInputElement>(null);
  const PriceAfterDiscountTextRef = useRef<HTMLIonInputElement>(null);
  const PriceBeforeDiscountTextRef = useRef<HTMLIonInputElement>(null);
  const IfOrderTextRef = useRef<HTMLIonInputElement>(null);
  const ThenGetTextRef = useRef<HTMLIonInputElement>(null);
  const DiscountPercentTextRef = useRef<HTMLIonInputElement>(null);
  const saveButtonRef = useRef<HTMLIonInputElement>(null);
  // #endregion Refs
  let couponCtx = useContext(CouponContext);
  const MemberDetailsCxt = useContext(MemberDetailsContext);
  const {
    handleSubmit,
    formState: { dirty },
    errors,
    control,
    reset,
  } = useForm({
    mode: "onChange",
  });

  const couponMember = couponCtx.coupon?.CouponMember;
  const couponTemplatesList = couponCtx.coupon?.CouponsTemplatesList;
  let currentCoupon = { ...couponCtx.coupon?.CouponMember } as CouponMember;
  let currentCouponAInfo = {
    ...couponCtx.coupon?.CouponAdditionalInfo,
  } as CouponAdditionalInfo;
  const couponAInfo = couponCtx.coupon?.CouponAdditionalInfo;
  var current_year = new Date().getFullYear();
  const yearValues = [current_year,current_year + 1,current_year + 2,current_year + 3, current_year + 4];
  // #region Effects
  useEffect(() => {
    if (couponCtx.coupon == null) {
      couponCtx.initCouponContext(true);
    }
  }, []);

  useEffect(() => {
    if (couponAInfo && !couponAInfo?.IsDatesValid) {
      setDropownDateVal(1);
    }
    setCouponImage({
      path: undefined,
      preview: couponAInfo?.ImgTemplate
        ? couponAInfo.ImgTemplate
        : templateID
        ? fillterImgTemplate(templateID)!
        : defaultFriendNew,
    });
  }, [couponAInfo, templateID]);

  useEffect(() => {
    setTemplateID(couponMember?.TemplateId!);

    if (couponMember) {
      setCouponActive(couponMember?.CouponActiv!);
      setFor144ClientsOnly(couponMember?.For144ClientsOnly!);
      setShowDiscountPct(couponMember?.showDiscountPct!);
      if (couponMember?.Quantity && couponMember?.Quantity > 0) {
        setCouponsQuantitySelected(true);
      }
      if (!couponMember.TemplateId) return;
      if (couponMember?.FromDate) {
        if (couponMember?.FromDate.indexOf("T") > -1) {
          setSelectedFromDate(couponMember?.FromDate);
        } else {
          setSelectedFromDate(dateToISOString(couponMember!.FromDate));
        }
      }
      if (couponMember?.ToDate) {
        if (couponMember?.ToDate.indexOf("T") > -1) {
          setSelectedToDate(couponMember?.ToDate);
        } else {
          setSelectedToDate(dateToISOString(couponMember!.ToDate));
        }
      }
    }
  }, [couponMember]);
  // #endregion Effects

  const toggleChecked = () => setAccordionMode((value) => !value);
  var couponImages = [
    { id: 8, url: pricePlusDiscountPercentIMG },
    { id: 9, url: couponPresentIMG },
    { id: 10, url: orderProdutGetSurpriseIMG },
    { id: 11, url: defaultPersonallyCustomizedIMG },
  ];

  const today = new Date();

  const todayDateFormat = getDateReverseFormat(today);

  const fillterImgTemplate = (tempID: number) => {
    if (couponImages) {
      const obj = couponImages.filter((o) => o.id === tempID);
      if (obj[0]) {
        return obj[0].url;
      }
    }
  };

  const pasreToDates = (value: number) => {
    setDropownDateVal(value);
    switch (value) {
      case 0: //טווח תאריכים
        if (!selectedFromDate) {
          setSelectedFromDate(todayDateFormat);
        }
        if (!selectedToDate) {
          setSelectedToDate(todayDateFormat);
        }
        break;
      case 2: // שעות 24
        setSelectedFromDate(todayDateFormat);
        setSelectedToDate(getDateReverseFormat(addDaysToDate(today, 1)));
        break;

      case 3: // יומיים
        setSelectedFromDate(todayDateFormat);
        setSelectedToDate(getDateReverseFormat(addDaysToDate(today, 2)));
        break;

      case 4: //שבוע
        setSelectedFromDate(todayDateFormat);
        setSelectedToDate(getDateReverseFormat(addDaysToDate(today, 7)));
        break;

      case 5: //חודש
        setSelectedFromDate(todayDateFormat);
        setSelectedToDate(getDateReverseFormat(addMonthsToDate(today, 1)));
        break;

      case 6: //שנה
        setSelectedFromDate(todayDateFormat);
        setSelectedToDate(getDateReverseFormat(addMonthsToDate(today, 12)));
        break;
    }
  };
  const saveHandler = async () => {
    if (!templateID) {
      toast.error("אנא בחר/י תבנית קופון");
    } else if (!selectedFromDate || !selectedToDate) {
      toast.error("אנא בחר/י תאריך לקופון");
    } else {
      if (currentCoupon) {
        currentCoupon.TemplateId = templateID!;
        currentCoupon.CouponDesc =
          templateID === 9
            ? `רוכשים ${IfOrderTextRef.current?.value?.toString()} ומקבלים ${ThenGetTextRef.current?.value?.toString()}`
            : CouponDescTextRef.current?.value?.toString() ||
              couponMember?.CouponDesc;
        currentCoupon.CouponActiv = couponActive;
        currentCoupon.For144ClientsOnly = for144ClientsOnly;
        currentCoupon.AdditionalInfo =
          AdditionalInfoTextRef.current?.value?.toString() ||
          couponMember?.AdditionalInfo;
        if (dropownDateVal !== 1) {
          currentCoupon.FromDate =
            selectedFromDate.indexOf("T") > -1
              ? moment(selectedFromDate).toISOString(true)
              : moment(new Date(selectedFromDate)).toISOString(true);

          currentCoupon.ToDate =
            selectedToDate.indexOf("T") > -1
              ? moment(selectedToDate).toISOString(true)
              : moment(new Date(selectedToDate)).toISOString(true);
        } else if (dropownDateVal === 1) {
          //כל הזמן (בלי הגבלה)
          currentCoupon.FromDate = "";
          currentCoupon.ToDate = "";
        }
        if (dropownDateVal !== 1) {
          currentCouponAInfo.IsDatesValid = true;
        } else {
          currentCouponAInfo.IsDatesValid = false;
        }

        currentCoupon.Quantity =
          +QuantityTextRef.current?.value! || couponMember?.Quantity!;
        currentCoupon.ifOrder =
          IfOrderTextRef.current?.value?.toString() || couponMember?.ifOrder;
        currentCoupon.thenGet =
          ThenGetTextRef.current?.value?.toString() || couponMember?.thenGet;
        currentCoupon.DiscountPercent =
          +DiscountPercentTextRef.current?.value! ||
          couponMember?.DiscountPercent!;
        currentCoupon.priceAfterDiscount =
          +PriceAfterDiscountTextRef.current?.value! ||
          couponMember?.priceAfterDiscount!;
        currentCoupon.priceBeforeDiscount =
          +PriceBeforeDiscountTextRef.current?.value! ||
          couponMember?.priceBeforeDiscount!;
        currentCoupon.showDiscountPct = showDiscountPct ? true : false;

        currentCoupon.couponsQuantityRG = +couponsQuantitySelected;
        currentCoupon.couponImage =
          couponImage?.preview?.indexOf("base64")! > -1
            ? await base64FromPath(couponImage!.preview)
            : couponAInfo?.ImgTemplate
            ? couponAInfo.ImgTemplate
            : "";
        currentCouponAInfo.ImgTemplate = currentCoupon.couponImage;
        couponCtx
          .saveCoupon(currentCoupon, currentCouponAInfo)
          .then((res: any) => {
            if (res) {
              MemberDetailsCxt.initContentStatusContext();
              dtlPushEvt({
                event: "coupons",
                eventInfo: {
                  ScreenName: "{{page path}}",
                  user_action: "adds_coupon",
                  business_name:
                    "" +
                    (MemberDetailsCxt.GeneralInfo
                      ? MemberDetailsCxt.GeneralInfo?.CompanyName
                      : ""),
                  business_category: GetMemberAdvCategories(
                    MemberDetailsCxt.AdvCategories
                  ),
                  business_location: MemberDetailsCxt.BusinessAddress?.City,
                },
              });

              if (!currentCoupon.CouponActiv) {
                props.closeModal(true);
              } else {
                props.closeModal(false);
              }
            }
          });
      }
    }
  };
  const photoPickHandler = (photo: Photo) => {
    setCouponImage({
      path: undefined,
      preview: photo.preview,
      name: photo.name,
      size: photo.size,
    });
  };

  const headerParameters: HeaderParameters = {
    isPageHaveChanges: dirty,
    isShowDeleteButton: !props.isNewCoupon,
    deleteFunc: () => {
      couponCtx.deleteCoupon().then((res: any) => {
        if (res) {
          MemberDetailsCxt.initContentStatusContext();

          dtlPushEvt({
            event: "coupons",
            eventInfo: {
              ScreenName: "{{page path}}",
              user_action: "deletes_coupon",
              business_name:
                "" +
                (MemberDetailsCxt.GeneralInfo
                  ? MemberDetailsCxt.GeneralInfo?.CompanyName
                  : ""),
              business_category: GetMemberAdvCategories(
                MemberDetailsCxt.AdvCategories
              ),
              business_location: MemberDetailsCxt.BusinessAddress?.City,
            },
          });

          props.closeModal(true);
        }
      });
    },
    modalType: "modal",
  };

  const dropdownTemplateFunc = (e: CustomEvent) => {
    setTemplateID(e.detail.value);
    setCouponImage({
      path: undefined,
      preview: couponAInfo?.ImgTemplate
        ? couponAInfo.ImgTemplate
        : templateID
        ? fillterImgTemplate(e.detail.value)!
        : defaultFriendNew,
    });

    setCouponsQuantitySelected(false);
    // setDropownDateVal(1);
    if (CouponDescTextRef.current && couponMember?.CouponDesc)
      CouponDescTextRef.current.value = couponMember?.CouponDesc;
  };

  return (
    <CustomModal
      pageName={"HandleMemberCoupon"}
      headerParameters={headerParameters}
      closeModal={() => {
        props.closeModal(false);
      }}
      isOpen={props.show}
      title="הקמה ועדכון קופון לעסק"
    >
      <CustomForm onSubmit={handleSubmit(saveHandler)} errors={errors}>
        <IonGrid className="ion-no-padding ion-padding-horizontal">
          <IonRow className="ion-justify-content-between">
            {/* start - column right (picture) desktop mode  */}
            <IonCol
              size="12"
              sizeMd="5.9"
              style={{ order: !isMobile ? "2" : "" }}
            >
              <IonRow className="ion-text-center">
                <IonCol>
                  <ImagePicker
                    onImagePick={photoPickHandler}
                    currentPhoto={couponImage}
                    isMultiple={false}
                  />
                </IonCol>
              </IonRow>
            </IonCol>

            {/* start - column left (input fields) desktop mode  */}
            <IonCol size="12" sizeMd="5.6">
              <IonGrid>
                <IonRow>
                  <IonCol className="ion-padding-vertical">
                    <IonItem color="light" lines="full" mode="md">
                      <IonLabel position="stacked">תבנית קופון</IonLabel>
                      <IonSelect
                        color="light"
                        cancelText="ביטול"
                        okText="אישור"
                        value={templateID}
                        placeholder="בחר תבנית"
                        onIonChange={(e) => {
                          dropdownTemplateFunc(e);
                        }}
                      >
                        {couponTemplatesList &&
                          couponTemplatesList.map((t) => {
                            return (
                              <IonSelectOption key={t.Id} value={t.Id}>
                                {t.Description}
                              </IonSelectOption>
                            );
                          })}
                      </IonSelect>
                    </IonItem>
                  </IonCol>
                </IonRow>

                {templateID === 2 ? (
                  <>
                    <IonRow>
                      <IonCol>
                        <CustomInput
                          key={"CouponDesc1"}
                          name="CouponDesc"
                          errors={errors}
                          control={control}
                          label={"על איזה מוצר מקבלים הנחה?"}
                          required={true}
                          type="text"
                          value={couponMember?.CouponDesc}
                          ref={CouponDescTextRef}
                          maxlength={80}
                          minlength={24}
                          max="80"
                          min="24"
                        />
                      </IonCol>
                    </IonRow>
                  </>
                ) : [7, 8].includes(templateID) ? (
                  <React.Fragment>
                    <IonRow>
                      <IonCol>
                        <CustomInput
                          key={"CouponDesc2"}
                          name="CouponDesc"
                          errors={errors}
                          control={control}
                          label="על איזה מוצר מקבלים הנחה?"
                          required={true}
                          type="text"
                          value={couponMember?.CouponDesc}
                          ref={CouponDescTextRef}
                          maxlength={80}
                          minlength={24}
                          max="80"
                          min="24"
                        />
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol className={isMobile ? "ion-margin-top" : ""}>
                        <CustomInput
                          key={"priceAfterDiscount"}
                          name="priceAfterDiscount"
                          errors={errors}
                          control={control}
                          label="מחיר לאחר הנחה"
                          required={true}
                          type="number"
                          value={couponMember?.priceAfterDiscount}
                          ref={PriceAfterDiscountTextRef}
                        />
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol className={isMobile ? "ion-margin-top" : ""}>
                        <CustomInput
                          key={"priceBeforeDiscount"}
                          name="priceBeforeDiscount"
                          errors={errors}
                          control={control}
                          label="מחיר לפני הנחה"
                          required={true}
                          type="number"
                          value={couponMember?.priceBeforeDiscount}
                          ref={PriceBeforeDiscountTextRef}
                        />
                      </IonCol>
                    </IonRow>
                  </React.Fragment>
                ) : templateID === 9 ? (
                  <React.Fragment>
                    <IonRow>
                      <IonCol>
                        <CustomInput
                          key={"ifOrder"}
                          name="ifOrder"
                          errors={errors}
                          control={control}
                          label="רוכשים"
                          required={true}
                          type="text"
                          value={couponMember?.ifOrder}
                          ref={IfOrderTextRef}
                          maxlength={50}
                          minlength={12}
                          max="50"
                          min="12"
                        />
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <CustomInput
                          key={"thenGet"}
                          name="thenGet"
                          errors={errors}
                          control={control}
                          label="ומקבלים"
                          required={true}
                          type="text"
                          value={couponMember?.thenGet}
                          ref={ThenGetTextRef}
                          maxlength={50}
                          minlength={12}
                          max="50"
                          min="12"
                        />
                      </IonCol>
                    </IonRow>
                  </React.Fragment>
                ) : templateID === 10 ? (
                  <IonRow>
                    <IonCol>
                      <CustomInput
                        key={"CouponDesc3"}
                        name="CouponDesc"
                        errors={errors}
                        control={control}
                        label="סוג המתנה או ההטבה"
                        required={true}
                        type="text"
                        value={couponMember?.CouponDesc}
                        ref={CouponDescTextRef}
                        maxlength={80}
                        minlength={24}
                        max="80"
                        min="24"
                      />
                    </IonCol>
                  </IonRow>
                ) : (
                  templateID === 11 && (
                    <React.Fragment>
                      <IonRow>
                        <IonCol>
                          <CustomInput
                            key={"CouponDesc4"}
                            name="CouponDesc"
                            errors={errors}
                            control={control}
                            label="על איזה מוצר מקבלים הנחה?"
                            required={true}
                            type="text"
                            value={couponMember?.CouponDesc}
                            ref={CouponDescTextRef}
                            maxlength={80}
                            minlength={24}
                            max="80"
                            min="24"
                          />
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <CustomInput
                            key={"DiscountPercent"}
                            name="DiscountPercent"
                            errors={errors}
                            control={control}
                            label="מה אחוז ההנחה?"
                            required={true}
                            type="number"
                            value={couponMember?.DiscountPercent}
                            ref={DiscountPercentTextRef}
                            max="3"
                            maxlength={3}
                          />
                        </IonCol>
                      </IonRow>
                    </React.Fragment>
                  )
                )}
              </IonGrid>
            </IonCol>
          </IonRow>
          {!!templateID && (
            <>
              <IonRow className="ion-justify-content-between date-range-picker">
                <IonCol size="12" sizeMd="5.6">
                  <IonItem color="light" lines="full" mode="md">
                    <IonLabel position="stacked">תוקף הקופון</IonLabel>
                    <IonSelect
                      value={String(dropownDateVal)}
                      cancelText="ביטול"
                      okText="אישור"
                      onIonChange={(e) => {
                        e.stopPropagation();
                        setDropownDateVal(+e.detail.value);
                        pasreToDates(+e.detail.value);
                      }}
                    >
                      <IonSelectOption key="0" value="0">
                        טווח תאריכים
                      </IonSelectOption>
                      <IonSelectOption key="1" value="1">
                        כל הזמן (בלי הגבלה)
                      </IonSelectOption>
                      <IonSelectOption key="2" value="2">
                        24 שעות
                      </IonSelectOption>
                      <IonSelectOption key="3" value="3">
                        יומיים
                      </IonSelectOption>
                      <IonSelectOption key="4" value="4">
                        שבוע
                      </IonSelectOption>
                      <IonSelectOption key="5" value="5">
                        חודש
                      </IonSelectOption>
                      <IonSelectOption key="6" value="6">
                        שנה
                      </IonSelectOption>
                    </IonSelect>
                  </IonItem>
                </IonCol>

                {dropownDateVal === 0 && selectedFromDate && selectedToDate && (
                  <IonCol size="12" sizeMd="5.9" className="ion-no-padding">
                    <IonGrid>
                      <IonRow className="ion-justify-content-between">
                        <IonCol
                          size="5.9"
                          className={isMobile ? "ion-margin-top" : ""}
                        >
                          <CustomDateTime
                            label="תאריך התחלה"
                            required={true}
                            displayFormat="DD/MM/YYYY"
                            name="fromtime"
                            placeholder="בחר תאריך"
                            value={selectedFromDate}
                            yearValues={yearValues}
                            control={control}
                            errors={errors}
                            onIonChange={(e) => {
                              setSelectedFromDate(e.detail.value!);
                            }}
                          ></CustomDateTime>
                        </IonCol>

                        <IonCol
                          size="5.9"
                          className={isMobile ? "ion-margin-top" : ""}
                        >
                          <CustomDateTime
                            label="תאריך סיום"
                            required={true}
                            displayFormat="DD/MM/YYYY"
                            name="totime"
                            placeholder="בחר תאריך"
                            value={selectedToDate}
                            min={selectedFromDate}
                            yearValues={yearValues}
                            control={control}
                            errors={errors}
                            onIonChange={(e) => {
                              setSelectedToDate(e.detail.value!);
                            }}
                          ></CustomDateTime>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCol>
                )}
                {![-1, 0, 1].includes(dropownDateVal) && (
                  <IonCol
                    size="12"
                    sizeMd="5.9"
                    className="ion-margin-vertical"
                  >
                    <p>
                      מתאריך:
                      {getDateObjFromIsoString(selectedFromDate)
                        .split("/")
                        .reverse()
                        .join("/")}
                      - עד תאריך:
                      {getDateObjFromIsoString(selectedToDate)
                        .split("/")
                        .reverse()
                        .join("/")}
                    </p>
                  </IonCol>
                )}
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonGrid className="accordion-wrapper">
                    <IonItem
                      button
                      mode="md"
                      lines="full"
                      className="accordion-item"
                      onClick={() => {
                        toggleChecked();
                        !isExpanded &&
                          setTimeout(
                            () =>
                              saveButtonRef.current?.scrollIntoView({
                                behavior: "smooth",
                              }),
                            200
                          );
                      }}
                    >
                      <IonLabel>
                        <h3 className="small-title bold-text">תנאי קופון</h3>
                      </IonLabel>
                      <IonIcon
                        icon={
                          isExpanded ? chevronUpOutline : chevronDownOutline
                        }
                        size="small"
                        slot="end"
                      />
                    </IonItem>

                    <IonRow
                      className={isExpanded ? "content is-expanded" : "content"}
                    >
                      <IonCol>
                        <IonRow>
                          <IonCol
                            size="12"
                            sizeMd="5.9"
                            className={
                              "ion-no-padding " +
                              (isMobile ? "ion-margin-bottom" : "")
                            }
                          >
                            <CustomToggle
                              onIonChange={(e) =>
                                setFor144ClientsOnly(e.detail.checked)
                              }
                              checked={for144ClientsOnly}
                              titleTxt="בלעדי ללקוחות B144"
                              modeTitleOptions={{
                                onFalse: "לא מוגבלת",
                                onTrue: "מוגבלת",
                              }}
                            />
                          </IonCol>
                        </IonRow>

                        <IonRow>
                          <IonCol
                            size="12"
                            sizeMd="5.9"
                            className={
                              "ion-no-padding " +
                              (isMobile ? "ion-margin-top" : "")
                            }
                          >
                            <CustomToggle
                              onIonChange={(e) =>
                                setCouponsQuantitySelected(e.detail.checked)
                              }
                              checked={couponsQuantitySelected}
                              titleTxt="כמות קופונים למימוש"
                              modeTitleOptions={{
                                onFalse: "לא מוגבלת",
                                onTrue: "מוגבלת",
                              }}
                            />
                          </IonCol>
                        </IonRow>
                        {couponsQuantitySelected && (
                          <IonRow>
                            <IonCol
                              size="12"
                              sizeMd="5.9"
                              className="ion-padding-bottom"
                            >
                              <CustomInput
                                key="Quantity"
                                name="Quantity"
                                errors={errors}
                                control={control}
                                label="מספר הקופונים שאפשר יהיה לממש"
                                type="number"
                                value={couponMember?.Quantity}
                                ref={QuantityTextRef}
                                required={true}
                              />
                            </IonCol>
                          </IonRow>
                        )}
                        <IonRow>
                          <IonCol size="12" sizeMd="5.9">
                            <CustomInput
                              key="AdditionalInfo"
                              name="AdditionalInfo"
                              errors={errors}
                              control={control}
                              label="האותיות הקטנות"
                              type="text"
                              value={couponMember?.AdditionalInfo}
                              ref={AdditionalInfoTextRef}
                              min="10"
                              minlength={10}
                              max="29"
                              maxlength={29}
                            />
                          </IonCol>
                        </IonRow>

                        <CustomAreYouSureAlert
                          cancelFunc={() => {
                            setShowAreYouSure(false);
                          }}
                          continueFunc={() => {
                            setShowAreYouSure(false);
                            // dropdownTemplateFunc();
                          }}
                          isOpen={showAreYouSure}
                          message={
                            "האם אתה בטוח? במידה ותעבור תבנית כל התוכן יאבד"
                          }
                        ></CustomAreYouSureAlert>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCol>
              </IonRow>
              {/* start - action button (align to left) desktop mode  */}

              <IonRow
                className="ion-justify-content-center"
                ref={saveButtonRef}
              >
                <IonCol size="12" sizeMd="6">
                  <CustomPublishButton title="שמירה"></CustomPublishButton>
                </IonCol>
              </IonRow>
            </>
          )}
        </IonGrid>
      </CustomForm>
    </CustomModal>
  );
};

export default HandleMemberCoupon;
