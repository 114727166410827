import React, { useRef, useState } from "react";
import { IonContent, IonGrid, IonRow, IonCol } from "@ionic/react";
import { BusinessEvent } from "../../../data/MemberDetailes-context";
import CustomInput from "../../utils/CustomElements/CustomInput";
import CustomTextarea from "../../utils/CustomElements/CustomTextarea";
import { useForm } from "react-hook-form";
import {
  getTimeObjFromIsoString,
  dateToISOString,
} from "../../../utils-ts/GlobalDateFunctions";
import CustomDateTime from "../../utils/CustomElements/CustomDateTime";
import CustomForm from "../../utils/CustomElements/CustomForm";
import CustomPublishButton from "../../utils/CustomElements/CustomPublishButton";
import CustomModal from "../../utils/CustomElements/CustomModal/CustomModal";
import { HeaderParameters } from "../../../data/Header-Context";
import BusinessEvents from "./BusinessEvents";

const HandleBusinessEvents: React.FC<{
  show: boolean;
  event: BusinessEvent | null;
  closeModal: () => void;
  onSave: (links: BusinessEvent) => void;
}> = (props) => {
  const { handleSubmit, formState, errors, control } = useForm({
    mode: "onChange",
  });
  const { dirty } = formState;
  const CityRef = useRef<HTMLIonInputElement>(null);
  const DescriptionTextRef = useRef<HTMLIonTextareaElement>(null);
  const LinkRef = useRef<HTMLIonInputElement>(null);
  const MessageRef = useRef<HTMLIonInputElement>(null);
  const StreetRef = useRef<HTMLIonInputElement>(null);
  const Street_NoRef = useRef<HTMLIonInputElement>(null);
  const [fromTime, setFromTime] = useState(() => {
    return props.event != null ? props.event.StartHour : "";
  });
  const [toTime, setToTime] = useState(() => {
    return props.event != null ? props.event.EndHour : "";
  });
  const [fromDate, setFromDate] = useState(() => {
    return props.event != null ? dateToISOString(props.event.StartDate) : "";
  });
  const [toDate, setToDate] = useState(() => {
    return props.event != null ? dateToISOString(props.event.EndDate) : "";
  });
  const [currentDescription, setCurrenDescription] = useState<string>(() => {
    return props.event != null ? props.event.Description : "";
  });
  const [currentLink, setCurrenLink] = useState<string>(() => {
    return props.event != null ? props.event.Link : "";
  });
  const [currentCity, setCurrenCity] = useState<string>(() => {
    return props.event != null ? props.event.City : "";
  });
  const [currentMessage, setCurrenMessage] = useState<string>(() => {
    return props.event != null ? props.event.Message : "";
  });
  const [currentStreet, setCurrenStreet] = useState<string>(() => {
    return props.event != null ? props.event.Street : "";
  });
  const [currentStreet_No, setCurrenStreet_No] = useState<string>(() => {
    return props.event != null ? props.event.Street_No : "";
  });

  const [currentId, setCurrenId] = useState<number>(() => {
    return props.event != null ? props.event.Id : -99;
  });

  const saveHandler = () => {
    //update and new
    const newDecription = DescriptionTextRef.current!.value!.toString();
    const newCity = CityRef.current!.value!.toString();
    const newStreet = StreetRef.current!.value!.toString();
    const newStreetNo = Street_NoRef.current!.value!.toString();
    const newLink = LinkRef.current!.value!.toString();
    const newMessage = MessageRef.current!.value!.toString();
    const eventObj: BusinessEvent = {
      City: newCity,
      isDelete: false,
      Description: newDecription,
      Street: newStreet,
      Street_No: newStreetNo,
      Message: newMessage,
      Link: newLink,
      StartDate: fromDate,
      EndDate: toDate,
      StartHour: fromTime,
      EndHour: toTime,
      Id: currentId,
    };
    props.onSave(eventObj);
  };

  const headerParameters: HeaderParameters = {
    isShowLogo: true,
    isPageHaveChanges: dirty,
    isShowDeleteButton: props.event != null,
    deleteFunc: () => {
      if (props.event != null) {
        const deleteObj = { ...props.event };
        deleteObj.isDelete = true;
        props.onSave(deleteObj);
      }
    },
  };
  return (
    <CustomModal
      pageName={"BusinessEvents"}
      headerParameters={headerParameters}
      title="לוח אירועים"
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
    >
      <CustomForm onSubmit={handleSubmit(saveHandler)} errors={errors}>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonCol>
                <CustomInput
                  name="messageEvent"
                  errors={errors}
                  control={control}
                  label="אירוע"
                  required={true}
                  ref={MessageRef}
                  value={currentMessage}
                />
              </IonCol>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <CustomTextarea
                name="DescEvent"
                onClearFunc={() => {
                  DescriptionTextRef.current!.value = "";
                }}
                errors={errors}
                control={control}
                label="תאור"
                ref={DescriptionTextRef}
                value={currentDescription}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <CustomInput
                name="link"
                errors={errors}
                control={control}
                label="לינק"
                type="url"
                ref={LinkRef}
                value={currentLink}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <CustomInput
                name="city"
                errors={errors}
                control={control}
                label="עיר"
                ref={CityRef}
                value={currentCity}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <CustomInput
                name="street"
                errors={errors}
                control={control}
                label="רחוב"
                ref={StreetRef}
                value={currentStreet}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <CustomInput
                name="streetNo"
                errors={errors}
                control={control}
                label="מס רחוב"
                ref={Street_NoRef}
                type="number"
                value={currentStreet_No}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <CustomDateTime
                label="תאריך התחלה"
                required={true}
                displayFormat="DD/MM/YYYY"
                name="fromDate"
                placeholder="בחר תאריך"
                value={fromDate!}
                control={control}
                errors={errors}
                onIonChange={(e) => {
                  if (e.detail.value) {
                    setFromDate(e.detail.value);
                  }
                }}
              ></CustomDateTime>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <CustomDateTime
                label="שעת התחלה"
                required={true}
                displayFormat="HH:mm"
                name="fromtime"
                placeholder="בחר שעה"
                value={fromTime!}
                control={control}
                errors={errors}
                onIonChange={(e) => {
                  let time = "";
                  if (e.detail.value) {
                    time = getTimeObjFromIsoString(e.detail.value);
                    setFromTime(time);
                  }
                }}
              ></CustomDateTime>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <CustomDateTime
                label="תאריך סיום"
                required={true}
                displayFormat="DD/MM/YYYY"
                name="toDate"
                placeholder="בחר תאריך"
                value={toDate!}
                control={control}
                errors={errors}
                onIonChange={(e) => {
                  if (e.detail.value) {
                    setToDate(e.detail.value);
                  }
                }}
              ></CustomDateTime>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <CustomDateTime
                label="שעת סיום"
                required={true}
                displayFormat="HH:mm"
                name="totime"
                placeholder="בחר שעה"
                value={toTime!}
                control={control}
                errors={errors}
                onIonChange={(e) => {
                  let time = "";
                  if (e.detail.value) {
                    time = getTimeObjFromIsoString(e.detail.value);
                    setToTime(time);
                  }
                }}
              ></CustomDateTime>
            </IonCol>
          </IonRow>
          <IonRow className="ion-text-center">
            <IonCol>
              <CustomPublishButton></CustomPublishButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </CustomForm>
    </CustomModal>
  );
};

export default HandleBusinessEvents;
