import React from "react";
import { IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { arrowDown, arrowUp } from "ionicons/icons";
import "./CustomTrend.scss";

const CustomTrend: React.FC<{
  header?: string;
  subHeader?: string;
  PrevValue: number;
  value: number;
  icon?: string;
  isShowTrend?: boolean;
  isHideIcon?: boolean;
  valColSize?: string;
  className?: string;
  style?: React.CSSProperties;
}> = (props) => {
  const trendValue =
    props.PrevValue > 0
      ? (props.value / props.PrevValue - 1) * 100
      : props.value > 0
      ? (props.PrevValue / props.value + 1) * 100
      : undefined;
  return (
    <IonGrid
      className={`trends ${props.className ? props.className : ""}`}
      style={props.style}
    >
      <IonRow>
        {!props.isHideIcon && <IonCol className="ion-margin-end" size="auto">
          {props.icon && (
            <IonIcon className="trends-icon" icon={props.icon}></IonIcon>
          )}
        </IonCol>}
        <IonCol className="trends-headers-col">
          {props.header && (
            <IonRow>
              <IonCol>
                <h1 className="trends-header ion-no-margin">{props.header}</h1>
              </IonCol>
            </IonRow>
          )}
          {props.subHeader && (
            <IonRow>
              <IonCol>
                <h2 className="trends-subheader ion-no-margin">
                  {props.subHeader}
                </h2>
              </IonCol>
            </IonRow>
          )}
        </IonCol>
        <IonCol className="trends-value-col" size="auto">
          {(props.isShowTrend === undefined || props.isShowTrend !== false) &&
            trendValue !== undefined && (
              <span className="percentage">
                {Math.trunc(Math.abs(trendValue))}%
                <IonIcon
                  className="icon"
                  icon={trendValue > 0 ? arrowUp : arrowDown}
                ></IonIcon>
              </span>
            )}
          <p className="trends-value">{Math.trunc(props.value)} </p>
        </IonCol>
      </IonRow>
    </IonGrid>

    // <IonGrid
    //   style={{
    //     marginLeft: "9px",
    //   }}
    // >
    //   <IonRow className="ion-justify-content-between ion-margin-top ion-margin-bottom ion-align-items-start">
    //     {props.icon && (
    //       <IonCol size="2">
    //         <IonIcon className="trends-icon" icon={props.icon}></IonIcon>
    //       </IonCol>
    //     )}
    //     <IonCol className="trends-title-wraper ion-no-padding">
    //       {props.header && (
    //         <IonRow>
    //           <IonCol className="trends-title ion-no-padding">
    //             <p className="bold-text">{props.header}</p>
    //           </IonCol>
    //         </IonRow>
    //       )}
    //       {props.subHeader && (
    //         <IonRow>
    //           <IonCol className="trends-description ion-no-padding">
    //             {props.subHeader}
    //           </IonCol>
    //         </IonRow>
    //       )}
    //     </IonCol>
    //     <IonCol
    //       // size={props.valColSize ? props.valColSize : props.icon ? "4" : "5"}
    //       size="auto"
    //       className="trends-precentage ion-no-padding ion-margin-top"
    //       style={{
    //         marginRight: isMobile ? "auto" : "25px",
    //       }}
    //     >
    //       {(props.isShowTrend === undefined || props.isShowTrend !== false) &&
    //         trendValue !== undefined && (
    //           <div
    //             className="precentage-change"
    //             style={{
    //               marginTop: "-15px",
    //             }}
    //           >
    //             {Math.trunc(Math.abs(trendValue))}%{" "}
    //             <IonIcon
    //               className="emph-text"
    //               icon={trendValue > 0 ? arrowUp : arrowDown}
    //             ></IonIcon>
    //           </div>
    //         )}
    //       <div className="precentage-value">{Math.trunc(props.value)}</div>
    //     </IonCol>
    //   </IonRow>
    // </IonGrid>
  );
};
export default CustomTrend;
