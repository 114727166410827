import React, { useContext, useEffect, useRef, useState } from 'react';
import { IonGrid, IonRow, IonCol, IonLabel, IonIcon, IonCheckbox, IonItem, IonSelect, IonSelectOption, IonButton } from '@ionic/react';
import CustomInput from '../../utils/CustomElements/CustomInput';
import { useForm } from 'react-hook-form';
import CustomForm from '../../utils/CustomElements/CustomForm';
import MemberDetailsContext, { BusinessMovie } from '../../../data/MemberDetailes-context';
import CustomPublishButton from '../../utils/CustomElements/CustomPublishButton';
import CustomModal from '../../utils/CustomElements/CustomModal/CustomModal';
import { HeaderParameters } from '../../../data/Header-Context';
import { toast } from 'react-toastify';
import { closeOutline } from 'ionicons/icons';
import { isMobile } from 'react-device-detect';
import { config } from '../../../Constants';
const BusinessMovieCtrlNew: React.FC<{
  show: boolean;
  id: number;
  closeModal: () => void;
  onSave: () => void;
}> = (props) => {
  const { handleSubmit, formState, errors, control } = useForm({
    mode: 'onChange',
  });
  const { dirty } = formState;

  const MemberDetailsCxt = useContext(MemberDetailsContext);
  const [isSaveAlert, setIsSaveAlert] = useState(false);
  const [urlChange, setUrlChange] = useState(false);
  const [movieType, setMovieType] = useState<'YT' | 'FB' | 'TK' | 'IG'>('YT');
  const [tempTags, setTempTags] = useState<string[]>([]);
  const [currMovie, setCurrMovie] = useState<BusinessMovie | undefined>(() => {
    if (props.id > 0) {
      const currnt = MemberDetailsCxt.BusinessMovies?.find((i) => i.Id == props.id);
      setMovieType(currnt ? currnt.MovieType : 'YT');
      setTempTags(currnt ? currnt.TagsArray : []);
      return currnt;
    } else {
      return { Id: -1, MovieUrl: '', MovieDesc: '', IsFirst: MemberDetailsCxt.BusinessMovies?.length == 0, MovieType: 'YT', TagsArray: [] };
    }
  });
  const videoTypeOptions_old = [
    {
      value: 'YT',
      label: 'יוטיוב',
    }
  ];
  const videoTypeOptions = config.newMovieFormats ? [
    {
      value: 'YT',
      label: 'יוטיוב',
    },
    {
      value: 'FB',
      label: 'פייסבוק',
    },
    {
      value: 'TK',
      label: 'טיקטוק',
    },
    //{ value: 'IG', label: 'אינסטגרם' },
  ] : videoTypeOptions_old;
  const movieUrlRef = useRef<HTMLIonInputElement>(null);
  const movieDescRef = useRef<HTMLIonInputElement>(null);
  const movieTagscRef = useRef<HTMLIonInputElement>(null);
  const checkBoxAgreeRef = useRef<HTMLIonCheckboxElement>(null);
  const checkBoxFirstRef = useRef<HTMLIonCheckboxElement>(null);
  useEffect(() => {
    if (MemberDetailsCxt.BusinessMovies == null) {
      MemberDetailsCxt.initMovieContext();
    }
  }, []);

  useEffect(() => {
    if (movieTagscRef && movieTagscRef.current) {
      movieTagscRef.current!.value = '';
    }
  }, [tempTags]);

  const saveHandler = () => {
    if (!checkBoxAgreeRef.current?.checked && currMovie?.MovieUrl) {
      toast.info('עליך לאשר את תנאי השימוש');
      return;
    }

    if (currMovie) {
      currMovie.TagsArray = tempTags;
      setIsSaveAlert(true);
      MemberDetailsCxt.saveMovieData(currMovie).then((result: any) => {
        setIsSaveAlert(false);
        if (result) {
          props.onSave();
          props.closeModal();
        }
      });
    }
  };

  const headerParameters: HeaderParameters = {
    isPageHaveChanges: dirty,
    modalType: 'modal',
    isShowDeleteButton: props.id > 0,
    deleteFunc: () => {
      if (currMovie) {
        MemberDetailsCxt.saveMovieHandler(currMovie, 'delete');
        props.onSave();
        props.closeModal();
      }
    },
  };
  //   async function getPreviewUrl(movieUrl: string) {
  //     if (movieUrl.includes('youtube.com/shorts')) {
  //       setPreviewUrl( movieUrl.split('?')[0].replace('shorts/', 'watch?v='));
  //     }
  // else if(movieType == 'FB' || movieType == 'TK') { //movieUrl.startsWith('https://fb.watch/')){
  //   //getMovieUrl
  //   MemberDetailsCxt.getMovieData(movieType, movieUrl).then((response:any)=> {
  //     setPreviewUrl(response);
  //   })

  // } else{
  //   setPreviewUrl(movieUrl);
  // }

  //}
const getInputLabel = (movieType:string) => {
  let label = "כתובת הסרטון";
  switch (movieType){
case 'FB':
  label= "כתובת הסרטון בפייסבוק"
  break;
  case 'YT':
  label= "כתובת הסרטון ביוטיוב"
  break;
  case 'TK':
  label= "כתובת הסרטון בטיקטוק"
  break;
  case 'IG':
  label= "כתובת הסרטון באינסטגרם"
  break;
  }
  return label;

}
  return (
    <>
    <CustomModal
      pageName={'BusinessMovie'}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
      headerParameters={headerParameters}
      title="סרטון"
      subTitle="">
      <CustomForm onSubmit={handleSubmit(saveHandler)} errors={errors}>
        
        {currMovie != null && (
          <IonGrid>
            <IonRow>
              <IonCol size="12" >
                <IonRow className="input-row-group">
                  <IonCol size="12" sizeMd="5.9" style={isMobile ? { paddingBottom: 25 } : {}}>
                    <IonItem color="light" lines="full" mode="md">
                      <IonLabel position="stacked">פלטפורמה</IonLabel>
                      <IonSelect
                        color="light"
                        mode="md"
                        value={movieType}
                        interface="popover"
                        interfaceOptions={{
                          showBackdrop: false,
                        }}
                        placeholder="בחירה פלטפורמה"
                        onIonChange={(e) => {
                          e.stopPropagation();
                          setMovieType(e.detail.value);
                          setCurrMovie((movie) => {
                            let updatedMovie = movie;
                            if (updatedMovie) {
                              updatedMovie.MovieType = e.detail.value;
                            }
                            return updatedMovie;
                          });
                        }}>
                        { 
                        videoTypeOptions.map((v) => {
                          return (
                            <IonSelectOption key={v.value} value={v.value}>
                              {v.label}
                            </IonSelectOption>
                          );
                        })}
                      </IonSelect>
                    </IonItem>
                  </IonCol>
                  <IonCol size="12" sizeMd="5.9">
                    <CustomInput
                      name="movieUrl"
                      label={getInputLabel(movieType)}
                      errors={errors}
                      control={control}
                      required={true}
                      //patternType='YT'
                      maxlength={150}
                      type="url"
                      ref={movieUrlRef}
                      value={currMovie.MovieUrl}
                      onIonBlur={() => {
                        //getPreviewUrl(movieUrlRef.current!.value!.toString());
                        setCurrMovie((movie) => {
                          let updatedMovie = movie;
                          if (updatedMovie) {
                            updatedMovie.MovieUrl = movieUrlRef.current!.value!.toString();
                          }
                          return updatedMovie;
                        });
                        setUrlChange(true);
                      }}
                    />
                    
                  </IonCol>
                </IonRow>
                <IonRow className="input-row-group">
                  <IonCol size="12" sizeMd="5.9">
                    <CustomInput
                      name="movieDesc"
                      label="תאור הסרטון"
                      errors={errors}
                      control={control}
                      required={true}
                      maxlength={30}
                      type="text"
                      ref={movieDescRef}
                      value={currMovie.MovieDesc}
                      onIonBlur={() => {
                        setCurrMovie((movie) => {
                          let updatedMovie = movie;
                          if (updatedMovie) {
                            updatedMovie.MovieDesc = movieDescRef.current!.value!.toString();
                          }
                          return updatedMovie;
                        });
                      }}
                    />
                  </IonCol>
                  <IonCol size="12" sizeMd="5.9" >
                    <CustomInput
                      name="tags"
                      label="תגיות"
                      errors={errors}
                      control={control}
                      maxlength={30}
                      type="text"
                      ref={movieTagscRef}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          const val = movieTagscRef.current!.value!.toString();
                          setTempTags((curr) => {
                            // movieTagscRef.current!.value = '';
                            const tags = [...curr, ...val.split(',')];
                            return tags;
                          });
                        }
                      }}
                    />
                    <small>
                      ניתן להוסיף תגיות שיעזרו למציאת הסרטון שלכם
                      <br />
                      יש להזין תגית אחת או מספר תגיות מופרדות בפסיק וללחץ ENTER
                    </small>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol size="12" className='ion-no-padding'>
                    <div>
                      <h3 className="bold-text small-title ion-no-margin">תגיות שנבחרו ({tempTags.length}) </h3>
                    </div>
                    <IonRow>
                      <IonCol className="ion-no-padding">
                        {tempTags &&
                          tempTags.map((tag, index) => {
                            return (
                              <span key={index} className="service-chip">
                                <IonButton
                                  fill="clear"
                                  size="small"
                                  onClick={() => {
                                    setTempTags((curr) => {
                                      const tags = [...curr];
                                      tags.splice(index, 1);
                                      return tags;
                                    });
                                  }}>
                                  <IonIcon slot="start" icon={closeOutline} />
                                  <IonLabel color="primary" style={{ textTransform: 'none' }}>
                                    {tag}
                                  </IonLabel>
                                </IonButton>
                              </span>
                            );
                          })}
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
                <IonRow className="ion-margin-top ion-margin-bottom">
                  <IonCol size="1">
                    <IonCheckbox
                      ref={checkBoxFirstRef}
                      checked={currMovie.IsFirst}
                      mode="md"
                      onIonChange={(event: CustomEvent) => {
                        setCurrMovie((movie) => {
                          let updatedMovie = movie;
                          if (updatedMovie) {
                            updatedMovie.IsFirst = checkBoxFirstRef.current!.checked;
                          }
                          return updatedMovie;
                        });
                      }}
                    />
                  </IonCol>
                  <IonCol>
                    <IonLabel>
                      <p className="small-p">סרטון ראשון</p>
                    </IonLabel>
                  </IonCol>{' '}
                </IonRow>

                {currMovie && currMovie.MovieUrl && (
                  <IonRow className="ion-margin-top ion-margin-bottom">
                    <IonCol size="1">
                      <IonCheckbox ref={checkBoxAgreeRef} mode="md" onIonChange={(e) => {}} />
                    </IonCol>
                    <IonCol>
                      <IonLabel>
                        <p className="small-p">
                          אני מאשר כי התוכן/הסרט הוא חוקי ואינו עלול להטעות, וזכויות היוצרים בו שייכים לי. כמו כן אני מצהיר כי האחריות להוספת כתוביות כנדרש, על פי כללי הנגישות
                          לסרטונים שמועלים על ידי לאתר, חלה עלי
                        </p>
                      </IonLabel>
                    </IonCol>
                  </IonRow>
                )}

<IonRow className="input-row-group">
                  <IonCol size="12"  className='ion-no-padding' style={{height:22}}>
                    
                   {isSaveAlert && <small>
                    אנחנו בודקים את הקישור לסרטון שהזנת. אנא המתינו...
                    </small>}
                  </IonCol>
                </IonRow>
          
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
        <CustomPublishButton style={{ marginTop: 0 }} fixed={false} />
      </CustomForm>
    </CustomModal>
   
    </>
  );
};

export default BusinessMovieCtrlNew;
