import React, { useContext, useEffect, useState } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';

import UserSettingsContext, {
  memberPreventNotification,
} from '../../data/UserSettings-Context';
import { useForm } from 'react-hook-form';
import CustomForm from '../utils/CustomElements/CustomForm';
import CustomModal from '../utils/CustomElements/CustomModal/CustomModal';
import { HeaderParameters } from '../../data/Header-Context';
import CustomToggle from '../utils/CustomElements/CustomToggle';

const NotificationSettings: React.FC<{
  show: boolean;
  closeModal: () => void;
  onSave: () => void;
}> = (props) => {
  const { formState, errors } = useForm({
    mode: 'onChange',
  });
  const { dirty } = formState;
  const UserSettingsCtx = useContext(UserSettingsContext);
  const [isHasNotification, setIsNotification] = useState(true);
  const [preventNotification, setPreventNotification] = useState<
    memberPreventNotification[]
  >([]);
  useEffect(() => {
    if (UserSettingsCtx.userSettingsInfo == null) {
      UserSettingsCtx.initUserSettingContext(true);
    }
    if (
      UserSettingsCtx.memberPreventNotification == null ||
      UserSettingsCtx.notificationGroup == null
    ) {
      UserSettingsCtx.initUserNotificationsContext(true);
    }
  }, []);

  useEffect(() => {
    if (
      UserSettingsCtx.memberPreventNotification &&
      UserSettingsCtx.notificationGroup &&
      UserSettingsCtx.userSettingsInfo
    ) {
      setPreventNotification(UserSettingsCtx.memberPreventNotification);
      setIsNotification(UserSettingsCtx.userSettingsInfo.isShowNotification);
    }
  }, [
    UserSettingsCtx.memberPreventNotification,
    UserSettingsCtx.notificationGroup,
    UserSettingsCtx.userSettingsInfo,
  ]);

  const changeSettings = (isShowNotification: boolean) => {
    
    dtlPushEvt({'event':'notifications','eventInfo': {'ScreenName':'{{page path}}','user_action':'updates_notification_settings'}});

    setIsNotification(isShowNotification);
    const newSettings = { isShowNotification: isShowNotification };
    UserSettingsCtx.updateUserSettingContext(true, newSettings);
  };

  const showNotificationFunc = (id: number, isShowNotification: boolean) => {
    
    dtlPushEvt({'event':'notifications','eventInfo': {'ScreenName':'{{page path}}','user_action':'updates_notification_settings'}});

    let newArray = [...preventNotification];
    if (isShowNotification == false) {
      if (!newArray.some((a) => a.NotificationGroupId === id)) {
        newArray.push({ NotificationGroupId: id });
      }
    } else {
      newArray = newArray.filter((f) => f.NotificationGroupId != id);
    }
    setPreventNotification(newArray);
    UserSettingsCtx.updateUserNotificationsContext(true, newArray);
  };

  const headerParameters: HeaderParameters = {
    isPageHaveChanges: dirty,
    modalType: 'modal',
  };
  return (
    <CustomModal
      pageName={'NotificationSettings'}
      title={'אילו נוטיפיקציות מעדכנים?'}
      headerParameters={headerParameters}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
    >
      <CustomForm onSubmit={() => {}} errors={errors}>
        {UserSettingsCtx.memberPreventNotification &&
          UserSettingsCtx.notificationGroup &&
          UserSettingsCtx.userSettingsInfo && (
            <IonGrid>
              <IonRow>
                <IonCol>
                  <CustomToggle
                    titleTxt='הפעלת התראות'
                    checked={isHasNotification}
                    toggleTitle={true}
                    onIonChange={(e) => {
                      changeSettings(e.detail.checked);
                    }}
                  />
                </IonCol>
              </IonRow>
              {isHasNotification && (
                <React.Fragment>
                  <IonRow className='ion-padding-left'>
                    <IonCol>
                      {UserSettingsCtx.notificationGroup.map((g, index) => {
                        const isCheck = preventNotification.some(
                          (p) => p.NotificationGroupId === g.Id
                        );

                        return (
                          <CustomToggle
                            key={index}
                            disabled={!isHasNotification}
                            titleTxt={g.name}
                            subTitleTxt={g.Description}
                            lines='full'
                            checked={!isCheck}
                            onIonChange={(e) => {
                              showNotificationFunc(g.Id, e.detail.checked);
                            }}
                          />
                        );
                      })}
                    </IonCol>
                  </IonRow>
                </React.Fragment>
              )}
            </IonGrid>
          )}
      </CustomForm>
    </CustomModal>
  );
};

export default NotificationSettings;
