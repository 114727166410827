import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonCheckbox,
  IonLabel,
  IonItem,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import ImagePicker, { Photo } from "../../utils/ImagePicker/ImagePicker";
import CustomInput from "../../utils/CustomElements/CustomInput";
import { useForm } from "react-hook-form";
import { base64FromPath } from "@ionic/react-hooks/filesystem";
import CustomForm from "../../utils/CustomElements/CustomForm";
import CustomPublishButton from "../../utils/CustomElements/CustomPublishButton";
import CustomModal from "../../utils/CustomElements/CustomModal/CustomModal";
import { HeaderParameters } from "../../../data/Header-Context";
import { toast } from "react-toastify";
import MemberDetailsContext, { BusinessCertificateItem } from "../../../data/MemberDetailes-context";
import CustomTextarea from "../../utils/CustomElements/CustomTextarea";
import CustomSelect from "../../SelfSale/CustomElements/CustomSelect";
import CustomDateTime from "../../utils/CustomElements/CustomDateTime";
import moment from "moment";

const AddEditCertificate: React.FC<{
  show: boolean;
  certificateItem: BusinessCertificateItem | undefined;
  closeModal: () => void;
  onSave: (product: BusinessCertificateItem) => void;
  onDelete: (newsId: number) => void;
}> = (props) => {
  const { handleSubmit,setError,clearError, formState, errors, control } = useForm({
    mode: "onChange",
  });
  const MemberDetailsCxt = useContext(MemberDetailsContext);
  
  const { dirty } = formState;
  const [isPhotoChange, setIsPhotoChange] = useState(false);
  const [isPageChange, setIsPageChange] = useState(false);
  

  const [selectedIssuerType,setSelectedIssuerType] = useState(props.certificateItem?.IssuerType);
  const qualifiedNameTextRef = useRef<HTMLIonInputElement>(null);
  const descriptionTextRef = useRef<HTMLIonTextareaElement>(null);
  const licenseNumberTextRef = useRef<HTMLIonInputElement>(null);
  const issuerDescTextRef = useRef<HTMLIonInputElement>(null);
  
  const [selectedIssueDate, setSelectedIssueDate] = useState<string>(props.certificateItem ? props.certificateItem.DateOfIssue : "");
  const [selectedEstablishmentDate, setSelectedEstablishmentDate] = useState<string>(props.certificateItem ? props.certificateItem.DateOfEstablishment : "");
  const [selectedExpiryDate, setSelectedExpiryDate] = useState<string>(props.certificateItem ? props.certificateItem.DateOfExpiry : "");
  
  const checkBoxAgreeRef = useRef<HTMLIonCheckboxElement>(null);
  

  const [CurrentCertificateItem, setCurrentCertificateItem] = useState(() => {
    console.log('setCurrentCertificateItem');
    if (props.certificateItem != undefined) {
      return { ...props.certificateItem };
    } else {
      const certificateItem: BusinessCertificateItem = {
        Id: -1,
   QualifiedName: '',
   LicenseNumber: '',
  Description: '',
  IssuerType: -1,
  IssuerOtherDesc:'',
  
  DateOfIssue: '',
  DateOfEstablishment: '',
  DateOfExpiry: '',
  Base64Image: '',
   ImagePath: '',
  
      };
      return certificateItem;
    }
  });

  const [takenPhoto, setTakenPhoto] = useState(() => {
    if (props.certificateItem != undefined) {
      const photo: Photo = {
        path: props.certificateItem.ImagePath,
        preview: props.certificateItem.Base64Image || props.certificateItem.ImagePath,
      };
      return photo;
    }
  });


useEffect(()=>{
  console.log(props.certificateItem);
if(props.certificateItem){
  if (props.certificateItem.DateOfIssue) {
    setSelectedIssueDate(props.certificateItem.DateOfIssue);
  }else{
    setSelectedIssueDate('');
  }

  if (props.certificateItem.DateOfEstablishment) {
    setSelectedEstablishmentDate(props.certificateItem.DateOfEstablishment);
  } else{
    setSelectedEstablishmentDate('');
  }
  if (props.certificateItem.DateOfExpiry) {
    setSelectedExpiryDate(props.certificateItem.DateOfExpiry);
  }else{
    setSelectedExpiryDate('');
  }
}

},[props.certificateItem]);

let _isCustomValid = false;
const customValidate = ()=> {
  let valid = true;
  if (!checkBoxAgreeRef.current?.checked) {
    valid = false;
    toast.info('עליך לאשר הצגת התעודה באתר');
    
  }
  if (CurrentCertificateItem.Base64Image === "") {
    valid = false;
    toast.warning("לא נבחרה תמונה");
    
  }
  clearError("IssuerType");
  if(selectedIssuerType == undefined || selectedIssuerType == -1){
    valid = false;
    setError("IssuerType", "manual", "חובה");
  }
  _isCustomValid = valid;
  return valid;
};
  const saveHandler = (data:any) => {
    
if(_isCustomValid == false){
  return;
}
    const qualifiedNameText = qualifiedNameTextRef.current!.value;
    const descriptionText = descriptionTextRef.current!.value;
    const licenseNumberText = licenseNumberTextRef.current!.value;
    const issuerTypeDescText =  issuerDescTextRef.current ? issuerDescTextRef.current!.value : '';
    
    CurrentCertificateItem!.QualifiedName = qualifiedNameText!.toString();
    CurrentCertificateItem!.LicenseNumber = licenseNumberText!.toString();
    CurrentCertificateItem!.Description = descriptionText!.toString();
    CurrentCertificateItem!.IssuerType = selectedIssuerType || -1;
    CurrentCertificateItem!.IssuerOtherDesc = selectedIssuerType == 999 ? issuerTypeDescText!.toString() : '';
    
    CurrentCertificateItem!.DateOfIssue = getSavedDate(selectedIssueDate);

    CurrentCertificateItem!.DateOfEstablishment = getSavedDate(selectedEstablishmentDate);

    CurrentCertificateItem!.DateOfExpiry = getSavedDate(selectedExpiryDate);
     
    props.onSave(CurrentCertificateItem!);
  };

  const imagePickHandler = async (photo: Photo) => {
    if (photo) {
      setTakenPhoto(photo);
      setIsPhotoChange(true);
      const base64 = await base64FromPath(photo!.preview);
      CurrentCertificateItem!.Base64Image = base64;
    } else {
      setIsPhotoChange(false);
    }
  };
  function getSavedDate(date_str: string){
    if(date_str && date_str.length > 0){
      if(date_str.indexOf("T") > -1){
        return moment(date_str).toISOString(true);
      } else if(date_str.split('/').length == 3){
        return moment(new Date(date_str.split('/')[2] + "-" + date_str.split('/')[1] + "-" + date_str.split('/')[0])).toISOString(true);
      }
      else{
        return moment(new Date(date_str)).toISOString(true);
      }
    } else{
      return "";
    }
  }
  const headerParameters: HeaderParameters = {
    isPageHaveChanges: dirty || isPhotoChange || isPageChange,
    modalType: "modal",
    isShowLogo: false,
    isShowDeleteButton: props.certificateItem != undefined,
    deleteFunc: () => {
      props.onDelete(props.certificateItem!.Id);
    },
  };

  return (
    <CustomModal
      pageName={"AddEditBusinessCertificate"}
      headerParameters={headerParameters}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
      title="תעודות והסמכות"
      subTitle='בחלק זה תוכלו לעלות את התעודות וההסמכות המקצועיות שלכם על מנת שהמבקרים בכרטיס יוכלו להתרשם מהמקצועיות שלכם'
    >
      <CustomForm onSubmit={handleSubmit(saveHandler)} errors={errors} customValidateForm={customValidate}>
        <IonGrid className="ion-no-padding ion-padding-horizontal">
       
          <IonRow className="ion-justify-content-between" >
            {/* start - column right (picture) desktop mode  */}
            <IonCol className="ion-no-margin" size="12" sizeMd="5.6">
              <IonGrid>
                <IonRow>
                  <IonCol  size="12">
                  <ImagePicker
                    onImagePick={imagePickHandler}
                    currentPhoto={takenPhoto}
                    showPreview={true}
                    isMultiple={false}
                  />
                  </IonCol>
                <IonCol size="12" style={{paddingTop:12,paddingBottom:12}}>
                  {MemberDetailsCxt.CertificateIssuerTypes &&  
                  <>
                  <IonItem color="light" lines="full" mode="md">
                      <IonLabel position="stacked">גורם מנפיק</IonLabel>
                      <IonSelect
                        color="light"
                        mode="md"
                        value={selectedIssuerType}
                        interface="popover"
                        interfaceOptions={{
                          showBackdrop: false,
                        }}
                        placeholder="בחירה מתוך רשימה"
                        onIonChange={(e) => {
                          e.stopPropagation();
                          setSelectedIssuerType(e.detail.value)
                        }}>
                        {MemberDetailsCxt.CertificateIssuerTypes.map((v) => {
                          return (
                            <IonSelectOption key={v.Id} value={v.Id}>
                              {v.IssuerTypeDesc}
                            </IonSelectOption>
                          );
                        })}
                      </IonSelect>
                    </IonItem>
                    
                  {/* <CustomSelect
                       onSelect={(e) => setSelectedIssuerType(e)}
                       multiple={false}
                        class="input-item-bordered"
                       value={selectedIssuerType}
                       errors={errors}
                       
                       label=""
                       showValues={false}
                       placeholder="בחירה מתוך רשימה"
                       name="IssuerType"
                       optionLabelFld="IssuerTypeDesc"
                       optionValueFld="Id"
                       options={MemberDetailsCxt.CertificateIssuerTypes} />  */}
                       </> }
                  </IonCol>
                 {selectedIssuerType == 999 && <IonCol size="12">
                    <CustomInput
                      name="IssuerOtherDesc"
                      errors={errors}
                      control={control}
                      label="שם גורם מנפיק"
                      ref={issuerDescTextRef}
                      value={CurrentCertificateItem?.IssuerOtherDesc}
                      required={true}
                      maxlength={50}
                    ></CustomInput>
                  </IonCol>}
                 
                  <IonCol size="12">
                    <CustomInput
                      name="licenseNumber"
                      errors={errors}
                      control={control}
                      label="מספר רשיון"
                      ref={licenseNumberTextRef}
                      value={CurrentCertificateItem?.LicenseNumber}
                      maxlength={50}
                    ></CustomInput>
                  </IonCol>
                  
                    
                  
                </IonRow>
              </IonGrid>
            </IonCol>
            <IonCol size="12"  sizeMd="5.9">
            <IonGrid>
              <IonRow className="ion-text-center">
              <IonCol size="12">
                  <CustomTextarea
                name="description"
                errors={errors}
                control={control}
                maxlength={160}
                ref={descriptionTextRef}
                value={CurrentCertificateItem?.Description}
                
                label="שם ההסמכה/תעודה/רישיון"
              />
                   
                  </IonCol>
              <IonCol size="12">
                    <CustomInput
                      name="qualifiedName"
                      errors={errors}
                      control={control}
                      label="שם המוסמך/ת"
                      ref={qualifiedNameTextRef}
                      value={CurrentCertificateItem?.QualifiedName}
                      required={true}
                      maxlength={50}
                    ></CustomInput>
                  </IonCol>
                  
            <IonCol size="12">
            <CustomDateTime
                             label="תאריך הנפקה"
                            required={true}
                            displayFormat="DD/MM/YYYY"
                            name="dateOfIssue"
                            placeholder="בחר תאריך הנפקה"
                            value={selectedIssueDate}
                            control={control}
                            errors={errors} 
                            onIonChange={(e) => {
                              setSelectedIssueDate(e.detail.value!);
                            }}
                          ></CustomDateTime>
            </IonCol>
            <IonCol size="12">
            <CustomDateTime
                            label="תאריך הקמת העסק"
                            required={false}
                            displayFormat="DD/MM/YYYY"
                            name="DateOfEstablishment"
                            placeholder="בחר תאריך הקמת העסק"
                            value={selectedEstablishmentDate}
                            control={control}
                            errors={errors}  pickerOptions={
                              {buttons:[{
                              text:"אישור" ,
                              role:"done",
                              handler: (e) => {
                                const date_str = e.day.text + "/" + e.month.text + "/" + e.year.text;
                                control.setValue('DateOfEstablishment',date_str);
                                setSelectedEstablishmentDate(date_str);
                            }
                            },{
                              text: 'הסרה',
                                 handler: () => {control.setValue('DateOfEstablishment',null);
                                 setSelectedEstablishmentDate('');}
                                },{
                                  text:"ביטול" ,
                                  role:"cancel"
                                }]}}
                            onIonChange={(e) => {
                              setSelectedEstablishmentDate(e.detail.value!);
                            }}
                          ></CustomDateTime>
            </IonCol>
            <IonCol size="12"> 
            <CustomDateTime
                            label="תאריך תפוגה"
                            required={false}
                            displayFormat="DD/MM/YYYY"
                            name="DateOfExpiry"
                            placeholder="בחר תאריך תפוגה"
                            value={selectedExpiryDate}
                            control={control}  
                            errors={errors}  
                            max={((new Date()).getFullYear() + 10) + '-12-31' }
                            min={moment().format('YYYY-MM-DD') }
                            pickerOptions={

                              {buttons:[{
                              text:"אישור" ,
                              role:"done",
                              handler: (e) => {
                                const date_str = e.day.text + "/" + e.month.text + "/" + e.year.text;
                                control.setValue('DateOfExpiry',date_str);
                                setSelectedExpiryDate(date_str);
                            }
                            },{
                              text: 'הסרה',
                                 handler: () => {control.setValue('DateOfExpiry',null);
                                 setSelectedExpiryDate('');}
                                },{
                                  text:"ביטול" ,
                                  role:"cancel"
                                }]}}
                            onIonChange={(e) => {
                              setSelectedExpiryDate(e.detail.value!);
                            }}
                          ></CustomDateTime>
            </IonCol>
            <IonCol size="12">
              <IonRow>
                <IonCol size="1">
                <IonCheckbox ref={checkBoxAgreeRef} mode="md" onIonChange={(e) => {}}  />
                </IonCol>
                <IonCol size="11" className="ion-text-start">
                <IonLabel>
                        <p className="small-p">
                        אני מאשר/ת להציג את התעודה באתר
                        </p>
                      </IonLabel>
                </IonCol>
              </IonRow>
                    </IonCol>
              </IonRow>
              </IonGrid>
            </IonCol> 
            

            {/* start - column left (input fields) desktop mode  */}

           
          </IonRow>

          {/* start - action button (align to left) desktop mode  */}

          <IonRow className="ion-justify-content-end">
            <IonCol size="12" sizeMd="5.9">
              <CustomPublishButton></CustomPublishButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </CustomForm>
    </CustomModal>
  );
};

export default AddEditCertificate;
