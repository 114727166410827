import React, { createRef, useRef, useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonCheckbox,
  IonLabel,
} from "@ionic/react";
import { isMobile } from "react-device-detect";
import { iProduct } from "../../../data/Products-context";
import ImagePicker, { Photo } from "../../utils/ImagePicker/ImagePicker";
import CustomInput from "../../utils/CustomElements/CustomInput";
import { useForm } from "react-hook-form";
import { base64FromPath } from "@ionic/react-hooks/filesystem";
import CustomForm from "../../utils/CustomElements/CustomForm";
import CustomPublishButton from "../../utils/CustomElements/CustomPublishButton";
import CustomModal from "../../utils/CustomElements/CustomModal/CustomModal";
import { HeaderParameters } from "../../../data/Header-Context";
import { toast } from "react-toastify";
import { BusinessNewsItem } from "../../../data/MemberDetailes-context";
import CustomTextarea from "../../utils/CustomElements/CustomTextarea";

const AddEditNews: React.FC<{
  show: boolean;
  newsItem: BusinessNewsItem | undefined;
  closeModal: () => void;
  onSave: (product: BusinessNewsItem) => void;
  onDelete: (newsId: number) => void;
}> = (props) => {
  const { handleSubmit, formState, errors, control } = useForm({
    mode: "onChange",
  });
  const { dirty } = formState;
  const [isPhotoChange, setIsPhotoChange] = useState(false);
  const [isPageChange, setisPageChange] = useState(false);
  const newsTitleTextRef = useRef<HTMLIonInputElement>(null);
  const newsDescriptionTextRef = useRef<HTMLIonTextareaElement>(null);
  const newsLinkTextRef = useRef<HTMLIonInputElement>(null);
  

  const [CurrentNewsItem, setCurrentNewsItem] = useState(() => {
    if (props.newsItem != undefined) {
      return { ...props.newsItem };
    } else {
      const newsItem: BusinessNewsItem = {
        Id: -1,
  Title: '',
  Description: '',
  Link: '',
  Base64Image: '',
   ImagePath: '',
   LastUpdate: ''
      };
      return newsItem;
    }
  });

  const [takenPhoto, setTakenPhoto] = useState(() => {
    if (props.newsItem != undefined) {
      const photo: Photo = {
        path: props.newsItem.ImagePath,
        preview: props.newsItem.Base64Image || props.newsItem.ImagePath,
      };
      return photo;
    }
  });

  const saveHandler = () => {
    if (CurrentNewsItem.Base64Image === "") {
      toast.warning("לא נבחרה תמונה");
      return;
    }

    const newsTitleText = newsTitleTextRef.current!.value;
    const newsDescriptionText = newsDescriptionTextRef.current!.value;
    const newsLink = newsLinkTextRef.current!.value;

    
    CurrentNewsItem!.Title = newsTitleText!.toString();
    CurrentNewsItem!.Link = newsLink!.toString();
    CurrentNewsItem!.Description = newsDescriptionText!.toString();
    

    props.onSave(CurrentNewsItem!);
  };

  const imagePickHandler = async (photo: Photo) => {
    if (photo) {
      setTakenPhoto(photo);
      setIsPhotoChange(true);
      const base64 = await base64FromPath(photo!.preview);
      CurrentNewsItem!.Base64Image = base64;
    } else {
      setIsPhotoChange(false);
    }
  };
  let isBtnFocuse = false;
  const headerParameters: HeaderParameters = {
    isPageHaveChanges: dirty || isPhotoChange || isPageChange,
    modalType: "modal",
    isShowLogo: false,
    isShowDeleteButton: props.newsItem != undefined,
    deleteFunc: () => {
      props.onDelete(props.newsItem!.Id);
    },
  };

  return (
    <CustomModal
      pageName={"AddEditBusinessNews"}
      headerParameters={headerParameters}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
      title={"איזה חדשות תעדכן?"}
      subTitle={"תמונה טובה תגדיל את החשיפה שלה ונותן לך יתרון שיווקי."}
    >
      <CustomForm onSubmit={handleSubmit(saveHandler)} errors={errors}>
        <IonGrid className="ion-no-padding ion-padding-horizontal">
          <IonRow className="ion-justify-content-between">
            {/* start - column right (picture) desktop mode  */}
            <IonCol
              size="12"
              sizeMd="5.9"
              style={{ order: !isMobile ? "2" : "" }}
            >
              <IonRow className="ion-text-center">
                <IonCol className="ion-margin-bottom">
                  <ImagePicker
                    onImagePick={imagePickHandler}
                    currentPhoto={takenPhoto}
                    showPreview={true}
                    isMultiple={false}
                  />
                </IonCol>
              </IonRow>
            </IonCol>

            {/* start - column left (input fields) desktop mode  */}

            <IonCol className="ion-no-margin" size="12" sizeMd="5.6">
              <IonGrid>
                <IonRow>
                  <IonCol size="12">
                    <CustomInput
                      name="newsTitle"
                      errors={errors}
                      control={control}
                      label="כותרת"
                      ref={newsTitleTextRef}
                      value={CurrentNewsItem?.Title}
                      required={true}
                      maxlength={50}
                    ></CustomInput>
                  </IonCol>
                  <IonCol size="12">
                  <CustomTextarea
                name="newsDesc"
                errors={errors}
                control={control}
                maxlength={100}
                ref={newsDescriptionTextRef}
                value={CurrentNewsItem?.Description}
                
                label="תיאור"
              />
                   
                  </IonCol>
                  <IonCol size="12">
                    <CustomInput
                      name="newsLink"
                      errors={errors}
                      control={control}
                      label="קישור"
                      ref={newsLinkTextRef}
                      value={CurrentNewsItem?.Link}
                      maxlength={500}
                      type="url"
                    ></CustomInput>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>

          {/* start - action button (align to left) desktop mode  */}

          <IonRow className="ion-justify-content-end">
            <IonCol size="12" sizeMd="5.9">
              <CustomPublishButton></CustomPublishButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </CustomForm>
    </CustomModal>
  );
};

export default AddEditNews;
