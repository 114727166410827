import jsPDF from "jspdf";
import React, { useContext, useEffect, useState } from "react";
import StatisticContext, { StatProd } from "../../data/Statistic-Context";
import { getStatisticReport } from "../../utils-ts/PdfGenerator";

import { HeaderParameters } from "../../data/Header-Context";
import CustomModal from "../utils/CustomElements/CustomModal/CustomModal";
import MonthFilter from "../utils/Filters/MonthFilter/MonthFilter";
import MemberCampaignData from "./MemberCampaignData";

const MemberCampaign: React.FC<{
  month?: number;
  year?: number;
  show: boolean;
  Product: StatProd;
  LimitMonth?: number;
  LimitYear?: number;
  Package_Desc: string;
  closeModal: () => void;
}> = (props) => {
  const StatisticsCxt = useContext(StatisticContext);
  const [statisticReport, setStatisticReport] = useState<jsPDF>();
  const [month, setMonth] = useState<number>(() => {
    if (props.month) {
      return props.month;
    } else {
      let newDate = new Date();
      return newDate.getMonth() + 1;
    }
  });
  const [year, setyear] = useState<number>(() => {
    if (props.year) {
      return props.year;
    } else {
      let newDate = new Date();
      return newDate.getFullYear();
    }
  });

  useEffect(() => {
    StatisticsCxt.initStatProductsContext(true, month, year);
  }, [month, year]);
  useEffect(() => {
    if (
      StatisticsCxt.actionSummery !== undefined &&
      StatisticsCxt.statProducts !== undefined
    ) {
      const report = getStatisticReport(
        month,
        year,
        props.Package_Desc,
        StatisticsCxt.actionSummery,
        StatisticsCxt.statProducts
      );
      setStatisticReport(report);
    }
  }, [StatisticsCxt.actionSummery, StatisticsCxt.statProducts]);

  const prodDetaile = StatisticsCxt.statProducts?.filter(
    (p) => p.Prodmain === props.Product.Prodmain
  )[0];

  const headerParameters: HeaderParameters = {
    pdfDownloadPdf: statisticReport,
    modalType: "modal",
  };
  const limitMonth = props.LimitMonth;
  const limitYear = props.LimitYear;

  return (
    <CustomModal
      pageName={"MemberCampaign"}
      headerParameters={headerParameters}
      closeModal={() => {
        props.closeModal();
      }}
      cssClass="fullscreen"
      isOpen={props.show}
      title={prodDetaile ? prodDetaile.ProdMainName : "קטגוריות"}
    >
      <div id="downloadToPdf">
        {month && (
          <MonthFilter
            currntMonth={month}
            CorrentYear={year}
            LimitMonth={limitMonth}
            LimitYear={limitYear}
            onChange={(newMonth: number, newYear: number) => {
              setMonth(newMonth);
              setyear(newYear);
            }}
          ></MonthFilter>
        )}
        <MemberCampaignData
          Product={props.Product}
          month={month}
          year={year}
          LimitMonth={props.LimitMonth}
          LimitYear={props.LimitYear}
        ></MemberCampaignData>
      </div>
    </CustomModal>
  );
};

export default MemberCampaign;
