import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import CustomAlert from '../../components/utils/CustomElements/CustomAlert';
import CustomPage from '../../components/utils/CustomElements/CustomPage';
import UserContext from '../../data/Auth-context';
import { HeaderParameters } from '../../data/Header-Context';

const CheckUserLogin: React.FC<{
  loginType: 'user' | 'cm' | 'cm_query';
}> = (props) => {
  const userCtx = React.useContext(UserContext);

  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState('');
  
  const cm_username = useParams<{ cm_username: string }>().cm_username;
  const tp = useParams<{ tp: string }>().tp;
  
  let checkUserLoginToken = useParams<{ token: string }>().token;
  
  
  const getDefaultPath = () => {

    const params = new URLSearchParams(decodeURIComponent(window.location.search));
    const specificp = params.get('specificp') || '';
   
    let path = '/Home';
    if(!!specificp && specificp.length > 0 ){
      switch(specificp){
        case 'logo':
        case 'info':
        case 'open_hours':
        case 'desc':
        case 'movie':
        case 'laguages':
        case 'links':
          path = '/BusinessCard';
        break;
        case 'gallery':
          path = '/BusinessCard/gallery';
        break;
        case 'coupons':
          path = '/BusinessCard/coupons';
        break;
        case 'products':
          path = '/BusinessCard/products';
        break;
      }
    }
    return path;
  };

  useEffect(() => {
    //alert(checkUserLoginToken);
    if (!checkUserLoginToken) {
    
      const params = new URLSearchParams(decodeURIComponent(window.location.search));
      checkUserLoginToken = params.get('t') || '';
      checkUserLoginToken = checkUserLoginToken.replace(/ /g,"+");
    }

    //alert(checkUserLoginToken);
    if (props.loginType == 'user') {
      userCtx.loginUserByToken(checkUserLoginToken).then((res: any) => {
        if (res == 'OK') {
          window.location.href = getDefaultPath();
        } else if (!!res && res != '') {
          setError(res);
          setShowAlert(true);
        }
      });
    } else if (props.loginType == 'cm') {
      userCtx.loginCmByToken(checkUserLoginToken, cm_username, tp).then((res: any) => {
        if (res == 'OK') {
          window.location.href = getDefaultPath();
        } else if (!!res && res != '') {
          setError(res);
          setShowAlert(true);
        }
      });
    } else if (props.loginType == 'cm_query') {
      
      const params = new URLSearchParams(decodeURIComponent(window.location.search));
      const loginToken = params.get('t');
      if (loginToken) {
        userCtx.loginCmByToken(loginToken, cm_username, tp).then((res: any) => {
          if (res == 'OK') {
            window.location.href = getDefaultPath();
          } else if (!!res && res != '') {
            setError(res);
            setShowAlert(true);
          }
        });
      }
    }
  }, []);

  const ErrorMessageButton = [
    {
      text: 'אישור',
      role: 'ok',
      handler: () => {
        setShowAlert(false);
        setError('');

        window.location.href = getDefaultPath();
      },
    },
  ];
  const headerProps: HeaderParameters = {
    isShowLogo: true,
    isPageHaveChanges: false,
    isShowHamburger: false,
    backButton: { isShowMobile: false, isShowDesktop: false },
    isShowDeleteButton: false,
    isImagePreview: false,
    isCloseModalBtn: false,
    isShowEditButton: false,
    isLogin: true,
  };
  return (
    <CustomPage headerParameters={headerProps} title="ברוכים הבאים לאפליקציית b144" subTitle={props.loginType == 'cm' ? 'התחברות ע"י נציג תמיכה' : 'התחברות מהירה'} pageName="">
      <CustomAlert
        isOpen={showAlert}
        header={'הודעת מערכת'}
        message={error}
        onDismiss={() => {
          setShowAlert(false);
          setError('');

          window.location.href = getDefaultPath();
        }}
        buttons={ErrorMessageButton}></CustomAlert>
    </CustomPage>
  );
};

export default CheckUserLogin;
