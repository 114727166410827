import React, { FC, ComponentProps, useState, useEffect, useRef } from "react";
import { closeOutline } from "ionicons/icons";
import { IonButton, IonIcon } from "@ionic/react";
import CustomAreYouSureAlert from "./CustomAreYouSureAlert";
import { isMobile } from "react-device-detect";

type IonButtonProps = ComponentProps<typeof IonButton>;

interface ButtonProps extends IonButtonProps {
  onClose: () => void;
  isHideAreYouSureBtn?: boolean;
  title?: string;
  icon?: string;
  isPageHaveChanges?: boolean;
}

const CustomCloseButton: FC<ButtonProps> = ({
  onClose,
  title,
  icon,
  isHideAreYouSureBtn,
  isPageHaveChanges,
  ...props
}) => {
  const btnRef = useRef<HTMLIonButtonElement>(null);
  const onBackButtonEvent = () => {
    if (btnRef.current) {
      btnRef.current.click();
    } else {
      onClose();
    }
  };
  const [showAreYouSure, setShowAreYouSure] = useState(false);

  const closeByEsc = (e: any) => {
    if (e.key === "Escape") {
      onBackButtonEvent();
    }
  };
  const closeByBackDrop = (e: any) => {
    const targetName = e.target.localName;
    const targetParent = e.target.parentElement.localName;
    const targetFirstParent = e.target.parentElement.previousSibling?.localName;
    if (
      targetName === "ion-backdrop" &&
      targetParent === "ion-modal" &&
      (targetFirstParent === undefined || targetFirstParent !== "ion-modal")
    ) {
      onBackButtonEvent();
    }
  };

  useEffect(() => {
    // close by prevButton press
    window.addEventListener("popstate", onBackButtonEvent);
    if (
      window.history.state == null ||
      window.history.state.name == null ||
      window.history.state.name !== "PopModal"
    ) {
      window.history.pushState({ name: "PopModal" }, "PopModal", null);
    }
    // close by esc press
    if (!isMobile && onClose !== undefined) {
      window.addEventListener("keydown", closeByEsc);
      window.addEventListener("mousedown", closeByBackDrop);
      return function cleanupListener() {
        window.removeEventListener("keydown", closeByEsc);
        window.removeEventListener("mousedown", closeByBackDrop);
      };
    }
  }, []);

  const cancleFunc = () => {
    if (showAreYouSure === true) {
      setShowAreYouSure(false);
      window.addEventListener("popstate", onBackButtonEvent);
      if (
        window.history.state == null ||
        window.history.state.name == null ||
        window.history.state.name !== "PopModal"
      ) {
        window.history.pushState({ name: "PopModal" }, "PopModal", null);
      }
    }
  };
  return (
    <>
      <IonButton
        ref={btnRef}
        {...props}
        onClick={() => {
          if (isHideAreYouSureBtn !== undefined) {
            onClose();
          } else {
            if (isPageHaveChanges && isPageHaveChanges === true) {
              setShowAreYouSure(true);
            } else {
              onClose();
            }
          }
        }}
      >
        {title ? title : ""}
        <IonIcon icon={icon ? icon : closeOutline}></IonIcon>
      </IonButton>
      <CustomAreYouSureAlert
        cancelFunc={() => {
          cancleFunc();
        }}
        continueFunc={() => {
          cancleFunc();
          onClose();
        }}
        isOpen={showAreYouSure}
        message={"לחצת על יציאה <br>בלי לשמור את העדכונים שביצעת"}
      ></CustomAreYouSureAlert>
    </>
  );
};

export default CustomCloseButton;
