import { IonCard, IonCol } from "@ionic/react";
import React, { useEffect, useState } from "react";
import ProductStatisticTable from "../../components/Statistic/ProductStatisticTable";
import PimaxPreview from "../../components/ProductStatistics/PimaxPreview/PimaxPreview";
import { GetPiMaxPreview, PiMaxPreview } from "../../data/ProductStatsService";
import "./ProductStatistics.scss";

interface Props {
  pageData?: any;
  tabs?: { he: string; en: string }[];
  tabNumber: number;
  children?: React.ReactNode | React.ReactNode[];
}

const GoogleStats = ({ pageData, tabs, tabNumber, children }: Props) => {
  const [piMaxdata, setPiMaxdata] = useState<PiMaxPreview | null>(null);
  const additionalData = pageData?.additionalData;
  const isCommentAdwords =
    pageData && tabs && tabs[tabNumber].en === "commentsAdwords";

  useEffect(() => {
    if (!tabs || tabs[tabNumber].en !== "piMaxPreview") return;
    (async () => {
      const pimaxPreviewData = await GetPiMaxPreview();
      setPiMaxdata(pimaxPreviewData);
    })();
  }, [tabs, tabNumber]);

  return (
    <IonCol size="12" className="card-wrapper">
      <IonCard mode="md" className="statistics-card">
        {children && children}
        {piMaxdata && tabs && tabs[tabNumber].en === "piMaxPreview" ? (
          <PimaxPreview piMaxdata={piMaxdata} />
        ) : (
          <ProductStatisticTable
            additionalData={
              pageData &&
              tabs &&
              tabs[tabNumber].en === "adwords" &&
              additionalData
            }
            items={pageData && tabs ? pageData[tabs[tabNumber].en] : undefined}
            exportToExl={
              isCommentAdwords ? false : tabs ? tabs[tabNumber].he : false
            }
            noDataElement={isCommentAdwords && <p>אין פניות</p>}
          />
        )}
      </IonCard>
    </IonCol>
  );
};

export default GoogleStats;
