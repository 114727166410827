import React, { useState, useEffect, useCallback, useContext } from "react";
import { base64FromPath } from "@ionic/react-hooks/filesystem";
import GalleryContext, { Image } from "./Gallery-context";
import { Photo } from "../components/utils/ImagePicker/ImagePicker";
import { config } from "../Constants";
import { fetchRequest } from "../utils-ts/fetchRequest";
import { toast } from "react-toastify";
//import CustomLoader from "../components/utils/CustomElements/CustomLoader";
import MemberDetailsContext from "./MemberDetailes-context";
import GlobalContext from "./Global-Context";

const parseString = require("xml2js").parseString;

const GalleryContextProvider: React.FC = (props) => {
  const [gallery, setGallery] = useState<Image[]>();
  //const [loading, setLoading] = useState<boolean>(false);
  const globalCtx = useContext(GlobalContext);

 const setLoading = (loading:boolean)=>{
  globalCtx.setLoader(loading);

 };
  useEffect(() => {
    if (gallery) {
      setLoading(false);
    }
  }, [gallery]);
  const MemberDetailsCxt = useContext(MemberDetailsContext);

  const addImage = async (photo: Photo, alt: string) => {
    if (photo!.preview) {
      const base64 = await base64FromPath(photo!.preview);
      setLoading(true);
      var data = new URLSearchParams({
        sImageDesc: alt,
        sImage: base64,
      }).toString();

      fetchRequest(
        `${config.url.API_URL}/ClientFiles.asmx/UploadB144GalleryImage`,
        "post",
        data
      )
        .then((response) => {
          parseString(response, (err: any, result: any) => {
            if (result) {
              var res = JSON.parse(result.string["_"]);
              if (res.Success) {
                toast.success("התמונה הועלתה בהצלחה ותופיע באתר תוך 48 שעות");
                MemberDetailsCxt.initContentStatusContext();

                initGalleryContext(true);
              } else {
                toast.error("ארעה שגיאה בהעלאת תמונה");

                setLoading(false);
              }
            }
          });
        })
        .catch(function (error) {
          setLoading(false);
          console.log("Request failed", error);
        });
    }
  };

  const initGalleryContext = useCallback(async (useLoading: boolean) => {
    useLoading && setLoading(useLoading);
    var data = new URLSearchParams({}).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientFiles.asmx/GetB144Gallery`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const galleryArray = JSON.parse(res.Response);
              setGallery(galleryArray);
            }
          }
          useLoading && setLoading(false);
        });
      })
      .catch(function (error) {
        useLoading && setLoading(false);
        console.log("Request failed", error);
      });
  }, []);

  const saveImage = async (imageDesc: string, imageId: string) => {
    setLoading(true);
    var data = new URLSearchParams({
      sImageDesc: imageDesc,
      sImageId: imageId,
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientFiles.asmx/UpdateB144GalleryImage`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              toast.success("התמונה עודכנה בהצלחה ותופיע באתר תוך 48 שעות");
              initGalleryContext(true);
            } else {
              toast.error("ארעה שגיאה בעדכון תמונה");
              setLoading(false);
            }
          }
        });
      })
      .catch(function (error) {
        setLoading(false);
        console.log("Request failed", error);
      });
  };

  const uploadDeleteFromGoogleHandler = async (
    uploadImages: string[],
    isDeleted: boolean
  ) => {
    setLoading(true);
    let imageArr: { id: number; GmbId: string }[] = [];
    let selectedImages = gallery?.filter((g) =>
      uploadImages.includes(g.Id.toString())
    );
    if (isDeleted == true) {
      selectedImages = selectedImages?.filter((g) => g.GmbId != null);
    }
    if (selectedImages!.length > 0) {
      selectedImages?.map((g) => {
        const img = {
          id: Number(g.Id),
          GmbId: g.GmbId,
        };
        imageArr.push(img);
      });
      var data = new URLSearchParams({
        jsDetails: JSON.stringify(imageArr),
        isDeleted: isDeleted.toString(),
      }).toString();

      fetchRequest(
        `${config.url.API_URL}/ClientFiles.asmx/UpdateGoogleGallery`,
        "post",
        data
      )
        .then((response) => {
          parseString(response, (err: any, result: any) => {
            if (result) {
              var res = JSON.parse(result.string["_"]);
              if (res.Success) {
                toast.success("בוצע בהצלחה");
                initGalleryContext(true);
                MemberDetailsCxt.initContentStatusContext();
              } else {
                toast.error("ארעה שגיאה");
                setLoading(false);
              }
            }
          });
        })
        .catch(function (error) {
          setLoading(false);
          console.log("Request failed", error);
        });
    } else {
      setLoading(false);
      toast.success("בוצע בהצלחה");
      console.log("image not exists");
    }
  };
  const deletedImagesHandler = useCallback(
    async (deletedImages: string[]) => {
      const googlrImage = gallery!.filter(
        (g) => deletedImages.includes(g.Id.toString()) && g.GmbId != null
      );
      setLoading(true);
      var data = new URLSearchParams({
        ImageIds: deletedImages.toString(),
      }).toString();
      fetchRequest(
        `${config.url.API_URL}/ClientFiles.asmx/DeleteB144GalleryImages`,
        "post",
        data
      )
        .then((response) => {
          parseString(response, (err: any, result: any) => {
            if (result) {
              var res = JSON.parse(result.string["_"]);
              if (res.Success) {
                toast.success("התמונת נמחקו בהצלחה");
                initGalleryContext(true);
                if (googlrImage.length > 0) {
                  uploadDeleteFromGoogleHandler(deletedImages, true);
                }
                MemberDetailsCxt.initContentStatusContext();
              } else {
                toast.error("ארעה שגיאה במחיקת תמונות");
                setLoading(false);
              }
            }
          });
        })
        .catch(function (error) {
          setLoading(false);
          console.log("Request failed", error);
        });
    },
    [uploadDeleteFromGoogleHandler]
  );

  return (
    <GalleryContext.Provider
      value={{
        gallery,
        addImage,
        initGalleryContext,
        saveImage,
        deletedImagesHandler,
        uploadDeleteFromGoogleHandler,
      }}
    >
      {props.children}
      {/* <CustomLoader
        isOpen={loading}
        message={""}
        duration={10000}
        onDidDismiss={() => {
          setLoading(false);
        }}
      ></CustomLoader> */}
    </GalleryContext.Provider>
  );
};

export default GalleryContextProvider;
