import { IonButton, IonCheckbox, IonChip, IonContent, IonIcon, IonImg, IonLabel, IonPopover, IonToast } from "@ionic/react";
import React, { useState } from "react";
import { questionMarkIcon } from "../../../images";
const QuestionButton: React.FC<{ text: string }> = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <IonButton fill="clear" size="small" onClick={() => setIsOpen(!isOpen)}>
                <IonImg src={questionMarkIcon}></IonImg>
            </IonButton>
            <IonPopover isOpen={isOpen} onDidDismiss={() => setIsOpen(false)}>
                <IonContent class="ion-padding">{props.text}</IonContent>
            </IonPopover>
        </>
    )
}
export default QuestionButton;