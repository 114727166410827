import React from "react";
import { HeaderParameters } from "../../data/Header-Context";
import CustomModal from "../utils/CustomElements/CustomModal/CustomModal";
import ProductListData from "./ProductListData";
const ProductListModal: React.FC<{
  closeModal: () => void;
  show: boolean;
}> = (props) => {
  const headerParameters: HeaderParameters = {
    isShowLogo: true,
    modalType: "modal",
  };
  return (
    <CustomModal
      pageName={"ProductList"}
      headerParameters={headerParameters}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
      title={"איזה נכס מעניין אותך "}
    >
      <ProductListData></ProductListData>
    </CustomModal>
  );
};
export default ProductListModal;
