import React from "react";
import { useParams } from "react-router";
import ProductInfoData from "../../components/Statistic/ProductInfoData";
import CustomPage from "../../components/utils/CustomElements/CustomPage";

import { HeaderParameters } from "../../data/Header-Context";
const ProductInfoPage: React.FC = () => {
  const headerParameters: HeaderParameters = {
    isShowLogo: true,
    backButton: {
      isShowMobile: true,
      isShowDesktop: true,
      link: "/ProductListPage",
    },
    isShowNotification: true,
  };
  const productName: any = useParams<{
    productName: string;
  }>().productName.toString();

  return (
    <CustomPage
      headerParameters={headerParameters}
      pageName={"ProductInfo"}
      title={"עמוד הנכס שלך"}
    >
      <ProductInfoData productName={productName}></ProductInfoData>
    </CustomPage>
  );
};
export default ProductInfoPage;
