import React from "react";
import {autocompleteOption} from '../components/utils/CustomElements/CustomAutocomplete';


export interface selectOption {
  value: number;
  text: string;

}

export interface VasProduct {
  prod_code: number;
  title: string;
  short_desc?: string;
  allow_purchase: boolean;
  icon_select_vas:any;
  icon_my_vas:any;

}

export interface VasProductDetailed {
  short_desc?: string;
  produuctInfo: productInfoItem[];

}

export interface productInfoItem {
  pageNumber: number;
  text: string;

}

export interface DigitalProductField {

  // case 1://"text"
  // case 2://"longtext"
  // case 6: bool
  // case 7://"email"
  // case 11://"autocomplete"
  // case 12://"address google autocomplete"

  // case 3://"date"
  // case 4://"number"
  // case 8://"richtext"
  // case 9://"time"
  // case 10://"drop down"
  fldNumber: number;
  fldType: number;
  fldLength?: number;
  isMandtory?: boolean;
  fldCaption: string;

  fldKey: string;
  minValue?: number;
  maxValue?: number;
  sortOrder: number;
  defaultValue?: string;
  //fldParams?: string;
  fieldDesc?: string;
  value?: string;
}



export interface landingPageReq {
  pack: string;
  hasImages: boolean;
  hasDocs: boolean;
  availabilityTime: string;
  comments: string;
  pageGoal: string;
  // landingPageUserId: string;
  // isHotTrans: boolean;
  // userDueDate: Date;
}
export interface digitalProductReq{
  productCode:String;
  pack: string;
  data:DigitalProductField[]
}
export interface webSiteReq{
  prod_pack: string;
  availability_time: string;
  comments: string;
  description: string;
  colors: string;
  // site_user_id: string;
  // due_date?:Date;
  // is_hot:boolean;
  is_domain:boolean;
}
export interface ecommerceReq{
  prod_pack: string;
  availability_time: string;
  //website: string;
 // due_date:Date;
  comments: string;
  //storeIsSelfBuild:boolean;
  //storeIsExpress:boolean;
}

const VasContext = React.createContext<{
  
  FacebookCategories: autocompleteOption[] | null;
  
  getVasProducts: (useLoading: boolean) => any;
  getPackagesByProdCode: (prod_code: number, useLoading: boolean) => any,

  getLandingPageGoalsList: () => any;
  getAddressAutocomplete: (search_string: string) => any;
  getGoogleCategoriesAutocomplete: (search_string: string,useLoading?: boolean) => any;
  //getCustomAutocomplete: (search_string: string, field_number: number) => any;
  
  insertLandingPage:(useLoading: boolean,lPageReq:landingPageReq) => any;
  insertDigitalProduct:(useLoading: boolean,dProReq:digitalProductReq) => any;
  insertWebSite:(useLoading: boolean,wsReq:webSiteReq) => any;
  insertEcommerce:(useLoading: boolean,ecReq:ecommerceReq) => any;
  
  
  initVasProductInfoContext: (prod_code: number) => any;
  initDigitalProductFields: (prod_code: number, useLoading: boolean) => any;
}>({
  
  FacebookCategories: [],

  getVasProducts: () => {},
  getPackagesByProdCode: () => { },
  getLandingPageGoalsList: () => { },
  initDigitalProductFields: () => { },
  getAddressAutocomplete: () => { },
  getGoogleCategoriesAutocomplete: () => { },
  //getCustomAutocomplete: () => { },
  insertLandingPage: () => {},
  insertDigitalProduct: () => {},
  insertWebSite:() => {},
  insertEcommerce:() => {},
  
  initVasProductInfoContext: () => { }
});

export default VasContext;