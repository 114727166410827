import React, { useContext, useEffect, useRef, useState } from 'react';
import { IonRow, IonCol, IonButton, IonLabel } from '@ionic/react';
//import ColorPicker from 'mui-color-picker'

import MemberDetailsContext from '../../data/MemberDetailes-context';

import { Control, FieldError, NestDataObject } from 'react-hook-form';

import CustomInput from '../utils/CustomElements/CustomInput';
import { getRandomLogoStyle, renderImageEx } from '../../data/AutoLogoService';
import CustomColorPicker, { CustomColorPickerParams } from '../utils/CustomElements/CustomColorPicker';

interface AutoLogoObj {
  foreColor: string;
  backColor: string;
  fontSize: number;
}
const BusinessAutoLogo: React.FC<{
  control?: Control;
  errors?: NestDataObject<Record<string, any>, FieldError>;
  setIsPageChange: (change: boolean) => void;
  setAutoLogoBase64: (base64: string) => void;
}> = (props) => {
  const MemberDetailsCxt = useContext(MemberDetailsContext);

  const [autoLogoText, setAutoLogoText] = useState(
    MemberDetailsCxt.GeneralInfo
      ? MemberDetailsCxt.GeneralInfo!.CompanyName.length > 25
        ? MemberDetailsCxt.GeneralInfo!.CompanyName.substring(0, 25)
        : MemberDetailsCxt.GeneralInfo!.CompanyName
      : ''
  );
  const [showColorPicker, setshowColorPicker] = useState(false);
  const [colorPickerParams, setColorPickerParams] = useState<CustomColorPickerParams | undefined>();
  const [autoLogo, setAutoLogo] = useState<AutoLogoObj>();
  const [tempBase64, setTempBase64] = useState('');

  const [error, setError] = useState('');

  const logoTextRef = useRef<HTMLIonInputElement>(null);

  useEffect(() => {
    getRandomLogoStyle().then((result: any) => {
      if (result) {
        setAutoLogo({
          foreColor: result.ForeColor,
          backColor: result.BackColor,
          fontSize: initFontSize(),
        });
      } else {
        //default style
        setAutoLogo({
          foreColor: '#16254f',
          backColor: '#c4c4e9',
          fontSize: initFontSize(),
        });
      }
    });
  }, []);
  let reqDelay = useRef<NodeJS.Timeout>(); 

  useEffect(() => {
    setTimeout(() => {
      //no need to reset font size
      //only rerender HTML
      calcFontSize();
      // RERENDER IMAGE
      //renderImage();
    }, 100);
  }, [autoLogoText]);

  useEffect(() => {
    reqDelay.current && clearTimeout(reqDelay.current);
    reqDelay.current = setTimeout(() => {
      // RERENDER IMAGE
      renderImage();
    }, 700);

    return () => {
      reqDelay.current && clearTimeout(reqDelay.current);
    }
  }, [autoLogo]);

  const padd = 30;
  const logo_size = 200;
  const initFontSize = () => {
    let font_size = 60;
    if(autoLogoText.length > 20){
      font_size = 40;
    } else if(autoLogoText.length > 18){
      font_size = 45;
    } else if(autoLogoText.length > 15){
      font_size = 50;
    }
    else if(autoLogoText.length > 12){
      font_size = 55;
    }
    console.log(autoLogoText.length,font_size);
    return font_size;
  };
  const calcFontSize = () => {
    let font_size = initFontSize();
    // let line_height = font_size - 5;
    const text_fitter = document.getElementById('text_fitter');
    const text_fitter_cont = document.getElementById('text_fitter_cont');
    // const imageContent = document.getElementById('imageContent');
    if (text_fitter && text_fitter_cont) {
      //RESET STYLES
      text_fitter_cont!.style.paddingTop = 0 + 'px';
      text_fitter_cont!.style.height = 200 + 'px';
      text_fitter!.style.fontSize = font_size + 'px';
      // text_fitter!.style.lineHeight = line_height + 'px';
      while (font_size > 8 && 
        (text_fitter!.offsetWidth + padd > logo_size + 5 || text_fitter!.offsetHeight + padd > logo_size + 5)) {
        font_size = font_size - 1;
        text_fitter!.style.fontSize = font_size + 'px';
        
      }
    }
    renderHtmlByFontSize(font_size);
  };

  const renderHtmlByFontSize = (font_size: number) => {
    // let line_height = font_size - 5;

    const text_fitter = document.getElementById('text_fitter');
    const text_fitter_cont = document.getElementById('text_fitter_cont');
    const imageContent = document.getElementById('imageContent');
    if (text_fitter && text_fitter_cont && imageContent) {
      text_fitter!.style.fontSize = font_size + 'px';
      imageContent!.style.fontSize = font_size + 'px';

      //vertical centering
      const h1 = text_fitter_cont!.offsetHeight;
      const h2 = text_fitter!.offsetHeight;
      const top_pad = (h1 - h2) / 2;

      //preview
      text_fitter_cont!.style.paddingTop = top_pad + 'px';
      text_fitter_cont!.style.height = 200 - top_pad + 'px';

      //html to render
      imageContent!.style.paddingTop = top_pad - 5 + 'px';
      imageContent!.style.height = 200 - top_pad + 'px';
      //0-9\s!@#$%^&*()_+-=[\]{};':"|,.<>/?
      // var english = /^[a-zA-Z]*$/;
      // if (english.test(autoLogoText)) {
      //   imageContent!.style.direction = 'ltr';
      // } else {
      //   imageContent!.style.direction = 'rtl';
      // }
    }
    //setAutoLogoFontSize(font_size);
    setAutoLogo((current) => {
      const tempObj = { ...current! };

      tempObj.fontSize = font_size;

      return tempObj;
    });
  };

  function renderImage() {
    const imageContainer = document.getElementById('imageContainer');
    // const js = false;
    // if (js) {
    //   if (imageContainer) {
    //     console.log('start ', new Date());
    //     // htmlToImage.toJpeg(imageContainer).then((response: any) => {
    //     //   console.log('toJpeg ',new Date())
    //     //  // const img = response.toDataURL('image/jpeg');
    //     //    setTempBase64(response);
    //     //  });
    //     html2canvas(imageContainer).then((response: any) => {
    //       console.log('html2canvas ', new Date());
    //       const img = response.toDataURL('image/jpeg');
    //       setTempBase64(img);
    //     });
    //   }
    // } else {
      if (imageContainer) {
        var htmlToRender = imageContainer!.outerHTML;

        renderImageEx(htmlToRender).then((response: any) => {
          setTempBase64('data:image/jpg;base64,' + response);
        });
      } else {
        console.log('no html');
      }
   // }
  }
  const checkImageSize = (img: any) => {
    const imgTempLogo = img.currentTarget; //document.getElementById('imgTempLogo');
    if (imgTempLogo) {
      //console.log('newH ' + imgTempLogo.naturalHeight)
      if (imgTempLogo.naturalHeight > 202) {
        //generated image is too big
        //reduce font size
        //and render again
        console.log('2big' + imgTempLogo.naturalHeight);
        autoLogo && renderHtmlByFontSize(autoLogo.fontSize - 1);
      } else if (imgTempLogo.naturalHeight < 195) {
        //too small
        //try again
        console.log('2small' + imgTempLogo.naturalHeight);
        calcFontSize();
      } else {
        //generated image is OK
        //update in modal state
        props.setAutoLogoBase64(tempBase64);
      }
    }
  };

  const colors_palette = [
    '#9493ec',
    '#c31d97',
    '#323a45',
    '#fdb81e',
    '#fd6363',
    '#1e801e',
    '#02bfe7',
    '#ccfd1c',
    '#f4712a',
    '#701e80',
    '#205493',
    '#94bfa2',
    '#f5f84f',
    '#c4c4e9',
    '#4773aa',
    '#981b1e',
    '#5b616b',
    '#112e51',
    '#c799ad',
    '#e1f3f8',
    '#e7f4e4',
    '#fdff9e',
    '#f9dede',
    '#8ba6ca',
    '#ddd0ac',
    '#e58bb6',
    '#ffc09e',
    '#ade0ff',
    '#fad980',
    '#4c7311',
    '#212121',
    '#5e5817',
  ];
  const selectColor = (type: 'fore' | 'back') => {
    //var random_color = colors_palette[Math.floor(Math.random()*colors_palette.length)];
    autoLogo &&
      setColorPickerParams({
        name: type,
        title: type == 'back' ? 'צבע רקע' : 'צבע טקסט',
        value: type == 'back' ? autoLogo.backColor : autoLogo.foreColor,
        presetColors: colors_palette,
      });
    setshowColorPicker(true);
  };
  return (
    <>
      {colorPickerParams && (
        <CustomColorPicker
          show={showColorPicker}
          closeModal={() => {
            setshowColorPicker(false);
            setColorPickerParams(undefined);
          }}
          params={colorPickerParams}
          onColorSelected={(color: any, name: string) => {
            //console.log(color);
            setAutoLogo((current) => {
              const tempObj = { ...current! };
              if (name === 'back') {
                tempObj.backColor = color;
              } else {
                tempObj.foreColor = color;
              }
              return tempObj;
            });

            // if (name === 'back') {
            //   setAutoLogoBackColor(color);
            // } else {
            //   setAutoLogoForeColor(color);
            // }
            setshowColorPicker(false);
            setColorPickerParams(undefined);
          }}
        />
      )}
      <IonRow className="ion-text-center">
        <IonCol size="12">
          {/* PREVIEW */}
          
            <div
              id="text_fitter_cont"
              className="auto-logo-cont"
              style={{
                width: 170,
                height: 170,
                paddingLeft: 15,
                paddingRight: 15,
                textAlign: 'center',
                wordWrap: 'normal',
              }}>
              <span id="text_fitter" className="text-fitter">
                {autoLogoText}
              </span>
            </div>
          
          {/* html to render */}
          {autoLogo && (
            <div id="imageContainer" className="auto-logo-prime-cont primeContainer" style={{
              backgroundColor: autoLogo.backColor,
              color: autoLogo.foreColor,
              fontSize: autoLogo.fontSize,
              direction: 'rtl',
              textAlign: 'center',
              fontWeight: 'bold',
              fontFamily: 'Arial',
              width: 200,
              height: 200,
              paddingLeft: 15,
              paddingRight: 15,
              verticalAlign: 'middle',
            }}>
              <div
                id="imageContent"
                className="img-cont"
                style={{
                  backgroundColor: autoLogo.backColor,
                  color: autoLogo.foreColor,
                  fontSize: autoLogo.fontSize,
                  direction: 'rtl',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontFamily: 'Arial',
                  //width: 200,
                  // height: 200,
                  //paddingLeft: 15,
                  //paddingRight: 15,
                  verticalAlign: 'middle',
                }}>
                {autoLogoText}
              </div>
            </div>
          )}

          <div className="dvTempLogo">
            <img
              id="imgTempLogo"
              src={tempBase64}
              alt="preview"
              onLoad={(img: any) => {
                checkImageSize(img);
              }}
            />{' '}
          </div>
        </IonCol>
        <IonCol size="12">
          {autoLogo && (
            <div className="dvColorPickers">
              <IonRow className="ion-text-center">
                <IonCol size="6">
                  <IonLabel className="lbl-logo-color ion-text-center">
                    צבע רקע
                    <IonButton className="btn-logo-color" color="autoLogoBackColor" onClick={() => selectColor('back')}>
                      <div
                        className="btn-logo-color-inner"
                        style={{
                          backgroundColor: autoLogo.backColor,
                        }}></div>
                    </IonButton>
                  </IonLabel>
                </IonCol>
                <IonCol size="6">
                  <IonLabel className="lbl-logo-color ion-text-center">
                    צבע טקסט
                    <IonButton className="btn-logo-color" color={autoLogo.foreColor} onClick={() => selectColor('fore')}>
                      <div
                        className="btn-logo-color-inner"
                        style={{
                          backgroundColor: autoLogo.foreColor,
                        }}></div>
                    </IonButton>
                  </IonLabel>
                </IonCol>
              </IonRow>
            </div>
          )}
        </IonCol>
        {MemberDetailsCxt.GeneralInfo && (
          <IonCol size="12">
            <CustomInput
              name="logoText"
              errors={props.errors}
              control={props.control}
              label="טקסט לוגו"
              
              onKeyUp={(e: any) => {
                // console.log(e);
                setAutoLogoText(e.target.value);
              }}
              ref={logoTextRef}
              value={autoLogoText}
              required={true}
              maxlength={25}></CustomInput>
          </IonCol>
        )}
      </IonRow>
    </>
  );
};

export default BusinessAutoLogo;
