import React from "react";

export default function PeopleIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="36"
      viewBox="0 0 37 36"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd">
        <path d="M35.149 18.859c-.751-1.67-2.233-2.172-3.667-2.659a24.429 24.429 0 01-.86-.303l-.346-.131-.284.235c-1.86 1.546-4.594 1.577-6.5.072l-.283-.224-.335.128c-.277.105-.553.199-.823.291-.767.261-1.494.509-2.142.956l.72 1.043c.504-.348 1.148-.568 1.83-.8.178-.06.356-.122.536-.185 2.253 1.58 5.305 1.55 7.513-.077.19.067.379.132.568.195 1.284.436 2.393.812 2.903 1.94 1.074 2.862 1.298 5.34.927 5.612a.144.144 0 01-.036.003h-9.338v1.267h9.338c.451 0 .84-.202 1.093-.57 1.095-1.58-.602-6.231-.814-6.793"></path>
        <path d="M23.952 8.428c.644-.749 1.57-1.128 2.75-1.128 1.182 0 2.107.38 2.75 1.128.568.66.88 1.583.88 2.601 0 2.043-1.272 4.214-3.63 4.214-2.357 0-3.629-2.17-3.629-4.214 0-1.018.312-1.941.879-2.6m2.75 8.081c3.18 0 4.897-2.824 4.897-5.48 0-1.322-.42-2.538-1.186-3.428-.882-1.026-2.165-1.57-3.71-1.57s-2.83.544-3.712 1.57c-.763.89-1.184 2.106-1.184 3.427 0 2.657 1.715 5.481 4.896 5.481M23.45 29.705c-.064.092-.126.124-.245.124H1.736c-.118 0-.18-.032-.245-.124-.638-.922.125-4.716 1.223-7.644.732-1.625 2.091-2.087 3.81-2.674.27-.092.544-.185.819-.284 2.98 2.152 7.07 2.11 9.987-.102.287.103.576.201.863.298 1.767.6 3.293 1.117 4.02 2.725 1.113 2.965 1.876 6.76 1.237 7.68M18.6 18.102c-.386-.132-.774-.263-1.16-.41l-.345-.131-.284.236c-2.568 2.134-6.341 2.173-8.97.096l-.282-.222-.336.127c-.373.142-.743.268-1.107.391-1.769.603-3.598 1.227-4.572 3.39-.104.276-2.523 6.785-1.094 8.848.303.437.749.67 1.286.67h21.469c.538 0 .983-.233 1.286-.67 1.429-2.063-.99-8.572-1.109-8.885-.968-2.148-2.908-2.805-4.782-3.44"></path>
        <path d="M8.428 7.352c.891-1.036 2.221-1.585 3.844-1.585 1.623 0 2.953.549 3.845 1.585.792.922 1.227 2.209 1.227 3.622 0 2.838-1.777 5.855-5.072 5.855S7.2 13.812 7.2 10.974c0-1.413.436-2.7 1.228-3.622m3.844 10.743c4.118 0 6.34-3.668 6.34-7.12 0-1.718-.546-3.297-1.535-4.448-1.14-1.326-2.802-2.027-4.805-2.027-2.004 0-3.665.701-4.805 2.027-.99 1.152-1.534 2.73-1.534 4.447 0 3.453 2.221 7.121 6.339 7.121"></path>
      </g>
    </svg>
  );
}
