import {
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonList,
  IonRow,
} from "@ionic/react";
import React, { useContext, useEffect } from "react";
import { isMobile } from "react-device-detect";
import StatisticContext from "../../data/Statistic-Context";
import { arrowDown, important_devices } from "../../images/index";

const StatisticActionSummery: React.FC<{
  fromDate: Date;
  toDate: Date;
  listHeight?: number;
}> = (props) => {
  const [isActive, setIsActive] = React.useState(false);
  const StatisticsCxt = useContext(StatisticContext);

  useEffect(() => {
    if (StatisticsCxt.actionSummery == null) {
      StatisticsCxt.initActionSummeryContext(
        false,
        props.fromDate,
        props.toDate
      );
    }
  }, []);
  let flag = 0;
  return (
    <React.Fragment>
      {StatisticsCxt.actionSummery && StatisticsCxt.actionSummery.length > 0 && (
        <IonGrid>
          <IonRow>
            <IonCol className="card-title ion-no-padding">
              <IonIcon
                icon={important_devices}
                className="statistics"
                style={{
                  background:
                    "linear-gradient(315deg, rgba(255,182,67,1) 0%, rgba(255,105,47,1) 100%)",
                }}
              />
              <h3 className="bold-text ion-no-margin">סיכום פעולות</h3>
            </IonCol>
          </IonRow>
          <IonList
            style={{
              // height: !isActive && props.listHeight,
              display: "flex",
              flexWrap: "wrap",
              overflow: "unset",
              padding: "0px 5px",
            }}
            className={`itemListActions ${isActive ? "active" : ""}`}
          >
            {StatisticsCxt.actionSummery.map((s, index) => {
              if (
                s.SumClicks > 0 &&
                s.ClickTypeDesc &&
                s.ClickTypeDesc !== ""
              ) {
                flag++;
                return (
                  <IonItem
                    key={index}
                    lines="none"
                    style={{
                      width: isMobile ? "50%" : "33%",
                    }}
                  >
                    <IonCol
                      className="ion-no-padding ion-text-center"
                      style={{ marginLeft: "24px" }}
                    >
                      <p className="bold-text" style={{ color: "#16254f" }}>
                        {s.SumClicks}
                      </p>
                    </IonCol>
                    <IonCol size="8">
                      <p className="ellipsis">{s.ClickTypeDesc}</p>
                    </IonCol>
                  </IonItem>
                );
              }
            })}
          </IonList>
          {((flag > 6 && isMobile) || (flag > 12 && !isMobile)) && (
            <IonRow>
              <IonCol>
                <IonItem
                  lines="none"
                  onClick={() => setIsActive(!isActive)}
                  className="ion-text-center"
                >
                  <div
                    style={{
                      margin: "0 auto",
                      cursor: "pointer",
                      color: "#383b41",
                      display: "flex",
                      textDecoration: "underline",
                      fontWeight: "bold",
                      fontSize: isMobile ? "12px" : "14px",
                    }}
                  >
                    פעולות נוספות{" "}
                    <img
                      src={arrowDown}
                      alt="arrow down"
                      style={
                        isActive
                          ? {
                              transform: "rotate(180deg)",
                              transition: "transform ease 0.5s",
                            }
                          : undefined
                      }
                    />
                  </div>
                </IonItem>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      )}
    </React.Fragment>
  );
};

export default StatisticActionSummery;
