import React, { useEffect, useState } from 'react'
import { CoSaleSubject } from '../../data/CoSale-Context'
import CustomAlert from '../../components/utils/CustomElements/CustomAlert'

const CoSaleItem: React.FC<{
  currentItemIndex: number
  currentSubject: CoSaleSubject
}> = (props) => {
  const [currentHtml, setCurrentHtml] = useState('')
  const [currentContent, setCurrentContent] = useState<JSX.Element | undefined>(
    undefined
  )

  const [showAlert, setShowAlert] = useState(false)
  const [alert, setAlert] = useState('')

  const next_item = props.currentSubject.Items[props.currentItemIndex]

  useEffect(() => {
    updateHtml()
  }, [props.currentItemIndex])

  useEffect(() => {
    updateHtml()
  }, [props.currentSubject])

  function updateHtml() {
    if (props.currentSubject.Items) {
      setShowAlert(false)
      setAlert('')
      if (next_item && next_item.Content) {
        if (next_item.Type == 'Page') {
          setCurrentContent(undefined)
          setCurrentHtml(next_item.Content || '')
        } else if (next_item.Type == 'ReactPage') {
          setCurrentHtml('')
          setCurrentContent(next_item.Content())
        } else if (next_item.Type == 'Alert') {
          setAlert(next_item.Content || '')
          setShowAlert(true)
        }
      } else {
        console.log('no item')
      }
    }
  }

  return (
    <>
      <CustomAlert
        isOpen={showAlert}
        header={'הודעת מערכת'}
        message={alert}
        onDismiss={() => {
          setShowAlert(false)
          setAlert('')
        }}
        buttons={[
          {
            text: 'אישור',
            role: 'ok',
            handler: () => {
              setShowAlert(false)
              setAlert('')
            },
          },
        ]}
      ></CustomAlert>
      {currentContent != undefined && currentContent}
      {currentHtml.length > 0 && (
        <div dangerouslySetInnerHTML={{ __html: currentHtml }} />
      )}
      {/* {next_item.Type === "ReactPage" ? (
        next_item.Content()
      ) : (
        <div dangerouslySetInnerHTML={{ __html: currentHtml }} />
      )} */}
    </>
  )
}

export default CoSaleItem
