import React, { useState, useCallback, useContext } from "react";
import CouponContext, {
  Coupon,
  CouponAdditionalInfo,
  CouponMember,
} from "./Coupon-context";
import { config } from "../Constants";
import { fetchRequest } from "../utils-ts/fetchRequest";
import { toast } from "react-toastify";
//import CustomLoader from "../components/utils/CustomElements/CustomLoader";
import GlobalContext from "./Global-Context";
const parseString = require("xml2js").parseString;

const CouponContextProvider: React.FC = (props) => {
  const [coupon, setCoupon] = useState<Coupon | undefined>();
  //const [loading, setLoading] = useState<boolean>(false);

  const globalCtx = useContext(GlobalContext);

  const setLoading = (loading:boolean)=>{
   
   globalCtx.setLoader(loading);
 
  };

  const initCouponContext = useCallback(async (useLoading: boolean) => {
    useLoading && setLoading(useLoading);
    var data = new URLSearchParams({}).toString();
    fetchRequest(
      `${config.url.API_URL}/ClientCoupon.asmx/GetCoupon`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Response) {
              if (res.Success) {
                const couponObj = JSON.parse(res.Response);
                setCoupon(couponObj);
              }
            }
          }
          useLoading && setLoading(false);
        });
      })
      .catch(function (error) {
        console.log("Request failed", error);
        useLoading && setLoading(false);
      });
  }, []);

  const saveCoupon = (
    couponMember: CouponMember,
    currentCouponAInfo: CouponAdditionalInfo
  ) => {
    return new Promise((resolve) => {
      setLoading(true);
      const currentCoupon: Coupon = {
        CouponMember: couponMember,
        CouponsTemplatesList: coupon?.CouponsTemplatesList!,
        CouponAdditionalInfo: currentCouponAInfo,
      };
      setCoupon(currentCoupon);

      var data = new URLSearchParams({
        jsCoupon: JSON.stringify(couponMember),
      }).toString();

      fetchRequest(
        `${config.url.API_URL}/ClientCoupon.asmx/saveCoupon`,
        "post",
        data
      )
        .then((response) => {
          parseString(response, (err: any, result: any) => {
            if (result) {
              var res = JSON.parse(result.string["_"]);
              if (res.Success) {
                dtlPushEvt({'event':'details_update','eventInfo': {'ScreenName':'{{page path}}','user_action':'updates_ClientCoupon'}});
                toast.success("נשמר בהצלחה");
              } else {
                dtlPushEvt({'event':'errors','eventInfo': {'ScreenName':'{{page path}}','user_action':'got_error_ClientCoupon'}});
                toast.error(res.ErrorMessage);
              }
              resolve(res.Success);
            }
            setLoading(false);
          });
        })
        .catch(function (error) {
          console.log("Request failed", error);
          setLoading(false);
          resolve(false);
        });
    });
  };

  const deleteCoupon = async () => {
    return new Promise((resolve) => {
      setLoading(true);
      var data = new URLSearchParams({}).toString();
      fetchRequest(
        `${config.url.API_URL}/ClientCoupon.asmx/deleteCoupon`,
        "post",
        data
      )
        .then((response) => {
          parseString(response, (err: any, result: any) => {
            if (result) {
              var res = JSON.parse(result.string["_"]);
              if (res.Success) {
                setCoupon(undefined);
                toast.success("נמחק בהצלחה");
              } else {
                toast.error(res.ErrorMessage);
              }
              resolve(res.Success);
            }
            setLoading(false);
          });
        })
        .catch(function (error) {
          console.log("Request failed", error);
          setLoading(false);
          resolve(false);
        });
    });
  };

  return (
    <CouponContext.Provider
      value={{ coupon, saveCoupon, deleteCoupon, initCouponContext }}
    >
      {props.children}
      {/* <CustomLoader
        duration={10000}
        isOpen={loading}
        onDidDismiss={() => {
          setLoading(false);
        }}
      ></CustomLoader> */}
    </CouponContext.Provider>
  );
};

export default CouponContextProvider;
