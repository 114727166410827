import React from "react";
import { Photo } from "../components/utils/ImagePicker/ImagePicker";

export type productGalleryTypes = "default" | "eCommerce";
export type originModal =
  | "OpenDays"
  | "PublicationDays"
  | "GeneralInfo"
  | "AdvCategories"
  | "Languages"
  | "Description";

export interface BusinessLanguages {
  Arabic: boolean;
  Chinese: boolean;
  English: boolean;
  France: boolean;
  German: boolean;
  Hebrew: boolean;
  Italian: boolean;
  Portuguese: boolean;
  Romanian: boolean;
  Russian: boolean;
  Spanish: boolean;
  Bulgarian: boolean;
  Hungarian: boolean;
  Turkish: boolean;
  Polish: boolean;
  Ukrainian: boolean;
  Japanese: boolean;
  Yiddish: boolean;
  Amharic: boolean;
}

export interface ContentStatus {
  title: string;
  isExist: number;
  weight: number;
  iconName: string;
}

export interface ExtraDetailes {
  Package_Desc: string;
  RegistrationDate: string;
}

export interface GeneralInfo {
  CompanyName: string;
  EcommerceSite: string;
  Email: string;
  Facebook: string;
  FacebookId: string;
  Fax: string;
  Instagram: string;
  LandingPage: string;
  Mobile: string;
  Phone1: string;
  WebSite: string;
  VirtualNumber:string;
  BusinessExpert: string;
  BuisenessPageUrl: string;
  YoutubeChannel: string;
  TikTok:string;
  Wolt:string;
  SeoBrands:string;
}

export interface AdvCategories {
  Category: string;
  Id: string;
  IsChecked: boolean;
  Title: string;
  CategoryFldDesc: string;
}

export interface OpenDays {
  Day1: boolean;
  Day2: boolean;
  Day3: boolean;
  Day4: boolean;
  Day5: boolean;
  Day6: boolean;
  Day7: boolean;
  FromHour: number | undefined;
  FromHourMinutes: number | undefined;
  Id: string;
  Is24x7: boolean;
  IsDeleted: boolean;
  IsDirty?: boolean;
  IsNeedToPhone: boolean;
  IsNextDay: boolean;
  IsSaturdayInOut: boolean;
  ToHour: number | undefined;
  ToHourMinutes: number | undefined;
}

export interface NormalizedOpenDay {
  openDays: string;
  openHour: string;
  closeHour: string;
  isNextDay: boolean;
}
export interface PublicationDays {
  Day1: boolean;
  Day2: boolean;
  Day3: boolean;
  Day4: boolean;
  Day5: boolean;
  Day6: boolean;
  Day7: boolean;
  FromHour: number | undefined;
  FromHourMinutes: number | undefined;
  Id: string;
  ToHour: number | undefined;
  ToHourMinutes: number | undefined;
  isDelete: boolean | false;
}

export interface BusinessAddress {
  City: string;
  Street: string;
  StreetNo: string;
  ZipCode: string;
}

export interface BusinessLink {
  Address: string;
  Description: string;
  LinksNum: number;
  isDelete: boolean;
}

export interface BusinessMovie {
  Id: number;
  MovieUrl: string;
  MovieDesc: string;
  IsFirst: boolean;
  MovieType: "YT" | "FB" | "TK"| "IG";
  TagsArray: string[];
}
export interface QuestionsAndAnswers {
  Question: string;
  Answer: string;
  Id: number;
  SortOrder: number;
}

export interface BusinessEvent {
  City: string;
  Description: string;
  EndDate: string;
  Id: number;
  Link: string;
  Member_Id?: string;
  Message: string;
  StartDate: string;
  StartHour: string;
  EndHour: string;
  Street: string;
  Street_No: string;
  isDelete: boolean;
}

export interface BusinessServiceAreaObj {
  ServiceAreaType: "country" | "region" | "city";
  MemberCity: { City_Code: number; City: string };
  ServiceAreasList: { City_Code: number; City: string }[];
  DoDelivery?: boolean;
  HasDelivery: boolean;
}

export interface BusinessNewsItem {
  Id: number;
  Title: string;
  Description: string;
  Link: string;
  Base64Image: string;
  ImagePath: string;
  LastUpdate: string;
}
export interface BusinessCertificateItem {
  Id: number;
  QualifiedName: string;
  Description: string;
  LicenseNumber: string;
  IssuerType: number;
  IssuerOtherDesc: string;
  Base64Image: string;
  ImagePath: string;
  DateOfIssue: string;
  DateOfEstablishment: string;
  DateOfExpiry: string;
}


export interface CertificateIssuerType {
  Id: number;
  IssuerTypeDesc: string;
}
        //public int FileId { get; set; }
export interface BusinessSeoDetailsItem {
  Id: number;
  Text: string;
  Type: number;
  SeoRunId?: string;
  isDelete:boolean;
}
export interface SeoDetailsItem {
  Id: number;
  Text: string;
  Type: number;
  IsOpenField:boolean;
  Placeholder?:string;
}

export interface  MultiCardsMember 
{
    Id: string;
    IsAdmin:boolean;
    Name: string;
    City: string;
    Street: string;
    StreetNo: string;
    Current: boolean;
}

const MemberDetailes = React.createContext<{
  ContentStatus: ContentStatus | undefined;
  ContentStatusPercentage: number | undefined;
  //BusinessMovie: string | undefined;
  BusinessMovies: BusinessMovie[] | undefined;
  BusinessLinks: BusinessLink[] | undefined;
  BusinessSeoDetails: BusinessSeoDetailsItem[] | undefined;
  SeoDetails: SeoDetailsItem[] | undefined;
  BusinessQuestionsAndAnswers: QuestionsAndAnswers[] | undefined;
  BusinessEvents: BusinessEvent[] | undefined;
  Description: string | undefined;
  DaysRemarks: string;
  AdvCategories: AdvCategories[] | undefined;
  OpenDays: OpenDays[] | undefined;
  isPublicationDays: boolean | false;
  PublicationDays: PublicationDays[] | undefined;
  publicationDaysType: number | undefined;
  fullFriday: boolean | true;
  halfFriday: boolean | false;
  fullSaturday: boolean | true;
  BusinessNews: BusinessNewsItem[] | undefined;
  BusinessCertificates: BusinessCertificateItem[] | undefined;
  CertificateIssuerTypes: CertificateIssuerType[] | undefined;
  allowSyncGmb: boolean | undefined;
  GeneralInfo: GeneralInfo | undefined;
  BusinessLanguages: BusinessLanguages | undefined;
  BusinessAddress: BusinessAddress | undefined;
  BusinessLogo: string | undefined;
  BusinessServiceArea: BusinessServiceAreaObj | undefined;
  ExtraDetailes: ExtraDetailes | undefined;
  ShowProductGalleryTypes: boolean;
  NewProducts: boolean;
  PriceLists: boolean;
  ShowNews: boolean;
  ShowSeoBrands: boolean;
  IsAutoLogo: boolean;
  ManageProductGallery: boolean;
  ProductGalleryType: productGalleryTypes;
  FirstLogin: boolean | undefined;
  ShowSelfSale: boolean | undefined;
  ShowSelfSaleAdds: boolean | undefined;
  ShowSelfSaleGoogle: boolean | undefined;
  IsMemberMultiCard: boolean;
  MultiCardsMembers: MultiCardsMember[] | undefined;
  saveMainDetails: (
    _originModal: originModal,
    _advCategories: AdvCategories[] | undefined,
    _businessLanguages: BusinessLanguages | undefined,
    //_openDays: OpenDays[] | undefined,
   // _DaysRemarks: string | undefined,
    _GeneralInfo: GeneralInfo | undefined,
    _description: string | undefined,
    _syncGmb?: boolean | undefined,
    _syncType?: string | undefined
  ) => void;
  saveMovieHandler: (
    newMovie: BusinessMovie,
    action: "save" | "delete"
  ) => void;
  saveMovieData:(newMovie: BusinessMovie) => any;
  //getMovieData: (movieType: string,url:string) => any;
  saveLinksHandler: (link: BusinessLink) => void;
  saveSeoDetailsHandler: (item: BusinessSeoDetailsItem) => void;
  updateMemberSeoBrandsHandler: (items: BusinessSeoDetailsItem[]) => void;
  savePublicationDaysHandler: (
    publicationDays: PublicationDays[] | undefined,
    templateID: number | undefined,
    fullFriday: boolean,
    halfFriday: boolean,
    fullSaturday: boolean
  ) => void;
  saveQuestionAnswerHandler: (
    questionAnswer: QuestionsAndAnswers,
    action: "save" | "delete"
  ) => void;
  sortQuestionAnswerHandler: (
    QuestionsAndAnswersArray: QuestionsAndAnswers[],
    status: "start" | "finish"
  ) => void;
  saveEventsHandler: (event: BusinessEvent) => void;
  saveLogoHandler: (photo: Photo, sync_gmb: boolean) => void;
  saveAutoLogoHandler: (base64: string, sync_gmb: boolean) => void;
  saveServiceAreasHandler: (serviceArea: BusinessServiceAreaObj) => void;
  saveOpenDays: (_openDays: OpenDays[],_syncGmb: boolean)=> void;
  deleteLogoHandler: (sync_gmb: boolean) => void;
  initBusinessEventsContext: () => void;
  initMovieContext: () => void;
  initBusinessLinksContext: () => void;
  initBusinessSeoDetailsContext: () => void;
  initSeoDetailsContext: () => void;
  initBusinessQuestionsAndAnswerContext: () => void;
  initBusinessLogoContext: () => void;
  initMemberDetailsContext: (isSelfReg?: boolean) => void;
  initContentStatusContext: () => void;
  initExtraDetailes: () => void;
  initFirstLogin: () => void;
  initServiceAreasContext: () => any;
  syncMemberGmb: (isOpenHoursOnly: boolean) => void;
  updateProductsGalleryType: (galleryType: productGalleryTypes) => void;
  getBusinessNews: (useLoading: boolean) => void;
  saveBusinessNews: (item:BusinessNewsItem) => void;
  deleteBusinessNews: (id:number) => void;
  getBusinessCertificates: (useLoading: boolean) => void;
  saveBusinessCertificate: (item:BusinessCertificateItem) => void;
  deleteBusinessCertificate: (id:number) => void;
  initSelfSale: () => void;
  getMemberOpenHoursTemplate:()=> any;
  saveMemberDeliverySettings:(doDelivery: boolean)=> any;
}>({
  ContentStatus: undefined,
  ContentStatusPercentage: undefined,
  //BusinessMovie: undefined,
  BusinessMovies: undefined,
  BusinessLinks: undefined,
  BusinessSeoDetails: undefined,
  SeoDetails: undefined,
  BusinessQuestionsAndAnswers: undefined,
  BusinessEvents: undefined,
  Description: "",
  DaysRemarks: "",
  ExtraDetailes: undefined,
  ShowProductGalleryTypes: false,
  NewProducts: false,
  IsAutoLogo: false,
  PriceLists: false,
  ShowNews:false,
  ShowSeoBrands: false,
  IsMemberMultiCard:false,
  MultiCardsMembers: undefined,
  ManageProductGallery: false,
  ProductGalleryType: "default",
  FirstLogin: false,
  ShowSelfSale: false,
  ShowSelfSaleAdds: false,
  ShowSelfSaleGoogle: false,
  AdvCategories: undefined,
  OpenDays: undefined,
  isPublicationDays: false,
  PublicationDays: undefined,
  publicationDaysType: undefined,
  fullFriday: true,
  halfFriday: false,
  fullSaturday: true,
  BusinessNews:undefined,
  BusinessCertificates:undefined,
  CertificateIssuerTypes:undefined,
  allowSyncGmb: undefined,
  GeneralInfo: {
    CompanyName: "",
    EcommerceSite: "",
    Email: "",
    Facebook: "",
    FacebookId: "",
    Fax: "",
    Instagram: "",
    LandingPage: "",
    Mobile: "",
    Phone1: "",
    VirtualNumber:"",
    WebSite: "",
    BusinessExpert: "",
    BuisenessPageUrl: "",
    YoutubeChannel: "",
    TikTok: "",
    Wolt: "",
    SeoBrands: ""
  },
  BusinessLanguages: {
    Amharic: false,
    Arabic: false,
    Chinese: false,
    English: false,
    France: false,
    German: false,
    Hebrew: false,
    Italian: false,
    Portuguese: false,
    Romanian: false,
    Russian: false,
    Spanish: false,
    Bulgarian: false,
    Hungarian: false,
    Turkish: false,
    Polish: false,
    Ukrainian: false,
    Japanese: false,
    Yiddish: false,
  },
  BusinessAddress: { City: "", Street: "", StreetNo: "", ZipCode: "" },
  BusinessLogo: "",
  BusinessServiceArea: undefined,
  saveMainDetails: () => {},
  saveMovieHandler: () => {},
  saveMovieData: () => {},
  //getMovieData: () => {},
  saveLinksHandler: () => {},
  saveSeoDetailsHandler: () => {},
  updateMemberSeoBrandsHandler: () => {},
  savePublicationDaysHandler: () => {},
  saveQuestionAnswerHandler: () => {},
  sortQuestionAnswerHandler: () => {},
  saveEventsHandler: () => {},
  saveLogoHandler: () => {},
  saveAutoLogoHandler: () => {},
  saveServiceAreasHandler: () => {},
  saveOpenDays: () => {},
  deleteLogoHandler: () => {},
  initBusinessEventsContext: () => {},
  initMovieContext: () => {},
  initBusinessLinksContext: () => {},
  initBusinessSeoDetailsContext: () => {},
  initSeoDetailsContext: () => {},
  initBusinessQuestionsAndAnswerContext: () => {},
  initBusinessLogoContext: () => {},
  initMemberDetailsContext: () => {},
  initContentStatusContext: () => {},
  initExtraDetailes: () => {},
  initFirstLogin: () => {},
  initServiceAreasContext: () => {},
  syncMemberGmb: () => {},
  updateProductsGalleryType: () => {},
  getBusinessNews: () => {},
  saveBusinessNews: () => {},
  deleteBusinessNews: () => {},
  getBusinessCertificates: () => {},
  saveBusinessCertificate: () => {},
  deleteBusinessCertificate: () => {},
  initSelfSale: () => {},
  getMemberOpenHoursTemplate:()=> {},
  saveMemberDeliverySettings:()=> {}
});

export default MemberDetailes;
