import React, { useContext, useState, useEffect } from "react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { isMobile } from "react-device-detect";

import CustomPage from "../../components/utils/CustomElements/CustomPage";
import CustomModal from "../../components/utils/CustomElements/CustomModal/CustomModal";
import CustomHeaddingItem from "../../components/utils/CustomElements/CustomPageTitle/CustomHeaddingItem";
import VasContext, { VasProduct } from "../../data/Vas-context";

import FrmVasMain from "../../components/Vas/FrmVasMain";
import InnerVasInfo from "../../components/Vas/InnerVasInfo";
import {
  generateExternalLinkWithToken,
  ExternalLinkPages,
  InternalLinkPages,
} from "../../data/GlobalService";

import "./Vas.scss";
import UserContext from "../../data/AuthContextProvider";
import MemberDetailsContext from "../../data/MemberDetailes-context";

const VasMenu: React.FC = () => {
  const VasCxt = useContext(VasContext);
  const [VasProducts, setVasProducts] = useState<VasProduct[] | null>(null);
  const [selectedProd, setSelectedProd] = useState<VasProduct | null>(null);
  const [infoProd, setInfoProd] = useState<VasProduct | null>(null);
  const [externalLinkWithToken, setExternalLinkWithToken] = useState<
    string | undefined
  >();
  const { authValues } = useContext(UserContext);
  const MemberDetailsCxt = useContext(MemberDetailsContext);

  useEffect(() => {
    if (authValues && authValues.IsSelfRegister == false) {
      if (VasProducts == null) {
        VasCxt.getVasProducts(true).then((res: any) => {
          if (res) {
            //debugger;
            setVasProducts(res);
          }
        });
      }

      generateExternalLinkWithToken().then((data) => {
        setExternalLinkWithToken(data);
      });
    } else {
      setVasProducts([]);
    }
  }, []);

  const handleItemClicked = (prod: VasProduct) => {
    setInfoProd(prod);
    dtlPushEvt({
      event: "purchase",
      eventInfo: {
        ScreenName: "{{page path}}",
        user_action: "opens_" + prod?.title,
        product: "" + prod?.title,
      },
    });
  };

  const getProdExternalLink = (prod: VasProduct) =>
    InternalLinkPages[prod.prod_code]
      ? InternalLinkPages[prod.prod_code]
      : ExternalLinkPages[prod.prod_code]
      ? externalLinkWithToken + ExternalLinkPages[prod.prod_code]
      : externalLinkWithToken;

  const onPurchaseClicked = (prod_code: number) => {
    setInfoProd(null);
    const prod = VasProducts?.find((p) => {
      return p.prod_code == prod_code;
    });
    if (prod) {
      selectProdHandler(prod);

      dtlPushEvt({
        event: "purchase",
        eventInfo: {
          ScreenName: "{{page path}}",
          user_action: "clicks_purchase_" + prod.title,
          product: "" + prod.title,
        },
      });
    }
  };
  const selectProdHandler = (prod: VasProduct) => {
    //setFormDirty(false);
    setSelectedProd(prod);
  };
  const updateProductAdded = (prod_code: number) => {
    setVasProducts((currProducts) => {
      const updatedProducts = currProducts != null ? [...currProducts] : [];
      const updatedProductIndex = updatedProducts.findIndex(
        (p) => p.prod_code == prod_code
      );

      const updatedProduct = { ...updatedProducts[updatedProductIndex] };
      updatedProduct.allow_purchase = false;
      updatedProducts[updatedProductIndex] = updatedProduct;

      return updatedProducts;
    });
  };

  const onSuccess = (prod_code: number) => {
    updateProductAdded(prod_code);

    dtlPushEvt({
      event: "purchase",
      eventInfo: {
        ScreenName: "{{page path}}",
        user_action: "saves_purchase_" + selectedProd?.title,
        business_name:
          "" +
          (MemberDetailsCxt.GeneralInfo
            ? MemberDetailsCxt.GeneralInfo?.CompanyName
            : ""),
        business_category: GetMemberAdvCategories(
          MemberDetailsCxt.AdvCategories
        ),
        business_location: MemberDetailsCxt.BusinessAddress?.City,
      },
    });

    setSelectedProd(null);

    //setFormDirty(false);
  };
  return (
    <React.Fragment>
      <CustomModal
        pageName={"VasInfo"}
        headerParameters={{
          isPageHaveChanges: false,
          isShowDeleteButton: false,
          isShowLogo: isMobile,
          modalType: "modal",
        }}
        closeModal={() => {
          setInfoProd(null);
        }}
        isOpen={!!infoProd}
        title={!!infoProd ? infoProd.title : ""}
      >
        {!!infoProd && infoProd.prod_code > 0 && (
          <InnerVasInfo
            onPurchase={onPurchaseClicked}
            prod_code={infoProd.prod_code}
          ></InnerVasInfo>
        )}
      </CustomModal>
      <CustomModal
        pageName={"AddVas"}
        headerParameters={{
          isPageHaveChanges: true,
          isShowDeleteButton: false,
          isShowLogo: isMobile,
          modalType: "modal",
        }}
        closeModal={() => {
          setSelectedProd(null);
        }}
        isOpen={!!selectedProd}
        title={!!selectedProd ? selectedProd.title : ""}
      >
        {!!selectedProd && selectedProd.prod_code > 0 && (
          <FrmVasMain
            onSuccess={onSuccess}
            prod_code={selectedProd.prod_code}
          ></FrmVasMain>
        )}
      </CustomModal>

      <CustomPage
        headerParameters={{
          isShowLogo: true,
          backButton: { isShowMobile: true },
          isShowDeleteButton: false,
        }}
        title="מוצרי פרסום נלווים"
        pageName={"VasMenu"}
      >
        <IonGrid fixed={true} className="main-content">
          <IonRow>
            <IonCol>
              {!!VasProducts && (
                <IonGrid>
                  <IonRow>
                    {VasProducts.filter((prod) => {
                      return prod.allow_purchase;
                    }).length == 0 && <div style={{ height: 50 }} />}
                    {VasProducts.filter((prod) => {
                      return prod.allow_purchase;
                    }).map((prod) => (
                      <IonCol size="12" size-lg="4" key={prod.prod_code}>
                        <CustomHeaddingItem
                          mainTitle={prod.title}
                          subTitle={prod.short_desc}
                          icon={prod.icon_select_vas}
                          iconBackground="icon-background-white"
                          onClick={() => {
                            handleItemClicked(prod);
                          }}
                        ></CustomHeaddingItem>
                      </IonCol>
                    ))}
                  </IonRow>
                  {VasProducts.filter((prod) => {
                    return !prod.allow_purchase;
                  }).length > 0 && (
                    <React.Fragment>
                      <IonRow>
                        <IonCol>
                          <h3 className="bold-text small-title">
                            מוצרי הפרסום שלי
                          </h3>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        {VasProducts.filter((prod) => {
                          return !prod.allow_purchase;
                        }).map((prod) => (
                          <IonCol size="12" size-lg="4" key={prod.prod_code}>
                            <CustomHeaddingItem
                              mainTitle={prod.title}
                              subTitle={prod.short_desc}
                              icon={prod.icon_my_vas}
                              externalLink={getProdExternalLink(prod)}
                              target={
                                InternalLinkPages[prod.prod_code] && "_self"
                              }
                              button={!!getProdExternalLink(prod)}
                              noHover={true}
                              iconBackground="icon-background-blue"
                            ></CustomHeaddingItem>
                          </IonCol>
                        ))}
                      </IonRow>
                    </React.Fragment>
                  )}
                </IonGrid>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </CustomPage>
    </React.Fragment>
  );
};

export default VasMenu;
