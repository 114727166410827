import React, { FC, ComponentProps } from "react";
import { download } from "../../../images/index";
import { IonButton, IonIcon } from "@ionic/react";
import "../../../utils-ts/qrDownloadStyles.scss";
import { isMobile } from "react-device-detect";

type IonButtonProps = ComponentProps<typeof IonButton>;

interface ButtonProps extends IonButtonProps {
  icon?: string;
  btnTitle: string;
  btnClass?: string; //icon/iconAndTitle/iconAndTitleRow/title
  callbackfunc: () => void;
}

const CustomImageButton: FC<ButtonProps> = ({ ...props }) => {
  
  const btnHandler = () => {
    switch (props.btnClass) {
      case "iconAndTitleRow":
        return (
          <div>
            <IonIcon icon={props.icon} />
            {props.btnTitle}
          </div>
        );
      default:
        return (
          <div>
            <IonIcon icon={props.icon ? props.icon : download} />
            {!isMobile && props.btnTitle}
          </div>
        );
    }
  };

  return (
    <div className={`pdf-btn-wrapper ${props.btnClass && props.btnClass}`}>
      <IonButton
        className="button-flex-rows"
        fill={props.btnClass ? "solid" : "clear"}
        onClick={() => {
          props.callbackfunc();
        }}
        {...props}
      >
        {btnHandler()}
      </IonButton>
     
    </div>
  );
};

export default CustomImageButton;
