import { tip1, tip2 } from "../images/index";
 

export const tips=[
    {
        tipImg: tip1,
        tipTitle: 'ביקורות',
        tipDes: 'מחקרים מראים שטיב השירות ואיכות בית העסק נמדד בית היתר באמצעות...'
    },
    {
        tipImg: tip2,
        tipTitle: 'מבצעים',
        tipDes: 'מבצע אטרקטיבי ומעניין יכול למשוך לקוחות...'
    },
]


