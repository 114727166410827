import React from "react";
import { HeaderParameters } from "../../data/Header-Context";
import CustomModal from "../utils/CustomElements/CustomModal/CustomModal";
import ProductInfoData from "./ProductInfoData";
const ProductInfoModal: React.FC<{
  closeModal: () => void;
  show: boolean;
  selectedProduct: string;
}> = (props) => {
  const headerParameters: HeaderParameters = {
    isShowLogo: true,
    modalType: "modal",
  };
  return (
    <CustomModal
      pageName={"ProductInfo"}
      headerParameters={headerParameters}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
    >
      <ProductInfoData productName={props.selectedProduct}></ProductInfoData>
    </CustomModal>
  );
};
export default ProductInfoModal;
