import {
  IonButton,
  IonChip,
  IonIcon,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonText,
} from "@ionic/react";
import { filter, close } from "ionicons/icons";
import React, { useRef, useState } from "react";
import "./dropDown.scss";

export interface filterOptions {
  title: string;
  value: string | undefined;

}

const DropDownFilter: React.FC<{
  options: filterOptions[];
  defaulvalueIndex: number;
  disable?: boolean;
  onValueCahnge: (value: any) => void;
  showSelectedTextInBtn?:boolean;
  customStyle?:React.CSSProperties;
}> = (props) => {
  let selectRef = useRef<HTMLIonSelectElement>(null);

  // check defaulvalueIndex is valid
  let elementIndex =
    props.defaulvalueIndex > props.options.length ? 0 : props.defaulvalueIndex;
  const defaultvalue = props.options[elementIndex];
  const [selectedValue, setSelectedValue] = useState<filterOptions>(
    defaultvalue
  );
  const [showlable, setShowlable] = useState(false);
  return (
    <div className="dropDown-filter-wrapper" style={props.customStyle}>
      {props.options && props.options.length > 0 && (
        <IonButton
        size="small"
        fill="clear"
        disabled={props.disable}
          onClick={(e) => {
            selectRef.current?.open();
          }}
        >
          {props.showSelectedTextInBtn && selectedValue && <IonText className="ion-padding-end">{selectedValue.title}</IonText>}
          <IonIcon icon={filter}></IonIcon>
        </IonButton>
      )}
      <IonSelect
        mode="ios"
        ref={selectRef}
        hidden={true}
        cancelText="ביטול"
        okText="אישור"
        value={selectedValue.value}
        onIonChange={(e) => {
          const newValue = e.detail.value;
          const newIndex = props.options.findIndex((e) => e.value === newValue);
          if (newIndex != elementIndex && props.showSelectedTextInBtn != true) {
            setShowlable(true);
          } else {
            setShowlable(false);
          }
          setSelectedValue(props.options[newIndex]);
          props.onValueCahnge(newValue);
        }}
      >
        {props.options.map((f, index) => {
          return (
            <IonSelectOption key={index} value={f.value}>
              {f.title}
            </IonSelectOption>
          );
        })}
        );
      </IonSelect>
      {showlable == true && (

        <IonChip onClick={() => {
          setSelectedValue(props.options[props.defaulvalueIndex]);
        }}>
          <IonIcon icon={close}></IonIcon>
          <IonLabel>
          {selectedValue.title}
          </IonLabel>
        </IonChip>
      )}
    </div>
  );
};
export default DropDownFilter;
