import React, { useContext, useEffect, useState } from "react";
import {
    IonCol,
    IonGrid,
    IonRow,

} from "@ionic/react";

import CustomPublishButton from "../utils/CustomElements/CustomPublishButton";
import VasContext, { VasProductDetailed } from "../../data/Vas-context";


const InnerVasInfo: React.FC<{
    onPurchase: (prod_code: number) => void;
    prod_code: number;
}> = props => {
    const vasCxt = useContext(VasContext);

    const [vasProductDetailed, setVasProductDetailed,] = useState<VasProductDetailed | null>(null);

    useEffect(() => {
        if (vasProductDetailed == null) {
            vasCxt.initVasProductInfoContext(props.prod_code).then((result: any) => {
                setVasProductDetailed(result);
            });
        }
    }, []);


    const Purchase = () => {
        props.onPurchase(props.prod_code);
    };

    return (
        <IonGrid>
            <IonRow>
                <IonCol>
                    {vasProductDetailed && vasProductDetailed.produuctInfo && vasProductDetailed.produuctInfo.map((p,i) => {
                      return  <p key={i}  dangerouslySetInnerHTML={{ __html: p.text }}>
                            
                        </p>
                    })}
                   
                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-end">
                <IonCol size="12" sizeMd="6" push-md="3">
                    <CustomPublishButton onClick={Purchase} title="לרכישה"></CustomPublishButton>
                </IonCol>
            </IonRow>
        </IonGrid>

    );
};

export default InnerVasInfo;