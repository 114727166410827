import React, { useContext, useEffect, useState } from 'react';
import {
  IonCard,
  IonCol,
  IonGrid,
  IonRow,
  IonAlert,
  IonItem,
  IonLabel,
  IonImg,
  IonButton
} from '@ionic/react';
import SelfSaleContext, { RequestResult } from '../../data/SelfSaleContext';
import { HeaderParameters } from '../../data/Header-Context';
import { useParams } from 'react-router';
import CustomPage from '../../components/utils/CustomElements/CustomPage';
// import SelfSaleCategories from '../../components/SelfSale/SelfSaleCategories/SelfSaleCategories';
import SelfSaleCategory from '../../components/SelfSale/SelfSaleCategories/SelfSaleCategory';
import SelfSaleAreas from '../../components/SelfSale/SelfSaleAreas/SelfSaleAreas';
import SelfSaleBudget from '../../components/SelfSale/SelfSaleBudget/SelfSaleBudget';
// import SelfSalePayment from '../../components/SelfSale/SelfSalePayment/SelfSalePayment';
import SelfSalePayment from '../../components/SelfSale/SelfSalePayment/SelfSalePayment';
import SelfSaleSummary from '../../components/SelfSale/SelfSaleSummary/SelfSaleSummary';
import SelfSaleLandingPage from '../../components/SelfSale/SelfSaleLandingPage/SelfSaleLandingPage';
import SelfSaleGoogleAds from '../../components/SelfSale/SelfSaleGoogleAds/SelfSaleGoogleAds';
import Keywords from '../../components/SelfSale/SelfSaleGoogleAds/Keywords';
// import SelfSaleProgressBar from '../../components/SelfSale/SelfSaleProgressBar';
import SelfSaleProgressBar, { progressBarButton, progressBarStep } from '../../components/SelfSale/SelfSaleProgressBar';
import UserContext from '../../data/AuthContextProvider';
import { ScrollProvider } from '../../data/ScrollContext';
import { isMobile } from 'react-device-detect';
import './SelfSale.scss';
import { supportCall, qotes, supportImage, logoB144Party, phoneWhite, b144VideoBcg, googleVideoBcg, youtube, logoBipbip, logoChannel9, logoHomeless, logoOne, logoRotter, logoTopper, logoWalla, logoYad2, logoGoogleAds, logoGooglePartner } from '../../images/index';
import { close, alert, addCircleOutline, chevronBackOutline } from 'ionicons/icons';
import NativeApp from '../../utils-ts/NativeApp';
import SelfSaleWellcome from '../../components/SelfSale/SelfSaleWellcome/SelfSaleWellcome'

const headerParameters: HeaderParameters = {
  isShowLogo: true,
  backButton: { isShowMobile: true },
  isShowNotification: true,
};

const SelfSale: React.FC = () => {
  const saleType = useParams<{ saleType: string }>().saleType;

  const SelfSaleCtx = useContext(SelfSaleContext);
  const { authValues } = useContext(UserContext);
  const [showCategoryInBudgetCard, setShowCategoryInBudgetCard] = useState<boolean>(false);
  const [salePhases, setSalePhases] = useState<progressBarStep[]>([]);
  // const [saleSteps, setSaleSteps] = useState<progressBarStep[]>([]);
  const [progressBarNextBtn, setProgressBarNextBtn] = useState<progressBarButton>({
    text: "הבא", showIcon: true, class: "", hidden: false, disabled: false
  });
  const [progressBarBackBtn, setProgressBarBackBtn] = useState<progressBarButton>({
    text: "הקודם", showIcon: true, class: "", hidden: false, disabled: false
  });

  const [currentPhaseIndex, setCurrentPhaseIndex] = useState<number>(0);
  const [nextPhaseIndex, setNextPhaseIndex] = useState<number>(0);
  const [finalStepCompleted, setFinalStepCompleted] = useState<boolean>(false);
  const [selfSaleInitCompleted, setSelfSaleInitCompleted] = useState<boolean>(false);
  const [showPhaseError, setShowPhaseError] = useState<boolean>(false);
  const [showWellcomeScreen, setShowWellcomeScreen] = useState<boolean>(false);
  const [phaseErrorMsg, setPhaseErrorMsg] = useState<string>('');
  const { OpenDialer } = NativeApp();

  useEffect(() => {
    if (authValues?.IsSelfRegister == false) {
      window.location.href = '/Home';
    }
    if (authValues?.Id)
      SelfSaleCtx.initSelfSale(authValues?.Id, saleType).then((res: any) => {
        if (res) {
          setSelfSaleInitCompleted(true);
          setFinalStepCompleted(res.selfSaleCompleted);
          let index = res.salePhases.findIndex((el: any) => !el.is_completed);
          setCurrentPhaseIndex(index);
          setSalePhases(res.salePhases);
          let categoryPhaseHidden = res.salePhases.find((el: any) => el.id === 3) === undefined;
          setShowCategoryInBudgetCard(categoryPhaseHidden)
          SelfSaleCtx.setShowPayment(false);
          console.log("SelfSaleCtx.initSelfSale res: ", res)
          setShowWellcomeScreen(res.saleLineId ? false : true)
        }
      });
      return () => {
        // Reset all state
        setShowCategoryInBudgetCard(false);
        setSalePhases([]);
        setProgressBarNextBtn({
          text: "הבא", 
          showIcon: true, 
          class: "", 
          hidden: false, 
          disabled: false
        });
        setProgressBarBackBtn({
          text: "הקודם", 
          showIcon: true, 
          class: "", 
          hidden: false, 
          disabled: false
        });
        setCurrentPhaseIndex(0);
        setNextPhaseIndex(0);
        setFinalStepCompleted(false);
        setSelfSaleInitCompleted(false);
        setShowPhaseError(false);
        setShowWellcomeScreen(false);
        setPhaseErrorMsg('');
        
        // // Reset context state if needed
        // SelfSaleCtx.setShowPayment(false);
        // SelfSaleCtx.setShowSummary(false);
        // SelfSaleCtx.setFinalStep(false);
      };
  }, []);
  useEffect(() => {
    console.log("showWellcomeScreen: ", showWellcomeScreen)
  }, [showWellcomeScreen]);
  useEffect(() => {
    if (currentPhaseIndex !== salePhases.length - 1) {
      SelfSaleCtx.setShowSummary(false);
    }
  }, [currentPhaseIndex]);

  useEffect(() => {
    let backBtnText = SelfSaleCtx.showPayment ? "חזרה" : "הקודם";
    setProgressBarBackBtn(prevState => ({ ...prevState, text: backBtnText }))

    let nextBtnText = SelfSaleCtx.showPayment ? "סיום" : "הבא";
    // let nextBtnText = SelfSaleCtx.showSummary && !SelfSaleCtx.showPayment ? "לתשלום" : SelfSaleCtx.showPayment ? "סיום" : "הבא";
    setProgressBarNextBtn(prevState => (
      {
        ...prevState,
        text: nextBtnText,
        disabled: !SelfSaleCtx.finalStep && SelfSaleCtx.showSummary,
        showIcon: !SelfSaleCtx.showPayment,
        class: SelfSaleCtx.showPayment ? "pb-control pb-control-last" : ""
      }))

  }, [SelfSaleCtx.showPayment, SelfSaleCtx.finalStep, SelfSaleCtx.showSummary]);
const initSelfSale = () => {
  if (authValues?.Id)
  SelfSaleCtx.initSelfSale(authValues?.Id, saleType).then((res: any) => {
    if (res) {
      setSelfSaleInitCompleted(true);
      setFinalStepCompleted(res.selfSaleCompleted);
      let index = res.salePhases.findIndex((el: any) => !el.is_completed);
      setCurrentPhaseIndex(index);
      setSalePhases(res.salePhases);
      let categoryPhaseHidden = res.salePhases.find((el: any) => el.id === 3) === undefined;
      setShowCategoryInBudgetCard(categoryPhaseHidden)
      SelfSaleCtx.setShowPayment(false);
      setShowWellcomeScreen(res.saleLineId ? false : true)
    }
  });
}
  const startSelfSaleProccess = () => {
    if (SelfSaleCtx.saleLineId) {
      setShowWellcomeScreen(false)
    }
    else {
      SelfSaleCtx.startSelfSaleProccess(saleType).then((data: RequestResult) => {
        if (!data.Success) {
          setPhaseErrorMsg(data.ResultDesc);
          setShowPhaseError(true);
        } else {
          setPhaseErrorMsg('');
          setShowPhaseError(false);
          initSelfSale()
        }
      });
    }
  };
  const handlePhaseSkip = (phase: string) => {
    SelfSaleCtx.setPhaseCompleted(saleType, phase, '0', '1').then((res: any) => {
      if (res > 0) {
        setSalePhases((prevState) =>
          prevState.filter((item) => {
            return item.id.toString() !== phase;
          })
        );
        setShowCategoryInBudgetCard(true)
        setCurrentPhaseIndex(nextPhaseIndex);
      }
    });
  };
  const handleSetPhaseComplete = (phaseId: number, result: RequestResult | null) => {
    setPhaseErrorMsg('');
    setShowPhaseError(false);
    if (result === null || (result && !result.Success)) {
      setSalePhases((prevState) =>
        prevState.map((item) => {
          if (item.id === phaseId) {
            item.is_completed = false;
          }
          return item;
        })
      );
      if (result !== null) {
        setPhaseErrorMsg(result.ResultDesc);
        setShowPhaseError(true);
      }
    } else {
      SelfSaleCtx.setPhaseCompleted(saleType, salePhases[currentPhaseIndex].id.toString(), '1', '0').then((res: any) => {
        if (res > 0) {
          setSalePhases((prevState) =>
            prevState.map((item) => {
              if (item.id === phaseId) {
                item.is_completed = true;
              }
              return item;
            })
          );
          setCurrentPhaseIndex(nextPhaseIndex);
        }
      });
      if (SelfSaleCtx.finalStep) {
        SelfSaleCtx.setPhaseCompleted(saleType, '1', '1', '0').then((res: any) => {
          if (res > 0) {
            setFinalStepCompleted(true);
          }
        });
      }
    }
  };
  const handleSetPhase = (currentIndex: number, nextIndex: number, isCompleted: boolean) => {
    setShowWellcomeScreen(currentIndex === -1)
    // if (currentIndex === -1) {
    //   return;
    // }
    setNextPhaseIndex(nextIndex);
    if (isCompleted) {
      if (SelfSaleCtx.showSummary && SelfSaleCtx.finalStep && !SelfSaleCtx.showPayment) {
        SelfSaleCtx.setShowPayment(true)
        return;
      }
      setSalePhases((prevState) =>
        prevState.map((item) => {
          if (salePhases[currentIndex] && item.id === salePhases[currentIndex].id) {
            item.is_completed = true;
          }
          return item;
        })
      );
    } else {
      SelfSaleCtx.setFinalStep(false);
      if (SelfSaleCtx.showPayment) {
        SelfSaleCtx.setShowPayment(false)
        return;
      }
      if (currentIndex !== -1) {
        setSalePhases((prevState) =>
          prevState.map((item) => {
            if (item.id === salePhases[currentIndex].id || item.id === salePhases[nextIndex].id) {
              item.is_completed = false;
            }
            return item;
          })
        );
      }
      SelfSaleCtx.setPhaseCompleted(saleType, salePhases[nextIndex].id.toString(), '0', '0').then((res: any) => {
        if (res > 0) {
          setCurrentPhaseIndex(nextIndex);
        }
      });
    }
  };
  return (
    <ScrollProvider>
      <CustomPage
        headerParameters={headerParameters}
        pageName={'SelfSale'}
        title={saleType == '1' ? 'קידום העסק שלך במדריך B144' : 'פרסום העסק שלך בגוגל'}>
        <IonGrid fixed={true}>
          <IonRow>
            <IonCol size="12">
              <>
                {!showWellcomeScreen && salePhases[currentPhaseIndex]?.id === 2 && (
                  <SelfSaleAreas onComplete={handleSetPhaseComplete} phaseId="2" saleType={saleType} completed={salePhases[currentPhaseIndex]?.is_completed} />
                )}
                {SelfSaleCtx.saleLineId && salePhases[currentPhaseIndex]?.id === 3 && (
                  <SelfSaleCategory onSkip={handlePhaseSkip} onComplete={handleSetPhaseComplete} saleType={saleType} completed={salePhases[currentPhaseIndex]?.is_completed} />
                )}
                {SelfSaleCtx.saleLineId && salePhases[currentPhaseIndex]?.id === 4 && (
                  <SelfSaleBudget onComplete={handleSetPhaseComplete} saleType={saleType} completed={salePhases[currentPhaseIndex]?.is_completed} showCategoryCard={showCategoryInBudgetCard} />
                )}
                {SelfSaleCtx.saleLineId && salePhases[currentPhaseIndex]?.id === 5 && (
                  <Keywords onComplete={handleSetPhaseComplete} completed={salePhases[currentPhaseIndex]?.is_completed} adGroup="" />
                )}
                {SelfSaleCtx.saleLineId && salePhases[currentPhaseIndex]?.id === 6 && (
                  <SelfSaleGoogleAds onComplete={handleSetPhaseComplete} saleType={saleType} completed={salePhases[currentPhaseIndex]?.is_completed} />
                )}
                {SelfSaleCtx.saleLineId && salePhases[currentPhaseIndex]?.id === 7 && !SelfSaleCtx.showPayment && (
                  <SelfSaleSummary saleType={saleType} />
                )}
                {SelfSaleCtx.saleLineId && salePhases[currentPhaseIndex]?.id === 7 && SelfSaleCtx.showPayment && (
                  <SelfSalePayment onComplete={handleSetPhaseComplete} saleType={saleType} completed={salePhases[currentPhaseIndex]?.is_completed} />
                )}

                {finalStepCompleted && saleType == '1' && (
                  <>
                    <IonCard className={isMobile ? "self-sale-card self-sale-card-mobile" : "self-sale-card"}>
                      <div className="ion-text-center">
                        <h2 className="ion-no-margin self-sale-section-title">תודה שבחרת לפרסם אצלנו!</h2>
                        {SelfSaleCtx.chizukSale && (<h2 className="ion-no-margin self-sale-title">נציג של B144 ייצור איתך קשר במהלך היום כדי לקחת פרטי תשלום</h2>)}
                        {!SelfSaleCtx.chizukSale && (<h2 className="ion-no-margin self-sale-section-title">הקמפיין שלך יעלה לאוויר תוך יום עסקים אחד</h2>)}
                        <img src={logoB144Party} alt='logo' />
                      </div>

                      {!isMobile && (
                        <div className="ion-text-center">
                          <IonButton className="main-button" routerLink={`/Home`}>
                            מעבר לדף הבית
                          </IonButton></div>
                      )}
                    </IonCard>
                    {isMobile && (
                      <div className="ion-text-center">
                        <IonButton className="main-button" routerLink={`/Home`}>
                          מעבר לדף הבית
                        </IonButton>
                      </div>
                    )}
                  </>
                )}
                {finalStepCompleted && saleType == '2' && (
                  <>
                    {SelfSaleCtx.lpSaleStep !== 2 && <IonCard className={isMobile ? "self-sale-card self-sale-card-mobile" : "self-sale-card"}>

                      {SelfSaleCtx.lpSaleRequestId !== "" && (
                        <div className="ion-text-center">
                          <h2 className="ion-no-margin self-sale-text-blue bold-text" style={{ fontSize: '20px', marginBottom: '15px' }}>תודה שבחרת לפרסם דרכנו!</h2>
                          <p className="ion-no-margin self-sale-text-blue self-sale-f20 bold-text">הקמפיין שלך ל{SelfSaleCtx.businessName} </p>
                          <p className="ion-no-margin self-sale-text-blue self-sale-f20 bold-text" style={{ marginBottom: '15px' }}>יעלה לאוויר עד 5 ימי עסקים</p>
                          <p className="ion-no-margin self-sale-text-gray" style={{ fontSize: '18px', marginBottom: '15px' }}>גם צוות העיצוב שלנו מתחיל לעבוד על דף הנחיתה שלך,<br /> הוא יהיה מוכן לאישורך עד 5 ימי עבודה </p>
                          <p className="ion-no-margin self-sale-text-gray self-sale-f16" style={{ color: '#383B41' }}>לינק לקמפיין ומכתב סיכום עסקה יישלחו אליך למייל</p>
                        </div>
                      )}
                      {SelfSaleCtx.lpSaleRequestId === "" && (
                        <>
                          <div className="ion-text-center">
                            <h2 className="ion-no-margin bold-text" >השלב הראשון הסתיים בהצלחה! </h2>
                          </div>
                        </>
                      )}
                      <div className="ion-text-center">
                        <img src={logoB144Party} alt='logo' />
                      </div>

                      {SelfSaleCtx.lpSaleRequestId !== "" && (
                        <div className="ion-text-center">
                          <p className="ion-no-margin self-sale-section-text">לשליחת תכנים נוספים בווטסאפ עבור דף הנחיתה: 037279982</p>
                        </div>
                      )}
                      {SelfSaleCtx.lpSaleRequestId === "" && (
                        <>
                          <div className="ion-text-center">

                            <p className='self-sale-blue-title-lg'><b>רגע... עוד לא סיימנו :)                          </b></p>
                            <p className="ion-no-margin bold-text xl-p">כדי להתחיל את הקמפיין ברגל ימין,</p>
                            <p className="ion-no-margin bold-text xl-p">אנחנו מכינים דף נחיתה שיעוצב ויותאם לעסק שלך </p>
                          </div>
                        </>
                      )}
                    </IonCard>}
                    {SelfSaleCtx.lpSaleRequestId === "" && <SelfSaleLandingPage />}
                    {SelfSaleCtx.lpSaleRequestId !== "" && (
                      <div className='self-sale-section-right'>
                        <div className="ion-text-center">
                          <IonButton className="main-button back-button" routerLink={`/Home`}>
                            חזרה לדף הבית
                          </IonButton>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
              {showWellcomeScreen && (
                <SelfSaleWellcome saleType={saleType} handleStartClick={startSelfSaleProccess} startBtnDisabled={!selfSaleInitCompleted} />
              )}
              {!showWellcomeScreen && !finalStepCompleted && (
                <IonRow>
                  <IonCol size="12">
                    <SelfSaleProgressBar salePhases={salePhases} currentIndex={currentPhaseIndex} setCurrentPhase={handleSetPhase} class='self-sale-section-right' backBtn={progressBarBackBtn} nextBtn={progressBarNextBtn} hidePhasesIndicator={SelfSaleCtx.showPayment} />
                  </IonCol>
                </IonRow>
              )}
              {!isMobile && (
                // <IonCol size="0" sizeMd="3">
                <IonItem
                  target="_blank"
                  href="https://support.b144.co.il/Content.aspx?pid=176&id=183"
                  lines="none"
                  className="section-item-fixed"
                  onClick={() => {
                    SetDtlPushEvtOutBound('outbound_links', 'https://support.b144.co.il/Content.aspx?pid=176&id=183');
                  }}>
                  <IonLabel>
                    <h2 className="bold-text"><span>לרשותך בכל שאלה</span> <img src={supportCall} /></h2>
                    <IonImg src={supportImage}></IonImg>
                    <IonButton
                      onClick={(e) => {
                        e.preventDefault();
                        OpenDialer('tel:1700707144');
                      }}
                      expand="block"
                      href="tel:1700707144"
                      target="_blank">
                      <img src={phoneWhite} />
                      לשיחה עם נציג
                    </IonButton>
                    <div className='divider-h'></div>
                    <IonButton expand="block" href="http://support.b144.co.il/" target="_blank">
                      לשאלות ותשובות
                    </IonButton>
                  </IonLabel>
                </IonItem>
                // </IonCol>
              )}
            </IonCol>
            <IonAlert isOpen={showPhaseError} onDidDismiss={() => setShowPhaseError(false)} header="שגיאה" message={phaseErrorMsg} buttons={['OK']} />
          </IonRow>
        </IonGrid>
      </CustomPage>
    </ScrollProvider>
  );
};
export default SelfSale;
