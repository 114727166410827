import {
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { HeaderParameters } from "../../../data/Header-Context";
import MemberDetailsContext, {
  OpenDays,
} from "../../../data/MemberDetailes-context";
import PlusCircleIcon from "../../icons/PlusCircleIcon";
import CustomForm from "../../utils/CustomElements/CustomForm";
import CustomModal from "../../utils/CustomElements/CustomModal/CustomModal";
import OpenHoursPicker, {
  SelectedDays,
} from "../../utils/CustomElements/CustomOpenHoursPicker/CustomOpenHoursPicker";
import CustomPublishButton from "../../utils/CustomElements/CustomPublishButton";
import "../businessCardTabs.scss";

// type BusinessActivity = "custom" | "24/5" | "24/7" | "reservation";
type BusinessActivity = 1 | 2 | 3 | 4;

const templateIDs = [
  {
    text: "הגדרה לפי ימים ושעות",
    note: "יש להגדיר ימים וטווח שעות בהם העסק פתוח",
  },
  {
    text: "פתוח 24/5",
    note: "העסק פתוח בימים א-ה, 24 שעות ביממה",
  },
  { text: "פתוח 24/7" },
  { text: "בתיאום טלפוני" },
];

const HandleOpenHours: React.FC<{
  show: boolean;
  closeModal: () => void;
  onSave: (OpenDays: OpenDays[]) => void;
  openDaysArray: OpenDays[];
  editedopenHourIndex: number | null;
}> = (props) => {
  const [templateID, setTemplatId] = useState<BusinessActivity>();
  const [businessOpenHours, setBusinessOpenHours] = useState<SelectedDays[]>(
    []
  );
  const { formState, errors, control, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      businessOpenHours,
    },
  });
  const { dirty } = formState;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "openHours",
  });
  let MemberDetailsCxt = useContext(MemberDetailsContext);
  const defaultTemplate = 1;
  const placeholder = useMemo(() => {
    switch (templateID) {
      case 1:
        return templateIDs[0];
      case 2:
        return templateIDs[1];
      case 3:
        return templateIDs[2];
      case 4:
        return templateIDs[3];
      default:
        return;
    }
  }, [templateID]);

  const unFormatActivityTime = (activityTime: OpenDays[]) => {
    const formattedActivity: SelectedDays[] = [];

    activityTime.forEach(
      ({
        Day1,
        Day2,
        Day3,
        Day4,
        Day5,
        Day6,
        Day7,
        FromHour = 0,
        FromHourMinutes = 0,
        ToHour = 0,
        ToHourMinutes = 0,
      }) => {
        const openingTime = `${
          Number(FromHour) < 10 ? `0${FromHour}` : FromHour
        }:${
          Number(FromHourMinutes) < 10
            ? `0${FromHourMinutes}`
            : FromHourMinutes || "00"
        }`;
        const closingTime = `${
          Number(ToHour) < 10 ? `0${ToHour}` : ToHour || "00"
        }:${
          Number(ToHourMinutes) < 10
            ? `0${ToHourMinutes}`
            : ToHourMinutes || "00"
        }`;
        formattedActivity.push({
          sunday: Day1,
          monday: Day2,
          tuesday: Day3,
          wednesday: Day4,
          thursday: Day5,
          friday: Day6,
          saturday: Day7,
          openingTime,
          closingTime,
        });
      }
    );

    return formattedActivity;
  };

  useEffect(() => {
    if (!MemberDetailsCxt.OpenDays) {
      MemberDetailsCxt.initMemberDetailsContext();
    }

    let unFormatedActivityTime = unFormatActivityTime(props.openDaysArray);

    if (typeof props.editedopenHourIndex === "number") {
      const editOpenHour = props.openDaysArray[props.editedopenHourIndex];

      if (editOpenHour.IsNeedToPhone) return setTemplatId(4);

      if (
        editOpenHour.FromHour === editOpenHour.ToHour &&
        editOpenHour.FromHourMinutes === editOpenHour.ToHourMinutes
      ) {
        // 24/7
        if (editOpenHour.Day7) return setTemplatId(3);

        // 24/5
        return setTemplatId(2);
      }

      unFormatedActivityTime = unFormatActivityTime([editOpenHour]);

      setTemplatId(1);
      append({});
    }

    setBusinessOpenHours(unFormatedActivityTime);
  }, []);

  const saveHandler = () => {
    if (!templateID)
      return toast.error("יש לבחור סוג ימים ושעות פעילות מהרשימה");

    const activityTime = formatActivityTime(businessOpenHours, templateID);

    if (
      JSON.stringify(businessOpenHours) === JSON.stringify(props.openDaysArray)
    )
      return toast.error("יש להזין ימים ושעות פעילות");

    const businessActivityTime = [...props.openDaysArray];
    if (typeof props.editedopenHourIndex === "number")
      businessActivityTime[props.editedopenHourIndex] = activityTime[0];

    if (!businessActivityTime.length)
      return toast.error("יש לבחור טווח ימים ושעות פעילות");

    const currentRemarks = MemberDetailsCxt.DaysRemarks;

    MemberDetailsCxt.saveMainDetails(
      "OpenDays",
      MemberDetailsCxt.AdvCategories,
      MemberDetailsCxt.BusinessLanguages,
      //activityTime!,
      //currentRemarks,
      MemberDetailsCxt.GeneralInfo,
      MemberDetailsCxt.Description
    );

    if (activityTime.length === 0) {
      setTemplatId(undefined);
    } else {
      props.onSave(activityTime);
    }
  };

  const formatActivityTime = (
    time: SelectedDays[],
    activity: Exclude<BusinessActivity, null>
  ) => {
    if ([2, 3].includes(activity))
      return [
        {
          Day1: true,
          Day2: true,
          Day3: true,
          Day4: true,
          Day5: true,
          Day6: activity === 3,
          Day7: activity === 3,
          FromHour: 7,
          FromHourMinutes: 0,
          IsNeedToPhone: false,
          IsNextDay: false,
          IsSaturdayInOut: false,
          MemberID: "",
          Remarks: "",
          ToHour: 7,
          ToHourMinutes: 0,
          Id: "",
          Is24x7: true,
          IsDeleted: false,
        },
      ];

    if (activity === 4)
      return [
        {
          Day1: true,
          Day2: true,
          Day3: true,
          Day4: true,
          Day5: true,
          Day6: true,
          Day7: true,
          FromHour: 0,
          FromHourMinutes: 0,
          IsNeedToPhone: true,
          IsNextDay: false,
          IsSaturdayInOut: false,
          MemberID: "",
          Remarks: "",
          ToHour: 0,
          ToHourMinutes: 0,
          Id: "",
          Is24x7: false,
          IsDeleted: false,
        },
      ];

    const formattedActivity: OpenDays[] = [];

    time.forEach(
      ({
        sunday,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        openingTime,
        closingTime,
      }) => {
        const [FromHour, FromHourMinutes] = openingTime.split(":");
        const [ToHour, ToHourMinutes] = closingTime.split(":");
        formattedActivity.push({
          Day1: sunday,
          Day2: monday,
          Day3: tuesday,
          Day4: wednesday,
          Day5: thursday,
          Day6: friday,
          Day7: saturday,
          FromHour: Number(FromHour),
          FromHourMinutes: Number(FromHourMinutes),
          ToHour: Number(ToHour),
          ToHourMinutes: Number(ToHourMinutes),
          IsNeedToPhone: false,
          IsNextDay: false,
          IsSaturdayInOut: false,
          Id: "",
          Is24x7: false,
          IsDeleted: false,
        });
      }
    );

    return formattedActivity;
  };

  const onSaveOpenHours = (
    dayActivity: SelectedDays,
    index: number,
    type: "save" | "delete"
  ) => {
    const activityTime =
      type === "delete"
        ? [...businessOpenHours.filter((_, i) => i !== index)]
        : !!businessOpenHours[index]
        ? // update / replace
          [
            ...businessOpenHours.map((activity, i) =>
              i === index ? dayActivity : activity
            ),
          ]
        : // add
          [...businessOpenHours, dayActivity];

    setBusinessOpenHours(activityTime);
    setValue("businessOpenHours", activityTime);
  };

  const interfaceOptions = {
    showBackdrop: false,
  };

  const headerParameters: HeaderParameters = {
    isPageHaveChanges: dirty,
    modalType: "modal",
    isShowDeleteButton: props.editedopenHourIndex != null,
    deleteFunc: () => {
      saveHandler();
    },
  };

  return (
    <CustomModal
      pageName={"HandleOpenHours"}
      headerParameters={headerParameters}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
      title="שעות פתיחה"
      subTitle="בחר מתבנית קיימת או ערוך בעצמך את שעות הפעילות"
    >
      <CustomForm onSubmit={saveHandler} errors={errors}>
        <IonGrid>
          <IonRow>
            <IonCol size="12" sizeMd="6">
              <IonItem color="light" lines="full" mode="md">
                <IonLabel position="stacked">שעות הפתיחה</IonLabel>
                <IonSelect
                  color="light"
                  mode="md"
                  value={templateID}
                  interface="popover"
                  interfaceOptions={interfaceOptions}
                  placeholder="בחר מרשימה"
                  onIonChange={(e) => {
                    e.stopPropagation();
                    setTemplatId(e.detail.value);
                  }}
                >
                  {templateIDs.map(({ text }, i) => {
                    return (
                      <IonSelectOption key={i} value={i + 1}>
                        {text}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
              </IonItem>
              {placeholder?.note && (
                <small style={{ paddingTop: 8 }}>{placeholder?.note}</small>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 12,
                  maxHeight: templateID === defaultTemplate ? 9990 : 0,
                }}
              >
                {typeof props.editedopenHourIndex !== "number" &&
                  fields.length < 2 && (
                    <PlusCircleIcon
                      style={{ width: 24, height: 24, cursor: "pointer" }}
                      onClick={() => append({})}
                    />
                  )}

                {fields.map((_, i) => {
                  const index =
                    typeof props.editedopenHourIndex === "number"
                      ? i
                      : businessOpenHours.length + i;
                  return (
                    <Controller
                      key={i}
                      as={
                        <OpenHoursPicker
                          key={i}
                          index={index}
                          openHours={businessOpenHours}
                          editOpenHour={
                            typeof props.editedopenHourIndex === "number"
                              ? businessOpenHours[0]
                              : undefined
                          }
                          onSave={(dayActivity) =>
                            onSaveOpenHours(dayActivity, index, "save")
                          }
                          onDelete={(dayActivity) => {
                            onSaveOpenHours(dayActivity, index, "delete");
                            remove(i);
                          }}
                        />
                      }
                      control={control}
                      name={`openHours[${i}].name`}
                    />
                  );
                })}
              </div>
            </IonCol>
          </IonRow>
          {!isMobile && (
            <IonRow className="ion-justify-content-end">
              <IonCol
                size="12"
                sizeMd="5.5"
                className="publish-btn-wrapper ion-no-padding"
              >
                <CustomPublishButton></CustomPublishButton>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
        {isMobile && (
          <CustomPublishButton
            fixed={templateID === defaultTemplate ? false : true}
          ></CustomPublishButton>
        )}
      </CustomForm>
    </CustomModal>
  );
};

export default HandleOpenHours;
