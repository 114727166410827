import { config } from "../Constants";
import { Photo } from "../components/utils/ImagePicker/ImagePicker";
import { fetchRequest } from "../utils-ts/fetchRequest";
const parseString = require("xml2js").parseString;
const formatter = new Intl.DateTimeFormat("en-GB", {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
});

interface GoogleAdwords {
  AwAdGroupId: string;
  AwAdGroupName: string;
  AwDescription: string;
  AwDisplayURL: string;
  AwHeaderLine1: string;
  AwHeaderLine2: string;
  AwPath1: string;
  AwPath2: string;
  AwStatisticsClicks: string;
  AwStatisticsCtr: string;
  AwStatisticsImpressions: string;
  AwStatisticsPaidAmount: string;
  AwTextAdId: string;
  AwURL: string;
  Conversions: string;
}

interface GoogleKeywords {
  AdGroupId: number;
  AdGroupName: string;
  AdGroupStatus: string;
  AverageCpc: number;
  AverageCpm: number;
  Clicks: number;
  Conversions: string;
  Cost: number;
  Ctr: number;
  FirstPageCpc: string;
  Impressions: number;
  KeywordId: string;
  KeywordStatus: string;
  KeywordText: string;
  QualityScore: number;
  TopOfPageCpc: string;
}

interface GoogleCampagin {
  AverageCpc: number;
  AverageCpm: number;
  AwAdCampaignName: string;
  AwCampaignId: string;
  AwCampaignServingStatus: string;
  AwCampaignStatus: string;
  Budget: number;
  CampaignType: string;
  Clicks: number;
  Conversions: number;
  Cost: number;
  Ctr: string;
  Impressions: number;
  Labels: string;
}

interface Pnia {
  InsertDate: any;
  PniaId: number;
  TextPnia: string;
}

const normalizeDataFunc = (items: any, key: string) => {
  let normalizedData: any[] = [];
  switch (key.toLowerCase()) {
    case "adwords":
      normalizedData = items.map((item: GoogleAdwords) => ({
        "קבוצת מודעות": item.AwAdGroupName,
        מודעה: `<div>
            <span onclick="window.open(${item.AwURL})" style="cursor:pointer;color:#16254f;font-weight:bold">${item.AwHeaderLine1} - ${item.AwHeaderLine2}</span>
            <span style="color:Green;direction:ltr;display:block;padding: 4px 0">${item.AwDisplayURL}/${item.AwPath1}/${item.AwPath2}</span>
            <span>${item.AwDescription}</span>
          </div>`,
        קליקים: item.AwStatisticsClicks,
        חשיפות: item.AwStatisticsImpressions,
        "אחוזי הקלקה": item.AwStatisticsCtr,
      }));
      break;
    case "keyadwords":
      normalizedData = items.map((item: GoogleKeywords) => ({
        "קבוצת מודעות": item.AdGroupName,
        "מילת חיפוש": item.KeywordText,
        קליקים: item.Clicks,
        עלות: item.Cost,
        "אחוזי הקלקה": item.Ctr,
        חשיפות: item.Impressions,
        "עלות ממוצעת להקלקה": item.AverageCpc,
      }));
      break;
    case "campaginadwords":
      normalizedData = items.map((item: GoogleCampagin) => ({
        קמפיין: item.AwAdCampaignName,
        "תקציב יומי": item.Budget,
        הקלקות: item.Clicks,
        מחיר: item.Cost,
        "אחוזי הקלקה": item.Ctr,
        התרשמות: item.Impressions,
        "ממוצע CPC": item.AverageCpc,
      }));
      break;
    case "commentsadwords":
      normalizedData = items.map((item: Pnia) => ({
        פנייה: `<div style="text-align:right;font-size:14px;padding: 4px 0">${item.TextPnia}</div>`,
        תאריך: `<div style="font-size:14px">${formatter.format(
          Number(
            item.InsertDate.replaceAll("/", "")
              .replaceAll(")", "")
              .replace("Date(", "")
          )
        )}</div>`,
      }));
      break;
    case "fbleads":
      normalizedData = items.map((item: any) => ({
        "תאריך ליד": `<div>${formatter.format(
          Number(
            item.Lead_create_time.replaceAll("/", "")
              .replaceAll(")", "")
              .replace("Date(", "")
          )
        )}</div>`,
        "פרטי ליד": `<div style="text-align:right;">
            ${item.Lead_name && `<div><b>שם: </b>${item.Lead_name}</div>`}
            ${item.Lead_phone && `<div><b>טלפון: </b>${item.Lead_phone}</div>`}
            ${
              item.Lead_address &&
              `<div><b>כתובת: </b>${item.Lead_address}</div>`
            }
            ${item.Lead_email && `<div><b>אימייל: </b>${item.Lead_email}</div>`}
            ${
              item.Lead_job_title &&
              `<div><b>עבודה: </b>${item.Lead_job_title}</div>`
            }
            ${
              item.Lead_marital_status &&
              `<div><b>מצב משפחתי: </b>${item.Lead_marital_status}</div>`
            }
            </div>`,
      }));
      break;
    default:
      normalizedData = items;
      break;
  }

  return normalizedData;
};

export const GetProductStats = (
  fromDate: Date,
  toDate: Date,
  productType: "google" | "facebook" = "google",
  tabName: string,
  fbCampaignType?: string,
  sortExpression?: string
) => {
  return new Promise<any | null>((resolve, reject) => {
    const data = new URLSearchParams({
      jsDetails: JSON.stringify({
        toDate,
        tabName,
        fromDate,
        productType,
        fbCampaignType,
        sortExpression,
      }),
    }).toString();
    fetchRequest(
      `${config.url.API_URL}/ClientStatistics.asmx/GetProductStats`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            const res = JSON.parse(result.string["_"]);
            if (res.Success) {
              let statsArray = JSON.parse(res.Response);
              let normalizedStats: any;
              Object.keys(statsArray).map((key: string) => {
                if (!statsArray[key]) return;
                normalizedStats = {
                  ...normalizedStats,
                  [key]: normalizeDataFunc(statsArray[key], key),
                };
              });
              resolve(normalizedStats);
            } else {
              resolve(null);
            }
          }
        });
      })
      .catch((error) => {
        console.log(error);
        resolve(null);
      });
  });
};

export const OpenNewAdwordsPnia = (pniaText: string, pniaType: string) => {
  return new Promise<boolean>((resolve, reject) => {
    const data = new URLSearchParams({
      jsDetails: JSON.stringify({
        pniaText,
        pniaType,
      }),
    }).toString();
    fetchRequest(
      `${config.url.API_URL}/ClientStatistics.asmx/OpenNewAdwordsPnia`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            const res = JSON.parse(result.string["_"]);
            if (res.Success) {
              resolve(JSON.parse(res.Response));
            } else {
              resolve(false);
            }
          }
        });
      })
      .catch((error) => {
        console.log(error);
        resolve(false);
      });
  });
};

export const SaveFacebookAd = (
  bannerTopText: string,
  bannerBottomText: string,
  adId: string,
  photo: Photo
) => {
  return new Promise<boolean>((resolve, reject) => {
    const { size, width, height, preview, name } = photo;
    const data = new URLSearchParams({
      jsDetails: JSON.stringify({
        bannerTopText,
        bannerBottomText,
        adId,
        fBImage: {
          size,
          width,
          height,
          base64: preview,
          name,
        },
      }),
    }).toString();
    fetchRequest(
      `${config.url.API_URL}/ClientStatistics.asmx/SaveFacebookAd`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            const res = JSON.parse(result.string["_"]);
            if (res.Success) {
              resolve(JSON.parse(res.Response));
            } else {
              resolve(false);
            }
          }
        });
      })
      .catch((error) => {
        console.log(error);
        resolve(false);
      });
  });
};

export interface PiMaxPreview {
  adBusinessName: string;
  adDescriptions: string[];
  adHeaders: string[];
  adImages: string[];
  adLogos: string[];
  adLongHeaders: string[];
  displayUrl: string;
}

export const GetPiMaxPreview = () => {
  return new Promise<PiMaxPreview | null>((resolve, reject) => {
    const data = new URLSearchParams({
      jsDetails: JSON.stringify({}),
    }).toString();
    fetchRequest(
      `${config.url.API_URL}/ClientStatistics.asmx/GetPiMaxPreview`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            const res = JSON.parse(result.string["_"]);
            if (res.Success) {
              let data = JSON.parse(res.Response);

              resolve(data);
            } else {
              resolve(null);
            }
          }
        });
      })
      .catch((error) => {
        console.log(error);
        resolve(null);
      });
  });
};

export const SendMail = (address: string, adLink: string) => {
  return new Promise<boolean>((resolve, reject) => {
    const data = new URLSearchParams({
      jsDetails: JSON.stringify({ address, adLink }),
    }).toString();
    fetchRequest(
      `${config.url.API_URL}/ClientStatistics.asmx/SendMail`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            const res = JSON.parse(result.string["_"]);
            if (res.Success) {
              let data = JSON.parse(res.Response);

              resolve(data);
            } else {
              resolve(false);
            }
          }
        });
      })
      .catch((error) => {
        console.log(error);
        resolve(false);
      });
  });
};

export const SendSms = (address: string, adLink: string) => {
  return new Promise<boolean>((resolve, reject) => {
    const data = new URLSearchParams({
      jsDetails: JSON.stringify({ address, adLink }),
    }).toString();
    fetchRequest(
      `${config.url.API_URL}/ClientStatistics.asmx/SendSms`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            const res = JSON.parse(result.string["_"]);
            if (res.Success) {
              let data = JSON.parse(res.Response);

              resolve(data);
            } else {
              resolve(false);
            }
          }
        });
      })
      .catch((error) => {
        console.log(error);
        resolve(false);
      });
  });
};
