import React from "react";
import { Pie } from "react-chartjs-2";

const PieChart: React.FC<{
  chartData: { labels: string[]; data: number[]; title?: string };
  chartWidth?: number;
  chartHeight?: number;
  chartAdditionalData?: any;
  isShowLegend?: boolean;
  isGradient?: boolean;
  backgroundColor?: string[];
}> = ({ backgroundColor = "rgba(255, 158, 71, 0.5)", ...props }) => {
  const chartColor = Array.isArray(backgroundColor)
    ? backgroundColor[0]
    : backgroundColor;
  const chartBackgroundColor = Array.isArray(backgroundColor)
    ? backgroundColor[1]
    : backgroundColor;
  const data = {
    labels: props.chartData.labels, //["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: props.chartData.title ?? "# of Votes",
        data: props.chartData.data, //[12, 19, 3, 5, 8, 3],
        borderWidth: 1,
        backgroundColor: (context: any) => {
          const ctx = context.chart.ctx;
          let gradient: any = chartColor;
          if (props.isGradient) {
            gradient = ctx.createLinearGradient(0, 150, 0, 0);
            const colorStart = "#ffb643";
            const colorEnd = "#ff8c4a";
            gradient.addColorStop(0, colorStart);
            gradient.addColorStop(1, colorEnd);
          }
          return [gradient, chartBackgroundColor];
        },
      },
    ],
  };

  const options = {
    responsive: true,
    legend: {
      display: props.chartData.title,
      // prevent click (the click hides the graph)
      onClick: (e: any, legendItem: any, legend: any) => {
        return;
      },
    },
    plugins: {
      labels: {
        render: "percentage",
        fontSize: 14,
        fontColor: "#16254f",
        fontStyle: "bold",
        fontFamily: "Open Sans Hebrew",
      },
    },
    tooltips: {
      enabled: false,
    },
    // onHover: (event: any, chartElement: any) => {
    //   event.target.style.cursor = chartElement[0] ? "pointer" : "default";
    // },
  };

  return (
    <div
      style={{
        width: props.chartWidth ? props.chartWidth : "inherit",
        fontSize: 200,
        textAlign: "center",
      }}
      className="inline-block"
    >
      <Pie
        width={140}
        height={props.chartHeight}
        options={options}
        data={data}
      ></Pie>
    </div>
  );
};

export default PieChart;
