import React from 'react'
import Highlighter from '../../components/Highlighter/Highlighter'
import background from './facebook-feed-page.webp'

export default function FacebookFeedPage() {
  return (
    <div style={{ position: 'relative' }}>
      <img width="100%" src={background} alt="Facebook Feed" />
      <Highlighter
        title="מודעה בפייסבוק"
        text="טקסט"
        style={{
          width: '100%',
          position: 'absolute',
          height: '34%',
          top: '37%',
        }}
      />
    </div>
  )
}
