import React, { useContext, useEffect, useState } from "react";
import SelfSaleContext, { AdGroup } from "../../../data/SelfSaleContext";
import { v4 as uuidv4 } from 'uuid';
// import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
// import { Pagination } from 'swiper';
// import 'swiper/swiper.min.css';
// import 'swiper/modules/pagination/pagination.min.css';
import AdsPreviewSlide from './AdsPreviewSlide'

export type AdGroupAds = {
  adGroupId: number;
  adHeaders: string[]
  adDescriptions: string[]
  adPaths: string[]
}
const AdsPreviewSlider: React.FC<{ adgroups: AdGroup[] }> = (props) => {
  const SelfSaleCtx = useContext(SelfSaleContext);

  const [adgroups, setAdgroups] = useState<AdGroupAds[]>([]);
  useEffect(() => {
    if (!adgroups.length) {
      props.adgroups.forEach(ag => {
        let adgroupWithAds: AdGroupAds = { adGroupId: ag.id,adHeaders:[], adDescriptions:[],adPaths:[]}
        SelfSaleCtx.getGoogleResponsiveAdTemplate(ag.cat_code, ag.id.toString())
          .then((res: any) => {
            if (res && res.length) {
              adgroupWithAds.adHeaders = res.filter((item: any) => item.type === "3").map((item: any) => item.text.replace('<עיר>',SelfSaleCtx.businessCity).replace('<שם העסק>', SelfSaleCtx.businessName).replace('<תת קטגוריה>', ag.name).replace('<קטגוריה>', SelfSaleCtx.businessCategory))
              adgroupWithAds.adDescriptions = res.filter((item: any) => item.type === "2").map((item: any) => item.text.replace('<עיר>',SelfSaleCtx.businessCity).replace('<שם העסק>', SelfSaleCtx.businessName).replace('<תת קטגוריה>', ag.name).replace('<קטגוריה>', SelfSaleCtx.businessCategory))
              adgroupWithAds.adPaths = res.filter((item: any) => item.type === "1").map((item: any) => item.text.replace('<עיר>',SelfSaleCtx.businessCity).replace('<שם העסק>', SelfSaleCtx.businessName).replace('<תת קטגוריה>', ag.name).replace('<קטגוריה>', SelfSaleCtx.businessCategory))
              setAdgroups(adgroups => ([...adgroups, { ...adgroupWithAds }]))
            }
          })
      })
    }
  }, [])
  
  return (
    <>
      {adgroups.length > 0 && (
        <>
            {adgroups.map((item,index) => 
              // <SwiperSlide key={index}>
                <AdsPreviewSlide key={uuidv4()} adGroupAds={item}/>
              // </SwiperSlide>
            )}
          </>
      )}
    </>
  );
};

export default AdsPreviewSlider;