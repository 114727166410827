import React from "react";
import background from "./business-page.jpg";
import Highlighter from "../../components/Highlighter/Highlighter";

export default function BusinessPage() {
  return (
    <div style={{ position: "relative" }}>
      <img src={background} alt="Business page" width="100%" />
      <Highlighter
        title="דירוג של העסק"
        text="כאן מוצג מידע על הדירוג של העסק
        כמה אנשים דירגו את העסק 
        שלך ומה הדירוג שקיבלת"
        style={{
          position: "absolute",
          transform: "translateX(-50%)",
          left: "50%",
          top: "4.45%",
          width: "50%",
          height: "0.45%",
        }}
      />
      <Highlighter
        title="שעות פתיחה"
        text="טקסט"
        style={{
          position: "absolute",
          transform: "translateX(-50%)",
          left: "50%",
          top: "23%",
          width: "91%",
          height: "4.3%",
        }}
      />
      <Highlighter
        title="דירוג של העסק"
        text="כאן מוצג מידע על הדירוג של העסק
        כמה אנשים דירגו את העסק 
        שלך ומה הדירוג שקיבלת"
        style={{
          position: "absolute",
          transform: "translateX(-50%)",
          left: "50%",
          top: "35.8%",
          width: "91%",
          height: "7.1%",
        }}
      />
      <Highlighter
        title="גלריה"
        text="טקסט"
        style={{
          position: "absolute",
          transform: "translateX(-50%)",
          left: "50%",
          top: "51.5%",
          width: "91%",
          height: "3.2%",
        }}
      />
      <Highlighter
        title="סרטון"
        text="טקסט"
        style={{
          position: "absolute",
          transform: "translateX(-50%)",
          left: "50%",
          top: "58.4%",
          width: "91%",
          height: "6%",
        }}
      />
      <Highlighter
        title="קופון"
        text="טקסט"
        style={{
          position: "absolute",
          transform: "translateX(-50%)",
          left: "50%",
          top: "66.2%",
          width: "80%",
          height: "6%",
        }}
      />
    </div>
  );
}
