import React, { useContext, useEffect, useState } from "react";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonIcon,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { pencil } from "../../../images/index";
import MemberDetailsContext, {
  AdvCategories,
} from "../../../data/MemberDetailes-context";
import MemberDetaielsModules from "./MemberDetaielsModules";
import { getAdvCategoryCategories } from "../../../utils-ts/BusinessCardFunc";
const BusinessAdvCategories: React.FC<{ icon: string }> = (props) => {
  const MemberDetailsCxt = useContext(MemberDetailsContext);
  const [isShowAdvCategory, setIsShowAdvCategory] = useState(false);
  const [mainCategory, setMainCategory] = useState("");
  const [mainCategories, setMainCategories] = useState<string[]>([]);
  const [advCategories, setAdvCategories] = useState<
    AdvCategories[] | undefined
  >();

  useEffect(() => {
    if (MemberDetailsCxt.AdvCategories == null) {
      MemberDetailsCxt.initMemberDetailsContext();
    }
  }, []);
  useEffect(() => {
    if (MemberDetailsCxt.AdvCategories) {
      let emptyCategories = getAdvCategoryCategories(MemberDetailsCxt);
      let mainCategoriesTemp: string[] = [];
      MemberDetailsCxt.AdvCategories!.filter((f) => {
        if (emptyCategories.includes(f.Category) === false) {
          return f;
        }
      }).map((cat) => {
        if (mainCategoriesTemp.indexOf(cat.Category) === -1) {
          mainCategoriesTemp.push(cat.Category);
        }
      });
      setAdvCategories([...MemberDetailsCxt.AdvCategories]);
      setMainCategories(mainCategoriesTemp);
    }
  }, [MemberDetailsCxt.AdvCategories]);

  return (
    <React.Fragment>
      {advCategories &&
        mainCategories &&
        mainCategories.map((cat, index) => {
          const filterArray = advCategories?.filter(
            (c) => c.Category === cat && c.IsChecked === true
          );
          let subCat = "";
          if (filterArray) {
            filterArray.map((c) => {
              const title =
                c.Title != ""
                  ? c.CategoryFldDesc + " - " + c.Title
                  : c.CategoryFldDesc;
              subCat = title + ", " + subCat;
            });
          }

          subCat = subCat.replace(/,\s*$/, "");
          return (
            <IonCard mode="md" key={index} className="member-detail">
              <IonItem lines="inset">
                <IonIcon
                  icon={props.icon}
                  slot="start"
                  className="ion-no-margin"
                />
                <IonLabel>
                  <h2 className="md-title">
                    <span className="bold-text">קטגוריה ראשית:</span> {cat}
                  </h2>
                </IonLabel>
                <IonButton
                  className="ion-no-margin label-button"
                  fill="clear"
                  slot="end"
                  onClick={() => {
                    setIsShowAdvCategory(true);
                    setMainCategory(cat);
                  }}
                >
                  <IonIcon icon={pencil} slot="end" />
                </IonButton>
              </IonItem>

              <IonCardContent>
                <p className="bold-text ion-margin-bottom">מאפיינים:</p>
                <p className="text-wrap">{subCat}</p>
              </IonCardContent>
            </IonCard>
          );
        })}

      {isShowAdvCategory && (
        <MemberDetaielsModules
          mainCategory={mainCategory}
          closeModal={() => {
            setIsShowAdvCategory(false);
            setMainCategory("");
          }}
          onSave={() => {}}
          showElement={"AdvCategories"}
        ></MemberDetaielsModules>
      )}
    </React.Fragment>
  );
};

export default BusinessAdvCategories;
