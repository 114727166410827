import { IonAvatar, IonCol, IonGrid, IonPage, IonRow } from "@ionic/react";
import { isMobile } from "react-device-detect";
import React, { useContext, useEffect } from "react";
import HeaderContext, { HeaderParameters } from "../../../data/Header-Context";
import TipFromBezeq from "../../TipFromBezeq/TipFromBezeq";
import CustomContent from "./CustomContent";
import CustomPageTitle from "./CustomPageTitle/CustomPageTitle";
import GlobalContext from "../../../data/Global-Context";
import { imageWhite } from "../../../images/index";
import "../../../theme/avatar.scss";
import CustomAddFloatingButton from "./CustomAddFloatingButton";

export interface floatingButton {
  icon: string;
  title: string;
  onClick: () => void;
}

const CustomPage: React.FC<{
  title: string;
  subTitle?: string;
  smallSubTitle?: string;
  pageName: string; //send the  parent function name
  headerParameters: HeaderParameters;
  logoSrc?: string;
  innerPage?: boolean;
  logoClickFunc?: () => void;
  floatingButton?: floatingButton;
}> = (props) => {
  const HeaderCtx = useContext(HeaderContext);
  const PageTipCtx = useContext(GlobalContext);
  useEffect(() => {
    if (props.headerParameters) {
      HeaderCtx.initHeader(props.headerParameters);
    }
  }, [props.headerParameters]);

  useEffect(() => {
    if (props.pageName && props.pageName != 'CoSale') {
      PageTipCtx.getPageTip(props.pageName, "page");
    }
  }, []);

  const titleWrapperWidthHandler = () => {
    let titleWidthSize = "10";
    if (props.pageName === "BusinessCard") {
      if (PageTipCtx.pageTip && props.logoSrc !== undefined) {
        titleWidthSize = "7";
      } else if (!PageTipCtx.pageTip && props.logoSrc !== undefined) {
        titleWidthSize = "9";
      }
    } else if (props.pageName === "StatisticsOpt" && !PageTipCtx.pageTip) {
      titleWidthSize = "12";
    } else if(props.headerParameters.isLogin){
      titleWidthSize = "12";
    }
    return titleWidthSize;
  };

  return (
    <IonPage>
      <CustomContent
        isRefreshPage={true}
        fullscreen={true}
        isLogin={props.headerParameters.isLogin}
      >
        {!props.headerParameters.isLogin && <div className="wrapper"></div>}
        {isMobile && props.floatingButton && (
          <CustomAddFloatingButton
            onclick={() => {
              props.floatingButton?.onClick();
            }}
            title={props.floatingButton?.title}
            icon={props.floatingButton?.icon}
          ></CustomAddFloatingButton>
        )}
        <IonGrid fixed={true}>
          <IonRow
            className={
              "ion-align-items-center " +
              (PageTipCtx.pageTip
                ? "ion-justify-content-start"
                : "ion-justify-content-between")
            }
          >
            {props.title && (
              <IonCol
                className={
                  "title-wrapper" +
                  (PageTipCtx.pageTip && props.logoSrc !== undefined
                    ? " with-tip"
                    : "") +
                  (props.innerPage ? " inner-page-title-wrapper" : "") 
                }
                size-md="12"
                size={titleWrapperWidthHandler()}
              >
                <CustomPageTitle
                  pageName={props.pageName}
                  titleClass="white-text xxl-p-mbl"
                  title={props.title}
                  subTitle={props.subTitle}
                  smallSubTitle={props.smallSubTitle}
                  subTitleClass="ion-no-margin"
                  backButtonDesktop={props.headerParameters.backButton}
                  floatingButton={!isMobile ? props.floatingButton : undefined}
                  pdfDownloadPdf={props.headerParameters.pdfDownloadPdf}
                  moreOptionsButtons={props.headerParameters.moreOptionsButtons}
                  isLogin={props.headerParameters.isLogin}
                />
                {PageTipCtx.pageTip &&
                  !isMobile &&
                  props.pageName &&
                  props.pageName != "" && (
                    <TipFromBezeq
                      tip={PageTipCtx.pageTip}
                      light={true}
                      pageName={props.pageName}
                    />
                  )}
              </IonCol>
            )}
            {isMobile && props.logoSrc !== undefined && (
              <IonCol size="3" className="customer-logo-wrapper-mobile">
                <IonAvatar
                  className={props.logoSrc == "" ? "no-image" : ""}
                  onClick={() => {
                    if (props.logoClickFunc) {
                      props.logoClickFunc();
                    }
                  }}
                >
                  <img
                    src={props.logoSrc !== "" ? props.logoSrc : imageWhite}
                    alt=""
                  />
                </IonAvatar>
                {props.logoSrc == "" && (
                  <p className="small-p white-text">הוספת לוגו</p>
                )}
              </IonCol>
            )}
            {isMobile &&
              PageTipCtx.pageTip &&
              props.pageName &&
              props.pageName != "" && (
                <IonCol size="2" className="ion-text-end">
                  <TipFromBezeq
                    tip={PageTipCtx.pageTip}
                    light={true}
                    pageName={props.pageName}
                  />
                </IonCol>
              )}
          </IonRow>
        </IonGrid>

        {props.children}
      </CustomContent>
    </IonPage>
  );
};

export default CustomPage;
