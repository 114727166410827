import { fetchRequest } from "../utils-ts/fetchRequest";
import { config } from "../Constants";
const parseString = require('xml2js').parseString;

export const renderImageEx = (html:string) => {
  return new Promise((resolve, reject) => {
    var data = new URLSearchParams({htmlToRender: encodeURIComponent(html)}).toString();
    
    fetchRequest(
      `${config.url.API_URL}/ClientLogo.asmx/renderImageEx`,
      "post",
      data
    )
      .then((response: any) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string['_']);
            if (res.Success) {
              const logo = res.Response != undefined ? res.Response : '';
              resolve(logo);
            } else {
              resolve('');
            }
          }
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  });
};
export const getRandomLogoStyle = () => {
  return new Promise((resolve, reject) => {
    var data = new URLSearchParams().toString();
    
    fetchRequest(
      `${config.url.API_URL}/ClientLogo.asmx/GetRandomLogoStyle`,
      "post",
      data
    )
      .then((response: any) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string['_']);
            if (res.Success) {
              const style = res.Response != undefined ? JSON.parse(res.Response) : null;
              resolve(style);
            } else {
              resolve(null);
            }
          }
        });
      })
      .catch(function (error) {
        console.log(error);
        resolve(null);
      });
  });
};
