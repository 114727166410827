import React, { useContext, useEffect, useRef, useState } from "react";
import SelfServiceContext, { BudgetDetails } from "../../data/SelfService-context";
import { IonButton, IonCol, IonGrid, IonItem, IonLabel, IonRadio, IonRadioGroup, IonRow, IonText } from "@ionic/react";
import BudgetInfo from "../../components/Budget/BudgetInfo";
import CustomModal from "../../components/utils/CustomElements/CustomModal/CustomModal";
import CustomPublishButton from "../utils/CustomElements/CustomPublishButton";
import { useForm } from "react-hook-form";
import CustomForm from "../utils/CustomElements/CustomForm";
import { toast } from "react-toastify";
import { failOrange, successOrange } from "../../images";


const BudgetUpgrade: React.FC<{
  currentBudget: BudgetDetails;
  onSuccess: (success: boolean) => void;
}> = (props) => {
  const selfCtx = useContext(SelfServiceContext);
  const [result, setResult] = useState<{ success: boolean, message: string } | null>(null);

  //const [upgradeOptions, setUpgradeOptions] = useState<UpgradeBudgetOption[]>();
  const [allowSave, setAllowSave] = useState<boolean>(false);
  const [error, setError] = useState("");
  const mounted = useRef(false);

  const { handleSubmit, formState, errors, control, register, clearError, getValues } = useForm({
    mode: "onChange",
  });
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
  };
}, []);
 
  const onPackChange_callback = (event: CustomEvent) => {
    setAllowSave(!!event.detail.value);

  };

  const saveHandler = (data: any) => {
    selfCtx.createBudgetRequests(true, {
      old_b144_pack: props.currentBudget.pack_code.toString(),
      new_b144_pack: data['pack']
    }).then((res: any) => {
      if (res && res.success) {

        setResult({ success: true, message: '<div>בקשתך נקלטה במערכת!</div><div>החבילה תשודרג ב48 השעות הקרובות.</div>' });
      } else if (res && res.error) {
        setResult({ success: false, message: res.error });

      } else {
        toast.error('ארעה שגיאה')

      }
    });


  };
  const BudgetUpgradeConfirm = () => {

    props.onSuccess(result ? result.success : false);
    setResult(null);
  };

  const numberWithCommas = (x: number) => {
    if (!x) {
      return '0';
    }
    return x.toFixed(2).replace(/\.00$/, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <React.Fragment>
      <CustomForm onSubmit={handleSubmit(saveHandler)} errors={errors}>

        <IonGrid className="ion-margin-top">
          <IonRow>
            <IonCol>
              {props.currentBudget &&
                <BudgetInfo currentBudget={props.currentBudget} />}
            </IonCol>
          </IonRow>
          {props.currentBudget &&
            props.currentBudget.recommendedPacks &&
            props.currentBudget.recommendedPacks.length > 0 &&
            <React.Fragment>
              <IonRow className='ion-text-center'>
                <IonCol><h3>שדרוג תקציב החבילה</h3></IonCol>
              </IonRow>
              <IonRow className='ion-text-center'>
                <IonCol>
                  <small>עלות מדיה + דמי ניהול</small></IonCol>
              </IonRow>
              <IonRow>
                <IonCol className='ion-no-padding'>
                  <IonRadioGroup name='pack' ref={register} onIonChange={onPackChange_callback} >
                    <IonRow>
                      {props.currentBudget.recommendedPacks.map(pack =>
                        <IonCol size='6' className='ion-no-padding' key={pack.pack_code}>
                          <IonItem mode='md' lines='none' className='item-radio'>
                            <IonLabel className='item-radio-label'>
                              {numberWithCommas(pack.isFixed ? pack.max_budget_fee : pack.cost)} ₪</IonLabel>
                            <IonRadio mode='md' slot="start" className='item-radio-btn' value={pack.pack_code} />
                          </IonItem>
                        </IonCol>)}

                    </IonRow>
                  </IonRadioGroup>
                </IonCol>
              </IonRow>
            </React.Fragment>
          }

          {error && (
            <IonRow className="ion-text-center">
              <IonCol>
                <IonText color="danger">
                  <p>{error}</p>
                </IonText>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
        <IonRow>
          <IonCol sizeMd='10' pullMd='1'>
            <CustomPublishButton title='שדרג' size='small' disabled={!allowSave} />
          </IonCol>
        </IonRow>
      </CustomForm>




      <CustomModal
        pageName={"BudgetUpgradeConfirm"}
        headerParameters={{
          isPageHaveChanges: false,
          modalType: "confirm",
        }}
        closeModal={() => {
          if (mounted.current) {
          setResult(null);
        }
        }}
        isOpen={!!result} >

        {result &&
          <div className="ion-text-center">
            <div className="ion-text-center ion-margin-bottom ion-padding-bottom">
              <img src={result.success ? successOrange : failOrange} height={110} />
            </div>
            <div dangerouslySetInnerHTML={{ __html: result.message }}></div>
          </div>
        }
        <IonRow>
          <IonCol sizeMd='10' pullMd='1'>
            <IonButton size='small' expand='block' type='button' className="main-button " onClick={BudgetUpgradeConfirm} >
              אישור
            </IonButton>
          </IonCol>
        </IonRow>

      </CustomModal>
    </React.Fragment>
  );
};
export default BudgetUpgrade;
