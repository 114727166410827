import React from "react";
import NativeApp from "../utils-ts/NativeApp";

export default function useDownloadExcel({
  tableRef,
  filename,
}: {
  tableRef: React.RefObject<HTMLTableElement> | null;
  filename: string;
}) {
  const { IsNativeApp, DownloadFile } = NativeApp();

  const onDownload = () => {
    const rows = tableRef?.current?.rows;
    if (!rows) return;
    // Construct csv
    const csv = [];
    for (let i = 0; i < rows.length; i++) {
      const row = [],
        cols = rows[i].cells;
      for (let j = 0; j < cols.length; j++) {
        // Clean innertext to remove multiple spaces and jumpline (break csv)
        let innerText = cols[j].innerText;
        const data = (innerText.startsWith("0") ? "\t" + innerText : innerText)
          .replace(/(\r\n|\n|\r)/gm, "")
          .replace(/(\s\s)/gm, " ")
          .replace(/"/g, '""');
        // Push escaped string
        row.push('"' + data + '"');
      }
      // fit to hebrew - rtl
      csv.push(row.reverse().join(","));
    }
    const csv_string = csv.join("\n");

    // Download it
    const data =
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv_string);

    if (IsNativeApp) return DownloadFile(data);

    Object.assign(document.createElement("a"), {
      target: "_blank",
      href: data,
      download: `${filename}.csv`,
    }).click();
  };

  return { onDownload };
}
