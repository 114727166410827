import React, { SVGProps } from "react";

export default function FacebookIcon3(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      {...props}
    >
      <g fill="none" fillRule="evenodd" transform="translate(2.4 2.4)">
        <circle cx="21.6" cy="21.6" r="21.6" fill="currentColor"></circle>
        <path
          fill="#FEFEFE"
          d="M23.505 35.64V22.831h4.3l.643-4.991h-4.943v-3.187c0-1.446.401-2.43 2.474-2.43l2.643-.002V7.757c-.457-.061-2.026-.197-3.851-.197-3.812 0-6.42 2.326-6.42 6.598v3.682H14.04v4.991h4.31V35.64h5.155z"
        ></path>
      </g>
    </svg>
  );
}
