import React, { useEffect, useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonImg,
  IonIcon,
} from "@ionic/react";
import { isMobile } from "react-device-detect";

import CustomModal from "../utils/CustomElements/CustomModal/CustomModal";
import { HeaderParameters } from "../../data/Header-Context";
import {
  welcomeNewUser,
  welcomeNewUserMob,
  arrowBack,
} from "../../images/index";
import "./welcomeNewUser.scss";
import { Capacitor } from "@capacitor/core";

const WelcomeNewUser: React.FC<{
  show: boolean;
  closeModal: () => void;
}> = (props) => {
  const headerParameters: HeaderParameters = {
    modalType: "confirm",
    isShowLogo: false,
  };
  const imgEl = React.useRef<HTMLImageElement>(null);
  const [loaded, setLoaded] = useState(false);
  const onImageLoaded = () => setLoaded(true);
  useEffect(() => {
    const imgElCurrent = imgEl.current;

    if (imgElCurrent) {
      imgElCurrent.addEventListener('load', onImageLoaded);
      return () => imgElCurrent.removeEventListener('load', onImageLoaded);
    }
  }, [imgEl]);
  return (
    <CustomModal
      pageName={"welcomeNewUser"}
      headerParameters={headerParameters}
      closeModal={() => {
        props.closeModal();
      }}
      cssClass="welcomeNewUser"
      isOpen={props.show}
      title={"התחדשנו בממשק חדש!"}
      subTitle={"כל הנתונים על העסק שלך ואפשרויות עריכה זמינים ופשוטים מתמיד"}
    >
      <IonGrid>
        <IonRow>
        <img src={!isMobile ? welcomeNewUser : welcomeNewUserMob} 
        alt='welcome logo'
         ref={imgEl}
         
         />
        </IonRow>
        <IonRow className="actions-wrapper">
          <IonCol size="12">
            <IonButton
              className="button-pop"
              onClick={() => {
                props.closeModal();
                dtlPushEvt({'event':'onboarding','eventInfo': {'ScreenName':'{{page path}}','user_action':'clicks_on_המשך לאתר'}});
              }}
            >
              {Capacitor.getPlatform() !== "android" &&
              Capacitor.getPlatform() !== "ios" ? (
                <span>המשך לאתר</span>
              ) : (
                <span>המשך לאפליקציה</span>
              )}
              <IonIcon mode="ios" icon={arrowBack} />
            </IonButton>
          </IonCol>
          <IonCol size="12">
            <p className={!isMobile ? "large-p" : ""}>
              פרטים מלאים והדרכה על השינויים
              {isMobile && <br></br>}
              <a 
                onClick={() => {
                      dtlPushEvt({'event':'onboarding','eventInfo': {'ScreenName':'{{page path}}','user_action':'clicks_on_מרכז התמיכה'}});
                      SetDtlPushEvtOutBound('outbound_links','http://support.b144.co.il/');
                      }
                    } 
                className="link-outside" 
                href="http://support.b144.co.il/">
                במרכז התמיכה
              </a>
              שלנו
            </p>
          </IonCol>
        </IonRow>
      </IonGrid>
    </CustomModal>
  );
};

export default WelcomeNewUser;
