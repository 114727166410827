import {
  IonCheckbox,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
} from "@ionic/react";
import React, { useEffect, useRef } from "react";
import Hammer from "react-hammerjs";
import history from "../../../utils-ts/History";

export const SetMultiSelectListner = (
  isMultiChoiseShow: boolean,
  onCancelMultiCoiceFunc: () => void
) => {
  if (isMultiChoiseShow) {
    window.addEventListener("popstate", () => {
      onCancelMultiCoiceFunc();
      onBackButtonEvent();
    });
    window.history.pushState({ name: "MultiChoise" }, "MultiChoise", null);
    history.go(1);
  } else {
    onCancelMultiCoiceFunc();
    onBackButtonEvent();
    history.goBack();
  }
};

const onBackButtonEvent = () => {
  window.removeEventListener("popstate", onBackButtonEvent);
};

export const ChooseAll: React.FC<{
  show: boolean;
  onChooseAll: (checked: boolean) => void;
}> = (props) => {
  const cBoxRef = useRef<HTMLIonCheckboxElement>(null);
  useEffect(() => {
    if (!props.show) {
      cBoxRef.current!.checked = false;
    }
  });
  return (
    <div className="multi-choice-wraper">
      <IonCheckbox
        ref={cBoxRef}
        hidden={!props.show}
        mode="ios"
        onIonChange={(e) => {
          props.onChooseAll(e.detail.checked);
        }}
      />
      <IonLabel hidden={!props.show}>
        <h3 className="small-title bold-text">הכל</h3>
      </IonLabel>
    </div>
  );
};

const CustomMultiChoice: React.FC<{
  show: boolean;
  elementId: string;
  checkedElements: string[];
  onChange: (newCheckedElements: string[]) => void;
  onTap: () => void;
  onPress: () => void;
}> = (props) => {
  const setCheckedItems = (checked: boolean) => {
    const newArray = [...props.checkedElements];
    var index = newArray.indexOf(props.elementId.toString());
    if (index > -1 && !checked) {
      //remove from array
      newArray.splice(index, 1);
    } else if (index === -1 && checked) {
      //add to array
      newArray.push(props.elementId.toString());
    }
    return newArray;
  };
  let isFocuse = false;
  return (
    <IonGrid className="ion-no-padding">
      <IonRow>
        <IonCol className="ion-no-padding">
          {props.show && (
            <IonCheckbox
              mode="ios"
              className="pic-checkbox"
              checked={props.checkedElements.some(
                (item) => item == props.elementId
              )}
              onIonFocus={() => {
                // check if change by choose all
                isFocuse = true;
              }}
              onIonChange={(e) => {
                if (isFocuse) {
                  isFocuse = false;
                  const newCheckedElements = setCheckedItems(e.detail.checked);
                  props.onChange(newCheckedElements);
                }
              }}
            />
          )}
          <Hammer
            // options={{
            //   touchAction: "pan-y",
            //   recognizers: {
            //     swipe: {
            //       velocity: 0.5,
            //       direction: 1,
            //       threshold: 100,
            //     },
            //   },
            // }}
            onTap={() => {
              props.onTap();
            }}
            onPress={() => {
              props.onPress();
            }}
          >
            {props.children}
          </Hammer>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default CustomMultiChoice;
