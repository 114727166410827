import React from "react";

import CustomModal from "../../utils/CustomElements/CustomModal/CustomModal";
import { HeaderParameters } from "../../../data/Header-Context";
import { useForm } from "react-hook-form";
const CategoriesPopUp: React.FC<{
  show: boolean;
  children: React.ReactNode[] | React.ReactNode;
  closeModal: () => void;
}> = (props) => {
  const { formState } = useForm({
    mode: "onChange",
  });
  const { dirty } = formState;

  const headerParameters: HeaderParameters = {
    isPageHaveChanges: dirty,
    modalType: "modal",
  };

  return (
    <CustomModal
      pageName={"PriceListCategories"}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
      headerParameters={headerParameters}
      title={"בחירת קטגוריה"}
      subTitle={
        "העסק שלך משויך למספר קטגוריות, ברשותך להחליט איזה מחירון אתה מעוניין להוסיף"
      }
    >
      {props.children}
    </CustomModal>
  );
};

export default CategoriesPopUp;
