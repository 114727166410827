import { fetchRequest } from "../utils-ts/fetchRequest";
import { config } from "../Constants";
const parseString = require("xml2js").parseString;

export interface DateFilter {
  FromYear: number;
  FromMonth: number;
  FromDay: number;
  ToYear: number;
  ToMonth: number;
  ToDay: number;
}

export interface faceBookData {
  TotalPageActionsPostReactionsLikeTotal: number;
  TotalPageEngagedUsers: number;
  TotalPageImpressions: number;
  TotalPagePostEngagements: number;
  TotalPagePostsImpressions: number;
  TotalPageTotalActions: number;
  TotalPageViewsTotal: number;
}

export interface googleData {
  AllActivities: number;
  QueriesDirect: number;
  QueriesIndirect: number;
  QueriesChain: number;
  ViewsMaps: number;
  ViewsSearch: number;
  ActionsWebsite: number;
  ActionsPhone: number;
  ActionsDrivingDirections: number;
}

export interface landingPageObj {
  Active: string;
  Title: string;
  Id: string;
}

export interface memberLeadsObj {
  ContactName: string;
  Phone: string;
  Email: string;
  SystemDesc: string;
  SendDate: string;
}

export interface memberCallsObj {
  DialedFrom: string;
  StatusDesc: string;
  call_start: string;
}
export interface memberLeadOrCallObj {
  Id: string;
  LeadId: number;
  Date: string;
  Phone: string;
  OriginPhone: string;
  //Status?: string;
  StatusType: number;
  Referer: string;
  Type: number;
  Name: string;
  OriginName: string;
  Mail: string;
  OriginMail: string;
  Address: string;
  OriginAddress: string;
  Comments: string;
  NotesOrigin: string;
  HighQuality: number;
  CallDuration: string;
}
export interface logObj {
  Type: string;
  Date: string;
}

export const GetFacebookPagesSummery = (dateFilter: DateFilter) => {
  return new Promise<{ data: faceBookData[]; chart: [] }>((resolve, reject) => {
    const jsDetails = {
      dateFilter: dateFilter,
    };

    var data = new URLSearchParams({
      jsDetails: JSON.stringify(jsDetails),
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientStatistics.asmx/GetFacebookPagesSummery`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const responseData = JSON.parse(res.Response);
              if (responseData) {
                resolve(createChartData(responseData));
              }
            }
          }
        });
      })
      .catch((error) => {});
  });
};

export const GetGooglePagesSummery = (dateFilter: DateFilter) => {
  return new Promise<{ data: faceBookData[]; chart: [] }>((resolve, reject) => {
    const jsDetails = {
      dateFilter: dateFilter,
    };

    var data = new URLSearchParams({
      jsDetails: JSON.stringify(jsDetails),
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientStatistics.asmx/GetGooglePagesSummery`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const responseData = JSON.parse(res.Response);
              if (responseData) {
                resolve(createChartData(responseData));
              }
            }
          }
        });
      })
      .catch((error) => {});
  });
};

export const GetLandingPages = () => {
  return new Promise<landingPageObj[]>((resolve, reject) => {
    const jsDetails = {};

    var data = new URLSearchParams({
      jsDetails: JSON.stringify(jsDetails),
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/Client.asmx/GetLandingPages`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const landingPageList = JSON.parse(res.Response);
              if (landingPageList) {
                resolve(landingPageList);
              } else {
                resolve([]);
              }
            }
          }
        });
      })
      .catch((error) => {});
  });
};

export const GetECommerceSummery = (dateFilter: DateFilter) => {
  return new Promise<{ data: faceBookData[]; chart: [] }>((resolve, reject) => {
    const jsDetails = {
      dateFilter: dateFilter,
    };

    var data = new URLSearchParams({
      jsDetails: JSON.stringify(jsDetails),
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientStatistics.asmx/GetECommerceSummery`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const responseData = JSON.parse(res.Response);
              if (responseData) {
                resolve(createChartData(responseData));
              }
            }
          }
        });
      })
      .catch((error) => {});
  });
};

export const GetLandingPageSummery = (
  dateFilter: DateFilter,
  PageId: string
) => {
  return new Promise<{ data: faceBookData[]; chart: [] }>((resolve, reject) => {
    const jsDetails = {
      dateFilter: dateFilter,
      PageId: PageId,
    };

    var data = new URLSearchParams({
      jsDetails: JSON.stringify(jsDetails),
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientStatistics.asmx/GetLandingPagesSummery`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const responseData = JSON.parse(res.Response);
              let data = createChartData(responseData);
              if (responseData) {
                resolve(data);
              }
            }
          }
        });
      })
      .catch((error) => {});
  });
};

export const GetMemberLeads = (dateFilter: DateFilter) => {
  return new Promise<memberLeadsObj[]>((resolve, reject) => {
    const jsDetails = {
      dateFilter: dateFilter,
    };

    var data = new URLSearchParams({
      jsDetails: JSON.stringify(jsDetails),
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientStatistics.asmx/GetLeadsSummery`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const responseData = JSON.parse(res.Response);
              if (responseData) {
                resolve(responseData);
              }
            }
          }
        });
      })
      .catch((error) => {});
  });
};

export const GetMemberCalls = (dateFilter: DateFilter) => {
  return new Promise<memberCallsObj[]>((resolve, reject) => {
    const jsDetails = {
      dateFilter: dateFilter,
    };

    var data = new URLSearchParams({
      jsDetails: JSON.stringify(jsDetails),
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientStatistics.asmx/GetLeadCallsSummery`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const responseData = JSON.parse(res.Response);
              if (responseData) {
                resolve(responseData);
              }
            }
          }
        });
      })
      .catch((error) => {});
  });
};

export const GetAllLeadsTypes = (dateFilter: DateFilter) => {
  return new Promise<memberLeadOrCallObj[]>((resolve, reject) => {
    const jsDetails = {
      dateFilter: dateFilter,
    };

    var data = new URLSearchParams({
      jsDetails: JSON.stringify(jsDetails),
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientStatistics.asmx/GetAllLeadsTypes`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const responseData = JSON.parse(res.Response);
              if (responseData) {
                resolve(responseData);
              }
            }
          }
        });
      })
      .catch((error) => {});
  });
};
//Get logs
export const GetLogsForItem = (id: string) => {
  return new Promise<logObj[]>((resolve, reject) => {
    var data = new URLSearchParams({
      id: id,
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientStatistics.asmx/GetLogsForItem`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const responseData = JSON.parse(res.Response);
              if (responseData) {
                resolve(responseData);
              }
            }
          }
        });
      })
      .catch((error) => {});
  });
};
export const GetAllDataPhone = (phone: string) => {
  return new Promise<memberLeadOrCallObj[]>((resolve, reject) => {
    var data = new URLSearchParams({
      phone: phone,
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientStatistics.asmx/GetAllLeadsByPhone`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const responseData = JSON.parse(res.Response);
              if (responseData) {
                resolve(responseData);
              }
            }
          }
        });
      })
      .catch((error) => {});
  });
};
// Update & Insert
export const UpdateLeadOrCallItem = (item: memberLeadOrCallObj) => {
  return new Promise<string>((resolve, reject) => {
    var data = new URLSearchParams({
      jsDetails: JSON.stringify(item),
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientStatistics.asmx/UpdateLeadOrCallItem`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            resolve(res);
          }
        });
      })
      .catch((error) => {});
  });
};
//Insert log
export const InsertLogLeadOrCallItem = (itemId: string, Type: string) => {
  return new Promise<string>((resolve, reject) => {
    var data = new URLSearchParams({
      jsDetails: JSON.stringify({ manageLeadId: itemId, Type: Type }),
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/ClientStatistics.asmx/InsertLogLeadOrCallItem`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            resolve(res);
          }
        });
      })
      .catch((error) => {});
  });
};

const createChartData = (responseData: any) => {
  const chartData = responseData.length >= 2 ? responseData[1].Value : null;
  const data = responseData.length >= 1 ? responseData[0].Value : null;
  return {
    data: data,
    chart: chartData,
  };
};
