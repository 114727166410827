import React, { useEffect, useRef, useState,useContext } from 'react';
import { IonGrid, IonRow, IonCol, IonText, IonItem, IonCheckbox, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';

import ImagePicker, { Photo } from '../utils/ImagePicker/ImagePicker';
import CustomPublishButton from '../utils/CustomElements/CustomPublishButton';

import CustomModal from '../utils/CustomElements/CustomModal/CustomModal';
import { HeaderParameters } from '../../data/Header-Context';
import MemberDetailsContext from "../../data/MemberDetailes-context";

import { useForm } from 'react-hook-form';
import CustomForm from '../utils/CustomElements/CustomForm';
import BusinessAutoLogo from './BusinessAutoLogo';


const BusinessLogo: React.FC<{
  show: boolean;
  businessLogo: string | undefined;
  allowGmbSync: boolean;
  closeModal: () => void;
  onSave: (tokenPhoto: Photo, sync_gmb: boolean) => void;
  onSaveAuto: (base64:string, sync_gmb: boolean) => void;
  onDelete: (sync_gmb: boolean) => void;
}> = (props) => {
  const { handleSubmit, formState, errors, control } = useForm({
    mode: 'onChange',
  });
  const { dirty } = formState;
  const [isPageChange, setIsPageChange] = useState(false);
  const [takenPhoto, setTakenPhoto] = useState<Photo>();
  const [autoLogoBase64, setAutoLogoBase64] = useState('');
  const [logoType, setLogoType] = useState<'IMAGE' | 'HTML'>('IMAGE');
  
  const syncGmbRef = useRef<HTMLIonCheckboxElement>(null);

  const [syncGmb, setSyncGmb] = React.useState(props.allowGmbSync);

  const [error, setError] = useState('');

  const MemberDetailsCxt = useContext(MemberDetailsContext);
  
  useEffect(() => {
    if (props.businessLogo) {
      setTakenPhoto({ path: undefined, preview: props.businessLogo });
    }
  }, [props.businessLogo]);

  const cbxSyncGmbChanged = () => {
    const sync = syncGmbRef.current!.checked;
    setSyncGmb(sync);
  };
  const saveLogoHandler = async () => {
    setError('');
    
      if ((logoType == 'HTML' && !autoLogoBase64) ||
       (logoType != 'HTML' && (!takenPhoto || takenPhoto.preview === ''))) {
        setError('לא נבחרה תמונה');
        return;
      }
    
    
    
    const sync_gmb = props.allowGmbSync && syncGmb;
  
if(logoType == 'HTML'){
    props.onSaveAuto(autoLogoBase64, sync_gmb);
  }else{
    props.onSave(takenPhoto!, sync_gmb);
  }
    props.closeModal();
  };

  const headerParameters: HeaderParameters = {
    isHeaderDark: false,
    modalType: 'modal',
    isShowDeleteButton: props.businessLogo && props.businessLogo != '' ? true : false,
    isPageHaveChanges: dirty || isPageChange,
    deleteFunc: () => {
      const sync_gmb = props.allowGmbSync && syncGmb;
      props.onDelete(sync_gmb);
      props.closeModal();
    },
  };
  const checkIfPageDirty = (newBusinessLogo: string) => {
    if (JSON.stringify(newBusinessLogo) !== JSON.stringify(props.businessLogo)) {
      setIsPageChange(true);
    } else {
      setIsPageChange(false);
    }
  };
  return (
    <CustomModal
      pageName={'BusinessLogo'}
      headerParameters={headerParameters}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
      title="לוגו">
      <CustomForm onSubmit={handleSubmit(saveLogoHandler)} errors={errors}>
        <IonGrid>
       {MemberDetailsCxt.IsAutoLogo && 
       <IonRow>
            <IonCol size="12" className='ion-padding' >
              <IonItem color="light" lines="full" mode="md"  >
                <IonLabel position="stacked">סוג לוגו</IonLabel>
                <IonSelect
                  color="light"
                  mode="md"
                  value={logoType}
                  className="custom-select"
                  interface="popover"
                  interfaceOptions={{
                    showBackdrop: false,
                  }}
                  placeholder="בחר מרשימה"
                  onIonChange={(e) => {
                    e.stopPropagation();
                    setLogoType(e.detail.value);
                    checkIfPageDirty(e.detail.value);
                  }}>
                  <IonSelectOption value="IMAGE">לוגו תמונה</IonSelectOption>
                  <IonSelectOption value="HTML">לוגו אוטומטי</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>}
          {logoType == 'IMAGE' && (
          <IonRow className={`ion-text-center image-wrapper-min-height ${props.allowGmbSync && 'image-wrapper-gmb'}`}>
            <IonCol>
                <ImagePicker
                onImagePick={(photo: Photo) => {
                  checkIfPageDirty(photo.preview);
                  setTakenPhoto(photo);
                }}
                currentPhoto={takenPhoto}
                isMultiple={false}
              />
            </IonCol>
          </IonRow>)}
          {logoType == 'HTML' && (
            <BusinessAutoLogo   
            errors={errors}
            control={control} 
            setAutoLogoBase64 ={setAutoLogoBase64}
            setIsPageChange={setIsPageChange} >

            </BusinessAutoLogo>
          )}
          {props.allowGmbSync && (
            <IonRow>
              <IonCol>
                <IonItem lines="none" className="checkbox-list-item">
                  <IonLabel>סינכרון עם דף גוגל</IonLabel>
                  <IonCheckbox slot="start" checked={syncGmb} value="SyncGmb" ref={syncGmbRef} onIonChange={cbxSyncGmbChanged} />
                </IonItem>
              </IonCol>
            </IonRow>
          )}
          {error && (
            <IonRow className="ion-text-center">
              <IonCol>
                <IonText color="danger">
                  <p>{error}</p>
                </IonText>
              </IonCol>
            </IonRow>
          )}
          <CustomPublishButton className={`main-button btn-logo-${logoType == 'HTML' ? 'auto' : 'img'} ${props.allowGmbSync && 'btn-logo-gmb'}`} ></CustomPublishButton>
        </IonGrid>
      </CustomForm>
    </CustomModal>
  );
};

export default BusinessLogo;
