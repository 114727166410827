import React from "react";
import ProductListData from "../../components/Statistic/ProductListData";
import CustomPage from "../../components/utils/CustomElements/CustomPage";
//import UserContext from "../../data/Auth-context";
import { HeaderParameters } from "../../data/Header-Context";
const ProductListPage: React.FC = () => {
  //const { authValues } = useContext(UserContext);
  const headerParameters: HeaderParameters = {
    isShowLogo: true,
    isShowNotification: true,
    backButton: {
      isShowMobile: true,
      isShowDesktop: true,
      link: "/StatisticsOpt",
    },
  };

  return (
    <CustomPage
      headerParameters={headerParameters}
      pageName={"ProductList"}
      title="איזה נכס מעניין אותך?"
    >
      <ProductListData></ProductListData>
    </CustomPage>
  );
};
export default ProductListPage;
