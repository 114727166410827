import React, { ComponentProps } from "react";
import { IonAlert, IonButton, IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";

type CustomAlertProps = ComponentProps<typeof IonAlert>;
export interface AlertInput extends CustomAlertProps {
  subHeader?: string;
  onDismiss?: () => void;
}
const CustomAlert: React.FC<AlertInput> = (props) => {
  if (props.buttons) {
  }
  return (
    <>
      <IonAlert
        onDidDismiss={() => {
          if (props.onDismiss) {
            props.onDismiss();
          }
        }}
        buttons={props.buttons}
        {...props}
      ></IonAlert>
    </>
  );
};
export default CustomAlert;
