import {
  IonCard,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
} from "@ionic/react";
import {
  browsersOutline,
  laptopOutline,
  logoWebComponent,
} from "ionicons/icons";
import jsPDF from "jspdf";
import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { HeaderParameters } from "../../data/Header-Context";
import StatisticContext, { StatProd } from "../../data/Statistic-Context";
import { facebook, google } from "../../images/index";
import { getStatisticReport } from "../../utils-ts/PdfGenerator";
import DoughnutChart from "../utils/Charts/DoughnutChart";
import CustomModal from "../utils/CustomElements/CustomModal/CustomModal";
import MonthFilter from "../utils/Filters/MonthFilter/MonthFilter";
import MemberCampaign from "./MemberCampaign";

const MemberCampaigns: React.FC<{
  month?: number;
  year?: number;
  LimitMonth?: number;
  LimitYear?: number;
  show: boolean;
  Package_Desc: string;
  closeModal: () => void;
}> = (props) => {
  const [prodMain, setProdMain] = useState<StatProd>();
  const [statisticReport, setStatisticReport] = useState<jsPDF>();
  const StatisticsCxt = useContext(StatisticContext);
  const limitMonth = props.LimitMonth;
  const limitYear = props.LimitYear;
  const [month, setMonth] = useState<number>(() => {
    if (props.month) {
      return props.month;
    } else {
      let newDate = new Date();
      return newDate.getMonth() + 1;
    }
  });

  const [year, setyear] = useState<number>(() => {
    if (props.year) {
      return props.year;
    } else {
      let newDate = new Date();
      return newDate.getFullYear();
    }
  });
  const [isOpneCampaign, setIsOpneCampaign] = useState(false);
  useEffect(() => {
    StatisticsCxt.initStatProductsContext(true, month, year);
  }, [month, year]);
  useEffect(() => {
    if (
      StatisticsCxt.actionSummery !== undefined &&
      StatisticsCxt.statProducts !== undefined
    ) {
      const report = getStatisticReport(
        month,
        year,
        props.Package_Desc,
        StatisticsCxt.actionSummery,
        StatisticsCxt.statProducts
      );
      setStatisticReport(report);
    }
  }, [StatisticsCxt.actionSummery, StatisticsCxt.statProducts]);

  const setIcon = (id: number) => {
    let icon = "";
    switch (id) {
      case 0:
        icon = "assets/icon/B144_Logo_2017.svg";
        break;
      case 21:
        icon = facebook;
        break;
      case 24:
        icon = google;
        break;
      case 18:
        icon = facebook;
        break;
      case 4:
        icon = google;
        break;
      case 20:
        icon = laptopOutline;
        break;
      case 1:
        icon = logoWebComponent;
        break;
      default:
        icon = browsersOutline;
    }
    return icon;
  };

  const headerParameters: HeaderParameters = {
    pdfDownloadPdf: statisticReport,
    modalType: "modal",
  };
  return (
    <CustomModal
      pageName={"MemberCampaigns"}
      headerParameters={headerParameters}
      closeModal={() => {
        props.closeModal();
      }}
      cssClass="fullscreen"
      isOpen={props.show}
      title="מידע מלא אודות קמפיינים"
    >
      <IonGrid fixed={true}>
        {month && (
          <MonthFilter
            currntMonth={month}
            CorrentYear={year}
            LimitMonth={limitMonth}
            LimitYear={limitYear}
            onChange={(newMonth: number, newYear: number) => {
              setMonth(newMonth);
              setyear(newYear);
            }}
          ></MonthFilter>
        )}
        <IonList className="campaigns-list">
          {StatisticsCxt.statProducts &&
            StatisticsCxt.statProducts.map((prod, index) => {
              let cost =
                prod.Prodmain === 4 || prod.Prodmain === 20
                  ? prod.GF_Cost
                  : prod.Cost;
              let precentage =
                prod.Prodmain === 4 || prod.Prodmain === 20
                  ? prod.GF_precentage
                  : prod.precentage;
              return (
                <IonCard
                  mode="md"
                  key={index}
                  button={true}
                  onClick={() => {
                    setProdMain(prod);
                    setIsOpneCampaign(true);
                  }}
                >
                  <IonItem className="ion-justify-content-between" lines="none">
                    <IonIcon
                      size="large"
                      src={setIcon(prod.Prodmain)}
                    ></IonIcon>
                    <IonLabel className="ion-margin-right">
                      <p className="bold-text">{prod.ProdMainName}</p>
                      <p>
                        נוצלו ₪{cost} מתוך ₪{Math.round(prod.Budget)}{" "}
                      </p>
                    </IonLabel>
                    {prod.IsByBudget && precentage && (
                      <DoughnutChart
                        value={precentage * 100}
                        chartWidth={!isMobile ? 135 : 59}
                        chartWeight={65}
                        chartColor="#FF692F"
                        chartBackgroundColor="#FFCBAD"
                      ></DoughnutChart>
                    )}
                  </IonItem>
                </IonCard>
              );
            })}
          {StatisticsCxt.statProducts == undefined && <div>אין תוצאות</div>}
        </IonList>
        {prodMain != null && (
          <MemberCampaign
            Product={prodMain}
            Package_Desc={props.Package_Desc}
            month={month}
            year={year}
            show={isOpneCampaign}
            LimitMonth={limitMonth}
            LimitYear={limitYear}
            closeModal={() => {
              setIsOpneCampaign(false);
            }}
          ></MemberCampaign>
        )}
      </IonGrid>
    </CustomModal>
  );
};

export default MemberCampaigns;
