import React from "react";
import background from "./results-page.jpg";
import Highlighter from "../../components/Highlighter/Highlighter";

export default function ResultsPage() {
  return (
    <div style={{ position: "relative" }}>
      <img src={background} alt="Results list" width="100%" />
      <Highlighter
        title="יחידות פרסום"
        text="הלקוחות הראשונים שמוצגים הם לקוחות
        שמנויים על השירות שלנו ואנו מקדמים אותם
        באמצעות חבילת יחידות הפרסום שהם רכשו"
        style={{
          width: "100%",
          position: "absolute",
          height: "6.3%",
          top: "1.4%",
        }}
      />
    </div>
  );
}
