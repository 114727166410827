import React from "react";
import {
    IonCheckbox,
    IonItem,
    IonLabel,
} from "@ionic/react";

import { Control, FieldError, NestDataObject } from "react-hook-form";
import { DigitalProductField } from "../../data/Vas-context";
import CustomInput from "../utils/CustomElements/CustomInput";
import CustomTextarea from "../utils/CustomElements/CustomTextarea";
import CustomAutocomplete from "../utils/CustomElements/CustomAutocomplete";



const VasDigitalField: React.FC<{
    field:DigitalProductField;
    control: Control;
    register: React.Ref<any>;
    errors?: NestDataObject<Record<string, any>, FieldError>;
    getCustomAutocomplete:(searchString: string, digitalFieldNumber?: number) => any;
}> = props => {

    const handleCbxChange = (e: CustomEvent) => {
        props.control.setValue(e.detail.value, e.detail.checked); //for outer form ref
    };
   
    return (
        <React.Fragment>
             {(props.field.fldType == 1 || props.field.fldType == 7) &&
                            <CustomInput
                                name={props.field.fldKey}
                                errors={props.errors}
                                control={props.control}
                                label={props.field.fldCaption}
                                required={!!props.field.isMandtory}
                                type={props.field.fldType == 7 ? 'email' : 'text'}
                                ref={props.register}
                                maxlength={props.field.fldLength}

                            />}
                        {props.field.fldType == 2 &&
                            <CustomTextarea
                                name={props.field.fldKey}
                                errors={props.errors}
                                control={props.control}
                                label={props.field.fldCaption}
                                required={!!props.field.isMandtory}
                                maxlength={props.field.fldLength}
                                ref={props.register} />
                        }
                        {props.field.fldType == 6 &&
                            <IonItem lines='none' color='light' className='item-cbx'>
                                <IonLabel>{props.field.fldCaption}</IonLabel>
                                <IonCheckbox slot='end' value={props.field.fldKey} onIonChange={handleCbxChange} />
                            </IonItem>
                        }

                        {(props.field.fldType == 11 || props.field.fldType == 12) && (
                            <CustomAutocomplete name={props.field.fldKey} isRequired={!!props.field.isMandtory}
                                label={props.field.fldCaption} digitalFieldNumber={props.field.fldNumber} control={props.control}
                                register={props.register} errors={props.errors} minInputLength={1}
                                getAutoCompleteItems={props.getCustomAutocomplete}></CustomAutocomplete>
                        )}
        </React.Fragment>
    );
};

export default VasDigitalField;