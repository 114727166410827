import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IonRow,
  IonCol,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonRadio,
  IonIcon,
  IonCard,
  IonButton,
} from "@ionic/react";
import { isMobile } from 'react-device-detect';
import './SelfSaleAreas.scss'
import SelfSaleContext, { RequestResult, CitiesResult } from "../../../data/SelfSaleContext";
import CustomSelect from '../CustomElements/CustomSelect';
import CustomAutocomplete from '../CustomElements/CustomAutocomplete';
import CustomChip from "../CustomElements/CustomChip";
import CustomInputGroup from "../CustomElements/CustomRadioGroup";
const SelfSaleAreas: React.FC<{ saleType: string, phaseId: string, completed: boolean, onComplete?: (phaseId: number, valid: RequestResult) => void }> = (props) => {

  const SelfSaleCtx = useContext(SelfSaleContext);
  const [locationType, setLocationType] = useState<string>("");
  const prevLocationType = useRef<string>();
  const [areaItemsNew, setAreasItemsNew] = useState<CitiesResult[]>([]);
  const [selectedAreasIds, setSelectedAreasIds] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<CitiesResult[]>([]);
  const isMounted = useRef(true);

  const locationTypes = [
    {
      value: "1",
      label: "בחירת יישוב"
    },
    {
      value: "2",
      label: "בחירת אזור"
    },
    {
      value: "3",
      label: "בכל הארץ"
    },
  ]

  const areaAccessFields = {
    valueAccessor: "city_code",
    labelAccessor: "city"
  }

  useEffect(() => {
    SelfSaleCtx.getMemberAreas()

    return () => {
      isMounted.current = false;
      setLocationType("");
      setAreasItemsNew([]);
      setSelectedAreasIds([]);
      setSelectedItems([]);
      prevLocationType.current = undefined;
    };
  }, []);

  useEffect(() => {
    if (props.completed && isMounted.current) {
      let citiesString = selectedItems?.length ? selectedItems.map(el => el.city_code).join(";") : ""
      if (locationType === "2") {
        citiesString = selectedAreasIds.join(";");
      }
      if (locationType) {
        SelfSaleCtx.saveMemberAreas(locationType, citiesString).then((res: RequestResult) => {
          if (isMounted.current) {
            props.onComplete && props.onComplete(2, res)
          }
        })
      }
    }
  }, [props.completed])

  useEffect(() => {
    if (SelfSaleCtx.memberAreas && isMounted.current) {
      setLocationType(SelfSaleCtx.memberAreas.area_type.toString())
      prevLocationType.current = SelfSaleCtx.memberAreas.area_type.toString();
      setSelectedItems(SelfSaleCtx.memberAreas.cities_result)
      setSelectedAreasIds(SelfSaleCtx.memberAreas.cities_result.map(c => c.city_code.toString()))
    }
    else if (isMounted.current) {
      setLocationType("1")
      setSelectedItems([])
    }
  }, [SelfSaleCtx.memberAreas])

  useEffect(() => {
    if (locationType === "2" && isMounted.current) {
      fetchAreasList("")
    }
    if (prevLocationType.current !== locationType && isMounted.current) {
      setSelectedItems([])
    }
    prevLocationType.current = locationType
  }, [locationType])

  useEffect(() => {
    if (isMounted.current) {
      if (SelfSaleCtx.businessCity.includes("אזור")) {
        setLocationType("2")
        setSelectedItems([])
      }
      else {
        fetchCitiesAutocomplete(SelfSaleCtx.businessCity).then((res: any) => {
          if (isMounted.current) {
            setSelectedItems(res)
          }
        })
      }
    }
  }, [SelfSaleCtx.businessCity])

  const handleSelect = (item: any) => {
    if (item.selected) {
      let found = selectedItems.find(i => i.city_code === item.city_code)
      if (!found) {
        setSelectedItems(prevState => [...prevState, { ...item }])
      }
    }
    else {
      handleRemove(item)
    }
  };
  const handleSelectAreas = (items: any[]) => {
    setSelectedAreasIds(items)
  };
  const handleRemove = (item: any) => {
    setSelectedItems(prevState => prevState.filter(c => c.city_code != item.city_code))
  };
  const handleRemoveArea = (item: any) => {
    setSelectedAreasIds(ids => (ids.filter(id => id !== item.city_code)))
    setAreasItemsNew(areaItemsNew => (areaItemsNew.map(area => {
      if (area.city_code === item.city_code) {
        area.selected = false
      }
      return area
    })))
  };

  const fetchCitiesAutocomplete = (prefix: string) => {
    return new Promise((resolve) => {
      if (!isMounted.current) {
        resolve([]);
        return;
      }
      SelfSaleCtx.getCitiesAutocomplete(prefix)
        .then((res: any) => {
          if (isMounted.current) {
            if (res && res.length) {
              resolve(res)
            }
            else {
              resolve([])
            }
          }
        })
    });
  }

  const fetchAreasList = (prefix: string) => {
    if (!isMounted.current) return;

    SelfSaleCtx.getAreasAutocomplete(prefix)
      .then((res: any) => {
        if (!isMounted.current) return;

        if (res && res.length) {
          if (SelfSaleCtx.memberAreas && SelfSaleCtx.memberAreas.cities_result.length) {
            let updateAreas = res.map((item: any) => {
              item.selected = false
              let checkSelected = SelfSaleCtx.memberAreas?.cities_result.find(selected => item.city_code === selected.city_code)
              if (checkSelected) {
                item.selected = true
              }
              return item
            })

            setSelectedAreasIds(updateAreas.filter((el: any) => el.selected).map((a: any) => a.city_code))
            setAreasItemsNew(updateAreas)
          }
          else {
            setAreasItemsNew(res)
          }
        }
        else {
          setAreasItemsNew([])
        }
      })
  }
  return (
    <IonCard mode="md" className={isMobile ? "self-sale-card self-sale-card-areas self-sale-card-mobile" : "self-sale-card self-sale-card-areas"}>
      <div style={{ minHeight: '420px' }}>
        <h2 className="ion-no-margin self-sale-section-title">איפה העסק שלך נותן שירות?</h2>
        <p className="ion-no-margin self-sale-section-text">התמקדות במקומות הספציפיים שבהם העסק פועל יעזור להגיע ללקוחות רלוונטיים</p>
        <CustomInputGroup value={locationType} name="ServiceAreas" options={locationTypes} setValue={(val) => setLocationType(val)} />
        <IonRow style={{ margin: '20px 0 30px' }}>
          <IonCol size="12" size-lg="6">
            {locationType === "1" && (<CustomAutocomplete
              options={areaAccessFields}
              placeholder={"שם היישוב"}
              selectedItems={selectedItems}
              header={"יישוב"}
              fetchItems={fetchCitiesAutocomplete}
              onSelect={handleSelect}
              query="" />

            )}
            {locationType === "2" && areaItemsNew.length && (
              <>
                <CustomSelect
                  onSelect={(e) => handleSelectAreas(e)}
                  multiple={true}
                  value={selectedAreasIds}
                  label="לפי אזורים"
                  showValues={false}
                  placeholder="בחירה מתוך הרשימה"
                  name="areas"
                  optionLabelFld="city"
                  optionValueFld="city_code"
                  options={areaItemsNew} />
              </>
            )}
          </IonCol>
        </IonRow>
        {((locationType === "1" && selectedItems.length > 0) || ((locationType === "2") && areaItemsNew.length > 0 && selectedAreasIds && selectedAreasIds.length > 0)) && (
          <div style={{ marginTop: '20px' }}>
            <p className="bold-text">העסק נותן שירות ב-</p>
            {locationType === "1" && selectedItems.length > 0 && (
              selectedItems.map((c, index) => {
                return (
                  <CustomChip icon="close" text={c.city} key={index} onClick={() => handleRemove(c)} />
                )
              })
            )}
            {(locationType === "2") && areaItemsNew.length > 0 && selectedAreasIds && selectedAreasIds.length > 0 && (
              areaItemsNew.map((c, index) => {
                if (selectedAreasIds.includes(c.city_code.toString())) {
                  return (
                    <CustomChip icon="close" text={c.city} key={index} onClick={() => handleRemoveArea(c)} />
                  )
                }
              })
            )}
          </div>
        )}
      </div>
      <p className="small-p">לידיעתך, במקרה בו התהליך לא הסתיים בהצלחה, נציג מטעמנו יחזור אלייך לסייע בסיומו.</p>
    </IonCard>
  );
};

export default SelfSaleAreas;