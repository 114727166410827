import React, { useContext, useEffect, useState } from 'react';
import { IonApp, IonButton, IonIcon, IonPopover, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { isMobile } from 'react-device-detect';
import { IonReactRouter } from '@ionic/react-router';
import Login from './pages/Login/Login';
import UserContext from './data/AuthContextProvider';
import Routing from './components/Navigation/Routing';
import Hamburger from './components/Navigation/Hamburger';
import { config } from './Constants';

/* Core CSS required for Ionic components to work properly. */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';
//import './theme/otefStyles.scss';
import './theme/theme.scss';
import HeaderContextProvider from './data/HeaderContextProvider';
import CustomHeader from './components/utils/CustomElements/CustomHeader/Header';
import { HeaderParameters } from './data/Header-Context';
import { SplashScreen } from '@capacitor/core';
import { arrowLeftWhite, logo_favicon_blue } from './images';

const App: React.FC = () => {
  const { authValues, initUserContext } = useContext(UserContext);
  const [isMenuOpen, menuOpenHandler] = useState(false);
  const [isCheckUserLogin, setIsCheckUserLogin] = useState(false);
  const [isMenuHidden, setIsMenuHidden] = useState(false);
  const [isCoSale, setIsCoSale] = useState(false);
  const [showOtefModal, setShowOtefModal] = useState(false);

  
  useEffect(() => {
    if (window.location.pathname && window.location.pathname.toLowerCase().indexOf('maintenance') >= 0) {
      setIsMenuHidden(true);
    }
    if (window.location.pathname && window.location.pathname.toLowerCase().indexOf('cosale') >= 0) {
      setIsMenuHidden(true);
      setIsCoSale(true);
    }
    if (
      window.location.pathname &&
      (window.location.pathname.toLowerCase().indexOf('cmlogin') >= 0 || 
      window.location.pathname.toLowerCase().indexOf('cmcheckuser') >= 0 || 
      window.location.pathname.toLowerCase().indexOf('userlogin') >= 0)
    ) {
      setIsCheckUserLogin(true);
    } else {
      initUserContext();
    }
  }, [initUserContext]);
  useEffect(() => {

    //load otef app styles
  if(config.otef_israel === true){
    setShowOtefModal(true);

    const scss_file = config.otef_israel === true ? './theme/otefStyles.scss' : './theme/variables.scss';
    //require(scss_file);
    document.title = "ניהול עסק - עוטף ישראל";
    const favicon = document.querySelector('link[rel="shortcut icon"]');
    favicon && favicon.setAttribute('href', logo_favicon_blue);
    }
    
    //check if site is in b144 native app
    const qs_params = new URLSearchParams(decodeURIComponent(window.location.search));
    let appToken = qs_params.get('_token');
    if (!appToken) {
      appToken = qs_params.get('token');
    }
    const platform = qs_params.get('platform') == 'webview';

    //console.log(appToken,platform);

    let AppToken = '';
    if (appToken) {
      AppToken = appToken;
    } else if (platform) {
      AppToken = '999';
    }

    // const params: any = window.location.search
    //   ? window.location.search.substring(1).split("&")
    //   : [];
    //   //alert('QS: ' + params);
    // let token =
    //   !!params.length &&
    //   params.find((param: any) => param.includes("_token"))?.substring(7);

    // let AppToken = token ? token : "";
    // if (!AppToken || AppToken === "") {
    //   token =
    //     !!params.length &&
    //     params.find((param: any) => param.includes("token"))?.substring(6);
    //   AppToken = token ? token : "";
    // }

    if (isMobile && AppToken && AppToken === '999') {
      if (typeof window !== 'undefined') {
        window.localStorage.setItem('AppToken', AppToken);
      }
    }

    if (SplashScreen) {
      SplashScreen.hide({
        fadeOutDuration: 1000,
      });
    }
  }, []);

  const headerParameters: HeaderParameters = {
    isShowLogo: true,
    isLogin: !authValues?.Token,
  };

  return (
    <IonApp dir="rtl" lang="he" className={isCoSale ? 'co-sale-page' : ''}>
      {!authValues?.Token && !isCheckUserLogin && !isCoSale ? (
        <React.Fragment>
          <CustomHeader headerParameters={headerParameters}></CustomHeader>
          <Login />
        </React.Fragment>
      ) : (
        <IonReactRouter>
          <HeaderContextProvider>
            <IonSplitPane contentId="hamburgerMain" className={isMobile && isMenuOpen ? 'side-menu-mobile' : ''}>
              <React.Suspense fallback={null}>
                {!isMenuHidden && <Hamburger sideMenuIndexPos={menuOpenHandler}></Hamburger>}
                <IonRouterOutlet id="hamburgerMain">
                  <Routing />
                </IonRouterOutlet>
              </React.Suspense>
            </IonSplitPane>
          </HeaderContextProvider>
        </IonReactRouter>
      )}

      {config.otef_israel && (
         <IonPopover 
         cssClass="alert-popover"
        
         backdropDismiss={false} 
        // ref={popUpRef} 
         onDidPresent = {() => {
           loopAllAnimations();
         }}
         isOpen={showOtefModal}
       >
        <div className="ion-text-end">
         <IonButton fill='clear' size='small' color="primary" className='btn-close' onClick={() =>setShowOtefModal(false)}>
            X
          </IonButton>
          </div>
         <div className='ion-padding-horizontal'>
           
           <p>החל מה- 1.9.24 אתר העסקים של עוטף ישראל ייסגר, אבל אל דאגה העסק שלך ימשיך להופיע באתר B144</p>
           
           <IonButton color="primary" href='https://app.b144.co.il' target='_blank' className='btn-main-link'>
           לניהול דף העסק שלך באתר B144
           <IonIcon
                    icon={arrowLeftWhite}
                    style={{
                      verticalAlign: "middle",
                      fontSize: 12,
                      marginRight: 6,
                    }}
                  ></IonIcon>
           </IonButton>
         </div>
         
       </IonPopover>
      )}
    </IonApp>
  );
};
export default App;
