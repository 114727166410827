import React, { useContext, useEffect, useRef } from "react";
import { IonCheckbox, IonContent, IonItem, IonLabel, isPlatform } from "@ionic/react";

import { useForm } from "react-hook-form";
import MemberDetailsContext from "../../../data/MemberDetailes-context";
import CustomForm from "../../utils/CustomElements/CustomForm";
import CustomTextarea, {
  getRows,
} from "../../utils/CustomElements/CustomTextarea";
import CustomPublishButton from "../../utils/CustomElements/CustomPublishButton";
import CustomModal from "../../utils/CustomElements/CustomModal/CustomModal";
import { HeaderParameters } from "../../../data/Header-Context";

const BusinessDescription: React.FC<{
  show: boolean;
  closeModal: () => void;
  onSave: () => void;
}> = (props) => {
  const { handleSubmit, formState, errors, control } = useForm({
    mode: "onChange",
  });
  const { dirty } = formState;

  const MemberDetailsCxt = useContext(MemberDetailsContext);
  useEffect(() => {
    if (MemberDetailsCxt.Description == null) {
      MemberDetailsCxt.initMemberDetailsContext();
    }
  }, []);

  const currentDescription = MemberDetailsCxt.Description;
  const allowGmbSync = MemberDetailsCxt.allowSyncGmb == true;

  const [syncGmb, setSyncGmb] = React.useState(allowGmbSync);
  const descriptionTextRef = useRef<HTMLIonTextareaElement>(null);
  const syncGmbRef = useRef<HTMLIonCheckboxElement>(null);
  const saveHandler = () => {
    const description = descriptionTextRef.current!.value!.toString();
    const sync_gmb = (allowGmbSync && syncGmb);
    MemberDetailsCxt.saveMainDetails(
      "Description",
      MemberDetailsCxt.AdvCategories,
      MemberDetailsCxt.BusinessLanguages,
      //MemberDetailsCxt.OpenDays,
      //MemberDetailsCxt.DaysRemarks,
      MemberDetailsCxt.GeneralInfo,
      description,
      sync_gmb,
      'desc'
    );
    
    
    props.onSave();
    props.closeModal();
  };

  
  const cbxSyncGmbChanged = () => {
    const sync = syncGmbRef.current!.checked;
    setSyncGmb(sync);
  };
  const headerParameters: HeaderParameters = {
    isPageHaveChanges: dirty,
    modalType: "modal",
  };

  return (
    <CustomModal
      headerParameters={headerParameters}
      pageName={"BusinessDescription"}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
      title="חידשת ללקוחות? העסק התרענן?"
      subTitle="הכי חשוב זה להיות עדכני! יש שינוי חדש בעסק? פרט נוסף ששכחת וחשוב שכל הלקוחות יידעו? כאן זה המקום"
    >
      <CustomForm onSubmit={handleSubmit(saveHandler)} errors={errors}>
        {currentDescription != undefined && (
          <CustomTextarea
            name="businessDesc"
            onClearFunc={() => {
              descriptionTextRef.current!.value = "";
            }}
            errors={errors}
            control={control}
            label="תאור העסק"
            minlength={85}
            maxlength={1000}
            required={true}
            rows={getRows(currentDescription)}
            ref={descriptionTextRef}
            value={currentDescription}
          />
          
        )}
        {allowGmbSync &&
         <IonItem  lines="none" className="checkbox-list-item">
                <IonLabel>סינכרון עם דף גוגל</IonLabel>
                <IonCheckbox
                  slot="start"
                  checked={syncGmb}
                  value='SyncGmb'
                  ref={syncGmbRef}
                  onIonChange={cbxSyncGmbChanged}
                />
              </IonItem>
              }
        <CustomPublishButton />
      </CustomForm>
    </CustomModal>
  );
};

export default BusinessDescription;


