import { IonDatetime, IonItem, IonLabel } from "@ionic/react";
import React, { ComponentProps, useEffect, useState } from "react";
import { getTimeObjFromIsoString } from "../../../../utils-ts/GlobalDateFunctions";
import PencilIcon from "../../../icons/PencilIcon";
import TrashIcon from "../../../icons/TrashIcon";
import "./CustomOpenHoursPicker.scss";

export type Day =
  | "sunday"
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday";

export type SelectedDays = Record<Day, boolean> & {
  openingTime: string;
  closingTime: string;
};

type Props = {
  onSave: (selectedDays: SelectedDays) => void;
  onDelete: (selectedDays: SelectedDays) => void;
  index: number;
  openHours?: SelectedDays[];
  editOpenHour?: SelectedDays;
};

export default function OpenHoursPicker({
  onDelete,
  onSave,
  index,
  openHours = [],
  editOpenHour,
}: Props) {
  const [open, setOpen] = useState(true);
  const [error, setError] = useState({ message: "", isAlert: false });
  const [openingTime, setOpeningTime] = useState(
    editOpenHour?.openingTime ?? "00:00"
  );
  const [closingTime, setClosingTime] = useState(
    editOpenHour?.closingTime ?? "00:00"
  );
  const [days, setDays] = useState<Record<Day, boolean>>(
    editOpenDays(editOpenHour) ?? {
      sunday: false,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
    }
  );
  const activeDays = Object.entries(days).filter((day) => day[1]);

  const hasDuplicateObjects = (arr: any[]) => {
    const seen = new Set();
    for (const obj of arr) {
      const serialized = typeof obj === "object" ? JSON.stringify(obj) : obj;
      if (seen.has(serialized)) {
        return true; // Found a duplicate
      }
      seen.add(serialized);
    }
    return false; // No duplicates
  };

  const isDuplicates = () => {
    if (!openHours.length) return false;

    for (const day of activeDays) {
      const selectedDays = openHours.filter(
        (d, i) => d[day[0] as Day] && i !== index
      );
      const openingTimes = [
        ...selectedDays.map(({ openingTime }) => openingTime),
        openingTime,
      ];
      if (hasDuplicateObjects(openingTimes)) return true;

      const closingTimes = [
        ...selectedDays.map(({ closingTime }) => closingTime),
        closingTime,
      ];
      if (hasDuplicateObjects(closingTimes)) return true;
    }

    return false;
  };

  const hasHoursOverLap = (openingTime: string, closingTime: string) => {
    const convertTimeToNumber = (time: string) => {
      const [hours, minutes] = time.split(":").map(Number);
      return hours + minutes / 60;
    };

    const range1Arr = openingTime.split("-");
    const range2Arr = closingTime.split("-");
    // Convert start and end times to numeric values
    const start1 = convertTimeToNumber(range1Arr[0]);
    const end1 = convertTimeToNumber(range1Arr[1]);
    const start2 = convertTimeToNumber(range2Arr[0]);
    const end2 = convertTimeToNumber(range2Arr[1]);

    // Check for overlapping intervals
    const isOverlapping =
      (start1 <= end2 && end1 >= start2) || (start2 <= end1 && end2 >= start1);

    return isOverlapping;
  };

  const isHoursOverLap = () => {
    if (!openHours.length) return false;
    const range = `${openingTime}-${closingTime}`;

    for (const day of activeDays) {
      const ranges = openHours.filter(
        (d, i) => d[day[0] as Day] && i !== index
      );

      for (const { openingTime, closingTime } of ranges) {
        if (hasHoursOverLap(range, `${openingTime}-${closingTime}`))
          return true;
      }
    }

    return false;
  };

  const validateOpenHours = () => {
    if (
      !openingTime ||
      !closingTime ||
      (openingTime == "00:00" && closingTime == "00:00")
    )
      return "יש להזין שעות פתיחה וסגירה";

    if (openingTime === closingTime) return "לא ניתן להזין שעות פתיחה חופפות";

    var _openingTime = Number(openingTime.split(":")[0]);
    var _closingTime = Number(closingTime.split(":")[0]);
    var _openingTime_min = Number(openingTime.split(":")[1]);
    var _closingTime_min = Number(closingTime.split(":")[1]);

    if (_closingTime === _openingTime && _closingTime_min < _openingTime_min)
      return "טווח שעות לא תקין";

    if (isDuplicates() || isHoursOverLap())
      return "נבחרו שעות חופפות עבור אותם ימים";

    return "";
  };

  const handleSave = (type: "save" | "delete") => {
    debugger;
    if (type === "delete")
      return onDelete({ openingTime, closingTime, ...days });

    if (!Object.values(days).some((value) => value))
      return setError({
        message: "יש לבחור לפחות יום אחד",
        isAlert: false,
      });

    const message = validateOpenHours();

    if (message) return setError({ message, isAlert: false });

    setOpen(false);
    return onSave({ openingTime, closingTime, ...days });
  };

  useEffect(() => {
    if (closingTime < openingTime)
      return setError({
        message: "לתשומת ליבך - הגדרת שהעסק יסגר ביום למחרת",
        isAlert: true,
      });
    else return setError({ message: "", isAlert: false });
  }, [openingTime, closingTime]);

  return (
    <div className="openHoursCard">
      {!open ? (
        <div className="closeCard">
          <div className="hours">
            {openingTime} - {closingTime}
          </div>
          <div className="days">
            {Object.entries(daySymbols).map(([day, value]) => (
              <div className={days[day as Day] ? "text-darkAzure" : undefined}>
                {value}
              </div>
            ))}
          </div>
          <button className="editBtn">
            <PencilIcon
              style={{ width: 16, height: 16 }}
              onClick={() => setOpen(true)}
            />
          </button>
        </div>
      ) : (
        <div className="openCard">
          <div className="hours">
            <div className="hour">
              <TimeInput
                label="שעת פתיחה"
                displayFormat="HH:mm"
                name="openingTime"
                placeholder="בחר שעה"
                value={openingTime}
                onChange={setOpeningTime}
              />
            </div>
            <div className="hour">
              <TimeInput
                label="שעת סגירה"
                displayFormat="HH:mm"
                name="closingTime"
                placeholder="בחר שעה"
                value={closingTime}
                onChange={setClosingTime}
              />
            </div>
            {error.message && (
              <span role="alert" className="alert">
                {error.message}
              </span>
            )}
          </div>
          <div className="days">
            {Object.entries(days).map(([day, value]) => (
              <label
                htmlFor={"day-" + day}
                className={"dayLabel" + (value ? " checked" : "")}
              >
                <input
                  type="checkbox"
                  onChange={() =>
                    setDays((days) => ({ ...days, [day]: !value }))
                  }
                  checked={value}
                  className="dayInput"
                  id={"day-" + day}
                />
                {daySymbols[day as Day]}
              </label>
            ))}
          </div>
          <div className="btnWrapper">
            <button
              className="saveBtn"
              type="button"
              onClick={() => handleSave("save")}
            >
              שמירה
            </button>
            <button
              className="deleteBtn"
              type="button"
              onClick={() => handleSave("delete")}
            >
              <TrashIcon style={{ width: 16, height: 16 }} />
              מחיקה
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

const daySymbols: Record<Day, string> = {
  sunday: "א",
  monday: "ב",
  tuesday: "ג",
  wednesday: "ד",
  thursday: "ה",
  friday: "ו",
  saturday: "ש",
};

const editOpenDays = (editObj?: SelectedDays) => {
  if (!editObj) return;
  const { openingTime, closingTime, ...editOpenDays } = editObj;
  return editOpenDays;
};

type IonDatetimeProps = ComponentProps<typeof IonDatetime>;
interface TimeInputProps extends Omit<IonDatetimeProps, "value" | "onChange"> {
  label: string;
  value: string;
  onChange: (time: string) => void;
}

const TimeInput = ({ value, onChange, label, ...props }: TimeInputProps) => (
  <IonItem color="light" mode="md" lines="full">
    <IonLabel position="stacked">{label}</IonLabel>

    <IonDatetime
      {...props}
      cancelText="ביטול"
      doneText="אישור"
      onIonChange={(selected) => {
        let time = "";
        if (selected.detail.value) {
          time = getTimeObjFromIsoString(selected.detail.value);
        }
        onChange(time);
        return time;
      }}
      value={props.displayFormat === "HH:mm" ? `2000-01-01T${value}:00` : value}
    />
  </IonItem>
);
