import React, { useContext, useEffect, useState } from "react";
import { IonButton, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonRow, IonText } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import CustomAutocomplete, { autocompleteOption } from "../utils/CustomElements/CustomAutocomplete";
import { Control, FieldError, NestDataObject } from "react-hook-form";
import VasContext from "../../data/Vas-context";
import MemberDetailsContext from "../../data/MemberDetailes-context";
import SelfServiceContext from "../../data/SelfService-context";
import { toast } from "react-toastify";

const FrmCities: React.FC<{
  register: React.Ref<any>;
  control: Control;
  errors?: NestDataObject<Record<string, any>, FieldError>;
}> = props => {
  const MemberDetailsCxt = useContext(MemberDetailsContext);
  const selfCxt = useContext(SelfServiceContext);
  const [tempCities, setTempCities] = useState<autocompleteOption[]>();
  const [error, setError] = useState("");
  const getCities = (searchString: string) => {
    return selfCxt.getCities(searchString);

  };

  useEffect(() => {
    let init_cities: autocompleteOption[] = [];
    if (!tempCities || tempCities.length == 0) {
      
     if(MemberDetailsCxt.BusinessServiceArea){
      if (MemberDetailsCxt.BusinessServiceArea.ServiceAreaType == 'city') {
        init_cities = MemberDetailsCxt.BusinessServiceArea.ServiceAreasList.map(i => {
          return {
            value: i.City_Code.toString(),
            display: i.City
          }
        });
      } 
      if(init_cities == null || init_cities.length == 0){
        if(MemberDetailsCxt.BusinessServiceArea.MemberCity && MemberDetailsCxt.BusinessServiceArea.MemberCity.City_Code > 0){
        
          init_cities = [{
            value: MemberDetailsCxt.BusinessServiceArea.MemberCity.City_Code.toString(),
            display: MemberDetailsCxt.BusinessServiceArea.MemberCity.City
          }];
        }
      }
      setTempCities(init_cities);
    } 
  }
    if (props.control &&
      props.control.fieldsRef &&
      props.control.fieldsRef!.current &&
      !props.control.fieldsRef!.current!.cities_arr) {
      props.control.register('cities_arr');
    }
    if (props.control &&
      props.control.fieldsRef &&
      props.control.fieldsRef!.current &&
      props.control.fieldsRef!.current!.cities_arr &&
      props.control.fieldsRef!.current!.cities_arr.ref.value == undefined) {
      props.control.setValue('cities_arr', init_cities);
    }
  },[]);


  const citySelected_callback = (option: autocompleteOption) => {
    if (tempCities && tempCities.length >= 15) {
      setError('לא ניתן להוסיף יותר מ-15 ערים');
      return;
    }
    setTempCities((current_cities) => {
      const updated_cities: autocompleteOption[] = current_cities ? [...current_cities] : [];
      updated_cities.push(option);

      if (updated_cities.length >= 15) {
        setError('לא ניתן להוסיף יותר מ-15 ערים');

      }

      props.control.setValue('cities_arr', updated_cities); //for outer form ref
      return updated_cities;
    });
  };

  const removeCity = (city_code: string) => {
    setError('');
    if(MemberDetailsCxt.BusinessServiceArea && city_code == MemberDetailsCxt.BusinessServiceArea.MemberCity.City_Code.toString()){
          toast.warning('לא ניתן להסיר את יישוב העסק');
      return;
    }
  
    setTempCities((current_cities) => {
      if (!!current_cities) {
        const updated_cities: autocompleteOption[] = [...current_cities!];

        const updateCities = updated_cities.filter(c => { return c.value != city_code });
        props.control.setValue('cities_arr', updateCities); //for outer form ref
        return updateCities;
      }
    });

  };
  return (<React.Fragment>
    <IonGrid>
      <IonRow>
        <IonCol>
          <CustomAutocomplete
            name='cities_search'
            label='ערים/יישובים'
            register={props.register}
            control={props.control}
            errors={props.errors}
            minInputLength={1}
            clearInputAfterSelect={true}
            getAutoCompleteItems={getCities}
            skipItems={tempCities}
            maxResults={6}
            optionSelected_callback={citySelected_callback}
          />
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol size='12' sizeMd='7'>
          <div>
            <h3 className='bold-text small-title'>הערים שנבחרו</h3>
          </div>
          <IonRow>
            {tempCities && tempCities.map((city, index) => {
              return (
                <IonCol size={city.display.length > 12 ? '5' : '4'} key={index} className='service-chip'>
                  <IonButton fill='clear' size="small" onClick={removeCity.bind(null, city.value)} >
                  <IonIcon slot='start' icon={closeOutline} />
                    <IonLabel color='primary'>
                      {city.display}</IonLabel>
                   
                  </IonButton></IonCol>
              )
            })}</IonRow>
        </IonCol>
      </IonRow>
      {error && (
        <IonRow className="ion-text-center">
          <IonCol>
            <IonText color="danger">
              <p>{error}</p>
            </IonText>
          </IonCol>
        </IonRow>
      )}


    </IonGrid>
  </React.Fragment>);
};

export default FrmCities;
