import React, { useContext, useEffect, useRef } from "react";
import { IonContent, IonGrid, IonRow, IonCol, isPlatform } from "@ionic/react";

import CustomInput from "../../utils/CustomElements/CustomInput";
import { useForm } from "react-hook-form";
import MemberDetailsContext from "../../../data/MemberDetailes-context";
import CustomForm from "../../utils/CustomElements/CustomForm";
import CustomModal from "../../utils/CustomElements/CustomModal/CustomModal";
import CustomPublishButton from "../../utils/CustomElements/CustomPublishButton";
import { HeaderParameters } from "../../../data/Header-Context";

const BusinessAddress: React.FC<{
  show: boolean;
  closeModal: () => void;
  onSave: () => void;
}> = (props) => {
  const { handleSubmit, formState, errors, control } = useForm({
    mode: "onChange",
  });
  const { dirty } = formState;

  const MemberDetailsCxt = useContext(MemberDetailsContext);
  useEffect(() => {
    if (MemberDetailsCxt.BusinessAddress == null) {
      MemberDetailsCxt.initMemberDetailsContext();
    }
  }, []);

  const currentBusinessAddress = MemberDetailsCxt.BusinessAddress;
  const saveHandler = () => {};
  const headerParameters: HeaderParameters = {
    isPageHaveChanges: dirty,
    modalType: "modal",
  };
  return (
    <CustomModal
      pageName={"BusinessAddress"}
      headerParameters={headerParameters}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
      title="כתובת"
      subTitle="כדאי שהלקוחות יראו היכן ממוקם העסק שלך, יש להוסיף כאן רחוב ומספר
    אפשר להשאיר את השדה הזה ריק אם אין לעסק שלך מיקום פיזי כמו חנות ומשרד"
    >
      <CustomForm onSubmit={handleSubmit(saveHandler)} errors={errors}>
        <IonGrid>
          {currentBusinessAddress && (
            <>
              <IonRow>
                <IonCol>
                  <CustomInput
                    name="city"
                    label="עיר"
                    errors={errors}
                    control={control}
                    value={currentBusinessAddress?.City}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <CustomInput
                    name="street"
                    label="רחוב"
                    errors={errors}
                    control={control}
                    value={
                      currentBusinessAddress?.Street +
                      " " +
                      currentBusinessAddress?.StreetNo
                    }
                  />
                </IonCol>
              </IonRow>
            </>
          )}
        </IonGrid>
        <CustomPublishButton />
      </CustomForm>
    </CustomModal>
  );
};

export default BusinessAddress;
