import React, { useContext, useEffect, useRef } from "react";
import { IonContent, IonGrid, IonRow, IonCol, isPlatform } from "@ionic/react";

import CustomInput from "../../utils/CustomElements/CustomInput";
import { useForm } from "react-hook-form";
import MemberDetailsContext from "../../../data/MemberDetailes-context";
import CustomForm from "../../utils/CustomElements/CustomForm";
import CustomPublishButton from "../../utils/CustomElements/CustomPublishButton";
import CustomModal from "../../utils/CustomElements/CustomModal/CustomModal";
import { HeaderParameters } from "../../../data/Header-Context";

const BusinessEcommerceSite: React.FC<{
  show: boolean;
  closeModal: () => void;
  onSave: () => void;
}> = (props) => {
  const { handleSubmit, formState, errors, control } = useForm({
    mode: "onChange",
  });
  const { dirty } = formState;

  const MemberDetailsCxt = useContext(MemberDetailsContext);
  useEffect(() => {
    if (MemberDetailsCxt.GeneralInfo == null) {
      MemberDetailsCxt.initMemberDetailsContext();
    }
  }, []);

  const currentGeneralInfo = MemberDetailsCxt.GeneralInfo;
  const EcommerceSiteTextRef = useRef<HTMLIonInputElement>(null);
  const saveHandler = () => {
    let newGeneralInfo = { ...currentGeneralInfo! };
    newGeneralInfo!.EcommerceSite = EcommerceSiteTextRef.current!.value!.toString();
    MemberDetailsCxt.saveMainDetails(
      "GeneralInfo",
      MemberDetailsCxt.AdvCategories,
      MemberDetailsCxt.BusinessLanguages,
      //MemberDetailsCxt.OpenDays,
      //MemberDetailsCxt.DaysRemarks,
      newGeneralInfo,
      MemberDetailsCxt.Description
    );
    props.onSave();
    props.closeModal();
  };

  const headerParameters: HeaderParameters = {
    isPageHaveChanges: dirty,
    modalType: "modal",
  };
  return (
    <CustomModal
      pageName={"BusinessEcommerceSite"}
      headerParameters={headerParameters}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
      title="אתר מסחר"
    >
      <CustomForm onSubmit={handleSubmit(saveHandler)} errors={errors}>
        <IonGrid>
          <IonRow>
            <IonCol>
              {currentGeneralInfo && (
                <CustomInput
                  name="ecommerceSite"
                  label="אתר מסחר"
                  errors={errors}
                  control={control}
                  type="url"
                  maxlength={1000}
                  value={currentGeneralInfo?.EcommerceSite}
                  ref={EcommerceSiteTextRef}
                />
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
        <CustomPublishButton/>
      </CustomForm>
    </CustomModal>
  );
};

export default BusinessEcommerceSite;
