/// <reference path='../utils-ts/react-text-format.d.ts' />
import React, { useContext } from "react";
import { messageObj } from "../data/MemberInbox-Context";
import { convertTimeStampToDate } from "../utils-ts/GlobalDateFunctions";
import { ClearImageFromString } from "../utils-ts/GlobalStringClearFunction";

import CustomModal from "./utils/CustomElements/CustomModal/CustomModal";
import CustomReactTextFormat from "./utils/CustomElements/CustomReactTextFormat";
import { HeaderParameters } from "../data/Header-Context";
import { IonCol, IonGrid, IonIcon, IonRow, isPlatform } from "@ionic/react";
import { logoNegative } from "../images/index";
import UserSettingsContext from "../data/UserSettings-Context";
import { isMobile } from "react-device-detect";

const MemberInboxMessage: React.FC<{
  show: boolean;
  selectedMessage: messageObj | undefined;
  closeModal: () => void;
  onDelete: (message: messageObj) => void;
}> = (props) => {
  const date = props.selectedMessage
    ? convertTimeStampToDate(props.selectedMessage.sending_time)
    : "";

  const UserSettingsCtx = useContext(UserSettingsContext);
  const isContainHtmlTags =
    props.selectedMessage &&
    props.selectedMessage.body &&
    props.selectedMessage.body.includes("html")
      ? true
      : false;
  const userName = UserSettingsCtx.userProfileSettingsInfo
    ? UserSettingsCtx.userProfileSettingsInfo.FullName
    : "";
  const body = props.selectedMessage
    ? props.selectedMessage.body.replace("#שם פרטי#", userName)
    : "";
  const headerParameters: HeaderParameters = {
    isShowDeleteButton: true,
    modalType: "modal",
    deleteFunc: () => {
      props.onDelete(props.selectedMessage!);
      props.closeModal();
    },
  };
  return (
    <CustomModal
      closeModal={() => {
        props.closeModal();
      }}
      pageName={"MemberInboxMessage"}
      isOpen={props.show}
      headerParameters={headerParameters}
      title="תוכן ההודעה"
    >
      <IonGrid className="inbox-modal">
        <IonRow className="ion-justify-content-between ion-margin-top">
          <IonCol size="1">
            <IonIcon icon={logoNegative}></IonIcon>
          </IonCol>
          <IonCol size="10.5" className="ion-no-padding">
            <IonGrid>
              <IonRow className="ion-justify-content-between ion-align-items-top">
                <IonCol size="8">
                  <h3 className="ion-no-margin bold-text">
                    {props.selectedMessage?.title}
                  </h3>
                </IonCol>
                <IonCol className="ion-text-left">
                  <p className="small-p">{date}</p>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
        </IonRow>
        <IonRow class="ion-margin-top">
          <IonCol size="12">
            <div style={{fontSize: (isMobile ? 15 : 18)}}>
              {props.selectedMessage &&
                props.selectedMessage.body &&
                isContainHtmlTags && (
                  <div
                    className="modal-body"
                    dangerouslySetInnerHTML={{
                      __html: body,
                    }}
                  />
                )}
              {props.selectedMessage &&
                props.selectedMessage.body &&
                !isContainHtmlTags && (
                  <CustomReactTextFormat>{body}</CustomReactTextFormat>
                )}
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </CustomModal>
  );
};
export default MemberInboxMessage;
