import { IonIcon, IonItem, IonLabel, IonList } from "@ionic/react";
import React from "react";
import { HeaderParameters } from "../../data/Header-Context";
import CustomModal from "../utils/CustomElements/CustomModal/CustomModal";
import { landingPageObj } from "../../data/statisticServices";
import { landingPage } from "../../images/index";
const LandingPageListModal: React.FC<{
  closeModal: () => void;
  chooseLandingPage: (Id: string) => void;
  show: boolean;
  landingPageListData: landingPageObj[];
}> = (props) => {
  const headerParameters: HeaderParameters = {
    modalType: "modal",
  };
  return (
    <CustomModal
      pageName={"LandingPageListModal"}
      headerParameters={headerParameters}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
      title={"בחר דף נחיתה"}
    >
      <IonList>
        {props.landingPageListData.map((d) => {
          return (
            <IonItem
              className="ion-margin-vertical"
              button
              slot="end"
              lines="full"
              detailIcon="chevron-back"
              onClick={(e) => {
                props.chooseLandingPage(d.Id);
              }}
              key={d.Id}
            >
              <IonLabel>{d.Title}</IonLabel>
              <IonIcon icon={landingPage} slot="start" />
            </IonItem>
          );
        })}
      </IonList>
    </CustomModal>
  );
};
export default LandingPageListModal;
