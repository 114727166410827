import React from "react";

const GlobalContext = React.createContext<{
  pageTip: string | undefined;
  modalTip: string | undefined;
  setLoader: (showLoading:boolean)=> void;
  getPageTip: (pageName: string, pageType: "modal" | "page") => void;
}>({
  pageTip: undefined,
  modalTip: undefined,
  getPageTip: () => {},
  setLoader:()=>{}
});

export default GlobalContext;
