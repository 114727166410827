export const getTitle = (fullName:string) => {
    const memberName = fullName;
    const newDate = new Date();
    const currentHour = newDate.getHours();
    let partOfTeDay = "";
    if (6 <= currentHour && currentHour < 11) {
      partOfTeDay = "בוקר טוב ";
    }
    if (11 <= currentHour && currentHour < 16) {
      partOfTeDay = "צהריים טובים  ";
    }
    if (16 <= currentHour && currentHour < 19) {
      partOfTeDay = 'אחה"צ טובים ';
    }
    if (19 <= currentHour && currentHour < 21) {
      partOfTeDay = "ערב טוב ";
    }
    if (21 <= currentHour || currentHour < 6) {
      partOfTeDay = "לילה טוב ";
    }
    return partOfTeDay + " " + memberName;
  };