import { Plugins } from "@capacitor/core";
import { config } from "../Constants";
import { fetchRequest } from "../utils-ts/fetchRequest";
const parseString = require("xml2js").parseString;
const { Storage } = Plugins;

export const ExternalLinkPages: { [key: string]: string } = {
  1: "1001", // website
  4: "9090", //googleAdwords
  12: "1100", // ecomm settings
  18: "9212", //fb campaign
  20: "9156", //landing pages
  24: "810", // GooglePages
  25: "9270", //taboola
};

export const InternalLinkPages: { [key: string]: string } = {
  4: "/Statistics/Google", //googleAdwords
  18: "/Statistics/Facebook", //fb campaign
};

export const generateExternalLinkWithToken = () => {
  return new Promise<string>((resolve, reject) => {
    Storage.get({ key: "userData" })
      .then((response) => {
        let link = "";
        if (response.value != null) {
          const storedUserData = response.value
            ? JSON.parse(response.value)
            : undefined;
          const EZToken = storedUserData.EZToken;
          if (EZToken != undefined && EZToken != "") {
            link = `${config.url.MEMBERS_LOGIN_CHECK_URL}${EZToken}&Member_username=&AC=&CM_username=-1&ActivityOrigins=&st=&tp=2&specificp=`;
          }
        }
        resolve(link);
      })
      .catch(function (error) {
        console.log(error);
      });
  });
};

export const initMemberActiveSystems = () => {
  return new Promise<number[]>((resolve, reject) => {
    var data = new URLSearchParams({}).toString();
    fetchRequest(
      `${config.url.API_URL}/Client.asmx/GetMemberActiveSystems`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const data = JSON.parse(res.Response);
              resolve(data);
            }
          }
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  });
};

export const checkIsSiteMaintenance = () => {
  return new Promise<number[]>((resolve, reject) => {
    var data = new URLSearchParams({}).toString();
    fetchRequest(
      `${config.url.API_URL}/SystemUI.asmx/CheckIsSiteMaintenance`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            resolve(res.IsSiteMaintenance);
          }
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  });
};
