import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonRadio,
  IonListHeader,
  IonCheckbox,
  IonText,
  IonButton,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonModal,
  IonItemGroup,
  IonItemDivider,
  IonHeader,
  IonCard,
} from "@ionic/react";
import { toast } from "react-toastify";
// import { useForm } from "react-hook-form";
import { isMobile } from 'react-device-detect';
// import { chevronDown, trash, close, addCircleOutline } from 'ionicons/icons';
import SelfSaleContext, { RequestResult, Categories, CategoriesAdv, AdGroup } from "../../../data/SelfSaleContext";
// import SelfSaleCategoriesAdvSelect from './SelfSaleCategoriesAdvSelect'
// import SelfSaleCategoryAdv from './SelfSaleCategoryAdvList'
// import CustomChip from '../CustomElements/CustomChip'
// import CustomSelect from '../CustomElements/CustomSelect'
// import { v4 as uuidv4 } from 'uuid';
import SelfSaleCategoryAdvList from "./SelfSaleCategoryAdvList";

const SelfSaleCategory: React.FC<{ saleType: string, completed: boolean, onComplete?: (phaseId: number, valid: RequestResult) => void, onSelect?: (item: any) => void, onSelectAdgroups?: (item: any) => void, onSkip?: (phaseId: string) => void }> = (props) => {
  const SelfSaleCtx = useContext(SelfSaleContext);
  const [selectedCategory, setSelectedCategory] = useState<Categories>({
    category: "",
    cat_code: "",
    category_adv: [],
    selected: true,
    min_cat_adv: 0,
    adgroups: ""
  });
  const [googleAdgroups, setGoogleAdgroups] = useState<AdGroup[]>([]);
  const [minCatAdv, setMinCatAdv] = useState<number>(-1);
  const [selectedAdGroupId, setSelectedAdGroupId] = useState<string>("-1");
  const [categoryAdvFields, setCategoryAdvFields] = useState<CategoriesAdv[]>([]);
  const [categoryAdvLists, setCategoryAdvLists] = useState<CategoriesAdv[] | null>(null);
  const [categoryAdvCheckboxes, setCategoryAdvCheckboxes] = useState<CategoriesAdv[] | null>(null);

  useEffect(() => {
    SelfSaleCtx.getMemberCategories().then((res: any) => {
      if (res && res.length) {
        setSelectedCategory(res[0])
      }
      else {
        SelfSaleCtx.getCategoriesAutocomplete(SelfSaleCtx.businessCategory)
          .then((res: any) => {
            if (res && res.length) {
              setSelectedCategory(prevState => ({ ...prevState, cat_code: res[0].cat_code, category: res[0].category, min_cat_adv: res[0].min_cat_adv }))
            }
          })
      }
    })
  }, [])

  useEffect(() => {
    if (selectedCategory.cat_code) {
      SelfSaleCtx.getMinAdvCategories(selectedCategory.cat_code).then((data: any) => {
        setMinCatAdv(data === -1 ? 2 : data)
      })
      SelfSaleCtx.getCategoriesAdv(selectedCategory.cat_code)
        .then((res: any) => {
          if (res && res.length) {
            let lists = res.filter((i: any) => i.fld_type === "d" && i.fld_desc !== "דוברי שפות")
            let checkboxes = res.filter((i: any) => i.fld_type === "c")
            setCategoryAdvLists(lists)
            setCategoryAdvCheckboxes(checkboxes)
            setCategoryAdvFields(res.filter((i: any) => i.fld_type === "d" || i.fld_type === "c"))
          }
          else {
            if (props.saleType === "1" && props.onSkip) {
              props.onSkip("3")
            }
            setCategoryAdvFields([])
          }
        })
      if (props.saleType === "2") {
        SelfSaleCtx.getResllerSubCategories(`'${selectedCategory.cat_code}'`)
          .then((res: any) => {
            setSelectedAdGroupId(res.filter((ag: any) => ag.cat_code === selectedCategory.cat_code && ag.selected).map((g: any) => g.id)[0])
            setGoogleAdgroups(res.filter((ag: any) => ag.cat_code === selectedCategory.cat_code).map((g: any) => {
              g.selected = g.status === "1" ? true : false
              return g
            }))
          })
      }
    }

  }, [selectedCategory])

  const handleSelectAdgroup = (adGroup: string) => {
    setSelectedAdGroupId(adGroup)
  };
  const createDataObjectN = () => {
    let errors: string[] = []
    let validatedCategoriesOptions: any[] = []
    let selectedAdgroups: string = ""
    var data = ""
    let catAdv: any[] = []
    var count_positive_answers = 0;
    var only_cbxs_count = 0;
    var total_selected = 0;
    var success = true;
    var catAdvId = ""
    if (categoryAdvFields && categoryAdvFields.length > 0) {
      categoryAdvFields.forEach(adv => {
        catAdvId = adv.cat_adv_id
        var fld_type = adv.fld_type;
        var fld_name = "";
        var val = "";
        if (adv.fld_type == "c") {
          only_cbxs_count++;
          if (adv.selected) {
            count_positive_answers++;
            total_selected++;
            val = "-1"; //yes, positive is -1
          }
          else {
            val = "0";
          }
          fld_name = adv.fld_desc
        } else if (adv.fld_type === "d" && adv.list_options.length) {
          var checkedValues = adv.list_options.filter(o => o.selected).map(f => {
            total_selected++;
            return f.fld_option_id
          })
          val = checkedValues.join();
          fld_name = adv.fld_desc;
          //validation-flt type d
          if (checkedValues.length == 0 && adv.fld_mandatory) {
            success = false;
            errors.push(`חובה לבחור לפחות תחום התמחות אחד ב${adv.fld_desc}`)
          }
        }
        catAdv.push({
          cat_adv_id: adv.cat_adv_fld_id,
          fld_type: fld_type,
          fld_desc: fld_name,
          fld_value: val
        });
      })
    }

    if (props.saleType === "2") {
      if (selectedAdGroupId === "" || selectedAdGroupId === undefined) {
        errors.push("יש לבחור תחום התמחות בגוגל")
      }
    }
    else {
      //validation-flt type c
      if (catAdv.length < 2 && count_positive_answers < 1 && only_cbxs_count > 0) {
        errors.push(`נא לסמן אפשרות חיובית אחת בקטגוריה ${selectedCategory.category}`)

      } else if (only_cbxs_count > 1 && count_positive_answers < selectedCategory.min_cat_adv) {
        errors.push(`יש לבחור לפחות ${selectedCategory.min_cat_adv} תחומי התמחות`);
      }
    }

    validatedCategoriesOptions.push({
      cat_id: selectedCategory.cat_code,
      adv_cat_id: catAdvId,
      categories_adv_list: catAdv,
      adgroups: [selectedAdGroupId]
    })
    let dataObj = { catData: validatedCategoriesOptions, errors }
    return dataObj
  }
  useEffect(() => {
    if (props.completed) {
      let validatedData = createDataObjectN()
      if (validatedData.errors.length) {
        props.onComplete && props.onComplete(3, { Success: false, ResultData: "", ResultDesc: validatedData.errors.join('<br/>') })
      }
      else {
        SelfSaleCtx.deleteMemberCategories(validatedData.catData.map(i => i.cat_id).join(',')).then((res: RequestResult) => {
          if (res.Success) {
            saveCategories(validatedData.catData)
          }
          else {
            toast.info(res.ResultDesc)
          }
        })
      }
    }
  }, [props.completed])

  const saveCategories = async (categoriesData: any) => {
    Promise.all(categoriesData.map(async (category: any) => {
      category.categories_adv_list = JSON.stringify(category.categories_adv_list)
      return await saveCategoryData(category);
    })
    ).then((res: any) => {
      if (res && res.length) {
        props.onComplete && props.onComplete(3, res[0])
      }
    })
  }
  const saveCategoryData = async (category: any) => {
    return new Promise((resolve) => {
      SelfSaleCtx.saveMemberCategoryAdv(category)
        .then((res: RequestResult) => {
          resolve(res)
        })
    });
  }

  return (
    <>
      {selectedCategory && (
        <>
          {props.saleType === '1' && (
            <IonCard mode="md" className={isMobile ? "self-sale-card self-sale-card-mobile category-card" : "self-sale-card category-card"}>
              <>
                <div className="category-card-header">
                  <h2 className="ion-no-margin self-sale-section-title">באיזה תחומים העסק שלך מתמחה?</h2>
                </div>
                <p className="self-sale-section-text">אלו התחומים הקיימים בקטגוריה <b>{selectedCategory.category}</b>. אפשר לסמן כמה תחומים:</p>
              </>
              <IonRow>
                <IonCol size="12">
                  <SelfSaleCategoryAdvList categoryAdvTypeC={categoryAdvCheckboxes} categoryAdvTypeD={categoryAdvLists} />
                </IonCol>
              </IonRow>
            </IonCard>
          )}
        </>
      )
      }
      {props.saleType === '2' && googleAdgroups && googleAdgroups.length > 0 && (
        <IonCard mode="md" className={isMobile ? "self-sale-card self-sale-card-mobile category-card self-sale-card-sm" : "self-sale-card category-card self-sale-card-sm"}>
          <div className="category-card-header">
            <h2 className="ion-no-margin self-sale-section-title">באיזה תחום העסק שלך מתמחה?</h2>
          </div>
          <p className="self-sale-section-text">העסק שלך יפורסם בגוגל וגם יקודם במדריך B144.
            <br />
            יש לבחור את תחום ההתמחות הכי רלוונטי לעסק שלך
          </p>
          <IonRow>
            <IonCol size="12">
              <div>
                <IonRadioGroup className="category-adgroups-list"
                  allowEmptySelection={false}
                  onIonChange={(e: any) => handleSelectAdgroup(e.target.value)}
                  value={selectedAdGroupId}>
                  {googleAdgroups.map((g, index) => (<IonItem key={index} mode="md" lines="none" className="item-radio">
                    <IonLabel className="item-radio-label">{g.name}</IonLabel>
                    <IonRadio
                      mode="md"
                      slot="start"
                      className="item-radio-btn"
                      value={g.id}
                    />
                  </IonItem>))}
                </IonRadioGroup>
                {/* {googleAdgroups.map(g=> (<IonItem mode="md" lines="none" className="" key={uuidv4()}>
                    <IonCheckbox
                      mode="md"
                      slot="start"
                      className="" checked={c.selected}
                      onIonChange={(e: any) => select(e.target.value, null)}
                      value={g.id}>
                      </IonCheckbox>
                    <IonLabel>
                    {g.name}
                    </IonLabel>
                  </IonItem>))} */}
              </div>

            </IonCol>
          </IonRow>
        </IonCard>
      )}
    </>
  );
};

export default SelfSaleCategory;
