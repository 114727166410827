import React, { useContext, useEffect, useRef, useState } from "react";
import { IonBadge, IonImg, IonSlide, IonSlides } from "@ionic/react";
import GalleryContext, { Image } from "../../../data/Gallery-context";
import GalleryHandler from "./GalleryHandler";
import { Photo } from "../../utils/ImagePicker/ImagePicker";
import CustomModal from "../../utils/CustomElements/CustomModal/CustomModal";
import { HeaderParameters } from "../../../data/Header-Context";
import "./Gallery.scss";
import { isMobile } from "react-device-detect";

const GalleryItemPreview: React.FC<{
  imagId: string;
  onDelete: (photoId: string) => void;
  closeModal: () => void;
  imageArray: Image[] | undefined;
  show: boolean;
}> = (props) => {
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const galleryCtx = useContext(GalleryContext);
  const [selectedImage, setSelectedImage] = useState<Image | undefined>(() => {
    if (props.imageArray && props.imagId) {
      return props.imageArray.find((f) => f.Id === props.imagId);
    }
  });
  const [selectedImageIndex] = useState<number | undefined>(() => {
    if (props.imageArray && props.imagId) {
      return props.imageArray.findIndex((f) => f.Id === props.imagId);
    }
  });
  const saveImageHandler = (imageDesc: string) => {
    if (selectedImage) {
      galleryCtx.saveImage(imageDesc, selectedImage.Id);
    }
  };

  const slideOption = { initialSlide: selectedImageIndex!, speed: 400 };

  const headerParameters: HeaderParameters = {
    modalType: "itemPreview",
    isHeaderDark: true,
    isShowEditButton: true,
    editBtnFunc: () => {
      setIsEditing(true);
    },
    isShowDeleteButton: true,
    deleteFunc: () => {
      props.onDelete(selectedImage!.Id);
      props.closeModal();
    },
  };
  let imgSize = "&w=328&h=173&typ=1";
  if (!isMobile) {
    imgSize = "&w=1270&h=670&typ=1";
  }
  return (
    <CustomModal
      onWillPresent={() => {
        if (slidesRef) {
          slidesRef.current!.options = slideOption;
          slidesRef.current!.update();
        }
      }}
      pageName={"GalleryItemPreview"}
      headerParameters={headerParameters}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
    >
      {isEditing && (
        <GalleryHandler
          onSave={(photo: Photo | undefined, desc: string) => {
            saveImageHandler(desc);
            setIsEditing(false);
          }}
          image={selectedImage!}
          closeModal={() => {
            setIsEditing(false);
          }}
          show={isEditing}
          isMultiple={false}
        ></GalleryHandler>
      )}

      <IonSlides
        pager={true}
        onIonSlideDidChange={() => {
          slidesRef.current?.getActiveIndex().then((result) => {
            setSelectedImage(props.imageArray![result]);
          });
        }}
        ref={slidesRef}
        options={slideOption}
      >
        {props.imageArray &&
          props.imageArray.map((m, index) => {
            return (
              <IonSlide key={index}>
                <div className="slide-container">
                  <IonImg src={m.ImagePath + imgSize} />
                  <IonBadge className="image-preview-badge" color="medium">
                    {m.ImageDesc}
                  </IonBadge>
                </div>
              </IonSlide>
            );
          })}
      </IonSlides>
    </CustomModal>
  );
};

export default GalleryItemPreview;
