import React from "react";

export default function PencilIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill="currentColor"
        d="M15.145 8.53L11.47 4.856l-9.802 9.802v3.676h3.676l9.802-9.802zm2.902-2.9a.976.976 0 000-1.383l-2.294-2.294a.976.976 0 00-1.382 0l-1.922 1.922 3.676 3.676 1.922-1.922z"
      ></path>
    </svg>
  );
}
