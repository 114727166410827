import React from "react";
import { HorizontalBar, Bar } from "react-chartjs-2";
import { isMobile } from "react-device-detect";
const Chart = require("react-chartjs-2").Chart;

const BarChart: React.FC<{
  chartData: {
    labels: string[] | IterableIterator<string>;
    data: number[];
    title?: string;
  };
  chartType?: "horizontal" | "vertical";
  chartWidth?: number | string;
  chartHeight?: number;
  chartAdditionalData?: any;
  isShowLegend?: boolean;
  backgroundColor?: string[];
  chartColor?: string;
  titleColor?: string;
}> = ({
  chartType = "horizontal",
  backgroundColor = "rgba(255, 158, 71, 0.5)",
  ...props
}) => {
  const titleColor = props.titleColor
    ? props.titleColor
    : props.chartColor
    ? props.chartColor
    : "black";
  const data = {
    labels: props.chartData.labels, //["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: props.chartData.title ?? "",
        data: props.chartData.data, //[12, 19, 3, 5, 8, 3],
        borderWidth: 1,
        backgroundColor: (context: any) => {
          const ctx = context.chart.ctx;
          const gradient =
            chartType === "horizontal"
              ? ctx.createLinearGradient(
                  isMobile ? 100 : 200,
                  0,
                  isMobile ? 200 : 300,
                  0
                )
              : ctx.createLinearGradient(
                  0,
                  isMobile ? 100 : 200,
                  0,
                  isMobile ? 200 : 300
                );
          const colorStart = "#ffb643";
          const colorEnd = "#ff8c4a";
          gradient.addColorStop(0, colorStart);
          gradient.addColorStop(1, colorEnd);
          return gradient;
        },
      },
    ],
  };

  chartType === "horizontal" &&
    data.datasets[0].data.map((currentValue, index, array) => {
      array[index] = currentValue * -1;
    });

  const options = {
    responsive: true,
    devicePixelRatio: 2,
    legend: {
      position: chartType === "horizontal" ? "left" : "top",
      display: true,
      // prevent click (the click hides the graph)
      onClick: (e: any, legendItem: any, legend: any) => {
        return;
      },
    },
    tooltips: {
      enabled: false,
    },
    plugins: {
      labels: {
        render: (args: any) => {
          return;
          // if (!args.value) return;
          // return args.value;
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            display: chartType === "horizontal",
            beginAtZero: true,
            fontColor: "rgb(0, 0, 0)",
          },
          maxBarThickness: 16, // number (pixels)
          gridLines: {
            display: false,
          },
          position: "right",
        },
      ],
      xAxes: [
        {
          ticks: {
            display: chartType !== "horizontal",
            beginAtZero: true,
            fontColor: "rgb(56, 56, 56)",
          },
          maxBarThickness: 16, // number (pixels)
          gridLines: {
            display: false,
          },
        },
      ],
    },
    // onHover: (event: any, chartElement: any) => {
    //   event.target.style.cursor = chartElement[0] ? "pointer" : "default";
    // },
  };

  const originalBarDraw = Chart.controllers.bar.prototype.draw;
  Chart.helpers.extend(Chart.controllers.bar.prototype, {
    draw() {
      const chart = this.chart;
      const type = chart.config.type;
      const ctx = chart.ctx;
      originalBarDraw.apply(this, arguments);
      ctx.font = Chart.helpers.fontString(14, "bold", "Open Sans Hebrew");
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillStyle = titleColor;

      chart.config.data.datasets.forEach(function (dataset: any, i: any) {
        const meta = chart.controller.getDatasetMeta(i);
        meta.data.forEach(function (bar: any, index: any) {
          let data = dataset.data[index];
          if (data == 0) return;
          data = data.toString().replace("-", "");
          const textSpaceX =
            type !== "horizontalBar"
              ? 0
              : data.length === 2
              ? -12
              : data.length === 3
              ? -15
              : -9;
          const textSpaceY = type === "horizontalBar" ? 1 : -7;
          ctx.fillText(
            data,
            bar._model.x + textSpaceX,
            bar._model.y + textSpaceY
          );
        });
      });
      // chart.update();
    },
  });

  Chart.plugins.register({
    beforeDraw: (c: any) => {
      const legends = c.legend.legendItems;
      legends.forEach((e: any) => {
        e.lineWidth = 0;
        e.fillStyle = "white";
      });
    },
  });

  return (
    <div
      style={{
        // width: props.chartWidth ? props.chartWidth : "inherit",
        fontSize: 200,
        textAlign: "center",
      }}
    >
      {chartType === "horizontal" ? (
        <HorizontalBar
          width={140}
          height={props.chartHeight}
          options={options}
          data={data}
        />
      ) : (
        <Bar
          width={140}
          height={props.chartHeight}
          options={options}
          data={data}
        />
      )}
    </div>
  );
};

export default BarChart;
