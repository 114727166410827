import React, { useState, useCallback, useContext } from "react";
import StatisticContext, {
  StatProd,
  actionSummery,
  ExposeStatType,
  B144Stats,
} from "./Statistic-Context";
import { config } from "../Constants";
import { fetchRequest } from "../utils-ts/fetchRequest";
//import CustomLoader from "../components/utils/CustomElements/CustomLoader";
import GlobalContext from "./Global-Context";
const parseString = require("xml2js").parseString;

const ProductsContextProvider: React.FC = (props) => {
  const [statProducts, setstatProducts] = useState<StatProd[] | undefined>();
  const [actionSummery, setActionSummery] = useState<
    actionSummery[] | undefined
  >();
  const [statProductsPrev, setstatProductsPrev] = useState<
    StatProd[] | undefined
  >();
  const [statB144, setStatB144] = useState<B144Stats>({
    StatsDaysByMonths: [],
    StatsByCatOrName: [],
    StatsExposeTypes: [],
    StatsByCategories: [],
    StatsBytransactionsSources: [],
    isStatEmpty: true,
  });

  //const [loading, setLoading] = useState<boolean>(false);
  const globalCtx = useContext(GlobalContext);
  const setLoading = (loading: boolean) => {
    globalCtx.setLoader(loading);
  };

  const initActionSummeryContext = useCallback(
    async (
      useLoading: boolean,
      fromDate: Date,
      toDate: Date,
      ProdMain?: number
    ) => {
      useLoading && setLoading(useLoading);
      const jsDetails = {
        fromDate,
        toDate,
        ProdMain: ProdMain,
      };
      var data = new URLSearchParams({
        jsDetails: JSON.stringify(jsDetails),
      }).toString();
      fetchRequest(
        `${config.url.API_URL}/ClientStatistics.asmx/GetActionSummery`,
        "post",
        data
      )
        .then((response) => {
          useLoading && setLoading(false);
          parseString(response, (err: any, result: any) => {
            if (result) {
              var res = JSON.parse(result.string["_"]);
              if (res.Success) {
                const data = JSON.parse(res.Response);
                setActionSummery(data.AcountsClicksActions);
              }
            }
          });
        })
        .catch((error) => {
          useLoading && setLoading(false);
        });
    },
    []
  );
  const initStatProductsContext = useCallback(
    async (useLoading: boolean, month: number, year: number) => {
      useLoading && setLoading(useLoading);
      const jsDetails = {
        StatYear: year,
        StatMonth: month,
        ProdMain: -1, //bring all products if need filter by prod id
      };

      var data = new URLSearchParams({
        jsDetails: JSON.stringify(jsDetails),
      }).toString();

      fetchRequest(
        `${config.url.API_URL}/ClientStatistics.asmx/GetMonthlyMembersProd`,
        "post",
        data
      )
        .then((response) => {
          useLoading && setLoading(false);
          parseString(response, (err: any, result: any) => {
            if (result) {
              var res = JSON.parse(result.string["_"]);
              if (res.Success) {
                const productsArray = JSON.parse(res.Response);
                if (productsArray == null) {
                  setstatProducts([]);
                } else {
                  setstatProducts(productsArray);
                }
                initStatProductsPrevContext(month, year);
              }
            }
          });
        })
        .catch((error) => {
          useLoading && setLoading(false);
        });
    },
    []
  );

  const initStatProductsPrevContext = useCallback(
    async (month: number, year: number) => {
      const prevDate = new Date(year, month - 1, 1);
      prevDate.setMonth(prevDate.getMonth() - 1);
      const jsDetails = {
        StatYear: prevDate.getFullYear(),
        StatMonth: prevDate.getMonth() + 1,
      };

      var data = new URLSearchParams({
        jsDetails: JSON.stringify(jsDetails),
      }).toString();

      fetchRequest(
        `${config.url.API_URL}/ClientStatistics.asmx/GetMonthlyMembersProd`,
        "post",
        data
      )
        .then((response) => {
          parseString(response, (err: any, result: any) => {
            if (result) {
              var res = JSON.parse(result.string["_"]);
              if (res.Success) {
                const productsArray = JSON.parse(res.Response);
                if (productsArray == null) {
                  setstatProductsPrev([]);
                } else {
                  setstatProductsPrev(productsArray);
                }
              }
            }
          });
        })
        .catch((error) => {});
    },
    []
  );

  const initStatB144Context = useCallback(
    async (
      fromDate: Date,
      toDate: Date,
      exposeStatType: ExposeStatType,
      userChoice = 1,
      chargedOrTotalExposures = 1
    ) => {
      const jsDetails = {
        fromDate, //: new Date("01/01/2022 00:00:00"),
        toDate, //: new Date("12/31/2022 00:00:00"),
        userChoice,
        chargedOrTotalExposures,
      };

      var data = new URLSearchParams({
        jsDetails: JSON.stringify(jsDetails),
      }).toString();

      const statWebMethod = {
        StatsDaysByMonths: "GetStatDaysByMonths",
        StatsByCatOrName: "GetStatByCatOrName",
        StatsExposeTypes: "GetStatExposeTypes",
        StatsByCategories: "GetStatByCategories",
        StatsBytransactionsSources: "GetStatBytransactionsSources",
        StatsByTotalExposed: "GetStatByTotalExposed",
      };

      fetchRequest(
        `${config.url.API_URL}/ClientStatistics.asmx/${statWebMethod[exposeStatType]}`,
        "post",
        data
      )
        .then((response) => {
          parseString(response, (err: any, result: any) => {
            if (result) {
              var res = JSON.parse(result.string["_"]);
              if (res.Success) {
                let statsArray = JSON.parse(res.Response);
                if (statsArray) {
                  if (exposeStatType === "StatsByCatOrName") {
                    statsArray = statsArray.map((stat: any) => ({
                      ...stat,
                      SearchType:
                        stat.SearchType.toLowerCase() === "categorial"
                          ? "קטגוריאלי"
                          : "שמי",
                    }));
                  }
                  if (Array.isArray(statsArray)) {
                    if (statsArray.length > 0) {
                      setStatB144((current) => ({
                        ...current,
                        isStatEmpty: false,
                        [exposeStatType]: [...statsArray],
                      }));
                    }
                  } else {
                    setStatB144((current) => ({
                      ...current,
                      isStatEmpty: false,
                      [exposeStatType]: statsArray,
                    }));
                  }
                }
              }
            }
          });
        })
        .catch((error) => {});
    },
    []
  );

  return (
    <StatisticContext.Provider
      value={{
        statProducts,
        statProductsPrev,
        actionSummery,
        statB144,
        initStatProductsContext,
        initActionSummeryContext,
        initStatB144Context,
        //loading,
      }}
    >
      {props.children}
      {/* <CustomLoader
        isOpen={loading}
        onDidDismiss={() => {
          setLoading(false);
        }}
      ></CustomLoader> */}
    </StatisticContext.Provider>
  );
};

export default ProductsContextProvider;
