import React from "react";
import background from "./app-business-page.webp";
import Highlighter from "../../components/Highlighter/Highlighter";

export default function AppBusinessPage() {
  return (
    <div style={{ position: "relative", marginTop: "2.2%" }}>
      <img src={background} alt="App business page" width="100%" />
      <Highlighter
        title="דף עסק"
        text="טקסט"
        style={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          top: "12%",
          height: "10%",
          width: "90%",
        }}
      />
    </div>
  );
}
