import React, {
  CSSProperties,
  ElementRef,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'
import XIcon from '../../../../../components/icons/XIcon'
import styles from './Highlighter.module.scss'

type Props = {
  title: string
  text: string
  style?: CSSProperties
  children?: ReactNode
}

export default function Highlighter({ title, text, style, children }: Props) {
  const [visible, setVisible] = useState(false)
  const highlighterRef = useRef<ElementRef<'div'>>(null)

  useEffect(() => {
    if (!visible) return

    const clickHandler = (ev: MouseEvent) => {
      if (!highlighterRef.current?.contains(ev.target as Node))
        setVisible(false)
    }

    document.addEventListener('click', clickHandler)
    return () => document.removeEventListener('click', clickHandler)
  }, [highlighterRef, visible])

  return (
    <>
      {/* {visible && (
        <div
          className={styles.backdrop}
          // hidden={!visible}
          onClick={() => setVisible(false)}
        />
      )} */}
      <div
        className={styles.highlighter}
        ref={highlighterRef}
        data-visible={visible}
        style={style}
        onClick={(e) => {
          e.currentTarget.scrollIntoView({ behavior: 'smooth' })
          setVisible(true)
        }}
      >
        <div
          hidden={visible}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '1em',
            height: '1em',
            borderRadius: '9999px',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold',
            background: 'blue',
            fontSize: '14px',
          }}
        >
          i
        </div>
        {children && <div className={styles.container}>{children}</div>}
        <div className={styles.popover}>
          <div className={styles.triangle} />
          <button
            className={styles.close}
            onClick={(ev) => {
              ev.stopPropagation()
              setVisible(false)
            }}
          >
            <XIcon className={styles.x} />
          </button>
          <div className={styles.title}>{title}</div>
          <p className={styles.text}>{text}</p>
        </div>
      </div>
    </>
  )
}
