import {
  IonCard,
  IonItem,
  IonCardContent,
  IonLabel,
  IonIcon,
  IonCol,
  IonRow,
} from "@ionic/react";
import React from "react";
import { isMobile } from "react-device-detect";
import { usePriceListContext } from "../../../data/PriceListContextProvider";
import { edit } from "../../../images";

const MiniMemberPriceList = () => {
  const { MemberPriceList } = usePriceListContext();
  let memberPriceList =
    MemberPriceList &&
    MemberPriceList.PriceListItems.map((priceListItem: any, i: any) => {
      let price;
      if (priceListItem.LiteralPrice) {
        price = priceListItem.LiteralPrice;
      } else {
        if (priceListItem.MinPrice && priceListItem.MaxPrice) {
          price =
            priceListItem.MinPrice + priceListItem.MaxPrice === 0
              ? "ללא תשלום"
              : priceListItem.MinPrice === priceListItem.MaxPrice
              ? `${priceListItem.MinPrice.toLocaleString()} ₪`
              : `${priceListItem.MinPrice.toLocaleString()} - ${priceListItem.MaxPrice.toLocaleString()} ₪`;
        } else if (!priceListItem.MinPrice && priceListItem.MaxPrice) {
          price = `עד ${priceListItem.MaxPrice.toLocaleString()} ₪`;
        } else if (priceListItem.MinPrice && !priceListItem.MaxPrice) {
          price = `מ ${priceListItem.MinPrice.toLocaleString()} ₪`;
        } else {
          price = "ללא תשלום";
        }
      }
      return (
        <IonRow key={i}>
          <IonCol >
            <IonItem lines="none">
              <IonCol size={isMobile ? undefined : "4"} style={{paddingLeft:7}} className="ellipsis">{priceListItem.Title}</IonCol>
             {!isMobile && <IonCol size={"4"} style={{paddingLeft:7}} className="ellipsis">
                {priceListItem.AdditionalData1}
              </IonCol>}
              <IonCol size="4" className={"ion-text-nowrap " + (isMobile && "ellipsis")}>{price}</IonCol>
            </IonItem>
          </IonCol>
        </IonRow>
      );
    });

  return (
    <IonCard>
      <IonCardContent>
        <IonItem lines="none">
          <IonIcon icon={edit} slot="start" />
          <IonLabel>
            <h2 className="md-title bold-text">המחירון שלי</h2>
          </IonLabel>
        </IonItem>
        <IonItem lines="none" style={{ fontWeight: "bold" }}>
          <IonCol size={isMobile ? undefined : "4"}>תיאור מוצר</IonCol>
          {!isMobile && <IonCol size={"4"}>הערה למוצר</IonCol>}
          <IonCol size="4">מחיר</IonCol>
        </IonItem>
        {memberPriceList}
      </IonCardContent>
    </IonCard>
  );
};

export default MiniMemberPriceList;
