import React from "react";
import { IonCol, IonImg, IonItem, IonLabel, IonRow } from "@ionic/react";
import "./tips.scss";

interface tipItem {
  tipTitle: string;
  tipDes: string;
  tipImg: string;
}

const Tips: React.FC<{ tipsTitle: string; cardData: tipItem[] }> = (props) => {
  return (
    <IonItem
      target="_blank"
      href="https://digital.b144.co.il/packages/"
      lines="none"
      className="section-item tip"
      onClick={() => {
        SetDtlPushEvtOutBound('outbound_links','https://digital.b144.co.il/packages/');
        }
      }
    >
      <IonLabel>
        <h2 className="bold-text">{props.tipsTitle}</h2>
        <IonRow>
          {props.cardData.map((tipItem: tipItem, index) => {
            return (
              <IonCol
                key={index}
                size="6"
                className="ion-align-self-start ion-no-padding"
              >
                <IonRow>
                  <IonCol size="6" className="ion-no-padding">
                    <IonImg className="tip-img" src={tipItem.tipImg} />
                  </IonCol>
                  <IonCol size="6">
                    <h3 className="small-title bold-text">
                      {tipItem.tipTitle}
                    </h3>
                    <p>{tipItem.tipDes}</p>
                  </IonCol>
                </IonRow>
              </IonCol>
            );
          })}
        </IonRow>
      </IonLabel>
    </IonItem>
  );
};
export default Tips;
