import React, {
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  isPlatform,
} from "@ionic/react";

import MemberDetailsContext from "../../../data/MemberDetailes-context";
import { close } from "ionicons/icons";
import CustomModal from "../../utils/CustomElements/CustomModal/CustomModal";
import CustomReactTextFormat from "../../utils/CustomElements/CustomReactTextFormat";
import CustomInput from "../../utils/CustomElements/CustomInput";
import CustomPublishButton from "../../utils/CustomElements/CustomPublishButton";
import CustomForm from "../../utils/CustomElements/CustomForm";
import { useForm } from "react-hook-form";
import CustomDeleteButton from "../../utils/CustomElements/CustomDeleteButton";
import { HeaderParameters } from "../../../data/Header-Context";

const BusinessLinks: React.FC<{
  show: boolean;
  closeModal: () => void;
  onSave: () => void;
}> = (props) => {
  const { handleSubmit, formState, errors, control } = useForm({
    mode: "onChange",
  });
  const { dirty } = formState;
  const MemberDetailsCxt = useContext(MemberDetailsContext);
  useEffect(() => {
    if (MemberDetailsCxt.BusinessLinks == null) {
      MemberDetailsCxt.initBusinessLinksContext();
    }
  }, []);
  let businessLinks = MemberDetailsCxt.BusinessLinks;
  const selectedLinkIndexRef = useRef<number | null>(null);
  let selectedLinkRef = useRef<HTMLIonItemSlidingElement | null>(null);
  const descriptionTextRef = useRef<HTMLIonInputElement>(null);
  const linkTextRef = useRef<HTMLIonInputElement>(null);

  const deleteLink = () => {
    if (businessLinks) {
      const linkIndex = selectedLinkIndexRef.current!;
      businessLinks[linkIndex].isDelete = true;
      MemberDetailsCxt.saveLinksHandler(businessLinks[linkIndex]);
    }
  };
  const saveHandler = () => {
    const newcription = descriptionTextRef.current!.value!.toString();
    const newlink = linkTextRef.current!.value!.toString();
    let linkObj = {
      Address: newlink,
      Description: newcription,
      LinksNum: -99,
      isDelete: false,
    };
    MemberDetailsCxt.saveLinksHandler(linkObj);
    props.closeModal();
  };

  const headerParameters: HeaderParameters = {
    isPageHaveChanges: dirty,
    modalType: "modal",
  };
  return (
    <CustomModal
      pageName={"BusinessLinks"}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
      headerParameters={headerParameters}
      title="קישורים לנכסים דיגיטליים"
      subTitle="עשו עליך כתבה, יש לך סרטון מגניב ביוטיוב? כאן המקום להכניס קישור ולשתף את זה עם העולם"
    >
      <CustomForm onSubmit={handleSubmit(saveHandler)} errors={errors}>
        <IonGrid>
          <IonRow className="ion-margin-bottom">
            <IonCol>
              <CustomInput
                name="linl"
                label="הוספת קישור"
                errors={errors}
                control={control}
                type="url"
                required={true}
                ref={linkTextRef}
                value={""}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-no-padding">
              <CustomInput
                name="linkDesc"
                errors={errors}
                control={control}
                maxlength={2000}
                required={true}
                ref={descriptionTextRef}
                value={""}
                label="תאור לינק"
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel className="small-title bold-text">
                קישורים שהוספת
              </IonLabel>
            </IonCol>
          </IonRow>
          {businessLinks &&
            businessLinks.length > 0 &&
            businessLinks.map((link, index) => {
              const myRef = createRef<HTMLIonItemSlidingElement>();
              return (
                <IonRow key={index} className="ion-align-items-center">
                  <IonCol size="1" className="ion-no-padding">
                    <CustomDeleteButton
                      icon={close}
                      fillClear={true}
                      sizeSmall={true}
                      onDelete={() => {
                        selectedLinkIndexRef.current = index;
                        selectedLinkRef.current = myRef.current;
                        deleteLink();
                      }}
                    />
                  </IonCol>
                  <IonCol size="4" className="text-wrap link ion-no-padding">
                    {link.Description}
                  </IonCol>
                  <IonCol
                    size="7"
                    className="text-wrap ltr link ion-no-padding"
                  >
                    <CustomReactTextFormat>
                      {link.Address}
                    </CustomReactTextFormat>
                  </IonCol>
                </IonRow>
              );
            })}

          <IonRow className="ion-text-center">
            <IonCol>
              <CustomPublishButton title="שמור"></CustomPublishButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </CustomForm>
    </CustomModal>
  );
};

export default BusinessLinks;
