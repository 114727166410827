import React from 'react';

export interface iProduct {
  Chosen: string;
  Id: string;
  ImagePath: string;
  Name: string;
  Description: string;
  LongDescription: string; //use as link || button name
  Price: string;
  CatNumber: string;
  CurrencySign: string;
  FileName: string;
  FileId: string;
  Base64Image: string;
  LinkButtonText: string;
}

export interface MemberProduct {
  Id: number;
  Active: boolean;
  Name: string;
  Price: number;
  Description: string;
  LongDescription: string;

  ImagePath: string;
  ImageDesc: string;
  FileId: number;
  Base64Image: string;

  CatNumber: string;
}

export enum GoogleMerchantEnum {
  None = 0, //allow google shop
  Pending = 1, //for remove by angine
  Created = 2, // waiting for varification
  Fail = 3,
  Verified = 4, // shop active
  Disabled = 5 //not allowed
}
export enum OrderStatus {
  New = 0, Handling = 1,Sent = 2,Canceled = 3,Ready = 4
}
export const OrderStatusDesc: {
  [key: number]: string;
} = {
  0: 'חדשה',
  1: 'הטיפול',
  2: 'נשלחה',
  3: 'מבוטלת',
  4: 'מוכנה',
 
};
export interface MemberOrder {
  Id: number;
  OrderNumber: number;
  Date: string;
  Status: OrderStatus; // New = 0, Handling = 1,Sent = 2,Canceled = 3,Ready = 4,
  TotalPrice: number;
}

export interface MemberOrderDetailed extends MemberOrder {
  Products: OrderProduct[];
 FullName : string;
  Address : string;
   Email: string;
   Phone: string;
 Remarks: string;
}
export interface OrderProduct {
  ProductId: number;
  Name: string;
  Quantity: number;
  TotalCost: number;
  CatalogNumber: string;
}

export interface MemberShipping {
  Id: number;
  Name: string;
  Cost: number;
  MaxHandlingTime: number;
  MinHandlingTime: number;
  MaxTransitTime: number;
  MinTransitTime: number;
}

const ProductsContext = React.createContext<{
  Products: iProduct[] | undefined;
  saveProduct: (product: iProduct) => void;
  initProductsContext: (useLoading: boolean) => void;
  deletedProductsHandler: (deletedProducts: string[]) => void;
  uploadProduct: (product: iProduct) => void;
  MemberProducts: MemberProduct[] | undefined;
  TotalOrders: number | undefined,
  NewOrders: number | undefined,
  initMemberProductsContext: (useLoading: boolean) => void;
  saveMemberProduct: (product: MemberProduct) => void;
  deletedMemberProductsHandler: (deletedProducts: string[]) => void;
  MemberOrders: MemberOrder[] | undefined;
  MemberOrderDetailed: MemberOrderDetailed | undefined;
  initMemberOrdersContext: (useLoading: boolean) => void;
  getMemberOrder: (orderId: number) => void;
  updateOrderStatus: (orderId: number, status: number) => void;
  GoogleMerchantStage: GoogleMerchantEnum;
  GoogleShopVerificationCode: string | undefined;
  openGoogleShop: () => void;
  sendGoogleShopVerificationCode:( mobilePhone:string)=> void;
  verifyGoogleShop:( smsCode:string)=> void;
  resetGoogleShopVerificationCode: ()=> void;
  googleMerchantStageChanges: boolean;
  setGoogleMerchantStageChanges:(changes: boolean)=> void;
  MemberShippings: MemberShipping[] | undefined;
  saveMemberShipping: (shopping:MemberShipping) => any;
  //getMemberShipping: () => any;
}>({
  Products: [],
  saveProduct: () => {},
  deletedProductsHandler: () => {},
  initProductsContext: () => {},
  uploadProduct: () => {},
  MemberProducts: [],
  TotalOrders: undefined,
  NewOrders: undefined,
  initMemberProductsContext: () => {},
  saveMemberProduct: () => {},
  deletedMemberProductsHandler: () => {},
  MemberOrders: [],
  MemberOrderDetailed: undefined,
  initMemberOrdersContext: () => {},
  getMemberOrder: () => {},
  updateOrderStatus: () => {},
  GoogleMerchantStage: GoogleMerchantEnum.None,
  GoogleShopVerificationCode: undefined,
  openGoogleShop: () => {},
  sendGoogleShopVerificationCode:() => {},
  verifyGoogleShop:() => {},
  resetGoogleShopVerificationCode: ()=> {},
  googleMerchantStageChanges: false,
  setGoogleMerchantStageChanges:()=> {},
  MemberShippings:[],
  saveMemberShipping:()=> {},
 // getMemberShipping:()=> {},
});

export default ProductsContext;
