import React, { useState } from "react";
import { config } from "../Constants";
import { fetchRequestNode } from "../utils-ts/fetchRequest";

export const checkIfGeneratedCode = () => {
  return new Promise<boolean>((resolve, reject) => {
    var data = new URLSearchParams({}).toString();
    fetchRequestNode(
      `${config.url.Node_API_URL}/shareApi/checkIfGeneratedCode`,
      "post",
      data
    )
      .then((response: any) => {
        var res = JSON.parse(response);
        const data = res.data;
        if (res.Success && data > 0) {
          resolve(true);
        }
        resolve(false);
      })
      .catch(function (error) {
        console.log(error);
        resolve(false);
      });
  });
};

export const generateQrCode = () => {
  return new Promise<boolean>((resolve, reject) => {
    var data = new URLSearchParams({}).toString();
    fetchRequestNode(
      `${config.url.Node_API_URL}/shareApi/generateQrCode`,
      "post",
      data
    )
      .then((response: any) => {
        var res: Array<any> = JSON.parse(response);
        if (res != null) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  });
};

export const updateQrDownload = () => {
  return new Promise<boolean>((resolve, reject) => {
    var data = new URLSearchParams({}).toString();
    fetchRequestNode(
      `${config.url.Node_API_URL}/shareApi/updateQrDownload`,
      "post",
      data
    )
      .then((response: any) => {
        var res: Array<any> = JSON.parse(response);
        if (res != null) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  });
};
