// React
import React, { createRef, useContext, useEffect, useRef, useState } from "react";
import { IonCol, IonImg, IonItem, IonRow } from "@ionic/react";

import { blankProductIcon } from "../../../images";
// Context
import UserContext from "../../../data/Auth-context";

// Component
import MemberDetailsContext, { BusinessCertificateItem } from "../../../data/MemberDetailes-context";
import AddEditCertificate from "./AddEditCertificate";
import CustomItemSliding from "../../utils/CustomElements/CustomItemSliding";

const MemberCertificates: React.FC<{
  isAddMemberCertificateClick: boolean;
  onIsAddMemberCertificatesClick: () => void;
  isMemberCertificateSelected: (isMemberNewsSelected: boolean) => void;
}> = (props) => {
  const { authValues } = useContext(UserContext);
 
  const MemberDetailsCxt = useContext(MemberDetailsContext);

  const [selectedCertificateItem, setSelectedCertificateItem] = useState<BusinessCertificateItem>();
 
  const [isAddEditing, setIsEditing] = useState(false);

  let selectedSlideRef = useRef<HTMLIonItemSlidingElement | null>(null);
  useEffect(() => {
    if(!MemberDetailsCxt.BusinessCertificates || !MemberDetailsCxt.CertificateIssuerTypes){
    MemberDetailsCxt.getBusinessCertificates(true);
  }
  }, []);
  useEffect(() => {
    if (props.isAddMemberCertificateClick === true) {
      setIsEditing(true);
      setSelectedCertificateItem(undefined);
      props.onIsAddMemberCertificatesClick();
    }
  }, [props.isAddMemberCertificateClick]);

  const deleteItemHandler = (id:number) => {
    setIsEditing(false);
    MemberDetailsCxt.deleteBusinessCertificate(id);
    
  };

  const saveItemHandler = (item:BusinessCertificateItem) => {
    
    MemberDetailsCxt.saveBusinessCertificate(item);
    
  };


  return (
    <React.Fragment>
      <IonRow>
        <IonCol className="ion-padding-bottom">
          <p className="small-p">
            הוספת תעודות המסכה תשדר אמינות ומקצועיות ללקוחות שלך ויגרמו להם לבחור בך
          </p>
        </IonCol>
      </IonRow>
      {(!MemberDetailsCxt.BusinessCertificates || MemberDetailsCxt.BusinessCertificates.length == 0)  && (
        <IonRow>
          <IonCol className="ion-text-center no-items-wraper">
            <IonImg src={blankProductIcon}></IonImg>
            <p className="large-p">אין תעודות והסמכות</p>
          </IonCol>
        </IonRow>
      )}
      {MemberDetailsCxt.BusinessCertificates && MemberDetailsCxt.BusinessCertificates.map((item:BusinessCertificateItem, index:number) => {
const thisRef = createRef<HTMLIonItemSlidingElement>();
return <IonRow key={item.Id}>
<IonCol class="ion-no-padding" style={{marginBottom:16}}>
<CustomItemSliding 
                      onDelete={() => {
                        deleteItemHandler(item.Id);
                        selectedSlideRef.current = thisRef.current;
                      }}
                      onEdit={() => {
                        setIsEditing(true);
                        setSelectedCertificateItem(item);
                        selectedSlideRef.current = thisRef.current;
                      }}
                      key={index}
                      ref={thisRef}
                    >
                      <IonItem lines="none"
                        key={index}
                        onClick={() => {
                          setIsEditing(true);
                          setSelectedCertificateItem(item);
                          selectedSlideRef.current = thisRef.current;
                        }}
                      >
                        <IonCol sizeXs="3" sizeSm="2"  style={{height:'100%',paddingTop:12,paddingBottom:12}}>

                          <div style={{backgroundSize: 'contain',
                                       backgroundRepeat: 'no-repeat',
                                       backgroundPosition: 'center',
                                       height: 70,
                                       width: 70,
                                       backgroundImage: 'url(' + item.ImagePath + ')' } } ></div>
                          </IonCol>
                        <IonCol style={{height:'100%',padding:12,fontSize:14}}>
                        {item.Description}
                          {/* <IonRow>
                            <IonCol class="ion-no-padding ion-text-start"><b style={{color:'#383b41'}}>{item.Description}</b></IonCol>
                            <IonCol class="ion-no-padding ion-text-end"><small dir="ltr">{formatDate(item.DateOfIssue,'DD/MM/YY HH:mm')}</small></IonCol>
                          </IonRow>
                          <IonRow >
                            <IonCol class="ion-no-padding ion-text-start" style={{whiteSpace: 'pre-wrap'}}>
                              <p style={{paddingTop:8}}>
                            {item.QualifiedName}</p></IonCol>
                            <IonCol class="ion-no-padding ion-text-end"><small dir="ltr">{item.LicenseNumber}</small></IonCol>
                            </IonRow> */}

                         
                        </IonCol>
                      </IonItem>
                    </CustomItemSliding>
  
</IonCol>
</IonRow>




      }) }

      {isAddEditing && authValues && (
        <AddEditCertificate
        onSave={(item:BusinessCertificateItem)=>{
          saveItemHandler(item);
          setIsEditing(false);
          selectedSlideRef.current?.closeOpened();
        }}
        onDelete={(newsId:number)=> {
          deleteItemHandler(newsId);
          selectedSlideRef.current?.closeOpened();
        }}
        certificateItem={selectedCertificateItem}
          show={isAddEditing}
          closeModal={() => {
            setIsEditing(false);
            selectedSlideRef.current?.closeOpened();
          }}
        />
      )}
    </React.Fragment>
  );
};

export default MemberCertificates;
