import React, { useContext, useEffect, useState } from 'react';
import { IonGrid, IonRow, IonCol, IonItem, IonLabel, IonCheckbox } from '@ionic/react';
import MemberDetailsContext, { BusinessSeoDetailsItem } from '../../../data/MemberDetailes-context';
import CustomPublishButton from '../../utils/CustomElements/CustomPublishButton';
import CustomModal from '../../utils/CustomElements/CustomModal/CustomModal';
import '../businessCardTabs.scss';
import { HeaderParameters } from '../../../data/Header-Context';

import CustomForm from '../../utils/CustomElements/CustomForm';
import { useForm } from 'react-hook-form';
const BusinessSeoBrands: React.FC<{
  show: boolean;
  closeModal: () => void;
  onSave: () => void;
}> = (props) => {
  const { handleSubmit, formState, errors } = useForm({
    mode: 'onChange',
  });
  const { dirty } = formState;
  const [isPageChange, setIsPageChange] = useState(false);
  const MemberDetailsCxt = useContext(MemberDetailsContext);
  const [selectedBrands, setSelectedBrands] = useState<string[] | undefined>();
  useEffect(() => {

    if (MemberDetailsCxt.BusinessSeoDetails == null) {
      MemberDetailsCxt.initBusinessSeoDetailsContext();
    }

    if (MemberDetailsCxt.SeoDetails == null) {
      MemberDetailsCxt.initSeoDetailsContext();
    }

  }, []);

  useEffect(() => {
    if (MemberDetailsCxt.BusinessSeoDetails) {
      const filterArray = MemberDetailsCxt.BusinessSeoDetails?.filter((brnd,i) => brnd.Type == 4 && brnd.SeoRunId).map((brnd) => brnd.SeoRunId!);
      setSelectedBrands(filterArray);
    }
  }, [MemberDetailsCxt.BusinessSeoDetails]);

  useEffect(() => {
    
    const originalArray = MemberDetailsCxt.BusinessSeoDetails?.filter((brnd,i) => brnd.Type == 4 && brnd.SeoRunId).map((brnd) => brnd.SeoRunId!);
    if(originalArray == selectedBrands){
      setIsPageChange(false);
    }else if(originalArray  && selectedBrands ){
    const isChange = checkIsChanged(selectedBrands,originalArray);

    setIsPageChange(isChange);
  }
  }, [selectedBrands]);

  const saveHandler = () => {
if(selectedBrands != undefined && MemberDetailsCxt.BusinessSeoDetails != undefined && MemberDetailsCxt.SeoDetails != undefined){
    
    
    //items2delete
    const dlt_items =  MemberDetailsCxt.BusinessSeoDetails.filter((brnd,i) => brnd.Type == 4 && brnd.SeoRunId && selectedBrands.indexOf(brnd.SeoRunId) < 0)
                                                .map((brnd) => {
                                                  let seoObj:BusinessSeoDetailsItem = {
                                                    Text: brnd.Text,
                                                    Type: 4,
                                                    Id: brnd.Id,
                                                    SeoRunId: brnd.SeoRunId,
                                                    isDelete: true,
                                                  };
                                                  return seoObj;
                                                });
//items2add
let add_items = MemberDetailsCxt.SeoDetails.filter((brnd,i) => brnd.Type == 4 && brnd.Id && selectedBrands.indexOf(brnd.Id.toString()) >= 0)
.map((brnd) => {
  const existing_id = MemberDetailsCxt.BusinessSeoDetails!.find(b => b.SeoRunId == brnd.Id.toString());
  let seoObj:BusinessSeoDetailsItem = {
    Text: brnd.Text,
    Type: 4,
    Id: existing_id ? existing_id.Id : -1,
    SeoRunId: brnd.Id.toString(),
    isDelete: false,
  };
  return seoObj;
});

const items: BusinessSeoDetailsItem[] = [...add_items,...dlt_items];
    
     MemberDetailsCxt.updateMemberSeoBrandsHandler( items);

    props.onSave();
    props.closeModal();}
  };

  const headerParameters: HeaderParameters = {
    modalType: 'modal',
    isPageHaveChanges: dirty || isPageChange,
  };

  function checkIsChanged( arrA:string[], arrB:string[] ){

    //check if lengths are different
    if(arrA.length !== arrB.length) return false;


    //slice so we do not effect the original
    //sort makes sure they are in order
    //join makes it a string so we can do a string compare
    var cA = arrA.slice().sort().join(","); 
    var cB = arrB.slice().sort().join(",");

    return cA===cB;

}

  return (
    <CustomModal
      pageName={'BusinessAdvCategories'}
      headerParameters={headerParameters}
      closeModal={() => {
        props.closeModal();
      }}
      title="מותגים"
      subTitle="כאן תוכלו לסמן לאיזה מותגים אתם נותנים שירות המידע יופיע בסמוך לתאור העסק בכרטיס"
      isOpen={props.show}>
      <CustomForm onSubmit={handleSubmit(saveHandler)} errors={errors}>
        {MemberDetailsCxt.SeoDetails && (
          <IonGrid>
            <IonRow>
              <IonCol>
                {MemberDetailsCxt.SeoDetails.filter((t)=> t.Type == 4).map((brnd, index) => {
                  return (
                    <IonItem key={index} className="toggle-token" mode="ios">
                      <IonCheckbox
                        className="ion-no-margin"
                         value={brnd.Id.toString()}
                        checked={selectedBrands && selectedBrands.find((b) => b == brnd.Id.toString()) != null} 
                        onIonChange={(e) => {
                          setSelectedBrands((current)=>{
                            
                            let updatedBrands = current ? [...current] : [];  
                            if(e.detail.checked){
                              //add
                              const existIndex = updatedBrands.findIndex(r => r == e.detail.value);
                              if(existIndex < 0){
                              updatedBrands.push(e.detail.value);
                              }
                            } else{
                              //remove
                              updatedBrands = updatedBrands.filter(r => r != e.detail.value)
                            }
                            return updatedBrands;
                          });
                         
                        }}
                      />
                      <IonLabel className="ion-no-margin">
                        <p>{brnd.Text}</p>
                      </IonLabel>
                    </IonItem>
                  );
                })}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <CustomPublishButton />
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </CustomForm>
    </CustomModal>
  );
};

export default BusinessSeoBrands;
