import React, {
  useContext, useEffect, useRef, useState
} from "react";
import {
  IonRow,
  IonCol,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonRadio,
  IonIcon,
  IonChip,
  IonCard,
  IonInput,
} from "@ionic/react";
import { toast } from "react-toastify";
import { isMobile } from 'react-device-detect';
import SelfSaleContext, { RequestResult } from "../../../data/SelfSaleContext";
import CustomInputGroup from "../CustomElements/CustomRadioGroup";
import CustomTextInput from "../CustomElements/CustomTextInput";

const AdsAdditional: React.FC<{ completed: boolean, onComplete?: (phaseId: number, valid: RequestResult) => void }> = (props) => {
  const SelfSaleCtx = useContext(SelfSaleContext);
  const [selectedActionCallAndConsultant, setSelectedActionCallAndConsultant] = useState<string>("0");
  const [selectedService24, setSelectedService24] = useState<string>("0");
  const [selectedDiscount, setSelectedDiscount] = useState<string>("");
  const [selectedAdditionalValue, setSelectedAdditionalValue] = useState<string>("0");
  const [keywordsNotes, setKeywordsNotes] = useState<string>("");
  const [generalNotes, setGeneralNotes] = useState<string>("");
  const [freeText, setFreeText] = useState<string>("");
  const [customDiscount, setCustomDiscount] = useState<string>("");
  const adsAdditionalList = [
    {
      value: "attractive_prices",
      label: "מחירים אטרקטיביים",
    },
    {
      value: "experienced_team",
      label: "צוות מנוסה",
    },
    {
      value: "best_service",
      label: "שירות ברמה הגבוהה ביותר",
    },
    {
      value: "professional_service",
      label: "שירות מקצועי",
    },
    {
      value: "no_adv",
      label: "ללא יתרונות",
    },
    {
      value: "free_text",
      label: "אחר",
    },
  ]
  const actionCallAndConsultant = [
    {
      value: "1",
      label: "התקשרו עכשיו"
    },
    {
      value: "-2", // not exist
      label: "השאירו פרטים"
    },
    {
      value: "4", // consultant = "1"
      label: "לפגישת יעוץ בחינם השאירו פרטים"
    },
    {
      value: "-1",  // not exist
      label: "לפתיחת צ׳ט ווטסאפ"
    },
    {
      value: "2",
      label: "בואו לבקר"
    },
    {
      value: "5",  // consultant = "2"
      label: "חייגו עכשיו ליעוץ טלפוני חינם"
    },
    {
      value: "0",
      label: "ללא קריאה לפעולה"
    },
  ]
  const service24 = [
    {
      value: "7",
      label: "שירות 24/7 מסביב לשעון (כולל שישי שבת ולילות)"
    },
    {
      value: "5",
      label: "שירות 24/5 מסביב לשעון (כולל לילות)"
    },
    {
      value: "0",
      label: "ללא שירות מסביב לשעון"
    },
  ]
  const discounts = [
    {
      value: "5",
      label: "5%"
    },
    {
      value: "10",
      label: "10%"
    },
    {
      value: "15",
      label: "15%"
    },
    {
      value: "100",
      label: "אחר"
    },
  ]
  useEffect(() => {
    SelfSaleCtx.getGoogleAdsAdditional()
      .then((res: any) => {
        if (res) {
          let notes = res.notes.split("^^^")
          let keywordsTxt = notes[0]
          setKeywordsNotes(keywordsTxt)
          let txt = notes.length > 1 ? notes[1].replace("הערות כלליות: ", "") : "";
          setGeneralNotes(txt)
          setFreeText(res.free_text)
          if (res.discount_pct !== "0") {
            setCustomDiscount(res.discount_pct)
          }
          if (res.action_call === "1" || res.action_call === "2") {
            setSelectedActionCallAndConsultant(res.action_call)
          }
          if (res.is_consultency === "1") {
            setSelectedActionCallAndConsultant("4")
          }
          else if (res.is_consultency === "2") {
            setSelectedActionCallAndConsultant("5")
          }
          if (res.attractive_prices || res.experienced_team || res.best_service || res.professional_service) {
            let procsValue = res.attractive_prices ? "attractive_prices" : res.experienced_team ? "experienced_team" : res.best_service ? "best_service" : res.professional_service ? "professional_service" : "";
            setSelectedAdditionalValue(procsValue)
          }
          else if (res.free_text !== ""){
            setSelectedAdditionalValue("free_text")
          }
          else {
            setSelectedAdditionalValue("no_adv")
          }

          if (res.discount_pct !== "" && Number(res.discount_pct) > 15) {
            setSelectedDiscount('100')
          }
          else {
            setSelectedDiscount(res.discount_pct)
          }
          setSelectedService24(res.is_service_24)
        }
      })
  }, [])

  useEffect(() => {
    if (props.completed) {
      let errors: string[] = [];

      if (selectedDiscount === "100") {
        if (customDiscount === undefined) {
          errors.push("יש להזין אחוז הנחה")
        }
        else if (Number(customDiscount) < 1 || Number(customDiscount) > 99) {
          errors.push("יש להזין אחוז הנחה 1-99")
        }
      }
     
      if (errors.length) {
        props.onComplete && props.onComplete(6, { Success: false, ResultData: "", ResultDesc: errors.join('<br/>') })
      }
      else {
        let summaryArray = []
        let actionCallText = actionCallAndConsultant.find(i => i.value === selectedActionCallAndConsultant)?.label
        let procsText = adsAdditionalList.find(i => i.value === selectedAdditionalValue)?.label
        let discountText = selectedDiscount === '100' ? customDiscount : selectedDiscount || ""
        if (selectedActionCallAndConsultant !== "0") {
          summaryArray.push(`קריאה לפעולה: ${actionCallText}`)
        }
        else if (actionCallText !== "ללא קריאה לפעולה") {
          summaryArray.push(actionCallText)
        }
        if (discountText !== "" && discountText !== "0") {
          summaryArray.push(`אחוז הנחה: ${discountText}%`)
        }
        if (selectedAdditionalValue === "free_text" && freeText !== "") {
          summaryArray.push(`יתרונות: ${freeText}`)
        }
        else if (procsText !== "אחר") {
          summaryArray.push(`יתרונות: ${procsText}`)
        }
        if (selectedService24 !== "0") {
          summaryArray.push(service24.find(i => i.value === selectedService24)?.label)
        }
        SelfSaleCtx.setGoogleAdsAdditionalSummary(summaryArray.join(", "))

        let consultency = selectedActionCallAndConsultant === "4" ? "1" : selectedActionCallAndConsultant === "5" ? "2" : "";
        let actionCall = (selectedActionCallAndConsultant === "1" || selectedActionCallAndConsultant === "2") ? selectedActionCallAndConsultant : "";
        let obj = {
          action_call: actionCall,
          attractive_prices: selectedAdditionalValue === "attractive_prices",
          best_service: selectedAdditionalValue === "best_service",
          discount_pct: selectedDiscount === '100' ? customDiscount : selectedDiscount || "",
          experienced_team: selectedAdditionalValue === "experienced_team",
          notes: generalNotes === undefined ? "" : keywordsNotes + '^^^הערות כלליות: ' + generalNotes,
          is_consultency: consultency,
          is_service_24: selectedService24 || "",
          free_text: freeText === undefined ? "" : freeText,
          professional_service: selectedAdditionalValue === "professional_service"
        }
        SelfSaleCtx.saveGoogleAdsAdditional(obj).then((res: RequestResult) => {
          props.onComplete && props.onComplete(6, res)
        })
      }
    }
  }, [props.completed])

  return (
    <>
      {/* <IonCard mode="md" className={isMobile ? "self-sale-card self-sale-card-mobile self-sale-card-sm" : "self-sale-card self-sale-card-sm"}>
        <p className="ion-no-margin self-sale-section-subtitle">אחוזי הנחה שהעסק רוצה לתת באמצעות המודעה</p>
        <div className="discount-group">
          <CustomInputGroup value={selectedDiscount} name="discounts" options={discounts} setValue={(val) => setSelectedDiscount(val)} />
          <div style={{ display: selectedDiscount === "100" ? "flex" : "none", alignItems: "center" }}><span>אחוז הנחה:</span><IonInput placeholder="1-99" name="customDiscount" type="number" value={customDiscount} onIonChange={(e:any) => setCustomDiscount(e.detail.value)} className="customDiscount"></IonInput></div>
        </div>
      </IonCard> */}

      <IonCard className={isMobile ? "self-sale-card self-sale-card-mobile action-call-consultant-card self-sale-card-sm" : "self-sale-card action-call-consultant-card self-sale-card-sm"}>
        <p className="ion-no-margin self-sale-section-subtitle google-ads">קריאה לפעולה במודעה</p>
        <CustomInputGroup value={selectedActionCallAndConsultant} name="actionCallAndConsultant" options={actionCallAndConsultant} setValue={(val) => setSelectedActionCallAndConsultant(val)} multichoise={false} />
      </IonCard>

      <IonCard className={isMobile ? "self-sale-card self-sale-card-mobile ads-additional-card self-sale-card-sm" : "self-sale-card ads-additional-card self-sale-card-sm"}>
        <p className="ion-no-margin self-sale-section-subtitle google-ads">יתרונות העסק שלך</p>
        <CustomInputGroup multichoise={false} value={selectedAdditionalValue} name="additional" options={adsAdditionalList} setValue={(val) => setSelectedAdditionalValue(val)} />
        <div style={{ display: selectedAdditionalValue === "free_text" ? "block" : "none" }}> <CustomTextInput customClass="small" maxlength={25} placeholder="הקלידו טקסט" name="freeText" value={freeText} onChange={(e) => setFreeText(e.detail.value)} /></div>
      </IonCard>

      <IonCard mode="md" className={isMobile ? "self-sale-card self-sale-card-mobile self-sale-card-sm" : "self-sale-card self-sale-card-sm"}>
        <p className="ion-no-margin self-sale-section-subtitle google-ads" style={{marginBottom: '15px '}}>שירות מסביב לשעון</p>
        <CustomInputGroup direction="column" value={selectedService24} name="service24" options={service24} setValue={(val) => setSelectedService24(val)} />
      </IonCard>

      <IonCard mode="md" className={isMobile ? "self-sale-card self-sale-card-mobile ads-notes-card self-sale-card-sm" : "self-sale-card ads-notes-card self-sale-card-sm"}>
        <p className="ion-no-margin self-sale-section-subtitle google-ads">הערות</p>
        <CustomTextInput rows={3} maxlength={100} placeholder="הקלידו טקסט" name="generalNotes" value={generalNotes} onChange={(e) => setGeneralNotes(e.detail.value)} />
      </IonCard>
      </>
  );
};

export default AdsAdditional;