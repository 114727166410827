import { IonButton, IonCheckbox, IonChip, IonContent, IonIcon, IonImg, IonItem, IonLabel, IonList, IonPopover, IonToast } from "@ionic/react";
import { add } from "date-fns";
import { addOutline, chevronDown, chevronUp, remove } from "ionicons/icons";
import React, { useEffect, useState } from "react";

const SelfSaleFAQ: React.FC<{ saleType: string }> = ({ saleType }) => {
    const [showAll, setShowAll] = useState(false);
    const [shownQuestion, setShownQuestion] = useState<number>(1);
    const [questions, setQuestions] = useState<{
        id: number;
        question: string;
        answer: string;
    }[]>([]);
    const questionsB144 = [
        {
            id: 1,
            question: "איזה מידע מומלץ כרטיס העסק ב – B144 יכלול?",
            answer: "חשוב שכרטיס העסק שלך במדריך העסקי של B144 יכלול את מרבית המידע אודות העסק שלך: תיאור העסק, תחומי פעילות, ימים ושעות פתיחה, מפת הגעה, קופונים, תגובות לקוחות, גלריות תמונות ותיאור מוצרים. כל זאת ועוד ניתן לעדכן באמצעות ממשק המפרסם העומד לשירותך."
        },
        {
            id: 2,
            question: "כיצד פרסום ב B144 מקדם את העסק שלי?",
            answer: "במדריך B144 העסק שלך נהנה מחשיפה גבוהה בתוצאות החיפוש. כמו כן, תחום העסק שלך מפורסם בגוגל לפי ביטויי חיפוש רלוונטיים ופופולאריים ובאתרים מובילים, ביניהם יד 2, עכבר העיר Time Out , Walla ,Ynet ועוד רבים אחרים."
        },
        {
            id: 3,
            question: "האם אני יכול לקדם ולפרסם את אתר האינטרנט שלי במקביל לפרסום במדריך B144?",
            answer: "בוודאי! שני הפרסומים אינם סותרים זה את זה. קישורים מאתר B144 יכולים אף לסייע לך בקידום במנוע החיפוש של גוגל ובאתר העסקי שלך."
        },
        {
            id: 4,
            question: "מהם יתרונות פרסום העסק שלך במדריך העסקי של B144?",
            answer: "באמצעות פרסום העסק שלך במדריך תוכל לחשוף את העסק למספר רחב של לקוחות פוטנציאליים, לנהל את המידע בכרטיס העסק באמצעות ממשק ניהול ידידותי גם דרך המובייל ולשדרג את תדמית העסק. פרסום העסק שלך יופיע בקטגוריה המתאימה לך (פרחים, מסעדות, רופאים וכן הלאה) ובאמצעותו, העסק ייחשף ללקוחות במגוון ערוצי תקשורת ובאתרי האינטרנט המובילים בישראל."
        },
    ]
    const questionsGoogle = [
        {
            id: 1,
            question: "מה זה גוגל אדוורדס?",
            answer: "גוגל אדוורדס הוא פלטפורמת פרסום ממומן באינטרנט שמאפשרת לעסקים להציג את המודעות שלהם בגוגל ובאתרים אחרים."
        },
        {
            id: 2,
            question: "כמה עולה לפרסם בגוגל?",
            answer: "המחיר של פרסום בגוגל משתנה בהתאם לגורמים רבים, כגון רמת התחרות של מילות המפתח, מיקום המודעה ותקציב המפרסם."
        },
        {
            id: 3,
            question: "מהו המחיר המינימלי של פרסום גוגל אדס?",
            answer: "גוגל אדס מאפשרת לך להגדיר תקציב יומי, ואתה משלם רק על הקליקים במודעה שלך. המחיר המינימלי להקלקה הוא 30 אגורות."
        },
        {
            id: 4,
            question: "האם פרסום בגוגל אדס מתאים לעסק קטן?",
            answer: "בהחלט! פרסום בגוגל אדס מתאים מאוד לעסקים קטנים. זהו כלי יעיל וגמיש שיכול לעזור לעסקים קטנים להגיע ללקוחות חדשים ולהגדיל את המכירות, עם תקציב שבחרו מראש."
        },
        {
            id: 5,
            question: "מדוע גוגל אדס לא עובד?",
            answer: "קרוב לוודאי שהקמפיין שלך לא עובד מאחת מהסיבות הבאות: 1. המודעה שלך לא רלוונטית למילות המפתח שבחרת. 2. מודעה לא איכותית. 3. תקציב נמוך מדי ביחס לתחרות. 4. יש בעיות טכניות עם הקמפיין שלך."
        },
        {
            id: 6,
            question: "האם גוגל אדס מתאים גם לפרסום שירותים?",
            answer: "כן. גוגל אדס מתאים לפרסום שירותים לא פחות מלפרסם מוצרים. באמצעותו תוכלו להגיע ללקוחות חדשים ולהגדיל את מספר הלקוחות הפוטנציאליים שלכם."
        },
        {
            id: 7,
            question: "האם אפשר לפרסם לבד בגוגל?",
            answer: "כן, אפשר לפרסם לבד בגוגל. עם זאת, אם אתה חדש בפרסום בגוגל, או אם אין לך את הזמן הדרוש להעמיק ולהתעדכן בהנחיות של גוגל אדס, מומלץ להיעזר במומחה שיוכל לעזור לך לבנות את הקמפיינים שלך ולמקסם את תוצאות הפרסום שלך."
        },
        {
            id: 8,
            question: "מהי הסמכה של גוגל אדס?",
            answer: "הסמכה של גוגל אדס היא אישור מקצועי שגוגל מעניקה לאנשים שמפגינים מומחיות בפרסום בגוגל אדס."
        },
        {
            id: 9,
            question: "אילו כלים פרסומיים כדאי לי לשלב בקמפיין בגוגל?",
            answer: "הכלים הפרסומיים הטובים ביותר לשימוש בקמפיין בגוגל הם אלו שהתאימו ביותר לעסק שלך וליעדים שלך. עם זאת, כמה מהכלים הפופולריים ביותר כוללים קמפיין חיפוש, קמפיין רשת שותפים ומודעות וידאו. חשוב מאוד שיהיה לך אתר אינטרנט אינפורמטיבי ושיווקי, במיוחד אם מדובר בקמפיין למוצר, שירות או מבצע ספציפי."
        },
        {
            id: 10,
            question: "איזה גורמים משפיעים על התמחור של גוגל אדס?",
            answer: "התמחור של גוגל אדס מבוסס על מערכת של מחיר מקסימלי לקליק (CPC). זה אומר שאתה משלם רק כאשר מישהו מקליק על המודעה שלך. הסכום שאתה משלם עבור כל קליק נקבע עי מספר גורמים, כולל: התחרות על כל מילת מפתח, רלוונטיות של המודעה, איכות המודעה ותקציב הפרסום שלך."
        },
        {
            id: 11,
            question: "על פי מה קובעים איך ומה לפרסם בגוגל?",
            answer: "גוגל אדס קובעים איך ומה לפרסם בגוגל על פי: • מטרות העסק: מה העסק רוצה להשיג באמצעות הפרסום? האם רוצים להגביר את המודעות למותג, ליצור לידים או להגדיל מכירות? • קהל היעד: מי קהל היעד של העסק? מה הם מחפשים באינטרנט? מה הם צריכים? מה הם רוצים? • התקציב: כמה העסק מוכן להשקיע בפרסום? • התחרות: כמה מפרסמים אחרים מתחרים על אותן מילות מפתח?"
        },
        {
            id: 12,
            question: "האם המודעה שלי תופיע במקום הראשון בגוגל?",
            answer: "המודעה שלך יכולה להופיע במקום הראשון בגוגל אם היא רלוונטית, איכותית ומציעה ערך למשתמשים וכן מה המחיר שאתה מוכן לשלם עבור הקלקה."
        },
        {
            id: 13,
            question: "כמה זמן לוקח לקמפיין גוגל לעלות לאוויר?",
            answer: "קמפיין גוגל יכול לעלות לאוויר תוך מספר דקות עד כמה ימים, תלוי במספר גורמים, כגון מילות המפתח שבחרת, התקציב שלך ורמת התחרות על מילות המפתח שלך."
        },
        {
            id: 14,
            question: "כמה זמן לוקח להצליח בגוגל אדס?",
            answer: "זמן ההצלחה בגוגל אדס תלוי במספר גורמים, כגון מטרות העסק, קהל היעד, התקציב והתחרות. עם זאת, בדרך כלל לוקח כמה שבועות עד חודשים כדי לראות תוצאות חיוביות."
        },
        {
            id: 15,
            question: "מי משלם לגוגל אדס?",
            answer: "במידה ובחרת להזמין מאיתנו את אחת החבילות של פרסום בגוגל, אנחנו נשלם ישירות לגוגל אדס."
        },
        {
            id: 16,
            question: "כיצד אוכל למשוך עוד אנשים לקמפיין גוגל אדס שלי?",
            answer: "ישנן מספר דרכים למשוך עוד אנשים לקמפיין גוגל אדס שלך, כולל: השתמש במילות מפתח רלוונטיות וממוקדות, כתוב מודעות מעניינות ומניעות לפעולה, הגדר תקציב פרסום מתאים, עקוב אחרי תוצאות הקמפיין שלך וערוך שינויים לפי הצורך."
        },
        {
            id: 17,
            question: "איזה אלטרנטיבות יש לגוגל אדס?",
            answer: "ישנן מספר אלטרנטיבות לגוגל אדס, כולל: פלטפורמות פרסום חברתיות כגון פייסבוק, אינסטגרם וטוויטר, המאפשרות לעסקים לפרסם את המודעות שלהם בפני קהל היעד שלהן. בינג אדס, פלטפורמת פרסום ממומן של מיקרוסופט, אך היקף הפרסום בבינג בישראל הוא מאוד מצומצם. יאהו אדס, פלטפורמת פרסום ממומן של יאהו."
        },
        {
            id: 18,
            question: "האם פרסום בגוגל אדס עוזר לקידום אורגני?",
            answer: "פרסום בגוגל אדס אינו משפיע ישירות על הדירוג האורגני שלך בתוצאות החיפוש."
        },
        {
            id: 19,
            question: "מה ההבדל בין קמפיין פייסבוק לקמפיין גוגל?",
            answer: "קמפיינים של גוגל אדס מופנים לאנשים שמחפשים במפורש אחר משהו, בעוד שקמפיינים של פייסבוק מופנים לאנשים שעשויים להיות מעוניינים במוצר או בשירות שלך, גם אם הם לא מחפשים אותו באופן פעיל."
        }
    ]
    useEffect(() => {
        if (saleType === "1") {
            setQuestions(questionsB144)
        }
        else {
            setQuestions(questionsGoogle)
        }
    }, []);
    const toggleQuestion = (question: number) => {
        const q = shownQuestion === question ? 0 : question
        setShownQuestion(q)
    }

    return (
        <div className="FAQ-div">
            <h2 className="ion-no-margin self-sale-text-blue bold-text">שאלות נפוצות</h2>
            <div className="FAQ-list">
                {showAll ? questions.map((item) => (
                    <div className="FAQ-list-item" key={item.id}>
                        <div className="d-flex justify-content-between align-items-center">
                            <p className="self-sale-text-blue large-p">{item.question}</p>
                            <IonButton fill='clear' onClick={() => toggleQuestion(item.id)}>
                                <IonIcon size="small" icon={shownQuestion === item.id ? remove : addOutline}></IonIcon>
                            </IonButton>
                        </div>
                        {shownQuestion === item.id &&
                            (<div className="FAQ-list-item-hidden">
                                <p>{item.answer}</p>
                            </div>)}
                    </div>
                )) :
                    questions.filter((q, i) => i < 3).map((item) => (
                        <div className="FAQ-list-item" key={item.id}>
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="self-sale-text-blue large-p">{item.question}</p>
                                <IonButton fill='clear' onClick={() => toggleQuestion(item.id)}>
                                    <IonIcon size="small" icon={shownQuestion === item.id ? remove : addOutline}></IonIcon>
                                </IonButton>
                            </div>
                            {shownQuestion === item.id &&
                                (<div className="FAQ-list-item-hidden">
                                    <p>{item.answer}</p>
                                </div>)}
                        </div>
                    ))}
                <div className="ion-text-center">
                    <IonButton fill="clear" onClick={() => setShowAll(!showAll)}>
                        <span className="bold-text"> {showAll ? 'הצג פחות' : 'שאלות נוספות'}</span>
                        <IonIcon size="small" icon={showAll ? chevronUp : chevronDown}></IonIcon>
                    </IonButton>
                </div>
            </div>
        </div>
    )
}
export default SelfSaleFAQ;

function useContext(SelfSaleContext: any) {
    throw new Error("Function not implemented.");
}
