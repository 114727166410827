import React, { useEffect, useState, useContext } from "react";
import { IonButton, IonCard, IonCardContent, IonCheckbox, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonList, IonRow, IonText } from "@ionic/react";
import MemberDetailsContext from "../../data/MemberDetailes-context";
import { Control, FieldError, NestDataObject } from "react-hook-form";
import { closeOutline } from "ionicons/icons";
import SelfServiceContext from "../../data/SelfService-context";

const FrmRegions: React.FC<{
  register: React.Ref<any>;
  control: Control;
  errors?: NestDataObject<Record<string, any>, FieldError>;

}> = props => {

  const MemberDetailsCxt = useContext(MemberDetailsContext);
  const selfCxt = useContext(SelfServiceContext);

  const [error, setError] = useState("");
  const [showRegions, setShowRegions] = useState<boolean>(false);

  useEffect(() => {
    if (props.control &&
      props.control.fieldsRef &&
      props.control.fieldsRef!.current &&
      !props.control.fieldsRef!.current!.regions_arr) {
      props.control.register('regions_arr');
    }
    if (props.control &&
      props.control.fieldsRef &&
      props.control.fieldsRef!.current &&
      props.control.fieldsRef!.current!.regions_arr &&
      props.control.fieldsRef!.current!.regions_arr.ref.value == undefined) {
      props.control.setValue('regions_arr', []);
    }
    if (regions == null) {
      selfCxt.getRegions(false).then((res: any) => {
        let reg_arr: { region_code: number, region_name: string, is_selected: boolean }[] = [];
        if (res) {
          for (let i = 0; i < res.length; i++) {

            reg_arr.push({
              region_code: res[i].City_Code,
              region_name: res[i].City,
              is_selected: isSelected(res[i].City_Code)
            });

          }
        }
        setRegions(reg_arr);
        props.control.setValue('regions_arr', reg_arr.filter(r => { return r.is_selected }).map(r => { return r.region_code }));
      });
    } else {
      //init selected
      if (!regions || regions.filter(r => { return r.is_selected }).length == 0) {
        if (MemberDetailsCxt.BusinessServiceArea && MemberDetailsCxt.BusinessServiceArea.ServiceAreaType == 'region') {
          for (let i = 0; i < MemberDetailsCxt.BusinessServiceArea.ServiceAreasList.length; i++) {
            const region_code = MemberDetailsCxt.BusinessServiceArea.ServiceAreasList[i].City_Code;
            if (region_code < 0) {
              if (regions.filter(r => { return r.region_code == region_code }).length > 0) {
                //set only if code is in list
                setRegionSelected(region_code.toString(), true);
              }
            }
          }
        }
      }
    }
  }, []);


  const isSelected = (region_code: number) => {
    if (MemberDetailsCxt.BusinessServiceArea && MemberDetailsCxt.BusinessServiceArea.ServiceAreaType == 'region') {
      return MemberDetailsCxt.BusinessServiceArea.ServiceAreasList.find(i => { return i.City_Code == region_code }) != null;
    }
    return false;
  };

  const [regions, setRegions] = useState<{ region_code: number, region_name: string, is_selected: boolean }[]>();

  const selectedRegionsChanged = (event: CustomEvent) => {
    setRegionSelected(event.detail.value, event.detail.checked);
  };



  const setRegionSelected = (region_code: string, is_selected: boolean) => {
    setRegions((currentRegions) => {
      const updatedRegions = currentRegions ? [...currentRegions] : [];
      const updatedRegionIndex = updatedRegions.findIndex(r => r.region_code.toString() == region_code);
      if (updatedRegionIndex >= 0) {
        updatedRegions[updatedRegionIndex].is_selected = is_selected;
        props.control.setValue('regions_arr', updatedRegions.filter(r => { return r.is_selected }).map(r => { return r.region_code })); //for outer form ref
      }
      return updatedRegions;
    });
  }

  const toggleRegionsOptions = () => {
    setShowRegions(!showRegions);
  };

  const selectedDisplay = () => {
    if (regions && regions.filter(r => { return r.is_selected }).length > 0) {
      let lbl = '';
      const reg = regions.find(r => { return r.is_selected });
      if (reg) {
        lbl = reg.region_name;
      }
      if (regions.filter(r => { return r.is_selected }).length > 1) {
        lbl += ' +' + (regions.filter(r => { return r.is_selected }).length - 1).toString();
      }
      return lbl;
    }
    return '';
  };
  return (<React.Fragment>

    <IonGrid>
      <IonRow>
        <IonCol>
          <IonItem lines='none'>
            <IonLabel>אזור שירות</IonLabel>
            <IonInput onClick={toggleRegionsOptions} readonly={true} value={selectedDisplay()} />
          </IonItem>
          {showRegions && !!regions && regions.length > 0 &&
            <IonCard className='auto-complete-cont' style={{ height: (window.innerWidth > 768 ? 240 : 150), overflow: 'auto' }}>
              <IonCardContent >
                <IonList >
                  {regions.map((reg, i) =>
                    <IonItem key={i} className='auto-complete-item'
                      lines={(i + 1 < regions.length ? 'full' : 'none')}  >
                      <IonLabel>{reg.region_name}</IonLabel>
                      <IonCheckbox slot='start' value={reg.region_code.toString()} className='ion-margin-start'
                        onIonChange={selectedRegionsChanged} checked={reg.is_selected}
                        disabled={!reg.is_selected && regions.filter(r => { return r.is_selected }).length >= 3}></IonCheckbox>
                    </IonItem>
                  )}
                </IonList>
              </IonCardContent>
            </IonCard>
          }
        </IonCol>
      </IonRow>
      <IonRow >
        {regions && regions.filter(r => { return r.is_selected }).length > 0 &&

          <IonCol size='12' sizeMd='6'>
            <div>
              <h3 className='bold-text small-title'>אזורים שנבחרו</h3>
            </div>
            <IonRow>
              {regions.filter(r => { return r.is_selected }).map((reg, index) => {
                return (
                  <IonCol size='12' key={index} className='ion-no-padding'>
                    <IonButton fill='clear' size="small" onClick={() => setRegionSelected(reg.region_code.toString(), false)} >
                      <IonLabel color='primary'>
                        {reg.region_name}</IonLabel>
                      <IonIcon slot='start' icon={closeOutline} />
                    </IonButton></IonCol>
                )
              })}</IonRow>
          </IonCol>
        }
        {error && (

           <IonCol size='12' >
             <div className="ion-text-center">
            <IonText color="danger">
              <p>{error}</p>
            </IonText>
            </div>
          </IonCol>

        )}
      </IonRow>


    </IonGrid>
  </React.Fragment>);
};

export default FrmRegions;