import React from 'react'
import B144Icon from '../../../../../components/icons/B144Icon'
import FacebookIcon3 from '../../../../../components/icons/FacebookIcon3'
import styles from './FacebookBenefitsPage.module.scss'

export default function FacebookBenefitsPage() {
  return (
    <div className={styles['facebook-benefits-page']}>
      <div className={styles.title}>מה תקבלו:</div>
      <ul className={styles.list}>
        <li>קמפיין מודעות שיווקיות מעוצבות ואפקטיביות בפייסבוק</li>
        <li>מיקוד הקמפיין לקהל היעד המתאים לעסק שלך</li>
        <li>יהול הקמפיין על ידי מומחים לפרסום עסקים קטנים בתקציבים נמוכים</li>
        <li>מעקב תדיר אחר תוצאות הקמפיין ודיוק האפקטיביות שלו לאורך זמן</li>
        <li>ממשק נח לעדכון עצמאי של פרטי העסק</li>
        <li>יכולת מעקב אחר ביצועי הקמפיינים</li>
        <li>מספר טלפון ייחודי לעסק עם יכולות מעקב אחרי הקמפיין</li>
      </ul>
      <div className={styles.icons}>
        <B144Icon className={`${styles.icon} ${styles.b144}`} />
        <FacebookIcon3 className={`${styles.icon} ${styles.facebook}`} />
      </div>
    </div>
  )
}
