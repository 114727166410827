import React, { useContext, useEffect, useRef, useState } from "react";
import { CategoriesAdv } from "../../../data/SelfSaleContext";
import CustomSelect from '../CustomElements/CustomSelect';
import { v4 as uuidv4 } from 'uuid';
import {
  IonRow,
  IonCol,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonCheckbox,
  IonIcon,
  IonPopover,
  IonCard,
  IonButton,
  IonContent,
} from "@ionic/react";
import { arrowDown } from "../../../images";
const SelfSaleCategoryAdvListNew: React.FC<{ categoryAdvTypeC: CategoriesAdv[]|null, categoryAdvTypeD: CategoriesAdv[]|null }> = (props) => {
  // const [categoryAdvLists, setCategoryAdvLists] = useState<CategoriesAdv[]>([]);
  // const [categoryAdvCheckboxes, setCategoryAdvCheckboxes] = useState<CategoriesAdv[]>([]);
  const [activeAdvListId, setActiveAdvListId] = useState<string>("");
  // useEffect(() => {
  //   console.log("useEffect []]: ", props.categoryAdv)

  // }, [])
  // useEffect(() => {
  //   console.log("useEffect props.categoryAdv: ", props.categoryAdv)

  //   if (props.categoryAdv.length > 0 && (!categoryAdvLists.length || !categoryAdvCheckboxes.length)) {
  //     let lists = props.categoryAdv.filter(i => i.fld_type === "d")
  //     let checkboxes = props.categoryAdv.filter(i => i.fld_type !== "d")
  //     setCategoryAdvLists(lists)
  //     setCategoryAdvCheckboxes(checkboxes)
  //   }
  // }, [props.categoryAdv])

  // const select = (e: any, parent: any) => {
  //   if (parent && parent.list_options.length > 0) {
  //     let updatedListOptions = parent.list_options.map((o: any) => {
  //       if (e.detail.value === o.fld_option_id) {
  //         o.selected = e.detail.checked
  //       }
  //       return o
  //     })
  //     let categoryAdvFiltered = props.categoryAdv.map((o: any) => {
  //       if (o.fld_type === "d" && parent.cat_adv_fld_id === o.cat_adv_fld_id) {
  //         o.list_options = updatedListOptions
  //       }
  //       return o
  //     })
  //     props.onSelect(categoryAdvFiltered)
  //   }
  //   else {
  //     let updated = props.categoryAdv.map((o: any) => {
  //       if (e === o.cat_adv_fld_id) {
  //         o.selected = !o.selected
  //       }
  //       return o
  //     })
  //     props.onSelect(updated)
  //   }
  // }
  const selectNew = (e: any, parent: any) => {
    if (parent && parent.list_options.length > 0) {
      parent.list_options.map((o: any) => {
        if (e.detail.value === o.fld_option_id) {
          o.selected = e.detail.checked
        }
        return o
      })
    }
    else {
      props.categoryAdvTypeC && props.categoryAdvTypeC.map((o: any) => {
        if (e === o.cat_adv_fld_id) {
          o.selected = !o.selected
        }
        return o
      })
    } 
  }
  const toggleListView = (e: any, item: any) => {
    if (item.cat_adv_fld_id === activeAdvListId) {
      setActiveAdvListId("")
    }
    else {
      e.currentTarget.scrollIntoView({ behavior: 'smooth' })
      setActiveAdvListId(item.cat_adv_fld_id)
    }
  }
  return (
    <>
      {props.categoryAdvTypeC && (
        <div>
          <div className="category-adv-list-opt">
            {props.categoryAdvTypeC.map(c => (<IonItem mode="md" lines="none" className="" key={uuidv4()}>
              <IonCheckbox
                mode="md"
                slot="start"
                className="" checked={c.selected}
                onIonChange={(e: any) => selectNew(e.target.value, null)}
                value={c.cat_adv_fld_id}>
              </IonCheckbox>
              <IonLabel>
                {c.fld_desc}
              </IonLabel>
            </IonItem>))}
          </div>
        </div>

      )}
      {props.categoryAdvTypeD &&  (

        <div className="category-adv-list-wrapper">
          {props.categoryAdvTypeD.map((item: CategoriesAdv) => {
            return (
              <div className="category-adv-list" key={uuidv4()}>
                <div className="category-adv-list-header" onClick={(e) => toggleListView(e, item)}>
                  <p className="self-sale-section-subtitle">{item.fld_desc}</p>
                  <img src={arrowDown} alt="arrow down" style={activeAdvListId === item.cat_adv_fld_id
                    ? {
                      transform: "rotate(180deg)",
                      transition: "transform ease 0.5s",
                    }
                    : undefined
                  }
                  />
                </div>
                {activeAdvListId === item.cat_adv_fld_id && (<div className="category-adv-list-opt">
                  {item.list_options.map(c => (<IonItem mode="md" lines="none" className="" key={uuidv4()}>
                    <IonCheckbox
                      mode="md"
                      slot="start"
                      className="" checked={c.selected}
                      onIonChange={(e: any) => selectNew(e, item)}
                      value={c.fld_option_id}>
                    </IonCheckbox>
                    <IonLabel>
                      {c.fld_option_desc}
                    </IonLabel>
                  </IonItem>))}
                </div>)}

              </div>
            )
          })}
        </div>
      )}
    </>
  );
};

export default SelfSaleCategoryAdvListNew;
