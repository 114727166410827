// React
import React, { useContext, useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonList,
  IonButton,
  IonIcon,
} from "@ionic/react";
import { add, addOutline, createOutline, trash } from "ionicons/icons";
// Custom
import CustomModal from "../../utils/CustomElements/CustomModal/CustomModal";
import CustomAreYouSureAlert from "../../utils/CustomElements/CustomAreYouSureAlert";
// Context
import PriceListContext, {
  PriceListItem,
} from "../../../data/PriceList-context";
import { HeaderParameters } from "../../../data/Header-Context";
import { usePriceListContext } from "../../../data/PriceListContextProvider";
// Component
import HandlePriceListItem from "./HandlePriceListItem";
import { isMobile } from 'react-device-detect';

const AddEditPriceList: React.FC<{
  show: boolean;
  selectedPriceListId: number;
  selectedCategoryName: string;
  closeModal: () => void;
}> = (props) => {
  const { CategoryPriceLists, MemberPriceList } = usePriceListContext();
  const PriceListCxt = useContext(PriceListContext);
  const [startEditing, setStartEditing] = useState(false);
  const [currentItem, setCurrentItem] = useState<PriceListItem | null>(null);
  const [mergedPriceLists, setMergedPriceLists] = useState<any>();
  const [showAreYouSure, setShowAreYouSure] = useState(false);

  const headerParameters: HeaderParameters = {
    isShowLogo: true,
    isLogin: true,
    modalType: "modal",
  };

  const savePriceListHandler = (item: PriceListItem) => {
    PriceListCxt.UpdatePriceListItem(item, props.selectedPriceListId);
    setStartEditing(false);
  };

  React.useEffect(() => {
    initMergedPriceLists();
  }, [MemberPriceList]);

  const title =
    props.selectedCategoryName !== "" && MemberPriceList === null
      ? "מחירון " + props.selectedCategoryName
      : "המחירון שלי";

  const initMergedPriceLists = () => {
    // New Items
    const newMemberItems =
      MemberPriceList &&
      MemberPriceList.PriceListItems &&
      MemberPriceList.PriceListItems.filter(
        (item) => item.PriceListProductId <= 0
      ).map((item) => {
        return {
          id: item.Id,
          PriceListId: item.PriceListId,
          PriceListProductId: -1,
          Title: item.Title,
          AdditionalData: item.AdditionalData1,
          MinPrice: item.MinPrice,
          MaxPrice: item.MaxPrice,
          LiteralPrice: item.LiteralPrice || "",
        };
      });

    // Edited Items & existing items from standard pricelist
    const mrgPriceLists =
      CategoryPriceLists.length > 0 &&
      CategoryPriceLists.filter(
        (priceList) => priceList.PriceListId === props.selectedPriceListId
      ).map((priceList) => {
        let customizedPriceList = priceList.PriceListItems.filter((item,index,self)=>{
          return self.map((m) => m.PriceListProductId).indexOf(item.PriceListProductId) == index
        }).map(
          (priceListItem, i) => {
            const memberPriceList =
              MemberPriceList &&
              MemberPriceList.PriceListItems &&
              MemberPriceList.PriceListItems.find(
                (p) => p.PriceListProductId === priceListItem.PriceListProductId
              );
            return {
              id: memberPriceList ? memberPriceList.Id : -1,
              PriceListId: priceList.PriceListId,
              PriceListProductId: priceListItem.PriceListProductId,
              Title: priceListItem.Title,
              AdditionalData: priceListItem.AdditionalData1,
              MinPrice: memberPriceList
                ? memberPriceList.MinPrice
                : priceListItem.MinPrice,
              MaxPrice: memberPriceList
                ? memberPriceList.MaxPrice
                : priceListItem.MaxPrice,
              LiteralPrice: memberPriceList
                ? memberPriceList.LiteralPrice
                : priceListItem.LiteralPrice,
            };
          }
        );

        const newArr = newMemberItems
          ? customizedPriceList
              .concat(newMemberItems.sort((a, b) => b.id! - a.id!))
              .sort(function (a, b) {
                if (a.PriceListProductId === -1) return -1;
                else return b.id! - a.id!;
              })
          : customizedPriceList;

        return newArr;
      })[0];

    mrgPriceLists
      ? setMergedPriceLists(mrgPriceLists)
      : setMergedPriceLists(newMemberItems);
  };

  return (
    <CustomModal
      pageName={"AddEditPriceList"}
      headerParameters={headerParameters}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
      title={title}
      subTitle={
        mergedPriceLists
          ? "לפניך מחירון סטנדרטי של בזק, ביכולתך לערוך את הנתונים המוצגים לפי בחירתך ולהוסיף פריטים משלך"
          : ""
      }
    >
      <IonRow>
        <IonButton
          onClick={() => {
            setStartEditing(true);
            setCurrentItem(null);
          }}
        >
          {"הוסף מוצר חדש"}
          <IonIcon icon={addOutline}></IonIcon>
        </IonButton>
      </IonRow>
      <IonGrid className="ion-no-padding ion-padding-horizontal">
        <IonList>
          <IonCol>
            <IonItem style={{ fontWeight: "bold" }}>
              <IonCol  size={isMobile ? "7" : "3.5"}>תיאור מוצר</IonCol>
              {!isMobile && <IonCol size="3">הערה למוצר</IonCol>}
              {!isMobile && <IonCol size="3">מחיר</IonCol>}
              <IonCol></IonCol>
            </IonItem>
          </IonCol>
        </IonList>

        {mergedPriceLists &&
          mergedPriceLists.map((priceListItem: any, i: any) => {
            let price;
            if (priceListItem.LiteralPrice) {
              price = priceListItem.LiteralPrice;
            } else {
              if (priceListItem.MinPrice && priceListItem.MaxPrice) {
                price =
                  priceListItem.MinPrice + priceListItem.MaxPrice === 0
                    ? "ללא תשלום"
                    : priceListItem.MinPrice === priceListItem.MaxPrice
                    ? `${priceListItem.MinPrice.toLocaleString()} ₪`
                    : `${priceListItem.MinPrice.toLocaleString()} - ${priceListItem.MaxPrice.toLocaleString()} ₪`;
              } else if (!priceListItem.MinPrice && priceListItem.MaxPrice) {
                price = `עד ${priceListItem.MaxPrice.toLocaleString()} ₪`;
              } else if (priceListItem.MinPrice && !priceListItem.MaxPrice) {
                price = `מ ${priceListItem.MinPrice.toLocaleString()} ₪`;
              } else {
                price = "ללא תשלום";
              }
            }
            return (
              <IonRow key={i}>
                <IonCol
                  key={i}
                  style={{
                    paddingTop: 0,
                    paddingBottom: 7,
                  }}
                >
                  <IonItem
                    lines="none"
                    key={i}
                    style={{
                      color: priceListItem.id! < 0 ? "gray" : "black",
                    }}
                  >
                    <IonCol size={isMobile ? "7" : "3.5"} style={{paddingLeft:7}} className={isMobile ? "ellipsis" : ""}>{priceListItem.Title}</IonCol>
                    {!isMobile && <IonCol size="3" style={{paddingLeft:7}}>{priceListItem.AdditionalData}</IonCol>}
                    {!isMobile && <IonCol size="3" className="ellipsis">{price}</IonCol>}
                    <IonCol>
                      {priceListItem.id! < 0 && (
                        <IonButton
                          onClick={() => {
                            setStartEditing(true);
                            setCurrentItem({
                              Id: priceListItem.id,
                              PriceListProductId:
                                priceListItem.PriceListProductId,
                              PriceListId: priceListItem.PriceListId,
                              Title: priceListItem.Title,
                              AdditionalData1: priceListItem.AdditionalData,
                              MinPrice: priceListItem.MinPrice,
                              MaxPrice: priceListItem.MaxPrice,
                              LiteralPrice: priceListItem.LiteralPrice,
                            });
                          }}
                        >
                          <IonIcon icon={add} slot="icon-only" />
                        </IonButton>
                      )}

                      {priceListItem.id! > 0 && (
                        <>
                          <IonButton
                            onClick={() => {
                              setStartEditing(true);
                              setCurrentItem({
                                Id: priceListItem.id,
                                PriceListProductId:
                                  priceListItem.PriceListProductId,
                                PriceListId: priceListItem.PriceListId,
                                Title: priceListItem.Title,
                                AdditionalData1: priceListItem.AdditionalData,
                                MinPrice: priceListItem.MinPrice,
                                MaxPrice: priceListItem.MaxPrice,
                                LiteralPrice: priceListItem.LiteralPrice,
                              });
                            }}
                          >
                            <IonIcon icon={createOutline} slot="icon-only" />
                          </IonButton>

                          <IonButton
                            onClick={() => {
                              setCurrentItem({
                                Id: priceListItem.id,
                                PriceListProductId:
                                  priceListItem.PriceListProductId,
                                PriceListId: priceListItem.PriceListId,
                                Title: priceListItem.Title,
                                AdditionalData1: priceListItem.AdditionalData,
                                MinPrice: priceListItem.MinPrice,
                                MaxPrice: priceListItem.MaxPrice,
                                LiteralPrice: priceListItem.LiteralPrice,
                              });
                              setShowAreYouSure(true);
                            }}
                          >
                            <IonIcon icon={trash} slot="icon-only" />
                          </IonButton>
                        </>
                      )}
                    </IonCol>
                  </IonItem>
                </IonCol>
              </IonRow>
            );
          })}
        <IonRow>
          <IonButton
            onClick={() => {
              setStartEditing(true);
              setCurrentItem(null);
            }}
          >
            {"הוסף מוצר חדש"}
            <IonIcon icon={addOutline}></IonIcon>
          </IonButton>
        </IonRow>
      </IonGrid>

      {startEditing && (
        <HandlePriceListItem
          show={startEditing}
          closeModal={() => {
            setStartEditing(false);
            setCurrentItem(null);
          }}
          onSave={savePriceListHandler}
          currentPriceListItem={currentItem}
        />
      )}
      {showAreYouSure && (
        <IonRow>
          <IonCol>
            <IonGrid className="accordion-wrapper">
              <CustomAreYouSureAlert
                cancelFunc={() => {
                  setShowAreYouSure(false);
                }}
                continueFunc={() => {
                  setShowAreYouSure(false);
                  currentItem && PriceListCxt.DeletePriceListItem(currentItem);
                }}
                isOpen={showAreYouSure}
                message={"האם אתה בטוח שברצונך למחוק מוצר זה?"}
                continueButton={"מחק"}
                cancelButton={"בטל"}
              ></CustomAreYouSureAlert>
            </IonGrid>
          </IonCol>
        </IonRow>
      )}
    </CustomModal>
  );
};

export default AddEditPriceList;
