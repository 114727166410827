import React, { useState } from "react";

import CoSaleContext, { CoSaleSubject, CoSaleSubjects } from "./CoSale-Context";
import { config } from "../Constants";
import { fetchRequestNode } from "../utils-ts/fetchRequest";

const CoSaleContextProvider: React.FC = (props) => {
  const [coSaleSubjects, setCoSaleSubjects] = useState<
    CoSaleSubject[] | undefined
  >();
  const [clientName, setClientName] = useState("");

  const getCoSaleByToken = (token: string) => {
    return new Promise(async (resolve) => {
      var data = new URLSearchParams({
        token: token,
      }).toString();
      fetchRequestNode(
        `${config.url.Node_API_URL}/coSaleApi/getByToken`,
        "post",
        data
      )
        .then((response: any) => {
          //console.log(response);
          var res = JSON.parse(response);
          if (res && res.Success) {
            const data = res.data;
            setClientName(data.client_name);
            const sale_items = data.saleItems.split(",");
            const subjects: CoSaleSubject[] = [];
            subjects.push({
              Type: 'General',
              SubType: 'Start',
              Title: 'ברוכים הבאים לB144',
              Items:[{
                Type: 'Page'
              }]
            });
            for (let i = 0; i < sale_items.length; i++) {
              const subject = CoSaleSubjects[sale_items[i]];
              if (subject && subject.Items.length > 0) {
                subjects.push(subject);
              }
            }
            subjects.push({
              Type: "General",
              SubType: "Finish",
              Title: "לסיכום",
              Items: [
                {
                  Type: "Page",
                },
              ],
            });
            setCoSaleSubjects(subjects);
            resolve(subjects && subjects.length > 0);
          } else {
            resolve(false);
          }
        })
        .catch(function (error) {
          //console.log(error);
          let error_message = error.message;
          if (error_message == "Failed to fetch") {
            error_message =
              "השירות לא זמין, אנא פנו למוקד השירות בטלפון 1-700-707-144";
          }
          resolve({
            message: error_message,
          });
        });
    });
  };

  return (
    <CoSaleContext.Provider
      value={{
        clientName,
        coSaleSubjects,
        getCoSaleByToken,
      }}
    >
      {props.children}
    </CoSaleContext.Provider>
  );
};

export default CoSaleContextProvider;
