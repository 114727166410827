import React, { useContext, useEffect } from 'react';
import { IonButton, IonGrid, IonRow, IonCol, IonItem, IonIcon, IonList, IonCard, IonLabel } from '@ionic/react';
import ProductsContext, { OrderStatus, OrderStatusDesc } from '../../../data/Products-context';

import {  trash } from 'ionicons/icons';
import {
  document,
  phone,
  serviceAreas,
  mail,
} from '../../../images/index';
import CustomModal from '../../utils/CustomElements/CustomModal/CustomModal';

import { HeaderParameters } from '../../../data/Header-Context';

const ECommerceOrder: React.FC<{
  show: boolean;
  closeModal: () => void;
  onSave: (status: OrderStatus) => void;
  orderId: number;
}> = (props) => {
  const ProductsCtx = useContext(ProductsContext);

  useEffect(() => {
    if (ProductsCtx.MemberOrderDetailed == undefined || ProductsCtx.MemberOrderDetailed.Id != props.orderId) {
      ProductsCtx.getMemberOrder(props.orderId);
    }
  }, []);

  const confirmOrder = () => {
    ProductsCtx.updateOrderStatus(props.orderId, OrderStatus.Ready);
    props.onSave(OrderStatus.Ready);
  };
  const cancelOrder = () => {
    ProductsCtx.updateOrderStatus(props.orderId, OrderStatus.Canceled);
    props.onSave(OrderStatus.Canceled);
  };
  const headerParameters: HeaderParameters = {
    isPageHaveChanges: false,
    modalType: 'modal',
  };

  return (
    <>
      {' '}
      {ProductsCtx.MemberOrderDetailed && (
        <CustomModal
          pageName={'ECommerceOrder'}
          closeModal={() => {
            props.closeModal();
          }}
          isOpen={props.show}
          headerParameters={headerParameters}
          title={"הזמנה " + OrderStatusDesc[ProductsCtx.MemberOrderDetailed.Status] + " #" + ProductsCtx.MemberOrderDetailed.OrderNumber}
          subTitle="לידיעתך - עם עדכון סטטוס ההזמנה יישלח ללקוח מסרון עדכון עם פרטי החנות">
          <>
          <IonCard  >
          <IonItem lines="full" >
                <IonIcon
                  icon={document}
                  slot="start"
                  className="ion-no-margin"
                />
                <IonLabel>
                 {ProductsCtx.MemberOrderDetailed.FullName}
                </IonLabel>
                </IonItem>
                <IonItem lines="full">
                <IonIcon
                  icon={phone}
                  slot="start"
                  className="ion-no-margin"
                />
                <IonLabel>
                 {ProductsCtx.MemberOrderDetailed.Phone}
                </IonLabel>
                </IonItem>
              <IonItem lines="full">
                <IonIcon
                  icon={mail}
                  slot="start"
                  className="ion-no-margin"
                />
                <IonLabel>
                 {ProductsCtx.MemberOrderDetailed.Email}
                </IonLabel>
                </IonItem>
                <IonItem lines="full">
                <IonIcon
                  icon={serviceAreas}
                  slot="start"
                  className="ion-no-margin"
                />
                <IonLabel>
                 {ProductsCtx.MemberOrderDetailed.Address}
                </IonLabel>
                </IonItem>
                <IonItem lines="none">
                <IonIcon
                  icon={document}
                  slot="start"
                  className="ion-no-margin"
                />
                <IonLabel>
                 {ProductsCtx.MemberOrderDetailed.Remarks}
                </IonLabel>
                </IonItem>
                
                </IonCard>
            <IonGrid>
             

              {ProductsCtx.MemberOrderDetailed.Products && (
                <IonRow>
                  <IonCol>
                    <IonList>
                      <IonItem style={{fontWeight:'bold'}}>
                        <IonCol>מוצר</IonCol>
                        <IonCol size='2'>כמות</IonCol>
                        <IonCol size='3'>סכום</IonCol>
                      </IonItem>
                   
                    {ProductsCtx.MemberOrderDetailed.Products.map((prod, index) => {
                      return (
                        <IonItem key={index}>
                          <IonCol>{prod.Name}</IonCol>
                          <IonCol size='2'>{prod.Quantity}</IonCol>
                          <IonCol size='3'>{prod.TotalCost} ₪</IonCol>
                        </IonItem>
                      );
                    })}
                    <IonItem lines="none" style={{fontWeight: 'bold'}}>
                      <IonCol>סה"כ</IonCol>
                      <IonCol size='2'></IonCol>
                      <IonCol size='3'>{ProductsCtx.MemberOrderDetailed.TotalPrice} ₪</IonCol>
                    </IonItem>
                    </IonList>
                  </IonCol>
                </IonRow>
              )}

              <IonRow>
                <IonCol>
                  {' '}
          <IonButton className="button" onClick={confirmOrder} disabled={ProductsCtx.MemberOrderDetailed.Status === OrderStatus.Ready}>
                    הזמנה מוכנה
                  </IonButton>
               
                  <IonButton className="button" fill='outline' color='danger' onClick={cancelOrder} disabled={ProductsCtx.MemberOrderDetailed.Status === OrderStatus.Canceled}>
                    ביטול הזמנה
                    <IonIcon slot="end" size="big" icon={trash} />
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </>
        </CustomModal>
      )}
    </>
  );
};

export default ECommerceOrder;
