import React, { useContext, useEffect, useState } from "react";
import {
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
} from "@ionic/react";

import { Control, FieldError, NestDataObject } from "react-hook-form";
import VasContext, { selectOption } from "../../data/Vas-context";

const VasPackSelect: React.FC<{
    prod_code: number;
    control: Control;
    register: React.Ref<any>;
    clearError:(name: string)=> void;
    errors?: NestDataObject<Record<string, any>, FieldError>;
}> = props => {

    const vasCxt = useContext(VasContext);
    const [packages, setPackages] = useState<selectOption[] | null>(null);
    
    useEffect(() => {
        if (packages == null) {
            vasCxt.getPackagesByProdCode(props.prod_code, false)
                .then((result: React.SetStateAction<selectOption[] | null>) => {
                    setPackages(result);
                });
        }
        // if (props.control &&
        //     props.control.fieldsRef &&
        //     props.control.fieldsRef!.current) {
            
        //     //set default value
        //     if (props.control.fieldsRef!.current!.contactHours &&
        //         props.control.fieldsRef!.current!.contactHours.ref.value == undefined) {
        //         props.control.setValue('contactHours', contactHoursList[0]);
        //     }
        // }

    });
    const ddlChangeHandler = (e: CustomEvent) => {
        //props.onChange(e);
        let pack = e.detail?.value;//getValues("pack"); //
        if (!!pack) {
            //props.errors[''].c
            props.clearError('pack');
        }
    };

    return (
        <React.Fragment>
             <IonItem color='light' className={props.errors && props.errors['pack'] != null ? "item-invalid" : ""}>
                            <IonLabel position="stacked">חבילה*</IonLabel>
                            <IonSelect
                                color="light"
                                interface='alert'
                                interfaceOptions={{
                                    cssClass: 'custom-select-alert'
                                }
                                }
                                cancelText="ביטול"
                                okText="אישור"
                                name="pack"
                                ref={props.register}  //register({ required: 'נא לבחור חבילה' })
                                onIonCancel={ddlChangeHandler}
                                onIonChange={ddlChangeHandler}
                                placeholder="בחר חבילה" >
                                {packages &&
                                    packages.map((t) => {
                                        return (
                                            <IonSelectOption key={t.value} value={t.value}>
                                                {t.text}
                                            </IonSelectOption>
                                        );
                                    })}
                            </IonSelect>
                        </IonItem>
                        <div className="input-alerts">
                            {props.errors && props.errors['pack'] != null && (
                                <p className="input-error">{(props.errors as any)['pack'].message}</p>
                            )}
                        </div>
        </React.Fragment>
    );
};

export default VasPackSelect;