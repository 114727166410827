import React from "react";
import PeopleIcon from "../../../../../components/icons/PeopleIcon";
import styles from "./B144IntroPage.module.scss";
// import FlowerIcon from "../../../../../components/icons/FlowerIcon";

export default function B144IntroPage() {
  return (
    <div className={styles["statistics-page"]}>
      {/* <div className={styles.container}>
        <FlowerIcon className={styles.icon} />
        <span className={styles["upper-text"]}>711</span>
        <span className={styles["lower-text"]}>חנויות פרחים באזור תל אביב</span>
      </div> 
      <div role="separator" className={styles.separator} />*/}
      <div className={styles.container}>
        <PeopleIcon className={styles.icon} />
        <span className={styles["upper-text"]}>
          <span>כ - </span> 2 מיליון
        </span>
        <span className={styles["lower-text"]}>גולשים מדי חודש באתר B144</span>
      </div>
      <div role="separator" className={styles.separator} />
      <div className={styles.container}>
        <span className={styles["upper-text"]}>
          <span>כ - </span> 11 מיליון
        </span>
        <span className={styles["lower-text"]}>דפים נצפים מדי חודש</span>
      </div>
      <div role="separator" className={styles.separator} />
      <div className={styles.container}>
        <span className={styles["upper-text"]}>
          <span>כ - </span> 30 אלף
        </span>
        <span className={styles["lower-text"]}>לקוחות מקודמים באתר</span>
      </div>
    </div>
  );
}
