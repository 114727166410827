import { IonButton, IonCol, IonGrid, IonIcon, IonImg, IonItem, IonLabel, IonRow, IonText } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import ImagePicker, {
  Photo,
} from "../../../components/utils/ImagePicker/ImagePicker";
import { isMobile } from "react-device-detect";
import CustomForm from "../../../components/utils/CustomElements/CustomForm";
import { Image } from "../../../data/Gallery-context";
import CustomPublishButton from "../../utils/CustomElements/CustomPublishButton";
import CustomModal from "../../utils/CustomElements/CustomModal/CustomModal";
import CustomInput from "../../utils/CustomElements/CustomInput";
import { HeaderParameters } from "../../../data/Header-Context";
import { toast } from "react-toastify";
import { trashDark } from "../../../images";
import { config } from "../../../Constants";

const GalleryHandler: React.FC<{
  show: boolean;
  closeModal: () => void;
  onSave: (photo: Photo | undefined, desc: string) => void;
  onMultipleSave?: (photos: Photo[], desc: string[]) => void;
  image: Image | undefined;
  isMultiple: boolean;

}> = (props) => {
  const [takenPhoto, setTakenPhoto] = useState<Photo>();
  const [takenPhotos, setTakenPhotos] = useState<Photo[]>();
  const [tempDescriptions, setTempDescriptions] = useState<FieldValues>();
  //const descRef = useRef<HTMLIonInputElement>(null);
  const desc = props.image != null ? props.image.ImageDesc : "";
  const [error, setError] = useState("");
  const { handleSubmit, formState, errors, control,register, setValue } = useForm({
    mode: "onChange",
  });
  const { dirty } = formState;
  const [isPageChange, setIsPageChange] = useState(false);
  const photoPickHandler = (photo: Photo) => {
    setTakenPhoto(photo);
    if (photo.path != "") {
      setIsPageChange(true);
    } else {
      setIsPageChange(false);
    }
  };
  const multi_photoPickHandler = (photos: Photo[]) => {
    setTempDescriptions(undefined);
    setTakenPhotos(photos);
    if (photos[0].path != "") {
      setIsPageChange(true);
    } else {
      setIsPageChange(false);
    }
  };

useEffect(()=> {
  if(takenPhotos && tempDescriptions){
    for(let i = 0; i < takenPhotos.length; i++){
      const photo = takenPhotos[i];
      const field_name = "imgDesc_" + photo.runId;
      const field_val = tempDescriptions[field_name];
      //console.log(field_name,field_val);
      setValue(field_name,field_val);
    }
  }
 
  
  
},[takenPhotos]);

  const saveHandler = async (data:any) => {
    if(props.isMultiple){
      if (!takenPhotos || takenPhotos.length == 0) {
        toast.warning("לא נבחרו תמונות");
        return;
      }

      props.onMultipleSave && props.onMultipleSave(takenPhotos, Object.values(data));
    }else{
    const enteredDesc = data["imgDesc"].toString();
    if (props.image != null) {
      props.onSave(undefined, enteredDesc);
    } else {
      if (!takenPhoto) {
        toast.warning("לא נבחרה תמונה");
        return;
      }
      props.onSave(takenPhoto, enteredDesc);
    }
  }
  };
const getTotalFilesSize = () => {
  let total_filesize = 0;
  takenPhotos && takenPhotos.map((photo) => photo.size || 0).forEach((size) => total_filesize += size);
  return (total_filesize / 1024 / 1024).toFixed(2);
};
  const headerParameters: HeaderParameters = {
    isPageHaveChanges: dirty || isPageChange,
    modalType: "modal",
  };

  return (
    <CustomModal
      pageName={"GalleryHandler"}
      headerParameters={headerParameters}
      closeModal={() => {
        props.closeModal();
      }}
      onDidDismiss={() => {
        props.closeModal();
      }}
      isOpen={props.show}
      title={props.isMultiple ? "אילו תמונות תבחר להעלות?" : "איזו תמונה תבחר להעלות?"}
      subTitle={"תיאור טוב לתמונה יגדיל את החשיפה שלה ונותן לך יתרון שיווקי."}
    >
      <CustomForm onSubmit={handleSubmit(saveHandler)} errors={errors}>
        {props.isMultiple && <IonLabel>לא יותר מ-10 תמונות או גודל כולל של {config.total_filesize_mb}MB</IonLabel>}
        <IonGrid className="ion-no-padding ion-padding-horizontal">
          <IonRow className="ion-justify-content-between">
            {/* start - column right (picture) desktop mode  */}
            <IonCol
              size="12"
              sizeMd={"5.9"}
              pullMd={props.isMultiple ? "3" : undefined}
              style={{ order: !isMobile ? "2" : "" }}
              className="ion-margin-bottom"
            >
              {props.image == null && (takenPhotos == undefined || takenPhotos.length == 0) && (
                <IonRow className="ion-text-center">
                  <IonCol>
                    <ImagePicker onImagePick={photoPickHandler} onImagesPick={multi_photoPickHandler} isMultiple={props.isMultiple} />
                  </IonCol>
                </IonRow>
              )}
              {props.image !== null && (
                <IonRow className="ion-text-center">
                  <IonCol>
                    <IonImg src={props.image?.ImagePath} />
                  </IonCol>
                </IonRow>
              )}
              {error && (
                <IonRow className="ion-text-center">
                  <IonCol>
                    <IonText color="danger">
                      <p>{error}</p>
                    </IonText>
                  </IonCol>
                </IonRow>
              )}
            </IonCol>

            {/* start - column left (input fields) desktop mode  */}
            {!props.isMultiple && <IonCol sizeMd="5.6" className="ion-no-margin">
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <CustomInput
                      title="הוספת תיאור משלך"
                      name="imgDesc"
                      errors={errors}
                      control={control}
                      required={true}
                      ref={register}
                      value={desc}
                      maxlength={30}
                      label="הוספת תיאור משלך"
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>}
            {props.isMultiple && takenPhotos && takenPhotos.length > 0 &&
            <IonCol size="12">
              <div className="ion-margin-bottom"><IonLabel >סה"כ {takenPhotos.length} תמונות בגודל כולל של {getTotalFilesSize()} MB</IonLabel></div>
              { takenPhotos.map((photo:Photo, index:number) => 
              
                <IonItem key={index} lines="none" style={{marginBottom: 7}}>
                  <IonCol size="1" sizeMd="0.5">
                    <IonButton className="ion-no-padding delete-btn" size="small" fill="clear" 
                    onClick={()=> {
                      const descriptions_temp = control.getValues();
                      
                      setTempDescriptions(descriptions_temp);
                      
                      setTakenPhotos((currentPhotos) => {
                        const updatedPhotos = currentPhotos ? [...currentPhotos] : [];

                        updatedPhotos.splice(index, 1);
                        return updatedPhotos;
                      });
                      
                    }}>
                      <IonIcon icon={trashDark}></IonIcon>
                    </IonButton>
                  </IonCol>
                  <IonCol size="3" sizeMd="2">
                  <div style={{backgroundSize: 'cover',
                                borderRadius:10,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                height: 70,
                                width: 70,
                                backgroundImage: 'url(' + photo.preview + ')' } } ></div>
                    {/* <IonImg src={photo.preview}></IonImg> */}
                  </IonCol>
                  <IonCol>
                    <CustomInput
                      title="הוספת תיאור משלך"
                      name={"imgDesc_" + photo.runId}
                      errors={errors}
                      control={control}
                      required={true}
                      value={desc}
                      ref={register}
                      maxlength={30}
                      label="הוספת תיאור משלך"
                    />
                  </IonCol>
                </IonItem>)
              }
             
            </IonCol>
           
            }
          </IonRow>

          {/* start - action button (align to left) desktop mode  */}
          <IonRow className="ion-justify-content-end">
            <IonCol size="12" sizeMd="5.9">
              <CustomPublishButton></CustomPublishButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </CustomForm>
    </CustomModal>
  );
};

export default GalleryHandler;
