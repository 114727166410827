import React, {
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  isPlatform,
} from "@ionic/react";
import { BusinessEvent } from "../../../data/MemberDetailes-context";
import HandleBusinessEvent from "./HandleBusinessEvents";
import { convertTimeStampToDate } from "../../../utils-ts/GlobalDateFunctions";
import CustomItemSliding from "../../utils/CustomElements/CustomItemSliding";
import MemberDetailsContext from "../../../data/MemberDetailes-context";
import CustomModal from "../../utils/CustomElements/CustomModal/CustomModal";
import { HeaderParameters } from "../../../data/Header-Context";

const BusinessEvents: React.FC<{
  show: boolean;
  closeModal: () => void;
  onSave: () => void;
}> = (props) => {
  const MemberDetailsCxt = useContext(MemberDetailsContext);
  useEffect(() => {
    if (MemberDetailsCxt.BusinessEvents == null) {
      MemberDetailsCxt.initBusinessEventsContext();
    }
  }, []);
  const businessEvents = MemberDetailsCxt.BusinessEvents;
  const [startedEditing, setStartEditing] = useState(false);
  const selectedEventElementRef = useRef<BusinessEvent | null>(null);
  let selectedEventRef = useRef<HTMLIonItemSlidingElement | null>(null);

  const saveHandler = (newEvent: BusinessEvent) => {
    MemberDetailsCxt.saveEventsHandler(newEvent);
    props.onSave();
  };

  const deleteEvent = () => {
    const deleteObj = selectedEventElementRef.current!;
    deleteObj.isDelete = true;
    saveHandler(deleteObj);
  };

  const headerParameters: HeaderParameters = {
    isShowEditButton: true,
    modalType: "modal",
    editBtnFunc: () => {
      setStartEditing(true);
      selectedEventElementRef.current = null;
    },
  };
  return (
    <CustomModal
      pageName={"BusinessEvents"}
      headerParameters={headerParameters}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
      title="לוח אירועים"
    >
      <IonGrid>
        {businessEvents && businessEvents.length > 0 && (
          <IonRow>
            <IonCol>
              <IonList>
                <IonItem>
                  <IonCol>שם אירוע</IonCol>
                  <IonCol>תאריך</IonCol>
                </IonItem>
              </IonList>
              {businessEvents &&
                businessEvents.map((event, index) => {
                  const myRef = createRef<HTMLIonItemSlidingElement>();
                  return (
                    <CustomItemSliding
                      onDelete={() => {
                        selectedEventElementRef.current = event;
                        selectedEventRef.current = myRef.current;
                        deleteEvent();
                      }}
                      onEdit={() => {
                        setStartEditing(true);
                        selectedEventElementRef.current = event;
                        selectedEventRef.current = myRef.current;
                      }}
                      key={index}
                      ref={myRef}
                    >
                      <IonItem>
                        <IonCol>{event.Message}</IonCol>
                        <IonCol>
                          {convertTimeStampToDate(event.StartDate)}
                        </IonCol>
                      </IonItem>
                    </CustomItemSliding>
                  );
                })}
            </IonCol>
          </IonRow>
        )}
      </IonGrid>

      {startedEditing && (
        <HandleBusinessEvent
          show={startedEditing}
          event={selectedEventElementRef.current!}
          closeModal={() => {
            setStartEditing(false);
            selectedEventRef.current?.closeOpened();
          }}
          onSave={(newEvent) => {
            setStartEditing(false);
            selectedEventRef.current?.closeOpened();
            saveHandler(newEvent);
          }}
        ></HandleBusinessEvent>
      )}
    </CustomModal>
  );
};

export default BusinessEvents;
