import React, { useContext, useEffect, useState } from "react";

import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonButton,
  IonImg,
  IonBackButton,
  IonIcon,
  IonLabel,
  IonRouterLink,
  IonTitle,
} from "@ionic/react";
import { isMobile } from "react-device-detect";
import Logo from "../../../../images/logos/logob-144-mobile.svg";
import LogoNegative from "../../../../images/logos/logob-144-mobile-negative.svg";
import { close, pencil, chevronForwardOutline } from "ionicons/icons";
import {
  iconEditWhite,
  closeBlack,
  notificationsOn,
  notificationsOff,
  menu,
  downloadBlack,
  download,
  uploadBlack,
  upload,
  trash,
  trashDark,
  logoRuahTova,
  logoNegativeWhite,
  logoRuahTova_black,
  b144LogoBlue,
  logo_B144_war_blue,
  logo_B144_war_white
} from "../../../../images/index";
import CustomDeleteButton from "../CustomDeleteButton";
import CustomCloseButton from "../CustomCloseButton";
import "./header.scss";
import CustomPdfButton from "../CustomPdfButton";
import CustomeMoreOptions from "../CustomeMoreOptions";
import { HeaderParameters } from "../../../../data/Header-Context";
import MemberInboxContext from "../../../../data/MemberInbox-Context";
import MemberDetailsContext from "../../../../data/MemberDetailes-context";
import { generateExternalLinkWithToken } from "../../../../data/GlobalService";
import { config } from "../../../../Constants";
const CustomHeader: React.FC<{
  headerParameters: HeaderParameters;
}> = (props) => {
  const MemberInboxCxt = useContext(MemberInboxContext);
  const MemberDetailsCxt = useContext(MemberDetailsContext);

  const isShowMultiChoiceNumber =
    props.headerParameters.numOfMultiSelected !== undefined ? true : false;

  const modalHandler =
    props.headerParameters.modalType == "itemPreview"
      ? "modal-item-preview"
      : "modal";
  const [externalLinkWithToken, setExternalLinkWithToken] = useState<
    string | undefined
  >();
  useEffect(() => {
    if (
      props.headerParameters.isShowBusinessCardPreview &&
      props.headerParameters.isShowBusinessCardPreview === true
    ) {
      generateExternalLinkWithToken().then((data) => {
        setExternalLinkWithToken(data);
      });
    }
  }, [props.headerParameters.isShowBusinessCardPreview]);

  useEffect(() => {
    if (
      props.headerParameters.isShowNotification &&
      props.headerParameters.isShowNotification === true &&
      MemberInboxCxt.unReadMessagesAmount === undefined
    ) {
      MemberInboxCxt.initMessageContext(false, 0);
    }
  }, [props.headerParameters.isShowNotification]);

  return (
    <IonHeader
      mode={props.headerParameters.modalType ? "ios" : undefined}
      className={
        "ion-no-border " +
        (props.headerParameters.modalType === "modal" ||
        props.headerParameters.modalType === "confirm" 
          ? "ion-padding-top ion-padding-horizontal"
          : "")
      }
    >
      <IonToolbar 
        className={`${props.headerParameters.modalType ? modalHandler : ""} 
                   ${props.headerParameters.isLogin ? "modal" : ""}`}
        mode={isMobile ? "ios" : undefined}
      >
        {props.headerParameters.toolbarTitle && <IonTitle className="toolbar-title">{props.headerParameters.toolbarTitle}</IonTitle>}
        <IonButtons
          slot={
            (props.headerParameters.modalType === "modal" && !isMobile) ||
            props.headerParameters.modalType === "confirm"
              ? "end"
              : "start"
          }
        >
          {props.headerParameters.backButton?.isShowMobile &&
            isShowMultiChoiceNumber === false &&
            isMobile && (
              <IonBackButton
                text=""
                icon={chevronForwardOutline}
                defaultHref={
                  props.headerParameters.backButton.link
                    ? props.headerParameters.backButton.link
                    : `/Home`
                }
              ></IonBackButton>
            )}
          {props.headerParameters.isCloseModalBtn == true &&
            isShowMultiChoiceNumber === false && (
              <CustomCloseButton
                icon={
                  (props.headerParameters.modalType === "modal" && !isMobile) ||
                  props.headerParameters.modalType === "confirm"
                    ? closeBlack
                    : chevronForwardOutline
                }
                isPageHaveChanges={props.headerParameters.isPageHaveChanges}
                onClose={() => {
                  if (props.headerParameters.closeModalFunc) {
                    props.headerParameters.closeModalFunc();
                  }
                }}
              ></CustomCloseButton>
            )}
          {props.headerParameters.isShowHamburger == true &&
            isShowMultiChoiceNumber === false && (
              <IonMenuButton>
                <IonIcon icon={menu}></IonIcon>
              </IonMenuButton>
            )}
          {props.headerParameters.isShowLogo == true &&
            isShowMultiChoiceNumber === false && (
              <IonButton
                routerLink={`/Home`}
                className={(isMobile ? "logo" : "") + (config.otef_israel && props.headerParameters.isLogin && " otef-headding-login-logo")}
              >
                {config.otef_israel ? 
                <>
                <IonImg className="otef-headding-logo" src={props.headerParameters.isLogin ? logoRuahTova_black : logoRuahTova} />
                <div className={props.headerParameters.isLogin ? "otef-headding-login-div" : "otef-headding-div"}></div>
                <IonImg
                  className="headding-logo"
                  src={props.headerParameters.isLogin ? b144LogoBlue : logoNegativeWhite }
                />
                </>: <IonImg
                  className="headding-logo"
                  src={config.logo_war ? (props.headerParameters.isLogin || props.headerParameters.modalType == "modal" ? logo_B144_war_blue : logo_B144_war_white) : (props.headerParameters.modalType ? LogoNegative : Logo)}
                />}

              </IonButton>
            )}
          {isShowMultiChoiceNumber && (
            <>
              <IonButton
                onClick={() => {
                  if (
                    props.headerParameters.onCancelMultiSelected != undefined
                  ) {
                    props.headerParameters.onCancelMultiSelected();
                  }
                }}
              >
                <IonIcon icon={close} />
              </IonButton>
              {props.headerParameters.numOfMultiSelected! > 0 && (
                <IonLabel>{props.headerParameters.numOfMultiSelected}</IonLabel>
              )}
            </>
          )}
        </IonButtons>
        <IonButtons slot="end">
          {props.headerParameters.isShowBusinessCardPreview !== undefined &&
            props.headerParameters.isShowBusinessCardPreview === true  && (
              <IonRouterLink
                target="_blank"
                href={MemberDetailsCxt.GeneralInfo?.BuisenessPageUrl}
                className="underline white-text md-title"
              >
                תצוגה מקדימה
              </IonRouterLink>
            )}
          {props.headerParameters.isShowEditButton === true && (
            <IonButton
              onClick={() => {
                if (props.headerParameters.editBtnFunc) {
                  props.headerParameters.editBtnFunc();
                }
              }}
            >
              <IonIcon
                icon={
                  props.headerParameters.modalType === "modal"
                    ? iconEditWhite
                    : pencil
                }
              ></IonIcon>
            </IonButton>
          )}
          {props.headerParameters.isShowUploadButton === true && (
            <IonButton
              onClick={() => {
                if (props.headerParameters.uploadFunc) {
                  props.headerParameters.uploadFunc();
                }
              }}
            >
              <IonIcon
                icon={
                  props.headerParameters.modalType === "modal"
                    ? uploadBlack
                    : upload
                }
              />
            </IonButton>
          )}
          {props.headerParameters.isShowDeleteButton === true && (
            <CustomDeleteButton
              fillClear={true}
              icon={
                props.headerParameters.modalType === "modal" ? trashDark : trash
              }
              onDelete={() => {
                if (props.headerParameters.deleteFunc) {
                  props.headerParameters.deleteFunc();
                }
              }}
            ></CustomDeleteButton>
          )}
          {props.headerParameters.pdfDownloadPdf != undefined && (
            <CustomPdfButton
              pdf={props.headerParameters.pdfDownloadPdf}
              callbackfunc={props.headerParameters.pdfDownloadPdfFunc}
              btnTitle='הורדת דו"ח'
              icon={
                props.headerParameters.modalType === "modal"
                  ? downloadBlack
                  : download
              }
            ></CustomPdfButton>
          )}

          {props.headerParameters.moreOptionsButtons &&
            isMobile &&
            props.headerParameters.moreOptionsButtons.length > 0 && (
              <CustomeMoreOptions
                options={props.headerParameters.moreOptionsButtons}
              ></CustomeMoreOptions>
            )}
          {MemberInboxCxt.unReadMessagesAmount !== undefined &&
            props.headerParameters.isShowNotification &&
            props.headerParameters.isShowNotification === true && (
              <IonButton routerLink={`/Inbox/notread`}>
                <IonIcon
                  icon={
                    MemberInboxCxt.unReadMessagesAmount > 0
                      ? notificationsOn
                      : notificationsOff
                  }
                />
                {/* <IonBadge>{MemberInboxCxt.unReadMessagesAmount}</IonBadge> */}
              </IonButton>
            )}
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};
export default CustomHeader;
