import React, { useContext, useEffect, useRef, useState } from "react";
import { IonActionSheet } from "@ionic/react";
import { mail, logoWhatsapp } from "ionicons/icons";
import { facebookWhite, qrWhite, sms, twitter_new_white } from "../../images/index";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { SocialSharing } from "@ionic-native/social-sharing";
import QrCodeModule from "./QrCodeModule";

import MemberDetailsContext from "../../data/MemberDetailes-context";
import "./shareOptions.scss";
import { Capacitor } from "@capacitor/core";
import { isMobile } from "react-device-detect";
import NativeApp from "../../utils-ts/NativeApp";

const ShareOptions: React.FC<{
  show: boolean;
  initShowQR: boolean;
  closeModal: () => void;
}> = (props) => {
  const MemberDetailsCxt = useContext(MemberDetailsContext);
  const [isShowQrCode, setIsShowQrCode] = useState(props.initShowQR);
  
  const[buisenessPageUrl,setBuisenessPageUrl]  = useState<string>();
  const[buisenessPageUrlQR,setBuisenessPageUrlQR]  = useState<string>();
  
  const shareText = "בואו תשאירו לי ביקורת באתר: ";

  const emailRef = React.createRef<any>();
  const facebookRef = React.createRef<any>();
  const whatsappRef = React.createRef<any>();
  const twitterRef = React.createRef<any>();
  const smsIOSRef = React.createRef<any>();
  const smsRef = React.createRef<any>();
  //const mailRef = React.createRef<any>();
  const ionActionSheetRef = useRef<HTMLIonActionSheetElement>(null);
  const { IsNativeApp, OpenWhatsApp, MailTo, SendSms } = NativeApp();

  const onBackButtonEvent = () => {
    if (ionActionSheetRef.current) {
      ionActionSheetRef.current.dismiss();
    } else {
      props.closeModal();
    }
  };
  const closeByEsc = (e: any) => {
    if (e.key === "Escape") {
      onBackButtonEvent();
    }
  };
  useEffect(() => {
    if (MemberDetailsCxt.GeneralInfo == null) {
      MemberDetailsCxt.initMemberDetailsContext();
    }
    window.addEventListener("popstate", onBackButtonEvent);
    if (
      window.history.state == null ||
      window.history.state.name == null ||
      window.history.state.name !== "ShareModal"
    ) {
      window.history.pushState({ name: "ShareModal" }, "ShareModal", null);
    }
    if (!isMobile && props.closeModal !== undefined) {
      window.addEventListener("keydown", closeByEsc);
      return function cleanupListener() {
        window.removeEventListener("keydown", closeByEsc);
      };
    }
  }, []);

  useEffect(()=>{
if(MemberDetailsCxt.GeneralInfo != null){
  setBuisenessPageUrl(MemberDetailsCxt.GeneralInfo.BuisenessPageUrl + "#opinionsDiv");
   setBuisenessPageUrlQR(MemberDetailsCxt.GeneralInfo.BuisenessPageUrl + "?commentOrigin=QR#opinionsDiv");
}

  },[MemberDetailsCxt.GeneralInfo]);

  function SetDtlPushEvtInner(user_action: string) {
    SetDtlPushEvt(
      "reviews",
      "request_review_by_" + user_action,
      "" +
        (MemberDetailsCxt.GeneralInfo
          ? MemberDetailsCxt.GeneralInfo?.CompanyName
          : ""),
      GetMemberAdvCategories(MemberDetailsCxt.AdvCategories),
      MemberDetailsCxt.BusinessAddress?.City
    );
  }

  return (
    <React.Fragment>
      
      {buisenessPageUrl && <>
        <EmailShareButton
        ref={emailRef}
        url={buisenessPageUrl}
        separator={" "}
        subject="דף העסק שלי"
        body={shareText}
      >
        <div></div>
      </EmailShareButton>

      <WhatsappShareButton
        ref={whatsappRef}
        url={buisenessPageUrl}
        title={shareText}
        // media={"assets/icon/B144_Logo_2017.svg"}
      >
        <div></div>
      </WhatsappShareButton>

      <FacebookShareButton
        ref={facebookRef}
        url={buisenessPageUrl}
        quote={shareText}
      >
        <div></div>
      </FacebookShareButton>

      <TwitterShareButton
        ref={twitterRef}
        url={buisenessPageUrl}
        title={shareText}
      >
        <div></div>
      </TwitterShareButton>

        <a href={`sms:&body=${shareText}${buisenessPageUrl}`} hidden={true} ref={smsIOSRef}>
        SMS
      </a>
      <a href={`sms:?&body=${shareText}${buisenessPageUrl}`} hidden={true} ref={smsRef}>
        SMS
      </a>
      {/* <a href={`mailto:?subject=${'דף העסק שלי'}&body=${shareText}${buisenessPageUrl}`} hidden={true} ref={mailRef}>
        EMAIL
      </a> */}
      </>}
      
      {isShowQrCode == true && buisenessPageUrlQR && (
        <QrCodeModule
          closeModal={() => {
            props.closeModal();
            document.getElementById("qrIamge") &&
              document.body.removeChild(document.getElementById("qrIamge")!);
            document.getElementById("bussinesDataContainer") &&
              document.body.removeChild(
                document.getElementById("bussinesDataContainer")!
              );
            document.getElementById("downloadToPdf") &&
              document.body.removeChild(
                document.getElementById("downloadToPdf")!
              );
            setIsShowQrCode(false);
          }}
          url={buisenessPageUrlQR}
          show={isShowQrCode}
        ></QrCodeModule>
      )}
     { buisenessPageUrl && <IonActionSheet
        isOpen={props.show}
        ref={ionActionSheetRef}
        mode="ios"
        onDidDismiss={() => {
          if (isShowQrCode == false) {
            props.closeModal();
          }
        }}
        cssClass="share-popup"
        header="בקשת חוות דעת באמצעות: "
        buttons={[
          {
            text: "Mail",
            icon: mail,
            cssClass: "mail",
            handler: () => {
                SetDtlPushEvtInner("Email");
                if (IsNativeApp) {
                  MailTo(
                    `mailto:?subject=דף העסק שלי&body=${shareText}${buisenessPageUrl}`
                  );
                } else {
                  emailRef.current.click();
                }
            },
          },
          {
            text: "SMS",
            icon: sms,
            cssClass: "sms",
            handler: () => {
              if (IsNativeApp) {
                //const send_sms = 'sms:?&body=' + buisenessPageUrl;
                buisenessPageUrl && SendSms('', buisenessPageUrl);
              } else if (Capacitor.getPlatform() === "ios") {
                smsIOSRef.current.click();
              }  else if (Capacitor.getPlatform() === "android") {
                buisenessPageUrl && SocialSharing.shareViaSMS(buisenessPageUrl, "")
                  .then((e) => {
                    console.log(e);
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              } else {
                smsRef.current.click();
              }
              SetDtlPushEvtInner("SMS");
            },
          },
          {
            text: "Whatsapp",
            icon: logoWhatsapp,
            cssClass: "whatsapp",
            handler: () => {
              SetDtlPushEvtInner("Whatsapp");
              if (isMobile) {
                OpenWhatsApp(
                  `whatsapp://send?text="${buisenessPageUrl}${shareText}`
                );
              } else {
                whatsappRef.current.click();
              }
            },
          },
          {
            text: "Facebook",
            icon: facebookWhite,
             
            cssClass: "facebook" + (IsNativeApp ? ' native-hide' : ''),
            handler: () => {
              facebookRef.current.click();
              SetDtlPushEvtInner("Facebook");
            },
          },
          {
            text: "X",
            icon: twitter_new_white,
            cssClass: "twitter" + (IsNativeApp ? ' native-hide' : ''),
            handler: () => {
              twitterRef.current.click();
              SetDtlPushEvtInner("Twitter");
            },
          },
          {
            text: "QrCode",
            icon: qrWhite,
            cssClass: "qr",
            handler: () => {
              setIsShowQrCode(true);
              SetDtlPushEvtInner("QrCode");
            },
          },
        ]}
      ></IonActionSheet> }
    </React.Fragment>
  );
};
export default ShareOptions;
