import React, { useEffect } from "react";
import {
    IonCheckbox,
    IonCol,
    IonItem,
    IonLabel,
    IonRow
} from "@ionic/react";

import { Control, FieldError, NestDataObject } from "react-hook-form";

import CustomTextarea from "../utils/CustomElements/CustomTextarea";

import VasContactHoursSelect from "./VasContactHoursSelect";
import VasPackSelect from "./VasPackSelect";



const FrmWebsite: React.FC<{

    register: React.Ref<any>;
    control: Control;
    errors?: NestDataObject<Record<string, any>, FieldError>;
    clearError: (name: string) => void;
}> = props => {


    useEffect(() => {

        if (props.control &&
            props.control.fieldsRef &&
            props.control.fieldsRef!.current) {
            //register cbx fields
            if (!props.control.fieldsRef!.current!.isDomain) {
                props.control.register('isDomain');
            }
            //set cbx default value-false
            if (props.control.fieldsRef!.current!.isDomain &&
                props.control.fieldsRef!.current!.isDomain.ref.value == undefined) {
                props.control.setValue('isDomain', false);
            }
        }

    });
    const handleCbxChange = (e: CustomEvent) => {
        props.control.setValue(e.detail.value, e.detail.checked); //for outer form ref
    };
    return (
        <React.Fragment>
            <IonRow>
                <IonCol size='12' sizeMd='6' className='col-1'>
                    <IonRow>
                        <IonCol >
                            <VasPackSelect prod_code={1} register={props.register} control={props.control} errors={props.errors} clearError={props.clearError} />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem lines='none' color='light' className='item-cbx'>
                                <IonLabel>רכישת דומיין</IonLabel>
                                <IonCheckbox slot='end' value='isDomain' onIonChange={handleCbxChange} />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <CustomTextarea name='busDesc'
                                label='תאור העסק'
                                errors={props.errors}
                                control={props.control}
                                ref={props.register}></CustomTextarea>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <CustomTextarea name='colors'
                                label='צבעי אתר'
                                errors={props.errors}
                                control={props.control}
                                ref={props.register}></CustomTextarea>
                        </IonCol>
                    </IonRow>

                   
                </IonCol>
                <IonCol size='12' sizeMd='6' className='col-2'>
                <IonRow>
                        <IonCol>
                            <VasContactHoursSelect register={props.register} control={props.control} />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <CustomTextarea
                                name='remarks'
                                errors={props.errors}
                                control={props.control}
                                label='הערות לצוות בוני האתר'
                                ref={props.register} />
                        </IonCol>
                    </IonRow>
                </IonCol>
            </IonRow>
        </React.Fragment>
    );
};

export default FrmWebsite;