import React, { useContext, useEffect } from 'react';
import { IonGrid, IonRow, IonCol, IonTitle } from '@ionic/react';

import { useForm } from 'react-hook-form';
import MemberDetailsContext, {
  AdvCategories,
} from '../../../data/MemberDetailes-context';
import CustomForm from '../../utils/CustomElements/CustomForm';
import CustomTitleButton from '../../utils/CustomElements/CustomTitleButton';
import CustomModal from '../../utils/CustomElements/CustomModal/CustomModal';
import { HeaderParameters } from '../../../data/Header-Context';

const BusinessMoreProperties: React.FC<{
  show: boolean;
  closeModal: () => void;
  onSave: () => void;
}> = (props) => {
  const { handleSubmit, formState, errors, control } = useForm({
    mode: 'onChange',
  });
  const { dirty } = formState;
  const MemberDetailsCxt = useContext(MemberDetailsContext);
  let ObjArrayByDate: { title: string; objArray: AdvCategories[] }[] = [];
  const setData = () => {
    let arrayTitle: string[] = [];
    MemberDetailsCxt.AdvCategories!.map((cat) => {
      if (cat.Title != '' && arrayTitle.indexOf(cat.CategoryFldDesc) === -1)
        arrayTitle.push(cat.CategoryFldDesc);
    });
    arrayTitle.map((title) => {
      const newObj = MemberDetailsCxt.AdvCategories!.filter((f) => {
        return f.CategoryFldDesc === title && f.IsChecked === true;
      });
      ObjArrayByDate.push({ title: title, objArray: newObj });
    });
    // const emptyTitleObj = MemberDetailsCxt.AdvCategories!.filter((f) => {
    //   return f.Title === '' && f.IsChecked === true;
    // });
    // ObjArrayByDate.push({
    //   title: "תחומי התמחות",
    //   objArray: emptyTitleObj,
    // });
  };
  useEffect(() => {
    if (MemberDetailsCxt.AdvCategories == null) {
      MemberDetailsCxt.initMemberDetailsContext();
    }
  }, []);

  if (MemberDetailsCxt.AdvCategories != null) {
    setData();
  }

  const headerParameters: HeaderParameters = {
    isPageHaveChanges: dirty,
    modalType: 'modal',
  };

  return (
    <CustomModal
      pageName={'BusinessMoreProperties'}
      headerParameters={headerParameters}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
      title={'מאפיינים לעסק'}
      subTitle={'הוספת מאפיינים לדף העיסקי תאפשר ללקוחות לדעת יותר על העסק שלך'}
    >
      <CustomForm onSubmit={() => {}} errors={errors}>
        {ObjArrayByDate &&
          ObjArrayByDate.map((row, index) => {
            return (
              row.objArray.length > 0 && (
                <IonGrid key={index}>
                  <IonRow>
                    <IonCol>
                      <IonTitle>{row.title}</IonTitle>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    {row.objArray.map((col, index) => {
                      return (
                        <IonCol key={index}>
                          <CustomTitleButton
                            disabled={true}
                            BtnTxt={
                              col.Title != '' ? col.Title : col.CategoryFldDesc
                            }
                          ></CustomTitleButton>
                        </IonCol>
                      );
                    })}
                  </IonRow>
                </IonGrid>
              )
            );
          })}
      </CustomForm>
    </CustomModal>
  );
};

export default BusinessMoreProperties;
