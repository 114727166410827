import React, { FC, ComponentProps } from "react";
import { IonDatetime, IonItem, IonLabel } from "@ionic/react";
import {
  Controller,
  Control,
  FieldError,
  NestDataObject,
} from "react-hook-form";

import { ErrorMessage } from "../../../utils-ts/GlobalValidationFunctions";

type IonDatetimeProps = ComponentProps<typeof IonDatetime>;

interface DateTimeProps extends IonDatetimeProps {
  label?: string;
  name: string;
  required: boolean;
  errors?: NestDataObject<Record<string, any>, FieldError>;
  control?: Control;
}

const CustomDateTime: FC<DateTimeProps> = ({
  label,
  required,
  errors,
  name,
  control,
  ...props
}) => {
  return (
    <>
    <IonItem color="light" mode="md" lines="full" className={errors && errors[name] != null ? "item-invalid" : ""}>
      {label && <IonLabel position="stacked">{label}</IonLabel>}
      <Controller
        as={<IonDatetime {...props} cancelText="ביטול" doneText="אישור" ></IonDatetime>}
        name={name}
        control={control}
        defaultValue={props.value}

        rules={{
          required: {
            value: required,
            message: ErrorMessage("required"),
          },
        }}
        onChangeName="onIonChange"
        onChange={([selected]: any) => {
          if (props.onIonChange) {
            props.onIonChange!(selected);
          }
          return selected.detail.value;
        }}
      ></Controller>
      
    </IonItem>
    
        <div className="input-alerts">
          <p className="input-error">{errors && errors[name] != null && (errors as any)[name].message}</p>
        </div>
    
    </>
  );
};

export default CustomDateTime;
