import React from "react";

export interface PriceListItem {
  Id?: number;
  PriceListProductId: number;
  PriceListId: number;
  Title: string;
  AdditionalData1: string;
  MinPrice: number;
  MaxPrice: number;
  LiteralPrice: string;
}

export interface PrivatePriceList {
  MemberId: string;
  PriceListItems: PriceListItem[];
}


export interface CategoryPriceList {
  PriceListId: number;
  CatCode: string;
  CatName: string;
  PriceListItems: PriceListItem[];
}

const PriceListContext = React.createContext<{
  CategoryPriceLists: CategoryPriceList[];
  MemberPriceList: PrivatePriceList | undefined;
  GetPriceListByMemberId: (memberId: string) => void;
  UpdatePriceListItem: (itemToUpdate: PriceListItem, priceListId: number) => void;
  DeletePriceListItem: (itemToDelete: PriceListItem) => void;
}>({
  CategoryPriceLists: [],
  MemberPriceList: undefined,
  GetPriceListByMemberId: () => { },
  UpdatePriceListItem: () => { },
  DeletePriceListItem: () => { },
});

export default PriceListContext;
