import React from "react";

export default function B144Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M30.07 15.118H17.45v-2.583h9.562l-.765-2.39 2.276-.613 1.549 4.725v.86zm0 8.989l-8.376-3.347v3.06h3.538v2.582h-3.538v1.607h-2.18v-1.607h-2.065V23.82h2.065v-6.274h.688l9.869 3.902v2.659zm0 11.8l-8.376-3.347v3.06h3.538v2.583h-3.538v1.606h-2.18v-1.606h-2.065V35.62h2.065v-6.274h.688l9.869 3.903v2.658zM40.613 9.661C36.942-6.046 4 2.687 4 11.74c0 4.22 3.234 6.959 3.234 12.733 0 8.006-3.01 10.93-3.01 14.428 0 2.12 1.37 3.138 2.88 4.078C21.802 51.4 41.908 45.23 43.75 35.805c.951-4.87-2.55-9.312-8.504-12.871 4.584-5.121 6.398-8.852 5.366-13.273z"
      ></path>
    </svg>
  );
}
