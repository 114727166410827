import React from 'react'
import {
  b144LogoBlue,
  facebook,
  google,
  landing_page,
  website,
} from '../images/index'
import AppBusinessPage from '../pages/CoSale/CoSaleContentPages/App/AppBusinessPage/AppBusinessPage'
import AppHomePage from '../pages/CoSale/CoSaleContentPages/App/AppHomePage/AppHomePage'
import AppMailPage from '../pages/CoSale/CoSaleContentPages/App/AppMailPage/AppMailPage'
import AppStatisticsPage from '../pages/CoSale/CoSaleContentPages/App/AppStatisticsPage/AppStatisticsPage'
import B144IntroPage from '../pages/CoSale/CoSaleContentPages/B144/B144IntroPage/B144IntroPage'
import BusinessPage from '../pages/CoSale/CoSaleContentPages/B144/BusinessPage/BusinessPage'
import GoogleResultsPage1 from '../pages/CoSale/CoSaleContentPages/B144/GoogleResultsPage/GoogleResultsPage'
import ResultsPage from '../pages/CoSale/CoSaleContentPages/B144/ResultsPage/ResultsPage'
import FacebookBenefitsPage from '../pages/CoSale/CoSaleContentPages/FacebookAd/FacebookBenefitsPage/FacebookBenefitsPage'
import FacebookFeedPage from '../pages/CoSale/CoSaleContentPages/FacebookAd/FacebookFeedPage/FacebookFeedPage'
import FacebookIntroPage from '../pages/CoSale/CoSaleContentPages/FacebookAd/FacebookIntroPage/FacebookIntroPage'
import FacebookLandingPage from '../pages/CoSale/CoSaleContentPages/FacebookAd/FacebookLandingPage/FacebookLandingPage'
import GoogleBenefitsPage from '../pages/CoSale/CoSaleContentPages/GoogleAd/GoogleBenefitsPage/GoogleBenefitsPage'
import GoogleIntroPage from '../pages/CoSale/CoSaleContentPages/GoogleAd/GoogleIntroPage/GoogleIntroPage'
import GoogleLandingPage from '../pages/CoSale/CoSaleContentPages/GoogleAd/GoogleLandingPage/GoogleLandingPage'
import GoogleResultsPage2 from '../pages/CoSale/CoSaleContentPages/GoogleAd/GoogleResultsPage/GoogleResultsPage'
import AppReviewsPage from '../pages/CoSale/CoSaleContentPages/App/AppReviewsPage/AppReviewsPage'

export interface CoSaleSubject {
  Type:
    | 'General'
    | 'B144'
    | 'Website'
    | 'GoogleAd'
    | 'FacebookAd'
    | 'LandingPage'
    | 'GMB'
    | 'App'
  SubType?: 'Start' | 'Finish'
  Title: string
  Icon?: string
  Items: CoSaleObj[]
}
export type CoSaleObj =
  | {
      Title?: string
      Content?: string
      Type: 'Page' | 'Alert'
    }
  | {
      Title?: string
      Type: 'ReactPage'
      Content: () => JSX.Element
    }

var ws_1 = require('../pages/CoSale/CoSaleContentPages/Website/coSale_ws_1.htm.js')

var lp_1 = require('../pages/CoSale/CoSaleContentPages/LandingPage/coSale_lp_1.htm.js')

// var fb_ad_1 = require("../pages/CoSale/CoSaleContentPages/FacebookAd/coSale_fbAd_1.htm.js");
// var fb_ad_2 = require("../pages/CoSale/CoSaleContentPages/FacebookAd/coSale_fbAd_2.htm.js");
// var fb_ad_3 = require("../pages/CoSale/CoSaleContentPages/FacebookAd/coSale_fbAd_3.htm.js");

//var ggl_ad_1 = require("../pages/CoSale/CoSaleContentPages/GoogleAd/coSale_gglAd_1.htm.js");
// var ggl_ad_2 = require("../pages/CoSale/CoSaleContentPages/GoogleAd/coSale_gglAd_2.htm.js");
//var ggl_ad_3 = require("../pages/CoSale/CoSaleContentPages/GoogleAd/coSale_gglAd_3.htm.js");

export const CoSaleSubjects: {
  [key: number]: CoSaleSubject
} = {
  0: {
    Type: 'B144',
    Title: 'קידום באתר B144',
    Icon: b144LogoBlue,
    Items: [
      // {
      //   Type: 'ReactPage',
      //   Content: B144IntroPage,
      // },
      // {
      //   Type: 'ReactPage',
      //   Content: GoogleResultsPage1,
      // },
      // {
      //   Title: 'דף תוצאות חיפוש',
      //   Type: 'ReactPage',
      //   Content: ResultsPage,
      // },
      // {
      //   Title: 'דף העסק שלך',
      //   Type: 'ReactPage',
      //   Content: BusinessPage,
      // },

      // DEMO
      {
        Type: 'ReactPage',
        Content: B144IntroPage,
      },
      {
        Type: 'ReactPage',
        Content: GoogleResultsPage1,
      },
      {
        Title: 'דף תוצאות חיפוש',
        Type: 'ReactPage',
        Content: ResultsPage,
      },
      {
        Title: 'דף העסק שלך',
        Type: 'ReactPage',
        Content: BusinessPage,
      },
      {
        Type: 'ReactPage',
        Content: AppHomePage,
      },
      {
        Type: 'ReactPage',
        Content: AppStatisticsPage,
      },
      {
        Type: 'ReactPage',
        Content: AppBusinessPage,
      },
      {
        Type: 'ReactPage',
        Content: AppReviewsPage,
      },
      {
        Type: 'ReactPage',
        Content: AppMailPage,
      },
      {
        Type: 'ReactPage',
        Content: GoogleIntroPage,
      },
      {
        Type: 'ReactPage',
        Content: GoogleResultsPage2,
      },
      {
        Type: 'ReactPage',
        Content: GoogleLandingPage,
      },
      {
        Type: 'ReactPage',
        Content: GoogleBenefitsPage,
      },
      {
        Type: 'ReactPage',
        Content: FacebookIntroPage,
      },
      {
        Type: 'ReactPage',
        Content: FacebookFeedPage,
      },
      {
        Type: 'ReactPage',
        Content: FacebookLandingPage,
      },
      {
        Type: 'ReactPage',
        Content: FacebookBenefitsPage,
      },
    ],
  },
  100: {
    Type: 'App',
    Title: 'אפליקציה לעסקים',
    Icon: website,
    Items: [
      {
        Type: 'ReactPage',
        Content: AppHomePage,
      },
      {
        Type: 'ReactPage',
        Content: AppStatisticsPage,
      },
      {
        Type: 'ReactPage',
        Content: AppBusinessPage,
      },
      {
        Type: 'ReactPage',
        Content: AppReviewsPage,
      },
      {
        Type: 'ReactPage',
        Content: AppMailPage,
      },
    ],
  },
  1: {
    Type: 'Website',
    Title: 'אתר אינטרנט',
    Icon: website,
    Items: [
      {
        Type: 'Page',
        Content: ws_1,
      },
    ],
  },
  4: {
    Type: 'GoogleAd',
    Title: 'מודעות גוגל',
    Icon: google,
    Items: [
      {
        Type: 'ReactPage',
        Content: GoogleIntroPage,
      },
      {
        Type: 'ReactPage',
        Content: GoogleResultsPage2,
      },
      {
        Type: 'ReactPage',
        Content: GoogleLandingPage,
      },
      {
        Type: 'ReactPage',
        Content: GoogleBenefitsPage,
      },
    ],
  },
  18: {
    Type: 'FacebookAd',
    Title: 'קידום ב Facebook',
    Icon: facebook,
    Items: [
      {
        Type: 'ReactPage',
        Content: FacebookIntroPage,
      },
      {
        Type: 'ReactPage',
        Content: FacebookFeedPage,
      },
      {
        Type: 'ReactPage',
        Content: FacebookLandingPage,
      },
      {
        Type: 'ReactPage',
        Content: FacebookBenefitsPage,
      },
    ],
  },
  20: {
    Type: 'LandingPage',
    Title: 'דף נחיתה',
    Icon: landing_page,
    Items: [
      {
        Type: 'Page',
        Content: lp_1,
      },
    ],
  },
  24: {
    Type: 'GMB',
    Title: 'דף עסק בגוגל',
    Icon: google,
    Items: [],
  },
}

const CoSaleContext = React.createContext<{
  clientName: string
  coSaleSubjects: CoSaleSubject[] | undefined

  getCoSaleByToken: (token: string) => any
}>({
  clientName: '',
  coSaleSubjects: undefined,
  getCoSaleByToken: () => {},
})

export default CoSaleContext
