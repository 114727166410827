import React, { useState } from 'react';
import { IonRow, IonCol, IonButton, IonIcon, IonText } from '@ionic/react';
import { OpenDays } from '../../../data/MemberDetailes-context';
import { getTimeFotmat, getTimeObjFromIsoString } from '../../../utils-ts/GlobalDateFunctions';
import { useForm } from 'react-hook-form';
import CustomDateTime from '../../utils/CustomElements/CustomDateTime';
import '../businessCardTabs.scss';
import { trash } from 'ionicons/icons';
import { closeBlack } from '../../../images';
import { toast } from 'react-toastify';

const HandleMemberOpenHours: React.FC<{
  //show: boolean;
  onCloseModal: () => void;
  onSave: (OpenDays: OpenDays) => void;
  onDelete: () => void;
  onChange: () => void;
  openDay: OpenDays;
}> = (props) => {
  const { handleSubmit, formState, errors, control } = useForm({
    mode: 'onChange',
  });

 
  const [currOpenDay, setCurrOpenDay] = useState<OpenDays>(Object.assign({}, props.openDay)); //keep original val
  const [weekDays, setWeekDays] = useState<Array<{ name: string; day: 'Day1' | 'Day2' | 'Day3' | 'Day4' | 'Day5' | 'Day6' | 'Day7'; checked: boolean }>>([
    { name: 'א', day: 'Day1', checked: props.openDay['Day1'] },
    { name: 'ב', day: 'Day2', checked: props.openDay['Day2'] },
    { name: 'ג', day: 'Day3', checked: props.openDay['Day3'] },
    { name: 'ד', day: 'Day4', checked: props.openDay['Day4'] },
    { name: 'ה', day: 'Day5', checked: props.openDay['Day5'] },
    { name: 'ו', day: 'Day6', checked: props.openDay['Day6'] },
    { name: 'ש', day: 'Day7', checked: props.openDay['Day7'] },
  ]);

  const onSaveClicked = () => {
    
    if (weekDays.filter((d) => d.checked).length == 0) {
      toast('לא נבחרו ימים');
      return;
    }
    const fromTime = getTimeFotmat(currOpenDay.FromHour!, currOpenDay.FromHourMinutes!);
    const toTime = getTimeFotmat(currOpenDay.ToHour!, currOpenDay.ToHourMinutes!);
    // if (Date.parse('01/01/2011 ' + fromTime) > Date.parse('01/01/2011 ' + toTime)) {
    //   setError('שעת סיום קטנה משעת התחלה');
    //   return;
    // }
    if (Date.parse('01/01/2011 ' + fromTime) == Date.parse('01/01/2011 ' + toTime)) {
      toast('שעת סיום זהה לשעת התחלה');
      return;
    }

    props.onSave(currOpenDay);
  };
  const setDayClicked = (index: number, day: 'Day1' | 'Day2' | 'Day3' | 'Day4' | 'Day5' | 'Day6' | 'Day7') => {
    props.onChange();
    const curr_val = currOpenDay[day];
    currOpenDay[day] = !curr_val;
    setCurrOpenDay(currOpenDay);

    setWeekDays((curr) => {
      const curr_items = [...curr];
      const curr_val = curr_items[index].checked;
      curr_items[index].checked = !curr_val;
      return curr_items;
    });
  };

  return (
    <>
      <IonCol style={{ paddingTop:5,paddingBottom:0 }}>
        <IonRow>
          <IonCol className='ion-no-padding ion-text-end'>
            <IonButton title='ביטול' 
            className='ion-no-padding' 
            fill="clear" onClick={()=>{ 
              props.onCloseModal()}}>
              <IonIcon slot="icon-only" icon={closeBlack}></IonIcon>
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol style={{ paddingLeft: 8, paddingTop:0 }}>
            <CustomDateTime
              label="פתיחה" 
              required={true}
              displayFormat="HH:mm"
              name="fromtime"
              placeholder="בחר שעה"
              value={getTimeFotmat(currOpenDay.FromHour!, currOpenDay.FromHourMinutes!)}
              control={control}
              errors={errors}
              minuteValues="0,15,30,45"
              onIonChange={(e) => {
                //console.log(e.detail.value);
                props.onChange();
                let time = '';
                if (e.detail.value) {
                  time = getTimeObjFromIsoString(e.detail.value);
                }
                if (time) {
                  const toTimeArray = time!.split(':');
                  currOpenDay.FromHour = Number(toTimeArray[0]);
                  currOpenDay.FromHourMinutes = Number(toTimeArray[1]);

                  const fromTime = getTimeFotmat(currOpenDay.FromHour!, currOpenDay.FromHourMinutes!);
                  const toTime = getTimeFotmat(currOpenDay.ToHour!, currOpenDay.ToHourMinutes!);
                  if (Date.parse('01/01/2011 ' + fromTime) > Date.parse('01/01/2011 ' + toTime)) {
                    currOpenDay.IsNextDay = true;
                  } else {
                    currOpenDay.IsNextDay = false;
                  }
                }
              }}></CustomDateTime>
          </IonCol>
          <IonCol style={{ paddingRight: 8, paddingTop:0 }}>
            <CustomDateTime
              label="סגירה"
              required={true}
              displayFormat="HH:mm"
              name="totime"
              placeholder="בחר שעה"
              value={getTimeFotmat(currOpenDay.ToHour!, currOpenDay.ToHourMinutes!)}
              control={control}
              errors={errors}
              minuteValues="0,15,30,45"
              onIonChange={(e) => {
                props.onChange();
                let time = '';
                if (e.detail.value) {
                  time = getTimeObjFromIsoString(e.detail.value);
                }
                if (time) {
                  const toTimeArray = time!.split(':');
                  currOpenDay.ToHour = Number(toTimeArray[0]);
                  currOpenDay.ToHourMinutes = Number(toTimeArray[1]);
                  const fromTime = getTimeFotmat(currOpenDay.FromHour!, currOpenDay.FromHourMinutes!);
                  const toTime = getTimeFotmat(currOpenDay.ToHour!, currOpenDay.ToHourMinutes!);
                  if (Date.parse('01/01/2011 ' + fromTime) > Date.parse('01/01/2011 ' + toTime)) {
                    currOpenDay.IsNextDay = true;
                  } else {
                    currOpenDay.IsNextDay = false;
                  }
                }
              }}></CustomDateTime>
          </IonCol>
        </IonRow>
        {currOpenDay.IsNextDay && (
          <IonRow>
            <IonCol>
              <IonText color="danger">
                <p>לתשומת לבך - הגדרת שהעסק יסגר ביום למחרת</p>
              </IonText>
            </IonCol>
          </IonRow>
        )}
        <IonRow>
          <IonCol>
            <p>ימים</p>
            <div className='toggle-day-cont'>
            {weekDays.map((day, i) => {
              return (
                <IonButton
                  key={i}
                  size="small"
                  fill="clear"
                  style={{fontSize:14}}
                  shape="round"
                  className={'toggle-day ' + (day.checked ? 'toggle-token' : '')}
                  onClick={() => {
                    setDayClicked(i, day.day);
                  }}>
                  {day.name}
                </IonButton>
              );
            })}
            </div>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className='ion-text-center ion-padding-top ion-padding-bottom'>
            <IonButton
              color="primary" size='large'
              style={{width: (parseInt(currOpenDay.Id) > 0 ? 132 : 224), height: 40, fontSize: 18, fontWeight:700}}
              onClick={() => {
                onSaveClicked();
              }}>
              שמירה
            </IonButton>
            { parseInt(currOpenDay.Id) > 0 && (
           
              <IonButton
                fill="clear"
                style={{fontSize: 16, marginRight:10}}
                onClick={() => {
                  props.onDelete();
                }}>
                מחיקה
                <IonIcon slot="start" icon={trash}></IonIcon>
              </IonButton>
            
          )}
          </IonCol>
          
        </IonRow>
      </IonCol>
    </>
  );
};

export default HandleMemberOpenHours;
