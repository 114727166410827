import React from "react";
import background from "./app-mail-page.webp";
import Highlighter from "../../components/Highlighter/Highlighter";

export default function AppMailPage() {
  return (
    <div style={{ position: "relative", marginTop: "2.2%" }}>
      <img src={background} alt="App mail page" width="100%" />
      <Highlighter
        title="הודעות"
        text="טקסט"
        style={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          top: "41%",
          height: "27.5%",
          width: "95%",
        }}
      />
    </div>
  );
}
