import React, { useContext, useEffect, useRef } from 'react';
import { IonButton, IonGrid, IonRow, IonCol, IonItem, IonIcon, IonList, IonCard, IonLabel } from '@ionic/react';
import ProductsContext, { GoogleMerchantEnum, OrderStatus, OrderStatusDesc } from '../../../data/Products-context';

import { trash } from 'ionicons/icons';
import { document, phone, serviceAreas, mail } from '../../../images/index';
import CustomModal from '../../utils/CustomElements/CustomModal/CustomModal';

import { HeaderParameters } from '../../../data/Header-Context';
import { useForm } from 'react-hook-form';
import CustomInput from '../../utils/CustomElements/CustomInput';

const GoogleShopVerification: React.FC<{
  show: boolean;
  closeModal: () => void;
}> = (props) => {
  const ProductsCtx = useContext(ProductsContext);

  const { handleSubmit, formState, errors, control } = useForm({
    mode: 'onChange',
  });

  const mobilePhoneTextRef = useRef<HTMLIonInputElement>(null);
  const smsCodeTextRef = useRef<HTMLIonInputElement>(null);

  useEffect(() => {}, []);

  const sendGoogleShopVerificationCode = () => {
    const mobile_phone = mobilePhoneTextRef.current!.value;
    if (mobile_phone) {
      ProductsCtx.sendGoogleShopVerificationCode(mobile_phone!.toString());
    }
  };

  const verifyGoogleShop = () => {
    const sms_code = smsCodeTextRef.current!.value;
    if (sms_code) {
      ProductsCtx.verifyGoogleShop(sms_code!.toString());
    }
  };

  const headerParameters: HeaderParameters = {
    isPageHaveChanges: false,
    modalType: 'modal',
  };

  return (
    <>
      <CustomModal
        pageName={'GoogleShopVerification'}
        closeModal={() => {
          props.closeModal();
        }}
        isOpen={props.show}
        headerParameters={headerParameters}
        title={'אימות חנות גוגל'}
        subTitle="סיימנו את שלב ההקמה של החנות בגוגל, כעת נשאר לנו רק לאמת אותה מול גוגל">
        <>
          {ProductsCtx.GoogleShopVerificationCode != undefined && ProductsCtx.GoogleShopVerificationCode.length == 0 && (
            <>
              {/* STEP 1 */}
              <IonLabel style={{ marginBottom: 15, display: 'block' }}>
                במהלך האימות מול גוגל עליכם להזין את מספר הנייד שלכם ולהזין את ה-SMS שיתקבל מגוגל בשדה הייעודי וללחוץ על כפתור השליחה
              </IonLabel>

              <CustomInput
                name="mobilePhone"
                errors={errors}
                control={control}
                label="טלפון סלולרי"
                ref={mobilePhoneTextRef}
                customType="mobile_phone"
                value={''}
                required={true}
                maxlength={10}></CustomInput>
              <IonButton color="primary" onClick={sendGoogleShopVerificationCode}>
                שליחה
              </IonButton>
            </>
          )}
          {ProductsCtx.GoogleShopVerificationCode != undefined && 
          ProductsCtx.GoogleShopVerificationCode.length > 0 && 
          ProductsCtx.GoogleMerchantStage != GoogleMerchantEnum.Verified &&(
            <>
              {/* STEP 2 */}
              <IonLabel style={{ marginBottom: 15, display: 'block' }}>
                ברגעים אלו ממש נשלח אליך SMS לצרכי אימות החנות שלך בגוגל, יש להזין את הקוד שיתקבל ב-SMS בשדה הייעודי.
              </IonLabel>

              <CustomInput name="smsCode" errors={errors} control={control} label="קוד אימות" ref={smsCodeTextRef} value={''} required={true} maxlength={10}></CustomInput>
              <IonButton color="primary" onClick={verifyGoogleShop}>
                אימות קוד סמס
              </IonButton>
              <IonButton
                className="button"
                fill="outline"
                onClick={() => {
                  ProductsCtx.resetGoogleShopVerificationCode();
                }}>
                שליחה מחדש
              </IonButton>
            </>
          )}
          {ProductsCtx.GoogleMerchantStage === GoogleMerchantEnum.Verified && (
            <>
              {/* STEP 3 */}
              <IonLabel>יש! האימות הצליחה, כעת החנות שלכם באוויר!</IonLabel>
              <IonButton
                color="primary"
                onClick={() => {
                  props.closeModal();
                }}>
                סיום
              </IonButton>
            </>
          )}
        </>
      </CustomModal>
    </>
  );
};

export default GoogleShopVerification;
