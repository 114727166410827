import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IonRow,
  IonCol,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonCheckbox,
  IonIcon,
  IonPopover,
  IonCard,
  IonButton,
  IonContent,
} from "@ionic/react";
import { isMobile } from 'react-device-detect';
import SelfSaleContext, { MemberAreas, Categories, CategoriesAdv } from "../../../data/SelfSaleContext";
import { el } from "date-fns/locale";
//import CustomCheckbox from '../CustomElements/CustomCheckbox';
const SelfSaleSummaryNew: React.FC<{ saleType: string }> = (props) => {
  const SelfSaleCtx = useContext(SelfSaleContext);
  const [categoriesSummary, setCategoriesSummary] = useState<string[]>([]);
  const [categoriesAdvSummary, setCategoriesAdvSummary] = useState<string[]>([]);
  const [areaSummary, setAreaSummary] = useState<string>("");
  const [budgetSummary, setBudgetSummary] = useState<string>("");
  const [adgroupSummary, setAdgroupSummary] = useState<string>("");
  const [adsAdditionalSummary, setAdsAdditionalSummary] = useState<string>("");
  const [termsOfService, setTermsOfService] = useState<boolean>(false)
  const [spamApproved, setSpamApproved] = useState<boolean>(false)

  useEffect(() => {
    SelfSaleCtx.setShowSummary(true)
    SelfSaleCtx.getMemberCategories().then((res: any) => {
      if (res && res.length > 0) {
        res.forEach((category: any) => {
          let categoryData = category.category;
          let categoryAdvFieldsArr: string[] = []
          SelfSaleCtx.getCategoriesAdv(category.cat_code)
            .then((res: any) => {
              if (res && res.length) {
                res.forEach((i: CategoriesAdv) => {
                  if (i.fld_type === "c" && i.selected) {
                    categoryAdvFieldsArr.push(i.fld_desc)
                  }
                  else if (i.fld_type === "d") {
                    i.list_options.forEach(o => {
                      if (o.selected) {
                        categoryAdvFieldsArr.push(o.fld_option_desc)
                      }
                    })
                  }
                })
              }
              setCategoriesSummary(prevState => ([...prevState, categoryData]))
              setCategoriesAdvSummary(prevState => ([...prevState, categoryAdvFieldsArr.join(", ")]))
            })
        })
      }
    })
    SelfSaleCtx.getMemberAreas().then((res: MemberAreas) => {
      if (res && res.cities_result) {
        setAreaSummary(res.cities_result.map(item => item.city).join(', '))
      }
      else {
        console.log("else: ", SelfSaleCtx.memberAreas)

      }
    })
    SelfSaleCtx.getMemberAdvertisingBudget(props.saleType)
      .then((res: any) => {
        if (res && res.length) {
          let selected = res.find((item: any) => item.selected == true);
          if (selected) {
            setBudgetSummary(`עד ${selected.TotalAmount} ₪ (${selected.BudgetDesc})`)
          }
        }
      })
    if (props.saleType === "2") {
      if (SelfSaleCtx.memberAdgroups && SelfSaleCtx.memberAdgroups.length > 0) {
        let memberAdgroupArr = SelfSaleCtx.memberAdgroups.filter(ag => ag.selected);
        let memberAdgroup = memberAdgroupArr && memberAdgroupArr.length ? memberAdgroupArr[0].name : "";
        setAdgroupSummary(memberAdgroup)
      }
      else {
        SelfSaleCtx.getResllerSubCategories("")
          .then((res: any) => {
            let memberAdgroupArr = res.filter((g: any) => g.selected);
            let memberAdgroup = memberAdgroupArr && memberAdgroupArr.length ? memberAdgroupArr[0].name : "";
            setAdgroupSummary(memberAdgroup)
          })
      }
      if (SelfSaleCtx.googleAdsAdditionalSummary === "") {
        SelfSaleCtx.getGoogleAdsAdditional()
          .then((res: any) => {
            if (res) {
              let summaryArray = []
              let actionCallText = ""
              let procsText = ""
              let discountText = res.discount_pct

              if (res.action_call === "1") {
                actionCallText = "התקשרו עכשיו"
              }
              else if (res.action_call === "2") {
                actionCallText = "בואו לבקר"
              }
              if (res.is_consultency === "1") {
                actionCallText = "לפגישת יעוץ בחינם השאירו פרטים"
              }
              else if (res.is_consultency === "2") {
                actionCallText = "חייגו עכשיו ליעוץ טלפוני חינם"
              }
              if (res.attractive_prices || res.experienced_team || res.best_service || res.professional_service) {
                procsText = res.attractive_prices ? "מחירים אטרקטיביים" : res.experienced_team ? "צוות מנוסה" : res.best_service ? "שירות ברמה הגבוהה ביותר" : res.professional_service ? "שירות מקצועי" : res.free_text;
              }

              if (actionCallText !== "" && actionCallText !== "ללא קריאה לפעולה") {
                summaryArray.push(`קריאה לפעולה: ${actionCallText}`)
              }

              if (discountText !== "" && discountText !== "0") {
                summaryArray.push(`אחוז הנחה: ${discountText}%`)
              }
              if (procsText !== "") {
                summaryArray.push(`יתרונות: ${procsText}`)
              }
              if (res.is_service_24 !== "0") {
                let service24Txt = res.is_service_24 === "5" ? "שירות 24/5 מסביב לשעון (כולל לילות)" : res.is_service_24 === "7" ? "שירות 24/7 מסביב לשעון (כולל שישי שבת ולילות)" : ""
                summaryArray.push(service24Txt)
              }
              setAdsAdditionalSummary(summaryArray.join(", "))
            }
          })
      }
      else {
        setAdsAdditionalSummary(SelfSaleCtx.googleAdsAdditionalSummary)
      }
    }
  }, [])
  useEffect(() => {
    if (termsOfService) {
      SelfSaleCtx.setFinalStep(true)
    }
    else SelfSaleCtx.setFinalStep(false)
  }, [termsOfService])
  useEffect(() => {
    const spamApprovedValue = spamApproved ? '1' : '0';
    SelfSaleCtx.saveSpamApproved(spamApprovedValue)
  }, [spamApproved])
  return (
    <>
      <IonCard mode="md" className={isMobile ? "self-sale-card self-sale-card-mobile" : "self-sale-card"}>
        <h2 className="ion-no-margin self-sale-section-title">סיימנו! הנה סיכום הקמפיין שלך לעסק {SelfSaleCtx.businessName}:</h2>
        <p className="ion-no-margin self-sale-section-subtitle">אזור שירות:</p>
        <p className="ion-no-margin self-sale-section-text">{areaSummary}</p>
        <p className="ion-no-margin self-sale-section-subtitle">קטגוריה:</p>
        {categoriesSummary.length && (categoriesSummary.map((sum, index) => <p className="ion-no-margin self-sale-section-text" key={index}>{sum}</p>))}
        {props.saleType === "1" && categoriesAdvSummary.length && (<p className="ion-no-margin self-sale-section-subtitle">תחומי ההתמחות של העסק שלך:</p>)}
        {props.saleType === "1" && categoriesAdvSummary.length && (categoriesAdvSummary.map((sum, index) => <p className="ion-no-margin self-sale-section-text" key={index}>{sum}</p>))}
        {adgroupSummary && (
          <>
            <p className="ion-no-margin self-sale-section-subtitle">תחומי ההתמחות בהם העסק יופיע בגוגל:</p>
            <p className="ion-no-margin self-sale-section-text">{adgroupSummary}</p>
          </>
        )}
        <p className="ion-no-margin self-sale-section-subtitle">תקציב פרסום חודשי:</p>
        <p className="ion-no-margin self-sale-section-text">{budgetSummary}</p>
        {adsAdditionalSummary && (
          <>
            <p className="ion-no-margin self-sale-section-subtitle">תוספות למודעות:</p>
            <p className="ion-no-margin self-sale-section-text">{adsAdditionalSummary}</p>
          </>
        )}


        <IonItem mode="md" lines="none" className="item-radio terms-checkbox">
          <IonCheckbox
            mode="md"
            slot="start"
            className="item-radio-btn" checked={termsOfService} onIonChange={(e) => setTermsOfService(e.detail.checked)}>

          </IonCheckbox>

          <IonLabel className="item-radio-label">קראתי ואישרתי את
            <IonButton className="terms-link-btn" size="small" href="https://www.b144.co.il/TermsOfService/" target="_blank" fill="clear">תנאי השירות</IonButton>
          </IonLabel>
        </IonItem>
        {props.saleType === "1" && (
          <IonItem mode="md" lines="none" className="item-radio terms-checkbox">
            <IonCheckbox
              mode="md"
              slot="start"
              className="item-radio-btn" checked={spamApproved} onIonChange={(e) => setSpamApproved(e.detail.checked)}>
            </IonCheckbox>
            <IonLabel className="item-radio-label">אני מאשר/ת לשלוח אלי דיוור</IonLabel>
          </IonItem>

        )}
      </IonCard>
    </>
  );
};

export default SelfSaleSummaryNew;