import React, { useRef, useState } from "react";
import {  IonGrid,  IonRow,  IonCol,IonItem,IonLabel,IonCheckbox  } from "@ionic/react";
import { isMobile } from "react-device-detect";
import { MemberProduct } from "../../../data/Products-context";
import ImagePicker, { Photo } from "../../utils/ImagePicker/ImagePicker";
import CustomInput from "../../utils/CustomElements/CustomInput";
import { useForm } from "react-hook-form";
import { base64FromPath } from "@ionic/react-hooks/filesystem";
import CustomForm from "../../utils/CustomElements/CustomForm";
import CustomPublishButton from "../../utils/CustomElements/CustomPublishButton";
import CustomModal from "../../utils/CustomElements/CustomModal/CustomModal";
import { HeaderParameters } from "../../../data/Header-Context";
import { toast } from "react-toastify";

const AddEditMemberProduct: React.FC<{
  show: boolean;
  product: MemberProduct | undefined;
  closeModal: () => void;
  onSave: (product: MemberProduct) => void;
  onDelete: (productid: number) => void;
}> = (props) => {
  const { handleSubmit, formState, errors, control } = useForm({
    mode: "onChange",
  });
  const { dirty } = formState;
  const [isPhotoChange, setIsPhotoChange] = useState(false);
  const [isPageChange, setisPageChange] = useState(false);
  const productNameTextRef = useRef<HTMLIonInputElement>(null);
  const productCatNumberTextRef = useRef<HTMLIonInputElement>(null);
  const productDescriptionTextRef = useRef<HTMLIonInputElement>(null);
  const productLongDescriptionTextRef = useRef<HTMLIonInputElement>(null);
  const productPriceTextRef = useRef<HTMLIonInputElement>(null);
  const productActiveCbxRef = useRef<HTMLIonCheckboxElement>(null);
  const imageDescTextRef = useRef<HTMLIonInputElement>(null);


  const [CurrentProduct, setCurrentProduct] = useState(() => {
    if (props.product != undefined) {
      return { ...props.product };
    } else {
      const iproduct: MemberProduct = {
        Base64Image: "",
        Active: true,
        // Chosen: "false",
        ImageDesc: "",
        CatNumber: "",
        FileId: -1,
        Id: -1,
        ImagePath: "",
        Name: "",
        Price: 0,
        Description: "",
        LongDescription: "",
        
      };
      return iproduct;
    }
  });

  const [takenPhoto, setTakenPhoto] = useState(() => {
    console.log('prod set');
    if (props.product != undefined) {
      const photo: Photo = {
        path: props.product.ImagePath,
        preview: props.product.ImagePath,
      };
      return photo;
    }
  });

  const saveHandler = () => {
    if(!CurrentProduct){
      toast.warning("לא נמצא מוצר");
      return;
    }
    if (CurrentProduct.Base64Image === "") {
      toast.warning("לא נבחרה תמונה");
      return;
    }
    
    CurrentProduct.Name = productNameTextRef.current!.value!.toString();
    CurrentProduct.CatNumber = productCatNumberTextRef.current!.value!.toString();
    const desc = productDescriptionTextRef.current!.value!.toString();
    CurrentProduct.Description = desc.replace(/<\/?[^>]+(>|$)/g, "");
    CurrentProduct.Price = parseFloat(productPriceTextRef.current!.value!.toString());
    CurrentProduct.Active =  productActiveCbxRef.current!.checked;
    const long_desc = productLongDescriptionTextRef.current!.value;
    CurrentProduct.LongDescription = long_desc ? long_desc.toString().replace(/<\/?[^>]+(>|$)/g, "") : '';
    CurrentProduct.ImageDesc = imageDescTextRef.current!.value!.toString();
    console.log(CurrentProduct);
    props.onSave(CurrentProduct!);
  };

  const imagePickHandler = async (photo: Photo) => {
    if (photo) {
      setTakenPhoto(photo);
      setIsPhotoChange(true);
      const base64 = await base64FromPath(photo!.preview);
      CurrentProduct!.Base64Image = base64;
    } else {
      setIsPhotoChange(false);
    }
  };
  let isBtnFocuse = false;
  const headerParameters: HeaderParameters = {
    isPageHaveChanges: dirty || isPhotoChange || isPageChange,
    modalType: "modal",
    isShowLogo: false,
    isShowDeleteButton: props.product != undefined,
    deleteFunc: () => {
      props.onDelete(props.product!.Id);
    },
  };

  return (
    <CustomModal
      pageName={"AddEditProduct"}
      headerParameters={headerParameters}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
      title={"איזה מוצר תעלה?"}
      subTitle={"תיאור טוב לתמונה יגדיל את החשיפה שלה ונותן לך יתרון שיווקי."}
    >
      <CustomForm onSubmit={handleSubmit(saveHandler)} errors={errors}>
        <IonGrid className="ion-no-padding ion-padding-horizontal">
          <IonRow className="ion-justify-content-between">
            {/* start - column right (picture) desktop mode  */}
            
            <IonCol
              size="12"
              sizeMd="5.9"
              style={{ order: !isMobile ? "2" : "" }}
            >
               <IonGrid>
               <IonRow>
                  <IonCol size="12">
                  <IonItem lines="none" className="item-cbx-cont">
                    <IonLabel>פעיל</IonLabel>
                    <IonCheckbox
                      slot="start"
                      name="cbx_active"
                      checked={CurrentProduct?.Active}
                      ref={productActiveCbxRef}
                      ></IonCheckbox>
                  </IonItem>
                  </IonCol>
                </IonRow>
               <IonRow className="ion-text-center">
               <IonCol  className="ion-margin-bottom">
                  <ImagePicker
                    onImagePick={imagePickHandler}
                    currentPhoto={takenPhoto}
                    showPreview={true}
                    isMultiple={false}
                  />
                </IonCol>
                
               
                {/*<IonCol size="12">
                  
                </IonCol><IonCol size="12">
                    
                  </IonCol> */}
              </IonRow>
              <IonRow>
                  <IonCol size="12">
                  <CustomInput
                      name="imageDesc"
                      errors={errors}
                      control={control}
                      label="תאור תמונה"
                      ref={imageDescTextRef}
                      value={CurrentProduct?.ImageDesc}
                      required={true}
                      maxlength={38}
                    ></CustomInput>
                  </IonCol>
                </IonRow>
                </IonGrid>
              
            </IonCol>
            
            {/* start - column left (input fields) desktop mode  */}

            <IonCol className="ion-no-margin" size="12" sizeMd="5.6">
              <IonGrid>
                <IonRow>
                  <IonCol size="12">
                    <CustomInput
                      name="productName"
                      errors={errors}
                      control={control}
                      label="שם מוצר"
                      ref={productNameTextRef}
                      value={CurrentProduct?.Name}
                      required={true}
                      maxlength={30}
                    ></CustomInput>
                  </IonCol>
                 
                  <IonCol size="12">
                    <CustomInput
                      name="productCatNumber"
                      errors={errors}
                      control={control}
                      label="מק''ט"
                      ref={productCatNumberTextRef}
                      value={CurrentProduct?.CatNumber}
                      maxlength={20}
                    ></CustomInput>
                  </IonCol>
                  <IonCol size="12">
                    <CustomInput
                      name="productDescription"
                      errors={errors}
                      control={control}
                      label="תיאור המוצר"
                      ref={productDescriptionTextRef}
                      value={CurrentProduct?.Description}
                      maxlength={250}
                    ></CustomInput>
                  </IonCol>
                  <IonCol size="12">
                    <CustomInput
                      name="productPrice"
                      errors={errors}
                      control={control}
                      label="מחיר"
                      maxlength={15}
                      ref={productPriceTextRef}
                      value={CurrentProduct?.Price}
                      customType='price'
                    ></CustomInput>
                  </IonCol>

                  
                  <IonCol size="12">
                    <CustomInput
                      name="longDescription"
                      errors={errors}
                      control={control}
                      label="מידע נוסף"
                      ref={productLongDescriptionTextRef}
                      value={CurrentProduct?.LongDescription}
                      type='text'
                    ></CustomInput>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>

          {/* start - action button (align to left) desktop mode  */}

          <IonRow className="ion-justify-content-end">
            <IonCol size="12" sizeMd="5.9">
              <CustomPublishButton></CustomPublishButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </CustomForm>
    </CustomModal>
  );
};

export default AddEditMemberProduct;
