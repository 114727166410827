import { OpenDays, PublicationDays } from "../data/MemberDetailes-context";

export const getOpenDays = (openDay: OpenDays | PublicationDays) => {
  let openDays = [];
  if (openDay.Day1) {
    openDays.push("א");
  }
  if (openDay.Day2) {
    openDays.push("ב");
  }
  if (openDay.Day3) {
    openDays.push("ג");
  }
  if (openDay.Day4) {
    openDays.push("ד");
  }
  if (openDay.Day5) {
    openDays.push("ה");
  }
  if (openDay.Day6) {
    openDays.push("ו");
  }
  if (openDay.Day7) {
    openDays.push("ש");
  }

  return openDays.toString();
};
