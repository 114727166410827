import { IonCol, IonGrid, IonImg, IonRow } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import NotificationSettings from "../components/UserSettings/NotificationSettings";
import ProfileSettings from "../components/UserSettings/ProfileSettings";
import CustomPage from "../components/utils/CustomElements/CustomPage";
import CustomHeaddingItem from "../components/utils/CustomElements/CustomPageTitle/CustomHeaddingItem";
import { HeaderParameters } from "../data/Header-Context";
import UserSettingsContext from "../data/UserSettings-Context";
import { sentimentSatisfied, notifications } from "../images/index";
import desktopBanner from "../images/banners/banner-user-settings.png";
import { isMobile } from "react-device-detect";
const UserSettings: React.FC = () => {
  const [isShowNotificationSettings, setShowNotificationSettings] = useState(
    false
  );
  const [isShowProfileSettings, setIsShowProfileSettings] = useState(false);

  const UserSettingsCtx = useContext(UserSettingsContext);

  useEffect(() => {
    if (UserSettingsCtx.userSettingsInfo == null) {
      UserSettingsCtx.initUserSettingContext(true);
    }
    if (UserSettingsCtx.userProfileSettingsInfo == null) {
      UserSettingsCtx.initUserProfileSettingContext(true);
    }
  }, []);

  const headerParameters: HeaderParameters = {
    isShowLogo: true,
    backButton: { isShowMobile: true },
    isShowNotification: true,
  };

  const userSettings = UserSettingsCtx.userSettingsInfo;
  return (
    <CustomPage
      headerParameters={headerParameters}
      title="מה תרצו לעדכן היום?"
      pageName={"UserSettings"}
    >
      <IonGrid fixed={true} className="main-content">
        <IonRow>
          <IonCol size="12" size-lg="4">
            <CustomHeaddingItem
              onClick={() => {
                setShowNotificationSettings(true);
              }}
              mainTitle={"התראות"}
              subTitle={"להיות מעודכן בכל מה שקורה בבית העסק"}
              icon={notifications}
              iconBackground="settings"
            ></CustomHeaddingItem>
          </IonCol>
          <IonCol size="12" size-lg="4">
            <CustomHeaddingItem
              onClick={() => {
                setIsShowProfileSettings(true);
              }}
              mainTitle={"פרופיל משתמש"}
              subTitle={"עדכון פרטי הקשר"}
              icon={sentimentSatisfied}
              iconBackground="settings"
            ></CustomHeaddingItem>
          </IonCol>
        </IonRow>
        
      </IonGrid>
      {isShowNotificationSettings && UserSettingsCtx.userSettingsInfo && (
        <NotificationSettings
          show={isShowNotificationSettings}
          closeModal={() => {
            setShowNotificationSettings(false);
          }}
          onSave={() => {}}
        ></NotificationSettings>
      )}
      {isShowProfileSettings && UserSettingsCtx.userSettingsInfo && (
        <ProfileSettings
          show={isShowProfileSettings}
          closeModal={() => {
            setIsShowProfileSettings(false);
          }}
          onSave={() => {}}
        ></ProfileSettings>
      )}
    </CustomPage>
  );
};

export default UserSettings;
