import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import CustomPage from "../components/utils/CustomElements/CustomPage";
import CustomHeaddingItem from "../components/utils/CustomElements/CustomPageTitle/CustomHeaddingItem";
import CustomReactTextFormat from "../components/utils/CustomElements/CustomReactTextFormat";
import { HeaderParameters } from "../data/Header-Context";

import {
  articles,
  support_site,
  campaigns,
  taboola_white,
  contact_us,
  talk_to_us,
  chatbot,
} from "../images/index";
import { generateExternalLinkWithToken } from "../data/GlobalService";
import NativeApp from "../utils-ts/NativeApp";
import { config } from "../Constants";
const Help: React.FC = () => {
  const headerParameters: HeaderParameters = {
    isShowLogo: true,
    backButton: { isShowMobile: true },
    isShowNotification: true,
  };
  const [externalLinkWithToken, setExternalLinkWithToken] = useState<
    string | undefined
  >();
  const { OpenDialer } = NativeApp();

  useEffect(() => {
    generateExternalLinkWithToken().then((data) => {
      setExternalLinkWithToken(data);
    });
  }, []);

  function startChatBot() {
    return;
    // (function() {
    //   var el = document.createElement('script');
    //   var timestamp = new Date().getTime()
    //   el.setAttribute('src', 'https://chat.b144biz.co.il/B144LeadBots/dist/bundle.js?v=' + timestamp);
    //   el.setAttribute('data-id', 'assistant-chat-bot');
    //   el.setAttribute('data-method', "https");
    //   el.setAttribute('data-host', "chat.b144biz.co.il");
    //   el.setAttribute('data-context', "B144LeadBots");
    //   el.setAttribute('type'," application/javascript");
    //   document.head.appendChild(el);
    // })();
  }

  return (
    <CustomPage
      headerParameters={headerParameters}
      pageName={"Help"}
      title="אנחנו כאן בשבילך"
    >
      <IonGrid fixed={true} className="main-content">
        <IonRow>
          <IonCol size="12" size-lg="4">
            <CustomHeaddingItem
              onClick={() => {
                SetDtlPushEvt("support", "כתבות ומאמרים");
                SetDtlPushEvtOutBound(
                  "outbound_links",
                  "http://www.b144biz.co.il/"
                );
              }}
              href="http://www.b144biz.co.il/"
              target="_blank"
              mainTitle={"כתבות ומאמרים"}
              subTitle={"מגוון כתבות ומאמרים בנושא שיווק דיגיטלי"}
              icon={articles}
              iconBackground="help"
            ></CustomHeaddingItem>
          </IonCol>
          <IonCol size="12" size-lg="4">
            <CustomHeaddingItem
              onClick={() => {
                SetDtlPushEvt("support", "אתר תמיכה");
                SetDtlPushEvtOutBound(
                  "outbound_links",
                  "http://support.b144.co.il/"
                );
              }}
              href="http://support.b144.co.il/"
              target="_blank"
              mainTitle={"אתר תמיכה"}
              subTitle={"כל המידע שצריך לניהול החשבון שלך"}
              icon={support_site}
              iconBackground="help"
            ></CustomHeaddingItem>
          </IonCol>
          <IonCol size="12" size-lg="4">
            <CustomHeaddingItem
              //routerLink="/Privacy"
              //target="_blank"
              mainTitle="מדיניות פרטיות"
              subTitle="מידע על הגנה על פרטיות ו-Cookies"
              onClick={()=> {
                window.open('/assets/privacypolicy.pdf','_blank');
              }}
              icon={support_site}
              iconBackground="help"
            ></CustomHeaddingItem>
          </IonCol>
          {!config.otef_israel && <>
            <IonCol size="12" size-lg="4">
            <CustomHeaddingItem
              onClick={() =>
                SetDtlPushEvt("support", "זה המקום לפניות למנהל הקמפיין שלך")
              }
              href={externalLinkWithToken + "9109"}
              mainTitle={"תמיכה בקמפיינים בגוגל פייסבוק וטאבולה"}
              target="_blank"
              subTitle={"זה המקום לפניות למנהל הקמפיין שלך"}
              icon={campaigns}
              iconBackground="help"
            ></CustomHeaddingItem>
          </IonCol>
          <IonCol size="12" size-lg="4">
            <CustomHeaddingItem
              onClick={() => SetDtlPushEvt("support", "סטטיסטיקות טאבולה")}
              href={externalLinkWithToken + "9270"}
              mainTitle={"סטטיסטיקות טאבולה"}
              target="_blank"
              subTitle={"במסך זה תוכלו לראות את הסטטיסטיקות של קמפיינים טאבולה"}
              icon={taboola_white}
              iconBackground="help"
            ></CustomHeaddingItem>
          </IonCol>
          </>}
          
          {/* <IonCol size="12" size-lg="4">
            <CustomHeaddingItem
              href="https://support.b144.co.il/Content.aspx?pid=171"
              target="_blank"
              mainTitle={"תמיכה בפייסבוק"}
              subTitle={"תיבת פניות יעודית לניהול הקמפיין שלך"}
              icon={facebookWhite}
              iconBackground="help"
            ></CustomHeaddingItem>
          </IonCol> */}
          <IonCol size="12" size-lg="4">
            <CustomHeaddingItem
              onClick={() => {
                SetDtlPushEvt("contact_us", "email");
                SetDtlPushEvtOutBound(
                  "outbound_links",
                  "https://" + (config.otef_israel ? "otefisrael" : "www") + ".b144.co.il/Contact/MailTo/"
                );
              }}
              href={"https://" + (config.otef_israel ? "otefisrael" : "www") + ".b144.co.il/Contact/MailTo/"}
              target="_blank"
              mainTitle={"צור קשר"}
              subTitle={"השאירו לנו הודעה"}
              icon={contact_us}
              iconBackground="help"
            ></CustomHeaddingItem>
          </IonCol>
          <IonCol size="12" size-lg="4">
            <CustomReactTextFormat>
              <CustomHeaddingItem
                onClick={(e) => {
                  e.preventDefault();
                  SetDtlPushEvt("contact_us", "click_to_call");
                  OpenDialer("tel:1700707144");
                }}
                href="tel:1700707144"
                target="_blank"
                mainTitle={"דברו איתנו"}
                subTitle={"דברו איתנו"}
                icon={talk_to_us}
                iconBackground="help"
              ></CustomHeaddingItem>
            </CustomReactTextFormat>
          </IonCol>
          {/* <IonCol size='12' size-lg='4'>
            <CustomReactTextFormat>
              <CustomHeaddingItem
                onClick={() => {
                  startChatBot();  
                  SetDtlPushEvt('contact_us','click_to_chat');
                }}
                mainTitle={"יצירת קשר בצ'אט"}
                subTitle={""}
                icon={chatbot}
                iconBackground='help'
              ></CustomHeaddingItem>
            </CustomReactTextFormat>
          </IonCol> */}
        </IonRow>
      </IonGrid>
    </CustomPage>
  );
};

export default Help;
