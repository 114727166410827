export const  locale_he_IL = {
    'Apply' : 'אישור',
    'Arrow' : 'חץ',
    'Arrow-2' : 'חץ -2',
    'Arrow-3' : 'חץ -3',
    'Blend' : 'תעורבת',
    'Blur' : 'טשטש',
    'Bold' : 'הדגשה',
    'Brightness' : 'בהירות',
    'Bubble' : 'בועה',
    'Cancel' : 'בטל',
    'Center' : 'מרכז',
    'Circle' : 'מעגל',
    'Color' : 'צבע',
    'Color Filter' : 'מסנן צבעים',
    'Crop' : 'חיתוך',
    'Custom' : 'מותאם אישית',
    'Custom icon' : 'סמל מותאם אישית',
    'Delete' : 'מחק',
    'DeleteAll' : 'מחק הכל',
    'Distance' : 'מרחק',
    'Download' : 'הורד',
    'Draw' : 'צייר',
    'Emboss' : 'הבלטה',
    'Fill' : 'למלא',
    'Filter' : 'סנן',
    'Flip' : 'הפוך',
    'Flip X' : 'הפוך את X',
    'Flip Y' : 'הפוך את Y',
    'Free' : 'חינם',
    'Grayscale' : 'גווני אפור',
    'Heart' : 'לב',
    'Icon' : 'אייקון',
    'Invert' : 'הפוך צבעים',
    'Italic' : 'נטוי',
    'Left' : 'שמאלה',
    'Load' : 'טען',
    'Load Mask Image' : 'טען תמונת מסכה',
    'Location' : 'מקום',
    'Mask' : 'מסכה',
    'Multiply' : 'הכפל',
    'Noise' : 'רעש',
    'Pixelate' : 'פיקסל',
    'Polygon' : 'מצולע',
    'Range' : 'טווח',
    'Rectangle' : 'מלבן',
    'Redo' : 'חזור על הפעולה',
    'Remove White' : 'הסר לבן',
    'Reset' : 'אתחל',
    'Right' : 'ימין',
    'Rotate' : 'סובב',
    'Sepia' : 'ספייה',
    'Sepia2' : 'ספייה 2',
    'Shape' : 'צורה',
    'Sharpen' : 'חדד',
    'Square' : 'ריבוע',
    'Star-1' : 'כוכב -1',
    'Star-2' : 'כוכב -2',
    'Straight' : 'יישר',
    'Stroke' : 'קו מטהר',
    'Text' : 'טֶקסט',
    'Text size' : 'גודל טקסט',
    'Threshold' : 'מפתן',
    'Tint' : 'צבע',
    'Triangle' : 'משולש',
    'Underline' : 'הדגשה',
    'Undo' : 'בטל',
    'Value' : 'ערך'
  };