import React from "react";
import { Photo } from "../components/utils/ImagePicker/ImagePicker";


export interface Image {
  Chosen: string;
  CoverImage: string;
  GmbId: string;
  Id: string;
  ImageCode: string;
  ImageDesc: string;
  SortOrder: string;
  Status: string;
  ImagePath: string; 
  InsertDate: string;
  IsDeleted?:boolean;
}

const GalleryContext = React.createContext<{
  gallery: Image[] | undefined;
  addImage: (photo: Photo, alt: string) => void; 
  saveImage: (imageDesc: string, imageId : string) => void;
  initGalleryContext: (useLoading: boolean) => void;
  deletedImagesHandler: (imagesID:string[],property:"Gallery"|"Google")=> void;
  uploadDeleteFromGoogleHandler: (imagesID:string[],isDeleted:boolean)=> void;
}>({
  gallery: [],
  addImage: () => {},
  saveImage: () => {},
  deletedImagesHandler: () => {},
  uploadDeleteFromGoogleHandler: () => {},
  initGalleryContext: () => {},
});

export default GalleryContext;
