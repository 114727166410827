import React, { useState, useContext } from "react";
import { config } from "../Constants";
//import CustomLoader from "../components/utils/CustomElements/CustomLoader";
import { toast } from "react-toastify";
import axios, { AxiosRequestConfig } from 'axios';
import SelfSaleContext, { MemberAreas, AdGroup, CreditGuardResponse } from "./SelfSaleContext";
import UserContext from '../data/Auth-context';
import GlobalContext from "./Global-Context";

const SelfSaleContextProvider: React.FC = (props) => {
  const { authValues } = useContext(UserContext);
  //const [loading, setLoading] = useState<boolean>(false);
  const globalCtx = useContext(GlobalContext);
 const setLoading = (loading:boolean)=>{
  globalCtx.setLoader(loading);
 };
  
  const [selfRegisterId, setSelfRegisterId] = useState<string>("");
  const [saleLineId, setSaleLineId] = useState<string>("");
  const [businessName, setBusinessName] = useState<string>("");
  const [businessCategory, setBusinessCategory] = useState<string>("");
  const [businessCity, setBusinessCity] = useState<string>("");
  const [memberAreas, setMemberAreas] = useState<MemberAreas | null>(null);
  const [showSummary, setShowSummary] = useState<boolean>(false);
  const [finalStep, setFinalStep] = useState<boolean>(false);
  const [showPayment, setShowPayment] = useState<boolean>(false);
  const [chizukSale, setChizukSale] = useState<boolean>(false);
  const [lpSaleStep, setLpSaleStep] = useState<number>(0);
  const [lpSaleRequestId, setLpSaleRequestId] = useState<string>("");
  const [memberAdgroups, setMemberAdgroups] = useState<AdGroup[]>([]);
  const [googleAdsAdditionalSummary, setGoogleAdsAdditionalSummary] = useState<string>("");
  const startSelfSaleProccess = (sale_type: string) => {
    var data = new URLSearchParams({ selfreg_id: selfRegisterId, sale_type }).toString();
    return new Promise((resolve) => {
      axios.post(`${config.url.SELF_SALE_WS}/startSelfSale`, data)
        .then((response: any) => {
          if (response.statusText === "OK") {
            let resData = JSON.parse(response.data.ResultData); 
            response.data.ResultData = resData
            setSaleLineId(resData.sales_line_id)
            resolve(response.data);
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const setPhaseCompleted = (sale_type: string, phase_id: string, status: string, skip_phase: string) => {
    var data = new URLSearchParams({ selfreg_id: selfRegisterId, sale_type, sales_line_id: saleLineId, phase_id, status, skip_phase}).toString();
    return new Promise((resolve) => {
      axios.post(`${config.url.SELF_SALE_WS}/setSalePhaseCompleted`, data)
        .then((response: any) => {
          
          if (response.statusText === "OK") {
            resolve(response.data);
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const initSelfSale = (ezbizId: string, sale_type: string) => {
    var data = new URLSearchParams({ ezbizId, sale_type }).toString();
    axios.defaults.headers.common['Auth-Token'] = authValues?.Token || "";
    
    return new Promise((resolve) => {
      axios.post(`${config.url.SELF_SALE_WS}/getSelfRegPhases`, data)
        .then((response: any) => {
          if (response.statusText === "OK") {
            let resData = JSON.parse(response.data.ResultData); 
            if (resData.lp_sale_data !== null) {
              setLpSaleRequestId(resData.lp_sale_data.request_id)
            }
            setSaleLineId(resData.sales_line_id)
            setSelfRegisterId(resData.sr_id)
            setMemberAreas(resData.memberAreas)
            setMemberAdgroups(resData.memberAdgroups)
            setBusinessName(resData.sr_business_name)
            setBusinessCategory(resData.sr_category)
            setBusinessCity(resData.sr_city)
            let res = {
              salePhases: resData.sale_phases,
              selfSaleCompleted: resData.sr_completed,
              saleLineId:resData.sales_line_id
            }
            resolve(res);
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const getMemberAreas = () => {
    return new Promise((resolve) => {
      var data = new URLSearchParams({ sale_line_id: saleLineId }).toString();
      axios.post(`${config.url.SELF_SALE_WS}/getMemberAreas`, data)
        .then((response: any) => {
          if (response.statusText === "OK") {
            setMemberAreas(response.data)
            resolve(response.data);
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const saveMemberAreas = (area_type: string, area_city_list: string) => {
    var data = new URLSearchParams({ sale_line_id: saleLineId, area_type, area_city_list, selfreg_id: selfRegisterId }).toString();
    return new Promise((resolve) => {
      axios.post(`${config.url.SELF_SALE_WS}/saveAreasCities`, data)
        .then((response: any) => {
          if (response.statusText === "OK") {
            resolve(response.data);
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const getKeywordsAutocomplete = (prefix: string) => {
    var data = new URLSearchParams({ prefix }).toString();
    return new Promise((resolve) => {
      axios.post(`${config.url.SELF_SALE_WS}/getAutoComplete_Keywords`, data)
        .then((response: any) => {
          if (response.statusText === "OK") {
            resolve(response.data);
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const getAdgroupKeywords = (adGroup: string) => {
    axios.defaults.headers.common['Auth-Token'] = authValues?.Token || "";
    var data = new URLSearchParams({ adGroup }).toString();
    return new Promise((resolve) => {
      axios.post(`${config.url.SELF_SALE_WS}/getGoogleKeywords`, data)
        .then((response: any) => {
          
          if (response.statusText === "OK") {
            resolve(response.data);
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const getCitiesAutocomplete = (prefix: string) => {
    return new Promise((resolve) => {
      var data = new URLSearchParams({ "prefix": prefix, "synonyms": 'false' }).toString();
      axios.post(`${config.url.SELF_SALE_WS}/getCities`, data)
        .then((response: any) => {
          
          if (response.statusText === "OK") {
            resolve(response.data);
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const getCityStreetsAutocomplete = (prefix: string, city_code:string) => {
    return new Promise((resolve) => {
      var data = new URLSearchParams({ "prefix": prefix, "city_code": city_code }).toString();
      axios.post(`${config.url.SELF_SALE_WS}/getCityStreets`, data)
        .then((response: any) => {
          
          if (response.statusText === "OK") {
            resolve(response.data);
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const getAreasAutocomplete = (prefix: string) => {
    return new Promise((resolve) => {
      var data = new URLSearchParams({ prefix }).toString();
      axios.post(`${config.url.SELF_SALE_WS}/getAreas`, data)
        .then((response: any) => {
          if (response.statusText === "OK") {
            resolve(response.data);
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const getMemberCategories = () => {
    return new Promise((resolve) => {
      var data = new URLSearchParams({ sale_line_id: saleLineId }).toString();
      axios.post(`${config.url.SELF_SALE_WS}/getMemberCategories`, data)
        .then((response: any) => {
          if (response.statusText === "OK") {
            resolve(response.data);
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const getCategoriesAdv = (cat_code: string) => {
    return new Promise((resolve) => {
      var data = new URLSearchParams({ sales_line_id: saleLineId, cat_code }).toString();
      axios.post(`${config.url.SELF_SALE_WS}/getMemberCategoriesAdv`, data)
        .then((response: any) => {
          
          if (response.statusText === "OK") {
            resolve(response.data);
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const getMinAdvCategories = (cat_code: string) => {
    return new Promise((resolve) => {
      var data = new URLSearchParams({ cat_code }).toString();
      axios.post(`${config.url.SELF_SALE_WS}/getMinAdvCategories`, data)
        .then((response: any) => {
          if (response.statusText === "OK") {
            resolve(response.data);
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const getCategoriesAutocomplete = (prefix: string) => {
    return new Promise((resolve) => {
      var data = new URLSearchParams({ "prefix": prefix }).toString();
      axios.post(`${config.url.SELF_SALE_WS}/getCategories`, data)
        .then((response: any) => {
          
          if (response.data) {
            resolve(response.data);
          }
          else {
            resolve([])
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };

  const deleteMemberCategories = (categories: string) => {
    var data = new URLSearchParams({ sale_line_id: saleLineId, categories} ).toString();
    return new Promise((resolve) => {
      axios.post(`${config.url.SELF_SALE_WS}/deleteMemberCategoriesData`, data)
        .then((response: any) => {
          if (response.statusText === "OK") {
            resolve(response.data);
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const saveMemberCategoryAdv = (category_data: any) => {
    var data = new URLSearchParams({ cat_id: category_data.cat_id, adv_cat_id: category_data.adv_cat_id,  sales_line_id: saleLineId, categories_adv_list: category_data.categories_adv_list, adgroups_list:category_data.adgroups}).toString();
    return new Promise((resolve) => {
      axios.post(`${config.url.SELF_SALE_WS}/saveCategoryWithAdv`, data)
        .then((response: any) => {
          if (response.statusText === "OK") {
            resolve(response.data);
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const getAdvertisingBudget = (sale_type: string, promoOnly: boolean) => {
    return new Promise((resolve) => {
      let prodmain = sale_type === "1" ? "0" : sale_type === "2" ? "4" :sale_type
      let promoOnlyText = promoOnly ? "1" : "0"
      var data = new URLSearchParams({ prodmain, sale_line_id: saleLineId, promoOnly: promoOnlyText }).toString();
      axios.post(`${config.url.SELF_SALE_WS}/getAdvertisingBudget`, data)
        .then((response: any) => {
          
          if (response.data) {
            resolve(response.data);
          }
          else {
            resolve([])
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const getMemberAdvertisingBudget = (sale_type: string) => {
    return new Promise((resolve) => {
      let prodmain = sale_type === "1" ? "0" : "4"
      var data = new URLSearchParams({ prodmain, sale_line_id: saleLineId }).toString();
      axios.post(`${config.url.SELF_SALE_WS}/getMemberAdvertisingBudget`, data)
        .then((response: any) => {
          
          if (response.data) {
            resolve(response.data);
          }
          else {
            resolve([])
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const getGoogleResponsiveAdTemplate = (cat_code: string, adGroup_id: string) => {
    return new Promise((resolve) => {
      var data = new URLSearchParams({ cat_code, adGroup_id }).toString();
      axios.post(`${config.url.SELF_SALE_WS}/getGoogleResponsiveAdTemplate`, data)
        .then((response: any) => {
          
          if (response.data) {
            resolve(response.data);
          }
          else {
            resolve([])
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const getGoogleAdsAdditional = () => {
    return new Promise((resolve) => {
      var data = new URLSearchParams({ sales_line_id:saleLineId }).toString();
      axios.post(`${config.url.SELF_SALE_WS}/getGoogleAdsAdditional`, data)
        .then((response: any) => {
          
          if (response.data) {
            resolve(response.data);
          }
          else {
            resolve([])
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const saveGoogleAdsAdditional = (adsAdditionalObj:any) => {
    return new Promise((resolve) => {
      var data = new URLSearchParams({ sales_line_id:saleLineId,...adsAdditionalObj  }).toString();
      axios.post(`${config.url.SELF_SALE_WS}/saveGoogleAdsAdditional`, data)
        .then((response: any) => {
          if (response.data) {
            resolve(response.data);
          }
          else {
            resolve([])
          }
        }).catch(function (error) {
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };

  const getGoogleAdsNotes = () => {
    return new Promise((resolve) => {
      var data = new URLSearchParams({ sales_line_id:saleLineId }).toString();
      axios.post(`${config.url.SELF_SALE_WS}/getGoogleAdsNotes`, data)
        .then((response: any) => {
          if (response.data) {
            resolve(response.data);
          }
          else {
            resolve([])
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const saveGoogleAdsNotes = (notes: string) => {
    return new Promise((resolve) => {
      var data = new URLSearchParams({ sales_line_id:saleLineId,notes}).toString();
      axios.post(`${config.url.SELF_SALE_WS}/saveGoogleAdsNotes`, data)
        .then((response: any) => {
          if (response.data) {
            resolve(response.data);
          }
          else {
            resolve([])
          }
        }).catch(function (error) {
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  
  const saveAdvertisingBudget = (budget: string) => {
    return new Promise((resolve) => {
      var data = new URLSearchParams({ sale_line_id: saleLineId, budget, selfreg_id: selfRegisterId }).toString();
      axios.post(`${config.url.SELF_SALE_WS}/saveAdvertisingBudget`, data)
        .then((response: any) => {
          if (response.data) {
            resolve(response.data);
          }
          else {
            resolve([])
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const saveGoogleKeywords = (google_keywords_list: string) => {
    return new Promise((resolve) => {
      var data = new URLSearchParams({ sale_line_id: saleLineId, google_keywords_list }).toString();
      axios.post(`${config.url.SELF_SALE_WS}/saveGoogleKeywords`, data)
        .then((response: any) => {
          
          if (response.data) {
            resolve(response.data);
          }
          else {
            resolve([])
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const saveSpamApproved = (approved: string) => {
    return new Promise((resolve) => {
      var data = new URLSearchParams({ sale_line_id: saleLineId, approved }).toString();
      axios.post(`${config.url.SELF_SALE_WS}/saveSpamApproved`, data)
        .then((response: any) => {
          
          if (response.data) {
            resolve(response.data);
          }
          else {
            resolve([])
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const getResllerSubCategories = (categories: string) => {
    return new Promise((resolve) => {
      var data = new URLSearchParams({ sales_line_id: saleLineId, cat_code: categories }).toString();
      
      axios.post(`${config.url.SELF_SALE_WS}/getResllerSubCategories`, data)
        .then((response: any) => {
          
          if (response.data) {
            resolve(response.data);
          }
          else {
            resolve([])
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const getBestPackByCategory = (categories: string) => {
    return new Promise((resolve) => {
      var data = new URLSearchParams({ sales_line_id: saleLineId, cat_code: categories }).toString();
      
      axios.post(`${config.url.SELF_SALE_WS}/getBestPackByCategory`, data)
        .then((response: any) => {
          
          if (response.data) {
            resolve(response.data);
          }
          else {
            resolve([])
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const saveMethodsOfPaymentWithBezeqAccount = (bezeq_cust_id: string,bezeq_tz_hp:string) => {
    return new Promise((resolve) => {
      var data = new URLSearchParams({ sales_line_id: saleLineId, bezeq_cust_id, bezeq_tz_hp }).toString();
      
      axios.post(`${config.url.SELF_SALE_WS}/saveMethodsOfPaymentWithBezeqAccount`, data)
        .then((response: any) => {
          
          if (response.data) {
            resolve(response.data);
          }
          else {
            resolve([])
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const saveMethodsOfPaymentNoBezeqAccount = (invoice_business_name: string,bezeq_tz_hp:string,billing_city:string,billing_street:string,billing_street_no:string) => {
    return new Promise((resolve) => {
      var data = new URLSearchParams({ 
        sales_line_id: saleLineId, 
        invoice_business_name, 
        bezeq_tz_hp, 
        billing_city,
        billing_street,
        billing_street_no,
        billing_zip_code:"0"
       }).toString();
      
      axios.post(`${config.url.SELF_SALE_WS}/saveMethodsOfPaymentNoBezeqAccount`, data)
        .then((response: any) => {
          if (response.data) {
            resolve(response.data);
          }
          else {
            resolve([])
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const getBezeqDetailsByTz = (tz_hp: string, credit_4_digits:string) => {
    return new Promise((resolve) => {
      var data = new URLSearchParams({ tz_hp,credit_4_digits }).toString();
      
      axios.post(`${config.url.SELF_SALE_WS}/getPaymentMethodFromBilling`, data)
        .then((response: any) => {
          if (response.data) {
            resolve(response.data);
          }
          else {
            resolve([])
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const initCreditGuard = () => {
    return new Promise((resolve) => {
      var data = new URLSearchParams({ sales_line_id: saleLineId }).toString();
      
      axios.post(`${config.url.SELF_SALE_WS}/CGSetup`, data)
        .then((response: any) => {
          if (response.data) {
            resolve(response.data);
          }
          else {
            resolve([])
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const completeCGTransaction = (cgResponse: CreditGuardResponse) => {
    return new Promise((resolve) => {
      var data = new URLSearchParams({ 
        unique_key: cgResponse.uniqueID,
        transaction_id: cgResponse.txId
      }).toString();
      
      axios.post(`${config.url.SELF_SALE_WS}/CGTransaction`, data)
        .then((response: any) => {
          if (response.data) {
            resolve(response.data);
          }
          else {
            resolve([])
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const getLandingPageGoals = () => {
    return new Promise((resolve) => {
      var data = new URLSearchParams({ }).toString();
      
      axios.post(`${config.url.SELF_SALE_WS}/getLandingPageGoals`, data)
        .then((response: any) => {
          
          if (response.data) {
            resolve(response.data);
          }
          else {
            resolve([])
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  const saveLandingPageSale = (lpData: string) => {
    return new Promise((resolve) => {
      var data = new URLSearchParams({ lpSaleData: lpData }).toString();
      axios.post(`${config.url.SELF_SALE_WS}/saveLandingPageSale`, data)
        .then((response: any) => {
          if (response.data) {
            resolve(response.data);
          }
          else {
            resolve([])
          }
        }).catch(function (error) {
          
          toast.error('ארעה שגיאה');
          resolve(null);
        });
    });
  };
  return (
    <SelfSaleContext.Provider
      value={{
        saleLineId,
        selfRegisterId,
        memberAreas,
        memberAdgroups,
        showSummary,
        chizukSale,
        finalStep,
        showPayment,
        lpSaleStep,
        lpSaleRequestId,
        businessName,
        businessCategory,
        businessCity,
        googleAdsAdditionalSummary,
        initSelfSale,
        setShowSummary,
        setChizukSale,
        setFinalStep,
        setShowPayment,
        getMemberAreas,
        getCitiesAutocomplete,
        getCityStreetsAutocomplete,
        getAreasAutocomplete,
        saveMemberAreas,
        getCategoriesAutocomplete,
        getCategoriesAdv,
        getMinAdvCategories,
        getAdgroupKeywords,
        getKeywordsAutocomplete,
        saveGoogleKeywords,
        deleteMemberCategories,
        saveMemberCategoryAdv,
        getMemberCategories,
        startSelfSaleProccess,
        setPhaseCompleted,
        getMemberAdvertisingBudget,
        getAdvertisingBudget,
        getBestPackByCategory,
        saveAdvertisingBudget,
        getGoogleResponsiveAdTemplate,
        getGoogleAdsAdditional,
        saveGoogleAdsAdditional,
        getGoogleAdsNotes,
        saveGoogleAdsNotes,
        getResllerSubCategories,
        saveMethodsOfPaymentWithBezeqAccount,
        saveMethodsOfPaymentNoBezeqAccount,
        getBezeqDetailsByTz,
        initCreditGuard,
        completeCGTransaction,
        getLandingPageGoals,
        saveLandingPageSale,
        setLpSaleStep,
        setLpSaleRequestId,
        setGoogleAdsAdditionalSummary,
        saveSpamApproved
      }}
    >
      {props.children}
      {/* <CustomLoader
        isOpen={loading}
        duration={5000}
        onDidDismiss={() => {
          setLoading(false);
        }}
      ></CustomLoader> */}
    </SelfSaleContext.Provider>
  );
};

export default SelfSaleContextProvider;
