import React, { ComponentProps, forwardRef, useRef, useState } from "react";
import {
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonTextarea,
} from "@ionic/react";
import { close } from "ionicons/icons";
import {
  validatePatterns,
  ErrorMessage,
} from "../../../utils-ts/GlobalValidationFunctions";
import {
  Controller,
  Control,
  FieldError,
  NestDataObject,
} from "react-hook-form";
import { isMobile } from "react-device-detect";

type IonTextareaProps = ComponentProps<typeof IonTextarea>;
type inputRef = HTMLIonTextareaElement;

interface InputProps extends IonTextareaProps {
  name: string;
  onClearFunc?: () => void;
  control?: Control;
  label?: string;
  isHideCounter?: boolean;
  pageName?: string;
  customClass?: string;
  errors?: NestDataObject<Record<string, any>, FieldError>;
  maxHeight?: string;
}

export const getRows = (value: string) => {
  if (Array.isArray(value)) {
    return
  }
  const lineBreaks = value?.split("\n")
    ? value?.split("\n").length > 1
      ? value?.split("\n").length
      : 0
    : 0;
  if (!isMobile) {
    return Math.ceil(value.length / 35 + lineBreaks);
  } else {
    const ratio = window.devicePixelRatio || 1;
    const screenWidth =
      window.screen.width * ratio - window.screen.width * ratio * 0.08;
    const charsInRow = screenWidth / (window.screen.width * ratio * 0.025);
    return Math.ceil(value.length / charsInRow + lineBreaks);
  }
};

const CustomTextarea = forwardRef<inputRef, InputProps>(
  ({ label, name, control, errors, onClearFunc, ...props }, ref) => {
    const [tempText, setTempText] = useState(() => {
      return props.value ? props.value : "";
    });

    const counterRef = useRef<HTMLParagraphElement>(null);
    const setRows = (value: string) => {
      if (props.maxlength && counterRef.current) {
        counterRef.current.innerText =
          value?.length.toString() + "/" + props.maxlength.toString();
      }
      return getRows(value);
    };

    return (
      <React.Fragment>
        <IonItem
          className={
            props.pageName == "CommentReplay" ? "comments-text-field" : props.customClass? props.customClass : ""
          }
          color={props.pageName !== "CommentReplay" ? "light" : ""}
          lines={props.pageName !== "CommentReplay" ? "full" : "none"}
          mode="md"
        >
          {label && (
            <IonLabel position="stacked">
              {props.required ? label + "*" : label}
            </IonLabel>
          )}
          <Controller
            as={
              <IonTextarea
                style={{
                  maxHeight: props.maxHeight,
                }}
                className={
                  props.pageName == "CommentReplay" ? "ion-no-padding" : ""
                }
                {...props}
                ref={ref}
              ></IonTextarea>
            }
            name={name}
            control={control}
            defaultValue={tempText}
            rules={{
              required: {
                value: props.required ? props.required : false,
                message: ErrorMessage("required"),
              },
              minLength: {
                value: props.minlength ? props.minlength : "undefined",
                message: ErrorMessage("minLength", props.minlength?.toString()),
              },
              maxLength: {
                value: props.maxlength ? props.maxlength : "undefined",
                message: ErrorMessage("maxLength", props.maxlength?.toString()),
              },
              validate: (value) => {
                // validate text
                if (value != "") {
                  return validatePatterns(value, "text");
                }
              },
            }}
            onChangeName="onIonChange"
            onChange={(selected) => {
              if (selected[0].detail.value != null) {
                const rowCount = setRows(selected[0].detail.value);
                selected[0].target.firstElementChild.firstChild.rows = rowCount;
              }
              return selected[0].detail.value;
            }}
          />
          {onClearFunc && (
            <IonButton
              fill="clear"
              className="textarea-clear-button"
              onClick={() => {
                if (onClearFunc) {
                  onClearFunc();
                }
              }}
            >
              <IonIcon icon={close}></IonIcon>
            </IonButton>
          )}
        </IonItem>
        <div
          className={`input-alerts valid ${
            errors && errors[name] != null ? "error" : ""
          }`}
        >
          {errors && errors[name] != null && (
            <p className="input-error">{(errors as any)[name].message}</p>
          )}
          {props.isHideCounter === undefined && props.maxlength && (
            <p ref={counterRef} className="small-p input-counter">
              {tempText?.length}/{props.maxlength}
            </p>
          )}
        </div>
      </React.Fragment>
    );
  }
);

export default CustomTextarea;
