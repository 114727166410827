import { IonIcon, IonText } from "@ionic/react";
import jsPDF from "jspdf";
import React, { useState, useContext } from "react";
import { HeaderParameters } from "../../data/Header-Context";
import {
  checkIfGeneratedCode,
  generateQrCode,
  updateQrDownload,
} from "../../data/ShareService";
import { getQrImag } from "../../utils-ts/PdfGenerator";
import CustomModal from "../utils/CustomElements/CustomModal/CustomModal";
import CustomQrCode from "../utils/CustomElements/CustomQrCode";
import MemberDetailsContext from "../../data/MemberDetailes-context";
import CustomPdfButton from "../utils/CustomElements/CustomPdfButton";
import CustomImageButton from "../utils/CustomElements/CustomImageButton";
import { printWhite } from "../../images";
import domtoimage from "dom-to-image";
import LogoQR from "../../images/logos/logob-144-mobile-negative-blue.jpg";
import {
  mobile,
  payAttention,
  qr,
  print,
  mobileWhite,
} from "../../images/index";
import axios from "axios";

const QrCodeModule: React.FC<{
  show: boolean;
  url: string;
  closeModal: () => void;
}> = (props) => {
  const MemberDetailsCxt = useContext(MemberDetailsContext);
  const [QrContainer, setQrContainer] = useState<jsPDF>();

  const downloadFunc = () => {
    checkIfGeneratedCode().then((res) => {
      if (res === false) {
        generateQrCode();
      } else {
        updateQrDownload();
      }
    });
    dtlPushEvt({
      event: "qr_code",
      user_action: "print",
    });
  };

  const headerParameters: HeaderParameters = {
    // pdfDownloadPdf: QrContainer,
    // pdfDownloadPdfFunc: downloadFunc,
    modalType: "modal",
  };

  const generateImage = () => {
    if (!document.getElementById("qrImageWraper")) {
      let qrIamge = document.createElement("div");
      qrIamge.className = "qrIamge";
      qrIamge.id = "qrIamge";
      let imageWraper = document.createElement("div");
      let qrEl = document.getElementById("downloadToPdf")!;
      let bussinesDataEl = document.getElementById("bussinesDataContainer")!;
      let qrLogo = new Image();
      qrLogo.src = LogoQR;
      qrLogo.className = "qr-logo";
      imageWraper.className = "qrImageWraper";
      imageWraper.id = "qrImageWraper";

      qrEl.appendChild(qrLogo);
      qrIamge.appendChild(imageWraper);
      imageWraper.appendChild(qrEl);
      imageWraper.appendChild(bussinesDataEl);
      document.body.appendChild(qrIamge);
    }

    domtoimage
      .toJpeg(document.getElementById("qrImageWraper")!, { quality: 1 })
      .then((imgData: any) => {
        var link = document.createElement("a");
        link.download = `${new Date().toISOString()}.jpeg`;
        link.href = imgData;
        link.click();
      });

      dtlPushEvt({
        event: "qr_code",
        user_action: "download",
      });
  };

  return (
    <CustomModal
      pageName={"QrCode"}
      headerParameters={headerParameters}
      closeModal={() => {
        props.closeModal();
      }}
      onWillPresent={async () => {
        if (MemberDetailsCxt.BusinessLogo) {
          if (!MemberDetailsCxt.BusinessLogo.includes("data:image/png")) {
            await axios
              .get(MemberDetailsCxt.BusinessLogo, {
                responseType: "blob",
              })
              .then((response: any) => {
                let reader = new FileReader();
                reader.readAsDataURL(response.data);
                reader.onloadend = function () {
                  let base64data = reader.result;
                  let qrImage = getQrImag(
                    MemberDetailsCxt.GeneralInfo!.CompanyName,
                    base64data
                  );
                  setQrContainer(qrImage);
                };
              })
              .catch((e: any) => {
                let qrImage = getQrImag(
                  MemberDetailsCxt.GeneralInfo!.CompanyName,
                  ""
                );
                setQrContainer(qrImage);
              });
          } else {
            let qrImage = getQrImag(
              MemberDetailsCxt.GeneralInfo!.CompanyName,
              MemberDetailsCxt.BusinessLogo
            );
            setQrContainer(qrImage);
          }
        } else {
          let qrImage = getQrImag(
            MemberDetailsCxt.GeneralInfo!.CompanyName,
            ""
          );
          setQrContainer(qrImage);
        }
      }}
      isOpen={props.show}
      title="הורדת QR"
    >
      <div style={{ marginBottom: 55 }}>
        <IonText color="dark">
          <h2>למה כדאי לכם להשתמש בקוד QR בתור בעלי עסקים באתר בזק B144?</h2>
        </IonText>
        <p className="ion-margin-bottom">
          בשנים האחרונות חוות דעת של גולשים הופכות יותר ויותר דומיננטיות וחשובות
          להצלחת עסקים.
        </p>
        <p className="ion-margin-bottom">
          עפ"י מחקרים בארה"ב 93% ממבצעי הרכישות אונליין הושפעו מחוות דעת על
          השירות/מוצר.
        </p>
        <p>
          חוות דעת דומות מאוד להמלצות של חברים או מכרים ועוזרות לגולשים לקבל את
          הביטחון להגיע להחלטה הנכונה.
        </p>
        <div className="highlight-section">
          <IonIcon icon={qr} size="large" />
          <h3>
            יצרנו עבורכם קוד QR המאפשר ללקוחותיכם להשאיר ביקורת בצורה קלה ומהירה
          </h3>
          <p>
            לחצו על כפתור ההורדה על מנת לשמור את התמונה או על כפתור ההדפסה על
            מנת להדפיס אותה
          </p>
        </div>
        <div className="icon-text-para">
          <IonIcon icon={mobile} />
          <p>
            במידה ואתם נותנים שירות בבית הלקוח דאגו לשמור את התמונה על המכשיר
            הנייד שלכם או בכל מקום זמין אחר
          </p>
        </div>

        <div className="icon-text-para">
          <IonIcon icon={print} />
          <p>
            במידה ואתם מקבלים לקוחות בבית העסק, הדפיסו את הקוד ומקמו אותו בצורה
            בולטת בבית העסק למשל ליד אזור התשלום
          </p>
        </div>

        <div className="highlight-section">
          <h3>אז איך מקבלים חוות דעת?</h3>
          <p>
            כל מה שנותר זה להציג ללקוחותיכם את קוד, לאחר שיסרקו אותו באמצעות
            הנייד הם יופנו מיידית לעמוד המשמש להשארת חוות דעת על העסק שלכם
          </p>
        </div>
        <div className="icon-text-para">
          <IonIcon icon={payAttention} />
          <p>
            אתם יכולים להדפיס את הקוד על גבי מדבקות ולהדביק אותן על גב
            הטלפון/תיק/רכב או כל מקום שתרצו
          </p>
        </div>

        <h3 className="bold-text">תתחדשו, יש לכם ביקורת חדשה</h3>
      </div>
      <div>
        {/* <IonButton onClick={downloadFunc}>הורדה לצורך הדפסה</IonButton> */}
        {QrContainer !== undefined && (
          <div className="fixed-modal-action-buttons-wraper">
            <CustomPdfButton
              pdf={QrContainer}
              callbackfunc={downloadFunc}
              icon={printWhite}
              btnTitle="הורדה לצורך הדפסה"
              btnClass="iconAndTitleRow"
            ></CustomPdfButton>
            <CustomImageButton
              callbackfunc={generateImage}
              icon={mobileWhite}
              btnTitle="הורדה למכשיר"
              btnClass="iconAndTitleRow"
            ></CustomImageButton>
          </div>
        )}
      </div>
      <CustomQrCode qrSize={100} value={props.url}></CustomQrCode>
    </CustomModal>
  );
};
export default QrCodeModule;
