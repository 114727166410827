import React from "react";

export interface CouponMember {
  TemplateId: number;
  CouponDesc: string | undefined;
  CouponActiv: boolean;
  For144ClientsOnly: boolean;
  AdditionalInfo: string | undefined;
  FromDate: string;
  ToDate: string;
  Quantity: number;
  ifOrder: string | undefined;
  thenGet: string | undefined;
  DiscountPercent: number;
  priceAfterDiscount: number;
  priceBeforeDiscount: number;
  showDiscountPct: boolean;
  couponsQuantityRG: number;
  couponImage: string | undefined;
  NumOfRealizations: number | undefined;
}

interface CouponsTemplatesList {
  Id: number;
  Description: string;
}

export interface CouponAdditionalInfo {
  IsDatesValid: boolean;
  ImgTemplate: string;
}

export interface Coupon {
  CouponMember: CouponMember | null;
  CouponsTemplatesList: CouponsTemplatesList[] | null;
  CouponAdditionalInfo: CouponAdditionalInfo | null;
}

const CouponContext = React.createContext<{
  coupon: Coupon | undefined;
  saveCoupon: (
    coupon: CouponMember,
    currentCouponAInfo: CouponAdditionalInfo
  ) => any;
  deleteCoupon: () => any;
  initCouponContext: (useLoading: boolean) => void;
}>({
  coupon: {
    CouponMember: {
      TemplateId: 0,
      CouponDesc: "",
      CouponActiv: false,
      For144ClientsOnly: false,
      AdditionalInfo: "",
      FromDate: "",
      ToDate: "",
      Quantity: 0,
      ifOrder: "",
      thenGet: "",
      DiscountPercent: 0,
      priceAfterDiscount: 0,
      priceBeforeDiscount: 0,
      showDiscountPct: false,
      couponsQuantityRG: 0,
      couponImage: "",
      NumOfRealizations: 0,
    },
    CouponsTemplatesList: [],
    CouponAdditionalInfo: { IsDatesValid: false, ImgTemplate: "" },
  },
  saveCoupon: () => {},
  deleteCoupon: () => {},
  initCouponContext: (useLoading: boolean) => {},
});

export default CouponContext;
