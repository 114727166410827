import React, { useContext, useEffect, useState } from "react";
import {
  IonButton,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
} from "@ionic/react";
import { information, pencil } from "../../../images/index";
import MemberDetailsContext from "../../../data/MemberDetailes-context";
import {
  MemberDetailesArrayToShow,
  businessCardModulesName,
  showEmptyElementsBtn,
  getHtmlData,
} from "../../../utils-ts/BusinessCardFunc";
import MemberDetaielsModules from "./MemberDetaielsModules";
import BusinessAdvCategories from "./BusinessAdvCategories";
import UserSettingsContext from "../../../data/UserSettings-Context";
import UserContext from "../../../data/AuthContextProvider";
import { config } from "../../../Constants";

const MemberDetails: React.FC<{
  changePrecentage: (isChange: boolean) => void;
}> = (props) => {
  const [
    showElementName,
    setShowElementName,
  ] = useState<businessCardModulesName>("");
  const MemberDetailsCxt = useContext(MemberDetailsContext);
  const UserSettingsCtx = useContext(UserSettingsContext);
  const { authValues } = useContext(UserContext);
  useEffect(() => {
    if (MemberDetailsCxt.GeneralInfo == null) {
      MemberDetailsCxt.initMemberDetailsContext();
    }
    if (UserSettingsCtx.userSettingsInfo == null) {
      UserSettingsCtx.initUserSettingContext(true);
    }
    // if (MemberDetailsCxt.BusinessEvents == null) {
    //   MemberDetailsCxt.initBusinessEventsContext();
    // }
    if (MemberDetailsCxt.BusinessLinks == null) {
      MemberDetailsCxt.initBusinessLinksContext();
    }
    if (MemberDetailsCxt.BusinessQuestionsAndAnswers == null) {
      MemberDetailsCxt.initBusinessQuestionsAndAnswerContext();
    }
    if (MemberDetailsCxt.BusinessMovies == null) {
      MemberDetailsCxt.initMovieContext();
    }
  }, []);
  return (
    <React.Fragment>
      {UserSettingsCtx.userSettingsInfo &&
        MemberDetailsCxt.GeneralInfo &&
        MemberDetailsCxt.BusinessLinks &&
        MemberDetailsCxt.BusinessQuestionsAndAnswers &&
        (MemberDetailsCxt.BusinessMovies != undefined) && (
          <IonList>
            {MemberDetailesArrayToShow.filter((o) => {
              if(o.id == "ServiceAreas"){
                //if otef show seviceAreas
                //if b144 show only if not selfReg
                if(!!config.otef_israel){
                  return true;
                }else{
                  return authValues && authValues.IsSelfRegister == false;
                }

              } else if(o.id == "SeoDetails" ||
              o.id == "BusinessLinks"){
                //if otef don't show 
                return !config.otef_israel; //remove SeoDetails next otef version
              } else if(o.id == 'SeoBrands'){
                return MemberDetailsCxt.ShowSeoBrands;
              }
              return true;
              
            }).map((o, index) => {
              if (o.id == "AdvCategories") {
                return (
                  <BusinessAdvCategories
                    key={index}
                    icon={o.icon}
                  ></BusinessAdvCategories>
                );
              } else if (!showEmptyElementsBtn(MemberDetailsCxt, o.id)) {
                const data = getHtmlData(
                  MemberDetailsCxt,
                  UserSettingsCtx,
                  o.id
                );
                return (
                  <IonCard
                    key={index}
                    mode="md"
                    className={` ${
                      !o.isEditable
                        ? " member-detail read-only"
                        : "member-detail"
                    } ${
                      o.id == "PublicationDays" &&
                      !MemberDetailsCxt.isPublicationDays &&
                      " hide "
                    }  ${
                      o.id == "VirtualNumber" &&
                      !MemberDetailsCxt.GeneralInfo?.VirtualNumber &&
                      " hide "
                    }`}
                  >
                    <IonItem lines="none">
                      <IonIcon icon={o.icon} slot="start" />
                      <IonLabel>
                        <h2 className="md-title bold-text">
                          {o.title}
                          {!o.isEditable && (
                            <>
                              :
                              <span
                                className="modal-body title-content"
                                dangerouslySetInnerHTML={{
                                  __html: data,
                                }}
                              ></span>
                            </>
                          )}
                        </h2>
                      </IonLabel>
                      {o.isEditable === true && (
                        <IonButton
                          className="label-button"
                          fill="clear"
                          slot="end"
                          onClick={() => {
                            setShowElementName(o.id);
                          }}
                        >
                          <IonIcon icon={pencil} slot="end" />
                        </IonButton>
                      )}
                    </IonItem>
                    {o.isEditable && (
                      <IonCardContent>
                        <div
                          className="modal-body"
                          dangerouslySetInnerHTML={{
                            __html: data,
                          }}
                        />
                      </IonCardContent>
                    )}
                    {o.id == "BusinessAddress" && 
                  <div className="dv-info-card" >
                    <img src={information}  />
                    <IonCard >
                    
                    שם עסק, טלפון וכתובת ניתן לשנות רק דרך המוקד
                    <IonButton fill="clear" 
                    href={"https://" + (config.otef_israel ? "otefisrael" : "www") + ".b144.co.il/Contact/MailTo/"}
                    target="_blank"  
                    >ליצירת קשר {">"}</IonButton>
                    </IonCard>
                  </div>
                  }
                  </IonCard>
                  
                  
                  
                );
              }
            })}
          </IonList>
        )}

      <MemberDetaielsModules
        closeModal={() => {
          setShowElementName("");
        }}
        onSave={() => {
          props.changePrecentage(true);
        }}
        showElement={showElementName}
      ></MemberDetaielsModules>
    </React.Fragment>
  );
};
export default MemberDetails;
