import React, { useState, useCallback,useContext } from "react";
import UserSettingsContext, {
  memberPreventNotification,
  notificationGroup,
  userProfileSettingsInfo,
  userSettingsInfo,
} from "./UserSettings-Context";
import { config } from "../Constants";
import { fetchRequest } from "../utils-ts/fetchRequest";
//import CustomLoader from "../components/utils/CustomElements/CustomLoader";
import GlobalContext from "./Global-Context";
import { toast } from "react-toastify";
import { Photo } from "../components/utils/ImagePicker/ImagePicker";
import { base64FromPath } from "@ionic/react-hooks/filesystem";
const parseString = require("xml2js").parseString;

const UserSettingsContextProvider: React.FC = (props) => {
  //const [loading, setLoading] = useState<boolean>(false);
  const globalCtx = useContext(GlobalContext);
 const setLoading = (loading:boolean)=>{
  globalCtx.setLoader(loading);
 };

  const [userNotificationAmount, setUserNotificationAmount] = useState<
    number | undefined
  >();

  const [userSettingsInfo, setUserSettingsInfo] = useState<
    userSettingsInfo | undefined
  >();
  const [userProfileSettingsInfo, setUserProfileSettingsInfo] = useState<
    userProfileSettingsInfo | undefined
  >();

  const [memberPreventNotification, setMemberPreventNotificatio] = useState<
    memberPreventNotification[] | undefined
  >();
  const [notificationGroup, setNotificationGroup] = useState<
    notificationGroup[] | undefined
  >();

  const ChangePassword = useCallback(
    async (
      useLoading: boolean,
      NewPassWord: string,
      confirmPassword: string
    ) => {
      useLoading && setLoading(useLoading);
      const jsDetails = {
        password: NewPassWord,
        confirmPassword: confirmPassword,
      };

      var data = new URLSearchParams({
        jsDetails: JSON.stringify(jsDetails),
      }).toString();

      fetchRequest(
        `${config.url.API_URL}/Client.asmx/ChangePassword`,
        "post",
        data
      )
        .then((response) => {
          useLoading && setLoading(false);
          parseString(response, (err: any, result: any) => {
            if (result) {
              var res = JSON.parse(result.string["_"]);
              if (res.Success) {
                if (res.Response == "") {
                  toast.success("סיסמה עודכנה בהצלחה");
                }
              } else {
                toast.error(res.ErrorMessage);
              }
            }
          });
        })
        .catch((error) => {
          useLoading && setLoading(false);
        });
    },
    []
  );

  const initUserSettingContext = useCallback(async (useLoading: boolean) => {
    useLoading && setLoading(useLoading);
    const jsDetails = {};

    var data = new URLSearchParams({
      jsDetails: JSON.stringify(jsDetails),
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/Client.asmx/GetMemberSettings`,
      "post",
      data
    )
      .then((response) => {
        useLoading && setLoading(false);
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              if (res.Response == "") {
                const newSettings = {
                  isShowNotification: true,
                  insertDate: "",
                  updateDate: "",
                  Id: 0,
                };
                setUserSettingsInfo(newSettings);
              } else {
                const data = JSON.parse(res.Response);
                setUserSettingsInfo(data);
              }
            }
          }
        });
      })
      .catch((error) => {
        useLoading && setLoading(false);
      });
  }, []);

  const initUserProfileSettingContext = useCallback(
    async (useLoading: boolean) => {
      useLoading && setLoading(useLoading);
      const jsDetails = {};

      var data = new URLSearchParams({
        jsDetails: JSON.stringify(jsDetails),
      }).toString();

      fetchRequest(
        `${config.url.API_URL}/Client.asmx/GetMemberProfileSettings`,
        "post",
        data
      )
        .then((response) => {
          useLoading && setLoading(false);
          parseString(response, (err: any, result: any) => {
            if (result) {
              var res = JSON.parse(result.string["_"]);
              if (res.Success) {
                const data = JSON.parse(res.Response);
                if (data.ProfileImage.Url != null) {
                  data.ProfileImage.Url =
                    `${config.url.Member_Url}` + `${data.ProfileImage.Url}`;
                } else {
                  data.ProfileImage.Url = "";
                }

                setUserProfileSettingsInfo(data);
              }
            }
          });
        })
        .catch((error) => {
          useLoading && setLoading(false);
        });
    },
    []
  );

  const updateUserSettingContext = useCallback(
    async (useLoading: boolean, userSettingsInfo: userSettingsInfo) => {
      useLoading && setLoading(useLoading);
      const jsDetails = {
        isShowNotification: userSettingsInfo.isShowNotification,
      };

      var data = new URLSearchParams({
        jsDetails: JSON.stringify(jsDetails),
      }).toString();

      fetchRequest(
        `${config.url.API_URL}/Client.asmx/UpdateMemberSettings`,
        "post",
        data
      )
        .then((response) => {
          useLoading && setLoading(false);
          parseString(response, (err: any, result: any) => {
            if (result) {
              var res = JSON.parse(result.string["_"]);
              if (res.Success) {
                setUserSettingsInfo(userSettingsInfo);
              }
            }
          });
        })
        .catch((error) => {
          useLoading && setLoading(false);
        });
    },
    []
  );

  const updateProfilePhoto = useCallback(async (photo: Photo) => {
    if (photo!.preview) {
      const base64 = await base64FromPath(photo!.preview);
      setLoading(true);
      var data = new URLSearchParams({
        sImage: base64,
      }).toString();

      fetchRequest(
        `${config.url.API_URL}/Client.asmx/UploadProfileImage`,
        "post",
        data
      )
        .then((response) => {
          parseString(response, (err: any, result: any) => {
            if (result) {
              var res = JSON.parse(result.string["_"]);
              if (res.Success) {
                setUserProfileSettingsInfo((current) => {
                  const tempObj = { ...current! };
                  tempObj.ProfileImage.Url = photo!.preview;
                  return tempObj;
                });
                toast.success("תמונה עלתה בהצלחה");
              } else {
                toast.error("ארעה שגיאה בהעלאת התמונה");
              }
              setLoading(false);
            }
          });
        })
        .catch(function (error) {
          console.log("Request failed", error);
        });
    }
  }, []);

  const initUserNotificationsContext = useCallback(
    async (useLoading: boolean) => {
      useLoading && setLoading(useLoading);
      const jsDetails = {};

      var data = new URLSearchParams({
        jsDetails: JSON.stringify(jsDetails),
      }).toString();

      fetchRequest(
        `${config.url.API_URL}/Client.asmx/GetMemberNotifications`,
        "post",
        data
      )
        .then((response) => {
          useLoading && setLoading(false);
          parseString(response, (err: any, result: any) => {
            if (result) {
              var res = JSON.parse(result.string["_"]);
              if (res.Success) {
                if (res.Response != "") {
                  const data = JSON.parse(res.Response);
                  setMemberPreventNotificatio(data.MemberPreventNotification);
                  setNotificationGroup(data.NotificationGroup);
                }
              }
            }
          });
        })
        .catch((error) => {
          useLoading && setLoading(false);
        });
    },
    []
  );
  const deleteProfilePhoto = useCallback(async (useLoading: boolean) => {
    useLoading && setLoading(useLoading);

    var data = new URLSearchParams({}).toString();

    fetchRequest(
      `${config.url.API_URL}/Client.asmx/DeleteProfileImage`,
      "post",
      data
    )
      .then((response) => {
        useLoading && setLoading(false);
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              toast.success("תמונה נמחקה בהצלחה");
              setUserProfileSettingsInfo((current) => {
                if (current) {
                  const newInfo = { ...current };
                  newInfo.ProfileImage.Url = "";
                  return newInfo;
                }
              });
            } else {
              toast.info("ארעה שגיאה");
            }
          }
        });
      })
      .catch((error) => {
        useLoading && setLoading(false);
      });
  }, []);
  const updateUserNotificationsContext = useCallback(
    async (
      useLoading: boolean,
      NotificationGroupId: memberPreventNotification[]
    ) => {
      useLoading && setLoading(useLoading);
      let groupsId: number[] = [];
      NotificationGroupId.map((g) => {
        groupsId.push(g.NotificationGroupId);
      });
      const jsDetails = {
        NotificationGroupId: groupsId,
      };

      var data = new URLSearchParams({
        jsDetails: JSON.stringify(jsDetails),
      }).toString();

      fetchRequest(
        `${config.url.API_URL}/Client.asmx/UpdateMemberNotifications`,
        "post",
        data
      )
        .then((response) => {
          parseString(response, (err: any, result: any) => {
            if (result) {
              var res = JSON.parse(result.string["_"]);
              if (res.Success) {
                setMemberPreventNotificatio(NotificationGroupId);
              }
            }
            useLoading && setLoading(false);
          });
        })
        .catch((error) => {
          useLoading && setLoading(false);
        });
    },
    []
  );

  const initUserNotificationAmount = useCallback(async () => {
    var data = new URLSearchParams({}).toString();
    setUserNotificationAmount(4);
    fetchRequest(
      `${config.url.API_URL}/ClientComments.asmx/GetPushes`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const data = JSON.parse(res.Response);
              setUserNotificationAmount(data);
            } else {
              setUserNotificationAmount(0);
            }
          }
        });
      })
      .catch((error) => {});
  }, []);

  const updateUserNotificationAmount = useCallback(async () => {
    var data = new URLSearchParams({}).toString();
    setUserNotificationAmount(0);
    fetchRequest(
      `${config.url.API_URL}/ClientComments.asmx/UpdatePushesRead`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              setUserNotificationAmount(0);
            } else {
            }
          }
        });
      })
      .catch((error) => {});
  }, []);

  return (
    <UserSettingsContext.Provider
      value={{
        initUserNotificationAmount,
        updateUserNotificationAmount,
        initUserSettingContext,
        initUserProfileSettingContext,
        updateUserSettingContext,
        initUserNotificationsContext,
        updateUserNotificationsContext,
        ChangePassword,
        updateProfilePhoto,
        deleteProfilePhoto,
        userNotificationAmount,
        userSettingsInfo,
        userProfileSettingsInfo,
        notificationGroup,
        memberPreventNotification,
      }}
    >
      {props.children}
      {/* <CustomLoader
        isOpen={loading}
        duration={200}
        onDidDismiss={() => {
          setLoading(false);
        }}
      ></CustomLoader> */}
    </UserSettingsContext.Provider>
  );
};

export default UserSettingsContextProvider;
