import { IonButton, IonDatetime, IonIcon, IonLabel } from "@ionic/react";
import { chevronForwardOutline, chevronBackOutline } from "ionicons/icons";
import React, { useState } from "react";
import { arrowDown, calendar } from "../../../../images";
import "./monthFilter.scss";

const MonthFilter: React.FC<{
  currntMonth: number;
  CorrentYear: number;
  LimitYear?: number;
  LimitMonth?: number;
  MaxYear?: number;
  MaxMonth?: number;
  onChange: (newMonth: number, newYear: number) => void;
  type?: "arrowPicker" | "selectPicker";
}> = ({ type = "arrowPicker", ...props }) => {
  let newDate = new Date();

  const currentMonth = newDate.getMonth() + 1;
  const _currentYear = newDate.getFullYear();
  let month = props.currntMonth;
  let year = props.CorrentYear;
  let minDate = "1980-01-01";
  let maxDate = _currentYear + "-" + ("0" + currentMonth).slice(-2) + "-01";

  if (props.LimitYear && props.LimitMonth) {
    minDate =
      props.LimitYear + "-" + ("0" + props.LimitMonth).slice(-2) + "-01";
  }

  if (props.MaxYear && props.MaxMonth) {
    maxDate = props.MaxYear + "-" + ("0" + props.MaxMonth).slice(-2) + "-01";
  }

  const [dateValue, setDateValue] = useState(() => {
    return year + "-" + (month < 10 ? "0" : "") + month;
    // return year + " " + months[month - 1];
  });

  const [showDecreaseBtn, isShowDecreaseBtn] = useState<boolean>(() => {
    if (
      props.LimitMonth &&
      props.LimitYear &&
      (year < props.LimitYear ||
        (year == props.LimitYear && month <= props.LimitMonth))
    ) {
      return true;
    } else {
      return false;
    }
  });

  const [showIncreaseBtn, isShowIncreaseBtn] = useState(() => {
    if (month === currentMonth && year === _currentYear) {
      return true;
    } else {
      return false;
    }
  });
  const [isOpen, setIsOpen] = React.useState(false);
  const datePickerRef = React.useRef<any>(null);

  //isIncrement = האם להעלות חודש
  const changeDate = (isIncrement: boolean) => {
    const newDate = new Date(year, month - 1, 1);
    newDate.setMonth(newDate.getMonth() + (isIncrement ? 1 : -1));
    month = newDate.getMonth() + 1;
    year = newDate.getFullYear();
    setDateValue(year + "-" + (month < 10 ? "0" : "") + month);
  };

  const monthPicker = (
    <IonDatetime
      className="ion-no-padding small-p bold-text"
      value={dateValue}
      // disabled={true}
      displayFormat="MM/YYYY"
      min={minDate}
      max={maxDate}
      cancelText="ביטול"
      doneText="אישור"
      ref={datePickerRef}
      onIonCancel={() => setIsOpen(false)}
      onIonChange={(e) => {
        setIsOpen(false);
        const value = e.detail.value!;
        if (value != "") {
          const DateArray = value.split("-");
          month = Number(DateArray[1]);
          year = Number(DateArray[0]);
          //setValues
          setDateValue(year + "-" + (month < 10 ? "0" : "") + month);
          //hide btns
          if (
            props.LimitMonth &&
            props.LimitYear &&
            (year < props.LimitYear ||
              (year == props.LimitYear && month <= props.LimitMonth))
          ) {
            isShowDecreaseBtn(true);
          } else {
            isShowDecreaseBtn(false);
          }

          if (month === currentMonth && year === _currentYear) {
            isShowIncreaseBtn(true);
          } else {
            isShowIncreaseBtn(false);
          }

          props.onChange(month, year);
        }
      }}
    />
  );

  return (
    <div className="month-filter-container">
      {dateValue != undefined && (
        <>
          {type === "arrowPicker" && (
            <IonButton
              size="small"
              className="icon-button"
              disabled={showDecreaseBtn}
              fill="clear"
              onClick={() => {
                changeDate(false);
              }}
            >
              <IonIcon
                icon={chevronForwardOutline}
                size="small"
                slot="icon-only"
              ></IonIcon>
            </IonButton>
          )}
          {type === "selectPicker" ? (
            <div className="monthButton">
              <img src={calendar} alt="calendar svg" />
              <div
                style={{ display: "flex", marginTop: "2px" }}
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                {monthPicker}
                <img
                  onClick={() => {
                    datePickerRef?.current?.click();
                  }}
                  src={arrowDown}
                  alt="arrow down"
                  style={
                    isOpen
                      ? {
                          transform: "rotate(180deg)",
                          transition: "transform ease 0.5s",
                          margin: "2px 3px",
                        }
                      : { margin: "3px" }
                  }
                />
              </div>
            </div>
          ) : (
            monthPicker
          )}
          {type === "arrowPicker" && (
            <>
              <IonButton
                disabled={showIncreaseBtn}
                className="icon-button"
                fill="clear"
                onClick={() => {
                  changeDate(true);
                }}
              >
                <IonIcon
                  icon={chevronBackOutline}
                  size="small"
                  slot="icon-only"
                ></IonIcon>
              </IonButton>
              <IonButton
                fill="clear"
                size="small"
                hidden={showIncreaseBtn}
                onClick={() => {
                  setDateValue(
                    _currentYear +
                      "-" +
                      (currentMonth < 10 ? "0" : "") +
                      currentMonth
                  );
                }}
              >
                החודש
              </IonButton>
            </>
          )}
        </>
      )}
    </div>
  );
};
export default MonthFilter;
