export const monthNames = [
  "ינואר",
  "פבואר",
  "מרץ",
  "אפריל",
  "מאי",
  "יוני",
  "יולי",
  "אוגוסט",
  "ספטמבר",
  "אוקטובר",
  "נובמבר",
  "דצמבר",
];

export const convertTimeStampToDate = (timeStamp: string) => {
  const returnDate = new Date(
    +timeStamp.replace(/\/Date\((\d+)\)\//, "$1")
  ).toLocaleDateString("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  return returnDate;
};

export const convertTimeStampToTime = (timeStamp: string) => {
  const returnDate = new Date(
    +timeStamp.replace(/\/Date\((\d+)\)\//, "$1")
  ).toLocaleTimeString("en-GB", {
    minute: "2-digit",
    hour: "2-digit",
  });
  return returnDate;
};

export const getTimeFotmat = (houre: number, minut: number) => {
  return ("0" + houre).slice(-2) + ":" + ("0" + minut).slice(-2);
};

export const getDateFormat = (date: Date) => {
  return (
    date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
  );
};

export const getDateReverseFormat = (date: Date) => {
  return (
    date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()
  );
};

export const getTimeObjFromIsoString = (value: string) => {
  let time = null;
  if (value.indexOf("T") > -1) {
    time = new Date(value);
    time = getTimeFotmat(time.getHours(), +time.getMinutes());
  } else {
    time = value;
  }
  return time;
};

export const getDateObjFromIsoString = (value: string) => {
  let date = null;
  if (value != null && value.indexOf("T") > -1) {
    date = new Date(value);
    date = getDateFormat(date);
  } else {
    date = value;
  }
  return date;
};

export const dateToISOString = (date: string) => {
  if (date) {
    return new Date(parseInt(date.substr(6))).toISOString();
  } else {
    return date;
  }
};

export const addDaysToDate = (date: Date, days: number) => {
  return new Date(date.setDate(date.getDate() + days));
};

export const addMonthsToDate = (date: Date, months: number) => {
  var d = date.getDate();
  date.setMonth(date.getMonth() + months);
  if (date.getDate() !== d) {
    date.setDate(0);
  }
  return date;
};

export const monthDiff = (d1: any, d2: any) => {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
};

/**
     * MM & DD -> return double digit date parts (with 0 prefix) if less then 10.
     * M & D -> return single digit date parts if less then 10.
     * mm -> minutes.
     * ss -> seconds.
     * fff -> milliseconds.
     */
export const formatDate = (timeStamp: string, format: string) => {
  try {
    const req_date = new Date(+timeStamp.replace(/\/Date\((\d+)\)\//, "$1"));
      if (!req_date) {
          return '';
      }
      
      const month_names = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      const month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const map:{ [key: string]: any } = {
          HH: ('0' + req_date.getHours()).slice(-2),
          mm: ('0' + req_date.getMinutes()).slice(-2),
          ss: ('0' + req_date.getSeconds()).slice(-2),
          fff: ('00' + req_date.getMilliseconds()).slice(-3),
          M: req_date.getMonth() + 1,
          MM: ('0' + (req_date.getMonth() + 1)).slice(-2),
          MMM: month_names_short[req_date.getMonth()],
          MMMM: month_names[req_date.getMonth()],
          D: req_date.getDate(),
          DD: ('0' + req_date.getDate()).slice(-2),
          YY: req_date.getFullYear().toString().slice(-2),
          YYYY: req_date.getFullYear()
      };

      const result = format.replace(/HH|mm|ss|fff|MMMM|MMM|MM|M|DD|D|YYYY|YY/g, (matched) => map[matched]);
      return result;
  } catch (error) {
      console.log(error);
      // logger.info(createLogMessage(stack(), error));
  }
}