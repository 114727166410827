import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IonRow,
  IonCol,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonCheckbox,
  IonIcon,
  IonPopover,
  IonCard,
  IonButton,
  IonContent,
} from "@ionic/react";
import { isMobile } from 'react-device-detect';
import SelfSaleContext, { MemberAreas, Categories, CategoriesAdv } from "../../../data/SelfSaleContext";
import SelfSaleProgressBar, { progressBarButton, progressBarStep } from '../SelfSaleProgressBar';
import CustomInputGroup from "../CustomElements/CustomRadioGroup";
import CustomInput from "../../utils/CustomElements/CustomInput";
import { useForm } from "react-hook-form";
import CustomSelect from "../CustomElements/CustomSelect";
import { toast } from "react-toastify";
import CustomTextInput from "../CustomElements/CustomTextInput";
import { number } from "yup";
//import CustomCheckbox from '../CustomElements/CustomCheckbox';
export interface lpGoal {
  goal_id: number, goal_desc: string, selected: boolean
}
interface lpData {
  sales_line_id: string
  lp_business_specialty: string,
  lp_population: string,
  lp_prod_service: string,
  prod_choose_reasons: string,
  prod_fix_problems: string,
  comments: string,
  lp_important_info: string,
  lp_slogen: string,
  lp_trigger: string,
  LandingPageGoal: string
}
const SelfSaleLandingPage: React.FC<{}> = (props) => {
  const { handleSubmit, setValue, errors, control, getValues } = useForm({
    mode: "onChange"
  });
  const SelfSaleCtx = useContext(SelfSaleContext);
  const [lpGoals, setLpGoals] = useState<lpGoal[]>([]);
  const [selectedLpGoals, setSelectedLpGoals] = useState<string[]>([]);
  const [selectedLpGoal, setSelectedLpGoal] = useState<string>("");
  const [selectedLpPopulation, setSelectedLpPopulation] = useState<string[]>([]);
  const [steps, setSteps] = useState<progressBarStep[]>([{
    id: 1, step_desc: '', is_completed: false, hidden: false
  },
  {
    id: 2, step_desc: '', is_completed: false, hidden: false
  }]);
  const [progressBarNextBtn, setProgressBarNextBtn] = useState<progressBarButton>({
    text: "הבא", showIcon: true, class: "", hidden: false, disabled: false
  });
  const [progressBarBackBtn, setProgressBarBackBtn] = useState<progressBarButton>({
    text: "הקודם", showIcon: true, class: "", hidden: false, disabled: false
  });
  const [currentPhaseIndex, setCurrentPhaseIndex] = useState<number>(0);
  const [nextPhaseIndex, setNextPhaseIndex] = useState<number>(0);

  const [landingPageData, setLandingPageData] = useState<lpData>({
    sales_line_id: "",
    lp_business_specialty: "",
    lp_population: "",
    lp_prod_service: "",
    prod_choose_reasons: "",
    prod_fix_problems: "",
    comments: "",
    lp_important_info: "",
    lp_slogen: "",
    lp_trigger: "",
    LandingPageGoal: "",
  });
  const [formValid, setFormValid] = useState<boolean>(false);
  const populationOptions = [
    {
      value: "1",
      label: "בנות"
    },
    {
      value: "2",
      label: "בנים"
    },
    {
      value: "3",
      label: "גיל 24-16"
    },
    {
      value: "4",
      label: "גיל 34-25"
    },
    {
      value: "5",
      label: "גיל 44-35"
    },
    {
      value: "6",
      label: "גיל 45 ומעלה"
    },
    {
      value: "7",
      label: "הורים לילדים"
    },
    {
      value: "0",
      label: "אחר"
    },
  ]

  useEffect(() => {
    SelfSaleCtx.setLpSaleStep(1)
    setLandingPageData(prevState => (
      {
        ...prevState,
        'sales_line_id': SelfSaleCtx.saleLineId
      }))
    SelfSaleCtx.getLandingPageGoals()
      .then((res: any) => {
        setLpGoals(res)
      })
  }, [])
  useEffect(() => {
    if (formValid) {
      saveLpData()
    }
  }, [formValid])
  useEffect(() => {
    if (nextPhaseIndex === steps.length - 1) {
      setProgressBarNextBtn(prevState => (
        {
          ...prevState,
          text: "סיום",
          disabled: false,
          showIcon: false,
          class: "pb-control pb-control-last"
        }))
    }
    else {
      setProgressBarNextBtn(prevState => (
        {
          ...prevState,
          text: "הבא",
          disabled: false,
          showIcon: true,
          class: "pb-control pb-control-continue"
        }))
    }
  }, [nextPhaseIndex])
  const handleSetCurrentPhase = (currentIndex: number, nextIndex: number, isCompleted: boolean) => {
    setFormValid(false)
    let valid = validateLpData(steps[currentIndex].id)
    if (!valid && isCompleted) {
      toast.info("יש למלא את כל השדות בטופס")
      return
    }
    if (nextIndex === -1 && isCompleted) {
      setFormValid(valid)
      return
    }
    setNextPhaseIndex(nextIndex);
    if (isCompleted) {
      setSteps((prevState) =>
        prevState.map((item) => {
          if (steps[currentIndex] && item.id === steps[currentIndex].id) {
            item.is_completed = true;
          }
          return item;
        })
      );
    } else {
      setSteps((prevState) =>
        prevState.map((item) => {
          if (item.id === steps[currentIndex].id || item.id === steps[nextIndex].id) {
            item.is_completed = false;
          }
          return item;
        })
      );
    }
    setCurrentPhaseIndex(nextIndex)
    SelfSaleCtx.setLpSaleStep(steps[nextIndex].id)
  };

  const saveLpData = () => {
    SelfSaleCtx.saveLandingPageSale(JSON.stringify(landingPageData))
      .then((res: any) => {
        if (res.Success && res.ResultData !== "") {
          SelfSaleCtx.setLpSaleStep(0)
          SelfSaleCtx.setLpSaleRequestId(res.ResultData)
        }
      })
  }
  const validateLpData = (step: number) => {
    let inputValues = getValues();
    let valid = true;

    if (step === 1) {
      valid = landingPageData.lp_prod_service !== undefined && landingPageData.lp_prod_service !== ""
        && selectedLpPopulation.join(",") !== ""
        && landingPageData.prod_fix_problems !== undefined && landingPageData.prod_fix_problems !== ""
        && landingPageData.lp_business_specialty !== undefined && landingPageData.lp_business_specialty !== ""
        && landingPageData.prod_choose_reasons !== undefined && landingPageData.prod_choose_reasons !== ""

      if (valid) {
        setLandingPageData(prevState => (
          {
            ...prevState,
            'lp_population': selectedLpPopulation.join(",")
          }))
      }
    }
    if (step === 2) {
      valid = landingPageData.lp_important_info !== undefined && landingPageData.lp_important_info !== ""
        && landingPageData.lp_slogen !== undefined && landingPageData.lp_slogen !== ""
        && landingPageData.lp_trigger !== undefined && landingPageData.lp_trigger !== ""
        && selectedLpGoal !== ""

      if (valid) {
        setLandingPageData(prevState => (
          {
            ...prevState,
            'LandingPageGoal': selectedLpGoal,
          }))
      }
    }


    return valid;
  }
  const handleInputChange = (e: any) => {
    setLandingPageData((prevState) => {
      return { ...prevState, [e.target.name]: e.detail.value }
    })
  }
  return (
    <>
      {steps[currentPhaseIndex].id === 1 && (
        <IonCard mode="md" className={isMobile ? "self-sale-card lp-card self-sale-card-mobile" : "self-sale-card lp-card"}>
          <h2 className="ion-no-margin self-sale-section-title">דף נחיתה במיוחד לעסק שלך</h2>
          <p className="ion-no-margin self-sale-section-text">
            כדי לבנות את דף הנחיתה, נשתמש במידע שמופיע בדף העסק שלך ובתשובות לשאלות שמופיעות בהמשך. מומלץ לתת לנו כמה שיותר מידע כדי שהפרסום יעודד לקוחות פוטנציאליים לפנות אליך.          </p>
          <IonRow>
            <IonCol size="12" size-lg="10">
              <div style={{ marginBottom: '11px' }}>
                <p className="ion-no-margin self-sale-section-subtitle">מה המוצרים או השירותים העיקריים שהעסק שלך מציע?</p>
                <CustomTextInput  maxlength={500} placeholder="לדוגמה: שירותי תיקון" name="lp_prod_service" value={landingPageData.lp_prod_service} onChange={handleInputChange} />
                {/* <CustomInput
                  name="lp_prod_service"
                  required={false}
                  control={control}
                  class="input-item-bordered"
                  placeholder="לדוגמה: שירותי תיקון"
                  maxlength={500}
                ></CustomInput> */}
              </div>

              <div style={{ marginBottom: '5px' }}>
                <p className="ion-no-margin self-sale-section-subtitle">מי קהל היעד העיקרי שלך?</p>
                <CustomSelect
                  onSelect={(e) => setSelectedLpPopulation(e)}
                  multiple={true}
                  value={selectedLpPopulation}
                  // class="input-item-bordered"
                  label=""
                  showValues={false}
                  placeholder="בחירה מתוך רשימה"
                  name="population"
                  optionLabelFld="label"
                  optionValueFld="id"
                  options={populationOptions} />
              </div>
              <div style={{ marginBottom: '4px' }}>
                <p className="ion-no-margin self-sale-section-subtitle">איזה בעיות העסק שלך פותר עבורם?</p>
                <CustomTextInput maxlength={500} placeholder="לדוגמה: הסרת כתמים קשים" name="prod_fix_problems" value={landingPageData.prod_fix_problems} onChange={handleInputChange} />
              </div>
              {/* <CustomInput
                name="prod_fix_problems"
                required={false}
                placeholder="לדוגמה: הסרת כתמים קשים"
                control={control}
                class="input-item-bordered"
                maxlength={500}
              ></CustomInput> */}
              <div style={{ marginBottom: '4px' }}>
                <p className="ion-no-margin self-sale-section-subtitle">מה הופך את העסק שלך לייחודי בהשוואה למתחרים?</p>
                <CustomTextInput maxlength={500} placeholder="לדוגמה: שימוש רק בחומרים טבעיים" name="lp_business_specialty" value={landingPageData.lp_business_specialty} onChange={handleInputChange} />
              </div>
              {/* <CustomInput
                name="lp_business_specialty"
                placeholder="לדוגמה: שימוש רק בחומרים טבעיים"
                required={false}
                control={control}
                class="input-item-bordered"
                maxlength={500}
              ></CustomInput> */}

              <div style={{ marginBottom: '1px' }}>
                <p className="ion-no-margin self-sale-section-subtitle">למה לקוחות ירצו לבחור בך ולא באחרים?</p>
                <CustomTextInput maxlength={500} placeholder="לדוגמה: וותק וניסיון עשיר" name="prod_choose_reasons" value={landingPageData.prod_choose_reasons} onChange={handleInputChange} />
              </div>
              {/* <CustomInput
                name="prod_choose_reasons"
                placeholder="לדוגמה: וותק וניסיון עשיר"
                required={false}
                control={control}
                class="input-item-bordered"
                maxlength={500}
              ></CustomInput> */}
            </IonCol>
          </IonRow>
        </IonCard>
      )}

      {steps[currentPhaseIndex].id === 2 && (
        <IonCard mode="md" className={isMobile ? "self-sale-card lp-card self-sale-card-mobile" : "self-sale-card lp-card"}>
          <IonRow>
            <IonCol size="12" size-lg="10">
              <div style={{ marginBottom: '11px' }}>
                <p className="ion-no-margin self-sale-section-subtitle">מה תהיה המטרה העיקרית של דף הנחיתה?</p>
                <CustomSelect
                  onSelect={(e) => setSelectedLpGoal(e)}
                  multiple={false}
                  // class="input-item-bordered"
                  value={selectedLpGoal}
                  label=""
                  showValues={false}
                  placeholder="בחירה מתוך רשימה"
                  name="LandingPageGoal"
                  optionLabelFld="goal_desc"
                  optionValueFld="goal_id"
                  options={lpGoals} />
              </div>
              <div style={{ marginBottom: '4px' }}>

                <p className="ion-no-margin self-sale-section-subtitle">איזה מידע חשוב לך מאוד שיופיע בדף?</p>
                <CustomTextInput maxlength={500} placeholder="לדוגמה: עלויות, כשרות, ביטוח, אחריות וכו׳" name="lp_important_info" value={landingPageData.lp_important_info} onChange={handleInputChange} />

                {/* <CustomInput
                  name="lp_important_info"
                  label=""
                  required={false}
                  control={control}
                  placeholder="לדוגמה: עלויות, כשרות, ביטוח, אחריות וכו׳"
                  class="input-item-bordered"
                  maxlength={500}
                ></CustomInput> */}
              </div>
              <div style={{ marginBottom: '4px' }}>

                <p className="ion-no-margin self-sale-section-subtitle">יש לך הטבה, קופון או הנחה מיוחדת להציע ללקוחות הדף?</p>
                <CustomTextInput maxlength={500} placeholder="לדוגמה: 5% הנחה למזמינים מעל 100 ש״ח" name="lp_trigger" value={landingPageData.lp_trigger} onChange={handleInputChange} />
              </div>
              {/* <CustomInput
                name="lp_trigger"
                label=""
                placeholder="לדוגמה: 5% הנחה למזמינים מעל 100 ש״ח"
                required={false}
                control={control}
                class="input-item-bordered"
                maxlength={500}
              ></CustomInput> */}
              <div style={{ marginBottom: '5px' }}>
                <p className="ion-no-margin self-sale-section-subtitle">יש לך בקשות ספציפיות שקשורות לעיצוב הדף?</p>

                <CustomTextInput maxlength={500} placeholder="לדוגמה: צבעים, גופנים, סגנון, סלוגן…" name="lp_slogen" value={landingPageData.lp_slogen} onChange={handleInputChange} />
              </div>
              {/* <CustomInput
                name="lp_slogen"
                placeholder="לדוגמה: צבעים, גופנים, סגנון, סלוגן…"
                required={false}
                control={control}
                class="input-item-bordered"
                maxlength={500}
              ></CustomInput> */}
              <div style={{ marginBottom: '12px' }}>

                <p className="ion-no-margin self-sale-section-subtitle">בקשות מיוחדות</p>
                <CustomTextInput maxlength={500} placeholder="ניתן להקליד כאן" name="comments" value={landingPageData.comments} onChange={handleInputChange} />
              </div>
              {/* <CustomInput
                name="comments"
                label=""
                placeholder="ניתן להקליד כאן"
                required={false}
                control={control}
                class="input-item-bordered"
                maxlength={500}
              ></CustomInput> */}
              <p className="ion-no-margin self-sale-section-text">רוצה להוסיף לדף הנחיתה תמונות, סרטונים או טקסטים?<br />
                אפשר לשלוח לנו אותם בוואטסאפ למספר: 037279982 </p>
            </IonCol>
          </IonRow>
        </IonCard>)}
      <IonRow>
        <IonCol size="12">
          <SelfSaleProgressBar salePhases={steps} currentIndex={currentPhaseIndex} setCurrentPhase={handleSetCurrentPhase} class='self-sale-section-right' backBtn={progressBarBackBtn} nextBtn={progressBarNextBtn} />
        </IonCol>
      </IonRow>
    </>
  );
};

export default SelfSaleLandingPage;