import React, { useContext, useState, useEffect, useRef } from "react";
import { IonButton, IonCol, IonGrid, IonRow } from "@ionic/react";

import { useForm } from "react-hook-form";
import CustomForm from "../utils/CustomElements/CustomForm";
import CustomPublishButton from "../utils/CustomElements/CustomPublishButton";
import VasContext, {
  DigitalProductField,
  digitalProductReq,
  ecommerceReq,
  landingPageReq,
  selectOption,
  webSiteReq,
} from "../../data/Vas-context";

import FrmLandingPage from "./FrmLandingPage";
import FrmWebsite from "./FrmWebsite";
import FrmEcommerce from "./FrmEcommerce";
import FrmDigitalProd from "./FrmDigitalProd";
import CustomModal from "../utils/CustomElements/CustomModal/CustomModal";
import { successBlue } from "../../images";

const FrmVasMain: React.FC<{
  onSuccess: (prod_code: number) => void;
  prod_code: number;
  //onFormSateChange: (dirty: boolean) => void;
}> = (props) => {
  const vasCxt = useContext(VasContext);
  const mounted = useRef(false);

  const [digitalProductFields, setDigitalProductFields] = useState<
    DigitalProductField[] | null
  >(null);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [formFields, setFormFields] = useState<string[]>([]);
  const [mainError, setMainError] = useState<string>("");

  useEffect(() => {
    mounted.current = true;
    if (
      (props.prod_code == 21 || props.prod_code == 24) &&
      digitalProductFields == null
    ) {
      vasCxt
        .initDigitalProductFields(props.prod_code, true)
        .then((result: any) => {
          setDigitalProductFields(result);
          if (result) {
            let fields = result.map((f: DigitalProductField) => {
              return f.fldKey;
            });
            fields.push("pack");
            setFormFields(fields);
          } else {
            setFormFields([]);
          }
        });
    }

    if (props.prod_code == 1) {
      setFormFields([
        "pack",
        "busDesc",
        "isDomain",
        "colors",
        "contactHours",
        "remarks",
      ]);
    } else if (props.prod_code == 12) {
      setFormFields(["pack", "contactHours", "remarks"]);
    } else if (props.prod_code == 20) {
      setFormFields([
        "pack",
        "goal",
        "hasImages",
        "hasDocs",
        "contactHours",
        "remarks",
      ]);
    }
    return () => {
      mounted.current = false;
    };
  }, []);

  const {
    handleSubmit,
    formState,
    errors,
    control,
    register,
    clearError,
    getValues,
    setError,
  } = useForm({
    mode: "onChange",
  });

  // useEffect(() => {
  //     props.onFormSateChange(formState.dirty);

  // }, [formState.dirty]);

  const SaveVasMain = (data: any) => {
    //const vals = getValues();
    const pack = data["pack"];
    if (!pack) {
      setError("pack", "manual", "נא לבחור חבילה");
      return;
    }

    switch (props.prod_code) {
      case 20: //landing page
        const lp_req: landingPageReq = {
          pack: pack,
          hasImages: data["hasImages"],
          hasDocs: data["hasDocs"],
          availabilityTime: data["contactHours"],
          comments: data["remarks"],
          pageGoal: data["goal"].toString(),
        };
        vasCxt.insertLandingPage(true, lp_req).then((res: any) => {
          if (res) {
            if (res.success) {
              setShowConfirmation(true);
            } else if (res.errorsList) {
              handleApiErrors(res.errorsList);
            }
          }
        });
        break;
      case 1: //website
        const ws_req: webSiteReq = {
          prod_pack: pack,
          availability_time: data["contactHours"],
          comments: data["remarks"],
          description: data["busDesc"],
          is_domain: data["isDomain"],
          colors: data["colors"],
        };
        vasCxt.insertWebSite(true, ws_req).then((res: any) => {
          if (res) {
            if (res.success) {
              setShowConfirmation(true);
            } else if (res.errorsList) {
              handleApiErrors(res.errorsList);
            }
          }
        });
        break;
      case 12: //ecomm
        const ecomm_req: ecommerceReq = {
          prod_pack: pack,
          availability_time: data["contactHours"],
          comments: data["remarks"],
        };
        vasCxt.insertEcommerce(true, ecomm_req).then((res: any) => {
          if (res) {
            if (res.success) {
              setShowConfirmation(true);
            } else if (res.errorsList) {
              handleApiErrors(res.errorsList);
            }
          }
        });
        break;
      case 21:
      case 24: //digital
        const req_data: DigitalProductField[] = [];
        if (digitalProductFields) {
          for (let i = 0; i < digitalProductFields.length; i++) {
            let field = digitalProductFields[i];
            if (field.fldType == 6) {
              field.value = data[field.fldKey] ? "1" : "0";
            } else {
              field.value = data[field.fldKey];
              if (field.value == undefined) {
                field.value = "";
              }
            }
            req_data.push(field);
          }
        }

        const digital_req: digitalProductReq = {
          productCode: props.prod_code.toString(),
          pack: pack,
          data: req_data,
        };
        vasCxt.insertDigitalProduct(true, digital_req).then((res: any) => {
          if (res && res.success) {
            props.onSuccess(props.prod_code);
          } else if (res && res.errorsList) {
            handleApiErrors(res.errorsList);
          }
        });
        break;
    }
  };

  const VasAddedConfirm = () => {
    setShowConfirmation(false);
    props.onSuccess(props.prod_code);
  };
  const handleApiErrors = (errorsList: any) => {
    for (let key in errorsList) {
      if (formFields.indexOf(key) >= 0) {
        setError(key, "manual", errorsList[key]);
      } else {
        setMainError(errorsList[key]);
      }
    }
  };

  return (
    <CustomForm onSubmit={handleSubmit(SaveVasMain)} errors={errors}>
      <CustomModal
        pageName={"FrmVasMainConfirm"}
        headerParameters={{
          isPageHaveChanges: false,
          modalType: "confirm",
        }}
        closeModal={() => {
          if (mounted.current) {
            setShowConfirmation(false);
          }
        }}
        isOpen={showConfirmation}
      >
        <div className="ion-text-center">
          <div className="ion-margin-bottom ion-padding-bottom">
            <img src={successBlue} height={110} />
          </div>
          <div>בקשתך נקלטה במערכת!</div>
          <div>נציג יחזור אליך בשעות הנוחות שלך.</div>
        </div>
        <IonButton
          type="button"
          size="default"
          expand="block"
          className="main-button "
          onClick={VasAddedConfirm}
        >
          אישור
        </IonButton>
      </CustomModal>
      <IonGrid className="main-form-cont">
        {props.prod_code == 20 && (
          <FrmLandingPage
            clearError={clearError}
            register={register}
            control={control}
            errors={errors}
          ></FrmLandingPage>
        )}
        {props.prod_code == 1 && (
          <FrmWebsite
            clearError={clearError}
            register={register}
            control={control}
            errors={errors}
          ></FrmWebsite>
        )}
        {props.prod_code == 12 && (
          <FrmEcommerce
            clearError={clearError}
            register={register}
            control={control}
            errors={errors}
          ></FrmEcommerce>
        )}
        {(props.prod_code == 21 || props.prod_code == 24) &&
          digitalProductFields && (
            <FrmDigitalProd
              clearError={clearError}
              register={register}
              control={control}
              errors={errors}
              prod_code={props.prod_code}
              digitalProductFields={digitalProductFields}
            ></FrmDigitalProd>
          )}
        {mainError && (
          <IonRow>
            <IonCol>
              <div className="input-alerts">
                <p className="input-error">{mainError}</p>
              </div>
            </IonCol>
          </IonRow>
        )}
        <IonRow className="ion-justify-content-end">
          <IonCol size="12" sizeMd="6">
            <CustomPublishButton title="שמירה"></CustomPublishButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </CustomForm>
  );
};

export default FrmVasMain;
