import React, { useContext, useEffect, useState } from "react";
import SelfServiceContext, {
  BudgetDetails,
} from "../../data/SelfService-context";
import { HeaderParameters } from "../../data/Header-Context";
import CustomPage from "../../components/utils/CustomElements/CustomPage";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonRow,
} from "@ionic/react";
import BudgetInfo from "../../components/Budget/BudgetInfo";
import CustomModal from "../../components/utils/CustomElements/CustomModal/CustomModal";
import BudgetUpgrade from "../../components/Budget/BudgetUpgrade";
import { toast } from "react-toastify";
import "./Budget.scss";
import { isMobile } from "react-device-detect";

const headerParameters: HeaderParameters = {
  isShowLogo: true,
  backButton: { isShowMobile: true },
  isShowNotification: true,
};

const Budget: React.FC = () => {
  const selfCtx = useContext(SelfServiceContext);
  const [currentBudget, setCurrentBudget] = useState<BudgetDetails>();
  const [upgradeBudget, setUpgradeBudget] = useState<boolean>(false);

  useEffect(() => {
    if (currentBudget == null) {
      selfCtx.getMemberBudgetDetails(true).then((res: any) => {
        if (res != null) {
          setCurrentBudget(res);
        }
      });
    }
  }, []);

  const handleBudgetUgradeSuccess = (success: boolean) => {
    setUpgradeBudget(false);
    if (success) {
      toast.success("החבילה תשודרג תוך 48 שעות");
    }
  };
  return (
    <React.Fragment>
      <CustomPage
        headerParameters={{
          isShowLogo: true,
          backButton: { isShowMobile: true },
          isShowDeleteButton: false,
        }}
        title="חבילת הפרסום שלך"
        pageName={"Budget"}
      >
        <IonGrid fixed={true} className="main-content">
          <IonRow>
            <IonCol sizeMd="6" pullMd="3">
              <IonCard>
                <IonCardContent>
                  {currentBudget && (
                    <BudgetInfo currentBudget={currentBudget} />
                  )}

                  {currentBudget &&
                    currentBudget.recommendedPacks &&
                    currentBudget.recommendedPacks.length > 0 && (
                      <IonRow>
                        <IonCol sizeMd="8" pullMd="2">
                          <IonButton
                            type="button"
                            size="small"
                            expand="block"
                            className="main-button "
                            onClick={() => {
                              setUpgradeBudget(true);
                            }}
                          >
                            שדרג
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    )}
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </CustomPage>
      <CustomModal
        pageName={"UpgradeBudget"}
        headerParameters={{
          isPageHaveChanges: true,
          isShowDeleteButton: false,
          isShowLogo: isMobile,
          modalType: "modal",
        }}
        closeModal={() => {
          setUpgradeBudget(false);
        }}
        isOpen={upgradeBudget}
        title="שדרוג חבילה"
        subTitle="כאן ניתן לשדרג את חבילת הפרסום שלך"
      >
        {currentBudget && (
          <BudgetUpgrade
            currentBudget={currentBudget}
            onSuccess={handleBudgetUgradeSuccess}
          />
        )}
      </CustomModal>
    </React.Fragment>
  );
};
export default Budget;
