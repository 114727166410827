import React, { useCallback, useContext } from 'react';
import { Capacitor, Plugins } from '@capacitor/core';
import { Redirect } from 'react-router-dom';
import UserContext, { userData } from './Auth-context';
import { config } from '../Constants';
import { fetchRequest, fetchRequestNode } from '../utils-ts/fetchRequest';
//import CustomLoader from '../components/utils/CustomElements/CustomLoader';
import GlobalContext from "./Global-Context";
import { toast } from 'react-toastify';
const parseString = require('xml2js').parseString;
const { Storage } = Plugins;

export const AuthContextProvider: React.FC = (props) => {
  // the reactive values
  const [authValues, setAuthValues] = React.useState<userData>();
  //const [loading, setLoading] = useState<boolean>(false);
  const globalCtx = useContext(GlobalContext);

  const setLoading = (loading:boolean)=>{
   
   globalCtx.setLoader(loading);
 
  };
  const initUserContext = useCallback(async () => {
    const userData = await Storage.get({ key: 'userData' });
    const storedUserData = userData.value ? JSON.parse(userData.value) : undefined;
    if (storedUserData) {
      setAuthValues(() => ({
        Token: storedUserData.Token,
        IsSelfRegister: storedUserData.IsSelfRegister,
        ClientId: storedUserData.ClientId,
        Email: storedUserData.Email,
        MobileNumber: storedUserData.MobileNumber,
        FacebookUserCode: storedUserData.FacebookUserCode,
        FullName: storedUserData.FullName,
        GooglePlusUserCode: storedUserData.GooglePlusUserCode,
        Id: storedUserData.Id,
        LangCode: storedUserData.LangCode,
        ProfileImage: storedUserData.ProfileImage,
        UserCode: storedUserData.UserCode,
        EZToken: storedUserData.EZToken,
        IsCm: !!storedUserData.IsCm,
      }));
      return <Redirect to="/Home" />;
    }
  }, []);

  const login = (MobileNumber: string, SmsCode: string, firebaseToken: any) => {
    return new Promise((resolve) => {
      setLoading(true);
      var data = new URLSearchParams({
        MobileNumber: MobileNumber,
        SmsCode: SmsCode,
        FbsId: firebaseToken,
        Platform: Capacitor.getPlatform(),
      }).toString();
      fetchRequest(`${config.url.API_URL}/Client.asmx/LogInBySMS`, 'post', data)
        .then((response) => {
          parseString(response, (err: any, result: any) => {
            if (result) {
              var res = JSON.parse(result.string['_']);
              if (res.Success) {
                if (res.Response) {
                  const responseData = JSON.parse(res.Response);
                  const ImagProfile = responseData.ProfileImage != '' ? `${config.url.Member_Url}` + responseData.ProfileImage : '';
                  getNodeToken(responseData.MemberId).then((resTemp: any) => {
                    if (resTemp.message === '') {
                      const userData = {
                        IsSelfRegister: responseData.IsSelfRegister,
                        Token: responseData.Token,
                        TokenNode: resTemp.Token,
                        ClientId: responseData.ClientId,
                        Email: responseData.Email,
                        MobileNumber: MobileNumber,
                        FacebookUserCode: responseData.FacebookUserCode,
                        FullName: responseData.FullName,
                        GooglePlusUserCode: responseData.GooglePlusUserCode,
                        Id: responseData.Id,
                        MemberId: responseData.MemberId,
                        LangCode: responseData.LangCode,
                        ProfileImage: ImagProfile,
                        UserCode: responseData.UserCode,
                        EZToken: responseData.EZToken,
                        IsCm: false,
                      };
                      Storage.set({
                        key: 'userData',
                        value: JSON.stringify(userData),
                      });
                      setAuthValues(userData);
                    } else {
                      resolve(resTemp.message);
                    }
                  });
                }
              } else {
                resolve(res.ErrorMessage);
              }
            }
            setLoading(false);
          });
        })
        .catch(function (error) {
          setLoading(false);
          resolve(error);
        });
    });
  };

  const loginUser = (userCode:string,pass:string, firebaseToken: any)=> {
    return new Promise((resolve) => {
      setLoading(true);
      var data = new URLSearchParams({
        UserName: userCode, 
        Password: pass,
        FbsId: firebaseToken,
        Platform: Capacitor.getPlatform(),
      }).toString();
      fetchRequest(`${config.url.API_URL}/Client.asmx/LogInUser`, 'post', data)
        .then((response) => {
          parseString(response, (err: any, result: any) => {
            if (result) {
              var res = JSON.parse(result.string['_']);
              if (res.Success) {
                if (res.Response) {
                  const responseData = JSON.parse(res.Response);
                  if(responseData.IsTempUser && responseData.TempUserToken){
                    //console.log('TEMP USER ' + responseData.TempUserToken);
                    resolve(responseData);
                    
                  }else {
                  const ImagProfile = responseData.ProfileImage != '' ? `${config.url.Member_Url}` + responseData.ProfileImage : '';
                  getNodeToken(responseData.MemberId).then((resTemp: any) => {
                    if (resTemp.message === '') {
                      const userData = {
                        IsSelfRegister: responseData.IsSelfRegister,
                        Token: responseData.Token,
                        TokenNode: resTemp.Token,
                        ClientId: responseData.ClientId,
                        Email: responseData.Email,
                        MobileNumber: responseData.MobileNumber,
                        FacebookUserCode: responseData.FacebookUserCode,
                        FullName: responseData.FullName,
                        GooglePlusUserCode: responseData.GooglePlusUserCode,
                        Id: responseData.Id,
                        MemberId: responseData.MemberId,
                        LangCode: responseData.LangCode,
                        ProfileImage: ImagProfile,
                        UserCode: responseData.UserCode,
                        EZToken: responseData.EZToken,
                        IsCm: false,
                      };
                      Storage.set({
                        key: 'userData',
                        value: JSON.stringify(userData),
                      });
                      setAuthValues(userData);
                    } else {
                      resolve(resTemp.message);
                    }
                  });
                }
                }
              } else {
                resolve(res.ErrorMessage);
              }
            }
            setLoading(false);
          });
        })
        .catch(function (error) {
          setLoading(false);
          resolve(error);
        });
    });
  };
  const loginUserByToken = (strToken: string) => {
    return new Promise((resolve) => {
      setLoading(true);
      var data = new URLSearchParams({
        loginKey: strToken,
      }).toString();
      fetchRequest(`${config.url.API_URL}/Client.asmx/LoginUserByToken`, 'post', data)
        .then((response) => {
          parseString(response, (err: any, result: any) => {
            if (result) {
              var res = JSON.parse(result.string['_']);
              if (res.Success) {
                if (res.Response) {
                  const responseData = JSON.parse(res.Response);
                  const ImagProfile = responseData.ProfileImage != '' ? `${config.url.Member_Url}` + responseData.ProfileImage : '';
                  getNodeToken(responseData.MemberId).then((resTemp: any) => {
                    if (resTemp.message === '') {
                      const userData = {
                        IsSelfRegister: responseData.IsSelfRegister,
                        Token: responseData.Token,
                        TokenNode: resTemp.Token,
                        ClientId: responseData.ClientId,
                        Email: responseData.Email,
                        MobileNumber: responseData.MobileNumber,
                        FacebookUserCode: responseData.FacebookUserCode,
                        FullName: responseData.FullName,
                        GooglePlusUserCode: responseData.GooglePlusUserCode,
                        Id: responseData.Id,
                        MemberId: responseData.MemberId,
                        LangCode: responseData.LangCode,
                        ProfileImage: ImagProfile,
                        UserCode: responseData.UserCode,
                        EZToken: responseData.EZToken,
                        IsCm: false,
                      };
                      Storage.set({
                        key: 'userData',
                        value: JSON.stringify(userData),
                      });
                      setAuthValues(userData);

                      resolve('OK');
                    } else {
                      resolve(resTemp.message);
                    }
                  });
                }
              } else {
                resolve(res.ErrorMessage);
              }
            }
            setLoading(false);
          });
        })
        .catch(function (error) {
          setLoading(false);
          resolve(error);
        });
    });
  };
  const loginCmByToken = (strToken: string, cm_username: string, tp: any) => {
    return new Promise((resolve) => {
      setLoading(true);
      var data = new URLSearchParams({
        strToken: strToken,
        cm_username: cm_username,
        tp: tp,
      }).toString();
      fetchRequest(`${config.url.API_URL}/Client.asmx/LoginCmByToken`, 'post', data)
        .then((response) => {
          parseString(response, (err: any, result: any) => {
            if (result) {
              var res = JSON.parse(result.string['_']);
              if (res.Success) {
                if (res.Response) {
                  const responseData = JSON.parse(res.Response);
                  const ImagProfile = responseData.ProfileImage != '' ? `${config.url.Member_Url}` + responseData.ProfileImage : '';
                  getNodeToken(responseData.MemberId).then((resTemp: any) => {
                    if (resTemp.message === '') {
                      const userData = {
                        IsSelfRegister: responseData.IsSelfRegister,
                        Token: responseData.Token,
                        TokenNode: resTemp.Token,
                        ClientId: responseData.ClientId,
                        Email: responseData.Email,
                        MobileNumber: responseData.MobileNumber,
                        FacebookUserCode: responseData.FacebookUserCode,
                        FullName: responseData.FullName,
                        GooglePlusUserCode: responseData.GooglePlusUserCode,
                        Id: responseData.Id,
                        MemberId: responseData.MemberId,
                        LangCode: responseData.LangCode,
                        ProfileImage: ImagProfile,
                        UserCode: responseData.UserCode,
                        EZToken: responseData.EZToken,
                        IsCm: true,
                      };
                      Storage.set({
                        key: 'userData',
                        value: JSON.stringify(userData),
                      });
                      setAuthValues(userData);
                      resolve('OK');
                    } else {
                      resolve(resTemp.message);
                    }
                  });
                }
              } else {
                resolve(res.ErrorMessage);
              }
            }
            setLoading(false);
          });
        })
        .catch(function (error) {
          setLoading(false);
          resolve(error);
        });
    });
  };
  const loginMultiCardMember = (memberId: string) => {
    
    return new Promise((resolve) => {
      setLoading(true);
     
     Storage.get({ key: 'userData' }).then((userData)=> {
      const storedUserData = userData.value ? JSON.parse(userData.value) : undefined;
      const MobileNumber = storedUserData ? storedUserData.MobileNumber : '';

      var data = new URLSearchParams({
        memberId: memberId.toString()
      }).toString();
      fetchRequest(`${config.url.API_URL}/Client.asmx/LoginMultiCardMember`, 'post', data)
        .then((response) => {
          parseString(response, (err: any, result: any) => {
            if (result) {
              var res = JSON.parse(result.string['_']);
              if (res.Success) {
                if (res.Response) {
                  const responseData = JSON.parse(res.Response);
                  const ImagProfile = responseData.ProfileImage != '' ? `${config.url.Member_Url}` + responseData.ProfileImage : '';
                  getNodeToken(responseData.MemberId).then((resTemp: any) => {
                    if (resTemp.message === '') {
                      const userData = {
                        IsSelfRegister: responseData.IsSelfRegister,
                        Token: responseData.Token,
                        TokenNode: resTemp.Token,
                        ClientId: responseData.ClientId,
                        Email: responseData.Email,
                        MobileNumber: MobileNumber,
                        FacebookUserCode: responseData.FacebookUserCode,
                        FullName: responseData.FullName,
                        GooglePlusUserCode: responseData.GooglePlusUserCode,
                        Id: responseData.Id,
                        MemberId: responseData.MemberId,
                        LangCode: responseData.LangCode,
                        ProfileImage: ImagProfile,
                        UserCode: responseData.UserCode,
                        EZToken: responseData.EZToken,
                        IsCm: false,
                      };
                      Storage.set({
                        key: 'userData',
                        value: JSON.stringify(userData),
                      });
                      setAuthValues(userData);
                      resolve(true);
                     
                    } else {
                      toast.error(resTemp.message);
                      resolve(false);
                    }
                  });
                }
              } else {
                toast.error(res.ErrorMessage);
                resolve(false);
                
              }
            }
            setLoading(false);
          });
        })
        .catch(function (error) {
          setLoading(false);
          toast.error('ארעה שגיאה');
          resolve(false);
          
        });
      });
      
  
      
    });
  };
  const getNodeToken = (memberID: string) => {
    return new Promise(async (resolve) => {
      var data = new URLSearchParams({
        memberID: memberID,
      }).toString();
      fetchRequestNode(`${config.url.Node_API_URL}/user/login`, 'post', data)
        .then((response: any) => {
          var res = JSON.parse(response);
          resolve(res);
        })
        .catch(function (error) {
          let error_message = error.message;
          if(error_message == 'Failed to fetch'){
            error_message = 'השירות לא זמין, אנא פנו למוקד השירות בטלפון 1-700-707-144';
          }
          resolve({
            message: error_message,
          });
        });
    });
  };

  const loginTest = () => {
    return new Promise((resolve) => {
      const userData = {
        ClientId: '',
        IsSelfRegister: false,
        EZToken:
          'NYIpkK8iid5rLDc3aMjfkX3diBnsj+0+C+q3Y/9nMPli9oOcpuiByxFNPTgDcxT5bbVFZDjhl8nWfDWLwdX9Zn2ZfzaMQrLPnmOBkAUhRz+CVqqzz158kqQSR/vhcCiwefYckDW93WdHzbJH5aSHUOoaSPTF5cGyhWf1PdotCUwLzV+LQmsS2MxdZZ+6oDOhbK1zmrBKit/xV2MKUImvLR9D02qgOZMqM8046HJ+O8eiQoiLvykqvZslZNxHlVIkKuZJPpzDd2xZxeJu5UO6stHU9/95IM3TMCVaskIuEzdONZM+TTpZs4FggV7JOz4jKd3w5p588sSb+eWnjbFk2x8A4uc6vf7lahXY+u4PwkQkMrkVT8T1K3wwRmDC4jTUYGIMBBH8xlzJxp8faiZLjc/r40SAro3GIeNNfeIZr+SvaK8/gqGxy7NorKZr8aRv/4Hsbd8YtrU6vsVE6Rc2G7TsHSO+rOExkXbO1fpPGrztMOiOvwnBK6vkQuZsK4G3GRHYhi+loIjpl8mM8IHUgH/LwDrHBAnF7UQieKGbX06+hYVcyjdqtFOJYIaYUaxb/vsIzD/tAHEAVMLlgN4ngEcbWIYPjiXbb9HPZxJDqhlrWr65bw/R5IJh0vplJuZb',
        Email: 'yaroni@b-soft.co.il',
        FacebookUserCode: '1268708869837672',
        FullName: 'יובל פלג רצאבי ',
        GooglePlusUserCode: '',
        Id: '53408',
        LangCode: 'HE',
        MobileNumber: '0547617945',
        ProfileImage: `${config.url.Member_Url}` + 'http://localhost:55309/Services/Image.ashx?File=41120316471C675D4B146812461C645B251207144073625F4E13061541746759491D070D1E3032',
        Token:
          'W8Q5QxdQnH54Z6s4MnO3vPuWtqlExV5OgEgG8qM719jDkr3gTyspOE4kbc9RWEak8e0Q1nLKzVQGTWo5n@MZtGokMKR5m70uNLpbjtJ4MS4rWBFLtSrbNg3Ob1JZHh2NvbFayen0R3Fx9mGXDdyIT0j2d2EvZE7OLwAMtWTioGlypB4LEiITmzdx5KNPjWv7lrYUYKwVRV$BbSNPCzgohYQ0cCmaEYah3TVUs7HD$q4XunDcwqTWpghg1nnif8lt3dqebq5Vou0tod2@SAENzz2plIzZUyHiuYYVoiGyHhEfw@fV7kDgqm6T0@KR0xmlHASo8Pl9DisdfynW82RlL1O1CrkKztntY@ELM3xjPi7o1JFGUsiTkUeO$QvMaO0fFpser@Ekb1n7rlOBrbgL8FFkNuCMVqI$Xjmb9QR6JjgirFHY6EHUpbiK3xMw$yVJlNMkey51MtcmtiNyPPgAMf8tS8CochJXIpzgds7VJQxf3wQehnXGRz8YvfEr1ZDY',
        UserCode: '036910910',
        IsCm: false,
      };
      Storage.set({
        key: 'userData',
        value: JSON.stringify(userData),
      });
      setAuthValues(userData);
    });
  };

  const logout = useCallback(async (calbackFunc: () => void) => {
    new Promise((resolve) => {
      try {
        Storage.clear();
        setAuthValues({
          IsSelfRegister: null,
          Token: null,
          ClientId: null,
          Email: null,
          MobileNumber: null,
          FacebookUserCode: null,
          FullName: null,
          GooglePlusUserCode: null,
          Id: null,
          LangCode: null,
          ProfileImage: null,
          UserCode: null,
          EZToken: null,
          IsCm: null,
        });
        resolve('Success');
      } catch (e) {
        resolve('failed');
      }
    }).then((value) => {
      if (value === 'Success') {
        calbackFunc();
        window.location.reload(false);
      } else {
        toast.error('ארעה שגיאה');
      }
    });
  }, []);

  const verificationCode = (MobileNumber: string, captcha: string) => {
    setLoading(true);
    return new Promise((resolve) => {
      var data = new URLSearchParams({
        MobileNumber: MobileNumber, //0547617945
        captcha: captcha,
      }).toString();

      fetchRequest(`${config.url.API_URL}/Client.asmx/SendLoginCodeBySms`, 'post', data)
        .then((response) => {
          parseString(response, (err: any, result: any) => {
            if (result) {
              var res = JSON.parse(result.string['_']);
              //console.log(res);
              if (res.Success) {
                resolve('');
              } else if (res.Response == "Error"){
                resolve(res.ErrorMessage);
                setLoading(false);
                return;
              }
            }
            resolve('מספר טלפון לא קיים במערכת');
            setLoading(false);
          });
        })
        .catch((error) => {
          resolve(error);
          setLoading(false);
        });
    });
  };
const changeTempPassword = (password:string, tempUserToken:string) => {
  return new Promise((resolve) => {
    setLoading(true);
    var data = new URLSearchParams({
      tempUserToken: tempUserToken, 
      password: password,
    }).toString();
    fetchRequest(`${config.url.API_URL}/Client.asmx/ChangeTempPassword`, 'post', data)
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string['_']);
            if (res.Success) {
              resolve('OK');
            } else {
              resolve(res.ErrorMessage);
            }
          }
          setLoading(false);
        });
      })
      .catch(function (error) {
        setLoading(false);
        resolve(error);
      });
  });
}

  const setLoader = (loading: boolean) => {
    setLoading(loading);
  };

  // the store object
  let state = {
    authValues,
    verificationCode,
    login,
    loginUser,
    loginCmByToken,
    loginUserByToken,
    loginMultiCardMember,
    loginTest,
    logout,
    changeTempPassword,
    initUserContext,
    setLoader,
  };

  // wrap the application in the provider with the initialized context
  return (
    <UserContext.Provider value={state}>
      {props.children}{' '}
      {/* <CustomLoader
        isOpen={loading}
        onDidDismiss={() => {
          setLoading(false);
        }}></CustomLoader> */}
    </UserContext.Provider>
  );
};

export default UserContext;
