import {
  AdvCategories,
  BusinessLink,
  QuestionsAndAnswers,
  OpenDays,
  PublicationDays,
  BusinessMovie,
  NormalizedOpenDay,
} from "../data/MemberDetailes-context";
import { getOpenDays } from "./BusinessOpenDaysFunc";
import { getTimeFotmat } from "./GlobalDateFunctions";
import {
  document,
  phone,
  adress,
  edit,
  heart,
  serviceAreas,
  website,
  landing_page,
  languages,
  main_category,
  mail,
  activity_time,
  promotional_video,
  ecommerce_site,
  youtube_channel,
  links,
  faq,
} from "../images/index";
import { facebook, instegram, tiktok, wolt } from "../images/index";
import { config } from "../Constants";

export const BusinessLanguagesHebrew: {
  [key: string]: string;
} = {
  Amharic: "אמהרית",
  Arabic: "ערבית",
  Chinese: "סינית",
  English: "אנגלית",
  France: "צרפתית",
  German: "גרמנית",
  Hebrew: "עברית",
  Italian: "איטלקית",
  Portuguese: "פורטוגזית",
  Romanian: "רומנית",
  Russian: "רוסית",
  Spanish: "ספרדית",
  Bulgarian: "בולגרית",
  Hungarian: "הונגרית",
  Turkish: "טורקית",
  Polish: "פולנית",
  Ukrainian: "אוקראינית",
  Japanese: "יפנית",
  Yiddish: "יידיש",
};
export type businessCardModulesName =
  | ""
  | "BusinessEvents"
  | "SocialNetWork"
  | "Description"
  | "OpenDays"
  | "PublicationDays"
  | "HandleOpenDays"
  | "BusinessLanguages"
  | "AdvCategories"
 // | "BusinessMovie"
  | "BusinessMovies"
  | "BusinessLinks"
  | "BusinessExpert"
  | "BusinessFax"
  | "Email"
  | "LandingPage"
  | "Mobile"
  | "Phone1"
  | "WebSite"
  | "EcommerceSite"
  | "BusinessAddress"
  | "BusinessMoreProperties"
  | "CompanyName"
  | "BusinessMainPhone"
  | "VirtualNumber"
  | "YoutubeChannel"
  | "BusinessQuestionsAndAnswers"
  | "ServiceAreas"
  | "SeoDetails" 
  | "SeoBrands";

export interface MemberDetaileProps {
  title?: string;
  btnTitle: string;
  id: businessCardModulesName;
  isEditable: boolean;
  icon: string;
}

export const MemberDetailesArrayToShow: MemberDetaileProps[] = [
  {
    title: "שם העסק",
    btnTitle: "שם העסק",
    id: "CompanyName",
    isEditable: false,
    icon: document,
  },
  {
    title: "טלפון",
    btnTitle: "טלפון",
    id: "BusinessMainPhone",
    isEditable: false,
    icon: phone,
  },
  {
    title: "טלפון לפרסום",
    btnTitle: "טלפון לפרסום",
    id: "VirtualNumber",
    isEditable: false,
    icon: phone,
  },
  {
    title: "כתובת",
    btnTitle: "כתובת",
    id: "BusinessAddress",
    isEditable: false,
    icon: adress,
  },
  {
    title: "טלפון נוסף 1",
    btnTitle: "טלפון נוסף 1",
    id: "Mobile",
    isEditable: true,
    icon: phone,
  },
  {
    title: "טלפון נוסף 2",
    btnTitle: "טלפון נוסף 2",
    id: "Phone1",
    isEditable: true,
    icon: phone,
  },
  {
    title: "תיאור העסק",
    btnTitle: "תיאור העסק",
    id: "Description",
    isEditable: true,
    icon: edit,
  },
  {
    title: "מידע נוסף",
    btnTitle: "מידע נוסף",
    id: "SeoDetails",
    isEditable: true,
    icon: edit,
  },
  {
    title: "מותגים",
    btnTitle: "מותגים",
    id: "SeoBrands",
    isEditable: true,
    icon: main_category,
  },
  {
    title: "אזורי שירות",
    btnTitle: "אזורי שירות",
    id: "ServiceAreas",
    isEditable: true,
    icon: serviceAreas,
  },
  {
    title: "",
    btnTitle: "קטגוריות נוספות",
    id: "AdvCategories",
    isEditable: true,
    icon: main_category,
  },
  {
    title: "מייל",
    btnTitle: "מייל",
    id: "Email",
    isEditable: true,
    icon: mail,
  },
  {
    title: "פקס",
    btnTitle: "פקס",
    id: "BusinessFax",
    isEditable: true,
    icon: phone,
  },
  {
    title: "שעות פעילות",
    btnTitle: "שעות פעילות",
    id: "OpenDays",
    isEditable: true,
    icon: activity_time,
  },
  {
    title: "השעות שבהן העסק יפורסם באתר 144B",
    btnTitle: "שעות פרסום",
    id: "PublicationDays",
    isEditable: true,
    icon: activity_time,
  },
  {
    title: "רשתות חברתיות",
    btnTitle: "רשתות חברתיות",
    id: "SocialNetWork",
    isEditable: true,
    icon: heart,
  },
  {
    title: "שפות",
    btnTitle: "שפות",
    id: "BusinessLanguages",
    isEditable: true,
    icon: languages,
  },
  {
    title: "דף נחיתה",
    btnTitle: "דף נחיתה",
    id: "LandingPage",
    isEditable: true,
    icon: landing_page,
  },
  {
    title: "אתר",
    btnTitle: "לינק לאתר",
    id: "WebSite",
    isEditable: true,
    icon: website,
  },
  {
    title: "סרטונים",
    btnTitle: "סרטונים",
    id: "BusinessMovies",
    isEditable: true,
    icon: promotional_video,
  },
  {
    title: "אתר מסחר",
    btnTitle: "אתר מסחר",
    id: "EcommerceSite",
    isEditable: true,
    icon: ecommerce_site,
  },
  {
    title: "ערוץ יוטיוב",
    btnTitle: "ערוץ יוטיוב",
    id: "YoutubeChannel",
    isEditable: true,
    icon: youtube_channel,
  },
  // {  //   title: 'תחומי התמחות', btnTitle: 'תחומי התמחות', id: 'BusinessExpert', isEditable: true, icon: document },
  {
    title: "לינקים",
    btnTitle: "לינקים",
    id: "BusinessLinks",
    isEditable: true,
    icon: links,
  },
  {
    title: "שאלות ותשובות",
    btnTitle: "שאלות ותשובות",
    id: "BusinessQuestionsAndAnswers",
    isEditable: true,
    icon: faq,
  },
];

export const getHtmlData = (
  MemberDetailsCxt: any,
  UserSettingsCtx: any,
  moduleName: businessCardModulesName
) => {
  function SetDtlPushEvtOutBoundInner(link: string) {
    SetDtlPushEvtOutBound("outbound_links", link);
  }

  if (moduleName === "OpenDays") {
    let HtmlResult = "";
    const open_days_array = MemberDetailsCxt.OpenDays?.filter((f: OpenDays) => {
      return f.IsDeleted === false;
    });
    if (open_days_array && open_days_array.length > 0) {
      const open_hours_template = MemberDetailsCxt.getMemberOpenHoursTemplate();
      //console.log('OpenHoursTemplate ' + open_hours_template);
      if(open_hours_template == 8){
        HtmlResult = "<div>העסק פתוח בימים א-ה, 24 שעות ביממה</div>";
      }else if(open_hours_template == 5){
        HtmlResult = "<div>העסק פתוח בימים א-ש, 24 שעות ביממה</div>";
      }else if(open_hours_template == 7){
        HtmlResult = "<div>בתיאום טלפוני</div>";
      }else{
        HtmlResult =
        "<table class='open-hours-card-table'>\
      <tr>\
        <td></td>\
        <td class='small-title'>פתיחה</td>\
        <td class='small-title'>סגירה</td>\
      </tr>";
      const openDaysList: NormalizedOpenDay[] = open_days_array.map(
        (openDay: OpenDays) => {
          const openDays = getOpenDays(openDay);
          const openHour = getTimeFotmat(
            openDay.FromHour!,
            openDay.FromHourMinutes!
          );
          const closeHour = getTimeFotmat(
            openDay.ToHour!,
            openDay.ToHourMinutes!
          );
          const isNextDay = closeHour < openHour;
          return { openDays, openHour, closeHour,isNextDay };
        }
      );

      openDaysList.sort((day1, day2) => {
        if (day1.openDays < day2.openDays) return -1;
        if (day1.openDays > day2.openDays) return 1;
        else return 0;
      });

      openDaysList.forEach(
        ({ openDays, openHour, closeHour,isNextDay }) =>
          (HtmlResult +=
            "<tr>\
            <td>" +
            openDays.toString() +
            "</td>\
            <td>" +
            openHour +
            "</td>\
            <td>" +
            closeHour + (isNextDay ? ' (למחרת)' : '') +
            "</td>\
        </tr>")
      );

      HtmlResult = HtmlResult + "</table>";
      }
     
      
    }
    // return HtmlResult;
    //   open_days_array.map((openDay: OpenDays) => {
    //     const openDays = getOpenDays(openDay);
    //     const openHour = getTimeFotmat(
    //       openDay.FromHour!,
    //       openDay.FromHourMinutes!
    //     );
    //     const closeHour = getTimeFotmat(
    //       openDay.ToHour!,
    //       openDay.ToHourMinutes!
    //     );
    //     HtmlResult =
    //       HtmlResult +
    //       "<tr>\
    //       <td>" +
    //       openDays.toString() +
    //       "</td>\
    //       <td>" +
    //       openHour +
    //       "</td>\
    //       <td>" +
    //       closeHour +
    //       "</td>\
    //   </tr>";
    //   });
    //   HtmlResult = HtmlResult + "</table>";
    // }

    return HtmlResult;
  } else if (moduleName === "PublicationDays") {
    let HtmlResult = "";
    //console.log(moduleName);
    const publication_days_array = MemberDetailsCxt.PublicationDays;
    if (publication_days_array && publication_days_array.length > 0) {
      HtmlResult =
        "<table class='publication-days-card-table'>\
      <tr>\
        <td></td>\
        <td class='small-title'>פתיחה</td>\
        <td class='small-title'>סגירה</td>\
      </tr>";
      publication_days_array.map((publicationDay: PublicationDays) => {
        const publicationDays = getOpenDays(publicationDay);
        const openHour = getTimeFotmat(
          publicationDay.FromHour!,
          publicationDay.FromHourMinutes!
        );
        const closeHour = getTimeFotmat(
          publicationDay.ToHour!,
          publicationDay.ToHourMinutes!
        );
        HtmlResult =
          HtmlResult +
          "<tr>\
          <td>" +
          publicationDays.toString() +
          "</td>\
          <td>" +
          openHour +
          "</td>\
          <td>" +
          closeHour +
          "</td>\
      </tr>";
      });
      HtmlResult = HtmlResult + "</table>";
    }
    return HtmlResult;
  } else if (moduleName === "SocialNetWork") {
    let social_links = "";
    if (!!MemberDetailsCxt.GeneralInfo.Facebook) {
      social_links += `<a href='${MemberDetailsCxt.GeneralInfo.Facebook}' target="_blank" onclick="SetDtlPushEvtOutBound('outbound_links','${MemberDetailsCxt.GeneralInfo.Facebook}')"    }><img src=${facebook} class='ion-margin-left'></a>`;
    }
    if (!!MemberDetailsCxt.GeneralInfo.Instagram) {
      social_links += `<a href='${MemberDetailsCxt.GeneralInfo.Instagram}' target="_blank"  onclick="SetDtlPushEvtOutBound('outbound_links','${MemberDetailsCxt.GeneralInfo.Instagram}')" ><img src=${instegram} class='ion-margin-left'></a>`;
    }
    if (!!MemberDetailsCxt.GeneralInfo.TikTok) {
      social_links += `<a href='${MemberDetailsCxt.GeneralInfo.TikTok}' target="_blank"  onclick="SetDtlPushEvtOutBound('outbound_links','${MemberDetailsCxt.GeneralInfo.TikTok}')" ><img src=${tiktok} class='ion-margin-left'></a>`;
    }
    if (!!MemberDetailsCxt.GeneralInfo.Wolt) {
      social_links += `<a href='${MemberDetailsCxt.GeneralInfo.Wolt}' target="_blank"  onclick="SetDtlPushEvtOutBound('outbound_links','${MemberDetailsCxt.GeneralInfo.Wolt}')" ><img src=${wolt} class='ion-margin-left'></a>`;
    }
    return social_links;
  } else if (moduleName === "BusinessAddress") {
    return MemberDetailsCxt.BusinessAddress != ""
      ? MemberDetailsCxt.BusinessAddress?.Street +
          " " +
          MemberDetailsCxt.BusinessAddress?.StreetNo +
          " " +
          MemberDetailsCxt.BusinessAddress?.City
      : "";
  } else if (
    moduleName === "BusinessLanguages" &&
    MemberDetailsCxt.BusinessLanguages
  ) {
    let activeBusinessLanguages: string[] = [];
    Object.keys(MemberDetailsCxt.BusinessLanguages)?.map(function (key) {
      if (MemberDetailsCxt.BusinessLanguages[key] === true) {
        const lang = BusinessLanguagesHebrew[key];
        activeBusinessLanguages.push(lang);
      }
    });

    return activeBusinessLanguages.join(", ");
  } 
  // else if (moduleName === "BusinessMovie") {
  //   if (MemberDetailsCxt.BusinessMovie) {
  //     return (
  //       "<a class='text-wrap' target='_blank' href='" +
  //       MemberDetailsCxt.BusinessMovie +
  //       "'>" +
  //       MemberDetailsCxt.BusinessMovie +
  //       "</a>"
  //     );
  //   } else {
  //     return "";
  //   }
  // } 
  else if (moduleName === "BusinessMovies") {
    if (
      MemberDetailsCxt.BusinessMovies == null ||
      MemberDetailsCxt.BusinessMovies.length == 0
    ) {
      return "טרם נוספו סרטונים";
    }
    let HtmlResult =
      "<div class='Table'>\
    <div class='Table-row Table-header small-title'>\
    <div class='Table-row-item u-Flex-grow3'>תיאור</div>\
    <div class='Table-row-item u-Flex-grow3'>לינק</div>\
    </div>";
    MemberDetailsCxt.BusinessMovies.map((movie: BusinessMovie) => {
      HtmlResult =
        HtmlResult +
        "<div class='Table-row'>\
        <div class='Table-row-item u-Flex-grow3' data-header='תיאור'>" +
        movie.MovieDesc +
        "</div>\
        <div class='Table-row-item u-Flex-grow3 ltr' data-header='לינק'><a target='_blank' href='" +
        movie.MovieUrl +
        "' " +
        'onClick=\'SetDtlPushEvtOutBound("outbound_links","' +
        movie.MovieUrl +
        "\")' >" +
        movie.MovieUrl +
        "</a></div>\
     </div>";
    });
    HtmlResult = HtmlResult + "</div>";
    return HtmlResult;
  }
  // else if (moduleName==="BusinessLinks"){
  //   let HtmlResult = "<table class='open-hours-card-table'>\
  //   <tr>\
  //     <td class='small-title'>תיאור</td>\
  //     <td class='small-title'>לינק</td>\
  //   </tr>"
  //   MemberDetailsCxt.BusinessLinks.map((link:BusinessLink)=>{
  //    HtmlResult=HtmlResult+"<tr>\
  //       <td>"+link.Description+"</td>\
  //       <td style='max-width: 133px' class='text-wrap'><a target='_blank' href='"+link.Address+"'>"+link.Address+"</a></td>\
  //    </tr>";
  //   })
  //   HtmlResult = HtmlResult +"</table>";
  //   return HtmlResult;
  // }
  else if (moduleName === "BusinessLinks") {
    let HtmlResult =
      "<div class='Table'>\
    <div class='Table-row Table-header small-title'>\
    <div class='Table-row-item u-Flex-grow3'>תיאור</div>\
    <div class='Table-row-item u-Flex-grow3 ltr'>לינק</div>\
    </div>";
    MemberDetailsCxt.BusinessLinks.map((link: BusinessLink) => {
      HtmlResult =
        HtmlResult +
        "<div class='Table-row'>\
        <div class='Table-row-item u-Flex-grow3' data-header='תיאור'>" +
        link.Description +
        "</div>\
        <div class='Table-row-item u-Flex-grow3 ltr' data-header='לינק'><a target='_blank' href='" +
        link.Address +
        "' " +
        'onClick=\'SetDtlPushEvtOutBound("outbound_links","' +
        link.Address +
        "\")' >" +
        link.Address +
        "</a></div>\
     </div>";
    });
    HtmlResult = HtmlResult + "</div>";
    return HtmlResult;
  } else if (moduleName === "BusinessQuestionsAndAnswers") {
    let HtmlResult =
      "<div class='Table'>\
    <div class='Table-row Table-header small-title'>\
    <div class='Table-row-item u-Flex-grow3'>שאלה</div>\
    <div class='Table-row-item u-Flex-grow3 ltr'>תשובה</div>\
    </div>";
    MemberDetailsCxt.BusinessQuestionsAndAnswers.map(
      (questionAnswer: QuestionsAndAnswers) => {
        HtmlResult =
          HtmlResult +
          "<div class='Table-row'>\
        <div class='Table-row-item u-Flex-grow3' data-header='שאלה'>" +
          questionAnswer.Question +
          "</div>\
        <div class='Table-row-item u-Flex-grow3 ltr' data-header='תשובה'>" +
          questionAnswer.Answer +
          "</div>\
     </div>";
      }
    );
    HtmlResult = HtmlResult + "</div>";
    return HtmlResult;
  } else if (moduleName === "ServiceAreas") {
    let service_area_html = "";
    if (MemberDetailsCxt.BusinessServiceArea) {
      if (MemberDetailsCxt.BusinessServiceArea.ServiceAreaType == "country") {
        service_area_html = "ארצי";
      } else {
        service_area_html = MemberDetailsCxt.BusinessServiceArea.ServiceAreasList.map(
          (s: any) => {
            return s.City;
          }
        ).join(", ");
      }
    }
    if (
      MemberDetailsCxt.BusinessServiceArea !== undefined &&
      MemberDetailsCxt.BusinessServiceArea.HasDelivery
    ) {
      service_area_html +=
        "</br><b>משלוחים:</b> " +
        (MemberDetailsCxt.BusinessServiceArea.DoDelivery ? "כן" : "לא");
    }
    return service_area_html;
  }else if (moduleName === "SeoDetails") {
    let seo_details_html = "";
    
    if (MemberDetailsCxt.BusinessSeoDetails) {
      const about_items = MemberDetailsCxt.BusinessSeoDetails.filter((s:any)=> s.Type == 2);
      if(about_items && about_items.length){
      seo_details_html += "<div><b>אודות העסק:</b> " +  about_items.map(
          (s: any) => {
            return s.Text;
          }
        ).join(", ") + "</div>";
        }
      const market_items = MemberDetailsCxt.BusinessSeoDetails.filter((s:any)=> s.Type == 1);
      if(market_items && market_items.length){
        
        seo_details_html += "<div><b>תוכן שיווקי:</b> " +  market_items.map(
          (s: any) => {
            return s.Text;
          }
        ).join(", ") + "</div>";
      }
      const discount_bolus_items = MemberDetailsCxt.BusinessSeoDetails.filter((s:any)=> s.Type == 3);
      if(discount_bolus_items && discount_bolus_items.length){
        seo_details_html += "<div><b>הנחות והטבות:</b>";
        for(let i = 0; i < discount_bolus_items.length; i++){
          seo_details_html += "<div>" +discount_bolus_items[i].Text+ "</div>";
        }
        seo_details_html += "</div>";
      }
    }
    
    return seo_details_html;
  } else if (moduleName === "Description") {
    return MemberDetailsCxt.Description;
  } else if (moduleName === "CompanyName") {
    return MemberDetailsCxt.GeneralInfo.CompanyName;
  } else if (moduleName === "BusinessMainPhone") {
    return UserSettingsCtx.userProfileSettingsInfo.MobilePhone;
  }else if (moduleName === "VirtualNumber") {
    console.log('GeneralInfo: ' + UserSettingsCtx.GeneralInfo)
    return MemberDetailsCxt.GeneralInfo.VirtualNumber;
  } else if (moduleName === "BusinessExpert") {
    return MemberDetailsCxt.GeneralInfo.BusinessExpert;
  } else if (moduleName === "BusinessFax") {
    return MemberDetailsCxt.GeneralInfo.Fax;
  } else if (moduleName === "Email") {
    return MemberDetailsCxt.GeneralInfo.Email;
  } else if (moduleName === "LandingPage") {
    return (
      "<a class='text-wrap' target='_blank' href='" +
      MemberDetailsCxt.GeneralInfo.LandingPage +
      "'  onclick='SetDtlPushEvtOutBound(&quot;outbound_links&quot;,&quot;" +
      MemberDetailsCxt.GeneralInfo.LandingPage +
      "&quot;)' >" +
      MemberDetailsCxt.GeneralInfo.LandingPage +
      "</a>"
    );
  } else if (moduleName === "Mobile") {
    return MemberDetailsCxt.GeneralInfo.Mobile;
  } else if (moduleName === "Phone1") {
    return MemberDetailsCxt.GeneralInfo.Phone1;
  } else if (moduleName === "WebSite") {
    return (
      "<a  class='text-wrap' target='_blank' href='" +
      (MemberDetailsCxt.GeneralInfo.WebSite.toString()
        .toLowerCase()
        .indexOf("http") != 0 && "//") +
      MemberDetailsCxt.GeneralInfo.WebSite +
      "'  onclick='SetDtlPushEvtOutBound(&quot;outbound_links&quot;,&quot;" +
      MemberDetailsCxt.GeneralInfo.WebSite +
      "&quot;)' >" +
      MemberDetailsCxt.GeneralInfo.WebSite +
      "</a>"
    );
  } else if (moduleName === "EcommerceSite") {
    return (
      "<a class='text-wrap' target='_blank' href='" +
      MemberDetailsCxt.GeneralInfo.EcommerceSite +
      "'>" +
      MemberDetailsCxt.GeneralInfo.EcommerceSite +
      "</a>"
    );
  } else if (moduleName === "YoutubeChannel") {
    return (
      "<a class='text-wrap' target='_blank' href='" +
      MemberDetailsCxt.GeneralInfo.YoutubeChannel +
      "'>" +
      MemberDetailsCxt.GeneralInfo.YoutubeChannel +
      "</a>"
    );
  } else if (moduleName === "SeoBrands") {
    return MemberDetailsCxt.GeneralInfo.SeoBrands;
  }
};

export const showEmptyElementsBtn = (
  MemberDetailsCxt: any,
  moduleName: businessCardModulesName
) => {
  if (moduleName === "OpenDays" && MemberDetailsCxt.OpenDays) {
    const open_days_array = MemberDetailsCxt.OpenDays?.filter((f: OpenDays) => {
      return f.IsDeleted === false;
    });
    if (open_days_array && open_days_array.length > 0) {
      return false;
    } else {
      return true;
    }
  }
  // else if( moduleName==="BusinessAddress"){
  //   if( MemberDetailsCxt.BusinessAddress== "" || (MemberDetailsCxt.BusinessAddress?.Street ==="" && MemberDetailsCxt.BusinessAddress?.StreetNo==="" &&MemberDetailsCxt.BusinessAddress?.City==="")){
  //     return true;
  //   }
  // }
  // else if (
  //   moduleName === "PublicationDays" &&
  //   MemberDetailsCxt.PublicationDays
  // ) {
  //   const publication_days_array = MemberDetailsCxt.PublicationDays;
  //   if (publication_days_array && publication_days_array.length > 0) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }
  else if (
    moduleName === "BusinessLanguages" &&
    MemberDetailsCxt.BusinessLanguages != undefined &&
    MemberDetailsCxt.BusinessLanguages.Arabic === false &&
    MemberDetailsCxt.BusinessLanguages.Chinese === false &&
    MemberDetailsCxt.BusinessLanguages.English === false &&
    MemberDetailsCxt.BusinessLanguages.France === false &&
    MemberDetailsCxt.BusinessLanguages.German === false &&
    MemberDetailsCxt.BusinessLanguages.Hebrew === false &&
    MemberDetailsCxt.BusinessLanguages.Italian === false &&
    MemberDetailsCxt.BusinessLanguages.Portuguese === false &&
    MemberDetailsCxt.BusinessLanguages.Romanian === false &&
    MemberDetailsCxt.BusinessLanguages.Russian === false &&
    MemberDetailsCxt.BusinessLanguages.Spanish === false &&
    MemberDetailsCxt.BusinessLanguages.Bulgarian === false &&
    MemberDetailsCxt.BusinessLanguages.Hungarian === false &&
    MemberDetailsCxt.BusinessLanguages.Turkish === false &&
    MemberDetailsCxt.BusinessLanguages.Polish === false &&
    MemberDetailsCxt.BusinessLanguages.Ukrainian === false &&
    MemberDetailsCxt.BusinessLanguages.Japanese === false &&
    MemberDetailsCxt.BusinessLanguages.Yiddish === false &&
    MemberDetailsCxt.BusinessLanguages.Amharic === false
  ) {
    return true;
  } else if (
    moduleName === "AdvCategories" &&
    MemberDetailsCxt.AdvCategories != undefined &&
    MemberDetailsCxt.AdvCategories.length > 0
  ) {
    const mainCategoriesTemp = getAdvCategoryCategories(MemberDetailsCxt);
    if (mainCategoriesTemp.length > 0) {
      return true;
    } else {
      return false;
    }
  } 
  // else if (
  //   moduleName === "BusinessMovie" &&
  //   MemberDetailsCxt.BusinessMovie != undefined &&
  //   MemberDetailsCxt.BusinessMovie === ""
  // ) {
  //   return true;
  // } 
  else if (
    moduleName === "BusinessMovies" &&
    (!MemberDetailsCxt.BusinessMovies ||
      MemberDetailsCxt.BusinessMovies.length == 0)
  ) {
    return true;
  } else if (
    moduleName === "BusinessLinks" &&
    MemberDetailsCxt.BusinessLinks != undefined &&
    MemberDetailsCxt.BusinessLinks.length < 1
  ) {
    return true;
  } else if (
    moduleName === "SeoDetails" &&
    MemberDetailsCxt.BusinessSeoDetails != undefined &&
    MemberDetailsCxt.BusinessSeoDetails.length < 1
  ) {
    return true;
  }else if (
    moduleName === "BusinessQuestionsAndAnswers" &&
    MemberDetailsCxt.BusinessQuestionsAndAnswers != undefined &&
    MemberDetailsCxt.BusinessQuestionsAndAnswers.length < 1
  ) {
    return true;
  } else if (
    moduleName === "BusinessEvents" &&
    MemberDetailsCxt.BusinessEvents != undefined &&
    MemberDetailsCxt.BusinessEvents.length < 1
  ) {
    return true;
  } else if (
    moduleName === "Description" &&
    MemberDetailsCxt.Description != undefined &&
    MemberDetailsCxt.Description === ""
  ) {
    return true;
  } else if (
    moduleName === "CompanyName" &&
    MemberDetailsCxt.CompanyName != undefined &&
    MemberDetailsCxt.CompanyName === ""
  ) {
    return true;
  } else if (
    moduleName === "SocialNetWork" &&
    MemberDetailsCxt.GeneralInfo != undefined &&
    MemberDetailsCxt.GeneralInfo.Facebook === "" &&
    //MemberDetailsCxt.GeneralInfo.FacebookId === '' ||
    MemberDetailsCxt.GeneralInfo.Instagram === "" &&
    MemberDetailsCxt.GeneralInfo.TikTok === "" &&
    MemberDetailsCxt.GeneralInfo.Wolt === ""
  ) {
    return true;
  } else if (
    moduleName === "BusinessExpert" &&
    MemberDetailsCxt.GeneralInfo != undefined &&
    MemberDetailsCxt.GeneralInfo.BusinessExpert === ""
  ) {
    return true;
  } else if (
    moduleName === "BusinessFax" &&
    MemberDetailsCxt.GeneralInfo != undefined &&
    MemberDetailsCxt.GeneralInfo.Fax === ""
  ) {
    return true;
  } else if (
    moduleName === "Email" &&
    MemberDetailsCxt.GeneralInfo != undefined &&
    MemberDetailsCxt.GeneralInfo.Email === ""
  ) {
    return true;
  } else if (
    moduleName === "LandingPage" &&
    MemberDetailsCxt.GeneralInfo != undefined &&
    MemberDetailsCxt.GeneralInfo.LandingPage === ""
  ) {
    return true;
  } else if (
    moduleName === "Mobile" &&
    MemberDetailsCxt.GeneralInfo != undefined &&
    MemberDetailsCxt.GeneralInfo.Mobile === ""
  ) {
    return true;
  } else if (
    moduleName === "Phone1" &&
    MemberDetailsCxt.GeneralInfo != undefined &&
    MemberDetailsCxt.GeneralInfo.Phone1 === ""
  ) {
    return true;
  } else if (
    moduleName === "WebSite" &&
    MemberDetailsCxt.GeneralInfo != undefined &&
    MemberDetailsCxt.GeneralInfo.WebSite === ""
  ) {
    return true;
  } else if (
    moduleName === "EcommerceSite" &&
    MemberDetailsCxt.GeneralInfo != undefined &&
    MemberDetailsCxt.GeneralInfo.EcommerceSite === ""
  ) {
    return true;
  } else if (
    moduleName === "YoutubeChannel" &&
    MemberDetailsCxt.GeneralInfo != undefined &&
    MemberDetailsCxt.GeneralInfo.YoutubeChannel === ""
  ) {
    return true;
  }else if (
    moduleName === "SeoBrands" &&
    MemberDetailsCxt.GeneralInfo != undefined &&
    MemberDetailsCxt.GeneralInfo.SeoBrands === "" && MemberDetailsCxt.ShowSeoBrands
  ) {
    return true;
  }

  return false;
};

export const getAdvCategoryCategories = (MemberDetailsCxt: any) => {
  let mainCategoriesTemp: string[] = [];
  let mainCategoriesTempChecked: string[] = [];
  MemberDetailsCxt.AdvCategories!.filter((f: AdvCategories) => {
    return f.IsChecked === true;
  }).map((c: AdvCategories) => {
    if (mainCategoriesTempChecked.indexOf(c.Category) === -1) {
      mainCategoriesTempChecked.push(c.Category);
    }
  });

  MemberDetailsCxt.AdvCategories!.filter((f: AdvCategories) => {
    if (mainCategoriesTempChecked.includes(f.Category) === false) {
      return f;
    }
  }).map((cat: AdvCategories) => {
    if (mainCategoriesTemp.indexOf(cat.Category) === -1) {
      mainCategoriesTemp.push(cat.Category);
    }
  });
  return mainCategoriesTemp;
};
