import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonRow,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router";
import { toast } from "react-toastify";
import CustomGlobalLoader from "../../components/utils/CustomElements/CustomGlobalLoader";
import CustomPage from "../../components/utils/CustomElements/CustomPage";
import CustomHeaddingItem from "../../components/utils/CustomElements/CustomPageTitle/CustomHeaddingItem";
import CustomDateRangePicker, {
  defaultStaticRanges,
  defaultStaticRangesOpt,
} from "../../components/utils/Filters/CustomDateRangePicker/CustomDateRangePicker";
import {
  GetProductStats,
  OpenNewAdwordsPnia,
} from "../../data/ProductStatsService";
import { statisticsWhite } from "../../images";
import FacebookStats from "./FacebookStats";
import GoogleStats from "./GoogleStats";
import DropDownFilter, { filterOptions } from "../../components/utils/Filters/DropDownFilter";

type childrenType = React.ReactNode | React.ReactNode[];

const ProductStatistics = () => {
  const [tabNumber, setTabNumber] = useState(0);
  const [pageData, setPageData] = useState<any>(null);
  const [approvedDateSelection, setApprovedDateSelection] = useState(true);
  const _defaultStaticRanges: defaultStaticRangesOpt = "החודש";
  const defaultStaticSelectedRanges = defaultStaticRanges.find(
    (range) => range.label === _defaultStaticRanges
  );
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(() => {
    return defaultStaticSelectedRanges!.startDate;
  });
  const prevSelectedStartDate = useRef<Date>();
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(() => {
    return defaultStaticSelectedRanges!.endDate;
  });
  const prevSelectedEndDate = useRef<Date>();
  const [characterCount, setCharacterCount] = useState(0);

  const prevSelectedCapmaignType = useRef<string>();
const [currentFbCapmaignType, setCurrentFbCapmaignType] = useState();

  const [loading, setLoading] = useState(true);
  const productName: any = useParams<{
    productName: string;
  }>().productName.toLowerCase();
  const pniaTextAreaRef = useRef<HTMLTextAreaElement>(null);
  const history = useHistory();

  const tabs =
    productName === "google"
      ? [
          { he: "מודעות", en: "adwords" },
          {
            he: "מילות חיפוש",
            en: "keyAdwords",
          },
          {
            he: "כללי",
            en: "campaginAdwords",
          },
          {
            he: "פניה למנהל הקמפיין בגוגל",
            en: "commentsAdwords",
          },
          {
            he: "תצוגת פיקמס",
            en: "piMaxPreview",
          },
        ]
      : productName === "facebook"
      ? [
          {
            he: "קמפיינים לפייסבוק - ביצועים",
            en: "fbAdsPerformance",
          },
          { he: "פניה למנהל הקמפיין בפייסבוק", en: "commentsAdwords" },
        ]
      : undefined;

  const titles: { [key: string]: string } = {
    google: "גוגל",
    facebook: "פייסבוק",
  };

  const hasDatesChanged =
    prevSelectedStartDate.current?.toISOString() !==
      selectedStartDate.toISOString() ||
    prevSelectedEndDate.current?.toISOString() !==
      selectedEndDate.toISOString() ||
      prevSelectedCapmaignType.current?.toString() !== currentFbCapmaignType;

  useEffect(() => {
    //console.log('>>>' + prevSelectedCapmaignType.current)
    if (!titles[productName]) return history.replace("/Statistics");
    if(!tabs){
      return;
    }
    //if CapmaignType changed we fetch anyway
    if(prevSelectedCapmaignType.current?.toString() == currentFbCapmaignType){
    if (
      (((!approvedDateSelection && !hasDatesChanged) ||
        (!approvedDateSelection && hasDatesChanged)) &&
        pageData &&
        pageData[tabs?.[tabNumber]?.en])
    )
    {
      
      return;
    }
  }
    if (hasDatesChanged) setPageData(null);
    (async () => {
      setLoading(true);
      const pageStats = await GetProductStats(
        selectedStartDate,
        selectedEndDate,
        productName,
        tabs && tabs[tabNumber].en, 
        (currentFbCapmaignType || "")
      );
      if(pageStats.fbCapmaignActiveType && !currentFbCapmaignType){
        setCurrentFbCapmaignType(pageStats.fbCapmaignActiveType);
      }
      prevSelectedStartDate.current = selectedStartDate;
      prevSelectedEndDate.current = selectedEndDate;
      prevSelectedCapmaignType.current = (currentFbCapmaignType || "");
      setPageData((prevState: any) =>
        prevState ? { ...prevState, ...pageStats } : pageStats
      );
      setApprovedDateSelection(false);
      setLoading(false);
    })();
  }, [selectedStartDate, selectedEndDate, tabNumber, approvedDateSelection,currentFbCapmaignType]);

  const openPnia = async (
    e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    const pniaText = pniaTextAreaRef.current?.value;
    if (!pniaText?.trim())
      return toast.error("אנא מלא לפחות 10 תווים בתוכן הפנייה");

    const isPniaOpened = await OpenNewAdwordsPnia(pniaText, productName);
    return isPniaOpened
      ? toast.success("הפנייה נפתחה בהצלחה!")
      : toast.error("באמת שניסינו, אירעה שגיאה אנא נסה בשנית");
  };

  const statsPage: { [key: string]: (children: any) => JSX.Element } = {
    google: (children: childrenType) => (
      <GoogleStats tabs={tabs} pageData={pageData} tabNumber={tabNumber}>
        {children}
      </GoogleStats>
    ),
    facebook: (children: childrenType) => (
      <FacebookStats tabs={tabs} pageData={pageData} tabNumber={tabNumber}>
        {children}
      </FacebookStats>
    ),
  };

  return (
    <>
      {loading && <CustomGlobalLoader />}
      <CustomPage
        headerParameters={{
          isShowLogo: true,
          backButton: { isShowMobile: true, isShowDesktop: true, link: "/Vas" },
          isShowDeleteButton: false,
        }}
        title={`סטטיסטיקות מוצרי פרסום נלווים${
          titles[productName] ? ` - ${titles[productName]}` : ""
        }`}
        pageName={"ProductStatistics"}
      >
        <IonGrid fixed={true} className="main-content">
          <IonRow>
            <IonCol size="12">
              <CustomHeaddingItem
                mainTitle={`כל הסטטיסטיקות מוצרי פרסום ${
                  titles[productName] ? `של ${titles[productName]}` : ""
                } במקום אחד במיוחד בשבילך`}
                icon={statisticsWhite}
                iconBackground="statistics-main"
                innerPage={true}
              />
            </IonCol>
          </IonRow>

          <IonRow
            style={
              pageData && tabs && tabs[tabNumber].en !== "commentsAdwords"
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <IonCol size="4">
              <CustomDateRangePicker
                maxDate={new Date()}
                onDateChange={(startDate: Date, endDate: Date) => {
                  if (startDate !== selectedStartDate) {
                    setSelectedStartDate(startDate);
                  }
                  if (endDate !== selectedEndDate) {
                    setSelectedEndDate(endDate);
                  }
                }}
                startDate={selectedStartDate}
                endDate={selectedEndDate}
                defaultStaticRanges={_defaultStaticRanges}
                onApproveDates={() => setApprovedDateSelection(true)}
              />
            </IonCol>
            
            
            
              {productName === "facebook" && tabs && tabs[tabNumber].en == "fbAdsPerformance" && pageData && pageData.fbCapmaignTypes != undefined && pageData.fbCapmaignTypes.length > 1 &&
              
              <IonCol size="4"  >
              <DropDownFilter
              customStyle={{width:120,display: "inline-block",position: 'relative',top: -8}}
              showSelectedTextInBtn={true}
                defaulvalueIndex={currentFbCapmaignType ? pageData.fbCapmaignTypes.map((i:filterOptions)=>i.value).indexOf(currentFbCapmaignType) : 0}
                options={pageData.fbCapmaignTypes}
                onValueCahnge={(value) => {
                  setCurrentFbCapmaignType(value);
                }}
              ></DropDownFilter>
            </IonCol>
              }
            
          </IonRow>

          <div
            style={
              pageData && tabs && tabs[tabNumber].en !== "commentsAdwords"
                ? { display: "none" }
                : { display: "block", height: 51 }
            }
          />

          <IonRow>
            {tabs && (
              <IonCol size="12" sizeMd="8">
                <IonSegment
                  mode="md"
                  className="seg-prod-stat"
                  value={String(tabNumber)}
                  scrollable={true}
                  onIonChange={(e) => setTabNumber(Number(e.detail?.value))}
                >
                  {tabs.map((tab, i) => (
                    <IonSegmentButton
                      value={String(i)}
                      key={i}
                      
                    >
                      <span className="segment">{tab.he}</span>
                    </IonSegmentButton>
                  ))}
                </IonSegment>
              </IonCol>
            )}
            {titles[productName] &&
              statsPage[productName](
                tabs && tabs[tabNumber].en === "commentsAdwords" && (
                  <IonCol className="pnia-box-wrapper">
                    <IonCard className="pnia-box">
                      <h2>כאן תוכלו לפנות ישירות למנהל הפרסום שלך</h2>
                      <h3>תוכן הפנייה:</h3>
                      <textarea
                        ref={pniaTextAreaRef}
                        maxLength={5000}
                        rows={10}
                        onChange={(e) =>
                          setCharacterCount(e.target.value.length)
                        }
                      />
                      <div className="character-couner">
                        {characterCount} / 5000
                      </div>
                      <IonButton onClick={(e) => openPnia(e)}>שלח</IonButton>
                    </IonCard>
                  </IonCol>
                )
              )}
          </IonRow>
        </IonGrid>
      </CustomPage>
    </>
  );
};

export default ProductStatistics;
