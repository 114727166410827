import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonLabel,
  IonItem,
} from "@ionic/react";

import { useForm } from "react-hook-form";
import CustomForm from "../utils/CustomElements/CustomForm";
import CustomModal from "../utils/CustomElements/CustomModal/CustomModal";
import { HeaderParameters } from "../../data/Header-Context";
import CustomInput from "../utils/CustomElements/CustomInput";
import UserSettingsContext from "../../data/UserSettings-Context";
import CustomPublishButton from "../utils/CustomElements/CustomPublishButton";
import { chevronDownOutline, chevronUpOutline } from "ionicons/icons";
import ImagePicker, { Photo } from "../utils/ImagePicker/ImagePicker";
import { isMobile } from "react-device-detect";

import MemberDetailsContext from "../../data/MemberDetailes-context";

const ProfileSettings: React.FC<{
  show: boolean;
  closeModal: () => void;
  onSave: () => void;
}> = (props) => {
  const UserSettingsCtx = useContext(UserSettingsContext);
  const { handleSubmit, formState, errors, control } = useForm({
    mode: "onChange",
  });

  const MemberDetailsCxt = useContext(MemberDetailsContext);

  const newPassWordTextRef = useRef<HTMLIonInputElement>(null);
  const confirmNewPassWordTextRef = useRef<HTMLIonInputElement>(null);
  const PassWordErrorTextRef = useRef<HTMLIonLabelElement>(null);

  const [isChangePassword, setIsChangePassword] = useState(false);

  const headerParameters: HeaderParameters = {
    isPageHaveChanges: false,
    isShowLogo: false,
    modalType: "modal",
  };

  const deleteProfilePhoto = () => {
    UserSettingsCtx.deleteProfilePhoto(true);
  };

  const updateProfilePhoto = (photo: Photo) => {
    UserSettingsCtx.updateProfilePhoto(photo);

    dtlPushEvt({
      event: "profile",
      eventInfo: {
        ScreenName: "{{page path}}",
        user_action: "updates_profile",
        business_name:
          "" +
          (MemberDetailsCxt.GeneralInfo
            ? MemberDetailsCxt.GeneralInfo?.CompanyName
            : ""),
        business_category: GetMemberAdvCategories(
          MemberDetailsCxt.AdvCategories
        ),
        business_location: MemberDetailsCxt.BusinessAddress?.City,
      },
    });
  };
  const [takenPhoto, setTakenPhoto] = useState<Photo>();
  useEffect(() => {
    if (UserSettingsCtx.userProfileSettingsInfo == null) {
      UserSettingsCtx.initUserProfileSettingContext(true);
    }
    setTakenPhoto({
      path: undefined,
      preview: UserSettingsCtx.userProfileSettingsInfo!.ProfileImage?.Url,
    });
  }, []);

  const accordionHandler = () => {
    setIsChangePassword(!isChangePassword);
    newPassWordTextRef.current!.setFocus();
  };

  const ChangePassword = () => {
    const newPassWord = newPassWordTextRef.current!.value!.toString();
    const confirmNewPassWord = confirmNewPassWordTextRef.current!.value!.toString();
    if (newPassWord === confirmNewPassWord) {
      UserSettingsCtx.ChangePassword(true, newPassWord, confirmNewPassWord);

      dtlPushEvt({
        event: "profile",
        eventInfo: {
          ScreenName: "{{page path}}",
          user_action: "updates_profile",
          business_name:
            "" +
            (MemberDetailsCxt.GeneralInfo
              ? MemberDetailsCxt.GeneralInfo?.CompanyName
              : ""),
          business_category: GetMemberAdvCategories(
            MemberDetailsCxt.AdvCategories
          ),
          business_location: MemberDetailsCxt.BusinessAddress?.City,
        },
      });
    } else {
      PassWordErrorTextRef.current!.innerHTML = "שדות אינם תואמים";
    }
  };
  return (
    <CustomModal
      pageName={"ProfileSettings"}
      title={"פרופיל משתמש"}
      subTitle={"פרופיל המשתמש הוא בשבילך בלבד. פרטים אלה לא יופיעו באתר"}
      headerParameters={headerParameters}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
    >
      <CustomForm onSubmit={handleSubmit(ChangePassword)} errors={errors}>
        {UserSettingsCtx.userProfileSettingsInfo && (
          <IonGrid className="ion-no-padding ion-padding-horizontal">
            <IonRow className="ion-justify-content-between">
              <IonCol
                size="12"
                sizeMd="5.9"
                style={{ order: !isMobile ? "2" : "" }}
              >
                <IonRow className="ion-text-center">
                  <IonCol className="ion-no-padding">
                    <ImagePicker
                      onImagePick={(photo: Photo) => {
                        if (photo) {
                          updateProfilePhoto(photo);
                          setTakenPhoto(photo);
                        }
                      }}
                      onDeletePic={() => {
                        deleteProfilePhoto();
                      }}
                      currentPhoto={takenPhoto}
                      isMultiple={false}
                    />
                  </IonCol>
                </IonRow>
              </IonCol>
              <IonCol
                size="12"
                sizeMd="5.6"
                className="ion-no-padding ion-padding-left"
              >
                <IonGrid>
                  <IonRow>
                    <IonCol className={isMobile ? "ion-margin-top" : ""}>
                      <CustomInput
                        name="Privatename"
                        errors={errors}
                        control={control}
                        label="שם פרטי"
                        type="text"
                        disabled={true}
                        value={
                          UserSettingsCtx.userProfileSettingsInfo.FirstName
                        }
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className={isMobile ? "ion-margin-top" : ""}>
                      <CustomInput
                        name="Lastname"
                        errors={errors}
                        control={control}
                        label="שם משפחה"
                        type="text"
                        disabled={true}
                        value={UserSettingsCtx.userProfileSettingsInfo.LastName}
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className={isMobile ? "ion-margin-top" : ""}>
                      <CustomInput
                        name="MainPhone"
                        errors={errors}
                        control={control}
                        label="טלפון ראשי"
                        type="text"
                        disabled={true}
                        value={UserSettingsCtx.userProfileSettingsInfo.Phone1}
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className={isMobile ? "ion-margin-top" : ""}>
                      <CustomInput
                        name="MobilePhone"
                        errors={errors}
                        control={control}
                        label="טלפון נייד"
                        type="text"
                        disabled={true}
                        value={
                          UserSettingsCtx.userProfileSettingsInfo.MobilePhone
                        }
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className={isMobile ? "ion-margin-top" : ""}>
                      <CustomInput
                        name="mail"
                        errors={errors}
                        control={control}
                        label="מייל"
                        type="text"
                        disabled={true}
                        value={UserSettingsCtx.userProfileSettingsInfo.Email}
                      />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className={isMobile ? "ion-margin-top" : ""}>
                <IonGrid className="accordion-wrapper">
                  <IonItem
                    button
                    mode="md"
                    lines="full"
                    className="accordion-item"
                    onClick={accordionHandler}
                  >
                    <IonLabel>
                      <h3 className="small-title bold-text">שינוי סיסמה</h3>
                    </IonLabel>
                    <IonIcon
                      icon={
                        isChangePassword ? chevronUpOutline : chevronDownOutline
                      }
                      size="small"
                      slot="end"
                    />
                  </IonItem>

                  <IonRow
                    className={
                      isChangePassword ? "content is-expanded" : "content"
                    }
                  >
                    <IonCol size="12" sizeMd="5.8" class="login">
                      <IonRow>
                        <IonCol className={isMobile ? "ion-margin-top" : ""}>
                          <CustomInput
                            name="newPassWord"
                            errors={errors}
                            control={control}
                            label="סיסמה חדשה"
                            type="password"
                            ref={newPassWordTextRef}
                            required={true}
                            onClick={() => {
                              PassWordErrorTextRef.current!.innerHTML = "";
                            }}
                          />
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol className={isMobile ? "ion-margin-top" : ""}>
                          <CustomInput
                            name="newPassWordConfirm"
                            errors={errors}
                            control={control}
                            label="אישור סיסמה"
                            type="password"
                            ref={confirmNewPassWordTextRef}
                            required={true}
                            onClick={() => {
                              PassWordErrorTextRef.current!.innerHTML = "";
                            }}
                          />
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol className={isMobile ? "ion-margin-top" : ""}>
                          <IonLabel ref={PassWordErrorTextRef}></IonLabel>
                        </IonCol>
                      </IonRow>
                    </IonCol>
                    <IonCol>
                      <IonRow className="ion-justify-content-end">
                        <IonCol size="12" sizeMd="5.9">
                          <CustomPublishButton title="שמירה"></CustomPublishButton>
                        </IonCol>
                      </IonRow>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </CustomForm>
    </CustomModal>
  );
};

export default ProfileSettings;
