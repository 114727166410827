import React, { useContext, useEffect, useRef, useState } from 'react';
import { IonButton, IonCard, IonLabel } from '@ionic/react';
import { isMobile } from 'react-device-detect';
import SelfSaleContext, { AdvertisingBudgetField, RequestResult } from '../../../data/SelfSaleContext';
import CustomInputGroup from '../CustomElements/CustomRadioGroup';

const SelfSaleBudget: React.FC<{ saleType: string; completed: boolean; onComplete?: (phaseId: number, valid: RequestResult) => void, showCategoryCard: boolean }> = (props) => {
  const SelfSaleCtx = useContext(SelfSaleContext);
  const [budgetOptions, setBudgetOptions] = useState<AdvertisingBudgetField[]>([]);
  const [budgetSummary, setBudgetSummary] = useState<number>(0);
  const [tempBudget, setTempBudget] = useState<AdvertisingBudgetField | undefined>();

  useEffect(() => {
    getAdvertisingBudget();
    SelfSaleCtx.getMemberCategories().then((data: any) => {
      if (data && data.length > 0) {
        let categoriesString = data.map((i: any) => `'${i.cat_code}'`).join(',');
        SelfSaleCtx.getBestPackByCategory(categoriesString).then((res: any) => setBudgetSummary(res));
      }
    });
  }, []);
  useEffect(() => {
    if (props.completed) {
      if (tempBudget) {
        SelfSaleCtx.saveAdvertisingBudget(tempBudget.BudgetType).then((res: RequestResult) => {
          props.onComplete && props.onComplete(4, res);
        });
      }
      else {
        props.onComplete && props.onComplete(4, { Success: false, ResultData: "", ResultDesc: "לא נבחר תקציב" })
      }
    }
  }, [props.completed]);

  useEffect(() => {
    if (budgetSummary > 0 && budgetOptions.length > 0 && !tempBudget) {
      budgetOptions.forEach((item: any) => {
        if (Number(item.BudgetAmount) === budgetSummary) {
          setTempBudget(item);
        }
      });
    }
  }, [budgetSummary]);

  const getAdvertisingBudget = () => {
    SelfSaleCtx.getMemberAdvertisingBudget(props.saleType).then((res: any) => {
      if (res && res.length) {
        setBudgetOptions(res);
        res.forEach((item: any) => {
          if (item.selected) {
            setTempBudget(item);
          }
        });
        if (!tempBudget) {
          setTempBudget(res[1])
        }
      } else {
        setBudgetOptions([]);
      }
    });
  };

  return (
    <>
      {props.showCategoryCard && <IonCard mode="md" className={isMobile ? 'self-sale-card self-sale-card-mobile' : 'self-sale-card'}>
        <div className="category-card-header">
          <h2 className="ion-no-margin self-sale-section-title">תחום עסק שלך: {SelfSaleCtx.businessCategory} </h2>
        </div>
      </IonCard>}
      <IonCard mode="md" className={isMobile ? 'self-sale-card self-sale-card-mobile' : 'self-sale-card'}>
        <h2 className="ion-no-margin self-sale-section-title">מה התקציב החודשי שלך לפרסום?</h2>
        <div>
          <p className='large-p'>רוב העסקים בקטגוריה {SelfSaleCtx.businessCategory} בחרו לפרסם בסכום של <b>{budgetSummary} ש״ח</b> לחודש.</p>
        </div>
        {budgetOptions && budgetOptions.length && (
          <div style={{margin: '15px 0', minHeight: '280px'}}>
            <CustomInputGroup
              direction="column"
              value={tempBudget && tempBudget.BudgetType}
              name="SaleBudget"
              options={budgetOptions.map((o: AdvertisingBudgetField) => {
                return { label: `עד ${parseInt(o.BudgetAmount).toLocaleString()} ₪`, value: o.BudgetType };
              })}
              setValue={(val) => {
                const temp_budget = budgetOptions.find((b) => b.BudgetType == val);
                setTempBudget(temp_budget);
              }
              } />
          </div>
        )}
        {tempBudget && (
          <>
            <div>
              {props.saleType === "2" && <p className='small-p '>עלות דמי ניהול: {parseInt(tempBudget.Damash).toLocaleString()} ₪</p>}
              {props.saleType === "2" && <p className='small-p '>סך הכל תקציב מקסימלי ודמי ניהול: {parseInt(tempBudget.TotalAmount).toLocaleString()} ₪</p>}
              <p className='small-p'>{tempBudget.BudgetDesc}</p>
              <p className='small-p d-flex'>המחירים אינם כוללים מע"מ. בכפוף
                <IonButton size='small' fill="clear" className='terms-button' href="https://www.b144biz.co.il/wp-content/uploads/2024/03/takanon_self.pdf" target="_blank">לתקנון ותנאי השירות</IonButton>
              </p>
            </div>
          </>)}
      </IonCard>
    </>
  );
};

export default SelfSaleBudget;
