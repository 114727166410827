import React, { useState, useCallback, useEffect, useContext } from "react";
import MemberInboxContext, { messageObj } from "./MemberInbox-Context";

import { config } from "../Constants";
import { fetchRequest } from "../utils-ts/fetchRequest";
import { toast } from "react-toastify";
//import CustomLoader from "../components/utils/CustomElements/CustomLoader";
import GlobalContext from "./Global-Context";
const parseString = require("xml2js").parseString;

const MemberInboxContextProvider: React.FC = (props) => {
  //const [loading, setLoading] = useState<boolean>(false);
  const globalCtx = useContext(GlobalContext);

 const setLoading = (loading:boolean)=>{
  
  globalCtx.setLoader(loading);

 };
  const [messages, setMessages] = useState<messageObj[] | undefined>();
  const [returnCountResults] = useState(20);
  const [
    totalMessagesAmount,
    setTotalMessagesAmountMessages,
  ] = useState<number>();

  const [unReadMessagesAmount, setUnReadMessagesAmount] = useState<
    number | undefined
  >();
  const [totalReadMessagesAmount, setTotalReadMessagesAmount] = useState<
    number | undefined
  >();

  useEffect(() => {
    if (messages) {
      setLoading(false);
    }
  }, [messages]);

  const getUnReadMessagesAmount = useCallback(async (totalAmount: number) => {
    let jsDetails = {
      pageNumber: 0,
      returnCount: totalAmount,
      isRead: false,
      isDeleted: false,
    };

    var data = new URLSearchParams({
      jsDetails: JSON.stringify(jsDetails),
    }).toString();

    fetchRequest(
      `${config.url.API_URL}/Client.asmx/GetMemberMessages`,
      "post",
      data
    )
      .then((response) => {
        parseString(response, (err: any, result: any) => {
          if (result) {
            var res = JSON.parse(result.string["_"]);
            if (res.Success) {
              const messagesArray = JSON.parse(res.Response);
              setUnReadMessagesAmount(
                messagesArray.MemberMessagesResponseItem.length
              );
              setTotalReadMessagesAmount(
                totalAmount - messagesArray.MemberMessagesResponseItem.length
              );
            }
          }
        });
      })
      .catch(function (error) {
        console.log("Request failed", error);
      });
  }, []);

  const initMessageContext = useCallback(
    async (
      useLoading: boolean,
      pageNumber: number,
      messageFilter?: string,
      returnCount?: number
    ) => {
      let isMessegeRead: boolean | undefined = undefined;
      if (messageFilter === "notread") {
        isMessegeRead = false;
      } else if (messageFilter === "read") {
        isMessegeRead = true;
      }
      useLoading && setLoading(useLoading);
      let jsDetails = {
        pageNumber: pageNumber,
        returnCount:
          returnCount != undefined ? returnCount : returnCountResults,
        isRead: isMessegeRead,
      };

      var data = new URLSearchParams({
        jsDetails: JSON.stringify(jsDetails),
      }).toString();

      fetchRequest(
        `${config.url.API_URL}/Client.asmx/GetMemberMessages`,
        "post",
        data
      )
        .then((response) => {
          useLoading && setLoading(false);
          parseString(response, (err: any, result: any) => {
            if (result) {
              var res = JSON.parse(result.string["_"]);
              if (res.Success) {
                const messagesArray = JSON.parse(res.Response);
                const totalAmount = messagesArray.MemberMessagesCount;
                const MemberMessagesResponseItem = messagesArray.MemberMessagesResponseItem.filter(
                  (m: messageObj) => {
                    return (
                      m.isDeleted === false &&
                      (isMessegeRead === undefined ||
                        m.isRead === isMessegeRead)
                    );
                  }
                );
                if (pageNumber > 0) {
                  setMessages((current) => {
                    if (MemberMessagesResponseItem.length > 0) {
                      return current!.concat(MemberMessagesResponseItem);
                    } else {
                      return current!;
                    }
                  });
                } else {
                  setMessages(MemberMessagesResponseItem);
                }
                setTotalMessagesAmountMessages(totalAmount);
                getUnReadMessagesAmount(totalAmount);
              }
            }
          });
        })
        .catch(function (error) {
          useLoading && setLoading(false);
          console.log("Request failed", error);
        });
    },
    [getUnReadMessagesAmount]
  );

  const updateMemberMessages = useCallback(
    async (
      rowsIds: string,
      platformType: string,
      action: "read" | "delete",
      pageNum: number,
      messageFilter?: string
    ) => {
      let isMessegeRead: boolean | undefined = undefined;
      if (messageFilter === "notread") {
        isMessegeRead = false;
      } else if (messageFilter === "read") {
        isMessegeRead = true;
      }
      const jsDetails = {
        rowId: rowsIds,
        platformType: platformType,
        action: action,
      };
      var data = new URLSearchParams({
        jsDetails: JSON.stringify(jsDetails),
      }).toString();

      fetchRequest(
        `${config.url.API_URL}/Client.asmx/UpdateMemberMessages`,
        "post",
        data
      )
        .then((response) => {
          parseString(response, (err: any, result: any) => {
            if (result) {
              var messagesArray = JSON.parse(result.string["_"]);
              if (messagesArray.Success) {
                setMessages((current) => {
                  let newArray = [...current!];
                  const changeElementsId = rowsIds.split(",");
                  newArray.map((e) => {
                    if (changeElementsId.includes(e.id.toString())) {
                      if (action === "delete") {
                        e.isDeleted = true;
                      } else if (action === "read") {
                        e.isRead = true;
                      }
                    }
                  });
                  return newArray.filter((m: messageObj) => {
                    return (
                      m.isDeleted === false &&
                      (isMessegeRead === undefined ||
                        m.isRead === isMessegeRead)
                    );
                  });
                });

                initMessageContext(false, pageNum, messageFilter);
              } else {
                toast.error(messagesArray.ErrorMessage);
              }
            }
          });
        })
        .catch(function (error) {
          console.log("Request failed", error);
        });
    },
    [initMessageContext]
  );

  return (
    <MemberInboxContext.Provider
      value={{
        messages,
        totalMessagesAmount,
        unReadMessagesAmount,
        totalReadMessagesAmount,
        initMessageContext,
        updateMemberMessages,
      }}
    >
      {props.children}
      {/* <CustomLoader
        isOpen={loading}
        onDidDismiss={() => {
          setLoading(false);
        }}
      ></CustomLoader> */}
    </MemberInboxContext.Provider>
  );
};

export default MemberInboxContextProvider;
