import React from 'react'
import googleAds from '../../../../../../src/images/icons/google-ads.webp'
import googleParner from '../../../../../../src/images/icons/google-partner.webp'
import B144Icon from '../../../../../components/icons/B144Icon'
import styles from './GoogleBenefitsPage.module.scss'

export default function FacebookBenefitsPage() {
  return (
    <div className={styles['google-benefits-page']}>
      <div className={styles.title}>מה תקבלו:</div>
      <ul className={styles.list}>
        <li>מחקר מילות מפתח ייעודי לעסק שלך</li>
        <li>הקמה וניהול קמפיין מודעות מקצועי ואפקטיבי</li>
        <li>מודעות שיווקית מעוצבות</li>
        <li>דף נחיתה מעוצב ומותאם אישית</li>
        <li>אפליקציה למעקב אחרי ביצועי הקמפיין</li>
        <li>ממשק לעדכון עצמאי של דף העסק</li>
        <li>קידום העסק באתר B144 ודף עסק דיגיטלי</li>
        <li>מספר טלפון ייחודי לעסק עם יכולות מעקב אחרי הקמפיין</li>
      </ul>
      <div className={styles.icons}>
        <B144Icon className={`${styles.icon} ${styles.b144}`} />
        <img
          src={googleAds}
          alt="Google ads"
          className={`${styles.icon} ${styles['google-ads']}`}
        />
        <img
          src={googleParner}
          alt="Google partner"
          className={`${styles.icon} ${styles['google-partner']}`}
        />
      </div>
    </div>
  )
}
