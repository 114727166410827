import React, { useContext } from "react";
import { IonButton, IonCol, IonItem, IonLabel, IonRow } from "@ionic/react";

import DoughnutChart from "../../components/utils/Charts/DoughnutChart";
import CustomTitleButton from "../../components/utils/CustomElements/CustomTitleButton";
import UserContext from "../../data/Auth-context";
import MemberDetailsContext from '../../data/MemberDetailes-context';
import { MemberDetailesArrayToShow, showEmptyElementsBtn,getAdvCategoryCategories, businessCardModulesName } from "../../utils-ts/BusinessCardFunc";
import { config } from "../../Constants";
import { isMobile } from "react-device-detect";


const BusinessCardQuickAccess: React.FC<{
  onSetMainCategory: (main_cat:string)=>void;
  onSetShowElementName: (bus_card_module_name:businessCardModulesName)=>void;
}> = (props) => {

  const { authValues } = useContext(UserContext);
  const MemberDetailsCxt = useContext(MemberDetailsContext);

  
  let showEmptyElements = 0;
  
  
  
  return (
    <>
      <IonItem lines="none" className={"section-item " + (!isMobile && "section-item-full")}>
                <IonLabel>
                  <IonRow className="ion-margin-bottom">
                    <IonCol className="ion-no-padding">
                      <h2 className="ion-no-margin large-p bold-text">{MemberDetailsCxt.ContentStatusPercentage === 100 ? 'כל הכבוד!' : 'עדיין חסרים פרטים בדף העיסקי'}</h2>
                      <p className="ion-no-margin">
                        {MemberDetailsCxt.ContentStatusPercentage === 100
                          ? 'עשית את זה בגדול! כל המידע אודות העסק שלך נרשם בהצלחה'
                          : 'דף עיסקי שלם משפר את דירוג העסק בחיפוש ועוזר ללקוחות'}
                      </p>
                    </IonCol>
                    {/* {authValues && authValues.IsSelfRegister === false && ( */}
                      <IonCol sizeMd="3" size="2.5" className="ion-no-padding">
                        <DoughnutChart
                          titleColor="black"
                          chartColor={config.otef_israel ? 'rgb(29,139,200)' : undefined}
                          value={MemberDetailsCxt.ContentStatusPercentage ? MemberDetailsCxt.ContentStatusPercentage : 0}
                          chartWidth={63}
                          chartWeight={80}
                        />
                      </IonCol>
                    {/* )} */}
                  </IonRow>
                  {!isMobile && MemberDetailesArrayToShow.map((o, index) => {
                    
                    if (showEmptyElements < 5 && showEmptyElementsBtn(MemberDetailsCxt, o.id)) {
                      if (o.id === 'AdvCategories') {
                        let mainCategoriesTemp = getAdvCategoryCategories(MemberDetailsCxt);
                        return mainCategoriesTemp.map((c, index) => {
                          if (showEmptyElements < 5) {
                            showEmptyElements = showEmptyElements + 1;
                            return (
                              <CustomTitleButton
                                position="home-section-btn"
                                key={'c_' + index}
                                onClick={() => {
                                  props.onSetMainCategory(c);
                                  props.onSetShowElementName(o.id);
                                  SetDtlPushEvt('user_engagement', o.btnTitle);
                                }}
                                BtnTxt={o.btnTitle + ' - ' + c}></CustomTitleButton>
                            );
                          }
                        });
                      } else {
                        showEmptyElements = showEmptyElements + 1;
                        return (
                          <CustomTitleButton
                            position="home-section-btn"
                            key={index}
                            onClick={() => {
                              props.onSetShowElementName(o.id);
                              SetDtlPushEvt('user_engagement', o.btnTitle);
                            }}
                            BtnTxt={o.btnTitle}></CustomTitleButton>
                        );
                      }
                    }
                  })}

                  <IonButton
                    routerLink="/BusinessCard"
                    expand="block"
                    className="main-button "
                    onClick={() => {
                      SetDtlPushEvt('user_engagement', 'לפרופיל העסק');
                    }}>
                    לפרופיל העסק
                  </IonButton>
                </IonLabel>
              </IonItem>
    </>
  );
};

export default BusinessCardQuickAccess;