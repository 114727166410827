import { IonItem, IonLabel, IonList, IonText } from "@ionic/react";
import React from "react";
import { BudgetDetails } from "../../data/SelfService-context";

const BudgetInfo: React.FC<{
  currentBudget: BudgetDetails;
}> = (props) => {
  return (
    <React.Fragment>
      <div className="ion-text-center  budget-info-cont">
        <h2>{props.currentBudget.pack_name}.</h2>
        <small style={{ color: "black" }}>עלות מדיה ודמי ניהול מקסימלי</small>

        <h1 style={{ marginTop: "10px" }}>
          <bdo dir="rtl">
            <bdo dir="ltr">{props.currentBudget.max_budget_fee}</bdo>₪
          </bdo>
        </h1>
        <small>*המחירים אינם כוללים מע"מ</small>

        {props.currentBudget.prods_in_pack &&
          props.currentBudget.prods_in_pack.length > 0 && (
            <IonList>
              {props.currentBudget.prods_in_pack.map((p, i) => {
                return (
                  <IonItem key={i}>
                    <IonLabel className="ion-text-center"> {p}</IonLabel>
                  </IonItem>
                );
              })}
            </IonList>
          )}
      </div>
    </React.Fragment>
  );
};

export default BudgetInfo;
