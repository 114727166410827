import React, { useState, useRef, useEffect } from "react";
import { IonIcon, IonButton, IonImg, IonItem } from "@ionic/react";
import { blankImageIcon, replaceImageWhite } from "../../../images/index";

import {
  Capacitor,
  CameraResultType,
  CameraSource,
  Plugins,
} from "@capacitor/core";
import "./ImagePicker.scss";
import CustomDeleteButton from "../CustomElements/CustomDeleteButton";
import CustomImageEditor from "./CustomImageEditor";
import { base64FromPath } from "@ionic/react-hooks/filesystem";
import { toast } from "react-toastify";
import { config } from "../../../Constants";

export interface Photo {
  runId?: number;
  path: string | undefined;
  preview: string;
  name?: string;
  size?: number;
  width?: number;
  height?: number;
}

const { Camera } = Plugins;

const ImagePicker: React.FC<{
  onImagePick: (photo: Photo) => void;
  onImagesPick?: (photos: Photo[]) => void;
  onValidate?: (
    blob: Blob,
    callBack: (
      answer: string,
      { width, height }: { width: number; height: number }
    ) => void
  ) => void;
  currentPhoto?: Photo;
  showPreview?: boolean;
  isMultiple: boolean;
  onDeletePic?: () => void;
}> = (props) => {
  ImagePicker.defaultProps = {
    showPreview: true,
  };
  const [takenPhoto, setTakenPhoto] = useState<Photo>();
  const [isShowEditor, setisShowEditor] = useState(false);
  const filePickerRef = useRef<HTMLInputElement>(null);
  //const [takenPhotos, setTakenPhotos] = useState<Photo[]>();

  const b64toBlob = (b64Data: string, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  useEffect(() => {
    if (props.currentPhoto && props.currentPhoto.preview) {
      setTakenPhoto(props.currentPhoto);
    }
  }, [props.currentPhoto]);

  const openFilePicker = () => {
    filePickerRef.current!.click();
  };

  const checkFileSize = (size: number) => {
    let ifFileValid = true;
    var filesize = size / 1024 / 1024; //.toFixed(4); // MB
    if (filesize > config.total_filesize_mb) {
      toast.error("לא ניתן להעלות תמונות מעל " + config.total_filesize_mb + "MB");
      ifFileValid = false;
    }
    return ifFileValid;
  };

  const validateUploadedFiles = (files: FileList) => {
    if (files.length > 10) {
      toast.error("לא ניתן להעלות למעלה מ-10 תמונות");
      return false;
    }

    let total_filesize = 0;
    for (let i = 0; i < files.length; i++) {
      total_filesize += files[i].size;
    }

    var total_filesize_mb = total_filesize / 1024 / 1024; //.toFixed(4); // MB
    if (total_filesize_mb > config.total_filesize_mb) {
      if (files.length > 1) {
        toast.error("לא ניתן להעלות תמונות בגודל כולל מעל " + config.total_filesize_mb + "MB");
      } else {
        toast.error("לא ניתן להעלות תמונות מעל " + config.total_filesize_mb + "MB");
      }
      return false;
    }
    return true;
  };

  // const pickFileHandler_sngl = (event: React.ChangeEvent<HTMLInputElement>) => {

  //   const file = event.target!.files![0];

  //   const FileSizeValid = checkFileSize(file.size);
  //   if (FileSizeValid == false) {
  //     return;
  //   }
  //   const fr = new FileReader();
  //   fr.onload = () => {
  //     const photo: Photo = {
  //       path: undefined,
  //       preview: fr.result!.toString(),
  //       size:file.size,
  //       name:file.name
  //     };
  //     setTakenPhoto(photo);
  //     props.onImagePick(photo);
  //   };
  //   fr.readAsDataURL(file);
  // };
  const pickFileHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target!.files!;

    const uploadValid = validateUploadedFiles(files);
    if (uploadValid != true) {
      return;
    }

    const new_photos: Photo[] = new Array<Photo>();

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      // const FileSizeValid = checkFileSize(file.size);
      // if (FileSizeValid == false) {
      //   return;
      // }
      const fr = new FileReader();
      fr.onload = () => {
        if (file.type == "image/webp") {
          const image = new Image();
          image.src = fr.result!.toString();
          image.onload = (event: Event) => {
            // console.log(img.width);
            const png = webp_to_png(event.currentTarget);
            const photo: Photo = {
              runId: i,
              path: undefined,
              preview: png,
              name: file.name,
              size: file.size,
            };
            if (!props.isMultiple || files.length == 1) {
              setTakenPhoto(photo);
              props.onImagePick(photo);
            } else {
              new_photos.push(photo);

              if (new_photos.length == files.length) {
                //setTakenPhotos(new_photos);
                props.onImagesPick && props.onImagesPick(new_photos);
              }
            }
          };
        } else {
          const photo: Photo = {
            runId: i,
            path: undefined,
            preview: fr.result!.toString(),
            name: file.name,
            size: file.size,
          };
          if (!props.isMultiple || files.length == 1) {
            setTakenPhoto(photo);
            props.onImagePick(photo);
          } else {
            new_photos.push(photo);

            if (new_photos.length == files.length) {
              //setTakenPhotos(new_photos);
              props.onImagesPick && props.onImagesPick(new_photos);
            }
          }
        }
      };
      fr.readAsDataURL(file);
    }
  };

  const webp_to_png = (img: any) => {
    const canvas = document.createElement("canvas");
    const canvas_context = canvas.getContext("2d");

    canvas.width = img.width;
    canvas.height = img.height;
    canvas_context && canvas_context.drawImage(img, 0, 0);
    return canvas.toDataURL("image/png");
  };

  const takePhotoHandler = async () => {
    //if multi upload then use regular file input
    if (!Capacitor.isPluginAvailable("Camera") || props.isMultiple) {
      openFilePicker();
      return;
    }

    try {
      const photo = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        source: CameraSource.Prompt,
        quality: 80,
        width: 500,
        webUseInput: true,
      });

      if (!photo || !photo.webPath) {
        return;
      }
      const base64 = await base64FromPath(photo!.webPath);
      const cleaneBase64 = base64.split(";base64,")[1];
      const blobImage = b64toBlob(cleaneBase64);
      const FileSizeValid = checkFileSize(blobImage.size);
      if (!FileSizeValid) {
        return;
      }
      const pickedPhoto: Photo = {
        runId: 1,
        path: photo.path,
        preview: photo.webPath,
        name: photo.format,
        size: blobImage.size,
      };
      if (props.onValidate) {
        props.onValidate(blobImage, (notValidMessage, { width, height }) => {
          if (notValidMessage) return toast.error(notValidMessage);
          pickedPhoto.height = height;
          pickedPhoto.width = width;
          setTakenPhoto(pickedPhoto);
          setisShowEditor(true);
        });
      } else {
        setTakenPhoto(pickedPhoto);
        //props.onImagePick(pickedPhoto);
        setisShowEditor(true);
      }
    } catch (error) {
      //error == "User cancelled photos app"
      //openFilePicker();
    }
  };

  return (
    <>
      <IonItem
        lines="none"
        className={"image-preview " + (!takenPhoto ? "no-image " : "")}
      >
        {props.showPreview ? (
          <React.Fragment>
            {!takenPhoto && (
              <div className="no-pic-wraper">
                <IonButton
                  className="upload-img-btn"
                  fill="clear"
                  size="large"
                  shape="round"
                  onClick={takePhotoHandler}
                >
                  <IonIcon
                    icon={blankImageIcon}
                    size="large"
                    slot="icon-only"
                  ></IonIcon>
                </IonButton>
                <p>{props.isMultiple ? "לא נבחרו תמונות" : "לא נבחרה תמונה"}</p>
              </div>
            )}

            {takenPhoto && (
              <React.Fragment>
                <IonImg
                  onClick={takePhotoHandler}
                  src={takenPhoto.preview}
                  alt=""
                ></IonImg>
                <div className="image-actions-wrapper">
                  <IonButton fill="clear" onClick={takePhotoHandler}>
                    <IonIcon icon={replaceImageWhite} />
                  </IonButton>
                  {takenPhoto !== undefined &&
                    props.onDeletePic !== undefined &&
                    takenPhoto.preview !== "" && (
                      <CustomDeleteButton
                        isShow={
                          takenPhoto !== undefined &&
                          props.onDeletePic !== undefined &&
                          takenPhoto.preview !== ""
                        }
                        onDelete={() => {
                          if (props.onDeletePic) {
                            props.onDeletePic();
                            setTakenPhoto(undefined);
                          }
                        }}
                        fillClear={true}
                      ></CustomDeleteButton>
                    )}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        ) : (
          <IonButton
            className="upload-img-btn"
            fill="clear"
            size="large"
            onClick={takePhotoHandler}
          >
            <IonIcon
              icon={blankImageIcon}
              size="large"
              slot="icon-only"
            ></IonIcon>
          </IonButton>
        )}
      </IonItem>

      <input
        type="file"
        accept="image/png,image/gif,image/jpeg,image/pjpeg,image/bmp,image/webp"
        hidden
        multiple={props.isMultiple}
        ref={filePickerRef}
        onChange={pickFileHandler}
      />
      {isShowEditor === true && takenPhoto !== undefined && (
        <CustomImageEditor
          show={isShowEditor}
          photo={takenPhoto!}
          closeModal={() => {
            setisShowEditor(false);
          }}
          onSave={(imgSrc: string) => {
            const newPhoto: Photo = {
              runId: 1,
              path: undefined,
              preview: imgSrc,
              name: "",
              size: 0,
            };
            const photo = takenPhoto
              ? { ...takenPhoto, preview: imgSrc }
              : newPhoto;

            setTakenPhoto(photo);
            props.onImagePick(photo);
          }}
        ></CustomImageEditor>
      )}
    </>
  );
};

export default ImagePicker;
