import React, { ComponentProps, forwardRef, useState } from "react";
import {
  IonIcon,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
} from "@ionic/react";
import { createOutline, trash } from "ionicons/icons";
import CustomAlert from "./CustomAlert";

type IonItemSlidingProps = ComponentProps<typeof IonItemSliding>;
type inputRef = HTMLIonItemSlidingElement;
interface SlidingProps extends IonItemSlidingProps {
  onDelete?: () => void;
  onEdit?: () => void;
  showDeleteButton?: boolean;
}

const CustomItemSliding = forwardRef<inputRef, SlidingProps>(
  ({ showDeleteButton = true, ...props }, ref) => {
    const [startedDeleting, setStartDeleteing] = useState(false);
    const DeleteButtonsArray = [
      {
        text: "לא",
        role: "no",
        handler: () => {
          setStartDeleteing(false);
        },
      },
      {
        text: "כן",
        role: "yes",
        handler: () => {
          setStartDeleteing(false);
          if (props.onDelete) {
            props.onDelete();
          }
        },
      },
    ];
    return (
      <>
        <CustomAlert
          isOpen={startedDeleting}
          header="הודעת מערכת"
          message="האם ברצונך לבצע מחיקה"
          buttons={DeleteButtonsArray}
        ></CustomAlert>
        <IonItemSliding key={props.key} ref={ref}>
          {showDeleteButton && (
            <IonItemOptions side="start">
              <IonItemOption
                onClick={() => {
                  setStartDeleteing(true);
                }}
                color="danger"
              >
                <IonIcon slot="icon-only" icon={trash}></IonIcon>
              </IonItemOption>
            </IonItemOptions>
          )}
          {props.children}
          <IonItemOptions side="end">
            <IonItemOption onClick={props.onEdit}>
              <IonIcon slot="icon-only" icon={createOutline}></IonIcon>
            </IonItemOption>
          </IonItemOptions>
        </IonItemSliding>
      </>
    );
  }
);

export default CustomItemSliding;
