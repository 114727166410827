import React, { useContext, useEffect, useState } from 'react';
import HeaderContext, { HeaderParameters } from './Header-Context';
import MemberDetailsContext from '../data/MemberDetailes-context';

import CustomHeader from '../components/utils/CustomElements/CustomHeader/Header';
import UserContext from './AuthContextProvider';

const HeaderContextProvider: React.FC = (props) => {
  const [headerParameters, setHeaderParameters] = useState<HeaderParameters>({});

  const MemberDetailsCxt = useContext(MemberDetailsContext);
  const { authValues } = useContext(UserContext);

  useEffect(() => {
    if (MemberDetailsCxt.GeneralInfo == null && !!authValues) {
      MemberDetailsCxt.initMemberDetailsContext(!!authValues?.IsSelfRegister);
    }
  }, []);

  const initHeader = (headerParameters: HeaderParameters) => {
    setHeaderParameters(headerParameters);
  };

  return (
    <HeaderContext.Provider
      value={{
        headerParameters,
        initHeader,
      }}>
      <CustomHeader headerParameters={headerParameters}></CustomHeader>

      {props.children}
    </HeaderContext.Provider>
  );
};

export default HeaderContextProvider;
