import React from "react";
import { Doughnut } from "react-chartjs-2";
const Chart = require("react-chartjs-2").Chart;

const DoughnutChart: React.FC<{
  value: number;
  chartWidth?: number;
  chartWeight?: number;
  chartColor?: string;
  chartBackgroundColor?: string;
  titleColor?: string;
  isGradient?: boolean;
}> = (props) => {
  const full = Math.round(props.value);
  const empty = 100 - full;
  const valuesArray = [full, empty];
  const chartColor = props.chartColor ? props.chartColor : "#42A3FF";
  const chartBackgroundColor = props.chartBackgroundColor
    ? props.chartBackgroundColor
    : "#F1F1F1";
  const titleColor = props.titleColor
    ? props.titleColor
    : props.chartColor
    ? props.chartColor
    : "black";

  const options = {
    legend: { display: false },
    cutoutPercentage: props.chartWeight ? props.chartWeight : 80,
    responsive: true,
    tooltips: {
      enabled: false,
    },
    plugins: {
      labels: {
        render: (args: any) => {
          if (!args.value) return;
          return "";
        },
      },
    },
  };

  const data = {
    text: full,
    labels: ["מלא", "ריק"],
    datasets: [
      {
        borderWidth: 0,
        data: valuesArray,
        backgroundColor: (context: any) => {
          const ctx = context.chart.ctx;
          let gradient: any = chartColor;
          if (props.isGradient) {
            gradient = ctx.createLinearGradient(0, 0, 120, 20);
            const colorStart = "#ffb643";
            const colorEnd = "#ff8c4a";
            gradient.addColorStop(0, colorStart);
            gradient.addColorStop(1, colorEnd);
          }
          return [gradient, chartBackgroundColor];
        },
      },
    ],
  };

  const originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
  Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
    draw() {
      const chart = this.chart;
      const type = chart.config.type;
      const ctx = chart.ctx;
      const text = chart.config.data.text + "%";
      // const text = chart.config.data.text + "%";
      const width = chart.width;
      const height = chart.height;
      originalDoughnutDraw.apply(this, arguments);
      if (type === "doughnut") {
        const fontSize = (height / 65).toFixed(2);
        ctx.font = `${fontSize}em Open Sans Hebrew`;
        ctx.textBaseline = "middle";
        ctx.height = height;
        ctx.width = width;
        ctx.fillStyle = titleColor;
        

        // text alingn inside the chart calcilate the chart width minus the text width /2
        const textX =
          Math.round((width - ctx.measureText(text).width) / 2) +
          ctx.measureText(text).width;
        const textY = (height / 2) + 2;

        ctx.fillText(text, textX, textY);
      }

      // chart.update();
    },
  });

  return (
    <div
      style={{ width: props.chartWidth ? props.chartWidth : "inherit" }}
      className="inline-block"
    >
      <Doughnut width={140} options={options} data={data}></Doughnut>
    </div>
  );
};

export default DoughnutChart;

// props.chartWidth? props.chartWidth: 'inherit'
