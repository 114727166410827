import React, { useContext, useEffect, useState } from "react";
import CouponContext from "../../../data/Coupon-context";
import {
  IonRow,
  IonCol,
  IonImg,
  IonIcon,
  IonButton,
  IonGrid,
} from "@ionic/react";
import "./MemberCoupon.scss";
import { blankProductIcon } from "../../../images/index";

import defaultFriendNew from "../../../images/coupons/defaultFriendNew.png";
import pricePlusDiscountPercentIMG from "../../../images/coupons/pricePlusDiscountPercent.jpg";
import couponPresentIMG from "../../../images/coupons/couponPresent.jpg";
import orderProdutGetSurpriseIMG from "../../../images/coupons/orderProdutGetSurprise.jpg";
import defaultPersonallyCustomizedIMG from "../../../images/coupons/defaultPersonallyCustomized.jpg";
import {
  convertTimeStampToDate,
  getDateObjFromIsoString,
} from "../../../utils-ts/GlobalDateFunctions";
import HandleMemberCoupon from "./HandleMemberCoupon";
import { pencil } from "../../../images/index";
import { chevronDownOutline, chevronUpOutline } from "ionicons/icons";

const MemberCoupon: React.FC<{
  isAddCouponClick: boolean;
  onIsAddCouponClick: () => void;
  isCouponSelected: (isCouponSelcted: boolean) => void;
}> = (props) => {
  const [isShowMemebrCoupon, setIsShowMemebrCoupon] = useState(false);
  const [templateID, setTemplateID] = useState<number>(0);
  const [tempTemplateID, setTempTemplateID] = useState<number>(-1);
  const [toDate, setToDate] = useState<string>();

  const couponCtx = useContext(CouponContext);
  const couponMember = couponCtx.coupon?.CouponMember;
  const couponAInfo = couponCtx.coupon?.CouponAdditionalInfo;
  const DiscountPercentage = couponMember?.DiscountPercent;
  const [couponImage, setCouponImage] = useState<string>();
  const [isShowMoreInfo, setShowMoreInfo] = useState<boolean>(false);

  interface CouponImage {
    url: string;
  }

  var couponImages: { [id: number]: CouponImage } = {};

  couponImages[8] = { url: pricePlusDiscountPercentIMG };
  couponImages[9] = { url: couponPresentIMG };
  couponImages[10] = { url: orderProdutGetSurpriseIMG };
  couponImages[11] = { url: defaultPersonallyCustomizedIMG };

  useEffect(() => {
    if (props.isAddCouponClick === true) {
      setIsShowMemebrCoupon(true);
      props.onIsAddCouponClick();
    }
  }, [props.isAddCouponClick]);

  useEffect(() => {
    if (couponCtx.coupon == null) {
      couponCtx.initCouponContext(true);
      props.isCouponSelected(false);
    }
  }, []);

  useEffect(() => {
    if (couponMember) {
      if (couponMember?.TemplateId!) {
        setTemplateID(couponMember?.TemplateId!);
      }
      if (tempTemplateID === -1) {
        setTempTemplateID(couponMember?.TemplateId!);
      }
      if (couponMember?.TemplateId !== 0) {
        props.isCouponSelected(true);
      }
      if (couponMember?.ToDate) {
        setToDate(couponMember?.ToDate);
      }
    }
  }, [couponMember]);

  useEffect(() => {
    let img = "";
    if (couponAInfo && couponAInfo?.ImgTemplate) {
      img = couponAInfo.ImgTemplate;
    } else if (templateID && couponImages[templateID] != undefined) {
      img = couponImages[templateID].url;
    } else {
      img = defaultFriendNew;
    }
    setCouponImage(img);

    if (!couponAInfo?.IsDatesValid) {
      setToDate(undefined);
    }
  }, [couponAInfo, templateID]);

  const discoutLabel =
    DiscountPercentage && DiscountPercentage > 0
      ? `${DiscountPercentage}% הנחה`
      : "מתנה";

  const couponTemplateDescs: { [key: string]: string } = {
    1: "חבר מביא חבר",
    2: "1+1 מתנה",
    3: "מתנה",
    4: discoutLabel,
    5: "מחיר הנחה",
    6: "קנה - קבל",
    7: "מחיר לפני - מחיר אחרי",
    8:
      !!couponMember?.priceAfterDiscount && !!couponMember.priceBeforeDiscount
        ? `${
            (100 -
            (couponMember?.priceAfterDiscount /
              couponMember?.priceBeforeDiscount) *
              100).toFixed(2).replace(/\.00$/, '')
          }% הנחה`
        : "מחיר מבצע",
    9: "מתנה", //"רוכשים מוצר ומקבלים מתנה",
    10: "מתנה",
    11: discoutLabel,
  };

  const couponTemplateDesc = templateID
    ? couponTemplateDescs[templateID]
    : "קופון";

  return (
    <React.Fragment>
      <IonGrid>
        <IonRow>
          <IonCol className="ion-padding-bottom">
            <p className="small-p">
              הוספה של קופון עם מבצע אטרקטיבי מגביר את הסיכוי שלך לקבלת פניות
              מלקוחות פוטנציאלים
            </p>
          </IonCol>
        </IonRow>
        {templateID === 0 && (
          <IonRow>
            <IonCol className="ion-text-center no-items-wraper">
              <IonImg src={blankProductIcon}></IonImg>
              <p className="large-p">אין קופונים</p>
            </IonCol>
          </IonRow>
        )}

        {templateID !== 0 && (
          <React.Fragment>
            <IonRow>
              <IonCol>
                <IonButton
                  className="btn-clear-icon-text"
                  fill="clear"
                  onClick={() => {
                    setIsShowMemebrCoupon(true);
                  }}
                >
                  <span className="small-p">קופון פעיל</span>
                  <IonIcon icon={pencil} size="small" slot="end" />
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-margin-bottom">
                <div
                  role="button"
                  className="coupon-wrapper"
                  onClick={() => {
                    setIsShowMemebrCoupon(true);
                  }}
                >
                  <IonImg
                    src={couponImage}
                    className="coupon-image"
                    alt="Coupon Image"
                  />
                  <div className="coupon-body">
                    <div className="coupon-tag-wrapper">
                      <div className="coupon-tag" />
                      <span className="coupon-label">
                        {couponTemplateDesc || "קופון"}
                      </span>
                    </div>

                    {couponMember?.For144ClientsOnly && (
                      <span className="b144-exclusive">
                        <IonIcon
                          class="b144-exclusive-icon"
                          src="/assets/icon/coupon-icon.svg"
                        />
                        בלעדי ללקוחות B144
                      </span>
                    )}

                    <strong className="coupon-description">
                      {couponMember?.CouponDesc}
                    </strong>

                    {[7, 8].includes(templateID) &&
                      !!couponMember?.priceAfterDiscount &&
                      !!couponMember.priceBeforeDiscount && (
                        <div className="discount-prices">
                          <span>₪{couponMember.priceAfterDiscount}</span>{" "}
                          <del className="before-discount">
                            ₪{couponMember.priceBeforeDiscount}
                          </del>
                          |
                          <span className="after-discount">
                            חיסכון של ₪
                            {(couponMember.priceBeforeDiscount -
                              couponMember.priceAfterDiscount).toFixed(2).replace(/\.00$/, '')}
                          </span>
                        </div>
                      )}

                    <span
                      className="coupon-expiration"
                      style={{ visibility: toDate ? "visible" : "hidden" }}
                    >
                      בתוקף עד:
                      {toDate && toDate.indexOf("T") > -1
                        ? getDateObjFromIsoString(toDate)
                        : toDate && convertTimeStampToDate(toDate)}
                    </span>

                    <IonButton size="small" className="coupon-button">
                      למימוש
                    </IonButton>

                    <small className="coupon-AdditionalInfo">
                      {couponMember?.AdditionalInfo &&
                        `*${couponMember?.AdditionalInfo}`}
                    </small>

                    <IonButton
                      size="small"
                      className="icon-button"
                      fill="clear"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowMoreInfo(!isShowMoreInfo);
                      }}
                    >
                      <IonIcon
                        icon={
                          isShowMoreInfo ? chevronUpOutline : chevronDownOutline
                        }
                        size="small"
                      ></IonIcon>
                      לצפייה בנתוני קופון
                    </IonButton>
                    {isShowMoreInfo && (
                      <div className="coupon-more-info">
                        <p>
                          מספר מימושים:{" "}
                          <span className="bold-text">
                            {couponMember?.NumOfRealizations}
                          </span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </IonCol>
            </IonRow>
          </React.Fragment>
        )}
      </IonGrid>

      {isShowMemebrCoupon && (
        <HandleMemberCoupon
          show={isShowMemebrCoupon}
          isNewCoupon={tempTemplateID === 0 ? true : false}
          closeModal={(isDeleted: boolean) => {
            if (isDeleted) {
              setTemplateID(0);
              setTempTemplateID(0);
              props.isCouponSelected(false);
            } else {
              setTempTemplateID(-1);
            }
            setIsShowMemebrCoupon(false);
          }}
          onSave={() => {}}
        ></HandleMemberCoupon>
      )}
    </React.Fragment>
  );
};
export default MemberCoupon;
