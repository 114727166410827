import React, {
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonButton,
  IonIcon,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonCheckbox,
} from "@ionic/react";
import { toast } from "react-toastify";
//elemnts
import CustomModal from "../../utils/CustomElements/CustomModal/CustomModal";
// import CustomItemSliding from "../../utils/CustomElements/CustomItemSliding";
import { HeaderParameters } from "../../../data/Header-Context";
import CustomForm from "../../utils/CustomElements/CustomForm";
// import { addOutline } from "ionicons/icons";
// import HandlePublicationDays from "./HandlePublicationDays";
// //functions
// import { getTimeFotmat } from "../../../utils-ts/GlobalDateFunctions";
// import { getOpenDays } from "../../../utils-ts/BusinessOpenDaysFunc";
//data
import MemberDetailsContext, {
  PublicationDays,
} from "../../../data/MemberDetailes-context";
import CustomPublishButton from "../../utils/CustomElements/CustomPublishButton";

const BusinessPublicationDays: React.FC<{
  show: boolean;
  closeModal: () => void;
  onSave: () => void;
}> = (props) => {
  const { handleSubmit, formState, errors, control } = useForm({
    mode: "onChange",
  });
  const { dirty } = formState;
  const headerParameters: HeaderParameters = {
    isPageHaveChanges: dirty,
    modalType: "modal",
  };

  let MemberDetailsCxt = useContext(MemberDetailsContext);
  useEffect(() => {
    if (MemberDetailsCxt.PublicationDays == undefined) {
      MemberDetailsCxt.initMemberDetailsContext();
    }
  }, []);

  let currentPublicationDays = MemberDetailsCxt.PublicationDays
    ? MemberDetailsCxt.PublicationDays
    : [];

  const [startedEditing, setStartEditing] = useState(false);
  let selectedOpenRef = useRef<HTMLIonItemSlidingElement | null>(null);
  const selectedOpenHourIndexRef = useRef<number | null>(null);

  const saveHandler = async () => {
    let error = "";
    if (templateID == undefined || templateID == 0) {
      error = "יש לבחור תבנית מהרשימה";
    }

    if (fullFriday && halfFriday) {
      error = "לא ניתן לבחור שישי מלא וגם שישי עד 13";
    }

    if (error != "") {
      toast.error(error);
    } else {
      const NewArray = await pasreToHours(templateID);
      MemberDetailsCxt.savePublicationDaysHandler(
        NewArray,
        templateID,
        fullFriday,
        halfFriday,
        fullSaturday
      );
      props.onSave();
    }
  };

  // const deleteOpenHours = () => {
  //   if (currentPublicationDays) {
  //     const openHourIndex = selectedOpenHourIndexRef.current!;
  //     currentPublicationDays[openHourIndex].isDelete = true;
  //     saveHandler();
  //   }
  // };

  const defaultTemplate = 0;
  const templateList = [
    { id: 0, description: "בחר" },
    { id: 1, description: "24 שעות 7 ימים בשבוע" },
    { id: 2, description: "משעה 8:00 בבוקר ועד 20:00" },
  ];

  const [templateID, setTemplatId] = useState<number>(() => {
    if (MemberDetailsCxt.publicationDaysType != null) {
      return MemberDetailsCxt.publicationDaysType;
    } else {
      return defaultTemplate;
    }
  });

  const [fullFriday, setFullFriday] = useState<boolean>(() => {
    if (MemberDetailsCxt.fullFriday != null) {
      return true;
    } else {
      return MemberDetailsCxt.fullFriday;
    }
  });

  const [halfFriday, setHalfFriday] = useState<boolean>(() => {
    if (MemberDetailsCxt.halfFriday != null) {
      return false;
    } else {
      return MemberDetailsCxt.halfFriday;
    }
  });

  const [fullSaturday, setFullSaturday] = useState<boolean>(() => {
    if (MemberDetailsCxt.fullSaturday != null) {
      return true;
    } else {
      return MemberDetailsCxt.fullSaturday;
    }
  });

  const pasreToHours = async (value: number) => {
    let pasreToHoursTemp: PublicationDays[] = [];
    let FromHour = 0;
    let FromHourMinutes = 0;
    let ToHour = 0;
    let ToHourMinutes = 0;
    switch (value) {
      case 1: //א-ש 24 שעות
        FromHour = 0;
        FromHourMinutes = 0;
        ToHour = 23;
        ToHourMinutes = 59;
        pasreToHoursTemp.push(
          createOpenHourObj(
            true,
            true,
            true,
            true,
            true,
            fullFriday,
            fullSaturday,
            FromHour,
            FromHourMinutes,
            ToHour,
            ToHourMinutes,
            false
          )
        );

        if (halfFriday) {
          FromHour = 7;
          FromHourMinutes = 0;
          ToHour = 13;
          ToHourMinutes = 0;
          pasreToHoursTemp.push(
            createOpenHourObj(
              false,
              false,
              false,
              false,
              false,
              true,
              false,
              FromHour,
              FromHourMinutes,
              ToHour,
              ToHourMinutes,
              false
            )
          );
        }
        break;

      case 2: //א-ש 8:00 -20:00
        FromHour = 8;
        FromHourMinutes = 0;
        ToHour = 20;
        ToHourMinutes = 0;
        pasreToHoursTemp.push(
          createOpenHourObj(
            true,
            true,
            true,
            true,
            true,
            fullFriday,
            fullSaturday,
            FromHour,
            FromHourMinutes,
            ToHour,
            ToHourMinutes,
            false
          )
        );
        if (halfFriday) {
          FromHour = 8;
          FromHourMinutes = 0;
          ToHour = 13;
          ToHourMinutes = 0;
          pasreToHoursTemp.push(
            createOpenHourObj(
              false,
              false,
              false,
              false,
              false,
              true,
              false,
              FromHour,
              FromHourMinutes,
              ToHour,
              ToHourMinutes,
              false
            )
          );
        }
        break;
    }
    return pasreToHoursTemp;
  };

  const createOpenHourObj = (
    Day1: boolean,
    Day2: boolean,
    Day3: boolean,
    Day4: boolean,
    Day5: boolean,
    Day6: boolean,
    Day7: boolean,
    FromHour: number,
    FromHourMinutes: number,
    ToHour: number,
    ToHourMinutes: number,
    isDelete: boolean
  ) => {
    let objId = "-1";

    const newObj = {
      Day1: Day1,
      Day2: Day2,
      Day3: Day3,
      Day4: Day4,
      Day5: Day5,
      Day6: Day6,
      Day7: Day7,
      FromHour: FromHour,
      FromHourMinutes: FromHourMinutes,
      Id: objId,
      ToHour: ToHour,
      ToHourMinutes: ToHourMinutes,
      isDelete: false,
    };

    return newObj;
  };

  return (
    <CustomModal
      pageName={"BusinessPublicationDays"}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
      headerParameters={headerParameters}
      title="השעות שבהן העסק יפורסם באתר 144B"
      subTitle="יש לבחור מתבנית קיימת את שעות הפרסום"
    >
      <CustomForm onSubmit={handleSubmit(saveHandler)} errors={errors}>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonItem color="light" lines="full" mode="md">
                <IonLabel position="stacked">שעות הפירסום</IonLabel>
                <IonSelect
                  color="light"
                  mode="md"
                  value={templateID}
                  interface="popover"
                  interfaceOptions={{
                    showBackdrop: false,
                  }}
                  placeholder="בחר מרשימה"
                  onIonChange={(e) => {
                    e.stopPropagation();
                    setTemplatId(e.detail.value);
                  }}
                >
                  {templateList &&
                    templateList.map((t) => {
                      return (
                        <IonSelectOption key={t.id} value={t.id}>
                          {t.description}
                        </IonSelectOption>
                      );
                    })}
                </IonSelect>
              </IonItem>
              <IonItem mode="md" lines="none" color="light">
                <IonCheckbox
                  className="ion-no-margin"
                  checked={fullFriday}
                  onIonChange={(e) => {
                    setFullFriday(e.detail.checked);
                  }}
                />
                <IonLabel className="ion-padding-start">
                  <p>שישי מלא</p>
                </IonLabel>
              </IonItem>
              <IonItem mode="md" lines="none" color="light">
                <IonCheckbox
                  className="ion-no-margin"
                  checked={halfFriday}
                  onIonChange={(e) => {
                    setHalfFriday(e.detail.checked);
                  }}
                />
                <IonLabel className="ion-padding-start">
                  <p>שישי עד שעה 13</p>
                </IonLabel>
              </IonItem>
              <IonItem mode="md" lines="none" color="light">
                <IonCheckbox
                  className="ion-no-margin"
                  checked={fullSaturday}
                  onIonChange={(e) => {
                    setFullSaturday(e.detail.checked);
                  }}
                />
                <IonLabel className="ion-padding-start">
                  <p>שבת</p>
                </IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>
          {/* {currentPublicationDays && currentPublicationDays.length > 0 && (
            <IonRow>
              <IonCol>
                <IonList>
                  <IonItem>
                    <IonCol>ימי פתיחה</IonCol>
                    <IonCol>פתיחה</IonCol>
                    <IonCol>סגירה </IonCol>
                  </IonItem>
                </IonList>
                {currentPublicationDays.map((openDay, index) => {
                  const openHour = getTimeFotmat(
                    openDay.FromHour!,
                    openDay.FromHourMinutes!
                  );
                  const closeHour = getTimeFotmat(
                    openDay.ToHour!,
                    openDay.ToHourMinutes!
                  );
                  const openDaysList = getOpenDays(openDay);
                  const myRef = createRef<HTMLIonItemSlidingElement>();
                  return (
                    <CustomItemSliding
                      onDelete={() => {
                        selectedOpenHourIndexRef.current = index;
                        selectedOpenRef.current = myRef.current;
                        deleteOpenHours();
                      }}
                      onEdit={() => {
                        setStartEditing(true);
                        selectedOpenHourIndexRef.current = index;
                        selectedOpenRef.current = myRef.current;
                      }}
                      key={index}
                      ref={myRef}
                    >
                      <IonItem
                        key={index}
                        onClick={() => {
                          setStartEditing(true);
                          selectedOpenHourIndexRef.current = index;
                          selectedOpenRef.current = myRef.current;
                        }}
                      >
                        <IonCol>{openDaysList}</IonCol>
                        <IonCol>{openHour}</IonCol>
                        <IonCol>{closeHour}</IonCol>
                      </IonItem>
                    </CustomItemSliding>
                  );
                })}
              </IonCol>
            </IonRow>
          )} */}
          {/* <IonRow>
            <IonCol>
              <IonButton
                onClick={() => {
                  setStartEditing(true);
                  selectedOpenHourIndexRef.current = null;
                }}
              >
                {"הוסף שעות פעילות"}
                <IonIcon icon={addOutline}></IonIcon>
              </IonButton>
            </IonCol>
          </IonRow> */}
          <IonRow className="ion-text-center">
            <IonCol>
              <CustomPublishButton title="שמור"></CustomPublishButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </CustomForm>
      {/* {startedEditing && (
        <HandlePublicationDays
          show={startedEditing}
          closeModal={() => {
            setStartEditing(false);
            selectedOpenRef.current?.closeOpened();
          }}
          onSave={(newOpenDaysArray) => {
            setStartEditing(false);
            selectedOpenRef.current?.closeOpened();
            currentPublicationDays = newOpenDaysArray;
          }}
          publicationDaysArray={currentPublicationDays!}
          editedopenHourIndex={selectedOpenHourIndexRef.current!}
        ></HandlePublicationDays>
      )} */}
    </CustomModal>
  );
};

export default BusinessPublicationDays;
