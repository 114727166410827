import React, { useState } from "react";
import { IonButton, IonPopover } from "@ionic/react";
import "./tipFromBezeq.scss";
import { isMobile } from "react-device-detect";
import { config } from "../../Constants";

const TipFromBezeq: React.FC<{
  tip: string;
  light?: boolean;
  pageName?: string;
  modal?: boolean;
}> = (props) => {
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  const colorStartHandler = () => {
    
    if(!!config.otef_israel){
      return "#1d8bc8";
    }
    switch (props.pageName) {
      case "BusinessOpenHours":
        return "rgba(66,163,255,1)";
      case "MemberInbox":
        return "#FA016D";
      case "Statistics":
        return "rgba(255,182,67,1)";
      case "MemberComments":
        return "rgba(55,211,202,1)";
      case "settings":
        return "#596164";
      case "help":
        return "#0BA360";
      default:
        return "rgba(66,163,255,1)";
    }
  };

  const colorStopHandler = () => {
    if(!!config.otef_israel){
      return "#1d8bc8";
    }
    switch (props.pageName) {
      case "BusinessOpenHours":
        return "rgba(144,235,255,1)";
      case "inbox":
        return "#DB41CF";
      case "Statistics":
        return "rgba(255,105,47,1)";
      case "MemberComments":
        return "rgba(153,233,161,1)";
      case "settings":
        return "#868F96";
      case "help":
        return "#3CBA92";
      default:
        return "rgba(144,235,255,1)";
    }
  };

  return (
    <React.Fragment>
      <IonPopover
        cssClass={`tips-popover ${props.light ? "light" : ""} ${
          props.modal && !isMobile ? "popover-desktop-popover" : ""
        }`}
        mode="md"
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        showBackdrop={false}
        onDidDismiss={() =>
          setShowPopover({ showPopover: false, event: undefined })
        }
      >
        <div
          onClick={() =>
            setShowPopover({ showPopover: false, event: undefined })
          }
        >
          <p className="large-p bold-text tip-title-wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 33 37"
              width="33"
              height="37"
            >
              <defs>
                <linearGradient
                  id="grd"
                  gradientUnits="userSpaceOnUse"
                  x1="163.199"
                  y1="1443.938"
                  x2="1667.687"
                  y2="-597.257"
                >
                  <stop offset="0" stopColor={colorStartHandler()} />
                  <stop offset="100" stopColor={colorStopHandler()} />
                </linearGradient>
              </defs>
              <path
                id="star-1"
                fillRule="evenodd"
                fill="url(#grd)"
                d="M20.07 7.34C20.67 10.35 21.54 12.56 22.69 13.97C23.84 15.39 25.3 16.16 27.06 16.28C25.33 16.71 23.87 17.53 22.69 18.77C21.51 20 20.64 21.57 20.07 23.46C19.78 21.51 19.04 19.95 17.87 18.77C16.69 17.59 14.98 16.76 12.74 16.28C14.89 16.3 16.6 15.53 17.87 13.97C19.14 12.41 19.87 10.2 20.07 7.34Z"
              />
              <path
                id="star-2"
                fillRule="evenodd"
                fill="url(#grd)"
                d="M9.19 7.3C9.49 8.64 9.92 9.63 10.5 10.26C11.08 10.89 11.81 11.24 12.7 11.29C11.83 11.48 11.09 11.85 10.5 12.4C9.91 12.96 9.47 13.66 9.19 14.5C9.04 13.63 8.67 12.93 8.08 12.4C7.49 11.88 6.63 11.51 5.5 11.29C6.58 11.3 7.44 10.96 8.08 10.26C8.71 9.56 9.08 8.58 9.19 7.3Z"
              />
              <path
                id="star-3"
                fillRule="evenodd"
                fill="url(#grd)"
                d="M12.74 19.9C12.89 20.91 13.11 21.65 13.4 22.12C13.69 22.6 14.06 22.85 14.5 22.9C14.06 23.04 13.7 23.31 13.4 23.73C13.1 24.14 12.89 24.67 12.74 25.3C12.67 24.65 12.48 24.12 12.19 23.73C11.89 23.33 11.46 23.06 10.9 22.9C11.44 22.9 11.87 22.64 12.19 22.12C12.51 21.6 12.69 20.86 12.74 19.9Z"
              />
            </svg>
            טיפ מבזק
          </p>
          <p>{props.tip}</p>
        </div>
      </IonPopover>

      {props.tip !== "" && (
        <IonButton
          className={"tips-container " + (props.light ? "light" : "")}
          onClick={(e: any) => {
            e.persist();
            dtlPushEvt({'event':'tips','eventInfo': {'ScreenName':'{{page path}}','user_action':'watch_tip_' + props.pageName}});
            setShowPopover({ showPopover: true, event: e });
          }}
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 33 37"
              width="33"
              height="37"
            >
              <defs>
                <linearGradient
                  id="grd"
                  gradientUnits="userSpaceOnUse"
                  x1="163.199"
                  y1="1443.938"
                  x2="1667.687"
                  y2="-597.257"
                >
                  <stop offset="0" stopColor={colorStartHandler()} />
                  <stop offset="100" stopColor={colorStopHandler()} />
                </linearGradient>
              </defs>
              <path
                id="container"
                className="shape-wrapper"
                d="M5.5 1L27.6 1C30.09 1 32.1 3.01 32.1 5.5L32.1 27.6C32.1 30.09 30.09 32.1 27.6 32.1L5.5 32.1C3.01 32.1 1 30.09 1 27.6L1 5.5C1 3.01 3.01 1 5.5 1Z"
              />
              <path
                id="arrow"
                className="shape-wrapper"
                d="M13.6 31.6L19.9 31.6C18.85 34.6 17.8 36.1 16.75 36.1C15.7 36.1 14.65 34.6 13.6 31.6Z"
              />
              <path
                id="star-1"
                fillRule="evenodd"
                fill="url(#grd)"
                d="M20.07 7.34C20.67 10.35 21.54 12.56 22.69 13.97C23.84 15.39 25.3 16.16 27.06 16.28C25.33 16.71 23.87 17.53 22.69 18.77C21.51 20 20.64 21.57 20.07 23.46C19.78 21.51 19.04 19.95 17.87 18.77C16.69 17.59 14.98 16.76 12.74 16.28C14.89 16.3 16.6 15.53 17.87 13.97C19.14 12.41 19.87 10.2 20.07 7.34Z"
              />
              <path
                id="star-2"
                fillRule="evenodd"
                fill="url(#grd)"
                d="M9.19 7.3C9.49 8.64 9.92 9.63 10.5 10.26C11.08 10.89 11.81 11.24 12.7 11.29C11.83 11.48 11.09 11.85 10.5 12.4C9.91 12.96 9.47 13.66 9.19 14.5C9.04 13.63 8.67 12.93 8.08 12.4C7.49 11.88 6.63 11.51 5.5 11.29C6.58 11.3 7.44 10.96 8.08 10.26C8.71 9.56 9.08 8.58 9.19 7.3Z"
              />
              <path
                id="star-3"
                fillRule="evenodd"
                fill="url(#grd)"
                d="M12.74 19.9C12.89 20.91 13.11 21.65 13.4 22.12C13.69 22.6 14.06 22.85 14.5 22.9C14.06 23.04 13.7 23.31 13.4 23.73C13.1 24.14 12.89 24.67 12.74 25.3C12.67 24.65 12.48 24.12 12.19 23.73C11.89 23.33 11.46 23.06 10.9 22.9C11.44 22.9 11.87 22.64 12.19 22.12C12.51 21.6 12.69 20.86 12.74 19.9Z"
              />
            </svg>
            <p className="bold-text">טיפ מבזק</p>
          </div>
        </IonButton>
      )}
    </React.Fragment>
  );
};

export default TipFromBezeq;
