// React
import React, { useEffect, useRef, useState } from "react";
import {
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { useForm } from "react-hook-form";
// Custom
import CustomModal from "../../utils/CustomElements/CustomModal/CustomModal";
import CustomPublishButton from "../../utils/CustomElements/CustomPublishButton";
import CustomForm from "../../utils/CustomElements/CustomForm";
import CustomInput from "../../utils/CustomElements/CustomInput";
import CustomAreYouSureAlert from "../../utils/CustomElements/CustomAreYouSureAlert";
// Context
import { HeaderParameters } from "../../../data/Header-Context";
import { PriceListItem } from "../../../data/PriceList-context";

const HandlePriceListItem: React.FC<{
  show: boolean;
  closeModal: () => void;
  onSave: (itemToUpdate: PriceListItem) => void;
  currentPriceListItem: PriceListItem | null | undefined;
}> = (props) => {
  const { handleSubmit, formState, errors, control } = useForm({
    mode: "onChange",
  });
  const { dirty } = formState;
  const [showAreYouSure, setShowAreYouSure] = useState(false);
  const [templateID, setTemplateID] = useState<string>("");
  const DescTextRef = useRef<HTMLIonInputElement>(null);
  const NoteTextRef = useRef<HTMLIonInputElement>(null);
  const minPriceTextRef = useRef<HTMLIonInputElement>(null);
  const maxPriceTextRef = useRef<HTMLIonInputElement>(null);
  const literalPriceTextRef = useRef<HTMLIonInputElement>(null);

  const [currentItem, setCurrentItem] = useState(() => {
    if (
      props.currentPriceListItem !== undefined &&
      props.currentPriceListItem !== null
    ) {
      return { ...props.currentPriceListItem };
    } else {
      const item: PriceListItem = {
        Id: -1,
        PriceListProductId: -1,
        PriceListId: -1,
        Title: "",
        AdditionalData1: "",
        MinPrice: 0,
        MaxPrice: 0,
        LiteralPrice: "",
      };
      return item;
    }
  });

  const saveHandler = async () => {
    const currentDescText = DescTextRef.current?.value;
    const currentNoteText = NoteTextRef.current?.value;
    const currentMinPriceText = Number(minPriceTextRef.current?.value);
    const currentMaxPriceText = Number(maxPriceTextRef.current?.value);
    const currentLiteralPriceText = literalPriceTextRef.current?.value;

    currentItem!.Title = currentDescText!.toString();
    currentItem!.AdditionalData1 = currentNoteText!.toString();
    if (templateID !== "") {
      currentItem!.MinPrice =
        templateID === "constant"
          ? currentMaxPriceText
          : isNaN(currentMinPriceText)
          ? 0
          : currentMinPriceText;
      currentItem!.MaxPrice = isNaN(currentMaxPriceText)
        ? 0
        : currentMaxPriceText;
      currentItem!.LiteralPrice = currentLiteralPriceText
        ? currentLiteralPriceText!.toString()
        : "";
    }
    props.onSave(currentItem);
  };
  const headerParameters: HeaderParameters = {
    isPageHaveChanges: dirty,
    modalType: "modal",
  };

  const dropdownTemplateFunc = (e: CustomEvent) => {
    setTemplateID(e.detail.value);
  };

  const priceCalc = () => {
    if (currentItem.LiteralPrice) {
      setTemplateID("constant");
    } else {
      if (currentItem.MinPrice && currentItem.MaxPrice) {
        if (currentItem.MinPrice === currentItem.MaxPrice) {
          setTemplateID("constant");
        }
        if (currentItem.MinPrice !== currentItem.MaxPrice) {
          setTemplateID("range");
        }
      } else if (!currentItem.MinPrice && currentItem.MaxPrice) {
        setTemplateID("until");
      } else if (currentItem.MinPrice && !currentItem.MaxPrice) {
        setTemplateID("startingFrom");
      }
    }
  };

  React.useEffect(() => {
    priceCalc();
  }, [currentItem]);

  return (
    <CustomModal
      pageName={"HandlePriceListItem"}
      headerParameters={headerParameters}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
      title="הקמה ועדכון מוצר למחירון"
    >
      <CustomForm onSubmit={handleSubmit(saveHandler)} errors={errors}>
        <IonGrid className="ion-no-padding ion-padding-horizontal">
          <IonRow className="ion-justify-content-between">
            <IonGrid>
              <IonRow>
                <IonCol className="ion-padding-vertical">
                  <React.Fragment>
                    <IonRow>
                      <IonCol>
                        <CustomInput
                          name="Description"
                          errors={errors}
                          control={control}
                          label="תיאור"
                          required={true}
                          type="text"
                          value={currentItem.Title}
                          ref={DescTextRef}
                          maxlength={50}
                          disabled={
                            currentItem.PriceListProductId === -1 ? false : true
                          }
                        />
                      </IonCol>
                    </IonRow>

                    <IonRow>
                      <IonCol>
                        <CustomInput
                          name="Note"
                          errors={errors}
                          control={control}
                          label="הערה"
                          required={false}
                          type="text"
                          disabled={
                            currentItem.PriceListProductId === -1 ? false : true
                          }
                          value={currentItem.AdditionalData1}
                          ref={NoteTextRef}
                          maxlength={50}
                        />
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol className="ion-padding-vertical">
                        <IonItem color="light" lines="full" mode="md">
                          <IonLabel position="stacked">תבנית מחיר</IonLabel>
                          <IonSelect
                            color="light"
                            cancelText="ביטול"
                            okText="אישור"
                            aria-required={true}
                            value={templateID}
                            placeholder={"בחר תבנית מחיר"}
                            onIonChange={(e) => {
                              dropdownTemplateFunc(e);
                            }}
                          >
                            <IonSelectOption value={"constant"}>
                              {"מחיר קבוע"}
                            </IonSelectOption>
                            <IonSelectOption value={"range"}>
                              {"טווח מחירים"}
                            </IonSelectOption>
                            <IonSelectOption value={"startingFrom"}>
                              {"החל מ"}
                            </IonSelectOption>
                            <IonSelectOption value={"until"}>
                              {"עד מחיר"}
                            </IonSelectOption>
                          </IonSelect>
                        </IonItem>
                      </IonCol>
                    </IonRow>

                    {templateID === "constant" && (
                      <div className="input-row-group">
                        <CustomInput
                          label="מחיר"
                          required={true}
                          name="fromtime"
                          placeholder="הכנס מחיר"
                          ref={maxPriceTextRef}
                          control={control}
                          errors={errors}
                          value={
                            currentItem.MinPrice === currentItem.MaxPrice
                              ? currentItem.MinPrice
                              : ""
                          }
                          onIonChange={(e) => {}}
                        />
                      </div>
                    )}
                    {templateID === "range" && (
                      <div className="input-row-group">
                        <CustomInput
                          label="מינימום"
                          required={true}
                          name="fromtime"
                          placeholder="הכנס מחיר מינימלי"
                          ref={minPriceTextRef}
                          control={control}
                          errors={errors}
                          value={
                            currentItem.MinPrice !== currentItem.MaxPrice
                              ? currentItem.MinPrice
                              : ""
                          }
                          onIonChange={(e) => {}}
                        />
                        <CustomInput
                          label="מקסימום"
                          required={true}
                          name="totime"
                          placeholder="הכנס מחיר מקסימלי"
                          value={
                            currentItem.MinPrice !== currentItem.MaxPrice
                              ? currentItem.MaxPrice
                              : ""
                          }
                          ref={maxPriceTextRef}
                          control={control}
                          errors={errors}
                          onIonChange={(e) => {}}
                        />
                      </div>
                    )}
                    {templateID === "startingFrom" && (
                      <div className="input-row-group">
                        <CustomInput
                          label="החל מ"
                          required={true}
                          name="fromtime"
                          placeholder="הכנס מחיר התחלתי"
                          ref={minPriceTextRef}
                          control={control}
                          errors={errors}
                          value={
                            !currentItem.MaxPrice && currentItem.MinPrice
                              ? currentItem.MinPrice
                              : ""
                          }
                          onIonChange={(e) => {}}
                        />
                      </div>
                    )}
                    {templateID === "until" && (
                      <div className="input-row-group">
                        <CustomInput
                          label="עד"
                          required={true}
                          name="until"
                          placeholder="הכנס מחיר מקסימלי"
                          ref={maxPriceTextRef}
                          control={control}
                          errors={errors}
                          value={
                            !currentItem.MinPrice && currentItem.MaxPrice
                              ? currentItem.MaxPrice
                              : ""
                          }
                          onIonChange={(e) => {}}
                        />
                      </div>
                    )}
                  </React.Fragment>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonGrid className="accordion-wrapper">
                <CustomAreYouSureAlert
                  cancelFunc={() => {
                    setShowAreYouSure(false);
                  }}
                  continueFunc={() => {
                    setShowAreYouSure(false);
                  }}
                  isOpen={showAreYouSure}
                  message={"האם אתה בטוח? במידה ותעבור תבנית כל התוכן יאבד"}
                ></CustomAreYouSureAlert>
              </IonGrid>
            </IonCol>
          </IonRow>

          <IonRow className="ion-justify-content-end">
            <IonCol sizeMd="2">
              <CustomPublishButton
                title="שמירה"
                disabled={templateID === "" && currentItem.Id! < 0}
              ></CustomPublishButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </CustomForm>
    </CustomModal>
  );
};

export default HandlePriceListItem;
