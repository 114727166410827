import React, { useContext, useEffect, useRef, useState } from 'react'

import {
  IonButton,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonGrid,
  IonIcon,
  IonItem,
  IonList,
  IonRow,
} from '@ionic/react'
import CustomAlert from '../../components/utils/CustomElements/CustomAlert'
import CoSaleContext, { CoSaleSubject } from '../../data/CoSale-Context'

import CustomPage from '../../components/utils/CustomElements/CustomPage'
import { HeaderParameters } from '../../data/Header-Context'
import './CoSaleContentPages/B144/b144.scss'
import './coSale.scss'

import {
  chevronBackOutline,
  chevronForwardOutline,
  menuOutline,
} from 'ionicons/icons'
import { useParams } from 'react-router'
import { setTimeout } from 'timers'
import { config } from '../../Constants'
import CoSaleGeneralPage from './coSaleGeneralPage'
import CoSaleItem from './coSaleItem'

const CoSale: React.FC = () => {
  const ctx = useContext(CoSaleContext)

  const [currentItemIndex, setCurrentItemIndex] = useState(0)
  const [currentSubjectIndex, setCurrentSubjectIndex] = useState(0)
  const [cobrowseCode, setCobrowseCode] = useState('')
  const [cobrowseSessionState, setCobrowseSessionState] = useState<
    'new' | 'active' | 'ended'
  >('new')
  const [showAlert, setShowAlert] = useState(false)
  const [error, setError] = useState('')
  const contentRef = useRef<HTMLIonContentElement | null>(null)

  let token = useParams<{ token: string }>().token
  if (!token) {
    const params = new URLSearchParams(
      decodeURIComponent(window.location.search)
    )
    token = params.get('t') || ''
  }
  useEffect(() => {
    if (token) {
      token = token.replace(/ /g, '+')

      ctx.getCoSaleByToken(token).then((res: any) => {
        if (res) {
          InitCobrowseSession()
        }
      })
    }
  }, [])

  const cobrowseSessionActive = (isActive: boolean) => {
    if (isActive) {
      setCobrowseSessionState('active')
    } else {
      setCobrowseSessionState('ended')
      setCurrentSubjectIndex(0)
      setCurrentSubjectIndex(0)
      InitCobrowseSession()
    }
  }

  const InitCobrowseSession = () => {
    showSessionCode(
      (code: any) => {
        setCobrowseCode(code)
      },
      cobrowseSessionActive,
      config.url.API_URL
    )
  }
  const ErrorMessageButton = [
    {
      text: 'אישור',
      role: 'ok',
      handler: () => {
        setShowAlert(false)
        setError('')
        // window.location.reload(false);
      },
    },
  ]

  const headerProps: HeaderParameters = {
    isShowLogo: false,
    isPageHaveChanges: false,
    isShowHamburger: false,
    backButton: { isShowMobile: false, isShowDesktop: false },
    isShowDeleteButton: false,
    isImagePreview: false,
    isCloseModalBtn: false,
    isShowEditButton: false,
    isLogin: true,
  }

  const scrollTop = () => {
    if (
      currentItemIndex >= 0 &&
      ctx.coSaleSubjects![currentSubjectIndex].Items
    ) {
      if (ctx.coSaleSubjects![currentSubjectIndex].Items[currentItemIndex]) {
        if (
          ctx.coSaleSubjects![currentSubjectIndex].Items[currentItemIndex]
            .Type == 'Alert'
        ) {
          return
        }
      }
    }
    setTimeout(() => {
      if (contentRef.current) {
        contentRef.current.scrollToTop()
      }
    }, 50)
  }

  const getPageTitle = () => {
    if (cobrowseSessionState == 'new') {
      return 'מייד מתחילים'
    }
    let title = ctx.coSaleSubjects![currentSubjectIndex].Title
    if (
      currentItemIndex >= 0 &&
      ctx.coSaleSubjects![currentSubjectIndex].Items
    ) {
      if (ctx.coSaleSubjects![currentSubjectIndex].Items[currentItemIndex]) {
        if (
          ctx.coSaleSubjects![currentSubjectIndex].Items[currentItemIndex].Title
        ) {
          title +=
            ' - ' +
            ctx.coSaleSubjects![currentSubjectIndex].Items[currentItemIndex]
              .Title
        }
      }
    }
    return title
  }

  return (
    <CustomPage
      headerParameters={headerProps}
      title=""
      // subTitle={'שלום חבר, בוא נמכור לך כמה מוצרי איכות'}
      pageName="CoSale"
    >
      <>
        <CustomAlert
          isOpen={showAlert}
          header={'הודעת מערכת'}
          message={error}
          onDismiss={() => {
            setShowAlert(false)
            setError('')
            //window.location.reload(false);
          }}
          buttons={ErrorMessageButton}
        ></CustomAlert>
        <div
          style={{
            padding: '10px 20px',
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <button
            onClick={() => setCurrentItemIndex(currentItemIndex + 1)}
            style={{ fontWeight: 'bold', fontSize: 24, background: 'none' }}
          >
            {'<'}
          </button>
          <button
            onClick={() => setCurrentItemIndex(currentItemIndex - 1)}
            style={{ fontWeight: 'bold', fontSize: 24, background: 'none' }}
          >
            {'>'}
          </button>
        </div>

        {ctx.coSaleSubjects && (
          <>
            {cobrowseSessionState == 'active' && (
              <IonFab
                slot="fixed"
                vertical="top"
                horizontal="start"
                className="co-sale-menu"
              >
                <IonFabButton color="transparent" className="co-sale-menu-btn">
                  <IonIcon icon={menuOutline}></IonIcon>
                </IonFabButton>
                <IonFabList side="bottom">
                  <IonList>
                    {ctx.coSaleSubjects.map((subj: CoSaleSubject, index) => {
                      return (
                        subj.Type != 'General' && (
                          <IonItem key={index}>
                            <IonButton
                              fill="clear"
                              style={{
                                fontWeight:
                                  currentSubjectIndex == index
                                    ? 'bold'
                                    : 'normal',
                              }}
                              key={subj.Type}
                              title={subj.Title}
                              onClick={() => {
                                setCurrentItemIndex(0)
                                setCurrentSubjectIndex(index)
                                scrollTop()
                              }}
                            >
                              <IonIcon
                                icon={
                                  subj.Icon ? subj.Icon : chevronForwardOutline
                                }
                              ></IonIcon>
                              {subj.Title}
                            </IonButton>
                          </IonItem>
                        )
                      )
                    })}
                  </IonList>
                </IonFabList>
              </IonFab>
            )}

            <div className="co-sale-header">
              {cobrowseSessionState == 'active'
                ? getPageTitle()
                : 'מוצרים בהתאמה אישית'}
            </div>
            <IonContent className="co-sale-content" ref={contentRef}>
              <IonGrid fixed={true} className="cosale-wrapper">
                {ctx.coSaleSubjects && ctx.coSaleSubjects.length > 0 && (
                  <IonRow>
                    <IonCol>
                      {ctx.coSaleSubjects[currentSubjectIndex].Type ==
                        'General' || cobrowseSessionState == 'ended' ? (
                        <CoSaleGeneralPage
                          cobrowseSessionState={cobrowseSessionState}
                          clientName={ctx.clientName}
                          coSaleSubjects={ctx.coSaleSubjects}
                          onSubjectClicked={(index: number) => {
                            setCurrentItemIndex(0)
                            setCurrentSubjectIndex(index)
                            scrollTop()
                          }}
                          cobrowseCode={cobrowseCode}
                          SubType={
                            ctx.coSaleSubjects[currentSubjectIndex].SubType!
                          }
                        />
                      ) : (
                        <CoSaleItem
                          currentItemIndex={currentItemIndex}
                          currentSubject={
                            ctx.coSaleSubjects[currentSubjectIndex]
                          }
                        ></CoSaleItem>
                      )}
                    </IonCol>
                  </IonRow>
                )}
              </IonGrid>
            </IonContent>
            {cobrowseSessionState == 'active' && currentSubjectIndex != null && (
              <div className="co-sale-footer">
                <IonRow>
                  <IonCol className="ion-text-start">
                    <IonButton
                      fill="clear"
                      disabled={
                        currentItemIndex >=
                          ctx.coSaleSubjects![currentSubjectIndex].Items
                            .length -
                            1 &&
                        currentSubjectIndex >= ctx.coSaleSubjects!.length - 1
                      }
                      onClick={() => {
                        if (
                          currentItemIndex <
                          ctx.coSaleSubjects![currentSubjectIndex].Items
                            .length -
                            1
                        ) {
                          setCurrentItemIndex((curr) => {
                            const new_indx = curr + 1
                            return new_indx
                          })
                          scrollTop()
                        } else if (
                          currentSubjectIndex <
                          ctx.coSaleSubjects!.length - 1
                        ) {
                          setCurrentSubjectIndex((curr) => {
                            const new_indx = curr != null ? curr + 1 : 0
                            setCurrentItemIndex(0)
                            return new_indx
                          })
                          scrollTop()
                        }
                      }}
                    >
                      <IonIcon
                        slot="icon-only"
                        color="primary"
                        icon={chevronForwardOutline}
                      ></IonIcon>
                    </IonButton>
                  </IonCol>
                  <IonCol className="ion-text-end">
                    <IonButton
                      fill="clear"
                      disabled={
                        currentItemIndex <= 0 && currentSubjectIndex <= 0
                      }
                      onClick={() => {
                        if (currentItemIndex > 0) {
                          setCurrentItemIndex((curr) => {
                            let new_indx = curr - 1
                            //check if prev item is alert
                            //if alert skip to next page item
                            //we dont show alerts when going back
                            if (ctx.coSaleSubjects) {
                              while (
                                ctx.coSaleSubjects[currentSubjectIndex].Items[
                                  new_indx
                                ].Type == 'Alert'
                              ) {
                                new_indx = new_indx - 1
                              }
                            }
                            return new_indx
                          })
                          scrollTop()
                        } else if (currentSubjectIndex > 0) {
                          setCurrentSubjectIndex((curr) => {
                            const new_indx = curr != null ? curr - 1 : 0
                            var last_page_index =
                              ctx.coSaleSubjects![new_indx].Items.length - 1
                            setCurrentItemIndex(last_page_index)
                            return new_indx
                          })
                          scrollTop()
                        }
                      }}
                    >
                      <IonIcon
                        slot="icon-only"
                        color="primary"
                        icon={chevronBackOutline}
                      ></IonIcon>
                    </IonButton>
                  </IonCol>
                </IonRow>
              </div>
            )}
          </>
        )}
      </>
    </CustomPage>
  )
}
export default CoSale
