import { config } from "../Constants";

export const coupon = require("./coupons/couponPresent.jpg");
export const covidLogo = require("./logos/covid-logo.png");
export const googleLogo = require("./logos/google.png");
export const googleAndB144 = require("./logos/google-b144.png");
export const logoMobile = require("./logos/logob-144-mobile.svg");
export const logoNegative = require("./logos/logob-144-mobile-negative.svg");
export const logoOrangeHollow = require("./logos/logo-b-144-orange-hollow.svg");
export const logoNegativeWhite = require("./logos/logob-144-mobile-white.svg");
export const b144LogoBlue = require("./logos/logob-144-mobile-negative-blue.svg");
export const b144Logo_503 = require("./logos/503.svg");
export const logoRuahTova = require("./logos/iconLogoRuahTova.svg");
export const logoRuahTova_black = require("./logos/logo_RT_black.svg");
export const logo_favicon_blue = require("./logos/favicon.ico");
export const logo_B144_war_white = require("./logos/B144_war_white.svg");
export const logo_B144_war_blue = require("./logos/B144_war_blue.svg");


export const trash = require("./icons/delete.svg");
export const trashDark = require("./icons/trash-dark.svg");
export const businessCardWhite = require("./icons/business-card-white.svg");
export const cupon = require("./icons/cupon.svg");
export const product = require("./icons/product.svg");
export const reviewWhite = require("./icons/review-white.svg");
export const rating = require("./icons/your_rating.svg");
export const statisticsWhite = require("./icons/statistics-white.svg");
export const update = require("./icons/update.svg");
export const picture = require("./icons/picture.svg");
export const home = config.otef_israel ? require("./otef_icons/home.svg") : require("./icons/home.svg");
export const businessCard = config.otef_israel ? require("./otef_icons/business-card.svg") :require("./icons/business-card.svg");
export const help = require("./icons/help.svg");
export const inbox = require("./icons/inbox.svg");
export const inboxWhite = require("./icons/inbox-white.svg");
export const review = config.otef_israel ? require("./otef_icons/review.svg") : require("./icons/review.svg");
export const shopping = require("./icons/shopping-cart.svg");
export const statistics = require("./icons/statistics.svg");
export const settings = require("./icons/settings.svg");
export const settingsBlack = require("./icons/settings-black.svg");
export const onlineWebsiteBuilder = require("./icons/online-website-builder.svg");
export const laptop = require("./icons/laptop.svg");
export const exit = require("./icons/exit.svg");
export const iconEditWhite = require("./icons/icon-edit-white.svg");
export const send = require("./icons/send.svg");
export const pencil = require("./icons/pencil.svg");
export const document = config.otef_israel ? require("./otef_icons/document.svg") : require("./icons/document.svg");
export const website = config.otef_israel ? require("./otef_icons/website.svg") : require("./icons/website.svg");
export const landing_page = config.otef_israel ? require("./otef_icons/landing_page.svg") : require("./icons/landing_page.svg");
export const languages = config.otef_israel ? require("./otef_icons/languages.svg") : require("./icons/languages.svg");
export const main_category = config.otef_israel ? require("./otef_icons/main_category.svg") : require("./icons/main_category.svg");
export const mail = config.otef_israel ? require("./otef_icons/mail.svg") : require("./icons/mail.svg");
export const activity_time = config.otef_israel ? require("./otef_icons/activity_time.svg") : require("./icons/activity_time.svg");
export const ecommerce_site = config.otef_israel ? require("./otef_icons/ecommerce_site.svg") : require("./icons/ecommerce_site.svg");
export const links = config.otef_israel ? require("./otef_icons/links.svg") : require("./icons/links.svg");
export const promotional_video = config.otef_israel ? require("./otef_icons/promotional_video.svg") : require("./icons/promotional_video.svg");
export const youtube_channel = config.otef_israel ? require("./otef_icons/youtube_channel.svg") : require("./icons/youtube_channel.svg");
export const faq = config.otef_israel ? require("./otef_icons/faq.svg") : require("./icons/faq.svg");
export const price_list = require("./icons/price_list.svg");
export const menu_rest = require("./icons/menu_r.svg");

export const phone = config.otef_israel ? require("./otef_icons/phone.svg") : require("./icons/phone.svg");
export const adress = config.otef_israel ? require("./otef_icons/adress.svg") : require("./icons/adress.svg");
export const edit = config.otef_israel ? require("./otef_icons/edit.svg") : require("./icons/edit.svg");
export const colors = require("./icons/colors.svg");
export const heart = config.otef_israel ? require("./otef_icons/heart-blue.svg") : require("./icons/heart-blue.svg");
export const alert = require("./icons/alert.svg");
export const replaceImageWhite = require("./icons/replace-image-white.svg");
export const closeBlack = require("./icons/close-black.svg");
export const imageBlack = require("./icons/image-black.svg");
export const imageWhite = require("./icons/image-white.svg");
export const notificationsOn = require("./icons/notifications-on.svg");
export const notificationsOff = require("./icons/notifications-off.svg");
export const download = require("./icons/download.svg");
export const downloadBlack = require("./icons/download-black.svg");
export const upload = require("./icons/upload.svg");
export const uploadBlack = require("./icons/upload-black.svg");
export const mailOpenBlue = require("./icons/mail_open.svg");
export const menu = require("./icons/menu.svg");
export const avatar = require("./icons/avatar.svg");
export const arrowBack = require("./icons/arrowBack.svg");
export const serviceAreas = config.otef_israel ? require("./otef_icons/icon-service-area.svg") : require("./icons/icon-service-area.svg");
export const serviceAreaConfirm = require("./icons/service-area-confirm.svg");
export const budget = require("./icons/budget.svg");
export const budget_white = require("./icons/budget_white.svg");
export const excel = require("./icons/excel.svg");
export const whatsapp = require("./icons/whatsapp.svg");

export const blankImageIcon = require("./icons/blank-image-icon.svg");
export const blankProductIcon = require("./icons/blank-product-icon.svg");
export const blankProductIconOrange = require("./icons/blank-orange-product-icon.svg");
export const blankProductIconGreen = require("./icons/blank-green-product-icon.svg");
export const blankProductIconRed = require("./icons/blank-red-product-icon.svg");

export const imagePlaceholder = require("./general/placeholder_image.jpg");

export const facebook = require("./icons/Facebook.svg");
export const instegram = require("./icons/instegram.svg");
export const google = require("./icons/google.svg");
export const taboola = require("./icons/taboola.svg");
export const sms = require("./icons/sms-white.svg");
export const wolt = require("./icons/wolt-icon.svg");
export const tiktok = require("./icons/tiktok-icon.svg");
export const youtube = require("./icons/youtube-icon.png");


// statistics icons

export const donut_small = require("./icons/donut_small.svg");
export const fact_check = require("./icons/fact_check.svg");
export const important_devices = require("./icons/important_devices.svg");
export const touch_app = require("./icons/touch_app.svg");
export const ring_volume = require("./icons/ring_volume.svg");
export const whatsapp_click = require("./icons/wtsp_24px.svg");
export const myAssets = require("./icons/my-assets.svg");
export const landingPage = require("./icons/landing-page.svg");
export const leadsAndTelephones = require("./icons/leads-and-telephones.svg");
export const myCampaigns = require("./icons/my-campaigns.svg");
export const eCommerce = require("./icons/icon_WebSite.svg");
export const arrowDown = require("./icons/arrowDown.svg");
export const arrowRightFilled = require("./icons/arrowRightFilled.svg");
export const arrowLeftEmpty = require("./icons/arrowLeftEmpty.svg");
export const arrowLeftFilled = require("./icons/arrowLeftFilled.svg");
export const arrowLeftWhite = require("./icons/arrowLeftWhite.svg");
export const calendar = require("./icons/calendar.svg");
export const exclamationMark = require("./icons/exclamationMark.svg");
export const graph = require("./icons/graph-icon.svg");
export const distrib = require("./icons/distrib-icon.svg");
export const search = require("./icons/search-icon.svg");
export const megaphone = require("./icons/megaphone.svg");
export const ListIcon = require("./icons/lists-icon.svg");
export const information = require("./icons/information-icon.svg");


// inbox icons

export const inboxCustomSender = require("./icons/inbox-custom-sender.svg");

// user settings

export const ballot = require("./icons/ballot.svg");
export const sentimentSatisfied = require("./icons/sentiment_satisfied.svg");
export const notifications = require("./icons/notifications.svg");
export const markunreadMailbox = require("./icons/markunread_mailbox.svg");

// help

export const facebookWhite = require("./icons/facebook-white.svg");
export const googleWhite = require("./icons/google-white.svg");
export const taboola_white = require("./icons/taboola_white.svg");
export const helpCenter = require("./icons/help_center.svg");
export const message = require("./icons/message.svg");
export const contact_us = require("./icons/contact_us.svg");
export const talk_to_us = require("./icons/talk_to_us.svg");
export const supportAgent = require("./icons/support_agent.svg");
export const support = require("./icons/support.svg");
export const support_site = require("./icons/support_site.svg");
export const twitter_new_black= require("./icons/twitter_new.svg");
export const twitter_new_white= require("./icons/twitter_new_white.svg");

export const articles = require("./icons/articles.svg");
export const campaigns = require("./icons/campaigns.svg");
export const chatbot = require("./icons/chatbot.svg");

export const tip1 = require("./tips/app_tip_sale.jpg");
export const tip2 = require("./tips/app_tip_stars.jpg");

export const welcomeNewUser = require("./banners/V2_PopAnimation_D_500x330_whiteBG.gif");
export const welcomeNewUserMob = require("./banners/V2_PopAnimation_M_300x300_whiteBG.gif");

// pdf qr

export const mobile = require("./icons/icon-mobile.svg");
export const payAttention = require("./icons/icon-pay-attention.svg");
export const qr = require("./icons/icon-qr.svg");
export const print = require("./icons/icon-print.svg");
export const printWhite = require("./icons/icon-print-white.svg");
export const mobileWhite = require("./icons/icon-mobile-white.svg");
export const qrWhite = require("./icons/QR ICON-white.svg");

//general
export const successBlue = require("./icons/success_blue.svg");
export const successGreen = require("./icons/success_green.svg");
export const successOrange = require("./icons/success_orange.svg");
export const successPink = require("./icons/success_pink.svg");
export const failOrange = require("./icons/fail_orange.svg");
//vas
export const vas = require("./icons/vas.svg");
export const vas_white = require("./icons/vas_white.svg");
export const vas_facebook_exist = require("./icons/vas_facebook_white.svg");
export const vas_facebook_select = require("./icons/vas_facebook_blue.svg");

export const vas_google_exist = require("./icons/vas_google_white.svg");
export const vas_google_select = require("./icons/vas_google_blue.svg");

export const vas_website_exist = require("./icons/vas_website_white.svg");
export const vas_website_select = require("./icons/vas_website_blue.svg");

export const vas_ecommerce_exist = require("./icons/vas_ecommerce_white.svg");
export const vas_ecommerce_select = require("./icons/vas_ecommerce_blue.svg");

export const vas_landing_page_exist = require("./icons/vas_landing_page_white.svg");
export const vas_landing_page_select = require("./icons/vas_landing_page_blue.svg");

//self sale
export const supportCall = require("./icons/support-call.svg");
export const b144VideoBcg = require("./general/b144VideoBcg.png");
export const googleVideoBcg = require("./general/googleVideoBcg.png");
export const phoneWhite = require("./icons/phoneWhite.png");
export const supportImage = require("./general/support-image.png");
export const infoIcon = require("./icons/icon-info.svg");
export const questionMarkIcon = require("./icons/questionMark.png");
export const arrowLeftRed = require("./icons/arrow-left-red.svg");
export const logoB144Party = require("./logos/Logo_B144_Party_320x274.gif");
export const logoTopper = require("./logos/logo-topper.png");
export const logoRotter = require("./logos/logo-rotter.png");
export const logoHomeless = require("./logos/logo-homeless.png");
export const logoOne = require("./logos/logo-one.png");
export const logoWalla = require("./logos/logo-walla.png");
export const logoYad2 = require("./logos/logo-yad-2.png");
export const logoChannel9 = require("./logos/logo-channel-9.png");
export const logoBipbip = require("./logos/logo-bipbip.png");
export const logoGooglePartner = require("./logos/logo-google-partner.png");
export const logoGoogleAds = require("./logos/logo-google-ads.png");
export const qotes = require("./general/qotes.png");
export const reasonsAppIcon = require("./general/app-icon.svg");
export const reasonsKnowledgeIcon = require("./general/knowledge-icon.svg");
export const reasonsBudgetIcon = require("./general/budget-icon.svg");
export const reasonsExposureIcon = require("./general/exposure-icon.svg");