import { IonButton } from '@ionic/react';
import React, { CSSProperties, useEffect, useState } from 'react';
import { CirclePicker, Color, ColorResult, HSLColor, PhotoshopPicker, SketchPicker, SliderPicker } from 'react-color';

import { EditableInput, Hue, Saturation } from 'react-color/lib/components/common';
import { EditableInputStyles } from 'react-color/lib/components/common/EditableInput';
import { validatePatterns } from '../../../utils-ts/GlobalValidationFunctions';
import CustomModal from './CustomModal/CustomModal';

export interface CustomColorPickerParams {
  title?: string;
  name: string;
  value: string;
  presetColors?: string[] | undefined;
  hsl?: HSLColor;
}
const CustomColorPicker: React.FC<{
  show: boolean;
  closeModal: () => void;
  params: CustomColorPickerParams;
  onColorSelected: (color: string, name: string) => void;
}> = (props) => {
  const [selectedColor, setSelectedColor] = useState(props.params.value);
  const [error, setError] = useState('');

  const handleChangeComplete = (color: any, event: React.ChangeEvent<HTMLInputElement>) => {
    //console.log(color);
    setError('');
    if (color.hex) {
    //  setSelectedColor(color.hex);
      const validate_color_error = validatePatterns(color.hex,'color');
      if(validate_color_error){
      setError(validate_color_error);
    } else{
      setSelectedColor(color.hex);
    }
    }
  };
  
  
  return (
    <CustomModal
      pageName='ColorPicker'
      skipPageTips = {true}
      backdropDismiss = {true}
      headerParameters={{
        isHeaderDark: false,
        modalType: 'modal',
        isShowDeleteButton: false,
        toolbarTitle: props.params.title
      }}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
      title={undefined}>
      <>
        <div className="dv_color_picker_cont">
          <div className="dv_color_blocks">
            {props.params.presetColors &&
              props.params.presetColors.map((color, i) => (
                <IonButton
                  className={`btn-color-block ${color == selectedColor && 'selected'}`}
                  style={{ backgroundColor: color, color: color }}
                  key={i}
                  color={color}
                  onClick={(e: any) => handleChangeComplete({ hex: color }, e)}>
                  {color}
                </IonButton>
              ))}
          </div>
          <div className="dv_color_picker">
            <div className="dv_color_selector">
              
               <SketchPicker 
               color={selectedColor} 
               onChangeComplete={handleChangeComplete} 
               disableAlpha={true} 
               presetColors={[]} /> 
            </div>
            <div className="dv_color_panel">
              <div className="dv_color_preview" style={{ backgroundColor: selectedColor }}></div>
              <div className="dv_color_input">
                <EditableInput 
                // style={error ? inputStyles : undefined}
                value={selectedColor} 
                onChange={(color:any,e: any) => 
                handleChangeComplete({ hex: color }, e)
                } />
              </div>
            </div>
          </div>
        </div>
        <div className="ion-text-center">
        <IonButton
          size="small"
          type="button"
          className="main-button color-main-button"
          onClick={() => {
            props.onColorSelected(selectedColor, props.params.name);
          }}>
          אישור
        </IonButton></div>
      </>
    </CustomModal>
  );
};

export default CustomColorPicker;
