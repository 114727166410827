import { IonButton, IonIcon } from "@ionic/react";
import React from "react";
import { CoSaleSubject } from "../../data/CoSale-Context";

const CoSaleGeneralPage: React.FC<{
  clientName: string;
  coSaleSubjects: CoSaleSubject[];
  onSubjectClicked: (index: number) => void;
  cobrowseCode: string;
  SubType: "Start" | "Finish";
  cobrowseSessionState: 'new' | 'active' | 'ended';
}> = (props) => {
  return (
    <>
    {(props.cobrowseSessionState == 'new' || props.cobrowseSessionState == 'ended') && 
    <div className="ion-text-center ion-padding">
    {props.cobrowseCode ?
    <>
     <p className="ion-text-center" style={{marginTop:35, fontSize:18}}>
            הקוד שלך הינו: <b>{props.cobrowseCode}</b>
          </p>
          <p className="ion-padding">אנא הקרא אותו לנציג ולאחר מכן אשר את הפעלת המצגת</p></> : <p>מייד מתחילים</p>
          }
          <IonButton fill="clear" className="ion-margin-top" onClick={()=> {
            window.location.reload();
          }}><u>צור קוד חדש</u></IonButton>
      </div>}

      {props.cobrowseSessionState == 'active' && props.SubType == "Start" && (
        <div className=" co-sale-start">
         
          <p className="ion-margin-top">אנחנו כאן כדי להציג בפניכם מוצרים דיגיטליים המותאמים אישית לתחום העסק שלכם ולאזורי הפרסום הספציפיים שלו,  מה שיאפשר לכם לשדרג את הנוכחות הדיגיטלית שלכם ולהגיע ללקוחות חדשים בדרך היעילה ביותר.</p>
          <p className="ion-margin-vertical">אז בואו נתחיל</p>
          <div>
            {props.coSaleSubjects.map((subj: CoSaleSubject, index: number) => {
              return (
                subj.Type != "General" && (
                  <IonButton
                    slot="start"
                    size="large"
                    key={subj.Type}
                    title={subj.Title}
                    onClick={() => {
                      props.onSubjectClicked(index);
                    }}
                  >
                    <span className="co-sale-main-btn">
                      <IonIcon icon={subj.Icon} size="large"></IonIcon>
                      <span className="co-sale-main-btn-text">
                        {subj.Title}
                      </span>
                    </span>
                  </IonButton>
                )
              );
            })}
          </div>
          
        </div>
      )}
      {props.cobrowseSessionState == 'active' && props.SubType == "Finish" && (
        
        <div style={{margin: '30px 15px', background: 'white', padding: 16,borderRadius: 4}}>
          <p style={{fontSize:16,lineHeight:1.5}}>המוצרים הדיגיטליים שלנו יכולים להיות גשר המוביל את עסקכם בעידן הדיגיטלי. 
עם טכנולוגיה מתקדמת, פתרונות יצירתיים, ותמיכה מלאה לאורך כל הדרך, אנו כאן כדי להבטיח קידום יעיל ומוצלח של העסק שלכם.
תודה על הזמן שלכם, ואנחנו מצפים לפעול יחד עמכם להצלחה משותפת.
</p><br/>
          <p>בברכה,</p>
          <b>צוות b144</b>
        </div>
      )}
    </>
  );
};

export default CoSaleGeneralPage;
