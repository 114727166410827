import React from "react";

// export interface SalePhase {
//   id: number, phase_desc: string, is_completed: boolean
// }
export interface MemberAreas {
  area_type: number;
  cities_result: CitiesResult[];
}
export type CitiesResult = {
  city: string;
  city_code: number;
  selected: boolean;
}
export type RequestResult = {
  ResultDesc: string;
  Success: boolean;
  ResultData: string;
}
export type AdGroup = {
  name: string;
  id: number;
  selected: boolean;
  cat_code: string;
}
export type BillingAddress = {
  city: string;
  city_code: string;
  street: string;
  }
export type MethodsOfPayment = {
  has_bezeq_account: number;
  has_bezeq_phone: number;
  bezeq_phone: string;
  bezeq_tz_hp: string;
  credit_digits: string;
  invoice_business_name: string;
  billing_address: string;
}
export type BezeqPayer = {
  customer_id: string;
  customer_name: string;
  type: string;
  selected: string;
}
export type CategoriesAdv = {
  cat_adv_fld_id: string;
  cat_adv_id: string;
  fld_desc: string;
  fld_type: string;
  fld_value: string;
  selected: boolean;
  fld_mandatory: boolean;
  list_options: CategoriesAdvField[]
  cat_code?: string;
}
export type CategoryAdv = {
  cat_code: string;
  cat_adv_fld_id: string;
  cat_adv_id: string;
  fld_type: string;
  fld_value: string;
}
export type CategoriesAdvField = {
  cat_fld_id: string;
  fld_option_desc: string;
  fld_option_id: string;
  selected: boolean;
}
export type AdvertisingBudgetField = {
  BudgetDesc: string;
  BudgetId: string;
  BudgetType: string;
  BudgetAmount: string;
  Damash: string;
  PromoTextPack: string;
  PromoTextReg: string;
  PromoTextBold: string;
  TotalAmount: string;
}
export type Categories = {
  category: string;
  cat_code: string;
  category_adv?: CategoriesAdv[];
  selected: boolean;
  min_cat_adv: number;
  adgroups?: string;
}
export type Keyword = {
  id: string;
  text: string;
  selected: boolean;
  name: string;
}
export type ResponsiveAd = {
  run_id: string;
  ad_template_id: string;
  type: string;
  text: string;
  line_number: string;
}
export type CreditGuardResponse = {
  uniqueID: string;
  authNumber: string;
  cardToken: string;
  cardExp: string;
  cardMask: string;
  personalId: string;
  txId: string;
  errorCode: string;
  errorText: string;
}
export type CreditGuardSetup = {
  result: string;
  cg_link: string;
  error_desc: string;
  token: string;
  unique_id: string;
}
const SelfSaleContext = React.createContext<{
  saleLineId: string,
  selfRegisterId: string,
  memberAreas: MemberAreas | null,
  memberAdgroups: AdGroup[],
  showSummary:boolean,
  chizukSale:boolean,
  finalStep:boolean,
  showPayment:boolean,
  lpSaleStep:number,
  lpSaleRequestId:string,
  businessName:string,
  businessCategory:string,
  businessCity:string,
  googleAdsAdditionalSummary:string,
  initSelfSale: (authId: string, sale_type: string) => any,
  setShowSummary: (show:boolean) => any,
  setChizukSale: (show:boolean) => any,
  setFinalStep: (show:boolean) => any,
  setShowPayment: (show:boolean) => any,
  getMemberAreas: () => any,
  getCitiesAutocomplete: (prefix: string) => any,
  getCityStreetsAutocomplete: (prefix: string, city_code: string) => any,
  getAreasAutocomplete: (prefix: string) => any,
  saveMemberAreas: (area_type: string, area_city_list: string) => any,
  getCategoriesAutocomplete: (prefix: string) => any,
  getCategoriesAdv: (cat_code: string) => any,
  getMinAdvCategories: (cat_code: string) => any,
  getAdgroupKeywords: (adGroup: string) => any,
  getKeywordsAutocomplete: (prefix: string) => any,
  saveGoogleKeywords: (prefix: string) => any,
  deleteMemberCategories: (categories_list: string) => any,
  saveMemberCategoryAdv: (category_data: CategoryAdv) => any,
  getMemberCategories: () => any,
  startSelfSaleProccess: (sale_type: string) => any,
  setPhaseCompleted: (sale_type: string, phase_id: string, status: string, skip_phase: string) => any,
  getMemberAdvertisingBudget: (sale_type: string) => any
  getAdvertisingBudget: (sale_type: string, promoOnly: boolean) => any
  getBestPackByCategory: (sale_type: string) => any
  saveAdvertisingBudget: (budget: string) => any
  getGoogleResponsiveAdTemplate: (cat_code: string,adGroup_id: string) => any
  getGoogleAdsAdditional: () => any
  saveGoogleAdsAdditional: (adsAdditional:any) => any
  getGoogleAdsNotes: () => any
  saveGoogleAdsNotes: (notes:any) => any
  getResllerSubCategories: (categories: string) => any
  saveMethodsOfPaymentWithBezeqAccount: (bezeq_cust_id: string,bezeq_tz_hp:string) => any
  saveMethodsOfPaymentNoBezeqAccount: (invoice_business_name: string,bezeq_tz_hp:string,billing_city:string,billing_street:string,billing_street_no:string) => any
  getBezeqDetailsByTz: (tz_hp: string, credit_4_digits:string) => any
  initCreditGuard: () => any
  completeCGTransaction: (data: CreditGuardResponse) => any
  getLandingPageGoals: () => any
  saveLandingPageSale: (data: string) => any
  setLpSaleStep: (step:number) => any,
  setLpSaleRequestId: (id:string) => any,
  setGoogleAdsAdditionalSummary: (id:string) => any,
  saveSpamApproved: (approved:string) => any,
}>({
  saleLineId: "",
  selfRegisterId: "",
  memberAreas: null,
  memberAdgroups: [],
  showSummary: false,
  chizukSale: false,
  finalStep: false,
  showPayment: false,
  lpSaleStep: 0,
  lpSaleRequestId: "",
  businessName: "",
  businessCategory: "",
  businessCity: "",
  googleAdsAdditionalSummary: "",
  initSelfSale: () => { },
  setShowSummary: () => { },
  setChizukSale: () => { },
  setFinalStep: () => { },
  setShowPayment: () => { },
  getMemberAreas: () => { },
  getCitiesAutocomplete: () => { },
  getCityStreetsAutocomplete: () => { },
  getAreasAutocomplete: () => { },
  saveMemberAreas: () => { },
  getCategoriesAutocomplete: () => { },
  getCategoriesAdv: () => { },
  getMinAdvCategories: () => { },
  getAdgroupKeywords: () => { },
  getKeywordsAutocomplete: () => { },
  saveGoogleKeywords: () => { },
  deleteMemberCategories: () => { },
  saveMemberCategoryAdv: () => { },
  getMemberCategories: () => { },
  startSelfSaleProccess: () => { },
  setPhaseCompleted: () => { },
  getMemberAdvertisingBudget: () => { },
  getAdvertisingBudget: () => { },
  getBestPackByCategory: () => { },
  saveAdvertisingBudget: () => { },
  getGoogleResponsiveAdTemplate: () => { },
  getGoogleAdsAdditional: () => { },
  saveGoogleAdsAdditional: () => { },
  getGoogleAdsNotes: () => { },
  saveGoogleAdsNotes: () => { },
  getResllerSubCategories: () => { },
  saveMethodsOfPaymentWithBezeqAccount: () => { },
  saveMethodsOfPaymentNoBezeqAccount: () => { },
  getBezeqDetailsByTz: () => { },
  initCreditGuard: () => { },
  completeCGTransaction: () => { },
  getLandingPageGoals: () => { },
  saveLandingPageSale: () => { },
  setLpSaleStep: () => { },
  setLpSaleRequestId: () => { },
  setGoogleAdsAdditionalSummary: () => { },
  saveSpamApproved: () => { },
  
});

export default SelfSaleContext;
