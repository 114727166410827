import React from "react";
import { Line } from "react-chartjs-2";

const LineChart: React.FC<{
  chartData: { Key: string; Value: string[] }[];
  chartWidth?: number;
  chartHeight?: number;
  chartAdditionalData?: any;
  isShowLegend?: boolean;
}> = (props) => {
  let options = {
    legend: {
      display: props.isShowLegend ? props.isShowLegend : false,
      onHover: function (e: any) {
        e.target.style.cursor = "pointer";
      },
    },
    responsive: true,
    tooltips: {
      enabled: true,
      mode: "dataset",
    },
    plugins: {
      chartLables: {
        display: false,
      },
    },

    onHover: (event: any, chartElement: any) => {
      event.target.style.cursor = chartElement[0] ? "pointer" : "default";
    },
  };
  let datasets: any[] = [];
  let chartLables: any[] = [];
  props.chartData.map((d: any) => {
    if (d.Key === "labels") {
      if (d.Value) {
        chartLables = Date.parse(d.Value[0])
          ? d.Value.map((value: string) => value.slice(5))
          : d.Value;
      }
    } else {
      let obj = { label: d.Key, data: d.Value };
      if (props.chartAdditionalData && props.chartAdditionalData[d.Key]) {
        obj = { ...obj, ...props.chartAdditionalData[d.Key] };
      } else {
        obj = {
          ...obj,
          ...{
            backgroundColor: "rgba(75,192,192,0.2)",
            borderColor: "rgba(75,192,192,1)",
          },
        };
      }
      datasets.push(obj);
    }
  });

  const data = {
    labels: chartLables,
    datasets: datasets,
  };

  return (
    <div
      style={{
        width: props.chartWidth ? props.chartWidth : "inherit",
        fontSize: 200,
        textAlign: "center",
      }}
    >
      <Line
        width={140}
        height={props.chartHeight}
        options={options}
        data={data}
      ></Line>
    </div>
  );
};

export default LineChart;

// props.chartWidth? props.chartWidth: 'inherit'
