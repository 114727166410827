import React, { useContext, useEffect, useRef, useState } from "react";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import MemberDetailsContext, {
  QuestionsAndAnswers,
} from "../../../data/MemberDetailes-context";
import { useForm } from "react-hook-form";
import CustomPublishButton from "../../utils/CustomElements/CustomPublishButton";
import CustomForm from "../../utils/CustomElements/CustomForm";
import CustomModal from "../../utils/CustomElements/CustomModal/CustomModal";
import { HeaderParameters } from "../../../data/Header-Context";
import "../businessCardTabs.scss";
import CustomTextarea from "../../utils/CustomElements/CustomTextarea";
import CustomInput from "../../utils/CustomElements/CustomInput";

const HandleQuestionAnswer: React.FC<{
  show: boolean;
  closeModal: () => void;
  onSave: () => void;
  currentQuestionsAnswers: QuestionsAndAnswers;
}> = (props) => {
  const { handleSubmit, formState, errors, control } = useForm({
    mode: "onChange",
  });
  const { dirty } = formState;
  let MemberDetailsCxt = useContext(MemberDetailsContext);

  useEffect(() => {
    if (MemberDetailsCxt.OpenDays == undefined) {
      MemberDetailsCxt.initMemberDetailsContext();
    }
  }, []);

  const [currentQuestion, setCurrenQuestion] = useState<string>(() => {
    return props.currentQuestionsAnswers != null
      ? props.currentQuestionsAnswers.Question
      : "";
  });
  const [currentAnswer, setCurrentAnswer] = useState<string>(() => {
    return props.currentQuestionsAnswers != null
      ? props.currentQuestionsAnswers.Answer
      : "";
  });

  const answerTextRef = useRef<HTMLIonTextareaElement>(null);
  const questionTextRef = useRef<HTMLIonInputElement>(null);

  const headerParameters: HeaderParameters = {
    isPageHaveChanges: dirty,
    modalType: "modal",
    isShowDeleteButton: props.currentQuestionsAnswers != null,
    deleteFunc: () => {
      MemberDetailsCxt.saveQuestionAnswerHandler(
        props.currentQuestionsAnswers,
        "delete"
      );
      props.onSave();
    },
  };

  const saveHandler = () => {
    const Id =
      props.currentQuestionsAnswers != null
        ? props.currentQuestionsAnswers.Id
        : 0;
    const answer = answerTextRef.current!.value!.toString();
    const question = questionTextRef.current!.value!.toString();
    let answerQuestionObj: QuestionsAndAnswers = {
      Question: question,
      Answer: answer,
      Id: Id,
      SortOrder: 0,
    };
    MemberDetailsCxt.saveQuestionAnswerHandler(answerQuestionObj, "save");
    props.onSave();
  };

  return (
    <CustomModal
      pageName={"HandleOpenHours"}
      headerParameters={headerParameters}
      closeModal={() => {
        props.closeModal();
      }}
      isOpen={props.show}
      title="שאלות תשובות"
      subTitle="ערוך את השאלה"
    >
      <CustomForm onSubmit={handleSubmit(saveHandler)} errors={errors}>
        <IonGrid>
          <IonRow className="ion-margin-bottom">
            <IonCol>
              <CustomInput
                name="question"
                label="הוספת שאלה"
                errors={errors}
                control={control}
                type="text"
                required={true}
                maxlength={90}
                ref={questionTextRef}
                value={currentQuestion}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-no-padding">
              <CustomTextarea
                name="answer"
                errors={errors}
                control={control}
                required={true}
                ref={answerTextRef}
                value={currentAnswer}
                label="הוספת תשובה"
              />
            </IonCol>
          </IonRow>
          <IonRow className="ion-text-center">
            <IonCol>
              <CustomPublishButton title="שמור"></CustomPublishButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </CustomForm>
    </CustomModal>
  );
};

export default HandleQuestionAnswer;
