import React, { useEffect, useRef, useState } from "react";
import BusinessLanguage from "./BusinessLanguage";
import MemberOpenHours from "./MemberOpenHours";
import BusinessPublicationDays from "./BusinessPublicationDays";
import HandleOpenHours from "./HandleOpenHours";
//import BusinessMovieCtrl from "./BusinessMovie";
import BusinessLinks from "./BusinessLinks";
import BusinessDescription from "./BusinessDescription";
import BusinessEvents from "./BusinessEvents";
import BusinessSocialNetworks from "./BusinessSocialNetworks";
import BusinessExpert from "./BusinessExpert";
import { businessCardModulesName } from "../../../utils-ts/BusinessCardFunc";
import BusinessFax from "./BusinessFax";
import BusinessEmail from "./BusinessEmail";
import BusinessLandingPage from "./BusinessLandingPage";
import BusinessMobile from "./BusinessMobile";
import BusinessPhone1 from "./BusinessPhone";
import BusinessWebSite from "./BusinessWebSite";
import BusinessEcommerceSite from "./BusinessEcommerceSite";
import BusinessAddress from "./BusinessAddress";
import HandleBusinessEvents from "./HandleBusinessEvents";
import BusinessMoreProperties from "./BusinessMoreProperties";
import BusinessAdvCategory from "./BusinessAdvCategory";
import BusinessYoutubeChannel from "./BusinessYoutubeChannel";
import BusinessQuestionsAndAnswers from "./BusinessQuestionsAndAnswers";
import ServiceAreas from "../../ServiceAreas/ServiceAreas";
import BusinessMovies from "./BusinessMovies";
import BusinessSeoDetails from "./BusinessSeoDetails";
import BusinessSeoBrands from "./BusinessSeoBrands";

const MemberDetaielsModules: React.FC<{
  showElement: businessCardModulesName;
  closeModal: () => void;
  onSave: () => void;
  mainCategory?: string;
}> = (props) => {

  const mounted = useRef(false);
  
  const [isShowDescription, setIsShowDescription] = useState(false);
  const [isShowLanguage, setIsShowLanguage] = useState(false);
  const [isShowOpenHours, setIsShowOpenHours] = useState(false);
  const [isShowPublicationDays, setIsShowPublicationDays] = useState(false);
  const [isShowHandleOpenHours, setIsShowHandleOpenHours] = useState(false);
  //const [isShowMovie, setIsShowMovie] = useState(false);
  const [isShowMovies, setIsShowMovies] = useState(false);
  const [isShowLinks, setIsShowLinks] = useState(false);
  const [isShowSeoDetails,setIsShowSeoDetails]= useState(false);
  const [isShowSeoBrands,setIsShowSeoBrands]= useState(false);
  const [isShowQuestionsAndAnswers, setIsShowQuestionsAndAnswers] = useState(
    false
  );
  const [isBusinessFax, setIsBusinessFax] = useState(false);
  const [isBusinessExpert, setIsBusinessExpert] = useState(false);
  const [isBusinessEmail, setIsBusinessEmail] = useState(false);
  const [isBusinessLandingPage, setIsBusinessLandingPage] = useState(false);
  const [isBusinessMobile, setIsBusinessMobile] = useState(false);
  const [isBusinessPhone, setIsBusinessPhone] = useState(false);
  const [isBusinessWebSite, setIsBusinessWebSite] = useState(false);
  const [isEcommerceSite, setIsEcommerceSite] = useState(false);
  const [isShowEvents, setIsShowEvents] = useState(false);
  const [isShowAddress, setIsShowAddress] = useState(false);
  const [isShowMoreProperties, setIsShowMoreProperties] = useState(false);
  const [isShowAdvCategory, setIsShowAdvCategory] = useState(false);
  const [_mainCategory, setMainCategory] = useState("");
  // const [isShowEvents, setIsShowEvents] = useState(false);
  const [isShowSocialNetWork, setIsShowSocialNetWork] = useState(false);
  const [isYoutubeChannel, setIsYoutubeChannel] = useState(false);
  const [isServiceAreas, setIsServiceAreas] = useState(false);
  useEffect(() => {
  mounted.current = true;
  return () => {
    mounted.current = false;
};},[]);

  useEffect(() => {
    const setName = props.showElement;
    if (setName == "Description") {
      setIsShowDescription(true);
    } else if (setName == "BusinessLanguages") {
      setIsShowLanguage(true);
    } else if (setName == "AdvCategories") {
      if (props.mainCategory != undefined) {
        setMainCategory(props.mainCategory);
      }
      setIsShowAdvCategory(true);
    } else if (setName == "BusinessLinks") {
      setIsShowLinks(true);
    } else if (setName == "SeoDetails") {
      setIsShowSeoDetails(true);
    }else if (setName == "SeoBrands") {
      setIsShowSeoBrands(true);
    } else if (setName == "BusinessQuestionsAndAnswers") {
      setIsShowQuestionsAndAnswers(true);
    } 
    // else if (setName == "BusinessMovie") {
    //   setIsShowMovie(true);
    // } 
    else if (setName == "BusinessMovies") {
      setIsShowMovies(true);
    } else if (setName == "OpenDays") {
      setIsShowOpenHours(true);
    } else if (setName == "PublicationDays") {
      setIsShowPublicationDays(true);
    } else if (setName == "HandleOpenDays") {
      setIsShowHandleOpenHours(true);
    } else if (setName == "SocialNetWork") {
      setIsShowSocialNetWork(true);
    } else if (setName == "BusinessExpert") {
      setIsBusinessExpert(true);
    } else if (setName == "BusinessFax") {
      setIsBusinessFax(true);
    } else if (setName == "Email") {
      setIsBusinessEmail(true);
    } else if (setName == "LandingPage") {
      setIsBusinessLandingPage(true);
    } else if (setName == "Mobile") {
      setIsBusinessMobile(true);
    } else if (setName == "Phone1") {
      setIsBusinessPhone(true);
    } else if (setName == "WebSite") {
      setIsBusinessWebSite(true);
    } else if (setName == "EcommerceSite") {
      setIsEcommerceSite(true);
    } else if (setName == "BusinessAddress") {
      setIsShowAddress(true);
    } else if (setName == "BusinessMoreProperties") {
      setIsShowMoreProperties(true);
    } else if (setName == "YoutubeChannel") {
      setIsYoutubeChannel(true);
    } else if (setName == "ServiceAreas") {
      setIsServiceAreas(true);
    }
    //else if (setName == "BusinessEvents") {
    //   setIsShowEvents(true);
    // }
  }, [props.showElement]);
  return (
    <React.Fragment>
      {isShowSocialNetWork && (
        <BusinessSocialNetworks
          show={isShowSocialNetWork}
          closeModal={() => {
            setIsShowSocialNetWork(false);
            props.closeModal();
          }}
          onSave={() => {}}
        ></BusinessSocialNetworks>
      )}
      {isShowDescription && (
        <BusinessDescription
          show={isShowDescription}
          closeModal={() => {
            setIsShowDescription(false);
            props.closeModal();
          }}
          onSave={() => {
            props.onSave();
          }}
        ></BusinessDescription>
      )}
      {isShowOpenHours && (
        <MemberOpenHours
          show={isShowOpenHours}
          closeModal={() => {
            setIsShowOpenHours(false);
            props.closeModal();
          }}
          onSave={() => {
            props.onSave();
            setIsShowOpenHours(false);
          }}
        ></MemberOpenHours>
      )}
      {isShowPublicationDays && (
        <BusinessPublicationDays
          show={isShowPublicationDays}
          closeModal={() => {
            setIsShowPublicationDays(false);
            props.closeModal();
          }}
          onSave={() => {
            props.onSave();
          }}
        ></BusinessPublicationDays>
      )}
      {isShowHandleOpenHours && (
        <HandleOpenHours
          show={isShowHandleOpenHours}
          closeModal={() => {
            setIsShowHandleOpenHours(false);
            props.closeModal();
          }}
          onSave={() => {
            props.onSave();
            setIsShowOpenHours(true);
            setIsShowHandleOpenHours(false);
          }}
          openDaysArray={[]}
          editedopenHourIndex={null}
        ></HandleOpenHours>
      )}
      {isShowLanguage && (
        <BusinessLanguage
          show={isShowLanguage}
          closeModal={() => {
            setIsShowLanguage(false);
            props.closeModal();
          }}
          onSave={() => {
            props.onSave();
          }}
        ></BusinessLanguage>
      )}
      {/* {isShowMovie && (
        <BusinessMovieCtrl
          show={isShowMovie}
          id={-1}
          closeModal={() => {
            setIsShowMovie(false);
            props.closeModal();
          }}
          onSave={() => {
            props.onSave();
          }}
        ></BusinessMovieCtrl>
      )} */}
      {isShowMovies && (
        <BusinessMovies
          show={isShowMovies}
          closeModal={() => {
            setIsShowMovies(false);
            props.closeModal();
          }}
          onSave={() => {
            props.onSave();
          }}
        ></BusinessMovies>
      )}
      {isShowLinks && (
        <BusinessLinks
          show={isShowLinks}
          closeModal={() => {
            setIsShowLinks(false);
            props.closeModal();
          }}
          onSave={() => {
            props.onSave();
          }}
        ></BusinessLinks>
      )}
       {isShowSeoDetails && (
        <BusinessSeoDetails
          show={isShowSeoDetails}
          closeModal={() => {
            setIsShowSeoDetails(false);
            props.closeModal();
          }}
          onSave={() => {
            props.onSave();
          }}
        ></BusinessSeoDetails>
      )}
      {isShowSeoBrands && (
        <BusinessSeoBrands
          show={isShowSeoBrands}
          closeModal={() => {
            setIsShowSeoBrands(false);
            props.closeModal();
          }}
          onSave={() => {
            props.onSave();
          }}
        ></BusinessSeoBrands>
      )}

      {isShowQuestionsAndAnswers && (
        <BusinessQuestionsAndAnswers
          show={isShowQuestionsAndAnswers}
          closeModal={() => {
            setIsShowQuestionsAndAnswers(false);
            props.closeModal();
          }}
          onSave={() => {
            props.onSave();
          }}
        ></BusinessQuestionsAndAnswers>
      )}

      {isBusinessExpert && (
        <BusinessExpert
          show={isBusinessExpert}
          closeModal={() => {
            setIsBusinessExpert(false);
            props.closeModal();
          }}
          onSave={() => {
            props.onSave();
          }}
        ></BusinessExpert>
      )}
      {isBusinessFax && (
        <BusinessFax
          show={isBusinessFax}
          closeModal={() => {
            setIsBusinessFax(false);
            props.closeModal();
          }}
          onSave={() => {
            props.onSave();
          }}
        ></BusinessFax>
      )}
      {isBusinessEmail && (
        <BusinessEmail
          show={isBusinessEmail}
          closeModal={() => {
            setIsBusinessEmail(false);
            props.closeModal();
          }}
          onSave={() => {
            props.onSave();
          }}
        ></BusinessEmail>
      )}
      {isBusinessLandingPage && (
        <BusinessLandingPage
          show={isBusinessLandingPage}
          closeModal={() => {
            setIsBusinessLandingPage(false);
            props.closeModal();
          }}
          onSave={() => {
            props.onSave();
          }}
        ></BusinessLandingPage>
      )}
      {isBusinessMobile && (
        <BusinessMobile
          show={isBusinessMobile}
          closeModal={() => {
            setIsBusinessMobile(false);
            props.closeModal();
          }}
          onSave={() => {
            props.onSave();
          }}
        ></BusinessMobile>
      )}
      {isBusinessPhone && (
        <BusinessPhone1
          show={isBusinessPhone}
          closeModal={() => {
            setIsBusinessPhone(false);
            props.closeModal();
          }}
          onSave={() => {
            props.onSave();
          }}
        ></BusinessPhone1>
      )}
      {isBusinessWebSite && (
        <BusinessWebSite
          show={isBusinessWebSite}
          closeModal={() => {
            setIsBusinessWebSite(false);
            props.closeModal();
          }}
          onSave={() => {
            props.onSave();
          }}
        ></BusinessWebSite>
      )}
      {isEcommerceSite && (
        <BusinessEcommerceSite
          show={isEcommerceSite}
          closeModal={() => {
            setIsEcommerceSite(false);
            props.closeModal();
          }}
          onSave={() => {
            props.onSave();
          }}
        ></BusinessEcommerceSite>
      )}
      {isShowEvents && (
        <BusinessEvents
          show={isShowEvents}
          closeModal={() => {
            setIsShowEvents(false);
            props.closeModal();
          }}
          onSave={() => {
            props.onSave();
          }}
        ></BusinessEvents>
      )}
      {isShowAddress && (
        <BusinessAddress
          show={isShowAddress}
          closeModal={() => {
            setIsShowAddress(false);
            props.closeModal();
          }}
          onSave={() => {
            props.onSave();
          }}
        ></BusinessAddress>
      )}
      {isShowMoreProperties && (
        <BusinessMoreProperties
          show={isShowMoreProperties}
          closeModal={() => {
            setIsShowMoreProperties(false);
            props.closeModal();
          }}
          onSave={() => {
            props.onSave();
          }}
        ></BusinessMoreProperties>
      )}
      {isShowAdvCategory && (
        <BusinessAdvCategory
          show={isShowAdvCategory}
          mainCategory={_mainCategory}
          closeModal={() => {
            if (mounted.current) {
            setIsShowAdvCategory(false);
            setMainCategory("");
            props.closeModal();
            }
          }}
          onSave={() => {}}
        ></BusinessAdvCategory>
      )}
      {isYoutubeChannel && (
        <BusinessYoutubeChannel
          show={isYoutubeChannel}
          closeModal={() => {
            setIsYoutubeChannel(false);
            props.closeModal();
          }}
          onSave={() => {
            props.onSave();
          }}
        ></BusinessYoutubeChannel>
      )}
      {isServiceAreas && (
        <ServiceAreas
          show={isServiceAreas}
          closeModal={() => {
            setIsServiceAreas(false);
            props.closeModal();
          }}
          onSave={() => {
            props.onSave();
          }}
        ></ServiceAreas>
      )}
    </React.Fragment>
  );
};

export default MemberDetaielsModules;
