import React, { useContext, useEffect, useState } from 'react';
import { IonGrid, IonRow, IonCol, IonItem, IonLabel, IonRadioGroup, IonListHeader, IonRadio } from '@ionic/react';
import { isMobile } from 'react-device-detect';
import ProductsContext, { MemberShipping } from '../../../data/Products-context';

import CustomInput from '../../utils/CustomElements/CustomInput';
import { useForm } from 'react-hook-form';

import CustomForm from '../../utils/CustomElements/CustomForm';
import CustomPublishButton from '../../utils/CustomElements/CustomPublishButton';
import CustomModal from '../../utils/CustomElements/CustomModal/CustomModal';
import { HeaderParameters } from '../../../data/Header-Context';
import { toast } from 'react-toastify';

const ECommerceShipping: React.FC<{
  show: boolean;
  
  closeModal: (is_open_google_shop:boolean) => void;
  
  
}> = (props) => {
  const { handleSubmit, formState, errors, control, register, setError } = useForm({
    mode: 'onChange',
  });

  const ProductsCtx = useContext(ProductsContext);

  const { dirty } = formState;
  const [shippingType, setShippingType] = useState<'none' | 'free' | 'paid'>();
  const [isPageChange, setisPageChange] = useState(false);
//shipping: MemberShipping | undefined;
  const [CurrentShipping, setCurrentShipping] = useState<MemberShipping>();
  
  useEffect(()=>{
    if(CurrentShipping == undefined){
      if(ProductsCtx.MemberShippings && ProductsCtx.MemberShippings.length > 0){
        setCurrentShipping(ProductsCtx.MemberShippings[0]);
        setShippingType(ProductsCtx.MemberShippings[0].Cost == 0 ? 'free' : 'paid');
      }else{
        setCurrentShipping({
          Id: -1,
          Name: '',
          Cost: 0,
          MinHandlingTime: 0,
          MaxHandlingTime: 0,
          MinTransitTime: 0,
          MaxTransitTime: 0,
        });
      }
    }
  },[]);
 

  const saveHandler = (data: any) => {
    if (!CurrentShipping) {
      toast.warning('לא נמצא מוצר');
      return;
    }

    const name = data['Name'];
    const cost = shippingType == 'paid' ? data['Cost'] : 0;
    const minHandlingTime = parseInt(data['MinHandlingTime']);
    const maxHandlingTime = parseInt(data['MaxHandlingTime']);
    const minTransitTime = parseInt(data['MinTransitTime']);
    const maxTransitTime = parseInt(data['MaxTransitTime']);

    let invalid = false;
    
    if(minHandlingTime < 0){
      setError('MinHandlingTime','manual','זמן טיפול לא תקין');
      invalid = true;
    } 
    if(maxHandlingTime < minHandlingTime){
      setError('MaxHandlingTime','manual','זמן טיפול לא תקין');
      invalid = true;
    } 
    
    if(minTransitTime < 0){
      setError('MinTransitTime','manual','משך משלוח לא תקין');
      invalid = true;
    } 
    if(maxTransitTime < minTransitTime){
      setError('MaxTransitTime','manual','משך משלוח לא תקין');
      invalid = true;
    } 
    

    if (shippingType === 'none' || shippingType == undefined) {
      toast.warning('לא נבחרה שיטת משלוח');
      invalid = true;
    }else if(shippingType == 'paid' && (isNaN(cost) || cost <= 0)){
      setError('Cost','manual','לא הוזן מחיר משלוח תקין');
      invalid = true;
    }
    if(invalid){
      return;
    }

    CurrentShipping.Name = name;
    CurrentShipping.Cost = parseFloat(cost);
    CurrentShipping.MinHandlingTime = minHandlingTime;
    CurrentShipping.MaxHandlingTime = maxHandlingTime;
    CurrentShipping.MinTransitTime = minTransitTime;
    CurrentShipping.MaxTransitTime = maxTransitTime;
    console.log(CurrentShipping);

    ProductsCtx.saveMemberShipping(CurrentShipping).then((result:any)=>{
      if(result){
      props.closeModal(CurrentShipping.Id < 0);
    }
    });
    
  };

  const headerParameters: HeaderParameters = {
    isPageHaveChanges: dirty || isPageChange,
    modalType: 'modal',
    isShowLogo: false,
    isShowDeleteButton: false,
   
  };

  return (
    <CustomModal
      pageName={'AddEditShipping'}
      headerParameters={headerParameters}
      closeModal={() => {
        props.closeModal(false);
      }}
      isOpen={props.show}
      title={'איזה שיטת משלוח תעלה?'}
      subTitle={'משלוח חינם נותן לך יתרון שיווקי.'}>
     {CurrentShipping && <CustomForm onSubmit={handleSubmit(saveHandler)} errors={errors}>
        <IonGrid className="ion-no-padding ion-padding-horizontal">
          <IonRow className="ion-justify-content-between">
            <IonCol className="ion-no-margin">
              <IonGrid>
                <IonRow>
                  <IonCol size="12">
                    <CustomInput
                      name="Name"
                      errors={errors}
                      control={control}
                      label="שם המשלוח"
                      ref={register}
                      value={CurrentShipping.Name}
                      required={true}
                      maxlength={30}></CustomInput>
                  </IonCol>
                  <IonCol size="12">
                    <IonRow>
                      <IonCol size="12" style={{paddingBottom:0}}>
                      <IonListHeader>
                        <IonLabel>זמן טיפול בהזמנה (ימים)</IonLabel></IonListHeader>
                      </IonCol>
                      <IonCol size="5.8">
                        <CustomInput
                          name="MinHandlingTime"
                          errors={errors}
                          control={control}
                          label="החל מ"
                          ref={register}
                          required={true}
                          
                          value={CurrentShipping.MinHandlingTime}
                          
                          ></CustomInput>
                      </IonCol>
                      <IonCol size="0.4"></IonCol>
                      <IonCol size="5.8">
                        <CustomInput name="MaxHandlingTime" errors={errors} control={control} 
                        label="עד"
                        required={true}
                        
                        ref={register} 
                        value={CurrentShipping.MaxHandlingTime} ></CustomInput>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                  <IonCol size="12">
                    <IonRow>
                      <IonCol size="12" style={{paddingBottom:0}}>
                      <IonListHeader>
                        <IonLabel>משך משלוח (ימים)</IonLabel></IonListHeader>
                      </IonCol>
                      <IonCol size="5.8">
                        <CustomInput
                          name="MinTransitTime"
                          errors={errors}
                          control={control}
                          label="החל מ"
                          required={true}
                          
                          ref={register}
                          value={CurrentShipping.MinTransitTime}
                          ></CustomInput>
                      </IonCol>
                      <IonCol size="0.4"></IonCol>
                      <IonCol size="5.8">
                        <CustomInput name="MaxTransitTime" errors={errors} control={control} 
                        label="עד"
                        required={true}
                        
                        ref={register} 
                        value={CurrentShipping.MaxTransitTime} ></CustomInput>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                  
                    <IonCol size="12">
                      <IonRadioGroup
                        name="content_type"
                        onIonChange={(event: CustomEvent) => {
                          setShippingType(event.detail.value);
                        }}
                        ref={register}
                        
                        value={shippingType}>
                        <IonListHeader>
                          <IonLabel>בחירת שיטת משלוח</IonLabel>
                        </IonListHeader>
                        <IonRow>
                          <IonCol size="6" className="ion-no-padding">
                            <IonItem mode="md" lines="none" className="item-radio">
                              <IonLabel className="item-radio-label">משלוח חינם</IonLabel>
                              <IonRadio mode="md" slot="start" className="item-radio-btn" value="free" />
                            </IonItem>
                          </IonCol>
                          <IonCol size="6" className="ion-no-padding">
                            <IonItem mode="md" lines="none" className="item-radio">
                              <IonLabel className="item-radio-label">משלוח בתשלוח</IonLabel>
                              <IonRadio mode="md" slot="start" className="item-radio-btn" value="paid" />
                            </IonItem>
                          </IonCol>
                        </IonRow>
                      </IonRadioGroup>
                    </IonCol>
                  

                  {shippingType == 'paid' && (
                    <IonCol size="12">
                      <CustomInput name="Cost" errors={errors} 
                      control={control} label="מחיר" 
                      required={shippingType == 'paid'}
                      ref={register} value={CurrentShipping.Cost} customType="price"></CustomInput>
                    </IonCol>
                  )}
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>

          {/* start - action button (align to left) desktop mode  */}

          <IonRow className="ion-justify-content-end">
            <IonCol size="12" sizeMd="5.9">
              <CustomPublishButton></CustomPublishButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </CustomForm>}
    </CustomModal>
  );
};

export default ECommerceShipping;
