import {
  IonButton,
  IonCard,
  IonCol,
  IonIcon,
  IonLabel,
  IonList,
  IonRow,
} from "@ionic/react";
import React, { useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import {
  InsertLogLeadOrCallItem,
  UpdateLeadOrCallItem,
  memberLeadOrCallObj,
} from "../../data/statisticServices";
import CustomAlert from "../utils/CustomElements/CustomAlert";
import LeadItemPopup from "./LeadItemPopup";

import moment from "moment";
import { convertTimeStampToDate } from "../../utils-ts/GlobalDateFunctions";

//images+ icons+ scss
import { add } from "ionicons/icons";
import useDownloadExcel from "../../hooks/useDownloadExcel";
import { blankProductIconRed, excel, whatsapp } from "../../images";
import NativeApp from "../../utils-ts/NativeApp";
import "./statistics.scss";

const LeadsAndCallsList: React.FC<{
  items: memberLeadOrCallObj[];
  inPopup?: boolean | false;
  filterSelected: string;
}> = (props) => {
  const tableRef = useRef<HTMLTableElement>(null);
  const [startEditing, setStartEditing] = useState(false);
  const [currentItem, setCurrentItem] = useState<memberLeadOrCallObj | null>(
    null
  );
  const saveLeadOrCallItemHandler = async (item: memberLeadOrCallObj) => {
    const date = item.Date;
    item.Date = moment(item.Date).toISOString(true);
    const response: any = await UpdateLeadOrCallItem(item);
    if (response.Success) {
      item.Date = date;
      setStartEditing(false);
    } else {
      console.log(response.ErrorMessage);
      toast.error("ארעה שגיאה בעדכון הליד");
    }
  };
  const insertLogLeadOrCallItem = async (id: string, type: string) => {
    if ((id == null || id == "") && currentItem) {
      const cloneItem = { ...currentItem };
      cloneItem.Date = moment(currentItem.Date).toISOString(true);
      const response: any = await UpdateLeadOrCallItem(cloneItem);
      if (response.Success) {
        id = response.Response;
        setStartEditing(false);
      } else {
        console.log(response.ErrorMessage);
        toast.error("ארעה שגיאה בעדכון הליד");
      }
    }

    const response2: any = await InsertLogLeadOrCallItem(id, type);
    //const res = JSON.parse(response2.Response);
    if (!response2.Success) {
      console.log(response2.ErrorMessage);
      toast.error("ארעה שגיאה בתיעוד הליד");
    }
  };
  const statusDesc = (type: number) => {
    switch (type) {
      case 0:
        return "פניה פתוחה";
      case 1:
        return "אין מענה";
      case 2:
        return "לא רלוונטי";
      case 3:
        return "לחזור ללקוח";
      case 4:
        return "בטיפול";
      case 5:
        return "טופל";
      case 6:
        return "בוטל";
      case 7:
        return "מחכה לתשובה מהלקוח";
      case 8:
        return "אחר";
    }
    return type;
  };

  const [showPhoneAlert, setShowPhoneAlert] = useState(false);
  const [showWhatsappAlert, setShowWhatsappAlert] = useState(false);
  const [showMailAlert, setShowMailAlert] = useState(false);
  const { OpenWhatsApp, OpenDialer, MailTo } = NativeApp();
  const { onDownload } = useDownloadExcel({
    tableRef,
    filename: "לידים",
  });
  const createButtos = (type: string, value: string) => {
    const ButtonsArray = [
      {
        text: "לא",
        role: "no",
        handler: () => {
          if (type == "phone") {
            setShowPhoneAlert(false);
            OpenDialer("tel:" + value);
          } else if (type == "whatsapp") {
            setShowWhatsappAlert(false);
            var cleanPhon = value.startsWith("0") ? value.substring(1) : value;
            if (isMobile) {
              OpenWhatsApp("whatsapp://send?phone=" + "+972" + cleanPhon + "/");
            } else {
              window.open(
                "https://web.whatsapp.com/send?phone=" +
                  "+972" +
                  cleanPhon +
                  "/",
                "_blank"
              );
            }
          } else if (type == "mail") {
            setShowMailAlert(false);
            MailTo("mailto:" + value);
          }
          return false;
        },
      },
      {
        text: "כן",
        role: "yes",
        handler: () => {
          //insert log history
          insertLogLeadOrCallItem(currentItem!.Id, type);

          if (type == "phone") {
            setShowPhoneAlert(false);
            OpenDialer("tel:" + value);
          } else if (type == "whatsapp") {
            setShowWhatsappAlert(false);
            var cleanPhon = value.startsWith("0") ? value.substring(1) : value;
            if (isMobile) {
              OpenWhatsApp("whatsapp://send?phone=" + "+972" + cleanPhon + "/");
            } else {
              window.open(
                "https://web.whatsapp.com/send?phone=" +
                  "+972" +
                  cleanPhon +
                  "/",
                "_blank"
              );
            }
          } else if (type == "mail") {
            setShowMailAlert(false);
            MailTo("mailto:" + value);
          }
          return true;
        },
      },
    ];
    return ButtonsArray;
  };

  return (
    <>
      {props.items != null && props.items.length > 0 && (
        <>
          <IonRow
            className="ion-justify-content-end ion-align-items-center"
            style={{ columnGap: "40px", marginBottom: "10px" }}
          >
            {/* <span style={{ color: "#6a7ff7" }}>
              לחצו על הטלפון להתקשרות או על המייל ליצירת מייל
            </span> */}

            <IonCol className="ion-no-padding">
              <IonCard
                className="ion-no-margin"
                style={{ paddingTop: 5, paddingBottom: 5 }}
              >
                <IonLabel>
                  סה"כ שיחות {props.items.filter((m) => m.Type == 1).length}|
                  סה"כ לידים {props.items.filter((m) => m.Type != 1).length}
                </IonLabel>
              </IonCard>
            </IonCol>

            <button
              style={{
                borderRadius: "17px",
                boxShadow: "2px 2px 6px 0",
                border: "solid 1px #4a4a4a",
                padding: "4px 12px",
                display: "flex",
                alignItems: "center",
              }}
              onClick={onDownload}
            >
              <IonIcon size="small" icon={excel}></IonIcon>ייצוא לאקסל
            </button>
          </IonRow>

          <div
            style={{ overflowX: "scroll", width: "100%" }}
            className="leadsTable"
          >
            <table
              className="ion-no-padding ion-padding-horizontal "
              style={{
                width: isMobile ? "650px" : "100%",
              }}
              ref={tableRef}
            >
              <thead>
                <tr
                  style={{
                    padding: 0,
                    fontWeight: "bold",
                    fontSize: "12px",
                    height: "45px",
                    backgroundColor: "#16254f",
                    textAlign: "right",
                    color: "white",
                  }}
                >
                  {!props.inPopup && <th style={{ minWidth: 55 }}>פעולות</th>}
                  <th style={{ minWidth: "75px", paddingRight: "4px" }}>
                    ליד\שיחה
                  </th>
                  <th style={{ minWidth: "75px" }}>מקור</th>
                  <th style={{ minWidth: "75px" }}>תאריך</th>
                  <th style={{ minWidth: "55px" }}>משך שיחה</th>
                  <th style={{ minWidth: "95px" }}>שם</th>
                  <th style={{ minWidth: "95px" }}>טלפון</th>
                  {!props.inPopup && (
                    <th style={{ minWidth: "38px" }}>ווטצאפ</th>
                  )}
                  <th style={{ minWidth: "95px" }}>מייל</th>
                  <th style={{ minWidth: "65px" }}>סטטוס</th>
                </tr>
              </thead>
              <tbody>
                {props.items &&
                  props.items.length > 0 &&
                  props.items.map((m, index) => {
                    const date = m.Date ? convertTimeStampToDate(m.Date) : "";
                    if (props.filterSelected == "leads" && m.Type == 1) {
                      return;
                    }
                    if (props.filterSelected == "calls" && m.Type != 1) {
                      return;
                    }
                    return (
                      <tr
                        key={index}
                        style={{
                          fontSize: "12px",
                          borderBottom: "1px solid #e7e9ed",
                          backgroundColor: "white",
                          height: "30px",
                        }}
                      >
                        {!props.inPopup && (
                          <td>
                            <IonButton
                              size="small"
                              onClick={() => {
                                setStartEditing(true);
                                setCurrentItem(m);
                              }}
                            >
                              <IonIcon icon={add} slot="icon-only" />
                            </IonButton>
                          </td>
                        )}

                        <td>{m.Type == 1 ? "שיחה" : "ליד"}</td>
                        <td>{m.Referer}</td>
                        <td>{date}</td>
                        <td>{m.CallDuration}</td>
                        <td className="td-name1" style={{}}>
                          {m.Name}
                        </td>
                        <td>
                          {!props.inPopup ? (
                            <div
                              style={{
                                color: "blue",
                                textDecoration: "underline",
                                cursor: "pointer",
                                textAlign: "center",
                              }}
                              onClick={
                                !props.inPopup
                                  ? () => {
                                      setShowPhoneAlert(true);
                                      setCurrentItem(m);
                                    }
                                  : undefined
                              }
                            >
                              {m.Phone}
                            </div>
                          ) : (
                            <div>{m.Phone}</div>
                          )}
                        </td>
                        {!props.inPopup && (
                          <td>
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setShowWhatsappAlert(true);
                                setCurrentItem(m);
                              }}
                            >
                              <IonIcon size="small" icon={whatsapp}></IonIcon>
                            </div>
                          </td>
                        )}
                        <td className="td-mail">
                          {!props.inPopup ? (
                            <div
                              onClick={() => {
                                setShowMailAlert(true);
                                setCurrentItem(m);
                              }}
                              style={{
                                color: "blue",
                                textDecoration: "underline",
                                cursor: "pointer",
                                padding: "0 10px",
                                textAlign: "center",
                              }}
                            >
                              {m.Mail}
                            </div>
                          ) : (
                            <div> {m.Mail}</div>
                          )}
                        </td>
                        <td>{statusDesc(m.StatusType)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {currentItem && (
              <>
                <CustomAlert
                  onDismiss={() => {
                    setShowPhoneAlert(false);
                  }}
                  isOpen={showPhoneAlert}
                  header={"האם ברצונך לתעד את השיחה?"}
                  message={
                    "בלחיצה על כן, השיחה תירשם בדוח הפעילות שלך מול הלקוח"
                  }
                  buttons={createButtos("phone", currentItem.Phone.toString())}
                ></CustomAlert>
                <CustomAlert
                  onDismiss={() => {
                    setShowWhatsappAlert(false);
                  }}
                  isOpen={showWhatsappAlert}
                  header={"האם ברצונך לתעד את השיחה?"}
                  message={
                    "בלחיצה על כן, השיחה תירשם בדוח הפעילות שלך מול הלקוח"
                  }
                  buttons={createButtos(
                    "whatsapp",
                    currentItem.Phone.toString()
                  )}
                ></CustomAlert>
                <CustomAlert
                  onDismiss={() => {
                    setShowMailAlert(false);
                  }}
                  isOpen={showMailAlert}
                  header={"האם ברצונך לתעד את המייל?"}
                  message={
                    "בלחיצה על כן, המייל יירשם בדוח הפעילות שלך מול הלקוח"
                  }
                  buttons={createButtos("mail", currentItem.Mail.toString())}
                ></CustomAlert>
              </>
            )}
          </div>
        </>
      )}
      {props.items == null ||
        (props.items.length === 0 && (
          <IonList>
            <IonRow>
              <IonCol className="ion-text-center">
                <IonIcon
                  icon={blankProductIconRed}
                  className="blank-list-icon"
                />
                <p>אין לידים</p>
              </IonCol>
            </IonRow>
          </IonList>
        ))}
      {startEditing && currentItem && (
        <LeadItemPopup
          show={startEditing}
          closeModal={() => {
            setStartEditing(false);
            setCurrentItem(null);
          }}
          currentItem={currentItem}
          onSave={saveLeadOrCallItemHandler}
        />
      )}
    </>
  );
};

export default LeadsAndCallsList;
