import React from "react";
import googlePartner from "../../../../../../src/images/icons/google-partner.webp";
import googleIcon from "../../../../../../src/images/icons/google.svg";
import styles from "./GoogleIntroPage.module.scss";

export default function GoogleIntroPage() {
  return (
    <div className={styles["google-intro-page"]}>
      <img
        src={googleIcon}
        alt="Google icon"
        className={styles["google-icon"]}
      />
      <div className={styles.title}>פרסום ממומן בגוגל</div>
      <p className={styles.paragraph}>
        כשותפי גוגל (Google Partners) בישראל, המומחים שלנו יקימו קמפיין ממומן
        ומותאם עם מודעות אפקטיביות, שיחשפו את העסק שלך להרבה לקוחות פוטנציאליים!
      </p>
      <img
        src={googlePartner}
        alt="Google partner"
        className={styles["google-partner"]}
      />
    </div>
  );
}
